import * as React from 'react';

import { Indicator } from '../../../../types';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const TickForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label htmlFor="tick_periods" className="col-sm-6 control-label">
          <span>ATR Periods (d:14)</span>
        </label>
        <div className="col-sm-6">
          <input
            id="tick_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.atrPeriods}
            onChange={(event) => update({ atrPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="tick_upper" className="col-sm-6 control-label">
          Max Volatility
        </label>
        <div className="col-sm-6">
          <input
            id="tick_upper"
            type="number"
            step="1"
            className="form-control"
            value={indicator.upperThreshold}
            onChange={(event) => update({ upperThreshold: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="tick_lower" className="col-sm-6 control-label">
          Min Volatility
        </label>
        <div className="col-sm-6">
          <input
            id="tick_lower"
            type="number"
            step="1"
            className="form-control"
            value={indicator.lowerThreshold}
            onChange={(event) => update({ lowerThreshold: Number(event.target.value) })}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default TickForm;
