import { StrategyGroup } from '../../../types';

export interface StrategyGroupState {
  loaded: boolean;
  error?: string;
  strategyGroups: StrategyGroup[];
  ui: {
    selectedStrategyGroup?: StrategyGroup;
  };
}

export const initalStrategyGroupState: StrategyGroupState = {
  loaded: false,
  error: undefined,
  strategyGroups: [],
  ui: {
    selectedStrategyGroup: undefined
  }
};
