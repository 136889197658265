import React, { FunctionComponent } from 'react';

import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { GlobalState } from '../../../core/root';
import { Security, Subscription } from '../../../types';
import { formatUsd } from '../../shared/utils/currency-utils';
import { setThousenSeparator, setDecimalSpaces } from '../../shared/utils/number-utils';
import { setSecurityPicked } from '../store/action-creators';
import { getProductCategoryName } from '../utils/product-category-utils';

export interface SecurityListProps extends RouteComponentProps {
  securities: Security[];
  subscriptions: Subscription[];
}

const SecurityList: FunctionComponent<SecurityListProps> = ({ securities, subscriptions, history, match }: SecurityListProps) => {
  const dispatch = useDispatch();
  const showArchived = useSelector((state: GlobalState) => state.securityState.ui.showArchived);
  const productTypes = useSelector((state: GlobalState) => state.securityState.ui.selectedProductTypes);

  // const loaded = useSelector((x: GlobalState) => x.userState.loaded);
  // useEffect(() => {
  //   if (!loaded) {
  //     console.log("Inital load of User from SecurityList..");
  //     dispatch(loadUserAsync());
  //   }
  // }, [loaded]);

  const header = (
    <tr>
      <th className="middle small">Name</th>
      <td className="middle small">Symbol</td>
      <td className="middle small">Exchange</td>
      <td className="middle small">Product</td>
      <td className="middle small">Currency</td>

      <td className="middle text-right small">Multiplier</td>
      <td className="middle text-right small">Tick Increment</td>
      <td className="middle text-right small">Tick Value</td>
    </tr>
  );

  const rowClickHandler = (security: Security) => {
    dispatch(setSecurityPicked(security));
    history.push(`${match.url}/${security.id}`);
  };

  const getTickValue = (security: Security) => {
    return formatUsd(security.tickIncrement * security.multiplier, 2);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getSubscriptionColumns = (security: Security) => {
    const scheduled = subscriptions.filter((x) => x.securityId === security.id && x.active);
    const live = scheduled.filter((x) => !x.simulated);
    const simulated = scheduled.filter((x) => x.simulated);
    return (
      <>
        <td className="middle small text-center vborder">{scheduled.length}</td>
        <td className="middle small text-center">{simulated.length}</td>
        <td className={classNames('middle', 'small', 'text-center', { 'text-success': live.length > 0 })}>{live.length}</td>
      </>
    );
  };

  const body = securities
    .filter((x) => {
      if (productTypes.length === 0) {
        return x;
      } else {
        return productTypes.indexOf(x.productType) !== -1;
      }
    })
    .filter((x) => {
      if (showArchived === true) {
        return x;
      } else {
        return x.archived === false;
      }
    })
    .sort((a: Security, b: Security) => {
      if (a.name > b.name) {
        return 1;
      } else if (b.name > a.name) {
        return -1;
      }
      return 0;
    })
    .map((security: Security) => (
      <tr key={security.id} onClick={() => rowClickHandler(security)} className="clickable">
        <th className="middle small">{security.name}</th>
        <td className="middle small">{security.symbol}</td>
        <td className="middle small">{security.exchange}</td>
        <td className="middle small">{getProductCategoryName(security.productType)}</td>
        <td className="middle small">{security.currency}</td>

        <td className="middle small text-right vborder">{setThousenSeparator(security.multiplier)}</td>
        <td className="middle small text-right">{setDecimalSpaces(security.tickIncrement, 6)}</td>
        <td className="middle small text-right">{getTickValue(security)}</td>
      </tr>
    ));

  return (
    <div className="scrolling outer gap-bottom-20">
      <div className="inner">
        <table className="table table-hover table-striped table-condensed">
          <thead>{header}</thead>
          <tbody>{body}</tbody>
        </table>
      </div>
    </div>
  );
};

export default withRouter(SecurityList);
