import { AnyAction } from 'redux';

import { Trade } from '../../../../types';

import * as actionTypes from './action-types';
import { initalPositionTradeMapState, initalPositionTradeState } from './state';

export const positionTradeReducer = (state = initalPositionTradeMapState, action: AnyAction) => {
  const key = action.positionId;
  const current = state.get(key) || { ...initalPositionTradeState };

  if (action.type === actionTypes.SET_POSITION_TRADES_LOADED) {
    const loaded = action.loaded === true;
    return state.set(key, {
      ...current,
      loaded
    });
  } else if (action.type === actionTypes.SET_POSITION_TRADES_ERROR) {
    return state.set(key, {
      ...current,
      loaded: true,
      error: action.error
    });
  } else if (action.type === actionTypes.SET_POSITION_TRADES) {
    const trades = action.trades as Trade[];
    return state.set(key, {
      loaded: true,
      error: undefined,
      trades
    });
  } else if (action.type === actionTypes.ADD_POSITION_TRADE) {
    const trades = [...current.trades];
    const trade = action.trade as Trade;
    trades.push(trade);
    return state.set(key, {
      ...current,
      loaded: true,
      trades
    });
  }

  return state;
};
