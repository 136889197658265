import { Period, Subscription } from '../../../types';

const sortByActivation = (a: Subscription, b: Subscription) => {
  if (a.active && !b.active) return -1;
  if (b.active && !a.active) return 1;
  return 0;
};

const sortByStrategy = (a: Subscription, b: Subscription) => {
  const aStrategyName = a.strategy?.name;
  const bStrategyName = b.strategy?.name;
  if (!aStrategyName || !bStrategyName) return 0;
  if (aStrategyName > bStrategyName) return 1;
  if (aStrategyName < bStrategyName) return -1;
  return 0;
};

const sortByAliasOrSecurityName = (a: Subscription, b: Subscription) => {
  const aName = a?.alias || a.security?.name;
  const bName = b?.alias || b.security?.name;
  if (!aName || !bName) return 0;
  if (aName > bName) return 1;
  if (aName < bName) return -1;
  return 0;
};

export const sortSubscriptions = (a: Subscription, b: Subscription) => {
  if (!a.security || !a.strategy) {
    return 0;
  }
  let sort = sortByAliasOrSecurityName(a, b);
  if (sort !== 0) {
    return sort;
  }
  sort = sortByStrategy(a, b);
  if (sort !== 0) {
    return sort;
  }
  return sortByActivation(a, b);
};

export interface SubscriptionWrapper {
  period: Period;
  subscription: Subscription;
}

export const sortPeriodsWithSubscriptions = (a: SubscriptionWrapper, b: SubscriptionWrapper) => {
  return sortSubscriptions(a.subscription, b.subscription);
};
