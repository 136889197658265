import * as React from 'react';

import { Indicator } from '../../../../types';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const PullbackForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label htmlFor="pb_1_periods" className="col-sm-6 control-label">
          1st EMA Periods
        </label>
        <div className="col-sm-6">
          <input
            id="pb_1_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.periods}
            onChange={(event) => update({ periods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="pb_2_periods" className="col-sm-6 control-label">
          2nd EMA Periods
        </label>
        <div className="col-sm-6">
          <input
            id="pb_2_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.secondaryPeriods}
            onChange={(event) => update({ secondaryPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="section">
        <hr />
        <p>Settings</p>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="pb_gap_periods" className="col-sm-6 control-label">
          Gap Periods
        </label>
        <div className="col-sm-6">
          <input
            id="pb_gap_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.bounceProtectionPeriods}
            onChange={(event) => update({ bounceProtectionPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="pb_history_periods" className="col-sm-6 control-label">
          History Periods
        </label>
        <div className="col-sm-6">
          <input
            id="pb_history_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.basisPeriods}
            onChange={(event) => update({ basisPeriods: Number(event.target.value) })}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PullbackForm;
