import * as React from 'react';

import { Switch, Route } from 'react-router-dom';

import PositionListScreen from './components/PositionListScreen';
import PositionScreen from './components/PositionScreen';

export const positionModulePath = '/positions';

const render = () => {
  return (
    <Switch>
      <Route exact path={positionModulePath + '/:positionId'} component={PositionScreen}></Route>
      <Route exact path={positionModulePath} component={PositionListScreen}></Route>
    </Switch>
  );
};

export default {
  routeProps: {
    path: positionModulePath,
    render
  },
  name: 'Position'
};
