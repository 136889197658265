import * as React from 'react';

import { Modal, Button } from 'react-bootstrap';

import { ContractEntity } from '../../../../types';
import { toDateMonthYear } from '../../../shared/utils/date-format-utils';

export interface Props {
  contract: ContractEntity;
  show: boolean;
  close(): void;
}

export const DeliveryMonthDialog = ({ contract, show, close }: Props) => {
  return (
    <Modal show={show} bsSize="small" onHide={close} aria-labelledby="contained-modal-title">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title">
          <span>Futures Contract</span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="row form-horizontal">
          <div className="col-xs-12 gap-top-10">
            <div className="section">
              <p>Results</p>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered table-striped table-condensed">
                <tbody>
                  <tr>
                    <td className="middle small text-left">Name</td>
                    <td className="middle small text-right">{contract?.security.name}</td>
                  </tr>
                  <tr>
                    <td className="middle small text-left">Local Symbol</td>
                    <td className="middle small text-right">{contract?.localSymbol}</td>
                  </tr>
                  <tr>
                    <td className="middle small text-left">Year</td>
                    <td className="middle small text-right" width="100px">
                      {contract.year}
                    </td>
                  </tr>
                  <tr>
                    <td className="middle small text-left">Delivery Month</td>
                    <td className="middle small text-right" width="100px">
                      {contract.contractMonth}
                    </td>
                  </tr>
                  <tr>
                    <td className="middle small text-left">First Trade Date</td>
                    <td className="middle small text-right">{toDateMonthYear(contract.firstTradeDate)}</td>
                  </tr>
                  <tr>
                    <td className="middle small text-left">Roll Over Date</td>
                    <td className="middle small text-right">{toDateMonthYear(contract.rolloverDate)}</td>
                  </tr>
                  <tr>
                    <td className="middle small text-left">First Notice Date</td>
                    <td className="middle small text-right">{toDateMonthYear(contract.firstNoticeDate)}</td>
                  </tr>
                  <tr>
                    <td className="middle small text-left">Last Trade Date</td>
                    <td className="middle small text-right">{toDateMonthYear(contract.lastTradeDate)}</td>
                  </tr>
                  <tr>
                    <td className="middle small text-left">Num. Trade Months</td>
                    <td className="middle small text-right">{contract.numberOfTradeMonths}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={close}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
