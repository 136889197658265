import * as React from 'react';
import { useState, useEffect } from 'react';

import { Panel, OverlayTrigger, Popover } from 'react-bootstrap';
import { createUseStyles, useTheme } from 'react-jss';

import checkedLaggingSvg from '../../../../assets/svg/checked_lagging.svg';
import checkedLeadingSvg from '../../../../assets/svg/checked_leading.svg';
import { Theme } from '../../../../core/jss/theme';

const useStyles = createUseStyles(
  (theme: Theme) => ({
    titleWrapper: {
      '& :link': { textDecoration: 'none' },
      '& :hover': { color: 'gold', textDecoration: 'none' },
      '& :visited': { textDecoration: 'none' },
      display: 'flex',
      flexFlow: 'row nowrap'
    },
    upper: {
      letterSpacing: '1px',
      textTransform: 'uppercase'
    },
    leading: {
      color: theme.palette.brandDanger,
      '&:hover': {
        color: theme.palette.brandDangerDarker
      }
    },
    lagging: {
      color: '#6AC259',
      '&:hover': {
        color: '#479737'
      }
    },
    title: {
      flex: '0 1 auto',
      fontSize: theme.font.size.lg
    }
  }),
  { name: 'CollapsePanel' }
);

export interface Props {
  title: JSX.Element | string;
  smallTitle?: string;
  subTitle?: string;
  subTitles?: string[];
  checked?: boolean;
  tooltip?: string;
  upper?: boolean;
  collapsed?: boolean;
  onToggle?(collapsed: boolean): void;
  onClick?(): void;
  leading?: boolean;
  lagging?: boolean;
  bsStyle?: string | 'default' | 'primary' | 'success' | 'info' | 'warning' | 'danger';
  className?: string;
  children?: React.ReactNode;
}

export interface CollapsePanelState {
  collapsed: boolean;
}

const CollapsePanel: React.FunctionComponent<Props> = ({
  title,
  smallTitle,
  subTitle,
  subTitles,
  checked,
  tooltip,
  upper,
  collapsed: initialCollapsed,
  onToggle,
  onClick,
  leading,
  lagging,
  bsStyle,
  className,
  children
}: Props) => {
  const theme: Theme = useTheme();
  const classes = useStyles({ theme });

  const [collapsed, setCollapsed] = useState(initialCollapsed);
  const toggleHandler = () => {
    if (onToggle) {
      onToggle(!collapsed);
    } else {
      setCollapsed(!collapsed);
    }
  };

  useEffect(() => {
    setCollapsed(initialCollapsed);
  }, [initialCollapsed]);

  const cx = [classes.title];
  if (upper) {
    cx.push(classes.upper);
  }
  if (leading) {
    cx.push(classes.leading);
  }
  if (lagging) {
    cx.push(classes.lagging);
  }

  const tooltipPopover = tooltip ? <Popover id="tooltipPopover">{tooltip}</Popover> : '';

  return (
    <Panel bsStyle={bsStyle} expanded={!collapsed} onClick={onClick} onToggle={toggleHandler} className={className}>
      <Panel.Heading>
        <div className={classes.titleWrapper}>
          <Panel.Title toggle className="width_100">
            <div className={cx.join(' ')}>
              {title}
              {smallTitle && (
                <span>
                  &nbsp;<small>{smallTitle}</small>
                </span>
              )}
            </div>
          </Panel.Title>
          {subTitle && <span className="label label-default custom-label">{subTitle}</span>}
          {subTitles && (
            <div className={classes.title}>
              {subTitles.map((x) => (
                <span key={x} className="label label-default custom-label">
                  {x}
                </span>
              ))}
            </div>
          )}
          {checked === true && leading === true && (
            <div className={classes.title}>
              <img src={checkedLeadingSvg} width="14px" alt="Enabled" />
            </div>
          )}
          {checked === true && leading !== true && (
            <div className={classes.title}>
              <img src={checkedLaggingSvg} width="14px" alt="Enabled" />
            </div>
          )}
          {!!tooltip && (
            <OverlayTrigger placement="left" overlay={tooltipPopover}>
              <i className="fa fa-exclamation-circle fa-lg grey" />
            </OverlayTrigger>
          )}
        </div>
      </Panel.Heading>
      <Panel.Collapse>
        <Panel.Body>{children}</Panel.Body>
      </Panel.Collapse>
    </Panel>
  );
};

export default CollapsePanel;
