import { ScheduleStatus } from '../../../../types';
import axiosApi from '../../../shared/axios-api';

export class ScheduleStatusHttpRequest {
  public static getStatus() {
    return axiosApi.get<ScheduleStatus>(`/schedule/status`);
  }

  public static toggleRunSchedule() {
    return axiosApi.post<ScheduleStatus>(`/schedule/toggle`);
  }
}
