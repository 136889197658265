import { Indicator } from '../../../types';

import * as actionTypes from './action-types';

export const setIndicators = (strategyId: number, indicators: Indicator[]) => {
  return {
    type: actionTypes.SET_INDICATORS,
    strategyId,
    indicators
  };
};

export const setIndicatorsError = (strategyId: number, error: string) => {
  return {
    type: actionTypes.SET_INDICATORS_ERROR,
    strategyId,
    error
  };
};

export const clearIndicatorsError = (strategyId: number) => {
  return {
    type: actionTypes.CLEAR_INDICATORS_ERROR,
    strategyId
  };
};

export const addIndicator = (indicator: Indicator) => {
  return {
    type: actionTypes.ADD_INDICATOR,
    strategyId: indicator.strategyId,
    indicator
  };
};

export const updateIndicator = (indicator: Indicator) => {
  return {
    type: actionTypes.UPDATE_INDICATOR,
    strategyId: indicator.strategyId,
    indicator
  };
};

export const removeIndicator = (indicator: Indicator) => {
  return {
    type: actionTypes.REMOVE_INDICATOR,
    strategyId: indicator.strategyId,
    indicator
  };
};
