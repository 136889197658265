import * as React from 'react';
import { useState } from 'react';

import { Nav, Navbar } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import AccountDialog from '../../../modules/account/components/AccountDialog';
import StatusToolbar from '../../../modules/status/components/StatusToolbar';
import { GlobalState } from '../../root';

import Menu from './Menu';

const useStyles = createUseStyles({
  navbar: {
    composes: ['navbar-dark'],
    borderRadius: '0px',
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
  }
});

const Header = () => {
  const title = 'Nighthawk';

  const isAuthenticated = useSelector((state: GlobalState) => !!state.authState.token);

  const [showAccountDialog, setShowAccountDialog] = useState(false);

  const toggleShowAccountDialog = () => {
    setShowAccountDialog(!showAccountDialog);
  };

  const classes = useStyles();

  const navBarCollapse = <Navbar.Collapse>{isAuthenticated ? <Menu /> : <Nav></Nav>}</Navbar.Collapse>;

  const accountDialog =
    isAuthenticated && showAccountDialog ? <AccountDialog show={showAccountDialog} toggle={toggleShowAccountDialog} /> : undefined;

  return (
    <React.Fragment>
      {isAuthenticated && <StatusToolbar />}
      <Navbar className={classes.navbar} expand="md">
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/" className="ripple">
              {title}
            </Link>
            {/* <Link to="/" className="ripple" onClick={() => window.location.reload()}>{title}</Link> */}
            {/* <Link to="/" className="ripple" onClick={() => window.location.replace("/")}>{title}</Link> */}
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        {navBarCollapse}
      </Navbar>
      {accountDialog}
    </React.Fragment>
  );
};

export default Header;
