import { Period } from '../../../../types';

import * as actionTypes from './action-types';

export const setPeriod = (period: Period) => {
  return {
    type: actionTypes.SET_PERIOD,
    payload: period
  };
};

export const setPeriodLoading = (periodId: number, loading: boolean) => {
  return {
    type: actionTypes.SET_PERIOD_LOADING,
    periodId,
    value: loading
  };
};

export const setPeriodError = (periodId: number, error: string) => {
  return {
    type: actionTypes.SET_PERIOD_ERROR,
    periodId,
    error
  };
};
