import { ContractPartialQuote, Indicator, Strategy } from '../../../types';

export const hasQuotesForBacktesting = (strategy: Strategy, indicators: Indicator[], partialQuotes: ContractPartialQuote[]): boolean => {
  if (strategy) {
    const barSizes = indicators?.filter((x) => x.enabled).map((x) => x.barSize) || [];
    barSizes.push(strategy.periodBarSize);
    const distinctBarSizes = barSizes.filter((x, index, self) => self.indexOf(x) === index);
    let hasQuotes = true;
    for (let i = 0; i < distinctBarSizes.length; i++) {
      const quotesExists = partialQuotes.find((y) => y.barSize === distinctBarSizes[i] && y.numQuotes > 0);
      if (!quotesExists) {
        hasQuotes = false;
        break;
      }
    }
    return hasQuotes;
  }
  return false;
};
