import { BacktestMapState, BacktestState, initalBacktestState } from './state';

export interface PartialState {
  backtestMapState: BacktestMapState;
}

export const getBacktestState = (state: PartialState, securityId: number, strategyId: number): BacktestState => {
  const key = `${securityId}-${strategyId}`;
  return state.backtestMapState.get(key) || initalBacktestState;
};

export const getError = (state: PartialState, securityId: number, strategyId: number): string | undefined => {
  return getBacktestState(state, securityId, strategyId).error;
};

export const getLoading = (state: PartialState, securityId: number, strategyId: number) => {
  return getBacktestState(state, securityId, strategyId).loading;
};

export const getLoaded = (state: PartialState, securityId: number, strategyId: number) => {
  return getBacktestState(state, securityId, strategyId).loaded;
};

export const getBacktests = (state: PartialState, securityId: number, strategyId: number) => {
  return getBacktestState(state, securityId, strategyId).backtests;
};
