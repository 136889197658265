import * as React from 'react';

import { OverlayTrigger, Popover } from 'react-bootstrap';

import { Indicator } from '../../../../types';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const TheFoxForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  const smaPeriodsPopover = (
    <Popover id="smaPeriodsPopover">
      The number of periods for the {'"'}slow{'"'} SMA
    </Popover>
  );
  const emaPeriodsPopover = (
    <Popover id="emaPeriodsPopover">
      The number of periods for the {'"'}fast{'"'} EMA
    </Popover>
  );
  const emaSmoothingPopover = <Popover id="emaSmoothingPopover">The number of periods for smoothing the EMA.</Popover>;

  const pastPeriodsPopover = <Popover id="pastPeriodsPopover">The look-back number of periods.</Popover>;

  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label htmlFor="fox_sma" className="control-label col-sm-6">
          <span>SMA Periods (d: 5)</span>
          <OverlayTrigger placement="top" overlay={smaPeriodsPopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg info-text" />
            </span>
          </OverlayTrigger>
        </label>
        <div className="col-sm-6">
          <input
            id="fox_sma"
            type="number"
            step="1"
            className="form-control"
            value={indicator.periods}
            onChange={(event) => update({ periods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="fox_ema" className="col-sm-6 control-label">
          <span>EMA Periods (d: 6)</span>
          <OverlayTrigger placement="top" overlay={emaPeriodsPopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg info-text" />
            </span>
          </OverlayTrigger>
        </label>
        <div className="col-sm-6">
          <input
            id="fox_ema"
            type="number"
            step="1"
            className="form-control"
            value={indicator.secondaryPeriods}
            onChange={(event) => update({ secondaryPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="fox_ema_smoothing" className="col-sm-6 control-label">
          <span>EMA Smoothing (d: 3)</span>
          <OverlayTrigger placement="top" overlay={emaSmoothingPopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg info-text" />
            </span>
          </OverlayTrigger>
        </label>
        <div className="col-sm-6">
          <input
            id="fox_ema_smoothing"
            type="number"
            step="1"
            className="form-control"
            value={indicator.bbPeriods}
            onChange={(event) => update({ bbPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="fox_past" className="col-sm-6 control-label">
          <span>Past Periods (d: 80)</span>
          <OverlayTrigger placement="top" overlay={pastPeriodsPopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg info-text" />
            </span>
          </OverlayTrigger>
        </label>
        <div className="col-sm-6">
          <input
            id="fox_past"
            type="number"
            step="1"
            className="form-control"
            value={indicator.basisPeriods}
            onChange={(event) => update({ basisPeriods: Number(event.target.value) })}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default TheFoxForm;
