import * as React from 'react';
import { useState, useEffect } from 'react';

import classNames from 'classnames';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useSelector, useDispatch } from 'react-redux';

import { GlobalState } from '../../../../core/root';
import { BarSize, GraphRequest, GraphQuote, ContractEntity, GraphSettings } from '../../../../types';
import Spinner from '../../../shared/components/Spinner';
import { toMonthTime } from '../../../shared/utils/date-format-utils';
import { convertToDate } from '../../../shared/utils/date-utils';
import { getGraphConfigState, GraphConfigState } from '../../store/graph-config/state';
import * as graphActionCreators from '../../store/graph-data/action-creators';
import { getData, getError, getLoaded } from '../../store/graph-data/state';

import AroonGraph from './AroonGraph';
import AverageDirectionalIndexGraph from './AverageDirectionalIndexGraph';
import AverageTrueRangeGraph from './AverageTrueRangeGraph';
import CandleStickGraph from './CandleStickGraph';
import OscillatorGraph from './OscillatorGraph';
import VolumeGraph from './VolumeGraph';

const useStyles = createUseStyles({
  navTitle: {
    display: 'inline-block',
    marginBottom: '1rem',
    fontSize: '12px',
    color: '#959595',
    textTransform: 'uppercase',
    letterSpacing: '1px'
  }
});

export interface GraphProps {
  guid: string;
  contract: ContractEntity;
  barSize: BarSize;
  lastBarTime: Date;
  contractPartialId?: number;
  strategyId?: number;
  partialPeriodStart?: Date;
  partialPeriodEnd?: Date;
  expanded?: boolean;
}

const Graph: React.FunctionComponent<GraphProps> = ({
  guid,
  contract,
  barSize,
  lastBarTime,
  contractPartialId,
  strategyId,
  partialPeriodStart,
  partialPeriodEnd,
  expanded
}) => {
  const dispatch = useDispatch();

  const graphData: GraphQuote[] = useSelector((state: GlobalState) => getData(state, guid)) as GraphQuote[];
  const graphDataLoaded: boolean | undefined = useSelector((state: GlobalState) => getLoaded(state, guid));
  const graphDataError: string | undefined = useSelector((state: GlobalState) => getError(state, guid));
  const graphConfigState: GraphConfigState = useSelector((state: GlobalState) => getGraphConfigState(barSize, state)) as GraphConfigState;

  const [barsAddedBackward, setBarsAddedBackward] = useState(0);
  const [barsAddedForward, setBarsAddedForward] = useState(0);
  const [navBarStepBackward, setNavBarStepBackward] = useState(1);
  const [navBarStepForward, setNavBarStepForward] = useState(1);
  const navBarStepOptions = [1, 5, 10, 20, 30, 60];

  // the default number of bars before adding bars
  const numberOfBars = 30;

  const createGraphRequest = () => {
    const settings = { ...graphConfigState } as GraphSettings;
    return {
      contract,
      barSize: barSize || BarSize._15_MIN,
      lastBarTime,
      contractPartialId: contractPartialId || 0,
      strategyId: strategyId || 0,
      numberOfBars,
      barsAddedBackward,
      barsAddedForward,
      settings
    } as GraphRequest;
  };

  const fetchGraphData = () => {
    dispatch(graphActionCreators.setGraphDataLoaded(guid, false));
    const request = createGraphRequest();
    dispatch(graphActionCreators.loadGraphDataAsync(guid, request));
  };

  useEffect(() => {
    // graphDataLoaded = do not update on mount
    if (graphDataLoaded) {
      fetchGraphData();
    }
  }, [barsAddedBackward, barsAddedForward]);

  useEffect(() => {
    fetchGraphData();
  }, [graphConfigState]);

  const classes = useStyles();

  const loading = !graphDataLoaded;

  if (graphDataError) {
    return null;
  }

  const fromPeriod = (dt: Date) => {
    const outsidePeriod = partialPeriodStart && convertToDate(partialPeriodStart) > convertToDate(dt);
    return <span className={classNames({ 'danger-text2': outsidePeriod })}>{toMonthTime(dt)}</span>;
  };

  const toPeriod = (dt: Date) => {
    const outsidePeriod = partialPeriodEnd && convertToDate(partialPeriodEnd) < convertToDate(dt);
    return <span className={classNames({ 'danger-text2': outsidePeriod })}>{toMonthTime(dt)}</span>;
  };

  const timePeriod =
    graphData && graphData.length > 0 ? (
      <span className={classes.navTitle}>
        Period: {fromPeriod(graphData[0].closeTime)} - {toPeriod(graphData[graphData.length - 1].closeTime)}
      </span>
    ) : undefined;

  return (
    <div>
      {loading && <Spinner />}

      <div>
        <CandleStickGraph graphData={graphData} barSize={barSize} graphConfigState={graphConfigState} expanded={expanded} />

        <VolumeGraph
          graphData={graphData}
          barSize={barSize}
          showVolumeGraph={graphConfigState.showVolumeGraph}
          showVolumeAvg={graphConfigState.showVolumeAvg}
          expanded={expanded}
        />

        <OscillatorGraph graphData={graphData} barSize={barSize} graphConfigState={graphConfigState} expanded={expanded} />

        <AverageTrueRangeGraph graphData={graphData} barSize={barSize} graphConfigState={graphConfigState} expanded={expanded} />

        <AverageDirectionalIndexGraph graphData={graphData} barSize={barSize} showAdx={graphConfigState.showAdx} expanded={expanded} />

        <AroonGraph graphData={graphData} barSize={barSize} showAroon={graphConfigState.showAroon} expanded={expanded} />
      </div>

      <div className="row">
        <div className="col-xs-12 text-center">{timePeriod}</div>
        <div className="col-xs-12">
          <div className="btn-group btn-group-justified" role="group">
            <div className="btn-group btn-group-sm" role="group">
              <button
                type="button"
                className="btn btn-default"
                onClick={() => setBarsAddedBackward(barsAddedBackward + navBarStepBackward)}
              >
                <span className="glyphicon glyphicon glyphicon-backward warning-text2" aria-hidden="true"></span>
              </button>
            </div>
            <DropdownButton
              className="btn btn-default btn-sm dropdown-toggle"
              bsStyle={'default'}
              id={`select-bars-backward`}
              title={navBarStepBackward}
            >
              {navBarStepOptions.map((num) => (
                <MenuItem key={num} eventKey={num} onClick={() => setNavBarStepBackward(num)}>
                  {num}
                </MenuItem>
              ))}
            </DropdownButton>
            <div className="btn-group btn-group-sm" role="group">
              <button
                type="button"
                className="btn btn-default"
                onClick={() => setBarsAddedBackward(barsAddedBackward - navBarStepBackward)}
              >
                <span className="glyphicon glyphicon glyphicon-forward warning-text2" aria-hidden="true"></span>
              </button>
            </div>
            <div className="btn-group btn-group-sm" role="group">
              <button type="button" className="btn btn-default" onClick={() => setBarsAddedForward(barsAddedForward - navBarStepForward)}>
                <span className="glyphicon glyphicon glyphicon-backward success-text" aria-hidden="true"></span>
              </button>
            </div>
            <DropdownButton
              className="btn btn-default btn-sm dropdown-toggle"
              bsStyle={'default'}
              id={`select-bars-backward`}
              title={navBarStepForward}
            >
              {navBarStepOptions.map((num) => (
                <MenuItem key={num} eventKey={num} onClick={() => setNavBarStepForward(num)}>
                  {num}
                </MenuItem>
              ))}
            </DropdownButton>
            <div className="btn-group btn-group-sm" role="group">
              <button type="button" className="btn btn-default" onClick={() => setBarsAddedForward(barsAddedForward + navBarStepForward)}>
                <span className="glyphicon glyphicon glyphicon-forward success-text" aria-hidden="true"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Graph;
