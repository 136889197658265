import { toISOString } from './date-format-utils';
import { convertToDate } from './date-utils';

export const trimTimePartFromDate = (dt: Date | string | undefined) => {
  const dateTime = dt instanceof Date ? dt : convertToDate(dt);
  if (!dateTime) {
    return;
  }
  const date = new Date(dateTime.toDateString());
  return toISOString(date);
};

export const addDays = (dtString: string | undefined, days: number) => {
  const dateTime = convertToDate(dtString);
  if (!dateTime) {
    return;
  }
  dateTime.setDate(dateTime.getDate() + days);
  const dt = new Date(dateTime.toDateString());
  return toISOString(dt);
};

export const addMonths = (dtString: string | undefined, months: number) => {
  const dateTime = convertToDate(dtString);
  if (!dateTime) {
    return;
  }
  dateTime.setMonth(dateTime.getMonth() + months);
  const dt = new Date(dateTime.toDateString());
  return toISOString(dt);
};
