import * as React from 'react';

import { OverlayTrigger, Popover } from 'react-bootstrap';

import { Indicator } from '../../../../types';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const ThunnelForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  const thunnelPeriodsPopover = <Popover id="thunnelPeriodsPopover">The thunnel length in number of periods</Popover>;
  const numTicksPopover = <Popover id="numTicksPopover">The thunnel width in number of ticks</Popover>;

  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label htmlFor="thunnel_periods" className="control-label col-sm-6">
          <span>Thunnel Periods</span>
          <OverlayTrigger placement="top" overlay={thunnelPeriodsPopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg info-text" />
            </span>
          </OverlayTrigger>
        </label>
        <div className="col-sm-6">
          <input
            id="thunnel_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.periods}
            onChange={(event) => update({ periods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="thunnel_ticks" className="col-sm-6 control-label">
          <span>Ticks</span>
          <OverlayTrigger placement="top" overlay={numTicksPopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg info-text" />
            </span>
          </OverlayTrigger>
        </label>
        <div className="col-sm-6">
          <input
            id="thunnel_ticks"
            type="number"
            step="1"
            className="form-control"
            value={indicator.upperThreshold}
            onChange={(event) => update({ upperThreshold: Number(event.target.value) })}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ThunnelForm;
