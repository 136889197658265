import React, { FunctionComponent, useState } from 'react';

import { useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Switch from 'react-switch';
import TimePicker from 'react-time-picker';

import { Security, ProductType } from '../../../../types';
import CollapsePanel from '../../../shared/components/CollapsePanel';
import { ConfirmDialog } from '../../../shared/components/ConfirmDialog';
import { formatUsd } from '../../../shared/utils/currency-utils';
import { currencyTypes } from '../../constants/currency-types';
import { exchanges } from '../../constants/exchanges';
import { securityModulePath } from '../../routes';
import * as actionCreators from '../../store/action-creators';

interface GeneralTabProps extends RouteComponentProps {
  security: Security;
}

const GeneralTab: FunctionComponent<GeneralTabProps> = ({ security, history }: GeneralTabProps) => {
  const [securityState, setSecurityState] = useState(security);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const dispatch = useDispatch();
  const updateSecurity = (security: Security) => dispatch(actionCreators.updateSecurityAsync(security));
  const removeSecurity = (id: number) => dispatch(actionCreators.removeSecurityAsync(id));

  const mapFromState = (sec: Security): Security => {
    return {
      ...security,
      ...sec
    };
  };

  const navigateToList = () => {
    const url = `${securityModulePath}`;
    history.push(url);
  };

  const updateSecurityHandler = () => {
    const item = mapFromState(securityState);
    updateSecurity(item);
    // this.navigateToList();
  };

  const deleteSecurityHandler = () => {
    setShowConfirmDialog(false);
    removeSecurity(security.id);
    navigateToList();
  };

  const updateSecurityState = (obj: object) => {
    const field = Object.keys(obj)[0];
    const item = {
      ...securityState,
      [field]: obj[field]
    };
    setSecurityState(item);
  };

  const calculateTickValue = () => {
    const { tickIncrement, multiplier } = securityState;
    return formatUsd(Number(tickIncrement) * Number(multiplier), 2);
  };

  const onRthOpenChange = (marketOpen) => {
    updateSecurityState({ marketOpen });
  };

  const onRthCloseChange = (marketClosed) => {
    updateSecurityState({ marketClosed });
  };

  const onAfterHoursOpenChange = (marketAfterHoursOpen) => {
    updateSecurityState({ marketAfterHoursOpen });
  };

  const onAfterHoursCloseChange = (marketAfterHoursClosed) => {
    updateSecurityState({ marketAfterHoursClosed });
  };

  const {
    name,
    productType,
    notes,
    archived,
    symbol,
    currency,
    tickIncrement,
    multiplier,
    quotePriceMultiplier,
    marketOpen,
    marketClosed,
    marketAfterHoursOpen,
    marketAfterHoursClosed,
    exchange
  } = securityState;

  const productTypeOptions: JSX.Element[] = [];
  for (const item in ProductType) {
    if (Number(item)) {
      const productType: ProductType = Number(item);
      const title = ProductType[productType].replace('_', ' ');
      productTypeOptions.push(
        <option key={item} value={item}>
          {title}
        </option>
      );
    }
  }

  const currencyTypeOptions = currencyTypes
    ? currencyTypes.map((x) => (
        <option key={x.key} value={x.key}>
          {x.name}
        </option>
      ))
    : [];

  return (
    <div>
      <div className="row form-horizontal">
        <div className="col-md-6">
          <CollapsePanel title="Ticker">
            <div className="form-group form-group-sm">
              <label htmlFor="sec_symbol" className="col-sm-4 control-label">
                Symbol
              </label>
              <div className="col-sm-8">
                <input
                  id="sec_symbol"
                  type="text"
                  className="form-control"
                  value={symbol || ''}
                  onChange={(event) => updateSecurityState({ symbol: event.target.value })}
                />
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="sec_exchange" className="col-sm-4 control-label">
                Exchange
              </label>
              <div className="col-sm-8">
                <select
                  id="sec_exchange"
                  className="form-control"
                  value={exchange}
                  onChange={(event) => updateSecurityState({ exchange: event.target.value })}
                >
                  <option value="">Velg..</option>
                  {exchanges.map((x) => (
                    <option key={x.key} value={x.key} title={x.name}>
                      {x.key}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="sec_currency" className="col-sm-4 control-label">
                Currency
              </label>
              <div className="col-sm-8">
                <select
                  id="sec_currency"
                  className="form-control"
                  value={currency || ''}
                  onChange={(event) => updateSecurityState({ currency: event.target.value })}
                >
                  {currencyTypeOptions}
                </select>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="sec_multiplier" className="col-sm-4 control-label">
                Multiplier
              </label>
              <div className="col-sm-8">
                <input
                  id="sec_multiplier"
                  type="number"
                  step="1"
                  className="form-control"
                  value={multiplier}
                  onChange={(event) => updateSecurityState({ multiplier: Number(event.target.value) })}
                />
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="sec_incr" className="col-sm-4 control-label">
                Tick Increment
              </label>
              <div className="col-sm-8">
                <input
                  id="sec_incr"
                  type="number"
                  step="any"
                  className="form-control"
                  value={tickIncrement}
                  onChange={(event) => updateSecurityState({ tickIncrement: Number(event.target.value) })}
                />
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="sec_tick_val" className="col-sm-4 control-label">
                Tick Value
              </label>
              <div className="col-sm-8">
                <input id="sec_tick_val" type="text" step="any" className="form-control" value={calculateTickValue()} disabled={true} />
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="sec_quote_mult" className="col-sm-4 control-label">
                Price Multiplier
              </label>
              <div className="col-sm-8">
                <input
                  id="sec_quote_mult"
                  type="number"
                  step="1"
                  className="form-control"
                  value={quotePriceMultiplier}
                  onChange={(event) => updateSecurityState({ quotePriceMultiplier: Number(event.target.value) })}
                />
              </div>
            </div>
          </CollapsePanel>
        </div>

        <div className="col-md-6">
          <CollapsePanel title="Details">
            <div className="form-group form-group-sm">
              <label htmlFor="sec_name" className="col-sm-4 control-label">
                Name
              </label>
              <div className="col-sm-8">
                <input
                  id="sec_name"
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(event) => updateSecurityState({ name: event.target.value })}
                />
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="sec_product" className="col-sm-4 control-label">
                Product
              </label>
              <div className="col-sm-8">
                <select
                  id="sec_product"
                  className="form-control"
                  value={productType || ''}
                  onChange={(event) => updateSecurityState({ productType: Number(event.target.value) })}
                >
                  {productTypeOptions}
                </select>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="sec_notes" className="col-sm-4 control-label">
                Notes
              </label>
              <div className="col-sm-8">
                <textarea
                  id="sec_notes"
                  className="form-control"
                  rows={4}
                  value={notes || ''}
                  onChange={(event) => updateSecurityState({ notes: event.target.value })}
                />
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label className="col-sm-4 control-label">Archived</label>
              <div className="col-sm-8">
                <label className="react-switch react-switch--sm">
                  <Switch onChange={() => updateSecurityState({ archived: !archived })} checked={archived} />
                </label>
              </div>
            </div>
          </CollapsePanel>
          <CollapsePanel title="Market Hours">
            <div className="form-group form-group-sm">
              <label htmlFor="sec_rth_open" className="col-sm-6 control-label">
                Regular Hours Open
              </label>
              <div className="col-sm-6">
                <TimePicker id="sec_rth_open" onChange={onRthOpenChange} value={marketOpen} className="custom_timepicker" locale="nb-NO" />
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label htmlFor="sec_rth_closed" className="col-sm-6 control-label">
                Regular Hours Close
              </label>
              <div className="col-sm-6">
                <TimePicker
                  id="sec_rth_closed"
                  onChange={onRthCloseChange}
                  value={marketClosed}
                  className="custom_timepicker"
                  locale="nb-NO"
                />
              </div>
            </div>
            <hr />
            <div className="form-group form-group-sm">
              <label htmlFor="sec_ah_open" className="col-sm-6 control-label">
                After Hours Open
              </label>
              <div className="col-sm-6">
                <TimePicker
                  id="sec_ah_open"
                  onChange={onAfterHoursOpenChange}
                  value={marketAfterHoursOpen}
                  className="custom_timepicker"
                  locale="nb-NO"
                />
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label htmlFor="sec_ah_closed" className="col-sm-6 control-label">
                After Hours Close
              </label>
              <div className="col-sm-6">
                <TimePicker
                  id="sec_ah_closed"
                  onChange={onAfterHoursCloseChange}
                  value={marketAfterHoursClosed}
                  className="custom_timepicker"
                  locale="nb-NO"
                />
              </div>
            </div>
          </CollapsePanel>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-xs-4">
          <button type="button" className="btn btn-danger ripple" onClick={() => setShowConfirmDialog(true)}>
            Delete
          </button>
        </div>
        <div className="col-xs-8 text-right">
          <button type="button" className="btn btn-primary ripple gap-bottom-5 gap-right-5" onClick={updateSecurityHandler}>
            Save
          </button>
          <button type="button" className="btn btn-default ripple gap-bottom-5" onClick={navigateToList}>
            Close
          </button>
        </div>
      </div>

      <ConfirmDialog
        show={showConfirmDialog}
        cancel={() => setShowConfirmDialog(false)}
        confirm={deleteSecurityHandler}
        title="Confirm delete"
        text="Are you sure you want to delete the security?"
      />
    </div>
  );
};

export default withRouter(GeneralTab);
