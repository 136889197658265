import { BarSize } from '../../../../types';

import * as actionTypes from './action-types';
import { GraphConfigState } from './state';

export const setGraphConfig = (barSize: BarSize, config: GraphConfigState) => {
  return {
    type: actionTypes.SET_GRAPH_CONFIG,
    payload: {
      key: barSize,
      config
    }
  };
};
