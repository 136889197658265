import { Strategy } from '../../../types';

import * as actionTypes from './action-types';

export interface StrategyAction {
  type: string;
  strategy?: Strategy;
  strategies?: Strategy[];
  id?: number;
  loaded?: boolean;
  error?: string;
  showArchived?: boolean;
  collapsed?: boolean;
  selectedTab?: string;
}

export const addStrategy = (strategy: Strategy): StrategyAction => {
  return {
    type: actionTypes.ADD_STRATEGY,
    strategy
  };
};

export const updateStrategy = (strategy: Strategy): StrategyAction => {
  return {
    type: actionTypes.UPDATE_STRATEGY,
    strategy
  };
};

export const removeStrategy = (id: number): StrategyAction => {
  return {
    type: actionTypes.REMOVE_STRATEGY,
    id
  };
};

export const setStrategies = (strategies: Strategy[]): StrategyAction => {
  return {
    type: actionTypes.SET_STRATEGIES,
    strategies
  };
};

export const addArchivedStrategies = (strategies: Strategy[]): StrategyAction => {
  return {
    type: actionTypes.ADD_ARCHIVED_STRATEGIES,
    strategies
  };
};

export const setStrategyError = (error: string): StrategyAction => {
  return {
    type: actionTypes.SET_STRATEGY_ERROR,
    error
  };
};

export const setStrategyLoaded = (loaded: boolean): StrategyAction => {
  return {
    type: actionTypes.SET_STRATEGY_LOADED,
    loaded
  };
};

export const setShowArchived = (showArchived: boolean): StrategyAction => {
  return {
    type: actionTypes.UI_SHOW_ARCHIVED,
    showArchived
  };
};

export const setOptionPanelCollapsed = (collapsed: boolean): StrategyAction => {
  return {
    type: actionTypes.UI_COLLAPSE_OPTION_PANEL,
    collapsed
  };
};

export const setDetailsCollapsed = (collapsed: boolean): StrategyAction => {
  return {
    type: actionTypes.UI_COLLAPSE_DETAILS_PANEL,
    collapsed
  };
};

export const setPickerPanelCollapsed = (collapsed): StrategyAction => {
  return {
    type: actionTypes.UI_COLLAPSE_PICKER_PANEL,
    collapsed
  };
};

export const setStrategyPicked = (strategy: Strategy | undefined): StrategyAction => {
  return {
    type: actionTypes.UI_STRATEGY_PICKED,
    strategy
  };
};

export const selectTab = (selectedTab: string): StrategyAction => {
  return {
    type: actionTypes.UI_SELECT_TAB,
    selectedTab
  };
};
