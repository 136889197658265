import { setThousenSeparator, setDecimalSpaces } from './number-utils';

export const formatUsd = (num: number | undefined, spaces: number): string => {
  if (num === undefined || num === null) {
    return '';
  }
  const isNeg = num < 0;
  const posNum = Math.abs(num);
  let fixed = setDecimalSpaces(posNum, spaces);
  fixed = setThousenSeparator(fixed, '.');
  return (isNeg ? '-' : '') + '$' + fixed;
};

export const formatNok = (num: number | undefined, spaces: number): string => {
  if (num === undefined || num === null) {
    return '';
  }
  let fixed = setDecimalSpaces(num, spaces);
  fixed = setThousenSeparator(fixed, '.');
  return 'Kr ' + fixed;
};
