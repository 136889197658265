import { AnyAction } from 'redux';

import { ContractPartial } from '../../../../types';

import * as actionTypes from './action-types';
import { initalContractPartialMapState, initalContractPartialState } from './state';

export const contractPartialReducer = (state = initalContractPartialMapState, action: AnyAction) => {
  const key = action.securityId;
  const current = state.get(key) || { ...initalContractPartialState };

  if (action.type === actionTypes.SET_CONTRACT_PARTIALS) {
    const partials = action.partials as ContractPartial[];
    return state.set(key, {
      loaded: true,
      error: undefined,
      partials
    });
  } else if (action.type === actionTypes.ADD_CONTRACT_PARTIALS) {
    const partials = [...current.partials].concat(action.partials);
    return state.set(key, {
      loaded: true,
      error: undefined,
      partials
    });
  } else if (action.type === actionTypes.ADD_CONTRACT_PARTIAL) {
    const partials = [...current.partials];
    const partial = action.partial as ContractPartial;
    partials.push(partial);
    return state.set(key, {
      ...current,
      loaded: true,
      error: undefined,
      partials
    });
  } else if (action.type === actionTypes.UPDATE_CONTRACT_PARTIAL) {
    const partials = [...current.partials];
    const partial = action.partial as ContractPartial;
    const item = partials.find((x) => x.id === partial.id);
    const index = item ? partials.indexOf(item) : -1;
    partials[index] = partial;
    return state.set(key, {
      ...current,
      loaded: true,
      error: undefined,
      partials
    });
  } else if (action.type === actionTypes.REMOVE_CONTRACT_PARTIAL) {
    const partials = [...current.partials];
    const partial = action.partial as ContractPartial;
    const item = partials.find((x) => x.id === partial.id);
    const index = item ? partials.indexOf(item) : -1;
    partials.splice(index, 1);
    return state.set(key, {
      ...current,
      loaded: true,
      error: undefined,
      partials
    });
  } else if (action.type === actionTypes.SET_CONTRACT_PARTIAL_ERROR) {
    return state.set(key, {
      ...current,
      loaded: true,
      error: action.error
    });
  } else if (action.type === actionTypes.SET_CONTRACT_PARTIAL_LOADED) {
    return state.set(key, {
      ...current,
      loaded: action.loaded,
      error: undefined
    });
  }

  return state;
};
