import { OpenOrderViewModel } from '../../../../types';

import * as actionTypes from './action-types';

export interface OpenOrderAction {
  type: string;
  openOrders?: OpenOrderViewModel[];
  error?: string;
  openOrdersCollapsed?: boolean;
}

export const setOpenOrders = (openOrders: OpenOrderViewModel[]): OpenOrderAction => {
  return {
    type: actionTypes.SET_OPEN_ORDERS,
    openOrders
  };
};

export const setOpenOrdersLoading = (): OpenOrderAction => {
  return {
    type: actionTypes.SET_OPEN_ORDERS_LOADING
  };
};

export const setOpenOrdersError = (error?: string): OpenOrderAction => {
  return {
    type: actionTypes.SET_OPEN_ORDERS_ERROR,
    error
  };
};

export const setOpenOrdersPanelCollapsed = (collapse: boolean): OpenOrderAction => {
  return {
    type: actionTypes.UI_COLLAPSE_PANEL_OPEN_ORDERS,
    openOrdersCollapsed: collapse
  };
};
