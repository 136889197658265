import * as React from 'react';
import { useEffect } from 'react';

import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { GlobalState } from '../../../core/root';
import { ContractPartial } from '../../../types';
import { getContractPartials, getContractPartialsLoaded } from '../../contract-partial/store/partial-map/selectors';
import * as partialActionCreators from '../../contract-partial/store/partial-map/service';
import CategoryFilterPanel from '../../security/components/CategoryFilterPanel';
import SecurityPickerPanel from '../../security/components/SecurityPickerPanel';
import CollapsePanel from '../../shared/components/CollapsePanel';
import Spinner from '../../shared/components/Spinner';
import StrategyPickerPanel from '../../strategy/components/StrategyPickerPanel';
import * as graphActionCreators from '../store/graph/action-creators';

import GraphTilePanel from './GraphTilePanel';

const GraphScreen: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const state: GlobalState = useSelector((gs: GlobalState) => gs);
  const securitiesLoaded = state.securityState.loaded;
  const selectedSecurity = state.securityState.ui.securityPicked;
  const securityId = selectedSecurity?.id || 0;

  const selectedStrategy = useSelector((state: GlobalState) => state.strategyState.ui.strategyPicked);

  const partials: ContractPartial[] = getContractPartials(state, securityId);
  const partialsLoaded = getContractPartialsLoaded(state, securityId);

  const collapsed = useSelector((state: GlobalState) => state.graphState.partialPanelCollapsed);
  const selectedPartial: ContractPartial | undefined = useSelector((state: GlobalState) => state.graphState.selectedPartial);

  const toggleCollapse = () => {
    dispatch(graphActionCreators.setPartialPanelCollapsed(!collapsed));
  };

  useEffect(() => {
    if (!partialsLoaded) {
      dispatch(partialActionCreators.loadContractPartialsAsync(securityId));
    }
  }, [partialsLoaded]);

  useEffect(() => {
    if (
      selectedSecurity === undefined ||
      (selectedSecurity && selectedPartial && selectedSecurity.id !== selectedPartial.contract.securityId)
    ) {
      dispatch(graphActionCreators.setSelectedPartial(undefined));
    }
  }, [selectedSecurity]);

  const loading = !securitiesLoaded || !partialsLoaded;

  const pick = (partial: ContractPartial) => {
    if (isActive(partial)) {
      dispatch(graphActionCreators.setSelectedPartial(undefined));
    } else {
      dispatch(graphActionCreators.setSelectedPartial(partial));
    }
  };

  const isActive = (partial: ContractPartial) => {
    return selectedPartial && partial.id === selectedPartial.id;
  };

  let partialButtons: JSX.Element[] = [];
  if (selectedSecurity) {
    partialButtons = partials
      .filter((x) => x.contract.securityId === selectedSecurity.id)
      .sort((a: ContractPartial, b: ContractPartial) => {
        if (a.partialMonth > b.partialMonth) return 1;
        if (a.partialMonth < b.partialMonth) return -1;
        return 0;
      })
      .map((partial) => (
        <button
          key={partial.id}
          type="button"
          className={classNames('btn', 'btn-sm', 'btn-default', 'gap-bottom-5', { active: isActive(partial) })}
          onClick={() => pick(partial)}
        >
          {partial.partialMonth}
        </button>
      ));
  }

  const partialToolbar = selectedSecurity ? (
    <div className="btn-toolbar">{partialButtons}</div>
  ) : (
    <p className="small">No security selected..</p>
  );

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item active">Graphs</li>
      </ol>

      <div className="page-header">
        <h3>
          Graphs <small className="no-wrap"></small>
        </h3>
      </div>

      {loading && <Spinner />}

      <SecurityPickerPanel>
        <CategoryFilterPanel title="Filter" />
      </SecurityPickerPanel>

      <CollapsePanel title="Partials" upper={true} collapsed={collapsed} onToggle={toggleCollapse} subTitle={selectedPartial?.partialMonth}>
        <div className="form-horizontal">
          <div className="row">
            <div className="col-xs-12">{partialToolbar}</div>
          </div>
        </div>
      </CollapsePanel>

      <StrategyPickerPanel smallTitle="Optional" />

      <div className="section">
        <hr />
        <p>Graph View</p>
      </div>

      {selectedPartial ? (
        <GraphTilePanel partial={selectedPartial} strategy={selectedStrategy} />
      ) : (
        <p className="small">No partial selected..</p>
      )}
    </div>
  );
};

export default GraphScreen;
