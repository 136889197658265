import React, { FunctionComponent } from 'react';

import { useDispatch } from 'react-redux';

import { Security, ContractPartial, ContractPartialQuote, Strategy, Indicator } from '../../../types';
import { generateContractPartialsAsync } from '../store/partial-map/service';

import ContractPartialTable from './ContractPartialTable';

export interface Props {
  security: Security;
  strategy?: Strategy;
  partials: ContractPartial[];
  partialQuotes: ContractPartialQuote[];
  indicators: Indicator[];
}

const ContractPartialList: FunctionComponent<Props> = ({ security, strategy, partials, partialQuotes, indicators }: Props) => {
  const dispatch = useDispatch();

  const generateContractPartialsHandler = () => {
    dispatch(generateContractPartialsAsync(security.id));
  };

  return (
    <div>
      <div className="section">
        <hr />
        <p>Partials</p>
      </div>
      <ContractPartialTable
        security={security}
        partials={partials}
        partialQuotes={partialQuotes}
        strategy={strategy}
        indicators={indicators}
      />
      {security ? (
        <>
          <hr />
          <div className="btn-toolbar pull-right" role="toolbar">
            <button className="btn btn-sm btn-success" onClick={generateContractPartialsHandler}>
              Generate
            </button>
          </div>
        </>
      ) : (
        <span className="small">No security selected..</span>
      )}
    </div>
  );
};

export default ContractPartialList;
