import { ContractPartial } from '../../../../types';

import * as actionTypes from './action-types';

export const setContractPartials = (securityId: number, partials: ContractPartial[]) => {
  return {
    type: actionTypes.SET_CONTRACT_PARTIALS,
    securityId,
    partials
  };
};

export const addContractPartials = (securityId: number, partials: ContractPartial[]) => {
  return {
    type: actionTypes.ADD_CONTRACT_PARTIALS,
    securityId,
    partials
  };
};

export const addContractPartial = (securityId: number, partial: ContractPartial) => {
  return {
    type: actionTypes.ADD_CONTRACT_PARTIAL,
    securityId,
    partial
  };
};

export const updateContractPartial = (securityId: number, partial: ContractPartial) => {
  return {
    type: actionTypes.UPDATE_CONTRACT_PARTIAL,
    securityId,
    partial
  };
};

export const removeContractPartial = (securityId: number, partial: ContractPartial) => {
  return {
    type: actionTypes.REMOVE_CONTRACT_PARTIAL,
    securityId,
    partial
  };
};

export const setContractPartialError = (securityId: number, error: string) => {
  return {
    type: actionTypes.SET_CONTRACT_PARTIAL_ERROR,
    securityId,
    error
  };
};

export const setContractPartialLoaded = (securityId: number, loaded: boolean) => {
  return {
    type: actionTypes.SET_CONTRACT_PARTIAL_LOADED,
    securityId,
    loaded
  };
};
