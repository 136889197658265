import * as React from 'react';
import { useState } from 'react';

import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Strategy, StrategyGroup } from '../../../../types';
import { ConfirmDialog } from '../../../shared/components/ConfirmDialog';
import { CreateDialog } from '../../../shared/components/CreateDialog';
import { strategyModulePath } from '../../../strategy/routes';
import {
  addStrategyGroupAsync,
  removeStrategyGroupAsync,
  setSelectedStrategyGroup,
  updateStrategyGroupAsync
} from '../../store/action-creators';
import { sortStrategyGroups } from '../../utils';

import { EditGroupDialog } from './EditGroupDialog';

export interface Props extends RouteComponentProps {
  accountId: number;
  groups: StrategyGroup[];
  strategies: Strategy[];
  showCreateMenu: boolean;
}

const StrategyGroupPanel = ({ accountId, groups, strategies, showCreateMenu, history }: Props) => {
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [editMode, setEditMode] = useState<StrategyGroup | undefined>();
  const showEditGroupDialog = editMode !== undefined;
  const selectedGroupName = editMode?.name || '';
  const [removeGroupId, setRemoveGroupId] = useState<number | undefined>();
  const showConfirmDialog = removeGroupId != undefined;

  const dispatch = useDispatch();

  const cancelCreateStrategyGroupHandler = () => {
    setShowCreateDialog(false);
  };

  const createStrategyGroup = (name: string) => {
    const group = { id: 0, name } as StrategyGroup;
    dispatch(addStrategyGroupAsync(group));
    setShowCreateDialog(false);
  };

  const cancelEditStrategyGroupHandler = () => {
    setEditMode(undefined);
  };

  const editStrategyGroupHandler = (group: StrategyGroup) => {
    setEditMode(group);
  };

  const editStrategyGroup = (name: string) => {
    if (editMode) {
      const copy = { ...editMode };
      copy.name = name;
      dispatch(updateStrategyGroupAsync(copy));
    }
    setEditMode(undefined);
  };

  const cancelRemoveStrategyGroupHandler = () => {
    setRemoveGroupId(undefined);
  };

  const removeStrategyGroupHandler = (group: StrategyGroup) => {
    setRemoveGroupId(group.id);
  };

  const remoteStrategyGroup = () => {
    if (removeGroupId) {
      dispatch(removeStrategyGroupAsync(removeGroupId));
    }
    setRemoveGroupId(undefined);
  };

  const getNumStrategies = (group: StrategyGroup) => {
    return strategies.filter((x) => x.groupId === group.id).length;
  };

  const toggleArchiveStrategyGroup = (group: StrategyGroup) => {
    const gr = { ...group };
    gr.archived = !gr.archived;
    dispatch(updateStrategyGroupAsync(gr));
  };

  const goToStrategyGroup = (group: StrategyGroup) => {
    dispatch(setSelectedStrategyGroup(group));
    history.push(strategyModulePath);
  };

  const header = (
    <thead>
      <tr>
        <th className="small">Group</th>
        <th className="small text-center">Strategies</th>
        <th className="small text-center">{/*/ Edit */}</th>
        <th className="small text-center">{/*/ Go To */}</th>
        <th className="small text-center">{/*/ Archive */}</th>
        <th className="small text-center">{/*/ Delete */}</th>
      </tr>
    </thead>
  );

  const rows = groups
    .filter((x) => x.accountId === accountId)
    .sort(sortStrategyGroups)
    .map((x) => (
      <tr key={x.id} className={classNames({ grey: x.archived })}>
        <td className="small middle">{x.name}</td>
        <td className="small middle text-center">{getNumStrategies(x)}</td>
        <td className="small middle text-center">
          <button className="btn btn-xs btn-default ripple" title="Edit" onClick={() => editStrategyGroupHandler(x)}>
            <i className="fa fa-edit" />
          </button>
        </td>
        <td className="small middle text-center">
          <button className="btn btn-xs btn-success ripple" title="Go" onClick={() => goToStrategyGroup(x)}>
            <i className="fa fa-arrow-right" />
          </button>
        </td>
        <td className="vborder small middle text-center">
          <button className="btn btn-xs btn-default ripple" title="Active" onClick={() => toggleArchiveStrategyGroup(x)}>
            <span className={classNames({ 'success-text2': !x.archived })}>
              <i className={classNames('fa', { 'fa-check-square': !x.archived, 'fa-square-o': x.archived })} />
            </span>
          </button>
        </td>
        <td className="small middle text-center">
          <button className="btn btn-xs btn-danger ripple" title="Delete" onClick={() => removeStrategyGroupHandler(x)}>
            <i className="fa fa-trash" />
          </button>
        </td>
      </tr>
    ));

  return (
    <div>
      {rows.length > 0 ? (
        <div className="row">
          <div className="col-sm-offset-2 col-sm-8 col-md-offset-3 col-md-6">
            <div className="table-responsive">
              <table className="table table-hover table-striped">
                {header}
                <tbody>{rows}</tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <p className="small">No strategy groups..</p>
      )}
      {showCreateMenu && (
        <div>
          <hr />
          <div className="text-right">
            <button className="btn btn-sm btn-default" type="button" onClick={() => setShowCreateDialog(true)}>
              Create..
            </button>
          </div>
        </div>
      )}

      <CreateDialog
        show={showCreateDialog}
        cancel={cancelCreateStrategyGroupHandler}
        create={createStrategyGroup}
        title="Create Strategy Group"
        propName="Group"
      />

      <EditGroupDialog
        show={showEditGroupDialog}
        cancel={cancelEditStrategyGroupHandler}
        name={selectedGroupName}
        save={editStrategyGroup}
      />

      <ConfirmDialog
        show={showConfirmDialog}
        cancel={cancelRemoveStrategyGroupHandler}
        confirm={remoteStrategyGroup}
        title="Confirm delete"
        text="Are you sure you want to delete the security group?"
      />
    </div>
  );
};

export default withRouter(StrategyGroupPanel);
