import { Backtest } from '../../../../types';
import { showNetworkError } from '../../../shared/utils/growl-utils';

import * as actionTypes from './action-types';
import { BacktestAction } from './actions';
import { BacktestHttpRequest } from './http-request';

export const addBacktest = (securityId: number, strategyId: number, backtest: Backtest): BacktestAction => {
  return {
    type: actionTypes.ADD_BACKTEST,
    securityId,
    strategyId,
    backtest
  };
};

export const addBacktests = (securityId: number, strategyId: number, backtests: Backtest[]): BacktestAction => {
  return {
    type: actionTypes.ADD_BACKTESTS,
    securityId,
    strategyId,
    backtests
  };
};

export const updateBacktest = (securityId: number, strategyId: number, backtest: Backtest): BacktestAction => {
  return {
    type: actionTypes.UPDATE_BACKTEST,
    securityId,
    strategyId,
    backtest
  };
};

export const removeBacktest = (securityId: number, strategyId: number, backtest: Backtest): BacktestAction => {
  return {
    type: actionTypes.REMOVE_BACKTEST,
    securityId,
    strategyId,
    backtest
  };
};

export const setBacktests = (securityId: number, strategyId: number, backtests: Backtest[]): BacktestAction => {
  return {
    type: actionTypes.SET_BACKTEST_LIST,
    securityId,
    strategyId,
    backtests
  };
};

export const setBacktestError = (securityId: number, strategyId: number, error: string): BacktestAction => {
  return {
    type: actionTypes.SET_BACKTEST_LIST_ERROR,
    securityId,
    strategyId,
    error
  };
};

export const clearBacktestError = (securityId: number, strategyId: number) => {
  return {
    type: actionTypes.SET_BACKTEST_LIST_CLEAR_ERROR,
    securityId,
    strategyId
  };
};

/*** ASYNC WITH THUNK ***/

export const loadBacktestsAsync = (securityId: number, strategyId: number) => {
  return (dispatch) => {
    BacktestHttpRequest.get(securityId, strategyId)
      .then((response) => {
        // console.log(response);
        dispatch(setBacktests(securityId, strategyId, response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load backtests');
        dispatch(setBacktestError(securityId, strategyId, msg));
      });
  };
};

export const addBacktestAsync = (securityId: number, strategyId: number, backtest: Backtest) => {
  return (dispatch) => {
    BacktestHttpRequest.insert(backtest)
      .then((response) => {
        dispatch(addBacktest(securityId, strategyId, response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setBacktestError(securityId, strategyId, msg));
      });
  };
};

export const updateBacktestAsync = (securityId: number, strategyId: number, backtest: Backtest) => {
  return (dispatch) => {
    BacktestHttpRequest.update(backtest)
      .then(() => {
        dispatch(updateBacktest(securityId, strategyId, backtest));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setBacktestError(securityId, strategyId, msg));
      });
  };
};

export const removeBacktestAsync = (securityId: number, strategyId: number, backtest: Backtest) => {
  return (dispatch) => {
    BacktestHttpRequest.remove(backtest.id)
      .then(() => {
        dispatch(removeBacktest(securityId, strategyId, backtest));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setBacktestError(securityId, strategyId, msg));
      });
  };
};

export const generateBacktestsAsync = (securityId: number, strategyId: number) => {
  return (dispatch) => {
    BacktestHttpRequest.generate(securityId, strategyId)
      .then((response) => {
        dispatch(addBacktests(securityId, strategyId, response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to generate backtests');
        dispatch(setBacktestError(securityId, strategyId, msg));
      });
  };
};
