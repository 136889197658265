import * as React from 'react';

import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';

import { Position, PeriodType } from '../../../types';
import TrendDirectionAtom from '../../shared/components/TrendDirectionAtom';
import { formatUsd } from '../../shared/utils/currency-utils';
import { toTime } from '../../shared/utils/date-format-utils';
import { getDuration } from '../../shared/utils/time-utils';
import { positionModulePath } from '../pos-routes';

export interface OpenPositionTableProps extends RouteComponentProps {
  positions: Position[];
}

const OpenPositionTable: React.FunctionComponent<OpenPositionTableProps> = ({ positions, history }: OpenPositionTableProps) => {
  const rowClickHandler = (position: Position) => {
    const route = `${positionModulePath}/${position.id}`;
    history.push(route);
  };

  const getSumUnrealizedPnl = () => {
    if (positions.length === 0) {
      return 0;
    }
    const sum = positions
      .map((x) => x.sumUnrealizedPnl)
      .reduce((total: number, pnl: number) => {
        return total + pnl;
      });
    return formatUsd(sum, 0);
  };

  const getSumCommission = () => {
    if (positions.length === 0) {
      return 0;
    }
    const sum = positions
      .map((x) => x.sumCommission)
      .reduce((total: number, pnl: number) => {
        return total + pnl;
      });
    return formatUsd(sum, 1);
  };

  const getSumSpreadCost = () => {
    if (positions.length === 0) {
      return 0;
    }
    const sum = positions
      .map((x) => x.sumSlippageAmount)
      .reduce((total: number, pnl: number) => {
        return total + pnl;
      });
    return formatUsd(sum, 0);
  };

  const header = (
    <tr>
      <th className="middle small">Security</th>
      <td className="middle small">Contract</td>
      <td className="middle small">Type</td>
      <td className="middle small text-center">Size</td>
      <td className="middle small">Created</td>
      <td className="middle small">Updated</td>
      <td className="middle small text-right">Dur.</td>

      <td className="middle small text-center">Trend</td>
      <td className="middle small text-right">Unrlzd.</td>
      <td className="middle small text-right">Slipp.</td>
      <td className="middle small text-right">Comm.</td>
    </tr>
  );

  const rows = positions.map((position: Position) => {
    return (
      <tr key={position.id} className="clickable" onClick={() => rowClickHandler(position)}>
        <th className="middle small">{position.security.name}</th>
        <td className="middle small">{position.contract.localSymbol}</td>
        <td className="middle small">{PeriodType[position.type]}</td>
        <td className="middle small text-center">{position.size}</td>
        <td className="middle small">{toTime(position.created)}</td>
        <td className="middle small">{toTime(position.updated)}</td>
        <td className="middle small text-right">{getDuration(position.created, position.updated)}</td>

        <td className="middle small text-center vborder">
          <TrendDirectionAtom trendDirection={position.trendDirection} positionSize={position.size} />
        </td>
        <td className="middle small text-right">
          <span
            className={classNames({
              'success-text': position.sumUnrealizedPnl > 0,
              'danger-text2': position.sumUnrealizedPnl < 0
            })}
          >
            {formatUsd(position.sumUnrealizedPnl, 0)}
          </span>
        </td>
        <td className="middle small text-right">
          <span>
            {formatUsd(position.sumSlippageAmount, 0)} ({position.sumSlippageTicks})
          </span>
        </td>
        <td className="middle small text-right">
          <span>{formatUsd(position.sumCommission, 1)}</span>
        </td>
      </tr>
    );
  });

  const footer = (
    <tr>
      <th className="middle small">Sum:</th>
      <td className="middle small"></td>
      <td className="middle small"></td>
      <td className="middle small text-center"></td>
      <td className="middle small"></td>
      <td className="middle small"></td>
      <td className="middle small text-right"></td>

      <td className="middle small text-center"></td>
      <td className="middle small text-right">{getSumUnrealizedPnl()}</td>
      <td className="middle small text-right">{getSumSpreadCost()}</td>
      <td className="middle small text-right">{getSumCommission()}</td>
    </tr>
  );

  return (
    <div>
      {positions.length > 0 ? (
        <div className="scrolling outer">
          <div className="inner">
            <table className="table table-hover table-striped table-condensed">
              <thead>{header}</thead>
              <tbody>{rows}</tbody>
              <tfoot>{footer}</tfoot>
            </table>
          </div>
        </div>
      ) : (
        <span className="small">No positions..</span>
      )}
    </div>
  );
};

export default withRouter(OpenPositionTable);
