import * as React from 'react';

import DatePicker from '@christianleypoldt/react-bootstrap-date-picker';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ContractPartial, Security } from '../../../../types';
import CollapsePanel from '../../../shared/components/CollapsePanel';

export interface GeneralPanelProps extends RouteComponentProps {
  security: Security;
  partials: ContractPartial[];
  partial: ContractPartial;
  partialMonth: string;
  periodStart: string | undefined;
  periodEnd: string | undefined;
  updatePeriodStart(value: Date, formattedValue: string): void;
  updatePeriodEnd(value: Date, formattedValue: string): void;
}

const GeneralPanel = ({
  security,
  partials,
  partial,
  partialMonth,
  periodStart,
  periodEnd,
  updatePeriodStart,
  updatePeriodEnd,
  history
}: GeneralPanelProps) => {
  const periodStartToolstrip = <Tooltip id="periodStartToolstrip">{periodStart}</Tooltip>;

  const periodEndTooltip = <Tooltip id="periodEndTooltip">{periodEnd}</Tooltip>;

  const dateFormat = 'DD.MM.YYYY HH:mm:SS';

  const sortByDate = (a: ContractPartial, b: ContractPartial) => {
    if (a.periodEnd > b.periodEnd) return 1;
    if (a.periodEnd < b.periodEnd) return -1;
    return 0;
  };

  const goToNext = () => {
    const orderedPartials = partials.filter((x) => x.contract.securityId === partial.contract.securityId).sort(sortByDate);
    const filteredList = orderedPartials.filter((x) => x.periodEnd > partial.periodEnd);
    if (filteredList.length > 0) {
      const nextId = filteredList[0].id;
      history.push(`/contract-partials/${security.id}/${nextId}`);
    }
  };

  const goToPrevious = () => {
    const orderedPartials = partials.filter((x) => x.contract.securityId === partial.contract.securityId).sort(sortByDate);
    const filteredList = orderedPartials.filter((x) => x.periodEnd < partial.periodEnd);
    if (filteredList.length > 0) {
      const prevId = filteredList[filteredList.length - 1].id;
      history.push(`/contract-partials/${security.id}/${prevId}`);
    }
  };

  return (
    <CollapsePanel title="General" collapsed={false}>
      <div className="form-group form-group-sm">
        <label htmlFor="backtest_partial" className="col-sm-4 control-label">
          Partial
        </label>
        <div className="col-sm-8">
          <input id="backtest_partial" type="text" className="form-control" value={partialMonth} disabled={true} />
        </div>
      </div>
      <div className={classNames('form-group', 'form-group-sm', { 'has-error': !periodStart })}>
        <label htmlFor="backtest_periodstart" className="col-xs-12 col-sm-4 control-label">
          <OverlayTrigger placement="top" overlay={periodStartToolstrip}>
            <span>Period Start</span>
          </OverlayTrigger>
        </label>
        <div className="col-xs-12 col-sm-8">
          <div className="datepicker-fix">
            <DatePicker
              id="backtest_periodstart"
              required
              weekStartsOn={1}
              value={periodStart}
              placeholder="Period Start"
              dateFormat={dateFormat}
              onChange={updatePeriodStart}
            />
          </div>
        </div>
      </div>
      <div className={classNames('form-group', 'form-group-sm', { 'has-error': !periodEnd })}>
        <label htmlFor="backtest_periodend" className="col-xs-12 col-sm-4 control-label">
          <OverlayTrigger placement="top" overlay={periodEndTooltip}>
            <span>Period End</span>
          </OverlayTrigger>
        </label>
        <div className="col-xs-12 col-sm-8">
          <div className="datepicker-fix">
            <DatePicker
              id="backtest_periodend"
              required
              weekStartsOn={1}
              value={periodEnd}
              placeholder="Period End"
              dateFormat={dateFormat}
              onChange={updatePeriodEnd}
            />
          </div>
        </div>

        <div className="col-xs-12">
          <hr />
          <div className="row">
            <div className="col-xs-6">
              <button className="btn btn-default btn-block btn-sm" onClick={goToPrevious}>
                <span className="glyphicon glyphicon glyphicon-triangle-left" aria-hidden="true"></span>
                <span> Previous</span>
              </button>
            </div>
            <div className="col-xs-6">
              <button className="btn btn-default btn-block btn-sm" onClick={goToNext}>
                <span>Next </span>
                <span className="glyphicon glyphicon glyphicon-triangle-right" aria-hidden="true"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </CollapsePanel>
  );
};

export default withRouter(GeneralPanel);
