import { AnyAction } from 'redux';

import { ScheduleStatusAction } from './action-creators';
import * as actionTypes from './action-types';
import { initialState } from './state';

export const scheduleStatusReducer = (state = initialState, action: AnyAction | ScheduleStatusAction) => {
  if (action.type === actionTypes.SET_SCHEDULE_STATUS_LOADING) {
    action = action as ScheduleStatusAction;
    return {
      ...state,
      loading: action.loading,
      loaded: !action.loading,
      error: undefined
    };
  } else if (action.type === actionTypes.SET_SCHEDULE_STATUS) {
    action = action as ScheduleStatusAction;
    return {
      ...state,
      status: action.status,
      loading: false,
      loaded: true,
      error: undefined
    };
  } else if (action.type === actionTypes.TOGGLE_RUN_SCHEDULE) {
    const isScheduleStarted = action.status.isScheduleStarted;
    return {
      ...state,
      status: {
        ...state.status,
        isScheduleStarted
      },
      loading: false
    };
  } else if (action.type === actionTypes.CLEAR_SCHEDULE_STATUS) {
    return {
      ...state,
      status: {},
      loading: false,
      loaded: false,
      error: undefined
    };
  } else if (action.type === actionTypes.SET_SCHEDULE_STATUS_ERROR) {
    action = action as ScheduleStatusAction;
    return {
      ...state,
      error: action.error,
      loading: false
    };
  }

  return state;
};
