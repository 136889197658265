import * as React from 'react';

import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';

// Replaces BrowserRouter
// https://skryvets.com/blog/2018/09/20/an-elegant-solution-of-deploying-react-app-into-a-subdirectory/

const WithCustomRouter: React.FunctionComponent = (props) => {
  const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL
  });

  return <Router history={history}>{props.children}</Router>;
};

export default WithCustomRouter;
