import axios from 'axios';

import { LOCAL_STORAGE_TOKEN } from './constants/local-storage';
import { updateHeaderWithAuthToken } from './utils/http-utils';
import { onTokenEvent } from './utils/local-storage-utils';

const baseURL = process.env.REACT_APP_SERVER_URL;

const instance = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*'
  },
  baseURL
});

/* UPDATE TOKEN AND INIT TOKEN EVENT LISTENER */
const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
updateHeaderWithAuthToken(instance, token);

onTokenEvent((token: string | null) => {
  updateHeaderWithAuthToken(instance, token);
});
/* END */

export default instance;
