import * as React from 'react';
import { FunctionComponent, useState } from 'react';

import classNames from 'classnames';

import { Indicator, IndicatorResult, IndicatorType, OrderAction, OrderDirection, SqueezeResult } from '../../../types';
import IndicatorDialog from '../../indicator/components/IndicatorDialog';
import { getIndicatorOptionName } from '../../indicator/components/IndicatorOptionsPanel/indicator-options';
import { getBarSizeName } from '../../shared/utils/bar-utils';
import { sortByDirectionThenByActionThenByBarSizeThenByNames } from '../../shared/utils/indicator-utils';
import { setDecimalSpaces } from '../../shared/utils/number-utils';

interface Props {
  results: IndicatorResult[];
}

const IndicatorResultList: FunctionComponent<Props> = ({ results }: Props) => {
  const [selectedIndicator, setSelectedIndicator] = useState<Indicator | undefined>();

  const header = (
    <tr>
      <td className="middle small">Name</td>
      <td className="middle small">Direction</td>
      <td className="middle small">Action</td>
      <td className="middle small">Bar Size</td>
      <td className="middle small">Values</td>
      <td className="middle small text-center">Valid</td>
      <td className="middle small text-center">Error</td>
      <td className="middle small" style={{ minWidth: '150px' }}>
        Message
      </td>
    </tr>
  );

  const getValues = (result: IndicatorResult) => {
    switch (result.type) {
      case IndicatorType.Adx:
        const adx = setDecimalSpaces(result.value, 0);
        return adx ? `ADX=${adx}` : null;
      case IndicatorType.AverageTrueRange:
        const atr = setDecimalSpaces(result.value, 0);
        return atr ? `ATR=${adx}` : null;
      case IndicatorType.AvgUpDown:
        const mom = setDecimalSpaces(result.value, 0);
        return mom ? `Momentum=${mom}` : null;
      case IndicatorType.AmountUpDown:
        const amount = setDecimalSpaces(result.value, 0);
        return amount ? `Amount=${amount}` : null;
      case IndicatorType.BollingerBand:
        const bb = setDecimalSpaces(result.value, 2);
        return bb ? `Std. Deviation=${bb}` : null;
      case IndicatorType.Compare:
        const compare = setDecimalSpaces(result.value, 2);
        return compare ? `Diff=${compare}` : null;
      case IndicatorType.Range:
        const range = setDecimalSpaces(result.value, 0);
        return range ? `Range=${range}` : null;
      case IndicatorType.Shift:
        const shiftBarPct = setDecimalSpaces(result.shiftBarsPct, 0);
        const shiftBarBodyPct = setDecimalSpaces(result.shiftBarsBodyPct, 0);
        return shiftBarPct || shiftBarBodyPct ? `Bar=${shiftBarPct}% | Body=${shiftBarBodyPct}%` : null;
      case IndicatorType.Squeeze:
        return `Squeeze=${SqueezeResult[result.squeeze]}`;
      case IndicatorType.Tick:
        const ticks = setDecimalSpaces(result.value, 0);
        return ticks ? `Ticks=${ticks}` : null;
      case IndicatorType.BreakOut:
        const breakOutAmount = setDecimalSpaces(result.value, 0);
        return ticks ? `Amount=${breakOutAmount}` : null;
      default:
        return result.value;
    }
  };

  const rows = results.sort(sortByDirectionThenByActionThenByBarSizeThenByNames).map((result) => {
    return (
      <tr key={result.id} className="clickable" onClick={() => setSelectedIndicator(result.indicator)}>
        <td className="middle small">{getIndicatorOptionName(result.type)}</td>
        <td className="middle small vborder">{OrderDirection[result.direction]}</td>
        <td className="middle small">{result.action == OrderAction.Buy ? 'Long' : 'Short'}</td>
        <td className="middle small">{getBarSizeName(result.barSize)}</td>

        <td className="middle small vborder">{getValues(result)}</td>
        <td className="middle small text-center">
          <i
            className={classNames('fa', { 'fa-check-square-o': result.valid, 'text-success': result.valid, 'fa-square-o': !result.valid })}
          ></i>
        </td>
        <td className="middle small text-center">
          <i
            className={classNames('fa', { 'fa-check-square-o': result.error, 'text-danger': result.error, 'fa-square-o': !result.error })}
          ></i>
        </td>

        <td className="middle small vborder" style={{ minWidth: '150px' }}>
          {result.message}
        </td>
      </tr>
    );
  });

  return (
    <>
      <div className="table-responsive">
        <table className="table table-hover table-striped table-condensed">
          <thead>{header}</thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
      {selectedIndicator ? <IndicatorDialog indicator={selectedIndicator} close={() => setSelectedIndicator(undefined)} /> : null}
    </>
  );
};

export default IndicatorResultList;
