import { PeriodType } from '../../../types';
import * as constants from '../constants/broker-accounts';

export interface PositionTypeOption {
  positionTypeName: string;
  positionType: PeriodType;
  account?: string;
  selected: boolean;
}
export const positionTypeOptions: PositionTypeOption[] = [
  { positionTypeName: 'Live', positionType: PeriodType.Live, account: constants.LIVE_ACCOUNT, selected: false },
  { positionTypeName: 'Paper', positionType: PeriodType.Live, account: constants.PAPER_TRADING_ACCOUNT[0], selected: false },
  { positionTypeName: PeriodType[PeriodType.Simulation], positionType: PeriodType.Simulation, selected: false },
  { positionTypeName: PeriodType[PeriodType.Backtest], positionType: PeriodType.Backtest, selected: false }
];
