import { Strategy } from '../../../types';

export interface StrategyState {
  loaded: boolean;
  archivedLoaded: boolean;
  error?: string;
  strategies: Strategy[];
  ui: {
    optionPanelCollapsed: boolean;
    pickerPanelCollapsed: boolean;
    detailsCollapsed: boolean;
    strategyPicked: Strategy | undefined;
    showArchived: boolean;
    selectedTab: string;
  };
}

export const initalStrategyState: StrategyState = {
  loaded: false,
  archivedLoaded: false,
  error: undefined,
  strategies: [],
  ui: {
    optionPanelCollapsed: false,
    pickerPanelCollapsed: true,
    detailsCollapsed: false,
    strategyPicked: undefined,
    showArchived: false,
    selectedTab: 'general'
  }
};
