import { IndicatorState, PartialState } from './state';

export const getIndicatorState = (state: PartialState, strategyId: number): IndicatorState | undefined => {
  return state.indicatorMapState.get(strategyId);
};

export const getError = (state: PartialState, strategyId: number) => {
  return getIndicatorState(state, strategyId)?.error;
};

export const getLoading = (state: PartialState, strategyId: number) => {
  return getIndicatorState(state, strategyId)?.loading;
};

export const getLoaded = (state: PartialState, strategyId: number) => {
  return getIndicatorState(state, strategyId)?.loaded;
};

export const getIndicators = (state: PartialState, strategyId: number) => {
  return getIndicatorState(state, strategyId)?.indicators;
};

export const shouldFetchIndicators = (state: PartialState, strategyId: number): boolean => {
  const indicatorState = getIndicatorState(state, strategyId);
  return indicatorState === undefined || !!indicatorState.error;
};
