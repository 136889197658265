import * as React from 'react';
import { FunctionComponent, useState } from 'react';

import { Nav, NavDropdown, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { backtestSummaryModulePath } from '../../../../modules/backtest/backtest-summary-routes';
import { contractPartialModulePath } from '../../../../modules/contract-partial/routes';
import { contractModulePath } from '../../../../modules/contract/routes';
import { graphModulePath } from '../../../../modules/graph/routes';
import { periodModulePath } from '../../../../modules/period/period-routes';
import { pnlModulePath } from '../../../../modules/position/pnl-routes';
import { positionModulePath } from '../../../../modules/position/pos-routes';
import { securityModulePath } from '../../../../modules/security/routes';
import { strategyGroupsModulePath } from '../../../../modules/strategy-group/routes';
import { strategyModulePath } from '../../../../modules/strategy/routes';
import { subscriptionModulePath } from '../../../../modules/subscription/routes';
import CalculatePnlDialog from '../../CalculatePnlDialog';
import FlipTossDialog from '../../FlipTossDialog';

import AccountNavDropDown from './AccountNavDropDown';

const Menu: FunctionComponent = () => {
  const [showCalculatePnlDialog, setShowCalculatePnlDialog] = useState(false);
  const toggleShowCalculatePnlDialog = () => {
    setShowCalculatePnlDialog(!showCalculatePnlDialog);
  };

  const [showFlipTossDialog, setShowFlipTossDialog] = useState(false);
  const toggleShowFlipTossDialog = () => {
    setShowFlipTossDialog(!showFlipTossDialog);
  };

  return (
    <>
      <Nav>
        <NavDropdown title="Instruments" id="basic-nav-dropdown">
          <LinkContainer to={securityModulePath} className="ripple">
            <NavItem>Securities</NavItem>
          </LinkContainer>
          <LinkContainer to={contractModulePath} className="ripple">
            <NavItem>Contracts</NavItem>
          </LinkContainer>
          <hr className="nav-devider" />
          <LinkContainer to={contractPartialModulePath} className="ripple">
            <NavItem>Partials</NavItem>
          </LinkContainer>
        </NavDropdown>
        <NavDropdown title="Strategies" id="basic-nav-dropdown2">
          <LinkContainer to={strategyModulePath} className="ripple">
            <NavItem>Strategies</NavItem>
          </LinkContainer>
          <LinkContainer to={strategyGroupsModulePath} className="ripple">
            <NavItem>Strategy Groups</NavItem>
          </LinkContainer>
          <hr className="nav-devider" />
          <LinkContainer to={backtestSummaryModulePath} className="ripple">
            <NavItem>Backtest Summaries</NavItem>
          </LinkContainer>
        </NavDropdown>
        <NavDropdown title="Schedule" id="basic-nav-dropdown3">
          <LinkContainer to={periodModulePath} className="ripple">
            <NavItem>Periods</NavItem>
          </LinkContainer>
          <hr className="nav-devider" />
          <LinkContainer to={subscriptionModulePath} className="ripple">
            <NavItem>Subscriptions</NavItem>
          </LinkContainer>
        </NavDropdown>
        <NavDropdown title="Utils" id="basic-nav-dropdown4">
          <LinkContainer to={`${pnlModulePath}/month`} className="ripple">
            <NavItem>Monthy PnL</NavItem>
          </LinkContainer>
          <LinkContainer to={`${pnlModulePath}/strategy`} className="ripple">
            <NavItem>Strategy PnL</NavItem>
          </LinkContainer>
          <LinkContainer to={`${positionModulePath}`} className="ripple">
            <NavItem>Position History</NavItem>
          </LinkContainer>
          <hr className="nav-devider" />
          <LinkContainer to={graphModulePath} className="ripple">
            <NavItem>Graphs</NavItem>
          </LinkContainer>
          <NavItem onClick={toggleShowCalculatePnlDialog} className="ripple">
            Calculator
          </NavItem>
          <NavItem onClick={toggleShowFlipTossDialog} className="ripple">
            Flip Toss
          </NavItem>
        </NavDropdown>
        {showCalculatePnlDialog && <CalculatePnlDialog show={showCalculatePnlDialog} toggle={toggleShowCalculatePnlDialog} />}
        {showFlipTossDialog && <FlipTossDialog show={showFlipTossDialog} toggle={toggleShowFlipTossDialog} />}
      </Nav>
      <Nav pullRight>
        <AccountNavDropDown />
      </Nav>
    </>
  );
};

export default Menu;
