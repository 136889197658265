import { ContractPartial } from '../../../../types';

export interface GraphState {
  partialPanelCollapsed: boolean;
  selectedPartial: ContractPartial | undefined;
}

export const initalGraphState: GraphState = {
  partialPanelCollapsed: true,
  selectedPartial: undefined
};
