import * as React from 'react';
import { useState, useEffect } from 'react';

import { toTimeSeconds } from '../../../shared/utils/date-format-utils';

const INTERVAL_TIME = 1000; // 1 sec

interface Props {
  hhmm?: string;
}

const SystemClock = ({ hhmm }: Props) => {
  const [time, setTime] = useState<number>();

  useEffect(() => {
    let val = 0;
    if (hhmm) {
      const dt = new Date();
      const arr = hhmm.split(':');
      const hours = Number(arr[0]);
      const minutes = Number(arr[1]);
      val = dt.setHours(hours, minutes, dt.getSeconds());
    } else {
      val = 0;
    }
    setTime(val);
  }, [hhmm]);

  useEffect(() => {
    const to = setTimeout(() => {
      if (time) {
        setTime(time + INTERVAL_TIME);
      }
    }, INTERVAL_TIME);

    return () => {
      clearTimeout(to);
    };
  }, [time]);

  const clock = time ? toTimeSeconds(new Date(time)) : '- - : - -';
  return <span>{clock}</span>;
};

export default SystemClock;
