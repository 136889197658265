import * as React from 'react';

import classNames from 'classnames';

import { ValidationMode, Indicator } from '../../../../types';
import { compareTypes } from '../../utils/compare-utils';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const ContinuousMaForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label htmlFor="ci_periods" className="col-sm-6 control-label">
          Continuous Periods
        </label>
        <div className="col-sm-6">
          <input
            id="ci_periods"
            type="number"
            className="form-control"
            min="1"
            step="1"
            value={indicator.basisPeriods}
            onChange={(event) => update({ basisPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="compare_type" className="col-xs-12 col-sm-6 control-label">
          Continuous Type
        </label>
        <div className="col-sm-6">
          <select
            id="compare_type"
            className="form-control"
            value={indicator.compareType}
            onChange={(event) => update({ compareType: Number(event.target.value) })}
          >
            {compareTypes.map((x) => (
              <option key={x.key} value={x.key}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="compare_1_periods" className="col-sm-6 control-label">
          1st Periods
        </label>
        <div className="col-sm-6">
          <input
            id="compare_1_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.periods}
            onChange={(event) => update({ periods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="compare_mode" className="col-sm-6 control-label">
          Mode
        </label>
        <div className="col-sm-6">
          <div className="btn-group btn-group-justified" role="group">
            <div className="btn-group" role="group">
              <button
                key="btn-1"
                type="button"
                className={classNames('btn', 'btn-sm btn-default', 'ripple', { active: indicator.atrMode === ValidationMode.Above })}
                onClick={() => update({ atrMode: ValidationMode.Above })}
              >
                Above 2nd
              </button>
            </div>
            <div className="btn-group" role="group">
              <button
                key="btn-2"
                type="button"
                className={classNames('btn', 'btn-sm btn-default', 'ripple', { active: indicator.atrMode === ValidationMode.Below })}
                onClick={() => update({ atrMode: ValidationMode.Below })}
              >
                Below 2nd
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="compare_2_periods" className="col-sm-6 control-label">
          2nd Periods
        </label>
        <div className="col-sm-6">
          <input
            id="compare_2_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.secondaryPeriods}
            onChange={(event) => update({ secondaryPeriods: Number(event.target.value) })}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContinuousMaForm;
