import * as React from 'react';
import { useState, useEffect } from 'react';

import classNames from 'classnames';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Switch from 'react-switch';

import { GlobalState } from '../../../../core/root';
import { Strategy, MovingStopLoss, DoubleUpMode } from '../../../../types';
import CollapsePanel from '../../../shared/components/CollapsePanel';
import { getAmountTypeLabel } from '../../utils/risk-type-utils';

export interface RiskProps {
  strategyId: number;
  updateStrategy(strategy: Strategy): void;
}

const RiskTab: React.FunctionComponent<RiskProps> = ({ strategyId, updateStrategy }) => {
  const strategy = useSelector((state: GlobalState) => state.strategyState.strategies.find((x) => x.id === strategyId)) as Strategy;

  const [strategyForm, setStrategyForm] = useState<Strategy>(strategy);

  useEffect(() => {
    if (strategyForm != strategy) {
      setStrategyForm(strategy);
    }
  }, [strategy]);

  const mapFromState = (): Strategy => {
    return {
      ...strategy,
      ...strategyForm
    };
  };

  const updateStrategyHandler = () => {
    const str = mapFromState();
    updateStrategy(str);
  };

  const updateStrategyState = (obj: object) => {
    const field = Object.keys(obj)[0];
    const str = {
      ...strategyForm,
      [field]: obj[field]
    } as Strategy;
    if (strategyForm.group) {
      str.group = { ...strategyForm.group };
    }
    setStrategyForm(str);
  };

  const {
    profitType,
    profitAmount,
    lossType,
    lossAmount,
    useMaxIntradayLoss,
    intradayLossThreshold,
    useMaxIntradayProfit,
    intradayProfitThreshold,
    doubleUpMode,
    movingStopLoss,
    noLossLimitPct,
    trailingStopLossPct,
    useMaxMonthlyProfit,
    monthlyProfitThreshold,
    useMaxMonthlyLoss,
    monthlyLossThreshold,
    usePartialMonth,
    doubleUpOnLoss,
    isOptionTrading,
    isOptionCover,
    premiumAmount,
    optionDeltaPct,
    optionDeprecationPct
    // doubleUpOnLossType,
    // doubleUpOnLossMaxAmount,
    // doubleUpOnLossMaxSize
  } = strategyForm;

  const maxMonthlyPnlChecked = useMaxMonthlyProfit || useMaxMonthlyLoss;
  const maxIntradayPnlChecked = useMaxIntradayProfit || useMaxIntradayLoss;

  const popoverPosWarning = (
    <Popover id="less-then-zero" title="Warning">
      Value expected to be more than zero
    </Popover>
  );

  const popoverNegWarning = (
    <Popover id="less-then-zero" title="Warning">
      Value expected to be less than zero
    </Popover>
  );

  const noLossLimitPctInfo = (
    <Popover id="noLossLimitPctInfo">The limit reached in percent where the stop loss price moves to zero loss</Popover>
  );

  const trailingStopLossPctInfo = (
    <Popover id="trailingStopLossPctInfo">The distance in percentage which the stop loss price trails behind the close price</Popover>
  );

  const optionCoverInfo = (
    <Popover id="optionCoverInfo">
      1:1 up-down value. No delta. Max loss equals 3 * premium. Premium is also subtracted from the PnL.
    </Popover>
  );

  return (
    <div>
      <div className="row form-horizontal">
        <div className="col-md-6">
          <CollapsePanel title="Profit And Loss">
            <div className="form-group form-group-sm">
              <label htmlFor="fixed_limit" className="col-xs-12 col-sm-5 control-label">
                Profit Amount
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      {getAmountTypeLabel(profitType)}
                    </span>
                  </div>
                  <input
                    type="number"
                    step="any"
                    min="0"
                    value={profitAmount}
                    onChange={(event) =>
                      updateStrategyState({
                        profitAmount: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': doubleUpOnLoss
                    })}
                    id="fixed_limit"
                    placeholder="Amount"
                  />
                </div>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="fixed_stopp_loss" className="col-xs-12 col-sm-5 control-label">
                <span>Loss Amount</span>
                {lossAmount > 0 && (
                  <OverlayTrigger placement="top" overlay={popoverNegWarning}>
                    <span className="gap-left-10">
                      <i className="fa fa-warning fa-lg warning-text" />
                    </span>
                  </OverlayTrigger>
                )}
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      {getAmountTypeLabel(lossType)}
                    </span>
                  </div>
                  <input
                    type="number"
                    step="any"
                    max="0"
                    value={lossAmount}
                    onChange={(event) =>
                      updateStrategyState({
                        lossAmount: Number(event.target.value)
                      })
                    }
                    className="form-control"
                    id="fixed_stopp_loss"
                    placeholder="Amount"
                  />
                </div>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label className="col-sm-5 control-label">Use Double Loss as Profit</label>
              <div className="col-sm-7">
                <label className="react-switch react-switch--sm">
                  <Switch
                    onChange={() =>
                      updateStrategyState({
                        doubleUpOnLoss: !doubleUpOnLoss
                      })
                    }
                    checked={doubleUpOnLoss}
                  />
                </label>
              </div>
            </div>
          </CollapsePanel>

          <CollapsePanel title="Option Trading" collapsed={!isOptionTrading} checked={isOptionTrading}>
            <div className="form-group form-group-sm">
              <label className="col-sm-5 control-label">Is Option Trading</label>
              <div className="col-sm-7">
                <label className="react-switch react-switch--sm">
                  <Switch
                    onChange={() =>
                      updateStrategyState({
                        isOptionTrading: !isOptionTrading
                      })
                    }
                    checked={isOptionTrading}
                  />
                </label>
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label className="col-sm-5 control-label">
                Is Option Cover
                <OverlayTrigger placement="top" overlay={optionCoverInfo}>
                  <span className="gap-left-10">
                    <i className="fa fa-question-circle fa-lg text-info" />
                  </span>
                </OverlayTrigger>
              </label>
              <div className="col-sm-7">
                <label className="react-switch react-switch--sm">
                  <Switch
                    onChange={() =>
                      updateStrategyState({
                        isOptionCover: !isOptionCover
                      })
                    }
                    checked={isOptionCover}
                  />
                </label>
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label htmlFor="max_intra_loss" className="col-xs-12 col-sm-5 control-label">
                <span>Option Premium</span>
                {premiumAmount > 0 && (
                  <OverlayTrigger placement="top" overlay={popoverNegWarning}>
                    <span className="gap-left-10">
                      <i className="fa fa-warning fa-lg warning-text" />
                    </span>
                  </OverlayTrigger>
                )}
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      $
                    </span>
                  </div>
                  <input
                    type="number"
                    step="any"
                    max="0"
                    value={premiumAmount}
                    onChange={(event) =>
                      updateStrategyState({
                        premiumAmount: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !isOptionTrading
                    })}
                    id="max_intra_loss"
                    placeholder="Amount"
                  />
                </div>
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label className="col-xs-12 col-sm-5 control-label" htmlFor="option_delta">
                Option Delta
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      %
                    </span>
                  </div>
                  <input
                    type="number"
                    step="any"
                    min="1"
                    max="100"
                    value={optionDeltaPct}
                    onChange={(event) =>
                      updateStrategyState({
                        optionDeltaPct: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !isOptionTrading
                    })}
                    id="option_delta"
                    placeholder="Percentage"
                  />
                </div>
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label className="col-xs-12 col-sm-5 control-label" htmlFor="option_depr">
                Option Deprecation
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      %
                    </span>
                  </div>
                  <input
                    type="number"
                    step="any"
                    min="1"
                    max="100"
                    value={optionDeprecationPct}
                    onChange={(event) =>
                      updateStrategyState({
                        optionDeprecationPct: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !isOptionTrading
                    })}
                    id="option_depr"
                    placeholder="Percentage"
                  />
                </div>
              </div>
            </div>
          </CollapsePanel>
        </div>

        <div className="col-md-6">
          <CollapsePanel title="Intraday PnL" collapsed={!maxIntradayPnlChecked} checked={maxIntradayPnlChecked}>
            <div className="form-group form-group-sm">
              <label className="col-sm-5 control-label">Use Max Intraday Profit</label>
              <div className="col-sm-7">
                <label className="react-switch react-switch--sm">
                  <Switch
                    onChange={() =>
                      updateStrategyState({
                        useMaxIntradayProfit: !useMaxIntradayProfit
                      })
                    }
                    checked={useMaxIntradayProfit}
                  />
                </label>
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label htmlFor="max_intra_profit" className="col-xs-12 col-sm-5 control-label">
                <span>Max Intraday Profit</span>
                {intradayProfitThreshold < 0 && (
                  <OverlayTrigger placement="top" overlay={popoverPosWarning}>
                    <span className="gap-left-10">
                      <i className="fa fa-warning fa-lg warning-text" />
                    </span>
                  </OverlayTrigger>
                )}
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      $
                    </span>
                  </div>
                  <input
                    type="number"
                    step="any"
                    max="0"
                    value={intradayProfitThreshold}
                    onChange={(event) =>
                      updateStrategyState({
                        intradayProfitThreshold: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !useMaxIntradayProfit
                    })}
                    id="max_intra_profit"
                    placeholder="Amount"
                  />
                </div>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label className="col-sm-5 control-label">Use Max Intraday Loss</label>
              <div className="col-sm-7">
                <label className="react-switch react-switch--sm">
                  <Switch
                    onChange={() =>
                      updateStrategyState({
                        useMaxIntradayLoss: !useMaxIntradayLoss
                      })
                    }
                    checked={useMaxIntradayLoss}
                  />
                </label>
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label htmlFor="max_intra_loss" className="col-xs-12 col-sm-5 control-label">
                <span>Max Intraday Loss</span>
                {intradayLossThreshold > 0 && (
                  <OverlayTrigger placement="top" overlay={popoverNegWarning}>
                    <span className="gap-left-10">
                      <i className="fa fa-warning fa-lg warning-text" />
                    </span>
                  </OverlayTrigger>
                )}
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      $
                    </span>
                  </div>
                  <input
                    type="number"
                    step="any"
                    max="0"
                    value={intradayLossThreshold}
                    onChange={(event) =>
                      updateStrategyState({
                        intradayLossThreshold: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !useMaxIntradayLoss
                    })}
                    id="max_intra_loss"
                    placeholder="Amount"
                  />
                </div>
              </div>
            </div>
          </CollapsePanel>

          <CollapsePanel title="Monthly PnL" collapsed={!maxMonthlyPnlChecked} checked={maxMonthlyPnlChecked}>
            <div className="form-group form-group-sm">
              <label className="col-sm-5 control-label">Use Max Monthly Profit</label>
              <div className="col-sm-7">
                <label className="react-switch react-switch--sm">
                  <Switch
                    onChange={() =>
                      updateStrategyState({
                        useMaxMonthlyProfit: !useMaxMonthlyProfit
                      })
                    }
                    checked={useMaxMonthlyProfit}
                  />
                </label>
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label htmlFor="max_monthly_profit" className="col-xs-12 col-sm-5 control-label">
                <span>Max Monthly Profit</span>
                {monthlyProfitThreshold < 0 && (
                  <OverlayTrigger placement="top" overlay={popoverPosWarning}>
                    <span className="gap-left-10">
                      <i className="fa fa-warning fa-lg warning-text" />
                    </span>
                  </OverlayTrigger>
                )}
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      $
                    </span>
                  </div>
                  <input
                    type="number"
                    step="any"
                    max="0"
                    value={monthlyProfitThreshold}
                    onChange={(event) =>
                      updateStrategyState({
                        monthlyProfitThreshold: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !useMaxMonthlyProfit
                    })}
                    id="max_monthly_profit"
                    placeholder="Amount"
                  />
                </div>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label className="col-sm-5 control-label">Use Max Monthly Loss</label>
              <div className="col-sm-7">
                <label className="react-switch react-switch--sm">
                  <Switch
                    onChange={() =>
                      updateStrategyState({
                        useMaxMonthlyLoss: !useMaxMonthlyLoss
                      })
                    }
                    checked={useMaxMonthlyLoss}
                  />
                </label>
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label htmlFor="max_monthly_loss" className="col-xs-12 col-sm-5 control-label">
                <span>Max Monthly Loss</span>
                {monthlyLossThreshold > 0 && (
                  <OverlayTrigger placement="top" overlay={popoverNegWarning}>
                    <span className="gap-left-10">
                      <i className="fa fa-warning fa-lg warning-text" />
                    </span>
                  </OverlayTrigger>
                )}
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      $
                    </span>
                  </div>
                  <input
                    type="number"
                    step="any"
                    max="0"
                    value={monthlyLossThreshold}
                    onChange={(event) =>
                      updateStrategyState({
                        monthlyLossThreshold: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !useMaxMonthlyLoss
                    })}
                    id="max_monthly_loss"
                    placeholder="Amount"
                  />
                </div>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="indicator_type" className="col-sm-5 control-label">
                Month Type
              </label>
              <div className="col-sm-7">
                <label className="radio-inline small">
                  <input
                    id="indicator_type"
                    type="radio"
                    checked={!usePartialMonth}
                    onChange={() => {
                      updateStrategyState({ usePartialMonth: !usePartialMonth });
                    }}
                  />{' '}
                  Calendar Month
                </label>
              </div>
              <div className="col-sm-offset-5 col-sm-7">
                <label className="radio-inline small">
                  <input
                    type="radio"
                    checked={usePartialMonth}
                    onChange={() => {
                      updateStrategyState({ usePartialMonth: !usePartialMonth });
                    }}
                  />{' '}
                  Partial Month
                </label>
              </div>
            </div>
          </CollapsePanel>

          <CollapsePanel
            title="Double-Up On Profit"
            collapsed={doubleUpMode === DoubleUpMode.None}
            checked={doubleUpMode !== DoubleUpMode.None}
          >
            <div className="form-group form-group-sm gap-bottom-20">
              <label htmlFor="doubleup_mode" className="col-sm-5 control-label">
                Mode
              </label>
              <div className="col-sm-7">
                <label className="radio-inline small">
                  <input
                    id="doubleup_mode"
                    type="radio"
                    value={DoubleUpMode.None}
                    checked={doubleUpMode === DoubleUpMode.None}
                    onChange={(event) => {
                      if (event.currentTarget.value) {
                        updateStrategyState({
                          doubleUpMode: DoubleUpMode.None
                        });
                      }
                    }}
                  />{' '}
                  Disabled
                </label>
              </div>
              <div className="col-sm-offset-5 col-sm-7">
                <label className="radio-inline small">
                  <input
                    type="radio"
                    value={DoubleUpMode.StreakOfOne}
                    checked={doubleUpMode === DoubleUpMode.StreakOfOne}
                    onChange={(event) => {
                      if (event.currentTarget.value) {
                        updateStrategyState({
                          doubleUpMode: DoubleUpMode.StreakOfOne
                        });
                      }
                    }}
                  />{' '}
                  After 1st Profit
                </label>
              </div>
              <div className="col-sm-offset-5 col-sm-7">
                <label className="radio-inline small">
                  <input
                    type="radio"
                    value={DoubleUpMode.StreakOfTwo}
                    checked={doubleUpMode === DoubleUpMode.StreakOfTwo}
                    onChange={(event) => {
                      if (event.currentTarget.value) {
                        updateStrategyState({
                          doubleUpMode: DoubleUpMode.StreakOfTwo
                        });
                      }
                    }}
                  />{' '}
                  After 2nd Profit
                </label>
              </div>
            </div>
          </CollapsePanel>

          {/* <CollapsePanel title="Double-Up On Loss" collapsed={!doubleUpOnLoss} checked={doubleUpOnLoss}>
            <div className="form-group form-group-sm">
              <label className="col-sm-5 control-label">Use Double-Up On Loss</label>
              <div className="col-sm-7">
                <label className="react-switch react-switch--sm">
                  <Switch
                    onChange={() =>
                      updateStrategyState({
                        doubleUpOnLoss: !doubleUpOnLoss
                      })
                    }
                    checked={doubleUpOnLoss}
                  />
                </label>
              </div>
            </div>
            <div className="form-group form-group-sm gap-bottom-20">
              <label htmlFor="doubleup_loss_type" className="col-sm-5 control-label">
                Type
              </label>
              <div className="col-sm-7">
                <label className="radio-inline small">
                  <input
                    type="radio"
                    value={DoubleUpType.Size}
                    checked={doubleUpOnLossType === DoubleUpType.Size}
                    onChange={(event) => {
                      if (event.currentTarget.value) {
                        updateStrategyState({
                          doubleUpOnLossType: DoubleUpType.Size
                        });
                      }
                    }}
                  />{' '}
                  Order Size
                </label>
              </div>
              <div className="col-sm-offset-5 col-sm-7">
                <label className="radio-inline small">
                  <input
                    id="doubleup_loss_type"
                    type="radio"
                    value={DoubleUpType.Amount}
                    checked={doubleUpOnLossType === DoubleUpType.Amount}
                    onChange={(event) => {
                      if (event.currentTarget.value) {
                        updateStrategyState({
                          doubleUpOnLossType: DoubleUpType.Amount
                        });
                      }
                    }}
                  />{' '}
                  PnL
                </label>
              </div>
            </div> 

            <div className="form-group form-group-sm">
              <label htmlFor="trailing_stp_pct" className="col-sm-5 control-label">
                <span>Max Order Size</span>
              </label>
              <div className="col-sm-7">
                <input
                  type="number"
                  step="1"
                  min="0"
                  value={doubleUpOnLossMaxSize}
                  onChange={(event) =>
                    updateStrategyState({
                      doubleUpOnLossMaxSize: Number(event.target.value)
                    })
                  }
                  className={classNames('form-control', {
                    'inactive-control': doubleUpOnLossType !== DoubleUpType.Size
                  })}
                  id="trailing_stp_pct"
                />
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label htmlFor="trailing_stp_pct" className="col-sm-5 control-label">
                <span>Max Amount</span>
              </label>
              <div className="col-sm-7">
                <input
                  type="number"
                  step="any"
                  min="0"
                  value={doubleUpOnLossMaxAmount}
                  onChange={(event) =>
                    updateStrategyState({
                      doubleUpOnLossMaxAmount: Number(event.target.value)
                    })
                  }
                  className={classNames('form-control', {
                    'inactive-control': doubleUpOnLossType !== DoubleUpType.Amount
                  })}
                  id="trailing_stp_pct"
                />
              </div>
            </div>
          </CollapsePanel>
          */}

          <CollapsePanel
            title="Moving Stop Loss"
            collapsed={movingStopLoss === MovingStopLoss.Off}
            checked={movingStopLoss !== MovingStopLoss.Off}
          >
            <div className="form-group form-group-sm">
              <label htmlFor="moving_stop_loss" className="col-sm-5 control-label">
                Mode
              </label>
              <div className="col-sm-7">
                <label className="radio-inline small">
                  <input
                    id="moving_stop_loss"
                    type="radio"
                    value={MovingStopLoss.Off}
                    checked={movingStopLoss === MovingStopLoss.Off}
                    onChange={(event) => {
                      if (event.currentTarget.value) {
                        updateStrategyState({
                          movingStopLoss: MovingStopLoss.Off
                        });
                      }
                    }}
                  />{' '}
                  Off
                </label>
              </div>
              <div className="col-sm-offset-5 col-sm-7">
                <label className="radio-inline small">
                  <input
                    type="radio"
                    value={MovingStopLoss.NoLoss}
                    checked={movingStopLoss === MovingStopLoss.NoLoss}
                    onChange={(event) => {
                      if (event.currentTarget.value) {
                        updateStrategyState({
                          movingStopLoss: MovingStopLoss.NoLoss
                        });
                      }
                    }}
                  />{' '}
                  Zero Loss
                </label>
              </div>
              <div className="col-sm-offset-5 col-sm-7">
                <label className="radio-inline small">
                  <input
                    type="radio"
                    value={MovingStopLoss.Trailing}
                    checked={movingStopLoss === MovingStopLoss.Trailing}
                    onChange={(event) => {
                      if (event.currentTarget.value) {
                        updateStrategyState({
                          movingStopLoss: MovingStopLoss.Trailing
                        });
                      }
                    }}
                  />{' '}
                  Fixed Trailing
                </label>
              </div>
              <div className="col-sm-offset-5 col-sm-7">
                <label className="radio-inline small">
                  <input
                    type="radio"
                    value={MovingStopLoss.FluentTrailing}
                    checked={movingStopLoss === MovingStopLoss.FluentTrailing}
                    onChange={(event) => {
                      if (event.currentTarget.value) {
                        updateStrategyState({
                          movingStopLoss: MovingStopLoss.FluentTrailing
                        });
                      }
                    }}
                  />{' '}
                  Fluent Trailing
                </label>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="no_loss_pct" className="col-sm-5 control-label">
                <span>Zero Loss Pct</span>
                <OverlayTrigger placement="top" overlay={noLossLimitPctInfo}>
                  <span className="gap-left-10">
                    <i className="fa fa-question-circle fa-lg text-info" />
                  </span>
                </OverlayTrigger>
              </label>
              <div className="col-sm-7">
                <input
                  type="number"
                  step="1"
                  min="0"
                  max="100"
                  value={noLossLimitPct}
                  onChange={(event) =>
                    updateStrategyState({
                      noLossLimitPct: Number(event.target.value)
                    })
                  }
                  className={classNames('form-control', {
                    'inactive-control': movingStopLoss !== MovingStopLoss.NoLoss
                  })}
                  id="no_loss_pct"
                />
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="trailing_stp_pct" className="col-sm-5 control-label">
                <span>Fixed Trailing Pct</span>
                <OverlayTrigger placement="top" overlay={trailingStopLossPctInfo}>
                  <span className="gap-left-10">
                    <i className="fa fa-question-circle fa-lg text-info" />
                  </span>
                </OverlayTrigger>
              </label>
              <div className="col-sm-7">
                <input
                  type="number"
                  step="1"
                  min="0"
                  max="100"
                  value={trailingStopLossPct}
                  onChange={(event) =>
                    updateStrategyState({
                      trailingStopLossPct: Number(event.target.value)
                    })
                  }
                  className={classNames('form-control', {
                    'inactive-control': movingStopLoss !== MovingStopLoss.Trailing
                  })}
                  id="trailing_stp_pct"
                />
              </div>
            </div>
          </CollapsePanel>
        </div>
      </div>

      <hr />
      <div className="row">
        <div className="col-xs-12 text-right">
          <button type="button" className="btn btn-primary ripple gap-bottom-5" onClick={updateStrategyHandler}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default RiskTab;
