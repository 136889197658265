import React, { FunctionComponent, SyntheticEvent } from 'react';
import { useEffect } from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Backtest, ContractPartialQuote, Indicator, BacktestStatus, Strategy } from '../../../types';
import { getIndicators } from '../../indicator/store/selectors';
import { hasQuotesForBacktesting } from '../../shared/utils/backtest-utils';
import { ModuleState } from '../state';
import { runBacktestAsync } from '../store/backtest-manager/service';
import * as actionCreators from '../store/backtest/action-creators';

export interface BacktestButtonProps {
  securityId: number;
  strategyId: number;
  backtest: Backtest;
  className?: string;
}

const BacktestButton: FunctionComponent<BacktestButtonProps> = ({ securityId, strategyId, backtest, className }: BacktestButtonProps) => {
  useEffect(() => {
    // mounting..
    return () => {
      // unmounting..
    };
  }, []);

  const btnClasses = ['btn', 'ripple'];
  if (className) {
    btnClasses.push(className);
  }

  const state: ModuleState = useSelector((gs: ModuleState) => gs);
  const strategy = state.strategyState.strategies.find((x) => x.id === strategyId);
  const indicators: Indicator[] = getIndicators(state, strategyId)?.filter((x) => x.enabled) || [];

  const partialQuotes: ContractPartialQuote[] = useSelector((state: ModuleState) => {
    if (state.partialQuoteState.loaded) {
      return state.partialQuoteState.partialQuotes.filter((x) => x.contractPartialId === backtest.contractPartialId);
    }
    return [];
  });
  const hasQuotes = hasQuotesForBacktesting(strategy as Strategy, indicators, partialQuotes);

  const dispatch = useDispatch();

  const runBacktest = () => {
    dispatch(runBacktestAsync(backtest, securityId));
  };

  const runBacktestHandler = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    backtest.status = BacktestStatus.Running;
    dispatch(actionCreators.updateBacktest(securityId, strategyId, backtest));

    runBacktest();
    // setTimeout(() => {
    //   backtest.status = BacktestStatus.Completed;
    //   dispatch(actionCreators.updateBacktest(backtest));
    // }, 2000);
  };

  return (
    <button
      className={classNames(btnClasses, {
        'bt-btn__loading': backtest.status === BacktestStatus.Running,
        'btn-success': hasQuotes,
        'btn-default': !hasQuotes
      })}
      onClick={(event: SyntheticEvent) => runBacktestHandler(event)}
      disabled={!hasQuotes || backtest.status === BacktestStatus.Running}
    >
      <span>Backtest</span>
    </button>
  );
};

export default BacktestButton;
