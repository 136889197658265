import React, { FunctionComponent } from 'react';

import { createUseStyles, useTheme } from 'react-jss';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Theme } from '../jss/theme';
import { GlobalState } from '../root';

const useStyles = createUseStyles((x: Theme) => ({
  footerPanel: {
    padding: '20px',
    textAlign: 'center',
    color: x.palette.grayDark,
    background: x.palette.brandSuccess,
    fontSize: '14px'
  },
  mb5: {
    marginBottom: x.spacing.xs
  },
  mb5_bold: {
    composes: ['$mb5'], // arv: egendefinerte klasser (merk prefix: $)
    fontWeight: 'bold'
  },
  mb5_bold_h: {
    composes: ['$mb5_bold'],
    fontWeight: 'bold',
    paddingTop: '0.8rem'
  },
  navButton: {
    composes: ['btn', 'btn-link', 'ripple'], // arv: med klasser fra vendors (bootsrap, awesome etc.)
    '&:hover': {
      color: x.palette.brandSuccessDarker
    }
  }
}));

interface FooterProps extends RouteComponentProps {
  name?: string;
  version?: string;
}

const Footer: FunctionComponent<FooterProps> = ({ version, history }: FooterProps) => {
  const state: GlobalState = useSelector((gs: GlobalState) => gs);
  const status = state.scheduleStatusState.status;
  const statusLoaded = state.scheduleStatusState.loaded;
  const statusError = state.scheduleStatusState.error;

  const theme: Theme = useTheme();
  const styles = useStyles({ theme });

  const refreshBtnHandler = () => {
    window.location.reload();
    // window.location.replace("/")
  };

  const historyBackHandler = () => {
    if (history.length > 0) {
      history.goBack();
    }
  };

  let webApiVersion: string | undefined;
  let scheduleAppVersion: string | undefined;
  if (statusLoaded || statusError) {
    webApiVersion = !!status.webApiVersion ? `v${status.webApiVersion}  ` : 'OFFLINE';
    scheduleAppVersion = !!status.appVersion ? `v${status.appVersion}` : 'OFFLINE';
  }

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 text-center gap-bottom-5">
            <button type="button" title="Hard Refresh" className={styles.navButton} onClick={refreshBtnHandler}>
              <i className="fa fa-lg fa-repeat" aria-hidden="true"></i>
            </button>
            <button type="button" title="Back" className={styles.navButton} onClick={historyBackHandler}>
              <i className="fa fa-lg fa-arrow-left" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <div className={styles.footerPanel}>
        <p className={styles.mb5_bold}>
          {/* {name} */}
          {/* <Link to="/" className="ripple" onClick={() => window.location.reload()}>{name}</Link> */}
        </p>
        <p className={styles.mb5}>Frontend: {`v${version}`}</p>
        <p className={styles.mb5}>
          <span>Web API: {webApiVersion}</span>
        </p>
        <p className={styles.mb5}>Schedule App: {scheduleAppVersion}</p>
        {/* <p className={styles.mb5}>Next patch: continuous</p> */}
        {/* <p className={styles.mb5}>Next release: 08.01.2021</p> */}
        {/* <p className={classes['mb-5']} style={{ fontSize: '12px' }}>Copyright 2020</p> */}
      </div>
    </div>
  );
};

export default withRouter(Footer);
