import * as React from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { GlobalState } from '../../../core/root';
import { PeriodType, Security, Strategy } from '../../../types';
import CategoryFilterPanel from '../../security/components/CategoryFilterPanel';
import SecurityPickerPanel from '../../security/components/SecurityPickerPanel';
import * as securityActionCreators from '../../security/store/action-creators';
import CollapsePanel from '../../shared/components/CollapsePanel';
import * as layoutActionCreators from '../../shared/store/layout/action-creators';
import { PositionTypeOption } from '../../shared/utils/position-type-option';
import StrategyPickerPanel from '../../strategy/components/StrategyPickerPanel';
import * as strategyActionCreators from '../../strategy/store/action-creators';

const PositionFilter: React.FunctionComponent = () => {
  const positionFilterCollapsed: boolean = useSelector((state: GlobalState) => state.layoutState.ui.closedPositionsFilterCollapsed);
  const selectedSecurity: Security | undefined = useSelector((state: GlobalState) => state.securityState.ui.securityPicked);
  const selectedStrategy: Strategy | undefined = useSelector((state: GlobalState) => state.strategyState.ui.strategyPicked);
  const posTypeOptions: PositionTypeOption[] = useSelector((state: GlobalState) => state.layoutState.ui.positionTypeOptions);

  const dispatch = useDispatch();

  const togglePosTypeSelectedHandler = (posTypeOption: PositionTypeOption) => {
    const list = [...posTypeOptions];
    let pressed: PositionTypeOption | undefined;
    if (posTypeOption.positionType === PeriodType.Live) {
      pressed = list.find((x) => x.positionType === posTypeOption.positionType && x.account === posTypeOption.account);
    } else {
      pressed = list.find((x) => x.positionType === posTypeOption.positionType);
    }
    if (pressed !== undefined) {
      pressed.selected = !pressed.selected;
      dispatch(layoutActionCreators.setPositionTypeOptions(list));
    }
  };

  const posTypeOptionButtons = posTypeOptions.map((x, index) => (
    <button
      key={index}
      type="button"
      className={classNames('btn', 'btn-sm', 'btn-default', 'gap-bottom-5', { active: x.selected })}
      onClick={() => togglePosTypeSelectedHandler(x)}
    >
      {x.positionTypeName}
    </button>
  ));

  const menuBar = (
    <div>
      <div className="btn-toolbar">{posTypeOptionButtons}</div>
      <hr className="gap-top-5 gap-bottom-20" />
      <SecurityPickerPanel smallTitle="Optional">
        <CategoryFilterPanel />
      </SecurityPickerPanel>
      <StrategyPickerPanel smallTitle="Optional" />
    </div>
  );

  const toggleFilterPanel = () => {
    dispatch(layoutActionCreators.setClosedPositionsPanelFilterCollapsed(!positionFilterCollapsed));
  };

  const clearFilterHandler = () => {
    const list = [...posTypeOptions];
    list.forEach((x) => (x.selected = false));
    dispatch(layoutActionCreators.setPositionTypeOptions(list));
    dispatch(securityActionCreators.setSecurityPicked(undefined));
    dispatch(strategyActionCreators.setStrategyPicked(undefined));
  };

  const posTypeSelected = posTypeOptions.filter((x) => x.selected).length > 0;
  const isFiltered = posTypeSelected || !!selectedSecurity || !!selectedStrategy;

  return (
    <CollapsePanel title="Filter" upper={false} collapsed={positionFilterCollapsed} onToggle={toggleFilterPanel} checked={isFiltered}>
      {menuBar}
      <div className="text-right">
        <button className="btn btn-sm btn-default" type="submit" onClick={clearFilterHandler}>
          <span>Clear Filter</span>
        </button>
      </div>
    </CollapsePanel>
  );
};

export default PositionFilter;
