import { showNetworkError } from '../../shared/utils/growl-utils';

import { setIndicatorResults, setIndicatorsError } from './action-creators';
import { IndicatorResultHttpRequest } from './http-request';

export const loadIndicatorResultsAsync = (periodId: number) => {
  return (dispatch) => {
    IndicatorResultHttpRequest.get(periodId)
      .then((response) => {
        // console.log(response);
        dispatch(setIndicatorResults(periodId, response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load indicators');
        dispatch(setIndicatorsError(periodId, msg));
      });
  };
};
