import { AnyAction } from 'redux';

import { AuthToken } from '../models/auth-token';

import { AuthAction } from './action-creators';
import * as actionTypes from './action-types';
import { initalAuthState } from './auth-state';

export const authReducer = (state = initalAuthState, action: AuthAction | AnyAction) => {
  if (action.type === actionTypes.AUTH_START) {
    return {
      ...state,
      error: undefined,
      loading: true,
      loaded: false
    };
  } else if (action.type === actionTypes.AUTH_SUCCESS) {
    const authToken = action.authToken as AuthToken;
    return {
      ...state,
      error: undefined,
      token: authToken.accessToken,
      accountId: Number(authToken.accountId),
      userName: authToken.userName,
      firstName: authToken.firstName,
      loading: false,
      loaded: true,
      attempts: 0
    };
  } else if (action.type === actionTypes.AUTH_LOGOUT) {
    return {
      ...state,
      error: undefined,
      token: undefined,
      accountId: undefined,
      userName: undefined,
      firstName: undefined,
      loading: false,
      loaded: true
    };
  } else if (action.type === actionTypes.AUTH_FAIL) {
    return {
      ...state,
      error: action.value,
      token: undefined,
      accountId: undefined,
      userName: undefined,
      firstName: undefined,
      loading: false,
      loaded: true,
      attempts: state.attempts + 1
    };
  } else if (action.type === actionTypes.SET_AUTH_REDIRECT_PATH) {
    return {
      ...state,
      authRedirectPath: action.value
    };
  }

  return state;
};
