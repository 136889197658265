import { ScheduleStatus } from '../../../../types';

export interface ScheduleStatusState {
  loading: boolean;
  loaded: boolean;
  error?: string;
  status: ScheduleStatus;
}

export const initialState: ScheduleStatusState = {
  loading: false,
  loaded: false,
  error: undefined,
  status: {
    webApiVersion: '',
    webApiTimeZone: 0,
    webApiTimestamp: '',
    appVersion: '',
    appTimestamp: '',
    isAppRunning: false,
    isScheduleStarted: false
  }
};
