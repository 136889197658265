import * as React from 'react';

import classNames from 'classnames';

import { TrendDirection } from '../../../types';

export interface TrendDirectionAtomProps {
  trendDirection: TrendDirection;
  positionSize: number;
}

const TrendDirectionAtom: React.SFC<TrendDirectionAtomProps> = ({ trendDirection, positionSize }: TrendDirectionAtomProps) => {
  switch (trendDirection) {
    case TrendDirection.Up:
      return (
        <span
          className={classNames('glyphicon', 'glyphicon-arrow-up', 'trend-light', { short: positionSize < 0, long: positionSize > 0 })}
        />
      );
    case TrendDirection.UpStronger:
      return (
        <span
          className={classNames('glyphicon', 'glyphicon-arrow-up', 'trend-strong', { short: positionSize < 0, long: positionSize > 0 })}
        />
      );
    case TrendDirection.Down:
      return (
        <span
          className={classNames('glyphicon', 'glyphicon-arrow-down', 'trend-light', { short: positionSize < 0, long: positionSize > 0 })}
        />
      );
    case TrendDirection.DownStronger:
      return (
        <span
          className={classNames('glyphicon', 'glyphicon-arrow-down', 'trend-strong', { short: positionSize < 0, long: positionSize > 0 })}
        />
      );
    default:
      return <span className="glyphicon glyphicon-arrow-right grey" />;
  }
};

export default TrendDirectionAtom;
