export interface Group<T> {
  key: string;
  list: T[];
}

export const groupBy = <T>(xs: T[], key: string) => {
  const obj = xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

  const groupList: Group<T>[] = [];
  for (const prop in obj) {
    const group: Group<T> = { key: prop, list: obj[prop] };
    groupList.push(group);
  }
  return groupList;
};
