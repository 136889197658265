import * as React from 'react';

import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Backtest } from '../../../types';
import * as actionCreators from '../store/backtest/action-creators';

import BacktestTable from './BacktestTable';

export interface BacktestListProps extends RouteComponentProps {
  securityId: number;
  strategyId: number;
  backtests: Backtest[];
}

const BacktestList: React.SFC<BacktestListProps> = ({ securityId, strategyId, backtests }: BacktestListProps) => {
  const dispatch = useDispatch();
  const generateBacktests = () => {
    dispatch(actionCreators.generateBacktestsAsync(securityId, strategyId));
  };

  return (
    <div>
      {backtests && backtests.length > 0 ? (
        <BacktestTable securityId={securityId} strategyId={strategyId} backtests={backtests} />
      ) : (
        <p className="small">No backtests..</p>
      )}

      <hr />
      <div className="text-right">
        <button className="btn btn-sm btn-success gap-top-20" type="submit" onClick={generateBacktests}>
          Generate
        </button>
      </div>
    </div>
  );
};

export default withRouter(BacktestList);
