import * as React from 'react';
import { useState, useEffect } from 'react';
import { SyntheticEvent } from 'react';

import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { GlobalState } from '../../../core/root';
import { Forecast, MarketTradingHours, Period, Security, Strategy } from '../../../types';
import GraphDialog from '../../graph/components/GraphDialog';
import { loadSecuritiesAsync } from '../../security/store/action-creators';
import Spinner from '../../shared/components/Spinner';
import { CONTRACT_DETAILS_URL } from '../../shared/constants/links';
import { getBarSizeName } from '../../shared/utils/bar-utils';
import { formatUsd } from '../../shared/utils/currency-utils';
import { toTime } from '../../shared/utils/date-format-utils';
import { setDecimalSpaces, setThousenSeparator } from '../../shared/utils/number-utils';
import { loadStrategiesAsync } from '../../strategy/store/services';
import { loadSubscriptionsAsync } from '../../subscription/store/action-creators';
import { sortPeriodsWithSubscriptions, SubscriptionWrapper } from '../../subscription/utils/sort-subscriptions';
import { periodModulePath } from '../period-routes';

export interface Props extends RouteComponentProps {
  periods: Period[];
  single?: boolean;
}

const PeriodTable: React.FunctionComponent<Props> = ({ periods, history, single }: Props) => {
  const state: GlobalState = useSelector((gs: GlobalState) => gs);
  const securities = state.securityState.securities;
  const securitiesLoaded = state.securityState.loaded;
  const strategies = state.strategyState.strategies;
  const strategiesLoaded = state.strategyState.loaded;
  const subscriptions = state.subscriptionState.subscriptions;
  const subscriptionsLoaded = state.subscriptionState.loaded;

  const [showGraphDialog, setShowGraphDialog] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState<Period>();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!securitiesLoaded) {
      dispatch(loadSecuritiesAsync());
    }
    if (!strategiesLoaded) {
      dispatch(loadStrategiesAsync());
    }
    if (!subscriptionsLoaded) {
      dispatch(loadSubscriptionsAsync());
    }
  }, []);

  const loading = !securitiesLoaded || !strategiesLoaded;

  if (loading) {
    return <Spinner />;
  }

  const rowClickHandler = (period: Period) => {
    const route = `${periodModulePath}/${period.id}`;
    history.push(route);
  };

  const toggleShowGraphDialog = () => {
    setShowGraphDialog(!showGraphDialog);
  };

  const showGraphHandler = (e: SyntheticEvent, period: Period) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setSelectedPeriod(period);
    toggleShowGraphDialog();
  };

  const showInfoHandler = (e: SyntheticEvent, period: Period) => {
    e.stopPropagation();
    const contractRefId = period.contract?.refId;
    const url = `${CONTRACT_DETAILS_URL}${contractRefId}`;
    window.open(url, '_blank');
  };

  const header = (
    <tr>
      {!single && <th className="middle small">Subscription</th>}

      <td className="middle small">Time</td>
      <td className="middle small text-center">Mrkt.</td>
      <td className="middle small text-center">Succ.</td>
      <td className="middle small text-center">Forecast</td>

      <td className="middle small text-right">Price</td>
      <td className="middle small text-right">Volume</td>
      <td className="middle small text-center"></td>
      <td className="middle small text-center"></td>

      <td className="middle small">Strategy</td>
      <td className="middle small">Interval</td>
      <td className="middle small text-center">Pos.</td>
      <td className="middle small text-right">Prds.</td>
      <td className="middle small text-right">Unrlz.</td>

      <td className="middle small text-right">Limit</td>
      <td className="middle small text-right">Stop</td>
      <td className="middle small text-center">MSL</td>

      <td className="middle small wrap" style={{ minWidth: '150px' }}>
        Forecast Reason
      </td>
    </tr>
  );

  periods.forEach((x) => {
    x.security = securities.find((y) => y.id === x.securityId) as Security;
    x.strategy = strategies.find((y) => y.id === x.strategyId) as Strategy;
  });

  const periodsWithSubscriptions = periods.map(
    (x) =>
      ({
        period: x,
        subscription: subscriptions.find((y) => y.id === x.subscriptionId)
      } as SubscriptionWrapper)
  );

  const sort = (a: SubscriptionWrapper, b: SubscriptionWrapper) => {
    if (single) {
      const aCreated = a.period.created;
      const bCreated = b.period.created;
      if (aCreated > bCreated) return 1;
      if (aCreated < bCreated) return -1;
      return 0;
    } else {
      return sortPeriodsWithSubscriptions(a, b);
    }
  };

  const rows = periodsWithSubscriptions.sort(sort).map((ps: SubscriptionWrapper) => {
    const { period, subscription } = ps;
    const { security, strategy } = period;
    let forecastLabel: JSX.Element;
    switch (period.forecast) {
      case Forecast.Long:
      case Forecast.Short:
        forecastLabel = <span className="label label-success">{Forecast[period.forecast]}</span>;
        break;
      case Forecast.ExitPending:
        forecastLabel = <span className="label label-pending">Pending</span>;
        break;
      case Forecast.Averaging:
        forecastLabel = <span className="label label-pending">Avging</span>;
        break;
      case Forecast.Exit:
        forecastLabel = <span className="label label-warning">{Forecast[period.forecast]}</span>;
        break;
      case Forecast.Stay:
        forecastLabel = <span className="label label-info">{Forecast[period.forecast]}</span>;
        break;
      case Forecast.Block:
        forecastLabel = <span className="label label-danger">{Forecast[period.forecast]}</span>;
        break;
      default:
        forecastLabel = <span className="label label-default">{Forecast[period.forecast]}</span>;
        // forecastLabel = <span>{Forecast[period.forecast]}</span>;
        break;
    }

    let marketHoursLabel: JSX.Element | undefined;
    switch (period.marketTradingHours) {
      case MarketTradingHours.Regular:
        marketHoursLabel = (
          <span className="text-success">
            <i className="fa fa-clock-o" aria-hidden="true" title="Regular Trading Hours"></i>
          </span>
        );
        break;
      case MarketTradingHours.AfterHours:
        marketHoursLabel = (
          <span className="warning-text">
            <i className="fa fa-clock-o" aria-hidden="true" title="After Hours"></i>
          </span>
        );
        break;
      default:
        marketHoursLabel = (
          <span className="danger-text">
            <i className="fa fa-clock-o" aria-hidden="true" title="Closed"></i>
          </span>
        );
        break;
    }

    const stopLossMoves =
      period.movingStopLossCount && Number(period.movingStopLossCount) > 0 ? (
        <span className="small info-text">{period.movingStopLossCount}</span>
      ) : (
        ''
      );

    let limitAmount = period.positionLimit;
    let stopLossAmount = period.positionStopLoss;
    if (!!period.position) {
      limitAmount -= period.position.sumSlippageAmount;
      stopLossAmount -= period.position.sumSlippageAmount;
    }

    return (
      <tr key={period.id} className="clickable" onClick={() => rowClickHandler(period)}>
        {!single && <th className="middle small">{subscription.alias || security.name}</th>}
        <td className="middle small">{toTime(period.created)}</td>
        <td className="middle small text-center">{marketHoursLabel}</td>
        <td className={classNames('middle', 'small', 'text-center')}>
          <i
            className={classNames('fa', {
              'fa-check-square-o': !period.error,
              'text-success': !period.error,
              'fa-square-o': period.error,
              'text-danger': period.error
            })}
            title={period.errorMessage}
          ></i>
        </td>
        <td className="middle small text-center">{forecastLabel}</td>

        <td className="middle small text-right vborder">{setDecimalSpaces(period.price, 4)}</td>
        <td className="middle small text-right">{setThousenSeparator(period.volume)}</td>
        <td className="middle small text-center">
          <button className="btn btn-warning btn-xs" onClick={(event: SyntheticEvent) => showGraphHandler(event, period)}>
            <span className="fa fa-line-chart fa-xs"></span>
          </button>
        </td>
        <td className="middle small text-center">
          <button className="btn btn-default btn-xs" onClick={(event: SyntheticEvent) => showInfoHandler(event, period)}>
            <span className="fa fa-info-circle fa-xs" style={{ color: '#ddd' }}></span>
          </button>
        </td>

        <td className="middle small vborder">{strategy.name}</td>
        <td className="middle small">{getBarSizeName(period.periodBarSize)}</td>
        <td className="middle small text-center">{period.positionSize}</td>
        <td className="middle small text-right">{period.positionElapsedPeriods}</td>
        <td className="middle small text-right">
          <span
            className={classNames({
              'success-text': period.unrealizedPnl > 0,
              'danger-text2': period.unrealizedPnl < 0
            })}
          >
            {formatUsd(period.unrealizedPnl, 0)}
          </span>
        </td>

        <td className="middle small text-right">{formatUsd(limitAmount, 0)}</td>
        <td className="middle small text-right">{formatUsd(stopLossAmount, 0)}</td>
        <td className="middle small text-center">{stopLossMoves}</td>
        <td className="middle small vborder">{period.errorMessage || period.forecastReason}</td>
      </tr>
    );
  });

  const table = (
    <table className="table table-hover table-striped table-condensed">
      <thead>{header}</thead>
      <tbody>{rows}</tbody>
    </table>
  );

  let graphDialog: JSX.Element | undefined = undefined;
  if (showGraphDialog && selectedPeriod) {
    graphDialog = <GraphDialog period={selectedPeriod} show={showGraphDialog} toggle={toggleShowGraphDialog} />;
  }

  return single ? (
    <div className="table-responsive">
      {table}
      {graphDialog}
    </div>
  ) : (
    <div className="scrolling outer">
      <div className="inner">
        {table}
        {graphDialog}
      </div>
    </div>
  );
};

export default withRouter(PeriodTable);
