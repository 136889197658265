import { BarSize, ContractPartial } from '../../../../types';

export interface GraphTile {
  guid: string;
  partial: ContractPartial;
  lastDate: Date;
  barSize: BarSize;
  strategyId?: number;
  expanded: boolean;
}

export interface GraphTileState {
  tiles: GraphTile[];
}

export const initialGraphTileState: GraphTileState = { tiles: [] };
