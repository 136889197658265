/* eslint-disable no-console */
import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import Switch from 'react-switch';

import { Position } from '../../../../types';
import RaceList from '../../../position/components/RaceList';
import Spinner from '../../../shared/components/Spinner';
import { toggleShowRaceView } from '../../../shared/store/layout/action-creators';
import { ModuleState } from '../../state';
import { getBacktestPositions, shouldFetchPositions } from '../../store/backtest-position/selectors';
import { loadBacktestPositionsAsync } from '../../store/backtest-position/service';

import BacktestPositionTable from './BacktestPositionTable';

export interface Props {
  backtestId: number;
}

const BacktestPositionPanel: React.FunctionComponent<Props> = ({ backtestId }: Props) => {
  const state: ModuleState = useSelector((ms: ModuleState) => ms);
  const positions: Position[] = getBacktestPositions(state, backtestId) ?? [];
  const shouldFetch = shouldFetchPositions(state, backtestId);
  const positionsLoaded = !shouldFetch;
  const showRaceView = state.layoutState.ui.showRaceView;

  const dispatch = useDispatch();

  const toggleRaceView = () => {
    dispatch(toggleShowRaceView(!showRaceView));
  };

  useEffect(() => {
    if (!positionsLoaded) {
      dispatch(loadBacktestPositionsAsync(backtestId));
    }
  }, [backtestId]);

  const [showOverlapping, setShowOverlapping] = useState(false);

  if (!positionsLoaded) {
    return <Spinner />;
  }

  const filteredPositions = positions.filter((x) => {
    if (showOverlapping === true) {
      return x;
    } else {
      return x.isOverlapping === false;
    }
  });

  return (
    <div>
      <table className="gap-bottom-20">
        <tbody>
          <tr>
            <td className="middle">
              <label className="react-switch react-switch--sm">
                <Switch onChange={() => setShowOverlapping(!showOverlapping)} checked={showOverlapping} />
              </label>
            </td>
            <td className="middle">
              <span className="small" style={{ display: 'inline-block', marginBottom: '1rem', marginLeft: '1rem' }}>
                Show Overlapping
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      {showRaceView ? (
        <>
          <RaceList positions={filteredPositions.reverse()} />
          <hr />
          <BacktestPositionTable positions={filteredPositions} summaryOnly />
        </>
      ) : (
        <BacktestPositionTable positions={filteredPositions} />
      )}

      <div className="row gap-top-20">
        <div className="col-xs-12">
          <div className="btn-toolbar" style={{ justifyContent: 'center', display: 'flex' }}>
            <button
              className={classNames('btn', 'btn-default', { active: !showRaceView })}
              type="button"
              onClick={toggleRaceView}
              title="Grid View"
            >
              <span>
                <i className="fa fa-table" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BacktestPositionPanel;
