import * as React from 'react';
import { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { GlobalState } from '../../../../core/root';
import { BarSize, ContractPartial, ContractPartialQuote, Strategy } from '../../../../types';
import * as partialQuoteActionCreators from '../../../contract-partial/store/partial-quote/action-creators';
import IntradayNav from '../../../shared/components/IntradayNav';
import { getBarSizeName } from '../../../shared/utils/bar-utils';
import { convertToDate } from '../../../shared/utils/date-utils';
import { generateGuid } from '../../../shared/utils/guid-utils';
import * as graphDataActionCreators from '../../store/graph-data/action-creators';
import * as actionCreators from '../../store/graph-tile/action-creators';
import { GraphTile } from '../../store/graph-tile/state';
import GraphConfigPanel from '../GraphConfigPanel';
import GraphPanel from '../GraphPanel';

export interface GraphTilePanelProps {
  partial: ContractPartial;
  strategy?: Strategy;
}

const GraphTilePanel = ({ partial, strategy }: GraphTilePanelProps) => {
  const dispatch = useDispatch();

  const partialQuotes: ContractPartialQuote[] = useSelector((state: GlobalState) => state.partialQuoteState.partialQuotes);
  const partialQuotesLoaded = useSelector((state: GlobalState) => state.partialQuoteState.loaded);

  useEffect(() => {
    if (!partialQuotesLoaded) {
      dispatch(partialQuoteActionCreators.loadPartialQuotesAsync());
    }
  }, []);

  const [historyDate, setHistoryDate] = useState<Date | undefined>(undefined);

  useEffect(() => {
    const dt = convertToDate(partial.periodEnd);
    setHistoryDate(dt);
  }, [partial]);

  const tiles: GraphTile[] = useSelector((state: GlobalState) => state.graphTileState.tiles);

  const addGraphTileHandler = (barSize: BarSize, date: Date) => {
    const tile: GraphTile = {
      guid: generateGuid(),
      partial: partial,
      strategyId: strategy ? strategy.id : 0,
      barSize,
      lastDate: date,
      expanded: false
    };
    dispatch(actionCreators.addGraphTile(tile));
  };

  const removeGraphTileHandler = (guid: string) => {
    const tile = tiles.find((x) => x.guid === guid);
    if (tile) {
      dispatch(actionCreators.removeGraphTile(tile));
      // clean
      dispatch(graphDataActionCreators.removeGraphData(tile.guid));
    }
  };

  const reemoveAllGraphTilesHandler = () => {
    if (!tiles || tiles.length === 0) {
      return;
    }
    tiles.map((x) => x.guid).forEach((x) => removeGraphTileHandler(x));
  };

  const expandGraphTileHandler = (guid: string) => {
    const tile = tiles.find((x) => x.guid === guid);
    if (!tile) {
      return;
    }
    tile.expanded = !tile.expanded;
    dispatch(actionCreators.updateGraphTile(tile));
  };

  const filteredPartialQuotes = partialQuotes.filter((x) => x.contractPartialId === partial.id);

  const graphToolbar =
    filteredPartialQuotes.length > 0 ? (
      <div className="btn-toolbar">
        {filteredPartialQuotes
          .sort((a: ContractPartialQuote, b: ContractPartialQuote) => {
            if (a.barSize > b.barSize) return 1;
            if (a.barSize < b.barSize) return -1;
            return 0;
          })
          .map((x) => (
            <button
              key={x.barSize}
              className="btn btn-sm btn-success gap-bottom-5 ripple"
              onClick={() => addGraphTileHandler(x.barSize, historyDate as Date)}
            >
              <i className="fa fa-line-chart fa-xs" /> {getBarSizeName(x.barSize)}
            </button>
          ))}
        <button key="close_all" className="btn btn-sm btn-default gap-bottom-5 ripple" onClick={reemoveAllGraphTilesHandler}>
          <span>
            <span className="gap-right-5">Close All</span>
            <i className="fa fa-times fa-xs" />{' '}
          </span>
        </button>
      </div>
    ) : undefined;

  return (
    <div>
      <GraphConfigPanel title="Graph Config" contractPartialId={partial.id} />

      <IntradayNav
        title="Select Last Graph Date"
        minDate={partial.periodStart}
        maxDate={partial.periodEnd}
        intraday={historyDate}
        setIntraday={setHistoryDate}
      />

      {historyDate && graphToolbar}

      <div className="row">
        {tiles.map((tile) => (
          <div key={tile.guid} className={classNames('mt-15', 'mb-15', { 'col-sm-6': !tile.expanded, 'col-xs-12': tile.expanded })}>
            <GraphPanel
              guid={tile.guid}
              partial={tile.partial}
              barSize={tile.barSize}
              lastDate={tile.lastDate}
              strategyId={tile.strategyId}
              expand={expandGraphTileHandler}
              expanded={tile.expanded}
              close={removeGraphTileHandler}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GraphTilePanel;
