import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'theming';

import { updateFromLocalStorage } from '../modules/auth/store/service';
import Spinner from '../modules/shared/components/Spinner';

import Footer from './components/Footer';
import Header from './components/Header';
import useStyles from './jss/global';
import { Theme } from './jss/theme';
import { GlobalState } from './root';
import { getRoutes } from './routes';

interface Props {
  name?: string;
  version?: string;
}

function App({ name, version }: Props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateFromLocalStorage());
  }, []);

  const theme: Theme = useTheme();
  useStyles({ theme });

  const token = useSelector((state: GlobalState) => state.authState.token);
  const loaded = useSelector((state: GlobalState) => state.authState.loaded);
  const isAuthenticated = !!token;

  if (!loaded) {
    return <Spinner />;
  }

  if (!isAuthenticated) {
    return <div>{getRoutes(isAuthenticated)}</div>;
  }

  return (
    <div className="flex-body">
      <div className="flex-content">
        <Header />
        <div className="container">{getRoutes(isAuthenticated)}</div>
      </div>
      {isAuthenticated && (
        <div className="flex-footer">
          <Footer name={name} version={version} />
        </div>
      )}
    </div>
  );
}

export default App;
