import { Strategy } from '../../../types';
import { showNetworkError } from '../../shared/utils/growl-utils';

import { addArchivedStrategies, addStrategy, removeStrategy, setStrategies, setStrategyError, updateStrategy } from './action-creators';
import { StrategyHttpRequest } from './http-request';

export const loadStrategiesAsync = () => {
  return (dispatch) => {
    StrategyHttpRequest.get()
      .then((response) => {
        // console.log(response);
        dispatch(setStrategies(response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load stratgies');
        dispatch(setStrategyError(msg));
      });
  };
};

export const loadArchivedStrategiesAsync = () => {
  return (dispatch) => {
    StrategyHttpRequest.getArchived()
      .then((response) => {
        // console.log(response);
        dispatch(addArchivedStrategies(response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load archived stratgies');
        dispatch(setStrategyError(msg));
      });
  };
};

export const addStrategyAsync = (strategy: Strategy) => {
  return (dispatch) => {
    StrategyHttpRequest.insert(strategy)
      .then((response) => {
        dispatch(addStrategy(response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setStrategyError(msg));
      });
  };
};

export const duplicateStrategyAsync = (strategy: Strategy, callback: (strategyId: number) => void) => {
  return (dispatch) => {
    StrategyHttpRequest.insert(strategy)
      .then((response) => {
        const strategy = response.data;
        dispatch(addStrategy(strategy));
        callback(strategy.id);
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setStrategyError(msg));
        callback(0);
      });
  };
};

export const updateStrategyAsync = (strategy: Strategy) => {
  return (dispatch) => {
    StrategyHttpRequest.update(strategy)
      .then(() => {
        dispatch(updateStrategy(strategy));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setStrategyError(msg));
      });
  };
};

export const removeStrategyAsync = (strategyId: number) => {
  return (dispatch) => {
    StrategyHttpRequest.remove(strategyId)
      .then(() => {
        dispatch(removeStrategy(strategyId));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setStrategyError(msg));
      });
  };
};
