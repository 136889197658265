import { Position } from '../../../../types';

import * as actionTypes from './action-types';

export const setPositionSelected = (positionId: number, position: Position | undefined) => {
  return {
    type: actionTypes.SET_POSITION_SELECTED,
    positionId: positionId,
    position
  };
};

export const setPositionSelectedLoaded = (positionId: number, toggle: boolean) => {
  return {
    type: actionTypes.SET_POSITION_SELECTED_LOADED,
    positionId,
    loaded: toggle
  };
};

export const setPositionSelectedError = (positionId: number, error: string) => {
  return {
    type: actionTypes.SET_POSITION_SELECTED_ERROR,
    positionId,
    error
  };
};
