import { Subscription } from '../../../types';
import { showNetworkError } from '../../shared/utils/growl-utils';

import * as actionTypes from './action-types';
import { SubscriptionHttpRequest } from './http-request';

export interface SubscriptionAction {
  type?: string;
  value?: string;
  subscription?: Subscription;
  subscriptions?: Subscription[];
}

export const fetchSubscriptions = (): SubscriptionAction => {
  return {
    type: actionTypes.FETCH_SUBSCRIPTIONS
  };
};

export const setSubscriptions = (subscriptions: Subscription[]): SubscriptionAction => {
  return {
    type: actionTypes.SET_SUBSCRIPTIONS,
    subscriptions
  };
};

export const setSubscriptionError = (error: string): SubscriptionAction => {
  return {
    type: actionTypes.SET_SUBSCRIPTION_ERROR,
    value: error
  };
};

export const addSubscription = (subscription: Subscription): SubscriptionAction => {
  return {
    type: actionTypes.ADD_SUBSCRIPTION,
    subscription
  };
};

export const updateSubscription = (subscription: Subscription) => {
  return {
    type: actionTypes.UPDATE_SUBSCRIPTION,
    subscription
  };
};

export const removeSubscription = (id: number) => {
  return {
    type: actionTypes.REMOVE_SUBSCRIPTION,
    value: id
  };
};

/*** ASYNC WITH THUNK ***/

export const loadSubscriptionsAsync = () => {
  return (dispatch) => {
    dispatch(fetchSubscriptions);
    SubscriptionHttpRequest.getSubscriptions()
      .then((response) => {
        // console.log(response);
        dispatch(setSubscriptions(response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load subscriptions');
        dispatch(setSubscriptionError(msg));
      });
  };
};

export const addSubscriptionAsync = (sub: Subscription) => {
  return (dispatch) => {
    SubscriptionHttpRequest.insert(sub)
      .then((response) => {
        const lazy = {
          ...response.data,
          contract: sub.contract ? { ...sub.contract } : {},
          security: sub.security ? { ...sub.security } : {},
          strategy: sub.strategy ? { ...sub.strategy } : {}
        } as Subscription;
        dispatch(addSubscription(lazy));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setSubscriptionError(msg));
      });
  };
};

export const updateSubscriptionAsync = (sub: Subscription) => {
  return (dispatch) => {
    SubscriptionHttpRequest.update(sub)
      .then(() => {
        dispatch(updateSubscription(sub));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setSubscriptionError(msg));
      });
  };
};

export const removeSubscriptionAsync = (subId: number) => {
  return (dispatch) => {
    SubscriptionHttpRequest.remove(subId)
      .then(() => {
        dispatch(removeSubscription(subId));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setSubscriptionError(msg));
      });
  };
};
