import * as React from 'react';

import { ContractEntity as Contract, ContractOrderInfo } from '../../../../types';
import axiosApi from '../../../shared/axios-api';
import { showNetworkError } from '../../../shared/utils/growl-utils';

export interface ContractOrderInfoButtonProps {
  contracts: Contract[];
  contractId: number;
  orderSize: number;
  updateOrderInfo(info: ContractOrderInfo): void;
}

const ContractOrderInfoButton: React.FunctionComponent<ContractOrderInfoButtonProps> = ({
  contracts,
  contractId,
  orderSize,
  updateOrderInfo
}) => {
  const queryContractOrderInfo = () => {
    const contract = contracts.find((x) => x.id === contractId);
    if (!contract) {
      return;
    }
    axiosApi
      .post<ContractOrderInfo>('/contract-orderinfo', contract, {
        params: { orderSize: orderSize }
      })
      .then((response) => {
        updateOrderInfo(response.data);
      })
      .catch((error) => {
        showNetworkError(error);
      });
  };

  return (
    <button
      className="btn btn-warning btn-sm btn-block"
      type="button"
      onClick={queryContractOrderInfo}
      disabled={contractId === 0 || orderSize <= 0}
    >
      Query
    </button>
  );
};

export default ContractOrderInfoButton;
