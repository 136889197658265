import * as React from 'react';

import { createUseStyles, useTheme } from 'react-jss';

import { Theme } from '../../../core/jss/theme';

const useStyles = createUseStyles({
  spinner: {
    position: 'fixed' /* Sit on top of the page content */,
    width: '100%' /* Full width (cover the whole page) */,
    height: '100%' /* Full height (cover the whole page) */,
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0,0,0,0.5)' /* Black background with opacity */,
    zIndex: '5' /* Specify a stack order in case you're using a different order for other elements */,
    cursor: 'pointer' /* Add a pointer on hover */
    // transition: opacity 2s ease-in-out;
  },
  text: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    fontSize: '2rem',
    color: (theme: Theme) => theme.palette.brandSuccess,
    transform: 'translate(-50%,-50%)',
    '-ms-transform': 'translate(-50%,-50%)'
  }
});

const Spinner = () => {
  const theme: Theme = useTheme();
  const classes = useStyles({ ...theme });
  return (
    <div className={classes.spinner}>
      <div className={classes.text}>
        <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Spinner;
