import * as React from 'react';
import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import Switch from 'react-switch';

import { Backtest } from '../../../types';
import { formatPartialMonth } from '../../contract-partial/utils';
import CollapsePanel from '../../shared/components/CollapsePanel';
import { sortByPartialMonth } from '../backtest-utils';
import { ModuleState } from '../state';
import * as actionCreators from '../store/backtest/action-creators';
import { getBacktests } from '../store/backtest/selectors';

import BacktestPositionPanel from './BacktestPositionPanel';
import BacktestTable from './BacktestTable';

export interface RouteParamProps {
  strategyId: string;
  backtestId: string;
}

export type BacktestScreenProps = RouteComponentProps<RouteParamProps>;

const BacktestScreen = ({ history, match }: BacktestScreenProps) => {
  const strategyId = Number(match.params.strategyId);
  const backtestId = Number(match.params.backtestId);
  const state: ModuleState = useSelector((ms: ModuleState) => ms);
  const selectedSecurity = state.securityState.ui.securityPicked;
  const backtests: Backtest[] = useSelector((state: ModuleState) =>
    selectedSecurity ? getBacktests(state, selectedSecurity.id, strategyId) : []
  );
  const filteredBacktests = backtests.sort(sortByPartialMonth);

  const backtest = backtests.find((x) => x.id === backtestId) as Backtest;
  const securityId = selectedSecurity?.id as number;
  const navigateBackUrl = `/strategies/${strategyId}?tab=backtests`;

  const navigateToBacktestList = () => {
    history.push(navigateBackUrl);
  };

  useEffect(() => {
    if (!selectedSecurity) {
      navigateToBacktestList();
    }
  }, []);

  const dispatch = useDispatch();

  const deleteBacktestHandler = () => {
    dispatch(actionCreators.removeBacktestAsync(securityId, strategyId, backtest));
    navigateToBacktestList();
  };

  const toggleUseOverlappingHandler = () => {
    backtest.useOverlapping = !backtest.useOverlapping;
    dispatch(actionCreators.updateBacktestAsync(securityId, strategyId, backtest));
  };

  const goToPrevious = () => {
    const filteredList = filteredBacktests.filter((x) => x.partial.partialMonth < backtest.partial.partialMonth);
    if (filteredList.length > 0) {
      const prevId = filteredList[filteredList.length - 1].id;
      history.push(`/backtests/${strategyId}/${prevId}`);
    }
  };

  const goToNext = () => {
    const filteredList = filteredBacktests.filter((x) => x.partial.partialMonth > backtest.partial.partialMonth);
    if (filteredList.length > 0) {
      const nextId = filteredList[0].id;
      history.push(`/backtests/${strategyId}/${nextId}`);
    }
  };

  if (!backtest) {
    return null;
  }

  const breadCrumbTitle = `${backtest.contract.localSymbol} ${formatPartialMonth(backtest.partial.partialMonth)}`;

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to={navigateBackUrl}>Backtests</Link>
        </li>
        <li className="breadcrumb-item active">{breadCrumbTitle}</li>
      </ol>

      <div className="page-header">
        <h3>
          Backtest <small className="no-wrap">{breadCrumbTitle}</small>
        </h3>
      </div>

      <CollapsePanel title="General" collapsed={false}>
        <div className="row">
          <div className="col-sm-6">
            <table>
              <tbody>
                <tr>
                  <td className="middle">
                    <label className="react-switch react-switch--sm">
                      <Switch onChange={toggleUseOverlappingHandler} checked={backtest.useOverlapping} />
                    </label>
                  </td>
                  <td className="middle">
                    <span className="small" style={{ display: 'inline-block', marginBottom: '1rem', marginLeft: '1rem' }}>
                      Use Overlapping
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-xs-12">
            <BacktestTable securityId={securityId} strategyId={strategyId} backtests={[backtest]} />
          </div>

          <div className="col-sm-offset-3 col-sm-6 col-sm-offset-3 gap-top-10">
            <div className="btn-group btn-group-justified" role="group">
              <div className="btn-group btn-group-sm" role="group">
                <button className="btn btn-default btn-block btn-sm" onClick={goToPrevious}>
                  <span className="glyphicon glyphicon glyphicon-triangle-left" aria-hidden="true"></span>
                  <span> Previous</span>
                </button>
              </div>
              <div className="btn-group btn-group-sm" role="group">
                <button className="btn btn-default btn-block btn-sm" onClick={goToNext}>
                  <span>Next </span>
                  <span className="glyphicon glyphicon glyphicon-triangle-right" aria-hidden="true"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </CollapsePanel>

      <div className="gap-top-10">
        <CollapsePanel title="Positions">
          <BacktestPositionPanel backtestId={backtestId} />
        </CollapsePanel>
      </div>

      {/* <div>
        <div className="section">
          <hr />
          <p>Graph View</p>
        </div>

        <GraphTilePanel partial={backtest.partial} strategy={backtest.strategy} />
      </div> */}

      <div>
        <hr />
        <div className="row">
          <div className="col-xs-6">
            <button type="button" className="btn btn-danger ripple" onClick={deleteBacktestHandler}>
              Delete
            </button>
          </div>
          <div className="col-xs-6 text-right">
            <button type="button" className="btn btn-primary ripple" onClick={navigateToBacktestList}>
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BacktestScreen;
