import React from 'react';

import { GrowlScene } from '@christianleypoldt/react-growl';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'react-jss';
import { Provider } from 'react-redux';

import App from './core/App';
import WithCustomRouter from './core/hoc/WithCustomRouter';
import { theme } from './core/jss/theme';
import store from './core/root';
import * as serviceWorker from './serviceWorker';

// vendors
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootswatch/darkly/bootstrap.css';
//import '../node_modules/bootswatch/slate/bootstrap.css';
import '../node_modules/bootstrap-toggle/css/bootstrap-toggle.css';
import '../node_modules/font-awesome/css/font-awesome.css';
import '../node_modules/css-ripple-effect/dist/ripple.css';

import './assets/styles/vendor_overwrites.css';
import './assets/styles/main.css';
import './assets/styles/raceview.css';

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <WithCustomRouter>
        <App name={process.env.REACT_APP_NAME} version={process.env.REACT_APP_VERSION} />
      </WithCustomRouter>
      <GrowlScene />
    </ThemeProvider>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
