import * as React from 'react';

import { OverlayTrigger, Popover } from 'react-bootstrap';
import Switch from 'react-switch';

import { Indicator } from '../../../../types';
import { basisTypes } from '../../utils/basis-utils';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const RangeForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  const sectionTooltipPopover = (
    <Popover id="sectionTooltipPopover">
      <span>
        The section of the periods (ie. first-, middle-, last-part) which has to comply with the range thresholds. Usefull when more than
        one range-indicator are used in parallels.
      </span>
    </Popover>
  );

  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label htmlFor="range_basis" className="col-xs-12 col-sm-6 control-label">
          Range Type
        </label>
        <div className="col-sm-6">
          <select
            id="range_basis"
            className="form-control"
            value={indicator.basisType}
            onChange={(event) => update({ basisType: Number(event.target.value) })}
          >
            {basisTypes.map((x) => (
              <option key={x.key} value={x.key}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="strength_periods" className="col-sm-6 control-label">
          Periods
        </label>
        <div className="col-sm-6">
          <input
            id="strength_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.periods}
            onChange={(event) => update({ periods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="strength_upper" className="col-sm-6 control-label">
          Upper Threshold
        </label>
        <div className="col-sm-6">
          <input
            id="strength_upper"
            type="number"
            step="1"
            className="form-control"
            value={indicator.upperThreshold}
            onChange={(event) => update({ upperThreshold: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="strength_lower" className="col-sm-6 control-label">
          Lower Threshold
        </label>
        <div className="col-sm-6">
          <input
            id="strength_lowers"
            type="number"
            step="1"
            className="form-control"
            value={indicator.lowerThreshold}
            onChange={(event) => update({ lowerThreshold: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="section">
        <hr />
        <p>Options</p>
      </div>

      <div className="form-group form-group-sm">
        <label className="col-sm-6 control-label">
          <span>Use Section</span>
          <OverlayTrigger placement="top" overlay={sectionTooltipPopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg text-info" />
            </span>
          </OverlayTrigger>
        </label>
        <div className="col-sm-6">
          <label className="react-switch react-switch--sm">
            <Switch onChange={() => update({ useCrossOver: !indicator.useCrossOver })} checked={indicator.useCrossOver} />
          </label>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="section_begin" className="col-sm-6 control-label">
          Begin {'%'}
        </label>
        <div className="col-sm-6">
          <input
            id="section_begin"
            type="number"
            step="1"
            min="1"
            className="form-control"
            value={indicator.secondaryPeriods}
            onChange={(event) => update({ secondaryPeriods: Number(event.target.value) })}
            disabled={!indicator.useCrossOver}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="section_end" className="col-sm-6 control-label">
          End {'%'}
        </label>
        <div className="col-sm-6">
          <input
            id="section_end"
            type="number"
            step="1"
            min="1"
            className="form-control"
            value={indicator.basisPeriods}
            onChange={(event) => update({ basisPeriods: Number(event.target.value) })}
            disabled={!indicator.useCrossOver}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default RangeForm;
