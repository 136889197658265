import { Order, Position } from '../../../../types';
import axiosApi from '../../../shared/axios-api';

export class PositionOrderHttpRequest {
  public static getPositionOrders(positionId: number) {
    return axiosApi.get<Order[]>(`/order/position/${positionId}`);
  }
  public static placeLimitOrder(position: Position) {
    return axiosApi.post<Order>('/companion-order/limit', position);
  }
  public static placeStopOrder(position: Position) {
    return axiosApi.post<Order>('/companion-order/stop', position);
  }
  public static placeMarketOrder(position: Position) {
    return axiosApi.post<Order>('/market-order', position);
  }
}
