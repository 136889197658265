import React, { FunctionComponent, useEffect, useState } from 'react';

import classnames from 'classnames';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Modal, Button } from 'react-bootstrap';
import { createUseStyles, useTheme } from 'react-jss';
import Switch from 'react-switch';

import { Theme } from '../jss/theme';

export interface Props {
  show: boolean;
  toggle(): void;
}

const useStyles = createUseStyles((x: Theme) => ({
  slideContainer: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    paddingTop: '6px',
    paddingBottom: '7px'
  },
  slider: {
    appearance: 'none',
    borderRadius: '5px',
    outline: 'none',
    opacity: '0.7',
    transition: 'opacity .2s',
    '&:hover': {
      opacity: '1'
    },
    '&:-webkit-slider-thumb': {
      background: x.palette.brandSuccess // not working
    }
  }
}));

/* INTITIAL VALUES */
const INITIAL_CAPITAL = 1000;
const STOP_LOSS_PCT = 30;
const LIMIT_PCT = 100;
const NUM_TRADES = 100;
const NUM_SESSIONS = 10;
const WIN_PCT = 30;

interface SessionPnl {
  profitAmount: number;
  lossAmount: number;
}

const FlipTossDialog: FunctionComponent<Props> = ({ show, toggle }: Props) => {
  const [startCapital, setStartCapital] = useState(INITIAL_CAPITAL);
  const [addToCapital, setAddToCapital] = useState(false);
  const [winPct, setWinPct] = useState(WIN_PCT);
  const [numberOfSessions, setNumberOfSessions] = useState(NUM_SESSIONS);
  const [numberOfTrades, setNumberOfTrades] = useState(NUM_TRADES);
  const [sumProfit, setSumProfit] = useState(0);
  const [sumLoss, setSumLoss] = useState(0);
  const [sumPnl, setSumPnl] = useState(0);
  const [sumCapital, setSumCapital] = useState(0);

  const [useLimitPct, setUseLimitPct] = useState(false);
  const [limitPct, setLimitPct] = useState(LIMIT_PCT);
  const [limitAmount, setLimitAmount] = useState(1000);

  const [useStopLossPct, setUseStopLossPct] = useState(false);
  const [stopLossPct, setStopLossPct] = useState(STOP_LOSS_PCT);
  const [stopLossAmount, setStopLossAmount] = useState(333);

  const theme: Theme = useTheme();
  const styles = useStyles({ theme });

  const formatNumber = (val: number) => {
    return Math.round(val).toLocaleString('en');
  };

  const calulateSessionPnl = (capital: number): SessionPnl => {
    const profitAmount = useLimitPct ? (capital / 100) * limitPct : limitAmount;
    const lossAmount = useStopLossPct ? (capital / 100) * stopLossPct : stopLossAmount;

    const numberOfWinTrades = (numberOfTrades / 100) * winPct;
    const lossPct = 100 - winPct;
    const numberOfLossTrades = (numberOfTrades / 100) * lossPct;

    const sumProfitAmount = profitAmount * numberOfWinTrades;
    const sumLossAmount = lossAmount * numberOfLossTrades;

    return {
      profitAmount: sumProfitAmount,
      lossAmount: sumLossAmount
    };
  };

  const calulatePnl = () => {
    let _capital = startCapital;
    let _profitAmount = 0;
    let _lossAmount = 0;

    for (let i = 0; i < numberOfSessions; i++) {
      if (_capital < 0) {
        break;
      }

      const bettingCapital = addToCapital ? _capital : startCapital;
      const sessionPnl = calulateSessionPnl(bettingCapital);

      _profitAmount += sessionPnl.profitAmount;
      _lossAmount += sessionPnl.lossAmount;
      const sessionPnlAmount = sessionPnl.profitAmount - sessionPnl.lossAmount;
      _capital += sessionPnlAmount;
    }

    setSumProfit(_profitAmount);
    setSumLoss(_lossAmount);
    setSumPnl(_profitAmount - _lossAmount);
    setSumCapital(_capital);
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('Calculating..');
    calulatePnl();
  }, [
    startCapital,
    winPct,
    stopLossPct,
    limitPct,
    numberOfSessions,
    numberOfTrades,
    addToCapital,
    useLimitPct,
    useStopLossPct,
    limitAmount,
    stopLossAmount
  ]);

  const useAddToCapitapPopover = (
    <Popover id="useAddToCapitapPopover">Has effect if limit or stop-loss is percentage of session capital</Popover>
  );
  const usePnlPctPopover = <Popover id="useLimitPctPopover">Percentage of the session capital</Popover>;

  return (
    <Modal show={show} onHide={toggle} aria-labelledby="contained-modal-title">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title">Flip Toss</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form name="userForm" className="form-horizontal">
          <div className="form-group form-group-sm">
            <label htmlFor="start_capital" className="col-sm-4 control-label small">
              Start Capital
            </label>
            <div className="col-sm-8">
              <input
                id="start_capital"
                type="number"
                step="1"
                className="form-control"
                value={startCapital}
                onChange={(e) => setStartCapital(Number(e.target.value))}
              />
            </div>
          </div>

          <div className="form-group form-group-sm">
            <label className="col-sm-4 control-label">
              <span>Add To Capital</span>
              <OverlayTrigger placement="top" overlay={useAddToCapitapPopover}>
                <span className="gap-left-10">
                  <i className="fa fa-question-circle fa-lg info-text" />
                </span>
              </OverlayTrigger>
            </label>
            <div className="col-sm-8">
              <label className="react-switch react-switch--sm">
                <Switch onChange={() => setAddToCapital(!addToCapital)} checked={addToCapital} />
              </label>
            </div>
          </div>

          <div className="form-group form-group-sm">
            <label htmlFor="num_sessions" className="col-sm-4 control-label small">
              Number Of Sessions
            </label>
            <div className="col-sm-8">
              <input
                id="num_sessions"
                type="number"
                step="1"
                className="form-control"
                value={numberOfSessions}
                onChange={(e) => setNumberOfSessions(Number(e.target.value))}
              />
            </div>
          </div>

          <div className="form-group form-group-sm">
            <label htmlFor="num_trades" className="col-sm-4 control-label small">
              Trades per Session
            </label>
            <div className="col-sm-8">
              <input
                id="num_trades"
                type="number"
                step="1"
                className="form-control"
                value={numberOfTrades}
                onChange={(e) => setNumberOfTrades(Number(e.target.value))}
              />
            </div>
          </div>

          <hr />

          <div className="form-group form-group-sm">
            <label className="col-sm-4 control-label">
              <span>Use Limit Pct</span>
              <OverlayTrigger placement="top" overlay={usePnlPctPopover}>
                <span className="gap-left-10">
                  <i className="fa fa-question-circle fa-lg info-text" />
                </span>
              </OverlayTrigger>
            </label>
            <div className="col-sm-8">
              <label className="react-switch react-switch--sm">
                <Switch onChange={() => setUseLimitPct(!useLimitPct)} checked={useLimitPct} />
              </label>
            </div>
          </div>

          {useLimitPct ? (
            <div className="form-group form-group-sm">
              <label htmlFor="input_limit_pct" className="col-sm-4 control-label small">
                Limit Percentage
              </label>
              <div className="col-sm-8">
                <div className={styles.slideContainer}>
                  <input
                    id="input_limit_pct"
                    type="range"
                    step="1"
                    min="1"
                    max="100"
                    value={limitPct}
                    className={styles.slider}
                    onChange={(e) => setLimitPct(Number(e.target.value))}
                  />
                  <div>
                    <small>{limitPct}</small>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="form-group form-group-sm">
              <label htmlFor="input_limit_amount" className="col-sm-4 control-label small">
                Limit Amount
              </label>
              <div className="col-sm-8">
                <input
                  id="input_limit_amount"
                  type="number"
                  step="1"
                  className="form-control"
                  value={limitAmount}
                  onChange={(e) => setLimitAmount(Number(e.target.value))}
                />
              </div>
            </div>
          )}

          <div className="form-group form-group-sm">
            <label className="col-sm-4 control-label">
              <span>Use Stop Pct</span>
              <OverlayTrigger placement="top" overlay={usePnlPctPopover}>
                <span className="gap-left-10">
                  <i className="fa fa-question-circle fa-lg info-text" />
                </span>
              </OverlayTrigger>
            </label>
            <div className="col-sm-8">
              <label className="react-switch react-switch--sm">
                <Switch onChange={() => setUseStopLossPct(!useStopLossPct)} checked={useStopLossPct} />
              </label>
            </div>
          </div>

          {useStopLossPct ? (
            <div className="form-group form-group-sm">
              <label htmlFor="input_stop_loss_pct" className="col-sm-4 control-label small">
                Stop Loss Percentage
              </label>
              <div className="col-sm-8">
                <div className={styles.slideContainer}>
                  <input
                    id="input_stop_loss_pct"
                    type="range"
                    step="1"
                    min="1"
                    max="100"
                    value={stopLossPct}
                    className={styles.slider}
                    onChange={(e) => setStopLossPct(Number(e.target.value))}
                  />
                  <div>
                    <small>{stopLossPct}</small>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="form-group form-group-sm">
              <label htmlFor="input_stop_amount" className="col-sm-4 control-label small">
                Stop Amount
              </label>
              <div className="col-sm-8">
                <input
                  id="input_stop_amount"
                  type="number"
                  step="1"
                  className="form-control"
                  value={stopLossAmount}
                  onChange={(e) => setStopLossAmount(Number(e.target.value))}
                />
              </div>
            </div>
          )}

          <hr />

          <div className="form-group form-group-sm">
            <label htmlFor="input_win_pct" className="col-sm-4 control-label small">
              Win Percentage
            </label>
            <div className="col-sm-8">
              <div className={styles.slideContainer}>
                <input
                  id="input_win_pct"
                  type="range"
                  min="1"
                  max="100"
                  value={winPct}
                  className={styles.slider}
                  onChange={(e) => setWinPct(Number(e.target.value))}
                />
                <div>
                  <small>{winPct}</small>
                </div>
              </div>
            </div>
          </div>

          <div className={classnames('alert', 'gap-top-20', { 'alert-danger': sumPnl < 0, 'alert-success': sumPnl >= 0 })}>
            <p>Sum Profit: {formatNumber(sumProfit)}</p>
            <p>Sum Loss: {formatNumber(sumLoss)}</p>
            <p>Sum PnL: {formatNumber(sumPnl)}</p>
            <p>
              <b>Sum Capital: {formatNumber(sumCapital)}</b>
            </p>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={toggle}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FlipTossDialog;
