import { PeriodType, Position, Security, Strategy } from '../../../types';
import * as constants from '../../shared/constants/broker-accounts';
import { PositionTypeOption } from '../../shared/utils/position-type-option';

export const getFilteredPositionsByType = (positions: Position[], posTypeOptions: PositionTypeOption[]) => {
  // filter positions
  const selectedPositionTypes = posTypeOptions.filter((x) => x.selected);
  const liveSelected = selectedPositionTypes.find((y) => y.positionType === PeriodType.Live && y.account === constants.LIVE_ACCOUNT);
  const paperSelected = selectedPositionTypes.find(
    (y) => y.positionType === PeriodType.Live && constants.PAPER_TRADING_ACCOUNT.find((a) => a === y.account) !== undefined
  );

  return positions
    .filter((x) => {
      if (selectedPositionTypes.length === 0) {
        return x;
      }
      const exists = selectedPositionTypes.find((y) => y.positionType === x.type);
      return !!exists;
    })
    .filter((x) => {
      if (selectedPositionTypes.length === 0) {
        return x;
      }
      if (liveSelected) {
        return x;
      }
      // live not selected
      const isLive = x.type === PeriodType.Live && x.account === constants.LIVE_ACCOUNT;
      return !isLive;
    })
    .filter((x) => {
      if (selectedPositionTypes.length === 0) {
        return x;
      }
      if (paperSelected) {
        return x;
      }
      // paper not selected
      const isPaper = x.type === PeriodType.Live && constants.PAPER_TRADING_ACCOUNT.find((a) => a === x.account);
      return !isPaper;
    });
};

export const getFilteredPositions = (
  positions: Position[],
  selectedSecurity: Security | undefined,
  selectedStrategy: Strategy | undefined,
  posTypeOptions: PositionTypeOption[]
) => {
  if (!positions || positions.length === 0) {
    return [];
  }

  const filteredPos = positions
    .filter((x) => {
      if (!selectedSecurity) {
        return x;
      }
      return x.securityId === selectedSecurity.id;
    })
    .filter((x) => {
      if (!selectedStrategy) {
        return x;
      }
      return x.strategyId === selectedStrategy.id;
    });

  return getFilteredPositionsByType(filteredPos, posTypeOptions);
};
