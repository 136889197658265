import { showNetworkError } from '../../../shared/utils/growl-utils';

import { setStatus, setStatusError, setStatusLoading, toggleRunSchedule } from './action-creators';
import { ScheduleStatusHttpRequest } from './request';

export const getScheduleStatusAsync = (silent = false) => {
  return (dispatch) => {
    if (!silent) {
      dispatch(setStatusLoading(true));
    }
    ScheduleStatusHttpRequest.getStatus()
      .then((response) => {
        dispatch(setStatus(response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to retrieve schedule-status');
        dispatch(setStatusError(msg));
      });
  };
};

export const toggleRunScheduleAsync = () => {
  return (dispatch) => {
    dispatch(setStatusLoading(true));

    ScheduleStatusHttpRequest.toggleRunSchedule()
      .then((response) => {
        dispatch(toggleRunSchedule(response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to toggle run schedule');
        dispatch(setStatusError(msg));
      });
  };
};
