import * as React from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { GlobalState } from '../../../core/root';
import { PeriodType } from '../../../types';
import * as actionCreators from '../../shared/store/layout/action-creators';
import { PositionTypeOption } from '../../shared/utils/position-type-option';

const PositionTypeFilter: React.FunctionComponent = () => {
  const posTypeOptions: PositionTypeOption[] = useSelector((state: GlobalState) => state.layoutState.ui.positionTypeOptions);

  const dispatch = useDispatch();

  const togglePosTypeSelectedHandler = (posTypeOption: PositionTypeOption) => {
    const list = [...posTypeOptions];
    let pressed: PositionTypeOption | undefined;
    if (posTypeOption.positionType === PeriodType.Live) {
      pressed = list.find((x) => x.positionType === posTypeOption.positionType && x.account === posTypeOption.account);
    } else {
      pressed = list.find((x) => x.positionType === posTypeOption.positionType);
    }
    if (pressed !== undefined) {
      pressed.selected = !pressed.selected;
      dispatch(actionCreators.setPositionTypeOptions(list));
    }
  };

  const posTypeOptionButtons = posTypeOptions.map((x, index) => (
    <button
      key={index}
      type="button"
      className={classNames('btn', 'btn-sm', 'btn-default', 'gap-right-5', 'gap-bottom-5', { active: x.selected })}
      onClick={() => togglePosTypeSelectedHandler(x)}
    >
      {x.positionTypeName}
    </button>
  ));

  return <div>{posTypeOptionButtons}</div>;
};

export default PositionTypeFilter;
