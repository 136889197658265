import * as React from 'react';

import { Link } from 'react-router-dom';

import QuickNavBar from '../../shared/components/QuickNavBar';

import PositionListPanel from './PositionListPanel';

const PositionListScreen = () => {
  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item active">Positions</li>
      </ol>

      <QuickNavBar />

      <PositionListPanel />
    </div>
  );
};

export default PositionListScreen;
