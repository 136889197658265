import { Map } from 'immutable';

import { BacktestSummary } from '../../../../types';

export interface BacktestSummaryState {
  loading: boolean;
  loaded: boolean;
  error?: string;
  summaries: BacktestSummary[];
}

export const initialBacktestSummaryState: BacktestSummaryState = {
  loading: false,
  loaded: false,
  error: undefined,
  summaries: []
};

export type BacktestSummaryMapState = Map<number, BacktestSummaryState>;

export const initialBacktestSummaryMapState: BacktestSummaryMapState = Map();
