import { Subscription } from '../../../types';

export interface SubscriptionState {
  loading: boolean;
  loaded: boolean;
  error?: string;
  subscriptions: Subscription[];
}

export const initalSubscriptionState: SubscriptionState = {
  loading: false,
  loaded: false,
  error: undefined,
  subscriptions: []
};
