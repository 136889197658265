import * as React from 'react';
import { useEffect, useState } from 'react';

import { Modal, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Switch from 'react-switch';

import { Account } from '../../../types';
import { ModuleState } from '../state';
import { loadAccountAsync, updateAccountAsync } from '../store/service';

export interface AccountDialogProps {
  show: boolean;
  toggle(): void;
}

const AccountDialog: React.SFC<AccountDialogProps> = (props) => {
  useEffect(() => {
    setMailReceipt('');
  }, [props.show]);

  const state: ModuleState = useSelector((s: ModuleState) => s);
  const account: Account = state.accountState.account;
  const loaded: boolean = state.accountState.loaded;
  const dispatch = useDispatch();

  const [useEmailNotification, setUseEmailNotification] = useState(account.useEmailNotification);
  const [emailAddress, setEmailAddress] = useState(account.emailAddress);
  const [liveTradingInvestment, setLiveTradingInvestment] = useState(account.liveTradingInvestment);
  const [paperTradingInvestment, setPaperTradingInvestment] = useState(account.paperTradingInvestment);

  useEffect(() => {
    if (!loaded) {
      dispatch(loadAccountAsync());
    } else {
      // relation between redux campaign and local state
      setUseEmailNotification(account.useEmailNotification);
      setEmailAddress(account.emailAddress);
      setLiveTradingInvestment(account.liveTradingInvestment);
      setPaperTradingInvestment(account.paperTradingInvestment);
    }
  }, [loaded]);

  const [mailReceipt, setMailReceipt] = useState<string>('');

  const saveClickHandler = () => {
    const updatedAccount: Account = {
      ...account,
      useEmailNotification,
      emailAddress,
      liveTradingInvestment,
      paperTradingInvestment
    };
    dispatch(updateAccountAsync(updatedAccount));
    props.toggle();
  };

  const testMailNofificationHandler = () => {
    // todo: call endpoint
    // var title = "Tester e-post varsling";
    // var htmlBody = "<strong>Manubia Diagnostics</strong>"
    // inboxService.send(title, htmlBody, function (success, errorMsg)
    // 'Email notification feilet: " + errorMsg;
    setMailReceipt('Email notification sent..');
  };

  // const popoverDisableControlCenterRequests = (
  //   <Popover id="popoverDisableControlCenterRequests">
  //     Disable all requests to the Control Center API if the connection status is OFFLINE. Backtesting is run through Azure Web API in
  //     offline-modus.
  //   </Popover>
  // );

  return (
    <Modal show={props.show} onHide={props.toggle} aria-labelledby="contained-modal-title">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title">User Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form name="userForm" className="form-horizontal">
          {/* <div className="form-group form-group-sm">
            <label className="col-sm-6 control-label">
              <span>Control Center Offline Modus</span>
              <OverlayTrigger placement="top" overlay={popoverDisableControlCenterRequests}>
                <span className="gap-left-10">
                  <i className="fa fa-question-circle fa-lg info-text" />
                </span>
              </OverlayTrigger>
            </label>
            <div className="col-sm-6">
              <label className="react-switch small">
                <Switch
                  onChange={() => setDisableControlCenterRequests(!disableControlCenterRequests)}
                  checked={disableControlCenterRequests}
                />
              </label>
            </div>
          </div> */}
          <div className="section">
            <p>Notifications</p>
          </div>
          <div className="form-group form-group-sm">
            <label htmlFor="use_notify_email" className="col-sm-4 control-label small">
              Notify by Email
            </label>
            <div className="col-sm-8">
              <label className="react-switch small">
                <Switch onChange={() => setUseEmailNotification(!useEmailNotification)} checked={useEmailNotification} />
              </label>
            </div>
          </div>
          <div className="form-group form-group-sm">
            <label htmlFor="input_email_address" className="col-sm-4 control-label">
              Email Address
            </label>
            <div className="col-sm-8">
              <div className="input-group input-group-sm">
                <input
                  id="input_email_address"
                  type="text"
                  step="any"
                  className="form-control"
                  disabled={!useEmailNotification}
                  value={emailAddress}
                  onChange={(event) => setEmailAddress(event.target.value)}
                  placeholder="Email Address"
                />
                <span className="input-group-btn">
                  <button className="btn btn-warning" type="button" onClick={testMailNofificationHandler}>
                    Test
                  </button>
                </span>
              </div>
              {mailReceipt && (
                <div className="alert alert-info alert-dismissible gap-top-10">
                  <button type="button" className="close" aria-label="Close" onClick={() => setMailReceipt('')}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <span>{mailReceipt}</span>
                </div>
              )}
            </div>
          </div>

          <div className="section">
            <hr />
            <p>Funding</p>
          </div>

          <div className="form-group form-group-sm">
            <label htmlFor="funding_paper_trading" className="col-sm-4 control-label">
              Paper Trading
            </label>
            <div className="col-sm-8">
              <div className="input-group input-group-sm">
                <div className="input-group-addon">kr. </div>
                <input
                  type="number"
                  step="any"
                  className="form-control"
                  value={paperTradingInvestment}
                  onChange={(event) => {
                    setPaperTradingInvestment(Number(event.target.value));
                  }}
                  id="funding_paper_trading"
                  placeholder="Amount"
                />
                <div className="input-group-addon">.00</div>
              </div>
            </div>
          </div>

          <div className="form-group form-group-sm">
            <label htmlFor="live_paper_trading" className="col-sm-4 control-label">
              Live Trading
            </label>
            <div className="col-sm-8">
              <div className="input-group input-group-sm">
                <div className="input-group-addon">kr. </div>
                <input
                  type="number"
                  step="any"
                  value={liveTradingInvestment}
                  onChange={(event) => {
                    setLiveTradingInvestment(Number(event.target.value));
                  }}
                  className="form-control"
                  id="live_paper_trading"
                  placeholder="Amount"
                />
                <div className="input-group-addon">.00</div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="primary" onClick={saveClickHandler}>
          Save
        </Button>
        <Button onClick={props.toggle}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccountDialog;
