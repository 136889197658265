import * as React from 'react';
import { FunctionComponent } from 'react';

import classNames from 'classnames';
import { createUseStyles, useTheme } from 'react-jss';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// import abortIcon from '../../../../assets/svg/stop-loss.svg';
import backtestingIcon from '../../../../assets/svg/backtest-pos.svg';
import bearRacerIcon from '../../../../assets/svg/bear-racer2.svg';
import bullRacerIcon from '../../../../assets/svg/bull-racer2.svg';
//import liveIcon from '../../../../assets/svg/live.svg';
import racingFlagsIcon from '../../../../assets/svg/racing-flags.svg';
import startIcon from '../../../../assets/svg/start.svg';
import trophyIcon from '../../../../assets/svg/trophy.svg';
import simulationIcon from '../../../../assets/svg/vr_set.svg';
import { Theme } from '../../../../core/jss/theme';
import { GlobalState } from '../../../../core/root';
import { Forecast, PeriodType, Position } from '../../../../types';
import TrendDirectionAtom from '../../../shared/components/TrendDirectionAtom';
import { getSystemTime } from '../../../shared/utils/broker-time';
import { formatUsd } from '../../../shared/utils/currency-utils';
import { setDecimalSpaces } from '../../../shared/utils/number-utils';
import { getDuration } from '../../../shared/utils/time-utils';
import { positionModulePath } from '../../pos-routes';

const useStyles = createUseStyles({
  simulation: {
    margin: '0 10px',
    '& img': {
      paddingBottom: '2px'
    }
  }
});

interface Props extends RouteComponentProps {
  position: Position;
}

const Race: FunctionComponent<Props> = ({ position, history }: Props) => {
  const state: GlobalState = useSelector((gs: GlobalState) => gs);
  const tz = state.scheduleStatusState.status?.webApiTimeZone;

  const goToHandler = () => {
    const route = `${positionModulePath}/${position.id}`;
    history.push(route);
  };

  // race variables
  const slippage = position.sumSlippageAmount;
  const slippageSection = Math.abs(slippage);
  const profitSection = position.forecastLimit;
  const lossSection = Math.abs(position.forecastStopLoss);
  const raceBarWidth = lossSection + profitSection;

  let profitMargin = (lossSection * 100) / raceBarWidth;
  let profitWidthPct = 100 - profitMargin;

  let slippageMargin = 0,
    slippageWidthPct = 0;
  if (slippage > 0) {
    slippageMargin = profitMargin;
    slippageWidthPct = profitWidthPct;
    profitMargin = ((lossSection + slippageSection) * 100) / raceBarWidth;
    profitWidthPct = 100 - profitMargin;
  } else if (slippage < 0) {
    slippageMargin = ((lossSection - slippageSection) * 100) / raceBarWidth;
    slippageWidthPct = 100 - slippageMargin;
  }

  // bugfix: ensure green all the way to the finish
  //profitAreaPct += 1;

  const initialStopLoss = position.forecastStopLoss - slippage;
  const movingStopLoss = position.movingStopLoss - slippage;
  const posStopLossChange = initialStopLoss - movingStopLoss;
  const movingStopLossWidthPct = (Math.abs(posStopLossChange) * 100) / raceBarWidth;

  const startIconPadding = slippage >= 0 ? `${profitMargin}%` : `${slippageMargin}%`;
  const startIconStyle = {
    paddingLeft: startIconPadding
  };

  let posPnl = 0;
  const hasOpenPosition = position && !position.exited && position.size !== 0;
  if (position.type === PeriodType.Backtest) {
    posPnl = hasOpenPosition ? position.sumUnrealizedPnl : position.sumNetPnl;
  } else {
    posPnl = hasOpenPosition ? position.sumUnrealizedPnl : position.sumRealizedPnl;
  }
  let sumProfit = profitSection;
  let sumLoss = lossSection;
  if (slippage > 0) {
    sumProfit -= slippageSection;
    sumLoss += slippageSection;
  } else if (slippage < 0) {
    sumProfit += slippageSection;
    sumLoss -= slippageSection;
  }
  const posLossPct = posPnl > 0 ? 0 : (Math.abs(posPnl) * 100) / sumLoss;
  const posProfitPct = posPnl < 0 ? 0 : (posPnl * 100) / sumProfit;

  let racerBase = lossSection + posPnl;
  if (slippage > 0) {
    racerBase += slippageSection;
  } else if (slippage < 0) {
    racerBase -= slippageSection;
  }

  const racerPaddingPct = (racerBase * 100) / raceBarWidth;
  const racerIconStyle = {
    paddingLeft: `${racerPaddingPct}%`
  };

  const theme: Theme = useTheme();
  const classes = useStyles({ theme });

  let posTypeIcon: JSX.Element | undefined;
  if (position.type === PeriodType.Backtest) {
    posTypeIcon = (
      <span className={classes.simulation} title="Backtest">
        <img src={backtestingIcon} height="26px" alt="Backtest" />
      </span>
    );
  } else if (position.type === PeriodType.Simulation) {
    posTypeIcon = (
      <span className={classes.simulation} title="Simulation">
        <img src={simulationIcon} width="26px" alt="Simulation" />
      </span>
    );
  }

  let posMetaData: JSX.Element | undefined;
  if (hasOpenPosition) {
    if (position.type !== PeriodType.Backtest) {
      const systemTime = getSystemTime(tz);
      posMetaData = (
        <>
          <TrendDirectionAtom trendDirection={position.trendDirection} positionSize={position.size} />
          <span className="small" style={{ marginLeft: '10px' }}>
            {getDuration(position.created, systemTime)}
          </span>
        </>
      );
    }
  } else {
    posMetaData = <img src={racingFlagsIcon} width="28px" alt="completed" />;
  }

  const TITLE_MAX_LENGTH = 25;
  let title = `${position.security.name} - ${position.strategy.name}`;
  if (title.length > TITLE_MAX_LENGTH) {
    title = title.substring(0, TITLE_MAX_LENGTH) + '...';
  }

  let stopSlippage: JSX.Element | undefined;
  if (slippage < 0) {
    stopSlippage = <span className="grey">({setDecimalSpaces(slippage, 0)})</span>;
  }

  let limitSlippage: JSX.Element | undefined;
  if (slippage > 0) {
    const negSlippage = slippage * -1;
    limitSlippage = <span className="grey">({setDecimalSpaces(negSlippage, 0)})</span>;
  }

  return (
    <div className="race-container">
      <div className="head">
        <div>
          <table>
            <tbody>
              <tr style={{ lineHeight: '14px' }}>
                <td>
                  {posTypeIcon}
                  <span style={{ paddingRight: '5px' }}>{title}</span>
                </td>
                <td style={{ paddingLeft: '2px', verticalAlign: 'middle' }}></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>{posMetaData}</div>
      </div>
      <div className="body clickable" onClick={goToHandler}>
        <div className="inner-body">
          {/* <div className="abort">
            <img src={abortIcon} width="28px" alt="abort" />
          </div> */}

          <div className="start" style={startIconStyle}>
            <img src={startIcon} width="28px" alt="start" />
          </div>

          <div className="finish">
            <img src={trophyIcon} width="28px" alt="finish" />
          </div>

          <div className="racer" style={racerIconStyle}>
            {/* { raceItem.bullRacer && (
              <img src="assets/svg/bull-racer.svg" width="32px" alt="bull" />
            )}
            { raceItem.bearRacer && (
              <img src="assets/svg/bear-racer.svg" width="32px" alt="bull" />
            )} */}
            <img src={position.forecast === Forecast.Long ? bullRacerIcon : bearRacerIcon} width="32px" alt="race-car" />
          </div>
          <div className="loss"></div>
          <div
            className={classNames('slippage', { ['pos']: slippage > 0, ['neg']: slippage < 0 })}
            style={{ marginLeft: slippageMargin + '%', width: slippageWidthPct + '%' }}
          ></div>
          <div className="profit" style={{ marginLeft: profitMargin + '%', width: profitWidthPct + '%' }}></div>
          <div className="stop" style={{ width: movingStopLossWidthPct + '%' }}></div>
        </div>
      </div>
      <div className="footer">
        <div className={classNames('small', { profit: movingStopLoss > 0, loss: movingStopLoss < 0 })}>
          {formatUsd(movingStopLoss, 0)} {stopSlippage}
        </div>
        <div className="loss small">{setDecimalSpaces(posLossPct, 0)}%</div>
        <div className={classNames('small', { profit: posPnl > 0, loss: posPnl < 0 })}>{formatUsd(posPnl, 0)}</div>
        <div className="profit small">{setDecimalSpaces(posProfitPct, 0)}%</div>
        <div className="profit small">
          {limitSlippage} {formatUsd(sumProfit, 0)}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Race);
