import * as React from 'react';

import Switch from 'react-switch';

import { Indicator } from '../../../../types';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const AwesomeOscillatorForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label htmlFor="ao_1_periods" className="col-sm-6 control-label">
          Fast SMA (d:5)
        </label>
        <div className="col-sm-6">
          <input
            id="ao_1_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.periods}
            onChange={(event) => update({ periods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="ao_2_periods" className="col-sm-6 control-label">
          Slow SMA (d:34)
        </label>
        <div className="col-sm-6">
          <input
            id="ao_2_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.secondaryPeriods}
            onChange={(event) => update({ secondaryPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="ao_min_counter_trend_bars" className="col-sm-6 control-label">
          Min Counter Trend Bars (d:1)
        </label>
        <div className="col-sm-6">
          <input
            id="ao_min_counter_trend_bars"
            type="number"
            step="1"
            className="form-control"
            value={indicator.atrPeriods}
            onChange={(event) => update({ atrPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="ao_min_trend_bars" className="col-sm-6 control-label">
          Min Trend Bars (d:5)
        </label>
        <div className="col-sm-6">
          <input
            id="ao_min_trend_bars"
            type="number"
            step="1"
            className="form-control"
            value={indicator.basisPeriods}
            onChange={(event) => update({ basisPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label className="col-sm-6 control-label">Use Cross Zero Line</label>
        <div className="col-sm-6">
          <label className="react-switch react-switch--sm">
            <Switch onChange={() => update({ useCrossOver: !indicator.useCrossOver })} checked={indicator.useCrossOver} />
          </label>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AwesomeOscillatorForm;
