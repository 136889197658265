import { Position } from '../../../../types';

export interface OpenPositionState {
  loading: boolean;
  loaded: boolean;
  error?: string;
  positions: Position[];
}

export const initalOpenPositionState: OpenPositionState = {
  loading: false,
  loaded: false,
  error: undefined,
  positions: []
};
