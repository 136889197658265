import React from 'react';

import classNames from 'classnames';
import { connect } from 'react-redux';

import { Security, MarketType } from '../../../../types';
import CollapsePanel from '../../../shared/components/CollapsePanel';
import * as actionCreators from '../../store/action-creators';

export interface SeasonTabProps {
  security: Security;
  updateSecurity(security: Security): void;
}

export interface SeasonTabState {
  security: Security;
}

class SeasonTab extends React.Component<SeasonTabProps, SeasonTabState> {
  constructor(props: SeasonTabProps) {
    super(props);
    const { security } = props;
    this.state = {
      security
    };
  }

  mapFromState = (sec: Security): Security => {
    return {
      ...this.props.security,
      janMarket: sec.janMarket,
      febMarket: sec.febMarket,
      marMarket: sec.marMarket,
      aprMarket: sec.aprMarket,
      mayMarket: sec.mayMarket,
      junMarket: sec.junMarket,
      julMarket: sec.julMarket,
      augMarket: sec.augMarket,
      sepMarket: sec.sepMarket,
      octMarket: sec.octMarket,
      novMarket: sec.novMarket,
      decMarket: sec.decMarket,
      seasonalNotes: sec.seasonalNotes
    };
  };

  updateSecurityHandler = () => {
    const sec = this.state.security;
    if (!sec) {
      return;
    }
    const item = this.mapFromState(sec);
    this.props.updateSecurity(item);
  };

  updateSecurityState = (obj: object) => {
    if (!this.state.security) {
      return;
    }
    const field = Object.keys(obj)[0];
    const security = {
      ...this.state.security,
      [field]: obj[field]
    };
    this.setState({ security });
  };

  toggleMarketType = function (marketType: MarketType) {
    if (marketType === MarketType.Any) return MarketType.Bull;
    else if (marketType === MarketType.Bull) return MarketType.Bear;
    else return MarketType.Any;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleSeasonalAnalysis = (obj: any) => {
    const fieldName = Object.keys(obj)[0];
    const fieldValue = obj[fieldName];
    const newPosType = this.toggleMarketType(fieldValue);
    this.updateSecurityState({ [fieldName]: newPosType });
  };

  render() {
    const {
      janMarket,
      febMarket,
      marMarket,
      aprMarket,
      mayMarket,
      junMarket,
      julMarket,
      augMarket,
      sepMarket,
      octMarket,
      novMarket,
      decMarket,
      seasonalNotes
    } = this.state.security;

    return (
      <div>
        <CollapsePanel title="Seasonal Analysis">
          <div className="btn-toolbar" role="toolbar" aria-label="...">
            <div className="gap-bottom-10">
              <span className="label label-default gap-left-5">No Trend</span>
              <span className="label label-success gap-left-5">Bullish</span>
              <span className="label label-danger gap-left-5">Bearish</span>
            </div>
            <div className="btn-group" role="group" aria-label="...">
              <button
                type="button"
                className={classNames('btn', 'ripple', 'gap-top-5', {
                  'btn-default': !janMarket,
                  'btn-success': janMarket === MarketType.Bull,
                  'btn-danger': janMarket === MarketType.Bear
                })}
                onClick={() => this.toggleSeasonalAnalysis({ janMarket })}
              >
                January
              </button>

              <button
                type="button"
                className={classNames('btn', 'ripple', 'gap-top-5', {
                  'btn-default': !febMarket,
                  'btn-success': febMarket === MarketType.Bull,
                  'btn-danger': febMarket === MarketType.Bear
                })}
                onClick={() => this.toggleSeasonalAnalysis({ febMarket })}
              >
                Febrary
              </button>

              <button
                type="button"
                className={classNames('btn', 'ripple', 'gap-top-5', {
                  'btn-default': !marMarket,
                  'btn-success': marMarket === MarketType.Bull,
                  'btn-danger': marMarket === MarketType.Bear
                })}
                onClick={() => this.toggleSeasonalAnalysis({ marMarket })}
              >
                March
              </button>

              <button
                type="button"
                className={classNames('btn', 'ripple', 'gap-top-5', {
                  'btn-default': !aprMarket,
                  'btn-success': aprMarket === MarketType.Bull,
                  'btn-danger': aprMarket === MarketType.Bear
                })}
                onClick={() => this.toggleSeasonalAnalysis({ aprMarket })}
              >
                April
              </button>

              <button
                type="button"
                className={classNames('btn', 'ripple', 'gap-top-5', {
                  'btn-default': !mayMarket,
                  'btn-success': mayMarket === MarketType.Bull,
                  'btn-danger': mayMarket === MarketType.Bear
                })}
                onClick={() => this.toggleSeasonalAnalysis({ mayMarket })}
              >
                May
              </button>

              <button
                type="button"
                className={classNames('btn', 'ripple', 'gap-top-5', {
                  'btn-default': !junMarket,
                  'btn-success': junMarket === MarketType.Bull,
                  'btn-danger': junMarket === MarketType.Bear
                })}
                onClick={() => this.toggleSeasonalAnalysis({ junMarket })}
              >
                June
              </button>

              <button
                type="button"
                className={classNames('btn', 'ripple', 'gap-top-5', {
                  'btn-default': !julMarket,
                  'btn-success': julMarket === MarketType.Bull,
                  'btn-danger': julMarket === MarketType.Bear
                })}
                onClick={() => this.toggleSeasonalAnalysis({ julMarket })}
              >
                July
              </button>

              <button
                type="button"
                className={classNames('btn', 'ripple', 'gap-top-5', {
                  'btn-default': !augMarket,
                  'btn-success': augMarket === MarketType.Bull,
                  'btn-danger': augMarket === MarketType.Bear
                })}
                onClick={() => this.toggleSeasonalAnalysis({ augMarket })}
              >
                August
              </button>

              <button
                type="button"
                className={classNames('btn', 'ripple', 'gap-top-5', {
                  'btn-default': !sepMarket,
                  'btn-success': sepMarket === MarketType.Bull,
                  'btn-danger': sepMarket === MarketType.Bear
                })}
                onClick={() => this.toggleSeasonalAnalysis({ sepMarket })}
              >
                September
              </button>

              <button
                type="button"
                className={classNames('btn', 'ripple', 'gap-top-5', {
                  'btn-default': !octMarket,
                  'btn-success': octMarket === MarketType.Bull,
                  'btn-danger': octMarket === MarketType.Bear
                })}
                onClick={() => this.toggleSeasonalAnalysis({ octMarket })}
              >
                October
              </button>

              <button
                type="button"
                className={classNames('btn', 'ripple', 'gap-top-5', {
                  'btn-default': !novMarket,
                  'btn-success': novMarket === MarketType.Bull,
                  'btn-danger': novMarket === MarketType.Bear
                })}
                onClick={() => this.toggleSeasonalAnalysis({ novMarket })}
              >
                November
              </button>

              <button
                type="button"
                className={classNames('btn', 'ripple', 'gap-top-5', {
                  'btn-default': !decMarket,
                  'btn-success': decMarket === MarketType.Bull,
                  'btn-danger': decMarket === MarketType.Bear
                })}
                onClick={() => this.toggleSeasonalAnalysis({ decMarket })}
              >
                December
              </button>
            </div>
          </div>

          <div className="section">
            <hr />
            <p>Notes</p>
          </div>
          <textarea
            id="input_segment_notes"
            className="form-control"
            rows={5}
            value={seasonalNotes || ''}
            onChange={(event) => this.updateSecurityState({ seasonalNotes: event.target.value })}
          ></textarea>
        </CollapsePanel>
        <hr />
        <div className="row">
          <div className="col-xs-4"></div>
          <div className="col-xs-8 text-right">
            <button type="button" className="btn btn-primary ripple gap-bottom-5" onClick={this.updateSecurityHandler}>
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateSecurity: (security: Security) => dispatch(actionCreators.updateSecurityAsync(security))
  };
};

export default connect(null, mapDispatchToProps)(SeasonTab);
