export interface CurrencyType {
  key: string;
  name: string;
  desc: string;
}

export const currencyTypes: CurrencyType[] = [
  { key: 'USD', name: 'USD', desc: 'American Dollar' },
  { key: 'GBP', name: 'GBP', desc: 'Great Britain Pound' },
  { key: 'CAD', name: 'CAD', desc: 'Canadian Dollar' },
  { key: 'AUD', name: 'AUD', desc: 'Australian Dollar' },
  { key: 'JPY', name: 'JPY', desc: 'Japanese Yen' },
  { key: 'NOK', name: 'NOK', desc: 'Norwegian Krone' },
  { key: 'EUR', name: 'EUR', desc: 'Euro' },
  { key: 'CHF', name: 'CHF', desc: 'Swiss Franc' }
];
