import * as React from 'react';

import Switch from 'react-switch';

import { Indicator } from '../../../../types';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const LeechForm: React.FunctionComponent<Props> = ({ indicator, update }: Props) => {
  return (
    <React.Fragment>
      <p>
        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Use Jigsaw Protection</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch
                onChange={() => update({ useBounceProtection: !indicator.useBounceProtection })}
                checked={indicator.useBounceProtection}
              />
            </label>
          </div>
        </div>
      </p>
    </React.Fragment>
  );
};

export default LeechForm;
