import { AnyAction } from 'redux';

import { IndicatorResult } from '../../../types';

import * as actionTypes from './action-types';
import { initalIndicatorResultMapState, initalIndicatorResultState } from './state';

export const indicatorResultReducer = (state = initalIndicatorResultMapState, action: AnyAction) => {
  const key = action.periodId;
  const current = state.get(key) || { ...initalIndicatorResultState };

  if (action.type === actionTypes.SET_INDICATOR_RESULTS_LOADING) {
    const loading = action.loading === true;
    return state.set(key, {
      ...current,
      loading,
      error: undefined
    });
  } else if (action.type === actionTypes.SET_INDICATOR_RESULTS_ERROR) {
    return state.set(key, {
      ...current,
      loading: false,
      error: action.error
    });
  } else if (action.type === actionTypes.SET_INDICATOR_RESULTS) {
    const results = action.indicatorResults as IndicatorResult[];
    return state.set(key, {
      loading: false,
      error: undefined,
      results
    });
  }

  return state;
};
