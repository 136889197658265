import { Portfolio, Position } from '../../../types';

import { formatUsd } from './currency-utils';

export const syncUnrealizedPnl = (positions: Position[], portfolios: Portfolio[]) => {
  if (!positions || positions.length == 0 || !portfolios || portfolios.length == 0) {
    return;
  }
  for (let i = 0; i < positions.length; i++) {
    const position = positions[i];
    const refId = position.contract?.refId;
    const portfolio: Portfolio | undefined = portfolios.find((y) => y.contractRefId == refId);
    if (portfolio != undefined) {
      position.sumUnrealizedPnl = portfolio.unrealizedPnl;
    }
  }
};

export const getSumUnrealizedPnl = (positions: Position[]) => {
  if (positions.length == 0) {
    return 0;
  }
  const sum = positions
    .map((x) => x.sumUnrealizedPnl)
    .reduce((total: number, pnl: number) => {
      return total + pnl;
    });
  return sum;
};

export const getSumGrossPnl = (positions: Position[]) => {
  if (positions.length == 0) {
    return 0;
  }
  const sum = positions
    .map((x) => x.sumGrossPnl)
    .reduce((total: number, pnl: number) => {
      return total + pnl;
    });
  return sum;
};

export const getSumNetPnl = (positions: Position[]) => {
  if (positions.length == 0) {
    return 0;
  }
  const sum = positions
    .map((x) => x.sumNetPnl)
    .reduce((total: number, pnl: number) => {
      return total + pnl;
    });
  return sum;
};

export const getSumCommission = (positions: Position[]) => {
  if (positions.length == 0) {
    return 0;
  }
  const sum = positions
    .map((x) => x.sumCommission)
    .reduce((total: number, pnl: number) => {
      return total + pnl;
    });
  return formatUsd(sum, 0);
};

export const getSumSlippage = (positions: Position[]) => {
  if (positions.length == 0) {
    return 0;
  }
  const sum = positions
    .map((x) => x.sumSlippageAmount)
    .reduce((total: number, pnl: number) => {
      return total + pnl;
    });
  return formatUsd(sum, 0);
};
