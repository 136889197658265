import * as React from 'react';
import { FunctionComponent, useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { GlobalState } from '../../../core/root';
import { Subscription } from '../../../types';
import CollapsePanel from '../../shared/components/CollapsePanel';
import Spinner from '../../shared/components/Spinner';
import * as actionCreators from '../store/action-creators';

import SubscriptionList from './SubscriptionList';

export interface Props {
  subscriptions: Subscription[];
  subscriptionsLoaded: boolean;
  loadSubscriptions(): void;
  addSubscription(sub: Subscription): void;
}

const SubscriptionListScreen: FunctionComponent<Props> = ({}: Props) => {
  const state = useSelector((gs: GlobalState) => gs);
  const subscriptionsLoaded: boolean = state.subscriptionState.loaded;
  const subscriptions: Subscription[] = state.subscriptionState.subscriptions;
  const activeSubscriptions = subscriptions.filter((x) => x.active);
  const inactiveSubscriptions = subscriptions.filter((x) => !x.active);

  const [collapseActivePanel, setCollapseActivePanel] = useState(false);
  const [collapseInactivePanel, setCollapseInactivePanel] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!subscriptionsLoaded) {
      dispatch(actionCreators.loadSubscriptionsAsync());
    }
  });

  const createSubscriptionClickhandler = () => {
    dispatch(
      actionCreators.addSubscriptionAsync({
        contractId: undefined,
        securityId: undefined,
        strategyId: undefined
      } as Subscription)
    );
  };

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item active">Subscriptions</li>
      </ol>

      <div className="page-header">
        <h3>
          Subscriptions <small className="no-wrap"></small>
        </h3>
      </div>

      {!subscriptionsLoaded && <Spinner />}

      <CollapsePanel
        title="Active Subscriptions"
        bsStyle="success"
        collapsed={collapseActivePanel}
        onToggle={() => setCollapseActivePanel(!collapseActivePanel)}
      >
        {activeSubscriptions.length > 0 ? (
          <SubscriptionList subscriptions={activeSubscriptions} />
        ) : (
          <span className="small">No active subscriptions..</span>
        )}
      </CollapsePanel>

      <CollapsePanel
        title="Inactive Subscriptions"
        collapsed={collapseInactivePanel}
        onToggle={() => setCollapseInactivePanel(!collapseInactivePanel)}
      >
        {inactiveSubscriptions.length > 0 ? (
          <SubscriptionList subscriptions={inactiveSubscriptions} />
        ) : (
          <span className="small">No inactive subscriptions..</span>
        )}
      </CollapsePanel>

      <hr />
      <div className="text-right">
        <button className="btn btn-sm btn-default" type="submit" onClick={createSubscriptionClickhandler}>
          Create..
        </button>
      </div>
    </div>
  );
};

export default SubscriptionListScreen;
