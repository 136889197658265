import * as React from 'react';

import classNames from 'classnames';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { ScheduleStatus } from '../../../../types';
import Spinner from '../../../shared/components/Spinner';
import { getScheduleStatusAsync, toggleRunScheduleAsync } from '../../store/schedule-status/service';

export interface Props {
  loading: boolean;
  status: ScheduleStatus;
  show: boolean;
  close(): void;
}

export const ScheduleDialog = ({ loading, status, show, close }: Props) => {
  const dispatch = useDispatch();

  const checkControlCenterStatusHandler = () => {
    // eslint-disable-next-line no-console
    console.log('checking schedule-status..');
    dispatch(getScheduleStatusAsync());
  };

  const toggleRunScheduleHandler = () => {
    dispatch(toggleRunScheduleAsync());
  };

  return (
    <Modal show={show} bsSize="small" onHide={close} aria-labelledby="contained-modal-title">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title">
          <span>Schedule App</span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="row form-horizontal">
          <div className="col-xs-12 gap-top-10">
            <div className="form-group form-group-sm">
              <label className="col-xs-7 control-label">Schedule App</label>
              <label className="col-xs-5 text-center" style={{ lineHeight: '1.9rem' }}>
                <i
                  className={classNames('fa', 'fa-power-off', 'fa-lg', {
                    online: status.isAppRunning,
                    offline: !status.isAppRunning
                  })}
                  aria-hidden="true"
                ></i>
              </label>
            </div>

            <div className="form-group form-group-sm">
              <label className="col-xs-7 control-label">
                <span style={{ lineHeight: '1.7rem' }}>Schedule Started</span>
              </label>
              <div className="col-xs-5">
                <button
                  type="button"
                  className="btn btn-default btn-block"
                  onClick={toggleRunScheduleHandler}
                  disabled={!status.isAppRunning}
                >
                  <i
                    className={classNames('fa', 'fa-power-off', 'fa-lg', {
                      online: status.isScheduleStarted,
                      pending: status.isAppRunning && !status.isScheduleStarted,
                      offline: !status.isAppRunning
                    })}
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
            </div>

            <div className="section">
              <p>Details</p>
            </div>

            {!!status.isAppRunning ? (
              <div className="table-responsive">
                <table className="table table-bordered table-striped table-condensed">
                  <tbody>
                    <tr>
                      <td className="middle small text-left">Version</td>
                      <td className="middle small text-right" width="100px">
                        v{status.appVersion}
                      </td>
                    </tr>
                    <tr>
                      <td className="middle small text-left">Time</td>
                      <td className="middle small text-right">{status.appTimestamp}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <span className="small">Offline..</span>
            )}

            <div className="section">
              <hr />
              <p>Web API</p>
            </div>

            <div className="table-responsive">
              <table className="table table-bordered table-striped table-condensed">
                <tbody>
                  <tr>
                    <td className="middle small text-left">Version</td>
                    <td className="middle small text-right" width="100px">
                      v{status.webApiVersion}
                    </td>
                  </tr>
                  <tr>
                    <td className="middle small text-left">Time</td>
                    <td className="middle small text-right">{status.webApiTimestamp}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button bsStyle="primary" onClick={checkControlCenterStatusHandler}>
          Check Status
        </Button>
        <Button onClick={close}>Close</Button>
      </Modal.Footer>

      {loading && <Spinner />}
    </Modal>
  );
};
