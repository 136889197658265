import React, { useState, useEffect } from 'react';

import classNames from 'classnames';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, Link, withRouter } from 'react-router-dom';
import Switch from 'react-switch';

import { GlobalState } from '../../../core/root';
import { ContractEntity as Contract, ContractOrderInfo, Security } from '../../../types';
import * as securitiesActionCreators from '../../security/store/action-creators';
import axiosApi from '../../shared/axios-api';
import CollapsePanel from '../../shared/components/CollapsePanel';
import { ConfirmDialog } from '../../shared/components/ConfirmDialog';
import Spinner from '../../shared/components/Spinner';
import { BARCHART_FIRST_NOTICE_URL, CONTRACT_DETAILS_URL } from '../../shared/constants/links';
import { toDateMonthYear } from '../../shared/utils/date-format-utils';
import { trimTimePartFromDate } from '../../shared/utils/date-picker-utils';
import { getDeliveryMonthType } from '../../shared/utils/delivery-month';
import { showNetworkError } from '../../shared/utils/growl-utils';
import { contractModulePath } from '../routes';
import { loadContractsAsync, removeContractAsync, updateContractAsync } from '../store/service';

export interface RouteParamProps {
  id: string;
}

const ContractScreen: React.FunctionComponent<RouteComponentProps<RouteParamProps>> = ({ match, history }) => {
  const id = match.params.id;
  const state: GlobalState = useSelector((gs: GlobalState) => gs);
  const contractsLoaded: boolean = state.contractState.loaded;
  const securities: Security[] = state.securityState.securities;
  const securitiesLoaded: boolean = state.securityState.loaded;

  const selectedContract = state.contractState.contracts.find((x) => x.id === Number(id));
  const selectedSecurity = securities.find((x) => x.id === selectedContract?.securityId);

  const [contractState, setContractState] = useState(selectedContract);
  const [showDeleteContractDialog, setShowDeleteContractDialog] = useState(false);

  const dispatch = useDispatch();
  const loadContracts = () => dispatch(loadContractsAsync());
  const updateContract = (contract: Contract) => dispatch(updateContractAsync(contract));
  const removeContract = (id: number) => dispatch(removeContractAsync(id));
  const loadSecurities = () => dispatch(securitiesActionCreators.loadSecuritiesAsync());

  useEffect(() => {
    if (!securitiesLoaded) {
      loadSecurities();
    }
    if (!contractsLoaded) {
      loadContracts();
    }
  }, []);

  useEffect(() => {
    if (selectedContract && selectedSecurity) {
      const con = {
        ...selectedContract,
        security: { ...selectedSecurity }
      } as Contract;
      setContractState(con);
    }
  }, [selectedContract, selectedSecurity]);

  const updateContractState = (obj: object) => {
    if (!contractState) {
      return;
    }
    const field = Object.keys(obj)[0];
    const con = {
      ...contractState,
      security: { ...selectedSecurity },
      [field]: obj[field]
    } as Contract;
    setContractState(con);
  };

  const fetchContractCommission = () => {
    axiosApi
      .post<ContractOrderInfo>('/contract-orderinfo', contractState, {
        params: { orderSize: 1 }
      })
      .then((response) => {
        const { commission } = response.data;
        if (!contractState) {
          return;
        }
        const con = {
          ...contractState,
          security: { ...selectedSecurity },
          standardCommission: commission
        } as Contract;
        setContractState(con);
      })
      .catch((error) => {
        showNetworkError(error);
      });
  };

  const navigateToList = () => {
    // const url = `${securityModulePath}/${this.props.contract.securityId}?tab=contracts`;
    const url = `${contractModulePath}`;
    history.push(url);
  };

  const deleteContractHandler = () => {
    if (!contractState) {
      return;
    }
    removeContract(contractState.id);
    navigateToList();
  };

  const mapContractFromState = (): Contract => {
    return {
      ...contractState,
      lastTradeDate: trimTimePartFromDate(lastTradeDate),
      firstNoticeDate: trimTimePartFromDate(firstNoticeDate),
      rolloverDate: trimTimePartFromDate(rolloverDate),
      firstTradeDate: trimTimePartFromDate(firstTradeDate)
    } as Contract;
  };

  const updateContractHandler = () => {
    if (!contractState) {
      return;
    }
    const item = mapContractFromState();
    updateContract(item);
    navigateToList();
  };

  const loading = !contractsLoaded || !securitiesLoaded;

  if (loading) {
    return <Spinner />;
  }

  if (!contractState || !selectedSecurity) {
    return null;
  }

  const {
    localSymbol,
    type,
    year,
    contractMonth,
    refId,
    numberOfTradeMonths,
    skipGeneratePartials,
    standardCommission,
    firstTradeDate,
    rolloverDate,
    firstNoticeDate,
    lastTradeDate
  } = contractState;

  const popoverStandardCommission = (
    <Popover id="popoverStandardCommission">
      Standard Commission value is used for simulated trading and backtesting where commission is not set from broker-system
    </Popover>
  );

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/contracts">Contracts</Link>
        </li>
        <li className="breadcrumb-item active">{localSymbol}</li>
      </ol>

      <div className="page-header">
        <h3>
          {localSymbol} <small className="no-wrap gap-left-5">{selectedSecurity.name}</small>
        </h3>
      </div>

      <div className="row form-horizontal">
        <div className="col-md-6">
          <CollapsePanel title="Contract Info">
            <div className="row form-horizontal">
              <div className="col-xs-12">
                <div className="table-responsive">
                  <table className="table table-bordered table-striped table-condensed">
                    <tbody>
                      <tr>
                        <td className="middle small text-center" colSpan={2}>
                          <span className="grey">GENERAL</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="middle small text-right" width="50%">
                          Underlying
                        </td>
                        <td className="middle small text-left">{selectedSecurity.name}</td>
                      </tr>
                      <tr>
                        <td className="middle small text-right">Type</td>
                        <td className="middle small">
                          <span className={classNames('label', { 'label-info': type === 'FUT' })}>{type}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="middle small text-right">Local Symbol</td>
                        <td className="middle small text-left">{localSymbol}</td>
                      </tr>
                      <tr>
                        <td className="middle small text-right">Delivery Year</td>
                        <td className="middle small text-left">{year}</td>
                      </tr>
                      <tr>
                        <td className="middle small text-right">Delivery Month</td>
                        <td className="middle small text-left">{contractMonth}</td>
                      </tr>
                      <tr>
                        <td className="middle small text-right">Delivery Type</td>
                        <td className="middle small text-left">{getDeliveryMonthType(contractMonth, selectedSecurity)}</td>
                      </tr>
                      <tr>
                        <td className="middle small text-right">Ref. ID</td>
                        <td className="middle small text-left">{refId}</td>
                      </tr>
                      <tr>
                        <td className="middle small text-right">More Info</td>
                        <td className="middle small text-left">
                          <a href={`${CONTRACT_DETAILS_URL}${refId}`} target="_blank" rel="noreferrer">
                            Contract Details
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="middle small text-center" colSpan={2}>
                          <span className="grey">CALENDAR</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="middle small text-right">Num. Trade Months</td>
                        <td className="middle small text-left">{numberOfTradeMonths}</td>
                      </tr>
                      <tr>
                        <td className="middle small text-right">First Trade Date</td>
                        <td className="middle small text-left">{toDateMonthYear(firstTradeDate)}</td>
                      </tr>
                      <tr>
                        <td className="middle small text-right">Roll Over Date</td>
                        <td className="middle small text-left">{toDateMonthYear(rolloverDate)}</td>
                      </tr>
                      <tr>
                        <td className="middle small text-right">First Notice Date</td>
                        <td className="middle small text-left">{toDateMonthYear(firstNoticeDate)}</td>
                      </tr>
                      <tr>
                        <td className="middle small text-right">Last Trade Date</td>
                        <td className="middle small text-left">{toDateMonthYear(lastTradeDate)}</td>
                      </tr>
                      <tr>
                        <td className="middle small text-right">More Info</td>
                        <td className="middle small text-left">
                          <a href={BARCHART_FIRST_NOTICE_URL} target="_blank" rel="noreferrer">
                            Dates from BarChart
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </CollapsePanel>
        </div>

        <div className="col-md-6">
          <CollapsePanel title="Backtesting">
            <div className="form-group form-group-sm">
              <label htmlFor="contract_init_margin" className="col-xs-12 col-sm-5 control-label">
                <span>Std. Commission</span>
                <OverlayTrigger placement="top" overlay={popoverStandardCommission}>
                  <span className="gap-left-10">
                    <i className="fa fa-question-circle fa-lg text-info" />
                  </span>
                </OverlayTrigger>
              </label>
              <div className="col-xs-8 col-sm-5">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span style={{ display: 'inline-block', width: '20px', textAlign: 'center' }}>$</span>
                  </div>
                  <input
                    type="number"
                    step="any"
                    value={standardCommission}
                    onChange={(event) => updateContractState({ standardCommission: Number(event.target.value) })}
                    className="form-control"
                    id="contract_init_margin"
                    placeholder="Amount"
                  />
                  {/* <div className="input-group-addon">.00</div> */}
                </div>
              </div>
              <div className="col-xs-4 col-sm-2">
                <button className="btn btn-warning btn-sm btn-block" type="button" onClick={fetchContractCommission}>
                  <i className="glyphicon glyphicon-flash"></i>
                </button>
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label className="col-sm-5 control-label">Skip Gen. Partials</label>
              <div className="col-sm-7">
                <label className="react-switch react-switch--sm">
                  <Switch
                    onChange={() => updateContractState({ skipGeneratePartials: !skipGeneratePartials })}
                    checked={skipGeneratePartials}
                  />
                </label>
              </div>
            </div>
          </CollapsePanel>
        </div>
      </div>

      <hr />
      <div className="row">
        <div className="col-xs-4">
          <button
            type="button"
            className="btn btn-danger ripple gap-bottom-5 gap-right-5"
            onClick={() => setShowDeleteContractDialog(true)}
          >
            Delete
          </button>
        </div>
        <div className="col-xs-8 text-right">
          <button type="button" className="btn btn-primary ripple gap-bottom-5 gap-right-5" onClick={updateContractHandler}>
            Save
          </button>
          <button type="button" className="btn btn-default ripple gap-bottom-5" onClick={navigateToList}>
            Close
          </button>
        </div>
      </div>

      <ConfirmDialog
        show={showDeleteContractDialog}
        cancel={() => setShowDeleteContractDialog(false)}
        confirm={deleteContractHandler}
        title="Confirm delete"
        text="Are you sure you want to delete the contract?"
      />
    </div>
  );
};

export default withRouter(ContractScreen);
