import * as React from 'react';

import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { BacktestSummary as Summary, Security, Strategy } from '../../../../types';
import * as securityActionCreators from '../../../security/store/action-creators';
import { formatUsd } from '../../../shared/utils/currency-utils';
import { toMonthTime } from '../../../shared/utils/date-format-utils';
import { setDecimalSpaces } from '../../../shared/utils/number-utils';
import * as strategyActionCreators from '../../../strategy/store/action-creators';
import { ModuleState } from '../../state';

export interface Props extends RouteComponentProps {
  summaries: Summary[];
  onReload(): void;
  showSecurityNames?: boolean;
}

const BacktestSummaryTable: React.FunctionComponent<Props> = ({ summaries, onReload, history, showSecurityNames }) => {
  const state: ModuleState = useSelector((ms: ModuleState) => ms);
  const securities: Security[] = state.securityState.securities;
  const selectedSecurity: Security | undefined = state.securityState.ui.securityPicked;
  const strategies: Strategy[] = state.strategyState.strategies;

  const dispatch = useDispatch();

  const goToBackTesting = (security: Security, strategy?: Strategy) => {
    if (security.id !== selectedSecurity?.id) {
      dispatch(securityActionCreators.setSecurityPicked(security));
    }
    if (strategy) {
      dispatch(strategyActionCreators.setStrategyPicked(strategy));
      //history.push(backtestModulePath);
      history.push(`/strategies/${strategy.id}?tab=backtests`);
    }
  };

  const header = (
    <tr>
      {showSecurityNames ? (
        <React.Fragment>
          <th className="middle small">Security</th>
          <td className="middle small">Strategy</td>
        </React.Fragment>
      ) : (
        <th className="middle small">Strategy</th>
      )}
      <td className="middle small text-center">Bcktst</td>
      <td className="middle small text-right">Trades</td>
      <td className="middle small text-right">Comm.</td>
      <td className="middle small text-right">Rlzd. PnL</td>
      <td className="middle small text-right">Unrl. PnL</td>
      <td className="middle small text-right">Total PnL</td>
      <td className="middle small text-center">PnL Pct Avg</td>
      <td className="middle small text-center">Updated</td>
    </tr>
  );

  // work-around: security is null
  summaries.forEach((x) => (x.security = securities.find((y) => y.id == x.securityId) as Security));

  const sortByName = (a: Summary, b: Summary) => {
    if (a.security?.name > b.security?.name) return 1;
    if (a.security?.name < b.security?.name) return -1;
    return 0;
  };

  const sortByTotalPnlPct = (a: Summary, b: Summary) => {
    const aPnlPct = Number(setDecimalSpaces(a.totalPnlPct, 0)) || 0;
    const bPnlPct = Number(setDecimalSpaces(b.totalPnlPct, 0)) || 0;
    if (aPnlPct < bPnlPct) return 1;
    if (aPnlPct > bPnlPct) return -1;
    if (a.totalPnl < b.totalPnl) return 1;
    if (a.totalPnl > b.totalPnl) return -1;
    return 0;
  };

  const sort = showSecurityNames ? sortByName : sortByTotalPnlPct;

  const mapToRows = (list: Summary[]) => {
    return list.sort(sort).map((summary: Summary, index: number) => {
      const strategy = strategies.find((x) => x.id === summary.strategyId);

      const totalPnlPct = setDecimalSpaces(summary.totalPnlPct, 0);
      const totalPnlPctElement =
        totalPnlPct !== undefined && totalPnlPct !== null ? (
          <span
            className={classNames({
              'success-text': totalPnlPct !== undefined && totalPnlPct >= 60,
              'danger-text2': totalPnlPct !== undefined && totalPnlPct < 50
            })}
          >
            {`${totalPnlPct}%`}
          </span>
        ) : (
          <span>{'-'}</span>
        );

      return (
        <tr key={index} className="clickable" onClick={() => goToBackTesting(summary.security, strategy)}>
          {showSecurityNames ? (
            <React.Fragment>
              <th className="middle small">{summary.security.name}</th>
              <td className="middle small">{strategy?.name}</td>
            </React.Fragment>
          ) : (
            <th className="middle small">{strategy?.name}</th>
          )}
          <td className="middle small text-center">{summary.numberOfBacktests}</td>
          <td className="middle small text-right">{summary.count}</td>
          <td className="middle small text-right">{formatUsd(summary.commission, 0)}</td>
          <td className={classNames('middle', 'small', 'text-right', { 'danger-text2': summary.netPnl < 0 })}>
            {formatUsd(summary.netPnl, 0)}
          </td>
          <td className={classNames('middle', 'small', 'text-right', { 'danger-text2': summary.unrealizedPnl < 0 })}>
            {formatUsd(summary.unrealizedPnl, 0)}
          </td>
          <td className={classNames('middle', 'small', 'text-right', { 'danger-text2': summary.totalPnl < 0 })}>
            {formatUsd(summary.totalPnl, 0)}
          </td>
          <td className="middle small text-center">{totalPnlPctElement}</td>
          <td className="middle small text-center vborder">{toMonthTime(summary.lastUpdated)}</td>
        </tr>
      );
    });
  };

  const rows: JSX.Element[] | undefined = summaries ? mapToRows(summaries) : undefined;

  const table = (
    <table className="table table-striped table-hover table-condensed">
      <thead>{header}</thead>
      <tbody>{rows}</tbody>
    </table>
  );

  return (
    <div>
      {summaries.length > 0 ? (
        <div className="scrolling outer">
          <div className="inner">{table}</div>
        </div>
      ) : (
        <span className="small">No summaries..</span>
      )}

      <hr style={{ margin: '10px 0 10px 0' }} />
      <div className="text-right">
        <button className="btn btn-default mt-10 ripple" type="submit" onClick={onReload}>
          <span>
            Reload&nbsp;&nbsp;
            <i className="fa fa-refresh" />
          </span>
        </button>
      </div>
    </div>
  );
};

export default withRouter(BacktestSummaryTable);
