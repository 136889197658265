export enum FirstNoticeType {
  LastTradeDate = 1,
  PreviousMonth = 2
}

export enum AdxCrossOverMode {
  NoCrossOver = 0,
  CrossOver = 1,
  CrossOverSwing = 2
}
export enum AverageDirectionalIndexMode {
  NoAdx = 0,
  InsideAdx = 1,
  OutsideAdx = 2
}
export enum ValidationMode {
  Above = 0,
  Below = 1
}
export enum BacktestStatus {
  NA = 0,
  Running = 1,
  Completed = 2,
  Failed = 3
}
export enum BarPeakMode {
  NoBarPeaks = 0,
  TrendFollowing = 1,
  CounterTrend = 2,
  TriangleTrend = 3,
  TriangleCouterTrend = 4
}
export enum BarSize {
  NA = 0,
  _1_SEC = 1,
  _5_SEC = 2,
  _10_SEC = 3,
  _15_SEC = 4,
  _30_SEC = 5,
  _1_MIN = 6,
  _2_MIN = 7,
  _3_MIN = 8,
  _5_MIN = 9,
  _10_MIN = 10,
  _15_MIN = 11,
  _20_MIN = 12,
  _30_MIN = 13,
  _1_HR = 14,
  _2_HRS = 15,
  _3_HRS = 16,
  _4_HRS = 17,
  _8_HRS = 18,
  _1_DAY = 19,
  _1_WEEK = 20,
  _1_MONTH = 21
}
export enum ValidationType {
  ClosePrice = 0,
  HighPrice = 1,
  MidPrice = 2,
  LowPrice = 3,
  Volume = 99
}
export enum BollingerBandMode {
  NoBollingerBand = 0,
  InsideThreshold = 1,
  OutsideThreshold = 2,
  OutsideInvertedThreshold = 3
}
export enum CciMode {
  NoCommodityChannelIndex = 0,
  InsideThreshold = 1,
  OutsideThreshold = 2
}
export enum CompareType {
  Past = 0,
  SimpleMovingAverage = 1,
  ExponentialMovingAverage = 2
}
export enum CrossOverMode {
  NoCrossOver = 0,
  CrossOver = 1,
  CrossOverSecondOverFirst = 2,
  CrossOverFirstOverSecond = 3,
  CrossOverSwing = 4,
  CrossOverSecondOverFirstSwing = 5,
  CrossOverFirstOverSecondSwing = 6
}
export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}
export enum DeliveryMonthType {
  Inactive = 0,
  Commercial = 1,
  Retail = 2
}
export enum DomainLogType {
  Section = -1,
  Trace = 0,
  Info = 1,
  Warn = 2,
  Error = 3,
  Fatal = 4,
  None = 5
}
export enum DoubleUpMode {
  None = 0,
  StreakOfOne = 1,
  StreakOfTwo = 2
}
export enum DoubleUpType {
  Size = 0,
  Amount = 1
}
export enum EodCloseMode {
  NoEodCloseMode = 0,
  InsideThreshold = 1,
  OutsideThreshold = 2,
  OutsideInvertedThreshold = 3
}
export enum Forecast {
  NA = 0,
  Wait = 1,
  Block = 2,
  Long = 3,
  Short = 4,
  Stay = 6,
  Exit = 7,
  ExitPending = 8,
  Closed = 9,
  Averaging = 10
}
export enum HistoryPeriodType {
  Day = 1,
  Month = 2
}
export enum IndicatorDirection {
  Entry = 0,
  Exit = 1
}
export enum IndicatorSetType {
  Any = 0,
  Combined = 1
}
export enum IndicatorType {
  AverageTrueRange = 1,
  BollingerBand = 2,
  Range = 3,
  Compare = 4,
  Shift = 5,
  Tick = 6,
  Adx = 7,
  Squeeze = 8,
  AvgUpDown = 9,
  OpeningHour = 10,
  AmountUpDown = 11,
  DonchianChannels = 12,
  BreakOut = 13,
  Pullback = 14,
  MACD = 15,
  AwesomeOscillator = 16,
  KeltnerChannels = 17,
  TrendScore = 18,
  TrendFollowing = 19,
  Season = 20,
  TradingHours = 21,
  TradingWeekdays = 22,
  TrendFollowingAtr = 23,
  Leech = 24,
  ContinuousMa = 25,
  TheFox = 26,
  Thunnel = 27,
  HighLowSeq = 28
}
export enum IndicatorCategory {
  Time = 0,
  Risk = 1,
  Volume = 2,
  Volatility = 3,
  Momentum = 4, // 0
  Trend = 5 // 1
}
export enum IndicatorMode {
  Conditional = 1,
  Absolute = 2
}
export enum KcMode {
  NoKeltnerChannel = 0,
  InsideThreshold = 1,
  OutsideThreshold = 2
}
export enum KeepAlive {
  ImmediateOrCancel = 0,
  GoodUntilCanceled = 1,
  GoodUntilExpires = 2
}
export enum MacdMode {
  NoMacd = 0,
  MacdOverSignalLine = 1,
  MacdCrossOver = 2,
  MacdCrossOverBelowZero = 3,
  MacdCrossOverSwing = 4,
  MacdCrossOverBelowZeroSwing = 5
}
export enum MarketTradingHours {
  Regular = 0,
  AfterHours = 1,
  Closed = 2
}
export enum MarketType {
  Any = 0,
  Bull = 1,
  Bear = 2,
  Ambigious = 99
}
export enum MessageType {
  Profit = 1,
  Loss = 2,
  ConnectionLost = 3,
  PositionOffset = 4,
  PositionOffsetFixed = 5,
  OpenOrderOffset = 6,
  OpenOrderOffsetFixed = 7
}
export enum MfiMode {
  NoMoneyFlowIndex = 0,
  InsideRange = 1,
  OuteSideRange = 2
}
export enum MovingAverageCrossOver {
  NoCrossOver = 0,
  CrossOver = 1,
  CrossOverSwing = 2
}
export enum AmountUpDownMode {
  TrendFollowing = 0,
  CounterTrend = 1
}
export enum MovingAverageDirectionMode {
  NoDirection = 0,
  TrendFollowing = 1,
  CounterTrend = 2
}
export enum MovingStopLoss {
  Off = 0,
  NoLoss = 1,
  Trailing = 2,
  FluentTrailing = 3
}
export enum OrderAction {
  Buy = 1,
  Sell = 2
}
export enum OrderDirection {
  Enter = 1,
  Exit = 2
}
export enum OrderType {
  NA = 0,
  Market = 1,
  Limit = 2,
  Stop = 3
}
export enum PeakMode {
  NoPeak = 0,
  Peak = 1,
  Inverted = 2
}
export enum PeriodType {
  NA = 0,
  Live = 1,
  Simulation = 2,
  Backtest = 3
}
export enum PositionStateType {
  Regular = 0,
  Formula1 = 1
}
export enum PricePeakDirection {
  NA = 0,
  Up = 1,
  Down = 2
}
export enum ProductType {
  Currencies = 1,
  Energies = 2,
  Energies_Heating = 3,
  Financials_Bonds = 4,
  Financials_Stocks = 5,
  Grains = 6,
  Meats = 7,
  Metals = 8,
  Softs = 9,
  Others = 10
}
export enum Pullback {
  NoPullback = 0,
  Use1And3 = 1,
  Use1And5 = 2
}
export enum QueryType {
  PriceGraph = 0,
  PivotGraph = 9,
  VolumeGraph = 1,
  Rsi = 2,
  Mfi = 3,
  Cci = 4,
  Adx = 5,
  Atr = 6,
  Wpr = 7,
  Bb = 8,
  NA = 99
}
export enum RsiCrossOverMode {
  NoCrossOver = 0,
  TrendFollowing = 1,
  CounterTrend = 2
}
export enum RsiMode {
  NoRsi = 0,
  InsideThreshold = 1,
  OutsideThreshold = 2,
  OutsideInvertedThreshold = 3
}
export enum RviMode {
  NoRelativeVigorIndex = 0,
  RviOverSignal = 1,
  RviSignalCrossOver = 2,
  RviSignalCrossOverBelowZero = 3,
  RviSignalCrossOverSwing = 4,
  RviSignalCrossOverBelowZeroSwing = 5
}
export enum ScheduleType {
  Primary = 1,
  ConsistencyCheck = 2
}
export enum SingleDynamicAverageMode {
  NoMovingAverage = 0,
  SimpleMovingAverage = 1,
  ExponentialMovingAverage = 2
}
export enum SqueezeMode {
  NoSqueeze = 0,
  Inside = 1,
  Outside = 2
}
export enum SqueezeResult {
  Invalid = 0,
  Inside = 1,
  Outside = 2
}
export enum StochasticMode {
  NoStochastic = 0,
  InsideThresholds = 1,
  OutsideThresholds = 2,
  InsideThresholdsCrossOver = 3,
  OutsideThresholdsCrossOver = 4,
  InsideThresholdsCrossOverSwing = 5,
  OutsideThresholdsCrossOverSwing = 6
}
export enum RiskType {
  Currency = 0,
  AvgTrueRange = 1,
  Ticks = 2
}
export enum StreakMode {
  NoStreak = 0,
  Streak = 1,
  Inverted = 2
}
export enum StreakType {
  ClosePrice = 0,
  HighPrice = 1,
  LowPrice = 2
}
export enum StrengthCrossOverMode {
  NoCrossOver = 0,
  TrendFollowing = 1,
  CounterTrend = 2
}
export enum StrengthHistoryMode {
  NoStrengthHistory = 0,
  TrendFollowing = 1,
  CounterTrend = 2
}
export enum Support {
  NoSupport = 0,
  Use1And3 = 1,
  Use1And5 = 2,
  Use1And8 = 3
}
export enum TickChangeMode {
  NoTickChangeMode = 0,
  InsideRange = 1,
  OuteSideRange = 2
}
export enum TrendDirection {
  NA = 0,
  Up = 1,
  UpStronger = 2,
  Down = 3,
  DownStronger = 4,
  Ambigious = 99
}
export enum VolumeMode {
  NoVolume = 0,
  Increase = 1,
  Decrease = 2
}
export enum WhatToShow {
  TRADES = 0,
  MIDPOINT = 1,
  BID = 2,
  ASK = 3,
  BID_ASK = 4
}
export enum WprMode {
  NoWpr = 0,
  InsideThreshold = 1,
  OutsideThreshold = 2,
  OutsideInvertedThreshold = 3
}

export enum DatabaseType {
  MsSql = 0,
  AzureDb = 1
}

export interface Account {
  id: number;
  userName: string;
  userPassword: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  isAdminUser: boolean;
  isSuperUser: boolean;
  liveTradingInvestment: number;
  paperTradingInvestment: number;
  disabled: boolean;
  lastVisited: Date;
  /* features */
  useEmailNotification: boolean;
  disableRefreshBrokerConnection: boolean;
  disableControlCenterRequests: boolean;
}

export interface Backtest {
  avgMinutes: number;
  avgPnl: number;
  contract: ContractEntity;
  contractId?: number;
  contractPartialId?: number;
  execution?: TimeSpan;
  executionTicks?: number;
  id: number;
  jobsExecuted: number;
  jobsFailed: number;
  lastExecuted?: Date;
  medianMinutes: number;
  partial: ContractPartial;
  profitCountPct?: number;
  realizedProfitPct?: number;
  status: BacktestStatus;
  statusMessage: string;
  strategy: Strategy;
  strategyId: number;
  sumCommission: number;
  sumNetPnl: number;
  sumPnl: number;
  sumPositions: number;
  sumSlippageAmount: number;
  sumSlippageTicks: number;
  sumTotalGain: number;
  sumTotalLoss: number;
  sumTotalPnl: number;
  sumUnrealizedPnl: number;
  totalMinutes: number;
  totalPnlPct?: number;
  useOverlapping: boolean;
}
export interface BacktestRequest {
  contract: ContractEntity;
  overlappingTrades: boolean;
  security: Security;
  strategy: Strategy;
  summary: Backtest;
}
export interface BacktestResponse {
  backtest: Backtest;
  positions: Position[];
}
export interface BacktestSummary {
  commission: number;
  count: number;
  fromPeriod: Date;
  id: number;
  lastUpdated?: Date;
  netPnl: number;
  numberOfBacktests: number;
  security: Security;
  securityId: number;
  strategy: Strategy;
  strategyId: number;
  toPeriod: Date;
  totalGain: number;
  totalLoss: number;
  totalPnl: number;
  totalPnlPct?: number;
  unrealizedPnl: number;
}
export interface BrokerAccountInfo {
  totalCashValue: number;
  totalPnl: number;
  userPnl: number;
  userPnlUsd: number;
}
export interface BrokerSystem {
  autoConnect: boolean;
  autoConnectInterval: number;
  backTestClientId: number;
  daylightSavingTime: boolean;
  id: number;
  liveTradingAccount: string;
  liveTradingHost: string;
  liveTradingPort: number;
  name: string;
  nokUsdExchangeRate: number;
  paperTradingAccount: string;
  paperTradingHost: string;
  paperTradingPort: number;
  requestTimeout: number;
  serviceClientId: number;
  testClientId: number;
  timeZoneKey: number;
  useLiveTradingAccount: boolean;
  webClientId: number;
}

export interface Portfolio {
  accountCode: string;
  contractRefId: number;
  contractSymbol: number;
  contractType: string;
  echangeKey: string;
  positionSize: number;
  marketPrice: number;
  marketValue: number;
  averageCost: number;
  unrealizedPnl: number;
  realizedPnl: number;
}

export interface AccountInfo {
  accountCode: string;
  paperTradingMode: boolean;
  cashBalanceNok: number;
  cashBalanceUsd: number;
  cashBalanceBase: number;
  availableFundsNok: number;
  availableFundsUsd: number;
  availableFundsBase: number;
  exchangeRateNok: number;
  exchangeRateUsd: number;
  exchangeRateBase: number;
  realizedPnlNok: number;
  realizedPnlUsd: number;
  realizedPnlBase: number;
  unrealizedPnlNok: number;
  unrealizedPnlUsd: number;
  unrealizedPnlBase: number;
  initMarginReqNok: number;
  initMarginReqUsd: number;
  initMarginReqBase: number;
  maintMarginReqNok: number;
  maintMarginReqUsd: number;
  maintMarginReqBase: number;
  timestamp: string;
  portfolios: Portfolio[];
}

export interface ScheduleStatus {
  webApiVersion: string;
  webApiTimeZone: number;
  webApiTimestamp: string;
  appVersion: string;
  appTimestamp: string;
  isAppRunning: boolean;
  isScheduleStarted: boolean;
}

export interface ContractEntity {
  contractMonth: string;
  firstNoticeDate?: Date;
  firstNoticeMilliseconds?: number;
  firstTradeDate?: Date;
  firstTradeMilliseconds?: number;
  id: number;
  lastTradeDate?: Date;
  lastTradeMilliseconds?: number;
  localSymbol: string;
  monthCode: string;
  numberOfTradeMonths: number;
  refId: number;
  rolloverDate?: Date;
  rolloverMilliseconds?: number;
  security: Security;
  securityId: number;
  sequence: number;
  skipGeneratePartials: boolean;
  useMonthCode: boolean;
  year: number;
  standardCommission: number;
  type: string;
}
export interface ContractInfo {
  close: Date;
  contractId: number;
  contractMonth: string;
  currency: string;
  deliveryMonth: Date;
  exchange: string;
  lastTradeDate: Date;
  localSymbol: string;
  longName: string;
  marketName: string;
  multiplier: number;
  open: Date;
  securityType: string;
  symbol: string;
  tickIncrement: number;
  timeZoneId: string;
  tradingHours: string;
  utcOffset: TimeSpan;
}
export interface ContractOrderInfo {
  commission: number;
  commissionCurrency: string;
  initialMargin: number;
  maintenanceMargin: number;
  errorCode: number;
  errorMessage?: string;
}
export interface ContractPartial {
  averageCloseChangeInTicks: number;
  contract: ContractEntity;
  contractId: number;
  generalAtrTicks: number;
  generalAtrTicksBarSize: BarSize;
  id: number;
  partialMonth: string;
  periodEnd: Date;
  periodEndMilliseconds?: number;
  periodStart: Date;
  periodStartMilliseconds?: number;
}
export interface ContractPartialQuote {
  barSize: BarSize;
  contractPartialId: number;
  numQuotes: number;
}
export interface DomainLog {
  created: Date;
  domain: string;
  id: number;
  message: string;
  period: Period;
  periodId?: number;
  serverTime: Date;
  source: string;
  stackTrace: string;
  type: DomainLogType;
}
export interface GraphData {
  averageCloseChange: number;
  averageCloseTicks: number;
  dataSets: GraphDataSet[];
  errorMessage: string;
  isIntraday: boolean;
  jobs: number[];
  labels: Date[];
  title: string;
  type: string;
}
export interface GraphDataSet {
  borderSize: number;
  colorCode: string;
  name: string;
  values: number[];
}
export interface GraphItem {
  bollingerBandLower?: number;
  bollingerBandUpper?: number;
  bollingerBandWidthLower?: number;
  bollingerBandWidthUpper?: number;
  closeEma10?: number;
  closeEma21?: number;
  closePrice: number;
  closeSma100?: number;
  closeSma20?: number;
  closeSma3?: number;
  closeSma5?: number;
  closeSma50?: number;
  closeSma8?: number;
  closeTime: Date;
  dynamicMovingAverage?: number;
  dynamicMovingAverage2?: number;
  dynamicMovingAverage3?: number;
  highPrice: number;
  keltnerChannelLower?: number;
  keltnerChannelUpper?: number;
  lowPrice: number;
  openPrice: number;
  openTime: Date;
  trueRange?: number;
  volume: number;
}
export interface GraphQuote {
  adx?: number;
  aroonDown?: number;
  aroonUp?: number;
  atr?: number;
  atrMa?: number;
  barSize: BarSize;
  bbHigh?: number;
  bbLow?: number;
  closePrice: number;
  closeTime: Date;
  conClosePrice: number;
  conOpenPrice: number;
  dcHigh?: number;
  dcLimit?: number;
  dcLongEntry: boolean;
  dcLow?: number;
  dcMid?: number;
  dcResistanceLength: number;
  dcShortEntry: boolean;
  dcStop?: number;
  dcSupportLength: number;
  exitPrice?: number;
  haClosePrice: number;
  haHighPrice: number;
  haLowPrice: number;
  haOpenPrice: number;
  hasResistance: boolean;
  hasSupport: boolean;
  highPrice: number;
  kcHigh?: number;
  kcLow?: number;
  longEntryPrice?: number;
  lowPrice: number;
  minusDx?: number;
  openPrice: number;
  openTime: Date;
  peakHigh: number;
  peakHighNoise: boolean;
  peakLow: number;
  peakLowNoise: boolean;
  plusDx?: number;
  rsi?: number;
  rsiMa?: number;
  shortEntryPrice?: number;
  sma?: number;
  sma2nd?: number;
  sma3rd?: number;
  trueRange?: number;
  volume: number;
  volumeAvg?: number;
  wapPrice: number;
  williamsFractalBear?: number;
  williamsFractalBull?: number;
  wpr?: number;
}
export interface GraphRequest {
  barsAddedBackward: number;
  barsAddedForward: number;
  barSize: BarSize;
  contract: ContractEntity;
  contractPartialId: number;
  lastBarTime: Date;
  numberOfBars: number;
  settings: GraphSettings;
  strategyId: number;
}
export interface GraphSettings {
  adxBasisPeriods: number;
  adxPeriods: number;
  aroonPeriods: number;
  atrMaPeriods: number;
  atrPeriods: number;
  bbPeriods: number;
  bbStandardDeviation: number;
  dcLimitMultiplier: number;
  dcPeriods: number;
  dcResistanceSupportPeriods: number;
  dcUseClosePrice: boolean;
  kcAtrMultiplier: number;
  kcPeriods: number;
  peakPeriods: number;
  resistanceSupportRange: number;
  rsiMaPeriods: number;
  rsiPeriods: number;
  showAdx: boolean;
  showAroon: boolean;
  showAtr: boolean;
  showAtrMa: boolean;
  showBollingerBands: boolean;
  showConnectedBars: boolean;
  showDcEntry: boolean;
  showDonchianChannels: boolean;
  showHeikinAshi: boolean;
  showKeltnerChannels: boolean;
  showPeaks: boolean;
  showResistanceSupport: boolean;
  showRsi: boolean;
  showRsiMa: boolean;
  showSma: boolean;
  showSma2nd: boolean;
  showSma3rd: boolean;
  showTransactions: boolean;
  showTrueRange: boolean;
  showVolumeAvg: boolean;
  showWilliamsFractal: boolean;
  showWpr: boolean;
  sma2ndPeriods: number;
  sma3rdPeriods: number;
  smaPeriods: number;
  volumeAvgPeriods: number;
  williamsFractal: number;
  wprPeriods: number;
}
export interface Indicator {
  alias?: string;
  action: OrderAction;
  atrMode: ValidationMode;
  atrPeriods: number;
  barSize: BarSize;
  basisPeriods: number;
  basisType: ValidationType;
  bbPeriods: number;
  bbValue: number;
  bottomWickMaxPct: number;
  bottomWickMinPct: number;
  bounceProtectionPeriods: number;
  compareType: CompareType;
  direction: OrderDirection;
  enabled: boolean;
  id: number;
  increasedShiftPct: number;
  kcPeriods: number;
  kcValue: number;
  lowerThreshold: number;
  modifiedShiftBarsPct: number;
  numShiftBars: number;
  periods: number;
  secondaryPeriods: number;
  shiftBarsBodyPct: number;
  shiftBarsPct: number;
  shiftBounceHighPrice: number;
  shiftBounceLowPrice: number;
  squeezeMode: SqueezeMode;
  strategyId: number;
  topWickMaxPct: number;
  topWickMinPct: number;
  type: IndicatorType;
  upperThreshold: number;
  useBottomWickThresholds: boolean;
  useBounceProtection: boolean;
  useCrossOver: boolean;
  useIncreaseShiftOnIntradayLoss: boolean;
  useTopWickThresholds: boolean;
  entryHour: number;
  entryMinute: number;
  delayedPeriods: number;
  category: IndicatorCategory;
  mode: IndicatorMode;
  numberValue: number;
  decimalValue: number;
  trueOrFalseValue: boolean;
  textValue?: string;
}
export interface IndicatorResult {
  action: OrderAction;
  barSize: BarSize;
  direction: OrderDirection;
  error: boolean;
  id: number;
  indicator: Indicator;
  indicatorId?: number;
  message: string;
  period: Period;
  periodId?: number;
  shiftBarsBodyPct: number;
  shiftBarsPct: number;
  squeeze: SqueezeResult;
  type: IndicatorType;
  valid: boolean;
  value?: number;
  mode: IndicatorMode;
}
export interface IndicatorSet {
  adxBasisPeriods: number;
  adxCrossOverMode: AdxCrossOverMode;
  adxLower: number;
  adxPeriods: number;
  adxUpper: number;
  aroon2HighThreshold: number;
  aroon2LowThreshold: number;
  aroonHighThreshold: number;
  aroonLowThreshold: number;
  aroonPeriods: number;
  aroonVolume2HighThreshold: number;
  aroonVolume2LowThreshold: number;
  aroonVolumeHighThreshold: number;
  aroonVolumeLowThreshold: number;
  aroonVolumePeriods: number;
  atrPeriods: number;
  averageDirectionalIndexMode: AverageDirectionalIndexMode;
  barPeakMode: BarPeakMode;
  barPeakPeriods: number;
  barPeaks: number;
  barSize: BarSize;
  bbLower: number;
  bbPeriods: number;
  bbShortLower: number;
  bbShortUpper: number;
  bbUpper: number;
  betaCompareBasis: ValidationType;
  betaCompareFirstPeriods: number;
  betaCompareSecondPeriods: number;
  betaCompareType: CompareType;
  bollingerBandMode: BollingerBandMode;
  breakoutPeriods: number;
  cciLower: number;
  cciMode: CciMode;
  cciPeriods: number;
  cciShortLower: number;
  cciShortUpper: number;
  cciUpper: number;
  compareBasis: ValidationType;
  compareFirstPeriods: number;
  compareSecondPeriods: number;
  compareType: CompareType;
  crossOverMode: CrossOverMode;
  crossOverSmaPeriods: number;
  crossOverSmaSecondaryPeriods: number;
  dcMinResistanceSupportPeriods: number;
  dcPeriods: number;
  dcUseClosePrice: boolean;
  deltaCompareBasis: ValidationType;
  deltaCompareFirstPeriods: number;
  deltaCompareSecondPeriods: number;
  deltaCompareType: CompareType;
  direction: IndicatorDirection;
  enabled: boolean;
  eodCloseMode: EodCloseMode;
  eodLower: number;
  eodShortLower: number;
  eodShortUpper: number;
  eodUpper: number;
  id: number;
  kcLower: number;
  kcMode: KcMode;
  kcPeriods: number;
  kcShortLower: number;
  kcShortUpper: number;
  kcUpper: number;
  macdMode: MacdMode;
  maxTickChangeThreshold: number;
  mfiLower: number;
  mfiMode: MfiMode;
  mfiPeriods: number;
  mfiShortLower: number;
  mfiShortUpper: number;
  mfiUpper: number;
  minTickChangeThreshold: number;
  momentumBasis: ValidationType;
  momentumLongHigh: number;
  momentumLongLow: number;
  momentumPeriods: number;
  momentumShortHigh: number;
  momentumShortLow: number;
  movingAverageCrossOver: MovingAverageCrossOver;
  movingAverageDirectionMode: MovingAverageDirectionMode;
  movingAverageDirectionPeriods: number;
  normalizeBarPeaks: boolean;
  pricePeakFirstPeriods: number;
  pricePeakMaxPullbackPct: number;
  pricePeakMinPullbackPct: number;
  pricePeakMode: PeakMode;
  pricePeakPct: number;
  pricePeakPeriodsDelayed: number;
  pricePeakSecondPeriods: number;
  pullback: Pullback;
  purpose: string;
  rsiCrossOverLong: number;
  rsiCrossOverMode: RsiCrossOverMode;
  rsiCrossOverPeriods: number;
  rsiCrossOverShort: number;
  rsiLower: number;
  rsiMode: RsiMode;
  rsiPeriods: number;
  rsiShortLower: number;
  rsiShortUpper: number;
  rsiUpper: number;
  rviMode: RviMode;
  secondMovingAverageCrossOver: MovingAverageCrossOver;
  secondMovingAverageFirstPeriods: number;
  secondMovingAverageMode: SingleDynamicAverageMode;
  secondMovingAverageSecondsPeriods: number;
  singleDynamicAverage2Periods: number;
  singleDynamicAverageMode: SingleDynamicAverageMode;
  singleDynamicAveragePeriods: number;
  squeezeBbPeriods: number;
  squeezeBbValue: number;
  squeezeKcPeriods: number;
  squeezeKcValue: number;
  squeezeMode: SqueezeMode;
  stochasticHighThreshold: number;
  stochasticLowThreshold: number;
  stochasticMode: StochasticMode;
  stochasticPeriods: number;
  stochasticShortHighThreshold: number;
  stochasticShortLowThreshold: number;
  strategy: Strategy;
  strategyId: number;
  streakLength: number;
  streakLength2: number;
  streakMode: StreakMode;
  streakMode2: StreakMode;
  streakType: StreakType;
  streakType2: StreakType;
  strengthBasis: ValidationType;
  strengthCrossOverLongThreshold: number;
  strengthCrossOverMode: StrengthCrossOverMode;
  strengthCrossOverPeriods: number;
  strengthCrossOverShortThreshold: number;
  strengthHistoryLongInvalid: number;
  strengthHistoryLongValid: number;
  strengthHistoryMode: StrengthHistoryMode;
  strengthHistoryPeriods: number;
  strengthHistoryShortInvalid: number;
  strengthHistoryShortValid: number;
  strengthLongHigh: number;
  strengthLongLow: number;
  strengthPeriods: number;
  strengthShortHigh: number;
  strengthShortLow: number;
  support: Support;
  theDipAtrMinTicks: number;
  theDipAtrPeriods: number;
  theDipMaxTopWickPct: number;
  theDipMinBarAtrPct: number;
  theDipMinBarBodyAtrPct: number;
  theDipMinBodyOfBarPct: number;
  theDipMinBottomWickPct: number;
  theDipNumBars: number;
  theDipUseWickPct: boolean;
  thePopAtrMinTicks: number;
  thePopAtrPeriods: number;
  thePopMaxBottomWickPct: number;
  thePopMinBarAtrPct: number;
  thePopMinBarBodyAtrPct: number;
  thePopMinBodyOfBarPct: number;
  thePopMinTopWickPct: number;
  thePopNumBars: number;
  thePopUseWickPct: boolean;
  tickChangeMode: TickChangeMode;
  type: IndicatorSetType;
  useAroon: boolean;
  useAroonCrossOver: boolean;
  useAroonStage1: boolean;
  useAroonStage2: boolean;
  useAroonVolume: boolean;
  useAroonVolumeCrossOver: boolean;
  useAroonVolumeStage1: boolean;
  useAroonVolumeStage2: boolean;
  useBetaCompare: boolean;
  useBetaCompareCrossOver: boolean;
  useBreakOnValid: boolean;
  useBreakout: boolean;
  useCompare: boolean;
  useCompareCrossOver: boolean;
  useDeltaCompare: boolean;
  useDeltaCompareCrossOver: boolean;
  useDonchianChannels: boolean;
  useExperimental: boolean;
  useMomentum: boolean;
  usePricePeakCounterTrend: boolean;
  usePricePeakPullbackRange: boolean;
  useStochasticCrossOver: boolean;
  useStochasticCrossOverSwing: boolean;
  useStrength: boolean;
  useTheDip: boolean;
  useTheDipTrend: boolean;
  useThePop: boolean;
  useThePopTrend: boolean;
  useVolumeThreshold: boolean;
  useVwma: boolean;
  useVwmaCrossOver: boolean;
  useVwmaCrossOverSwing: boolean;
  useWilliamsFractal: boolean;
  useWilliamsFractalCounterTrend: boolean;
  vol1stPeriods: number;
  vol2ndPeriods: number;
  volumeMode: VolumeMode;
  volumePeakFirstPeriods: number;
  volumePeakMode: PeakMode;
  volumePeakPct: number;
  volumePeakPeriodsDelayed: number;
  volumePeakSecondPeriods: number;
  volumeThreshold: number;
  vwmaPeriods: number;
  williamsFractal: number;
  wprLower: number;
  wprMode: WprMode;
  wprPeriods: number;
  wprShortLower: number;
  wprShortUpper: number;
  wprUpper: number;
}
export interface Message {
  archived: boolean;
  brokerTime: Date;
  content: string;
  contract: ContractEntity;
  contractId?: number;
  id: number;
  localTime: Date;
  type: MessageType;
}
export interface OpenOrderViewModel {
  account: string;
  contract: ContractEntity;
  errorMessage: string;
  hasError: boolean;
  orderAction: OrderAction;
  orderCreated: Date;
  orderDirection: OrderDirection;
  orderExpires?: Date;
  orderId: number;
  orderKeepAlive: KeepAlive;
  orderPrice: number;
  orderSize: number;
  orderStateExists: boolean;
  orderStatePrice: number;
  orderStateSize: number;
  orderStateType: OrderType;
  orderType: OrderType;
  position: Position;
}
export interface Order {
  action: OrderAction;
  barSize: BarSize;
  brokerOrderRef: number;
  canceled: boolean;
  cancelReason: string;
  contract: ContractEntity;
  contractId: number;
  created: Date;
  direction: OrderDirection;
  errorMessage: string;
  expires?: Date;
  hasError: boolean;
  id: number;
  initialMargin: string;
  isCompanionOrder: boolean;
  keepAlive: KeepAlive;
  limitPrice: number;
  maintenanceMargin: string;
  position: Position;
  positionId?: number;
  requestId: number;
  size: number;
  stopLossPrice: number;
  targetPrice: number;
  type: OrderType;
}
export interface Period {
  account: string;
  barSizeInMinutes: number;
  contract: ContractEntity;
  contractId?: number;
  created: Date;
  error: boolean;
  errorMessage: string;
  forecast: Forecast;
  forecastDirection: OrderDirection;
  forecastReason: string;
  id: number;
  marketTradingHours: MarketTradingHours;
  movingStopLossCount: number;
  periodBarSize: BarSize;
  position: Position;
  positionElapsedPeriods: number;
  positionId?: number;
  positionLimit: number;
  positionSize: number;
  positionStopLoss: number;
  riskMessage: string;
  riskValid: boolean;
  security: Security;
  securityId?: number;
  strategy: Strategy;
  strategyId?: number;
  subscriptionId: number;
  suspendNewEntries: boolean;
  suspendProductType: boolean;
  timeMessage: string;
  timeValid: boolean;
  type: PeriodType;
  unrealizedPnl: number;
  useLiveAccount: boolean;
  price: number;
  volume: number;
}
export interface PeriodRequest {
  fromDate: Date;
  toDate: Date;
}
export interface Position {
  account: string;
  backtest: Backtest;
  backtestId?: number;
  contract: ContractEntity;
  contractId?: number;
  created: Date;
  entryJobId?: number;
  entryPeriodId: number;
  exited?: Date;
  exitPending: boolean;
  exitReason: string;
  forecast: Forecast;
  forecastEntryPrice: number;
  forecastLimit: number;
  forecastLimitTicks: number;
  forecastStopLoss: number;
  forecastStopLossTicks: number;
  id: number;
  isOverlapping: boolean;
  limitReached: boolean;
  lossDistancePct: number;
  manuallyUpdated: boolean;
  margin: number;
  movingStopLoss: number;
  movingStopLossTicks: number;
  orderSize: number;
  periodAvgTicks: number;
  profitDistancePct: number;
  security: Security;
  securityId?: number;
  size: number;
  stopLossMoves: number;
  stopLossReached: boolean;
  strategy: Strategy;
  strategyId?: number;
  sumCommission: number;
  sumGrossPnl: number;
  sumNetPnl: number;
  sumPnl: number;
  sumRealizedPnl: number;
  sumSlippageAmount: number;
  sumSlippageTicks: number;
  sumUnrealizedPnl: number;
  trendDirection: TrendDirection;
  type: PeriodType;
  updated?: Date;
  avgProfitCount: number;
  avgLossCount: number;
}
export interface PositionRequest {
  contractId: number;
  fromDate: Date;
  historyPeriodType: HistoryPeriodType;
  toDate: Date;
}
export interface PositionState {
  account: string;
  contractRefId: number;
  localSymbol: string;
  size: number;
}
export interface QueryPeriod {
  duration: number;
  endDate: Date;
  startDate: Date;
}
export interface QueryRequest {
  barSize: BarSize;
  contract: ContractEntity;
  duration: number;
  durationUnit: string;
  endTime?: Date;
  intraDayDuration: boolean;
  security: Security;
  type: QueryType;
  useEndTime: boolean;
  useRth: boolean;
}
export interface QueryResponse {
  errorMessage: string;
  graphData: GraphData;
  request: QueryRequest;
  value?: number;
  value2?: number;
}
export interface Quote {
  askPrice: number;
  askSize: number;
  backtestSequence: number;
  barSize: BarSize;
  bidPrice: number;
  bidSize: number;
  closePrice: number;
  closeTime: Date;
  contractPartialId?: number;
  currentDayVolume: number;
  highPrice: number;
  id: number;
  lastTradePrice: number;
  lastTradeSize: number;
  localSymbol: string;
  lowPrice: number;
  midPrice: number;
  openPrice: number;
  openTime: Date;
  partial: ContractPartial;
  volume: number;
  wapPrice: number;
  warning: string;
}
export interface ReportViewModel {
  accuracy: number;
  avgBidSize: number;
  avgDayVolume: number;
  buyAccuracy: number;
  sellAccuracy: number;
  stayAccuracy: number;
  stayCoupledAccuracy: number;
  stayDetachedAccuracy: number;
}
export interface Schedule {
  active: boolean;
  id: number;
  name: string;
  needsRestart: boolean;
  repeat: boolean;
  repeatAt: TimeSpan;
  repeatAtTicks: number;
  startAt: TimeSpan;
  startAtTicks: number;
  type: ScheduleType;
}
export interface Security {
  aprMarket: MarketType;
  archived: boolean;
  augMarket: MarketType;
  currency: string;
  decMarket: MarketType;
  deliveryApr: DeliveryMonthType;
  deliveryAug: DeliveryMonthType;
  deliveryDec: DeliveryMonthType;
  deliveryFeb: DeliveryMonthType;
  deliveryJan: DeliveryMonthType;
  deliveryJul: DeliveryMonthType;
  deliveryJun: DeliveryMonthType;
  deliveryMar: DeliveryMonthType;
  deliveryMay: DeliveryMonthType;
  deliveryNotes: string;
  deliveryNov: DeliveryMonthType;
  deliveryOct: DeliveryMonthType;
  deliverySep: DeliveryMonthType;
  exchange: string;
  febMarket: MarketType;
  id: number;
  janMarket: MarketType;
  julMarket: MarketType;
  junMarket: MarketType;
  marketAfterHoursClosed: TimeSpan;
  marketAfterHoursClosedTicks: number;
  marketAfterHoursOpen: TimeSpan;
  marketAfterHoursOpenTicks: number;
  marketClosed: TimeSpan;
  marketClosedTicks: number;
  marketOpen: TimeSpan;
  marketOpenTicks: number;
  marMarket: MarketType;
  mayMarket: MarketType;
  multiplier: number;
  name: string;
  notes: string;
  novMarket: MarketType;
  octMarket: MarketType;
  productType: ProductType;
  quotePriceMultiplier: number;
  seasonalNotes: string;
  sepMarket: MarketType;
  symbol: string;
  tickIncrement: number;
  firstNoticeType: FirstNoticeType;
  useFuturesMonthCodeSymbol: boolean;
  rollOverDays: number;
}
export interface Strategy {
  accountId?: number;
  anyProfitPeriods: number;
  archived: boolean;
  cutLossAmount: number;
  cutLossPeriods: number;
  doubleUpMode: DoubleUpMode;
  group?: StrategyGroup;
  groupId?: number;
  id: number;
  indicatorExitAmount: number;
  intradayLossThreshold: number;
  intradayProfitThreshold: number;
  monthlyLossThreshold: number;
  monthlyProfitThreshold: number;
  profitAmount: number;
  profitType: RiskType;
  movingStopLoss: MovingStopLoss;
  name: string;
  noLossLimitPct: number;
  notes: string;
  pauseOnProfitPeriods: number;
  pauseOnStopLossPeriods: number;
  periodBarSize: BarSize;
  avgTrueRangePeriods: number;
  smallProfitAmount: number;
  smallProfitPeriods: number;
  lossAmount: number;
  lossType: RiskType;
  suspendAfterWeekendOpen: boolean;
  suspendAfterWeekendOpenPeriods: number;
  suspendPriorToEiaReport: boolean;
  suspendPriorToWeekend: boolean;
  suspendPriorToWeekendPeriods: number;
  timeoutPeriods: number;
  trailingStopLossPct: number;
  useAnyProfit: boolean;
  useCutLoss: boolean;
  useIndicatorExitThreshold: boolean;
  useMaxIntradayLoss: boolean;
  useMaxIntradayProfit: boolean;
  useMaxMonthlyLoss: boolean;
  useMaxMonthlyProfit: boolean;
  usePartialMonth: boolean;
  usePauseOnProfit: boolean;
  usePauseOnStopLoss: boolean;
  useSmallProfit: boolean;
  useTimeOut: boolean;
  useWalkAway: boolean;
  walkAwayAmount: number;
  walkAwayPeriods: number;
  useCutProfit: boolean;
  cutProfitPeriods: number;
  cutProfitAmount: number;
  useCutProfit2: boolean;
  cutProfitPeriods2: number;
  cutProfitAmount2: number;
  successWinPct: number;
  dangerWinPct: number;
  doubleUpOnLoss: boolean;
  doubleUpOnLossType: DoubleUpType;
  doubleUpOnLossMaxSize: number;
  doubleUpOnLossMaxAmount: number;
  isOptionTrading: boolean;
  isOptionCover: boolean;
  optionDeltaPct: number;
  optionDeprecationPct: number;
  premiumAmount: number;
}

export interface StrategyGroup {
  accountId?: number;
  archived: boolean;
  id: number;
  name: string;
}
export interface Subscription {
  active: boolean;
  alias: string;
  checkConsistency: boolean;
  commission: number;
  contract: ContractEntity;
  contractId?: number;
  id: number;
  initialMargin: number;
  maintenanceMargin: number;
  orderSize: number;
  priority: number;
  security: Security;
  securityId?: number;
  simulated: boolean;
  strategy: Strategy;
  strategyId?: number;
  suspendEntries: boolean;
}
export interface Summary {
  account: string;
  archiveId?: number;
  barSize: BarSize;
  bidPrice: number;
  bidTickChange: number;
  closeChangePct?: number;
  commission: number;
  connected: boolean;
  contract: ContractEntity;
  contractId: number;
  contranctId: number;
  count: number;
  date: Date;
  direction: TrendDirection;
  duration: number;
  elapsedPeriods: number;
  entryJobId: number;
  entryPrice: number;
  evenNum: number;
  gain: number;
  gainNum: number;
  hours: number;
  hoursClosed: number;
  limitPrice: number;
  limitProfit: number;
  limitTickChange: number;
  localSymbol: string;
  loss: number;
  lossDistancePct: number;
  lossNum: number;
  minutes: number;
  netProfit: number;
  order: Order;
  position: number;
  profit: number;
  profitDistancePct: number;
  profitLossCountPct: number;
  profitPct: number;
  realizedProfitAndLoss: number;
  retrieved: Date;
  security: Security;
  securityId: number;
  spreadCost: number;
  stopLoss: number;
  stopLossPrice: number;
  stopTickChange: number;
  strategy: Strategy;
  strategyAlias: string;
  strategyId: number;
  takeAnyProfitPeriods: number;
  type: PeriodType;
  useTakeAnyProfit: boolean;
}
export interface Trade {
  commission: number;
  commissionCurrency: string;
  execId: string;
  filled: Date;
  id: number;
  order: Order;
  orderId: number;
  position: Position;
  positionId?: number;
  price: number;
  realizedPnl: number;
  size: number;
  slippage: number;
  slippageAmount: number;
  slippageTicks: number;
}
export interface TwsPosition {
  localSymbol: string;
  position: number;
  securityName: string;
}
export interface VersionModel {
  webApiVersion: string;
  webApiAuthorization: boolean;
  webApiDatabase: DatabaseType;
}

/**
 * C# generated TimeSpan class
 */
export interface TimeSpan {
  days: number;
  hours: number;
  milliseconds: number;
  minutes: number;
  seconds: number;
  ticks: number;
  totalDays: number;
  totalHours: number;
  totalMilliseconds: number;
  totalMinutes: number;
  totalSeconds: number;
}
