import { DeliveryMonthType, Security } from '../../../types';

export interface DeliveryMonth {
  month: Month;
  type: DeliveryMonthType;
  name: string;
  code: string;
}

export enum Month {
  January = 0,
  February = 1,
  March = 2,
  April = 3,
  May = 4,
  June = 5,
  July = 6,
  August = 7,
  September = 8,
  October = 9,
  November = 10,
  December = 11
}

const DeliveryMonthCodes = {
  January: 'F',
  February: 'G',
  March: 'H',
  April: 'J',
  May: 'K',
  June: 'M',
  July: 'N',
  August: 'Q',
  September: 'U',
  October: 'V',
  November: 'X',
  December: 'Z'
};

const getDeliveryMonthCode = (month: Month): string => {
  switch (month) {
    case Month.January:
      return DeliveryMonthCodes.January;
    case Month.February:
      return DeliveryMonthCodes.February;
    case Month.March:
      return DeliveryMonthCodes.March;
    case Month.April:
      return DeliveryMonthCodes.April;
    case Month.May:
      return DeliveryMonthCodes.May;
    case Month.June:
      return DeliveryMonthCodes.June;
    case Month.July:
      return DeliveryMonthCodes.July;
    case Month.August:
      return DeliveryMonthCodes.August;
    case Month.September:
      return DeliveryMonthCodes.September;
    case Month.October:
      return DeliveryMonthCodes.October;
    case Month.November:
      return DeliveryMonthCodes.November;
    case Month.December:
      return DeliveryMonthCodes.December;
    default:
      return '';
  }
};

export const getDeliveryMonthName = (code: string) => {
  switch (code) {
    case DeliveryMonthCodes.January:
      return 'January';
    case DeliveryMonthCodes.February:
      return 'February';
    case DeliveryMonthCodes.March:
      return 'March';
    case DeliveryMonthCodes.April:
      return 'April';
    case DeliveryMonthCodes.May:
      return 'May';
    case DeliveryMonthCodes.June:
      return 'June';
    case DeliveryMonthCodes.July:
      return 'July';
    case DeliveryMonthCodes.August:
      return 'August';
    case DeliveryMonthCodes.September:
      return 'September';
    case DeliveryMonthCodes.October:
      return 'October';
    case DeliveryMonthCodes.November:
      return 'November';
    case DeliveryMonthCodes.December:
      return 'Decemeber';
    default:
      return '';
  }
};

export const getDeliveryMonthType = (deliveryMonth: string, security: Security) => {
  const month = deliveryMonth.substring(4);
  switch (month) {
    case '01':
      return DeliveryMonthType[security.deliveryJan];
    case '02':
      return DeliveryMonthType[security.deliveryFeb];
    case '03':
      return DeliveryMonthType[security.deliveryMar];
    case '04':
      return DeliveryMonthType[security.deliveryApr];
    case '05':
      return DeliveryMonthType[security.deliveryMay];
    case '06':
      return DeliveryMonthType[security.deliveryJun];
    case '07':
      return DeliveryMonthType[security.deliveryJul];
    case '08':
      return DeliveryMonthType[security.deliveryAug];
    case '09':
      return DeliveryMonthType[security.deliverySep];
    case '10':
      return DeliveryMonthType[security.deliveryOct];
    case '11':
      return DeliveryMonthType[security.deliveryNov];
    case '12':
      return DeliveryMonthType[security.deliveryDec];
    default:
      return '';
  }
};

export const getDeliveryMonthTypeByCode = (code: string, security: Security) => {
  switch (code) {
    case DeliveryMonthCodes.January:
      return DeliveryMonthType[security.deliveryJan];
    case DeliveryMonthCodes.February:
      return DeliveryMonthType[security.deliveryFeb];
    case DeliveryMonthCodes.March:
      return DeliveryMonthType[security.deliveryMar];
    case DeliveryMonthCodes.April:
      return DeliveryMonthType[security.deliveryApr];
    case DeliveryMonthCodes.May:
      return DeliveryMonthType[security.deliveryMay];
    case DeliveryMonthCodes.June:
      return DeliveryMonthType[security.deliveryJun];
    case DeliveryMonthCodes.July:
      return DeliveryMonthType[security.deliveryJul];
    case DeliveryMonthCodes.August:
      return DeliveryMonthType[security.deliveryAug];
    case DeliveryMonthCodes.September:
      return DeliveryMonthType[security.deliverySep];
    case DeliveryMonthCodes.October:
      return DeliveryMonthType[security.deliveryOct];
    case DeliveryMonthCodes.November:
      return DeliveryMonthType[security.deliveryNov];
    case DeliveryMonthCodes.December:
      return DeliveryMonthType[security.deliveryDec];
    default:
      return '';
  }
};

const getDeliveryMonth = (month: Month, type: DeliveryMonthType): DeliveryMonth => {
  return {
    month,
    type,
    name: Month[month],
    code: getDeliveryMonthCode(month)
  };
};

export const getDeliveryMonths = (security: Security): DeliveryMonth[] => {
  return <DeliveryMonth[]>[
    getDeliveryMonth(Month.January, security.deliveryJan),
    getDeliveryMonth(Month.February, security.deliveryFeb),
    getDeliveryMonth(Month.March, security.deliveryMar),
    getDeliveryMonth(Month.April, security.deliveryApr),
    getDeliveryMonth(Month.May, security.deliveryMay),
    getDeliveryMonth(Month.June, security.deliveryJun),
    getDeliveryMonth(Month.July, security.deliveryJul),
    getDeliveryMonth(Month.August, security.deliveryAug),
    getDeliveryMonth(Month.September, security.deliverySep),
    getDeliveryMonth(Month.October, security.deliveryOct),
    getDeliveryMonth(Month.November, security.deliveryNov),
    getDeliveryMonth(Month.December, security.deliveryDec)
  ];
};

export const mapMonthNumberFromCode = (code: string | undefined) => {
  switch (code) {
    case 'F':
      return '01';
    case 'G':
      return '02';
    case 'H':
      return '03';
    case 'J':
      return '04';
    case 'K':
      return '05';
    case 'M':
      return '06';
    case 'N':
      return '07';
    case 'Q':
      return '08';
    case 'U':
      return '09';
    case 'V':
      return '10';
    case 'X':
      return '11';
    case 'Z':
      return '12';
    default:
      return '';
  }
};

export const mapMonthFromCode = (code: string | undefined) => {
  switch (code) {
    case 'F':
      return 'JAN';
    case 'G':
      return 'FEB';
    case 'H':
      return 'MAR';
    case 'J':
      return 'APR';
    case 'K':
      return 'MAY';
    case 'M':
      return 'JUN';
    case 'N':
      return 'JUL';
    case 'Q':
      return 'AUG';
    case 'U':
      return 'SEP';
    case 'V':
      return 'OCT';
    case 'X':
      return 'NOV';
    case 'Z':
      return 'DEC';
    default:
      return 'N/A';
  }
};
