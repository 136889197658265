import React, { FunctionComponent, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { GlobalState } from '../../../core/root';
import { ContractPartial, ContractPartialQuote } from '../../../types';
import * as indicatorSelectors from '../../indicator/store/selectors';
import { loadIndicatorsAsync } from '../../indicator/store/service';
import CategoryFilterPanel from '../../security/components/CategoryFilterPanel';
import SecurityPickerPanel from '../../security/components/SecurityPickerPanel';
import Spinner from '../../shared/components/Spinner';
import StrategyPickerPanel from '../../strategy/components/StrategyPickerPanel';
import { getContractPartials, getContractPartialsLoaded } from '../store/partial-map/selectors';
import { loadContractPartialsAsync } from '../store/partial-map/service';
import * as partialQuoteActionCreators from '../store/partial-quote/action-creators';

import ContractPartialList from './ContractPartialList';

const ContractPartialListScreen: FunctionComponent = () => {
  const state = useSelector((gs: GlobalState) => gs);
  const security = state.securityState.ui.securityPicked;
  const strategy = state.strategyState.ui.strategyPicked;

  const shouldFetchIndicators: boolean = strategy ? indicatorSelectors.shouldFetchIndicators(state, strategy.id) : false;
  const indicators = strategy ? indicatorSelectors.getIndicators(state, strategy.id) || [] : [];

  const contractPartialsLoaded: boolean = security ? getContractPartialsLoaded(state, security.id) : true;
  const contractPartials: ContractPartial[] = security ? getContractPartials(state, security.id) : [];

  const securitiesLoaded: boolean = state.securityState.loaded;
  const partialQuotes: ContractPartialQuote[] = state.partialQuoteState.partialQuotes;
  const partialQuotesLoaded: boolean = state.partialQuoteState.loaded;

  const dispatch = useDispatch();
  const loadContractPartials = (securityId: number) => dispatch(loadContractPartialsAsync(securityId));
  const loadPartialQuotes = () => dispatch(partialQuoteActionCreators.loadPartialQuotesAsync());
  const loadIndicators = (strategyId: number) => dispatch(loadIndicatorsAsync(strategyId));

  useEffect(() => {
    if (!partialQuotesLoaded) {
      loadPartialQuotes();
    }
  }, []);

  useEffect(() => {
    if (security) {
      if (!contractPartialsLoaded) {
        loadContractPartials(security.id);
      }
    }
  }, [security]);

  useEffect(() => {
    if (strategy) {
      if (shouldFetchIndicators) {
        loadIndicators(strategy.id);
      }
    }
  }, [strategy]);

  const loading = !contractPartialsLoaded || !securitiesLoaded || !partialQuotesLoaded || shouldFetchIndicators;

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item active">Partials</li>
      </ol>

      <div className="page-header">
        <h3>
          Contract Partials <small className="no-wrap"></small>
        </h3>
      </div>

      {loading && <Spinner />}

      <SecurityPickerPanel>
        <CategoryFilterPanel />
      </SecurityPickerPanel>

      <StrategyPickerPanel smallTitle="Optional" />

      <ContractPartialList
        security={security}
        strategy={strategy}
        partials={contractPartials}
        partialQuotes={partialQuotes}
        indicators={indicators}
      />
    </div>
  );
};

export default ContractPartialListScreen;
