import * as React from 'react';
import { FunctionComponent } from 'react';

import { Position } from '../../../../types';

import Race from './Race';

interface RaceListProps {
  positions: Position[];
}

const RaceList: FunctionComponent<RaceListProps> = ({ positions }: RaceListProps) => {
  const races = positions.map((position) => {
    return (
      <tr key={position.id}>
        <td>
          <Race position={position} />
        </td>
      </tr>
    );
  });

  return positions.length > 0 ? (
    <div className="gap-bottom-10">
      <table className="table table-condensed table-striped">
        <tbody>{races}</tbody>
      </table>
    </div>
  ) : (
    <span className="small">No positions..</span>
  );
};

export default RaceList;
