import React, { FunctionComponent, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { GlobalState } from '../../../core/root';
import { Security, Subscription } from '../../../types';
import CollapsePanel from '../../shared/components/CollapsePanel';
import { CreateDialog } from '../../shared/components/CreateDialog';
import Spinner from '../../shared/components/Spinner';
import { loadSubscriptionsAsync } from '../../subscription/store/action-creators';
import { addSecurityAsync, loadSecuritiesAsync, setShowArchived } from '../store/action-creators';

import CategoryFilter from './CategoryFilterPanel/CategoryFilter';
import SecurityList from './SecurityList';

const SecurityListScreen: FunctionComponent = () => {
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  const state: GlobalState = useSelector((gs: GlobalState) => gs);
  const securitiesLoaded: boolean = state.securityState.loaded;
  const securities: Security[] = state.securityState.securities;
  const showArchived: boolean = state.securityState.ui.showArchived;

  const subscriptionsLoaded: boolean = state.subscriptionState.loaded;
  const subscriptions: Subscription[] = state.subscriptionState.subscriptions;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!securitiesLoaded) {
      dispatch(loadSecuritiesAsync());
    }
    if (!subscriptionsLoaded) {
      dispatch(loadSubscriptionsAsync());
    }
  }, []);

  const cancelCreateSecurityHandler = () => {
    setShowCreateDialog(false);
  };

  const createSecurity = (name: string) => {
    const security = { name } as Security;
    dispatch(addSecurityAsync(security));
    setShowCreateDialog(false);
  };

  const toggleShowArchived = () => {
    dispatch(setShowArchived(!showArchived));
  };

  const loading = !securitiesLoaded || !subscriptionsLoaded;

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item active">Securities</li>
      </ol>

      <div className="page-header">
        <h3>
          Securities <small className="no-wrap">(aka. Products)</small>
        </h3>
      </div>

      {loading && <Spinner />}

      <CollapsePanel title="Securities">
        <div className="gap-top-5 gap-bottom-20">
          <CategoryFilter />
        </div>
        <SecurityList securities={securities} subscriptions={subscriptions} />
      </CollapsePanel>

      <div className="row">
        <div className="col-xs-6">
          <button
            type="button"
            className={classNames('btn', 'btn-sm', 'btn-default', { active: showArchived })}
            onClick={toggleShowArchived}
          >
            <span>Show Archived</span>
          </button>
        </div>
        <div className="col-xs-6 text-right">
          <button className="btn btn-sm btn-default" type="submit" onClick={() => setShowCreateDialog(true)}>
            Create..
          </button>
        </div>
      </div>

      <CreateDialog
        show={showCreateDialog}
        cancel={cancelCreateSecurityHandler}
        create={createSecurity}
        title="Create Security"
        propName="Name"
      />
    </div>
  );
};

export default SecurityListScreen;
