import { Account } from '../../../types';

import { AccountHttpRequest } from './account-http-request';
import { setAccount, setAccountError, setAccountList, setAccountListError, updateAccount } from './action-creators';

export const loadAccountAsync = () => {
  return (dispatch) => {
    AccountHttpRequest.get()
      .then((response) => {
        // console.log(response);
        dispatch(setAccount(response.data));
      })
      .catch((error) => {
        dispatch(setAccountError(error));
      });
  };
};

export const loadAccountListAsync = () => {
  return (dispatch) => {
    AccountHttpRequest.getAccountList()
      .then((response) => {
        dispatch(setAccountList(response.data));
      })
      .catch((error) => {
        dispatch(setAccountListError(error));
      });
  };
};

export const updateAccountAsync = (user: Account) => {
  return (dispatch) => {
    AccountHttpRequest.update(user)
      .then(() => {
        dispatch(updateAccount(user));
      })
      .catch((error) => {
        dispatch(setAccountError(error));
      });
  };
};
