export interface AuthState {
  loading: boolean;
  loaded: boolean;
  error?: string;
  token?: string;
  firstName?: string;
  userName?: string;
  authRedirectPath: string;
  attempts: number;
  accountId?: number;
}

export const initalAuthState: AuthState = {
  loading: false,
  loaded: false,
  error: undefined,
  token: undefined,
  firstName: undefined,
  userName: undefined,
  authRedirectPath: '/',
  attempts: 0,
  accountId: undefined
};
