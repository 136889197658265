import account from '../modules/account/routes';
import auth from '../modules/auth/routes';
import backtest from '../modules/backtest/backtest-routes';
import backtestSummary from '../modules/backtest/backtest-summary-routes';
import contractPartial from '../modules/contract-partial/routes';
import contract from '../modules/contract/routes';
import graph from '../modules/graph/routes';
import period from '../modules/period/period-routes';
import pnl from '../modules/position/pnl-routes';
import position from '../modules/position/pos-routes';
import security from '../modules/security/routes';
import strategyGroup from '../modules/strategy-group/routes';
import strategy from '../modules/strategy/routes';
import subscription from '../modules/subscription/routes';

export default [
  account,
  security,
  contract,
  contractPartial,
  subscription,
  strategy,
  strategyGroup,
  backtest,
  backtestSummary,
  position,
  pnl,
  period,
  auth,
  graph
];
