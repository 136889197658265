import React, { useState, useEffect } from 'react';

import classNames from 'classnames';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Switch from 'react-switch';

import { GlobalState } from '../../../../core/root';
import { Strategy } from '../../../../types';
import CollapsePanel from '../../../shared/components/CollapsePanel';
import { getFormatedTimeFromPeriods } from '../../../shared/utils/time-utils';
import { getAmountTypeLabel } from '../../utils/risk-type-utils';

export interface Props {
  strategyId: number;
  updateStrategy(strategy: Strategy): void;
}

const TimePeriodTab: React.FunctionComponent<Props> = ({ strategyId, updateStrategy }) => {
  // bugfix: use-selctor top listen to redux-state changes elsewhere;
  // listening to props changes didnt work
  const strategy = useSelector((state: GlobalState) => state.strategyState.strategies.find((x) => x.id === strategyId));

  const [strategyForm, setStrategyForm] = useState<Strategy>(strategy);

  useEffect(() => {
    if (strategyForm != strategy) {
      setStrategyForm(strategy);
    }
  }, [strategy]);

  const mapFromState = (): Strategy => {
    return {
      ...strategy,
      ...strategyForm
    };
  };

  const updateStrategyHandler = () => {
    const item = mapFromState();
    updateStrategy(item);
  };

  const updateStrategyState = (obj: object) => {
    const field = Object.keys(obj)[0];
    const str = {
      ...strategyForm,
      [field]: obj[field]
    };
    if (strategyForm.group) {
      str.group = { ...strategyForm.group };
    }
    setStrategyForm(str);
  };

  const {
    suspendPriorToEiaReport,
    suspendPriorToWeekend,
    suspendPriorToWeekendPeriods,
    suspendAfterWeekendOpen,
    suspendAfterWeekendOpenPeriods,
    periodBarSize,
    useSmallProfit,
    smallProfitAmount,
    smallProfitPeriods,
    useAnyProfit,
    anyProfitPeriods,
    useTimeOut,
    timeoutPeriods,
    useWalkAway,
    walkAwayAmount,
    walkAwayPeriods,
    usePauseOnStopLoss,
    pauseOnStopLossPeriods,
    usePauseOnProfit,
    pauseOnProfitPeriods,
    useCutLoss,
    cutLossAmount,
    cutLossPeriods,
    lossType,
    profitType,
    useIndicatorExitThreshold,
    indicatorExitAmount,
    useCutProfit,
    cutProfitPeriods,
    cutProfitAmount,
    useCutProfit2,
    cutProfitPeriods2,
    cutProfitAmount2
  } = strategyForm;

  const popoverNegWarning = (
    <Popover id="less-then-zero" title="Warning">
      Value expected to be less than zero
    </Popover>
  );

  const suspendPriorToEiaPopover = (
    <Popover id="suspendPriorToEiaPopover">
      Suspends trading on Wednesdays between 00:00 and 11:00 am. The EIA is a report which is released weekly on Wednesday at 10:30 am. The
      report has a big impact on any market, especially the enery-market.
    </Popover>
  );

  const cutLossPopover = (
    <Popover id="cutLossPopover">
      Cut Loss is a threshold which exits the position if losses are too high (below the negative threshold)
    </Popover>
  );

  const cutProfitPopover = (
    <Popover id="cutProfitPopover">
      Cut Profit is a threshold which exits the position if profits are too low (below the positive threshold)
    </Popover>
  );

  const tiExitProfitInfoInfo = (
    <Popover id="tiExitProfitInfoInfo">The PnL required to exit the position when technical indicators signal an exit</Popover>
  );

  const suspendPeriodsChecked =
    suspendPriorToWeekend || suspendAfterWeekendOpen || usePauseOnProfit || usePauseOnStopLoss || suspendPriorToEiaReport;

  const exitPositionOptionsChecked =
    useSmallProfit || useCutProfit || useCutProfit2 || useAnyProfit || useWalkAway || useCutLoss || useTimeOut;

  return (
    <div>
      <div className="row form-horizontal">
        <div className="col-md-6">
          <CollapsePanel title="Block Entries" collapsed={!suspendPeriodsChecked} checked={suspendPeriodsChecked}>
            <div className="form-group form-group-sm">
              <label className="control-label col-sm-6">
                <span>Suspend Before Weekend</span>
              </label>
              <div className="col-sm-6">
                <label className="react-switch react-switch--sm">
                  <Switch
                    onChange={() => updateStrategyState({ suspendPriorToWeekend: !suspendPriorToWeekend })}
                    checked={suspendPriorToWeekend}
                  />
                </label>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="sus_prior_wknd_periods" className="col-sm-6 control-label">
                <span>Periods</span>
              </label>
              <div className="col-sm-6">
                <div className="input-group input-group-sm">
                  <input
                    id="sus_prior_wknd_periods"
                    step="1"
                    type="number"
                    value={suspendPriorToWeekendPeriods}
                    onChange={(event) => updateStrategyState({ suspendPriorToWeekendPeriods: Number(event.target.value) })}
                    className={classNames('form-control', { 'inactive-control': !suspendPriorToWeekend })}
                  />
                  <div className="input-group-addon">{getFormatedTimeFromPeriods(periodBarSize, suspendPriorToWeekendPeriods)}</div>
                </div>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label className="control-label col-sm-6">
                <span>Suspend After Weekend</span>
              </label>
              <div className="col-sm-6">
                <label className="react-switch react-switch--sm">
                  <Switch
                    onChange={() => updateStrategyState({ suspendAfterWeekendOpen: !suspendAfterWeekendOpen })}
                    checked={suspendAfterWeekendOpen}
                  />
                </label>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="sus_after_wknd_periods" className="col-sm-6 control-label">
                <span>Periods</span>
              </label>
              <div className="col-sm-6">
                <div className="input-group input-group-sm">
                  <input
                    id="sus_after_wknd_periods"
                    step="1"
                    type="number"
                    value={suspendAfterWeekendOpenPeriods}
                    onChange={(event) => updateStrategyState({ suspendAfterWeekendOpenPeriods: Number(event.target.value) })}
                    className={classNames('form-control', { 'inactive-control': !suspendAfterWeekendOpen })}
                  />
                  <div className="input-group-addon">{getFormatedTimeFromPeriods(periodBarSize, suspendAfterWeekendOpenPeriods)}</div>
                </div>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label className="col-sm-6 control-label">Suspend On Profit</label>
              <div className="col-sm-6">
                <label className="react-switch react-switch--sm">
                  <Switch
                    onChange={() =>
                      updateStrategyState({
                        usePauseOnProfit: !usePauseOnProfit
                      })
                    }
                    checked={usePauseOnProfit}
                  />
                </label>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="profit_pause_prds" className="col-xs-12 col-sm-6 control-label">
                Periods
              </label>
              <div className="col-sm-6">
                <div className="input-group input-group-sm">
                  <input
                    type="number"
                    step="any"
                    min="0"
                    value={pauseOnProfitPeriods}
                    onChange={(event) =>
                      updateStrategyState({
                        pauseOnProfitPeriods: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !usePauseOnProfit
                    })}
                    id="profit_pause_prds"
                  />
                  <div className="input-group-addon">{getFormatedTimeFromPeriods(periodBarSize, pauseOnProfitPeriods)}</div>
                </div>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label className="col-sm-6 control-label">Suspend On Loss</label>
              <div className="col-sm-6">
                <label className="react-switch react-switch--sm">
                  <Switch
                    onChange={() =>
                      updateStrategyState({
                        usePauseOnStopLoss: !usePauseOnStopLoss
                      })
                    }
                    checked={usePauseOnStopLoss}
                  />
                </label>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="pause_prds" className="col-xs-12 col-sm-6 control-label">
                Periods
              </label>
              <div className="col-sm-6">
                <div className="input-group input-group-sm">
                  <input
                    type="number"
                    step="any"
                    min="0"
                    value={pauseOnStopLossPeriods}
                    onChange={(event) =>
                      updateStrategyState({
                        pauseOnStopLossPeriods: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !usePauseOnStopLoss
                    })}
                    id="pause_prds"
                  />
                  <div className="input-group-addon">{getFormatedTimeFromPeriods(periodBarSize, pauseOnStopLossPeriods)}</div>
                </div>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label className="control-label col-sm-6">
                <span>Suspend prior to EIA</span>
                <OverlayTrigger placement="top" overlay={suspendPriorToEiaPopover}>
                  <span id="period_barsize_popover" className="gap-left-10">
                    <i className="fa fa-question-circle fa-lg info-text" />
                  </span>
                </OverlayTrigger>
              </label>
              <div className="col-sm-6">
                <label className="react-switch react-switch--sm">
                  <Switch
                    onChange={() => updateStrategyState({ suspendPriorToEiaReport: !suspendPriorToEiaReport })}
                    checked={suspendPriorToEiaReport}
                  />
                </label>
              </div>
            </div>
          </CollapsePanel>
        </div>

        <div className="col-md-6">
          <CollapsePanel title="Enable Exits" collapsed={!exitPositionOptionsChecked} checked={exitPositionOptionsChecked}>
            <div className="form-group form-group-sm">
              <label className="col-sm-5 control-label">Use Small Profit</label>
              <div className="col-sm-7">
                <label className="react-switch react-switch--sm">
                  <Switch onChange={() => updateStrategyState({ useSmallProfit: !useSmallProfit })} checked={useSmallProfit} />
                </label>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="small_profit_time" className="col-xs-12 col-sm-5 control-label">
                Periods
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      <i className="fa fa-circle-o-notch" />
                    </span>
                  </div>
                  <input
                    type="number"
                    step="1"
                    min="0"
                    value={smallProfitPeriods}
                    onChange={(event) =>
                      updateStrategyState({
                        smallProfitPeriods: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !useSmallProfit
                    })}
                    id="small_profit_time"
                    placeholder="Periods"
                  />
                  <div className="input-group-addon">{getFormatedTimeFromPeriods(periodBarSize, smallProfitPeriods)}</div>
                </div>
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label htmlFor="small_profit" className="col-xs-12 col-sm-5 control-label">
                Small Profit
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      {getAmountTypeLabel(profitType)}
                    </span>
                  </div>
                  <input
                    type="number"
                    step="any"
                    min="0"
                    value={smallProfitAmount}
                    onChange={(event) =>
                      updateStrategyState({
                        smallProfitAmount: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !useSmallProfit
                    })}
                    id="small_profit"
                    placeholder="Amount"
                  />
                </div>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label className="control-label col-sm-5">
                <span>Use Cut Profit</span>
                <OverlayTrigger placement="top" overlay={cutProfitPopover}>
                  <span className="gap-left-10">
                    <i className="fa fa-question-circle fa-lg info-text" />
                  </span>
                </OverlayTrigger>
              </label>
              <div className="col-sm-7">
                <label className="react-switch react-switch--sm">
                  <Switch onChange={() => updateStrategyState({ useCutProfit: !useCutProfit })} checked={useCutProfit} />
                </label>
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label htmlFor="cut_profit_periods" className="col-xs-12 col-sm-5 control-label">
                Periods
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      <i className="fa fa-circle-o-notch" />
                    </span>
                  </div>
                  <input
                    type="number"
                    step="1"
                    value={cutProfitPeriods}
                    onChange={(event) =>
                      updateStrategyState({
                        cutProfitPeriods: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !useCutProfit
                    })}
                    id="cut_profit_periods"
                    placeholder="Periods"
                  />
                  <div className="input-group-addon">{getFormatedTimeFromPeriods(periodBarSize, cutProfitPeriods)}</div>
                </div>
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label htmlFor="cut_profit_amount" className="col-xs-12 col-sm-5 control-label">
                <span>Cut Profit Amount</span>
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      {getAmountTypeLabel(profitType)}
                    </span>
                  </div>
                  <input
                    type="number"
                    step="any"
                    min="0"
                    value={cutProfitAmount}
                    onChange={(event) =>
                      updateStrategyState({
                        cutProfitAmount: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !useCutProfit
                    })}
                    id="cut_profit_amount"
                    placeholder="Amount"
                  />
                </div>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label className="control-label col-sm-5">
                <span>Use Cut Profit (2)</span>
                <OverlayTrigger placement="top" overlay={cutLossPopover}>
                  <span className="gap-left-10">
                    <i className="fa fa-question-circle fa-lg info-text" />
                  </span>
                </OverlayTrigger>
              </label>
              <div className="col-sm-7">
                <label className="react-switch react-switch--sm">
                  <Switch onChange={() => updateStrategyState({ useCutProfit2: !useCutProfit2 })} checked={useCutProfit2} />
                </label>
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label htmlFor="cut_profit_periods2" className="col-xs-12 col-sm-5 control-label">
                Periods
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      <i className="fa fa-circle-o-notch" />
                    </span>
                  </div>
                  <input
                    type="number"
                    step="1"
                    value={cutProfitPeriods2}
                    onChange={(event) =>
                      updateStrategyState({
                        cutProfitPeriods2: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !useCutProfit2
                    })}
                    id="cut_profit_periods2"
                    placeholder="Periods"
                  />
                  <div className="input-group-addon">{getFormatedTimeFromPeriods(periodBarSize, cutProfitPeriods2)}</div>
                </div>
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label htmlFor="cut_profit_amount2" className="col-xs-12 col-sm-5 control-label">
                <span>Cut Profit Amount</span>
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      {getAmountTypeLabel(profitType)}
                    </span>
                  </div>
                  <input
                    type="number"
                    step="any"
                    min="0"
                    value={cutProfitAmount2}
                    onChange={(event) =>
                      updateStrategyState({
                        cutProfitAmount2: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !useCutProfit2
                    })}
                    id="cut_profit_amount2"
                    placeholder="Amount"
                  />
                </div>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label className="col-sm-5 control-label">Use Any Profit</label>
              <div className="col-sm-7">
                <label className="react-switch react-switch--sm">
                  <Switch onChange={() => updateStrategyState({ useAnyProfit: !useAnyProfit })} checked={useAnyProfit} />
                </label>
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label htmlFor="any_profit_periods" className="col-xs-12 col-sm-5 control-label">
                Periods
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      <i className="fa fa-circle-o-notch" />
                    </span>
                  </div>
                  <input
                    type="number"
                    step="1"
                    min="0"
                    value={anyProfitPeriods}
                    onChange={(event) =>
                      updateStrategyState({
                        anyProfitPeriods: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !useAnyProfit
                    })}
                    id="any_profit_periods"
                    placeholder="Periods"
                  />
                  <div className="input-group-addon">{getFormatedTimeFromPeriods(periodBarSize, anyProfitPeriods)}</div>
                </div>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label className="col-sm-5 control-label">Use Walk Away</label>
              <div className="col-sm-7">
                <label className="react-switch react-switch--sm">
                  <Switch onChange={() => updateStrategyState({ useWalkAway: !useWalkAway })} checked={useWalkAway} />
                </label>
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label htmlFor="walk_away_periods" className="col-xs-12 col-sm-5 control-label">
                Periods
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      <i className="fa fa-circle-o-notch" />
                    </span>
                  </div>
                  <input
                    type="number"
                    step="1"
                    min="0"
                    value={walkAwayPeriods}
                    onChange={(event) =>
                      updateStrategyState({
                        walkAwayPeriods: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !useWalkAway
                    })}
                    id="walk_away_periods"
                    placeholder="Periods"
                  />
                  <div className="input-group-addon">{getFormatedTimeFromPeriods(periodBarSize, walkAwayPeriods)}</div>
                </div>
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label htmlFor="walk_away_amount" className="col-xs-12 col-sm-5 control-label">
                <span>Walk Away Loss</span>
                {walkAwayAmount > 0 && (
                  <OverlayTrigger placement="top" overlay={popoverNegWarning}>
                    <span className="gap-left-10">
                      <i className="fa fa-warning fa-lg warning-text" />
                    </span>
                  </OverlayTrigger>
                )}
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      {getAmountTypeLabel(lossType)}
                    </span>
                  </div>
                  <input
                    type="number"
                    step="any"
                    max="0"
                    value={walkAwayAmount}
                    onChange={(event) =>
                      updateStrategyState({
                        walkAwayAmount: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !useWalkAway
                    })}
                    id="walk_away_amount"
                    placeholder="Amount"
                  />
                </div>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label className="control-label col-sm-5">
                <span>Use Cut Loss</span>
                <OverlayTrigger placement="top" overlay={cutLossPopover}>
                  <span className="gap-left-10">
                    <i className="fa fa-question-circle fa-lg info-text" />
                  </span>
                </OverlayTrigger>
              </label>
              <div className="col-sm-7">
                <label className="react-switch react-switch--sm">
                  <Switch onChange={() => updateStrategyState({ useCutLoss: !useCutLoss })} checked={useCutLoss} />
                </label>
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label htmlFor="cut_off_periods" className="col-xs-12 col-sm-5 control-label">
                Periods
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      <i className="fa fa-circle-o-notch" />
                    </span>
                  </div>
                  <input
                    type="number"
                    step="1"
                    value={cutLossPeriods}
                    onChange={(event) =>
                      updateStrategyState({
                        cutLossPeriods: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !useCutLoss
                    })}
                    id="cut_off_periods"
                    placeholder="Periods"
                  />
                  <div className="input-group-addon">{getFormatedTimeFromPeriods(periodBarSize, cutLossPeriods)}</div>
                </div>
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label htmlFor="cut_off_amount" className="col-xs-12 col-sm-5 control-label">
                <span>Cut Loss Amount</span>
                {cutLossAmount > 0 && (
                  <OverlayTrigger placement="top" overlay={popoverNegWarning}>
                    <span className="gap-left-10">
                      <i className="fa fa-warning fa-lg warning-text" />
                    </span>
                  </OverlayTrigger>
                )}
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      {getAmountTypeLabel(lossType)}
                    </span>
                  </div>
                  <input
                    type="number"
                    step="any"
                    max="0"
                    value={cutLossAmount}
                    onChange={(event) =>
                      updateStrategyState({
                        cutLossAmount: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !useCutLoss
                    })}
                    id="cut_off_amount"
                    placeholder="Amount"
                  />
                </div>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label className="col-sm-5 control-label">Use Time Out</label>
              <div className="col-sm-7">
                <label className="react-switch react-switch--sm">
                  <Switch onChange={() => updateStrategyState({ useTimeOut: !useTimeOut })} checked={useTimeOut} />
                </label>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="timeout_periods" className="col-xs-12 col-sm-5 control-label">
                Periods
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      <i className="fa fa-circle-o-notch" />
                    </span>
                  </div>
                  <input
                    type="number"
                    step="1"
                    min="0"
                    value={timeoutPeriods}
                    onChange={(event) =>
                      updateStrategyState({
                        timeoutPeriods: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !useTimeOut
                    })}
                    id="timeout_periods"
                    placeholder="Periods"
                  />
                  <div className="input-group-addon">{getFormatedTimeFromPeriods(periodBarSize, timeoutPeriods)}</div>
                </div>
              </div>
            </div>
          </CollapsePanel>

          <CollapsePanel title="Block Exits" collapsed={!useIndicatorExitThreshold} checked={useIndicatorExitThreshold}>
            <div className="section">
              <p>Technical Indicator Signals</p>
            </div>

            <div className="form-group form-group-sm">
              <label className="col-sm-5 control-label">Use Profit Threshold</label>
              <div className="col-sm-7">
                <label className="react-switch react-switch--sm">
                  <Switch
                    onChange={() => updateStrategyState({ useIndicatorExitThreshold: !useIndicatorExitThreshold })}
                    checked={useIndicatorExitThreshold}
                  />
                </label>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="ti_exit_amount" className="col-sm-5 control-label">
                <span>Exit Profit</span>
                <OverlayTrigger placement="top" overlay={tiExitProfitInfoInfo}>
                  <span className="gap-left-10">
                    <i className="fa fa-question-circle fa-lg text-info" />
                  </span>
                </OverlayTrigger>
              </label>
              <div className="col-sm-7">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '30px',
                        textAlign: 'center'
                      }}
                    >
                      {getAmountTypeLabel(profitType)}
                    </span>
                  </div>
                  <input
                    type="number"
                    step="any"
                    min="0"
                    value={indicatorExitAmount}
                    onChange={(event) =>
                      updateStrategyState({
                        indicatorExitAmount: Number(event.target.value)
                      })
                    }
                    className={classNames('form-control', {
                      'inactive-control': !useIndicatorExitThreshold
                    })}
                    id="ti_exit_amount"
                    placeholder="Amount"
                  />
                </div>
              </div>
            </div>
          </CollapsePanel>
        </div>
      </div>

      <hr />
      <div className="row">
        <div className="col-xs-12 text-right">
          <button type="button" className="btn btn-primary ripple gap-bottom-5" onClick={updateStrategyHandler}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default TimePeriodTab;
