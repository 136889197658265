import React, { FunctionComponent, useState } from 'react';

import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { GlobalState } from '../../../../core/root';
import { BarSize, Indicator, IndicatorCategory, IndicatorMode, OrderAction, OrderDirection, Strategy } from '../../../../types';
import CollapsePanel from '../../../shared/components/CollapsePanel';
import { setCreateIndicatorPanelCollapsed } from '../../../shared/store/layout/action-creators';
import { IndicatorOption } from '../../models/indicator-option';
import { addIndicatorAsync } from '../../store/service';

import { indicatorOptions } from './indicator-options';

export interface Props {
  title?: string;
  strategy: Strategy;
}

const IndicatorOptionsPanel: FunctionComponent<Props> = ({ title, strategy }) => {
  const state: GlobalState = useSelector((gs: GlobalState) => gs);
  const collapsed = state.layoutState.ui.createIndicatorPanelCollapsed;

  const dispatch = useDispatch();
  const toggleCollapse = () => dispatch(setCreateIndicatorPanelCollapsed(!collapsed));

  const [selectedCategory, setSelectedCategory] = useState<IndicatorCategory | undefined>();
  const isCategoryActive = (cat: IndicatorCategory) => cat === selectedCategory;
  const toggleSelectedCategory = (cat: IndicatorCategory) => {
    if (isCategoryActive(cat)) {
      setSelectedCategory(undefined);
    } else {
      setSelectedCategory(cat);
    }
  };

  const [selectedIndicatorOption, setSelectedIndicatorOption] = useState<IndicatorOption | undefined>();
  const isOptionActive = (io: IndicatorOption) => io === selectedIndicatorOption;
  const toggleSelectedOption = (io: IndicatorOption) => {
    if (isOptionActive(io)) {
      setSelectedIndicatorOption(undefined);
    } else {
      setSelectedIndicatorOption(io);
    }
  };

  const addIndicatorHandler = () => {
    if (!selectedIndicatorOption) {
      return;
    }
    const indicator: Indicator = {
      id: 0,
      direction: OrderDirection.Enter,
      action: OrderAction.Buy,
      barSize: BarSize._1_HR,
      strategyId: strategy.id,
      enabled: false,
      type: selectedIndicatorOption.type,
      category: selectedIndicatorOption.category,
      mode: IndicatorMode.Conditional,
      periods: 0,
      secondaryPeriods: 0,
      atrPeriods: 0,
      basisType: 0,
      compareType: 0,
      lowerThreshold: 0,
      upperThreshold: 0,
      useCrossOver: false,
      atrMode: 0,
      basisPeriods: 0,
      bbPeriods: 0,
      bbValue: 0,
      kcPeriods: 0,
      kcValue: 0,
      numShiftBars: 0,
      shiftBarsPct: 0,
      shiftBarsBodyPct: 0,
      useTopWickThresholds: false,
      topWickMaxPct: 0,
      topWickMinPct: 0,
      useBottomWickThresholds: false,
      bottomWickMaxPct: 0,
      bottomWickMinPct: 0,
      squeezeMode: 0,
      useIncreaseShiftOnIntradayLoss: false,
      increasedShiftPct: 0,
      modifiedShiftBarsPct: 0,
      useBounceProtection: false,
      bounceProtectionPeriods: 0,
      shiftBounceHighPrice: 0,
      shiftBounceLowPrice: 0,
      entryHour: 0,
      entryMinute: 0,
      delayedPeriods: 0,
      numberValue: 0,
      decimalValue: 0,
      trueOrFalseValue: false,
      textValue: undefined
    };
    dispatch(addIndicatorAsync(indicator));
  };

  const getIndicatorOptions = () => {
    const options = indicatorOptions.filter((x) => selectedCategory === undefined || x.category === selectedCategory);
    if (options.length === 0) {
      return <p className="gap-left-5 small">No indicators..</p>;
    }
    return options
      .sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      })
      .map((x) => (
        <button
          key={`${x.category}-${x.type}`}
          type="button"
          title={x.tooltip}
          className={classNames('btn', 'btn-sm', 'btn-default', 'gap-bottom-5', 'ripple', { active: isOptionActive(x) })}
          onClick={() => toggleSelectedOption(x)}
        >
          {x.name}
        </button>
      ));
  };

  const getCategoryButtons = () => {
    return [
      IndicatorCategory.Time,
      IndicatorCategory.Risk,
      IndicatorCategory.Momentum,
      IndicatorCategory.Trend,
      IndicatorCategory.Volatility,
      IndicatorCategory.Volume
    ].map((x) => (
      <button
        key={x}
        type="button"
        className={classNames('btn', 'btn-sm', 'btn-default', 'gap-bottom-5', {
          active: isCategoryActive(x)
        })}
        onClick={() => toggleSelectedCategory(x)}
      >
        {IndicatorCategory[x]}
      </button>
    ));
  };

  return (
    <CollapsePanel title={title || 'Available Indicators'} upper={false} collapsed={collapsed} onToggle={toggleCollapse}>
      <div className="form-horizontal">
        <div className="row">
          <div className="col-xs-12">
            <div className="btn-toolbar">{getCategoryButtons()}</div>
          </div>
          <div className="col-xs-12">
            <div className="section">
              <hr />
              <p>Indicators</p>
            </div>
            <div className="btn-toolbar">{getIndicatorOptions()}</div>
            <div className="gap-top-5">
              {selectedIndicatorOption?.tooltip && (
                <div className="alert alert-info small">
                  <p>
                    <b>{selectedIndicatorOption.name}</b>
                  </p>
                  <span>{selectedIndicatorOption.tooltip}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row gap-top-10">
          <div className="col-xs-12 text-right">
            <button
              className={classNames('btn', 'btn-sm', 'btn-success', { disabled: !selectedIndicatorOption })}
              type="submit"
              onClick={addIndicatorHandler}
            >
              Add Indicator
            </button>
          </div>
        </div>
      </div>
    </CollapsePanel>
  );
};

export default IndicatorOptionsPanel;
