import { Map } from 'immutable';

import { Trade } from '../../../../types';

export interface PositionTradeState {
  loaded: boolean;
  error?: string;
  trades: Trade[];
}

export const initalPositionTradeState: PositionTradeState = {
  loaded: false,
  error: undefined,
  trades: []
};

export type PositionTradeMapState = Map<number, PositionTradeState>;

export const initalPositionTradeMapState: PositionTradeMapState = Map();
