import * as React from 'react';

import classNames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Security, ContractPartial, ContractPartialQuote, Strategy, Indicator } from '../../../types';
import { getBarSizeName } from '../../shared/utils/bar-utils';
import { toShortMonth } from '../../shared/utils/date-format-utils';
import { contractPartialModulePath } from '../routes';
import { formatPartialMonth, sortContractPartialQuotesDecending } from '../utils';

export interface ContractPartialTableProps extends RouteComponentProps {
  security: Security;
  partials: ContractPartial[];
  partialQuotes: ContractPartialQuote[];
  strategy?: Strategy;
  indicators: Indicator[];
}

const ContractPartialTable = ({ security, partials, partialQuotes, strategy, indicators, history }: ContractPartialTableProps) => {
  const selectPartialHandler = (partial: ContractPartial) => {
    const route = `${contractPartialModulePath}/${security.id}/${partial.id}`;
    history.push(route);
  };

  const getPartialQuotes = (contractPartialId: number) => {
    const loadedItems = partialQuotes.filter((x) => x.contractPartialId === contractPartialId);

    let items: ContractPartialQuote[] = [];

    if (strategy && indicators) {
      const barSizes = indicators.filter((x) => x.enabled).map((x) => x.barSize);
      barSizes.push(strategy.periodBarSize);
      const distinctBarSizes = barSizes.filter((x, index, self) => self.indexOf(x) === index);
      distinctBarSizes.forEach((barSize) => {
        const exists = loadedItems.find((y) => y.barSize === barSize);
        items.push({ barSize, contractPartialId: 0, numQuotes: exists ? exists.numQuotes : 0 });
      });
    } else {
      items = [...loadedItems];
    }

    if (items) {
      return items.sort(sortContractPartialQuotesDecending).map((x) => (
        <span
          key={x.barSize}
          className={classNames('label', 'gap-left-5', {
            'label-success': x.numQuotes > 0,
            'label-default': x.numQuotes === 0
          })}
        >
          {getBarSizeName(x.barSize)}
        </span>
      ));
    }
  };

  const header = (
    <tr>
      <th className="middle small" style={{ width: '30px' }}>
        #
      </th>
      <th className="middle small">Contract</th>
      <th className="middle small">Partial</th>
      <th className="middle small">Period Start</th>
      <th className="middle small">Period End</th>

      <th className="middle small text-right">Cust. Tick avg.</th>
      <th className="middle small"></th>
      <th className="middle small text-right">24H Tick avg.</th>

      <th className="middle small text-center">Quotes</th>
    </tr>
  );

  const rows: JSX.Element[] = partials
    .filter((x) => x.contract && security && x.contract.securityId === security.id)
    .sort((a: ContractPartial, b: ContractPartial) => {
      if (a.partialMonth > b.partialMonth) {
        return 1;
      } else if (a.partialMonth < b.partialMonth) {
        return -1;
      }
      return 0;
    })
    .map((partial: ContractPartial, index: number) => {
      return (
        <tr key={partial.id} className="clickable" onClick={() => selectPartialHandler(partial)}>
          <td className="middle small">
            <i>{index + 1}</i>
          </td>
          <td className="middle small">{partial.contract?.localSymbol}</td>
          <td className="middle small">{formatPartialMonth(partial.partialMonth)}</td>
          <td className="middle small">{toShortMonth(partial.periodStart)}</td>
          <td className="middle small">{toShortMonth(partial.periodEnd)}</td>

          <td className="middle small text-right vborder">
            {partial.generalAtrTicks ? <span>{partial.generalAtrTicks}</span> : <span>-</span>}
          </td>
          <td className="middle small">
            <span>{getBarSizeName(partial.generalAtrTicksBarSize)}</span>
          </td>
          <td className="middle small text-right">
            {partial.averageCloseChangeInTicks ? <span>{partial.averageCloseChangeInTicks}</span> : <span>-</span>}
          </td>

          <td className="middle small vborder">{getPartialQuotes(partial.id)}</td>
        </tr>
      );
    });

  if (rows.length === 0) {
    return null;
  }

  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover table-condensed">
        <thead>{header}</thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

export default withRouter(ContractPartialTable);
