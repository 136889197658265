import * as React from 'react';
import { useState } from 'react';

import CollapsePanel from './CollapsePanel';
import { ConfirmDialog } from './ConfirmDialog';

export interface AdministrationPanelProps {
  onDuplicate: () => void;
  onDelete: () => void;
  duplicateTitle: string;
  deleteTitle: string;
  confirmDeleteText?: string;
}

const AdministrationPanel: React.FunctionComponent<AdministrationPanelProps> = ({
  onDuplicate,
  onDelete,
  duplicateTitle,
  deleteTitle,
  confirmDeleteText
}: AdministrationPanelProps) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const deleteHandler = () => {
    onDelete();
    setShowConfirmDialog(false);
  };

  return (
    <React.Fragment>
      <CollapsePanel title="Administration" collapsed={false}>
        <div className="row">
          <div className="col-xs-12">
            <div className="section">
              <p>Duplicate</p>
            </div>
            <table width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td>
                    <span className="small">{duplicateTitle}</span>
                  </td>
                  <td className="middle align-right">
                    <button
                      type="button"
                      className="btn btn-success btn-sm ripple gap-bottom-5"
                      style={{ width: '80px' }}
                      onClick={onDuplicate}
                    >
                      Duplicate
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-12">
            <div className="section">
              <hr />
              <p>Remove</p>
            </div>
            <table width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td>
                    <span className="small">{deleteTitle}</span>
                  </td>
                  <td className="middle align-right">
                    <button
                      type="button"
                      className="btn btn-danger btn-sm ripple"
                      style={{ width: '80px' }}
                      onClick={() => setShowConfirmDialog(true)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </CollapsePanel>

      <ConfirmDialog
        show={showConfirmDialog}
        cancel={() => setShowConfirmDialog(false)}
        confirm={deleteHandler}
        title="Confirm delete"
        text={confirmDeleteText || 'Are you sure you want to delete?'}
      />
    </React.Fragment>
  );
};

export default AdministrationPanel;
