import { Summary } from '../../../types';

export const getTotal = (list: number[]) => {
  if (list.length === 0) {
    return 0;
  }
  const sum = list.reduce((total: number, num: number) => {
    return total + num;
  });
  return sum;
};

export const getAverage = (list: number[]) => {
  if (list.length === 0) {
    return null;
  }
  const sum = getTotal(list);
  return sum / list.length;
};

export const mergeSummaries = (summaries: Summary[]) => {
  const s = {
    type: summaries[0].type,
    account: summaries[0].account
  } as Summary;
  const spreadList = summaries.map((x) => x.spreadCost);
  s.spreadCost = getTotal(spreadList);
  const commList = summaries.map((x) => x.commission);
  s.commission = getTotal(commList);
  const gainNumList = summaries.map((x) => x.gainNum);
  s.gainNum = getTotal(gainNumList);
  const lossNumList = summaries.map((x) => x.lossNum);
  s.lossNum = getTotal(lossNumList);
  const evenNumList = summaries.map((x) => x.evenNum);
  s.evenNum = getTotal(evenNumList);
  const countNumList = summaries.map((x) => x.count);
  s.count = getTotal(countNumList);
  const gainList = summaries.map((x) => x.gain);
  s.gain = getTotal(gainList);
  const lossList = summaries.map((x) => x.loss);
  s.loss = getTotal(lossList);
  const netProfitList = summaries.map((x) => x.netProfit);
  s.netProfit = getTotal(netProfitList);
  const rlzProfitList = summaries.map((x) => x.realizedProfitAndLoss);
  s.realizedProfitAndLoss = getTotal(rlzProfitList);
  return s;
};
