import * as React from 'react';
import { useEffect, useState } from 'react';

import { VictoryLine } from 'victory';

import { BarSize, GraphQuote } from '../../../../types';
import { GraphConfigState } from '../../store/graph-config/state';

import BaseGraph from './BaseGraph';
import { ChartLine, mapToAtr, mapToAtrMa, mapToTrueRange } from './graph-utils';

export interface AverageTrueRangeGraphProps {
  graphConfigState: GraphConfigState;
  barSize: BarSize;
  graphData: GraphQuote[];
  expanded?: boolean;
}

const AverageTrueRangeGraph = ({ graphConfigState, barSize, graphData, expanded }: AverageTrueRangeGraphProps) => {
  const [trueRangeData, setTrueRangeData] = useState<ChartLine[]>([]);
  const [atrData, setAtrData] = useState<ChartLine[]>([]);
  const [atrMaData, setAtrMaData] = useState<ChartLine[]>([]);

  useEffect(() => {
    if (graphData && graphConfigState.showTrueRange) {
      setTrueRangeData(mapToTrueRange(graphData));
    }
  }, [graphData, graphConfigState.showTrueRange]);

  useEffect(() => {
    if (graphData && graphConfigState.showAtr) {
      setAtrData(mapToAtr(graphData));
    }
  }, [graphData, graphConfigState.showAtr]);

  useEffect(() => {
    if (graphData && graphConfigState.showAtrMa) {
      setAtrMaData(mapToAtrMa(graphData));
    }
  }, [graphData, graphConfigState.showAtrMa]);

  const showAtrGraph = graphConfigState.showTrueRange || graphConfigState.showAtr || graphConfigState.showAtrMa;

  if (!showAtrGraph) {
    return null;
  }

  const strokeWidth = expanded ? 0.5 : 1;

  return (
    <BaseGraph barSize={barSize} title="Average True Range" height={125} expanded={expanded}>
      {graphConfigState.showAtrMa && (
        <VictoryLine
          style={{
            data: { stroke: 'gold', strokeWidth }
          }}
          data={atrMaData}
        />
      )}

      {graphConfigState.showAtr && (
        <VictoryLine
          style={{
            data: { stroke: '#0066cc', strokeWidth }
          }}
          data={atrData}
        />
      )}

      {graphConfigState.showTrueRange && (
        <VictoryLine
          style={{
            data: { stroke: '#73d2de', strokeWidth }
          }}
          data={trueRangeData}
        />
      )}
    </BaseGraph>
  );
};

export default AverageTrueRangeGraph;
