import * as React from 'react';

import { Switch, Route } from 'react-router-dom';

import ContractPartialListScreen from './components/ContractPartialListScreen';
import ContractPartialScreen from './components/ContractPartialScreen';

export const contractPartialModulePath = '/contract-partials';

const render = () => {
  return (
    <Switch>
      <Route exact path={contractPartialModulePath} component={ContractPartialListScreen}></Route>
      <Route exact path={contractPartialModulePath + '/:securityId/:id'} component={ContractPartialScreen}></Route>
    </Switch>
  );
};

export default {
  routeProps: {
    path: contractPartialModulePath,
    render
  },
  name: 'ContractPartial'
};
