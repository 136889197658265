import { AnyAction } from 'redux';

import { Order } from '../../../../types';

import * as actionTypes from './action-types';
import { initalPositionOrderMapState, initalPositionOrderState } from './state';

export const positionOrderReducer = (state = initalPositionOrderMapState, action: AnyAction) => {
  const key = action.positionId;
  const current = state.get(key) || { ...initalPositionOrderState };

  if (action.type === actionTypes.SET_POSITION_ORDERS_LOADED) {
    const loaded = action.loaded === true;
    return state.set(key, {
      ...current,
      loaded
    });
  } else if (action.type === actionTypes.SET_POSITION_ORDERS_ERROR) {
    return state.set(key, {
      ...current,
      loaded: true,
      error: action.error
    });
  } else if (action.type === actionTypes.SET_POSITION_ORDERS) {
    const orders = action.orders as Order[];
    return state.set(key, {
      loaded: true,
      error: undefined,
      orders
    });
  } else if (action.type === actionTypes.ADD_POSITION_ORDER) {
    const orders = [...current.orders];
    const order = action.order as Order;
    orders.push(order);
    return state.set(key, {
      ...current,
      loaded: true,
      orders
    });
  }

  return state;
};
