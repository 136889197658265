import { ProductType } from '../../../types';

export const getProductCategoryName = (productType: ProductType) => {
  switch (productType) {
    case ProductType.Currencies:
      return 'Currencies';
    case ProductType.Energies:
      return 'Energies';
    case ProductType.Energies_Heating:
      return 'Heating';
    case ProductType.Financials_Bonds:
      return 'Bonds';
    case ProductType.Financials_Stocks:
      return 'Stocks';
    case ProductType.Grains:
      return 'Grains';
    case ProductType.Meats:
      return 'Meats';
    case ProductType.Metals:
      return 'Metals';
    case ProductType.Others:
      return 'Others';
    case ProductType.Softs:
      return 'Softs';
    default:
      return 'N/A';
  }
};

export interface ProductCategory {
  type: ProductType;
  name: string;
}

export const getSortedProductCategories = (): ProductCategory[] => {
  const categories = Object.keys(ProductType)
    .filter((x) => isNaN(Number(x)))
    .filter((x) => ProductType[x] !== ProductType.Others)
    .map(
      (x) =>
        ({
          type: ProductType[x],
          name: getProductCategoryName(ProductType[x])
        } as ProductCategory)
    )
    .sort((a: ProductCategory, b: ProductCategory) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
  categories.push({
    type: ProductType.Others,
    name: getProductCategoryName(ProductType.Others)
  } as ProductCategory);
  return categories;
};
