import * as React from 'react';
import { useEffect } from 'react';

import { Tabs, Tab } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { GlobalState } from '../../../../core/root';
import { BarSize, ContractPartialQuote } from '../../../../types';
import * as actionCreators from '../../../contract-partial/store/partial-quote/action-creators';
import CollapsePanel from '../../../shared/components/CollapsePanel';
import Spinner from '../../../shared/components/Spinner';
import { BarSizeItem, barSizes } from '../../../shared/utils/bar-utils';

import GraphConfigTab from './GraphConfigTab';

export interface GraphConfigPanelProps {
  title?: string;
  contractPartialId?: number;
}

const GraphConfigPanel = ({ title, contractPartialId }: GraphConfigPanelProps) => {
  const partialQuotes: ContractPartialQuote[] = useSelector((state: GlobalState) => state.partialQuoteState.partialQuotes);
  const partialQuotesLoaded: boolean = useSelector((state: GlobalState) => state.partialQuoteState.loaded);
  const notLoaded = contractPartialId && !partialQuotesLoaded;

  const dispatch = useDispatch();
  useEffect(() => {
    if (notLoaded) {
      dispatch(actionCreators.loadPartialQuotesAsync());
    }
  }, []);

  let configTabs: BarSizeItem[];
  if (contractPartialId) {
    configTabs = partialQuotes
      .filter((x) => x.contractPartialId === contractPartialId)
      .map((x) => {
        return barSizes.find((y) => y.key === x.barSize) as BarSizeItem;
      });
  } else {
    configTabs = barSizes.filter((x) => x.key >= BarSize._5_MIN && x.key <= BarSize._1_DAY);
  }

  const configTabElements = configTabs
    .sort((a: BarSizeItem, b: BarSizeItem) => {
      if (a.key > b.key) return 1;
      if (a.key < b.key) return -1;
      return 0;
    })
    .map((x) => (
      <Tab key={x.name} eventKey={x.key} title={x.name}>
        <GraphConfigTab barSize={x.key} />
      </Tab>
    ));

  const defaultTabIndex = configTabs && configTabs.length > 0 ? configTabs[0].key : 0;

  return (
    <CollapsePanel title={title || 'Config'} upper={true} collapsed={true}>
      {notLoaded ? (
        <Spinner />
      ) : (
        <Tabs id="barsize_tabs" animation={true} defaultActiveKey={defaultTabIndex}>
          {configTabElements}
        </Tabs>
      )}
    </CollapsePanel>
  );
};

export default GraphConfigPanel;
