import * as React from 'react';
import { useEffect, useState } from 'react';

import { VictoryLine } from 'victory';

import { BarSize, GraphQuote } from '../../../../types';

import BaseGraph from './BaseGraph';
import { ChartLine, mapToAroonDown, mapToAroonUp } from './graph-utils';

export interface AroonGraphProps {
  showAroon: boolean;
  barSize: BarSize;
  graphData: GraphQuote[];
  expanded?: boolean;
}

const AroonGraph = ({ showAroon, barSize, graphData, expanded }: AroonGraphProps) => {
  const [aroonUpData, setAroonUpData] = useState<ChartLine[]>([]);
  const [aroonDownData, setAroonDownData] = useState<ChartLine[]>([]);

  useEffect(() => {
    if (graphData && showAroon) {
      setAroonUpData(mapToAroonUp(graphData));
      setAroonDownData(mapToAroonDown(graphData));
    }
  }, [graphData, showAroon]);

  if (!showAroon) {
    return null;
  }

  const strokeWidth = expanded ? 0.5 : 1;

  return (
    <BaseGraph barSize={barSize} title="Aroon" height={150} min={0} max={100} expanded={expanded}>
      <VictoryLine
        style={{
          data: { stroke: '#ff0033', strokeWidth }
        }}
        data={aroonDownData}
      />
      <VictoryLine
        style={{
          data: { stroke: '#00bc8c', strokeWidth }
        }}
        data={aroonUpData}
      />
    </BaseGraph>
  );
};

export default AroonGraph;
