import { Backtest } from '../../../../types';
import axiosApi from '../../../shared/axios-api';

export class BacktestHttpRequest {
  public static get(securityId: number, strategyId: number) {
    const url = `/backtest?securityId=${securityId}&strategyId=${strategyId}`;
    return axiosApi.get<Backtest[]>(url);
  }

  public static generate(securityId: number, strategyId: number) {
    return axiosApi.post(`/backtest/generate`, null, {
      params: {
        securityId,
        strategyId
      }
    });
  }

  public static update(backtest: Backtest) {
    return axiosApi.put(`/backtest/${backtest.id}`, backtest);
  }

  public static insert(backtest: Backtest) {
    return axiosApi.post('/backtest', backtest);
  }

  public static remove(id: number) {
    return axiosApi.delete(`/backtest/${id}`);
  }
}
