import * as React from 'react';
import { useState, useEffect } from 'react';

import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { createUseStyles, useTheme } from 'react-jss';
import { useSelector, useDispatch } from 'react-redux';

import { Theme } from '../../../../core/jss/theme';
import { Message, Position, ScheduleStatus } from '../../../../types';
import { getOpenOrdersAsync } from '../../../position/store/open-order/service';
import { setOpenPositions } from '../../../position/store/open-position/action-creators';
import { syncUnrealizedPnl } from '../../../shared/utils/position-utils';
import { ModuleState } from '../../state';
import { loadAccountInfoAsync } from '../../store/account-info/service';
import { loadInboxAsync } from '../../store/inbox/action-creators';
import { getScheduleStatusAsync } from '../../store/schedule-status/service';
import { getTwsStatusClassName, getScheduleStatusClassName } from '../../utils/status-utils';

import InboxDialog from './InboxDialog';
import { ScheduleDialog } from './ScheduleDialog';
import SystemClock from './SystemClock';
import { TwsDialog } from './TwsDialog';

const INTERVAL_IN_MINUTES = 1000 * 60 * 3;

interface StyleProps {
  twsOnline: boolean;
  scheduleOnline: boolean;
  inboxHasMessages: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
  clock: {
    fontSize: theme.font.size.lg,
    lineHeight: '36px',
    color: (props: StyleProps) => (props.scheduleOnline ? '#fff' : theme.palette.grayLight)
  },
  inbox: {
    color: (props: StyleProps) => (props.inboxHasMessages ? theme.palette.brandDanger : theme.palette.grayLight),
    '&:hover': {
      color: 'white'
    }
  },
  refresh: {
    color: theme.palette.brandInfo
  },
  btnIcon: {
    color: theme.palette.grayLight,
    '&:hover': {
      color: 'white'
    },
    'a:link': {
      color: theme.palette.grayLight
    },
    'a:active': {
      color: theme.palette.grayLight
    },
    'a:visited': {
      color: theme.palette.grayLight
    },
    'a:hover': {
      color: 'white'
    }
  },
  toolbar: {
    composes: ['btn-toolbar'],
    marginTop: '6px'
  }
}));

const StatusToolbar = () => {
  const [showTwsDialog, setShowTwsDialog] = useState(false);
  const [showScheduleDialog, setShowScheduleDialog] = useState(false);
  const [showInboxDialog, setShowInboxDialog] = useState(false);

  const state: ModuleState = useSelector((ms: ModuleState) => ms);

  const accountInfoLoading = !state.accountInfoState.loaded;
  const accountInfo = state.accountInfoState.accountInfo;
  const { portfolios } = accountInfo;
  const twsOnline = !!accountInfo.accountCode;

  const statusLoading: boolean = state.scheduleStatusState.loading;
  const status: ScheduleStatus = state.scheduleStatusState.status;
  const scheduleOnline = !!status.webApiTimestamp;

  const messages: Message[] = state.inboxState.messages;
  const inboxHasMessages = messages.filter((x) => !x.archived).length > 0;

  const openPositionsLoaded: boolean = state.openPositionState.loaded;
  const openPositions: Position[] = state.openPositionState.positions;

  const theme: Theme = useTheme();
  const props = { twsOnline, scheduleOnline, inboxHasMessages };
  const classes = useStyles({ ...props, theme });

  const dispatch = useDispatch();

  const refreshStatusBarHandler = () => {
    // eslint-disable-next-line no-console
    console.log('Refreshing status..');

    // get schedule-status
    dispatch(getScheduleStatusAsync());

    // update account-info
    dispatch(loadAccountInfoAsync());

    // update get open-orders
    dispatch(getOpenOrdersAsync());

    // get new messages
    dispatch(loadInboxAsync());
  };

  useEffect(() => {
    refreshStatusBarHandler();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      refreshStatusBarHandler();
    }, INTERVAL_IN_MINUTES);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    syncUnrealizedPnl(openPositions, portfolios);
    setOpenPositions(openPositions);
  }, [openPositionsLoaded, portfolios]);

  const twsStatusClassName = getTwsStatusClassName(accountInfoLoading, accountInfo);
  const scheduleStatusClassName = getScheduleStatusClassName(statusLoading, status);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xs-8">
            <div className={classes.toolbar}>
              <Button bsStyle="default" bsSize="xsmall" title="TWS" onClick={() => setShowTwsDialog(true)}>
                <span className={classes.btnIcon}>
                  <i className={classNames('fa', 'fa-power-off', 'fa-lg', { [twsStatusClassName]: true })} aria-hidden="true"></i>
                </span>
              </Button>
              <Button bsStyle="default" bsSize="xsmall" title="Schedule App" onClick={() => setShowScheduleDialog(true)}>
                <span className={classes.btnIcon}>
                  <i className={classNames('fa', 'fa-play-circle', 'fa-lg', { [scheduleStatusClassName]: true })} aria-hidden="true"></i>
                </span>
              </Button>
              <Button bsStyle="default" bsSize="xsmall" title="Warnings" onClick={() => setShowInboxDialog(true)}>
                <i className={classNames('fa', 'fa-exclamation-triangle', 'fa-lg', { [classes.inbox]: true })} aria-hidden="true"></i>
              </Button>
              <Button bsStyle="default" bsSize="xsmall" title="Refresh" onClick={refreshStatusBarHandler}>
                <span className={classes.btnIcon}>
                  <i className={classNames('fa', 'fa-refresh', 'fa-lg', { [classes.refresh]: true })} aria-hidden="true"></i>
                </span>
              </Button>
            </div>
          </div>
          <div className={classNames(classes.clock, 'col-xs-4', 'text-right')}>
            <SystemClock hhmm={status?.webApiTimestamp} />
          </div>
        </div>
      </div>
      {accountInfo.paperTradingMode && (
        <div className="paper-text paper-banner">
          <span>PAPER TRADING ACCOUNT FOR SIMULATED TRADING</span>
        </div>
      )}
      {showTwsDialog && (
        <TwsDialog accountInfo={accountInfo} loading={accountInfoLoading} show={showTwsDialog} close={() => setShowTwsDialog(false)} />
      )}
      {showScheduleDialog && (
        <ScheduleDialog status={status} loading={statusLoading} show={showScheduleDialog} close={() => setShowScheduleDialog(false)} />
      )}
      {showInboxDialog && <InboxDialog show={showInboxDialog} toggle={() => setShowInboxDialog(false)} />}
    </>
  );
};

export default StatusToolbar;
