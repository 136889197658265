import { BacktestSummary } from '../../../../types';

import * as actionTypes from './action-types';

export interface BacktestSummaryAction {
  type: string;
  securityId: number;
  summaries?: BacktestSummary[];
  error?: string;
  loading?: boolean;
}

export const setBacktestSummaries = (securityId: number, summaries: BacktestSummary[]): BacktestSummaryAction => {
  return {
    type: actionTypes.SET_BACKTEST_SUM_LIST,
    securityId,
    summaries
  };
};

export const setBacktestSummariesError = (securityId: number, error: string): BacktestSummaryAction => {
  return {
    type: actionTypes.SET_BACKTEST_SUM_ERROR,
    securityId,
    error
  };
};

export const clearBacktestSummariesError = (securityId: number): BacktestSummaryAction => {
  return {
    type: actionTypes.SET_BACKTEST_SUM_CLEAR_ERROR,
    securityId
  };
};

export const setBacktestSummariesLoading = (securityId: number, loading: boolean): BacktestSummaryAction => {
  return {
    type: actionTypes.SET_BACKTEST_SUM_LOADING,
    securityId,
    loading
  };
};
