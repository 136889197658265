import * as React from 'react';

import { Indicator } from '../../../../types';
import { basisTypes } from '../../utils/basis-utils';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const AvgUpDownForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label htmlFor="avgupdown_basis_type" className="col-xs-12 col-sm-6 control-label">
          Type
        </label>
        <div className="col-sm-6">
          <select
            id="avgupdown_basis_type"
            className="form-control"
            value={indicator.basisType}
            onChange={(event) => update({ basisType: Number(event.target.value) })}
          >
            {basisTypes.map((x) => (
              <option key={x.key} value={x.key}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="avgupdown_periods" className="col-sm-6 control-label">
          Periods (d:14)
        </label>
        <div className="col-sm-6">
          <input
            id="avgupdown_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.periods}
            onChange={(event) => update({ periods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="avgupdown_upper" className="col-sm-6 control-label">
          Upper Threshold
        </label>
        <div className="col-sm-6">
          <input
            id="avgupdown_upper"
            type="number"
            step="1"
            className="form-control"
            value={indicator.upperThreshold}
            onChange={(event) => update({ upperThreshold: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="avgupdown_lower" className="col-sm-6 control-label">
          Lower Threshold
        </label>
        <div className="col-sm-6">
          <input
            id="avgupdown_lower"
            type="number"
            step="1"
            className="form-control"
            value={indicator.lowerThreshold}
            onChange={(event) => update({ lowerThreshold: Number(event.target.value) })}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AvgUpDownForm;
