import * as React from 'react';
import { useEffect } from 'react';

import { Tabs, Tab } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { GlobalState } from '../../../core/root';
import { Account, Strategy, StrategyGroup } from '../../../types';
import * as accountActionCreators from '../../account/store/action-creators';
import { loadAccountListAsync } from '../../account/store/service';
import Spinner from '../../shared/components/Spinner';
import { loadStrategiesAsync } from '../../strategy/store/services';
import * as groupActionCreators from '../store/action-creators';

import StrategyGroupPanel from './StrategyGroupPanel';

const StrategyGroupScreen = () => {
  const authAccountId = useSelector((state: GlobalState) => state.authState.accountId);

  const accountsLoaded: boolean = useSelector((state: GlobalState) => state.accountState.accountListLoaded);
  const accounts: Account[] = useSelector((state: GlobalState) => state.accountState.accountList);
  const selectedAccountId = useSelector((state: GlobalState) => state.accountState.ui.selectedAccountId);

  const groupsLoaded: boolean = useSelector((state: GlobalState) => state.strategyGroupState.loaded);
  const groups: StrategyGroup[] = useSelector((state: GlobalState) => state.strategyGroupState.strategyGroups);

  const strategiesLoaded: boolean = useSelector((state: GlobalState) => state.strategyState.loaded);
  const strategies: Strategy[] = useSelector((state: GlobalState) => state.strategyState.strategies);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!accountsLoaded) {
      dispatch(loadAccountListAsync());
    }
    if (!groupsLoaded) {
      dispatch(groupActionCreators.loadStrategyGroupsAsync());
    }
    if (!strategiesLoaded) {
      dispatch(loadStrategiesAsync());
    }
  }, []);

  const selectTabHandler = (key: number) => {
    dispatch(accountActionCreators.setSelectedAccountId(key));
  };

  const loading = !accountsLoaded || !groupsLoaded;

  if (loading) {
    return <Spinner />;
  }

  const defaultId = accounts && accounts.length > 0 ? accounts[0].id : 0;
  const accountId = selectedAccountId || defaultId;
  const showCreateMenu = accountId === authAccountId;
  const tabs = accounts
    .filter((x) => (x.isAdminUser || x.isSuperUser) && !x.disabled)
    .sort((a, b) => {
      if (a.firstName > b.firstName) return 1;
      if (a.firstName < b.firstName) return -1;
      return 0;
    })
    .map((x) => (
      <Tab key={x.id} eventKey={x.id} title={x.firstName}>
        <StrategyGroupPanel groups={groups} strategies={strategies} accountId={x.id} showCreateMenu={showCreateMenu} />
      </Tab>
    ));

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item active">Groups</li>
      </ol>

      <div className="page-header">
        <h3>
          Strategy Groups <small className="no-wrap"></small>
        </h3>
      </div>

      {loading && <Spinner />}

      <Tabs id="account_strategy_group_tabs" defaultActiveKey={accountId} onSelect={selectTabHandler} animation={false}>
        {tabs}
      </Tabs>
    </div>
  );
};

export default StrategyGroupScreen;
