import React, { Component } from 'react';

import queryString from 'query-string';
import { Tabs, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';

import { GlobalState } from '../../../../core/root';
import { Security } from '../../../../types';
import Spinner from '../../../shared/components/Spinner';
import * as actionCreators from '../../store/action-creators';

import ContractTab from './ContractTab';
import DevlieryTab from './DeliveryTab';
import GeneralTab from './GeneralTab';
import SeasonTab from './SeasonTab';
import * as tabs from './tabs';

export interface SecurityScreenProps {
  security: Security;
  loaded: boolean;
  loadSecurities(): void;
  clearSecurityError(): void;
  selectedTab: string;
  selectTab(tab: string): void;
}

export interface RouteParamProps {
  id: string;
}

export type CombinedProps = SecurityScreenProps & RouteComponentProps<RouteParamProps>;

class SecurityScreen extends Component<CombinedProps, null> {
  componentDidMount() {
    if (!this.props.loaded) {
      this.props.loadSecurities();
    }
  }

  componentDidUpdate() {
    this.props.selectTab(this.props.selectedTab);
  }

  handleSelectTab = (key?: string) => {
    const search = key ? `?tab=${key}` : '';
    const pathname = this.props.match.url;
    this.props.history.push({ pathname, search: search });
  };

  render() {
    const { security } = this.props;

    const title = security ? security.name : 'undefined';
    return (
      <div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/securities">Securities</Link>
          </li>
          <li className="breadcrumb-item active">{title}</li>
        </ol>

        <div className="page-header">
          <h3>
            {title} <small className="no-wrap"></small>
          </h3>
        </div>

        {!this.props.loaded && <Spinner />}

        <Tabs id="security_tabs" activeKey={this.props.selectedTab} onSelect={this.handleSelectTab}>
          <Tab eventKey={tabs.GENERAL} title="General">
            {this.props.selectedTab === tabs.GENERAL && security && <GeneralTab security={security} />}
          </Tab>
          <Tab eventKey={tabs.SEASONS} title="Seasons">
            {this.props.selectedTab === tabs.SEASONS && security && <SeasonTab security={security} />}
          </Tab>
          <Tab eventKey={tabs.DELIVERY_MONTHS} title="Delivery Months">
            {this.props.selectedTab === tabs.DELIVERY_MONTHS && security && <DevlieryTab security={security} />}
          </Tab>
          <Tab eventKey={tabs.FUTURES} title="Futures">
            {this.props.selectedTab === tabs.FUTURES && security && <ContractTab security={security} />}
          </Tab>
          <Tab eventKey={tabs.OPTIONS} title="Options"></Tab>
          {/* <Tab eventKey={tabs.JOBS} title="Jobs">
            Tab 7 content
          </Tab>
          <Tab eventKey={tabs.TRADES} title="Trades">
            Tab 9 content
          </Tab>
          <Tab eventKey={tabs.PNL} title="PnL">
            Tab 10 content
          </Tab> */}
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState, props: CombinedProps) => {
  const params = queryString.parse(props.location.search);
  const tab = params.tab as string;
  const selectedTab = tab ? tab : state.securityState.ui.selectedTab || tabs.GENERAL;
  const security = state.securityState.securities.find((x) => x.id === Number(props.match.params.id));
  return {
    loaded: state.securityState.loaded,
    security,
    selectedTab
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSecurities: () => dispatch(actionCreators.loadSecuritiesAsync()),
    clearSecurityError: () => dispatch(actionCreators.clearSecurityError()),
    selectTab: (tab: string) => dispatch(actionCreators.selectTab(tab))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurityScreen);
