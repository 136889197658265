import { Position } from '../../../../types';
import axiosApi from '../../../shared/axios-api';

export class PositionSelectedHttpRequest {
  public static getPosition(id: number) {
    return axiosApi.get(`/position/${id}`);
  }
  public static updatePosition(position: Position) {
    return axiosApi.put(`/position/${position.id}`, position);
  }
}
