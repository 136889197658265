import * as React from 'react';
import { useEffect, useState } from 'react';

import classNames from 'classnames';
import { Tabs, Tab } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { GlobalState } from '../../../core/root';
import { Strategy, Account, StrategyGroup } from '../../../types';
import { setSelectedAccountId } from '../../account/store/action-creators';
import { loadAccountListAsync } from '../../account/store/service';
import CollapsePanel from '../../shared/components/CollapsePanel';
import { CreateDialog } from '../../shared/components/CreateDialog';
import Spinner from '../../shared/components/Spinner';
import { strategyModulePath } from '../routes';
import { setShowArchived } from '../store/action-creators';
import * as actionCreators from '../store/action-creators';
import { addStrategyAsync, loadArchivedStrategiesAsync, loadStrategiesAsync } from '../store/services';

import StrategyGroupFilterPanel from './StrategyGroupFilterPanel';

const StrategyListScreen = ({ history }: RouteComponentProps) => {
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  const state: GlobalState = useSelector((gs: GlobalState) => gs);
  const showArchived = state.strategyState.ui.showArchived;

  const authAccountId = state.authState.accountId;
  const accountsLoaded: boolean = state.accountState.accountListLoaded;
  const accounts: Account[] = state.accountState.accountList;
  const selectedAccountId = state.accountState.ui.selectedAccountId;

  const selectedGroup: StrategyGroup | undefined = state.strategyGroupState.ui.selectedStrategyGroup;

  const strategiesError = state.strategyState.error;
  const strategiesLoaded = state.strategyState.loaded;
  const archivedStrategiesLoaded = state.strategyState.archivedLoaded;
  const strategies = state.strategyState.strategies;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!accountsLoaded) {
      dispatch(loadAccountListAsync());
    }
    if (!strategiesLoaded) {
      dispatch(loadStrategiesAsync());
    }
  }, []);

  const cancelCreateStrategyHandler = () => {
    setShowCreateDialog(false);
  };

  const createStrategy = (name: string) => {
    dispatch(addStrategyAsync({ name } as Strategy));
    setShowCreateDialog(false);
  };

  const toggleShowArchived = () => {
    const toggle = !showArchived;
    if (toggle && !archivedStrategiesLoaded) {
      dispatch(loadArchivedStrategiesAsync());
    }
    dispatch(setShowArchived(toggle));
  };

  const accountId = selectedAccountId || authAccountId;
  const showCreateMenu = accountId === authAccountId;

  const tabs = accounts
    .filter((x) => (x.isAdminUser || x.isSuperUser) && !x.disabled)
    .sort((a, b) => {
      if (a.firstName > b.firstName) return 1;
      if (a.firstName < b.firstName) return -1;
      return 0;
    })
    .map((x) => <Tab key={x.id} eventKey={x.id} title={x.firstName}></Tab>);

  const selectTabHandler = (key: number) => {
    dispatch(setSelectedAccountId(key));
  };

  const loading = !accountsLoaded || !strategiesLoaded;

  const loadingArchived = showArchived && !archivedStrategiesLoaded;

  if (!strategiesError && (loading || loadingArchived)) {
    return <Spinner />;
  }

  const sortStrategies = (a: Strategy, b: Strategy) => {
    return a.name > b.name ? 1 : -1;
  };

  const goToStrategyHandler = (strategy: Strategy) => {
    // console.log(`row clicked: ${strategy.name}`);
    dispatch(actionCreators.setStrategyPicked(strategy));
    history.push(`${strategyModulePath}/${strategy.id}`);
  };

  const options = strategies
    .filter((x) => x.accountId === accountId)
    .filter((x) => {
      if (selectedGroup) {
        return selectedGroup.id === x.groupId;
      } else {
        return x.groupId == undefined;
      }
    })
    .filter((x) => {
      if (showArchived === true) {
        return x;
      } else {
        return x.archived === false;
      }
    })
    .sort(sortStrategies)
    .map((strategy: Strategy) => (
      <button key={strategy.id} type="button" className="btn btn-sm btn-default gap-bottom-5" onClick={() => goToStrategyHandler(strategy)}>
        {strategy.name}
      </button>
    ));

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item active">Strategies</li>
      </ol>

      <div className="page-header">
        <h3>
          Strategies <small className="no-wrap"></small>
        </h3>
      </div>

      <Tabs id="account_strategy_tabs" defaultActiveKey={accountId} onSelect={selectTabHandler} animation={false}>
        {tabs}
      </Tabs>

      <CollapsePanel title="Strategies" upper={true}>
        <StrategyGroupFilterPanel accountId={accountId} />
        <div className="btn-toolbar">{options}</div>
      </CollapsePanel>

      {showCreateMenu && (
        <div className="row">
          <div className="col-xs-6">
            <button
              type="button"
              className={classNames('btn', 'btn-sm', 'btn-default', { active: showArchived })}
              onClick={toggleShowArchived}
            >
              <span>Show Archived</span>
            </button>
          </div>
          <div className="col-xs-6 text-right">
            <button className="btn btn-sm btn-success" type="submit" onClick={() => setShowCreateDialog(true)}>
              Create..
            </button>
          </div>
        </div>
      )}

      <CreateDialog
        show={showCreateDialog}
        cancel={cancelCreateStrategyHandler}
        create={createStrategy}
        title="Create Strategy"
        propName="Name"
      />
    </div>
  );
};

export default withRouter(StrategyListScreen);
