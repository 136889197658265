export const sortByDate = (firstDt: Date | null = null, secondDt: Date | null = null, desc = false) => {
  firstDt = convertToDate(firstDt);
  secondDt = convertToDate(secondDt);
  const moreThan = desc ? -1 : 1;
  const lessThan = desc ? 1 : -1;
  if (!firstDt && !secondDt) {
    return 0;
  } else if (!firstDt) {
    return lessThan;
  } else if (!secondDt) {
    return moreThan;
  } else {
    return firstDt.getTime() > secondDt.getTime() ? moreThan : lessThan;
  }
};

export const getExpireInDays = (currentDt: Date, rolloverDt?: Date) => {
  const days = getDaysApart(currentDt, rolloverDt);
  return days && days > 0 ? days : 0;
};

export const getDaysApart = (first: Date | null = null, second: Date | null = null) => {
  if (!first || !second) {
    return null;
  }
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = convertToDate(first);
  const secondDate = convertToDate(second);
  return Math.round((secondDate.getTime() - firstDate.getTime()) / oneDay);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertToDate = (date: any): Date => {
  if (!date) {
    return date;
  }
  if (Number(date)) {
    return new Date(date);
  }
  if (typeof date === 'string') {
    return new Date(date);
  }
  return date;
};
