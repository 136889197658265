import { Map } from 'immutable';

import { GlobalState } from '../../../../core/root';
import { Summary } from '../../../../types';

export interface PnlStrategyState {
  summaries: Summary[];
  loading: boolean;
  loaded: boolean;
  error?: string;
}

export const initalPnlStrategyState: PnlStrategyState = {
  summaries: [],
  loading: false,
  loaded: false,
  error: undefined
};

export type PnlStrategyMapState = Map<number, PnlStrategyState>;

export const initalPnlStrategyMapState: PnlStrategyMapState = Map();

export const getPnlStrategyState = (state: GlobalState, key: number): PnlStrategyState | undefined => {
  return state.pnlStrategyMapState.get(key);
};

export const getError = (state: GlobalState, key: number) => {
  return getPnlStrategyState(state, key)?.error;
};

export const getLoading = (state: GlobalState, key: number) => {
  return getPnlStrategyState(state, key)?.loading;
};

export const getLoaded = (state: GlobalState, key: number) => {
  return getPnlStrategyState(state, key)?.loaded;
};

export const getSummaries = (state: GlobalState, key: number) => {
  return getPnlStrategyState(state, key)?.summaries;
};

export const shouldFetchSummaries = (state: GlobalState, key: number): boolean => {
  const pnlList = getPnlStrategyState(state, key);
  return pnlList === undefined || !!pnlList.error;
};
