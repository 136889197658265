import React, { useState } from 'react';

import classNames from 'classnames';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { createUseStyles, useTheme } from 'react-jss';
import { useDispatch } from 'react-redux';

import { Theme } from '../../../../../core/jss/theme';
import { AccountInfo } from '../../../../../types';
import Spinner from '../../../../shared/components/Spinner';
import { IB_SYSTEM_STATUS_URL, CME_HOLIDAY_CALENDAR_URL } from '../../../../shared/constants/links';
import { formatNok, formatUsd } from '../../../../shared/utils/currency-utils';
import { loadAccountInfoAsync } from '../../../store/account-info/service';

import { AccountStatsTable } from './AccountStatsTable';

const useStyles = createUseStyles((theme: Theme) => ({
  externalLink: {
    '&:hover': {
      color: theme.palette.brandSuccess
    }
  }
}));

export interface Props {
  accountInfo: AccountInfo;
  loading: boolean;
  show: boolean;
  close(): void;
}

export const TwsDialog = ({ accountInfo, loading, show, close }: Props) => {
  const twsOnline = !!accountInfo.accountCode;

  const DEFAULT_SELECTED_TAB = 'nok';

  const [selectedTab, setSelectedTab] = useState(DEFAULT_SELECTED_TAB);

  const selectTabHandler = (key?: string) => {
    setSelectedTab(key ?? DEFAULT_SELECTED_TAB);
  };

  const dispatch = useDispatch();
  const loadAccountInfoHandler = () => {
    // eslint-disable-next-line no-console
    console.log('loading account-info..');
    dispatch(loadAccountInfoAsync());
  };

  const theme: Theme = useTheme();
  const classes = useStyles({ theme });

  let numberOfPositions = 0;
  if (twsOnline) {
    numberOfPositions = accountInfo.portfolios?.filter((x) => x.positionSize != 0).length || 0;
  }

  return (
    <Modal show={show} bsSize="small" onHide={close} aria-labelledby="contained-modal-title">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title">
          <span>Trader Workstation</span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="row form-horizontal">
          <div className="col-xs-12">
            <div className="form-group form-group-sm">
              <label className="col-xs-7 control-label">Trader Workstation</label>
              <label className="col-xs-5 text-center" style={{ lineHeight: '1.9rem' }}>
                <i
                  className={classNames('fa', 'fa-power-off', 'fa-lg', {
                    online: !!accountInfo.accountCode,
                    offline: !accountInfo.accountCode
                  })}
                  aria-hidden="true"
                ></i>
              </label>
            </div>
          </div>

          <div className="col-xs-12 gap-top-10">
            <div className="btn-toolbar pull-right">
              <Button className={classes.externalLink} bsSize="small" href={IB_SYSTEM_STATUS_URL} target="_blank">
                Status Page
              </Button>
              <Button className={classes.externalLink} bsSize="small" href={CME_HOLIDAY_CALENDAR_URL} target="_blank">
                CME Holiday Calendar
              </Button>
            </div>
          </div>

          <div className="col-xs-12">
            <div className="section">
              <hr />
              <p>Account Info</p>
            </div>

            {twsOnline ? (
              <div className="table-responsive">
                <table className="table table-bordered table-striped table-condensed">
                  <tbody>
                    <tr>
                      <td className="middle small text-left">Time</td>
                      <td className="middle small text-right">{accountInfo.timestamp}</td>
                    </tr>
                    <tr>
                      <td className="middle small text-left">Positions</td>
                      <td className="middle small text-right">{numberOfPositions}</td>
                    </tr>
                    <tr>
                      <td className="middle small text-left">NOK - USD</td>
                      <td className="middle small text-right">
                        <span>{accountInfo.exchangeRateUsd}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <span className="small">Offline..</span>
            )}

            {twsOnline && (
              <Tabs id="account_stats" animation={true} activeKey={selectedTab} onSelect={selectTabHandler}>
                <Tab eventKey="nok" title="NOK">
                  <AccountStatsTable
                    formatCurrency={(val: number) => formatNok(val, 0)}
                    totalCash={accountInfo.cashBalanceBase}
                    availableCash={accountInfo.availableFundsNok}
                    initialMargin={accountInfo.initMarginReqNok}
                    unrealizedPnl={accountInfo.unrealizedPnlBase}
                    realizedPnl={accountInfo.realizedPnlBase}
                  />
                </Tab>
                <Tab eventKey="usd" title="USD">
                  <AccountStatsTable
                    formatCurrency={(val: number) => formatUsd(val, 0)}
                    totalCash={accountInfo.cashBalanceUsd}
                    availableCash={accountInfo.availableFundsNok / accountInfo.exchangeRateUsd}
                    initialMargin={accountInfo.initMarginReqNok / accountInfo.exchangeRateUsd}
                    unrealizedPnl={accountInfo.unrealizedPnlUsd}
                    realizedPnl={accountInfo.realizedPnlUsd}
                  />
                </Tab>
              </Tabs>
            )}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button bsStyle="primary" onClick={loadAccountInfoHandler}>
          Check Status
        </Button>
        <Button onClick={close}>Close</Button>
      </Modal.Footer>

      {loading && <Spinner />}
    </Modal>
  );
};
