import * as React from 'react';
import { useEffect, FunctionComponent } from 'react';

import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { GlobalState } from '../../../core/root';
import { Security } from '../../../types';
import CollapsePanel from '../../shared/components/CollapsePanel';
import * as actionCreators from '../store/action-creators';

export interface SecurityPickerPanelProps {
  title?: string;
  upper?: boolean;
  smallTitle?: string;
}

const SecurityPickerPanel: FunctionComponent<SecurityPickerPanelProps> = ({ title, upper, smallTitle, children }) => {
  const securitiesLoaded: boolean = useSelector((state: GlobalState) => state.securityState.loaded);
  const securities: Security[] = useSelector((state: GlobalState) => state.securityState.securities);
  const picked = useSelector((state: GlobalState) => state.securityState.ui.securityPicked);
  const collapsed = useSelector((state: GlobalState) => state.securityState.ui.pickerPanelCollapsed);
  const showArchived = useSelector((state: GlobalState) => state.securityState.ui.showArchived);
  const productTypes = useSelector((state: GlobalState) => state.securityState.ui.selectedProductTypes);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!securitiesLoaded) {
      dispatch(actionCreators.loadSecuritiesAsync());
    }
  }, []);

  const toggleCollapse = () => {
    dispatch(actionCreators.setPickerPanelCollapsed(!collapsed));
  };

  const pick = (security: Security) => {
    if (isActive(security)) {
      dispatch(actionCreators.setSecurityPicked(undefined));
    } else {
      dispatch(actionCreators.setSecurityPicked(security));
    }
  };

  const isActive = (security: Security) => {
    return picked && security.id === picked.id;
  };

  const options = securities
    .filter((x) => {
      if (productTypes.length === 0) {
        return x;
      } else {
        return productTypes.indexOf(x.productType) !== -1;
      }
    })
    .filter((x) => {
      if (showArchived === true) {
        return x;
      } else {
        return x.archived === false;
      }
    })
    .sort((a: Security, b: Security) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    })
    .map((security: Security) => (
      <button
        key={security.id}
        type="button"
        className={classNames('btn', 'btn-sm', 'btn-default', 'gap-bottom-5', { active: isActive(security) })}
        onClick={() => pick(security)}
      >
        {security.name}
      </button>
    ));

  const upperVal = upper !== undefined ? upper : true;

  return (
    <CollapsePanel
      title={title || 'Securities'}
      smallTitle={smallTitle}
      subTitle={picked?.name}
      upper={upperVal}
      collapsed={collapsed}
      onToggle={toggleCollapse}
    >
      <div className="form-horizontal">
        <div className="row">
          <div className="col-xs-12">
            {children}
            <div className="btn-toolbar">{options}</div>
          </div>
        </div>
      </div>
    </CollapsePanel>
  );
};

export default SecurityPickerPanel;
