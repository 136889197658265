import { ContractPartialQuote, ContractPartial, BarSize } from '../../../../types';
import { showNetworkError } from '../../../shared/utils/growl-utils';

import * as actionTypes from './action-types';
import { PartialQuoteHttpRequest } from './partial-quote-http-request';

export const addPartialQuote = (partialQuote: ContractPartialQuote) => {
  return {
    type: actionTypes.ADD_PARTIAL_QUOTE_ITEM,
    payload: partialQuote
  };
};

export const updatePartialQuote = (partialQuote: ContractPartialQuote) => {
  return {
    type: actionTypes.UPDATE_PARTIAL_QUOTE_ITEM,
    payload: partialQuote
  };
};

export const removePartialQuote = (partialQuote: ContractPartialQuote) => {
  return {
    type: actionTypes.REMOVE_PARTIAL_QUOTE_ITEM,
    payload: partialQuote
  };
};

export const setPartialQuotes = (partialQuotes: ContractPartialQuote[]) => {
  return {
    type: actionTypes.SET_PARTIAL_QUOTES,
    payload: partialQuotes
  };
};

export const setPartialQuoteError = (error: string) => {
  return {
    type: actionTypes.SET_PARTIAL_QUOTE_ERROR,
    value: error
  };
};

export const clearPartialQuoteError = () => {
  return {
    type: actionTypes.CLEAR_PARTIAL_QUOTE_ERROR
  };
};

export const togglePartialQuoteLoaded = (value: boolean) => {
  return {
    type: actionTypes.TOGGLE_PARTIAL_QUOTE_LOADED,
    value
  };
};

/*** ASYNC WITH THUNK ***/

export const loadPartialQuotesAsync = () => {
  return (dispatch) => {
    PartialQuoteHttpRequest.get()
      .then((response) => {
        // console.log(response);
        dispatch(setPartialQuotes(response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load partial-quotes');
        dispatch(setPartialQuoteError(msg));
      });
  };
};

export const collectPartialQuotesAsync = (partial: ContractPartial, barSize: BarSize) => {
  return (dispatch) => {
    dispatch(togglePartialQuoteLoaded(false));
    const promise = PartialQuoteHttpRequest.collect(partial, barSize);
    if (!promise) {
      return;
    }
    promise
      .then((response) => {
        if (Number(response.data)) {
          const partialQuote = {
            contractPartialId: partial.id,
            barSize,
            numQuotes: Number(response.data)
          } as ContractPartialQuote;
          dispatch(addPartialQuote(partialQuote));
          dispatch(togglePartialQuoteLoaded(true));
        }
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setPartialQuoteError(msg));
        dispatch(togglePartialQuoteLoaded(true));
      });
  };
};

export const updatePartialQuoteAsync = (partialQuote: ContractPartialQuote) => {
  return (dispatch) => {
    dispatch(togglePartialQuoteLoaded(false));
    const promise = PartialQuoteHttpRequest.updatePartialQuote(partialQuote);
    if (!promise) {
      return;
    }
    promise
      .then((response) => {
        if (Number(response.data)) {
          partialQuote.numQuotes = response.data;
          dispatch(updatePartialQuote(partialQuote));
          dispatch(togglePartialQuoteLoaded(true));
        }
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setPartialQuoteError(msg));
      });
  };
};

export const removePartialQuotesAsync = (partialQuote: ContractPartialQuote) => {
  return (dispatch) => {
    PartialQuoteHttpRequest.remove(partialQuote)
      .then(() => {
        dispatch(removePartialQuote(partialQuote));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setPartialQuoteError(msg));
      });
  };
};
