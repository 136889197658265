import * as React from 'react';

import { Switch, Route } from 'react-router-dom';

import StrategyListScreen from './components/StrategyListScreen';
import StrategyScreen from './components/StrategyScreen';

export const strategyModulePath = '/strategies';

const render = () => {
  return (
    <Switch>
      <Route exact path={strategyModulePath} component={StrategyListScreen}></Route>
      <Route exact path={strategyModulePath + '/:id'} component={StrategyScreen}></Route>
    </Switch>
  );
};

export default {
  routeProps: {
    path: strategyModulePath,
    render
  },
  name: 'Strategy'
};
