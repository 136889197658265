import * as React from 'react';

import { OverlayTrigger, Popover } from 'react-bootstrap';

import { BarSize } from '../../../../types';
import CollapsePanel from '../../../shared/components/CollapsePanel';
import { barSizes } from '../../../shared/utils/bar-utils';
import { convertToDate } from '../../../shared/utils/date-utils';

export interface Props {
  periodEnd?: string;
  generalAtrTicksBarSize: BarSize;
  setGeneralAtrTicksBarSize(barSize: BarSize): void;
  generalAtrTicks: number;
  setGeneralAtrTicks(num: number): void;
  queryGeneralAtrInRth(): void;
  averageCloseChangeInTicks: number;
  setAverageCloseChangeInTicks(num: number): void;
  queryEndOfDayAtrTicks(): void;
}

const StatisticsPanel = ({
  periodEnd,
  generalAtrTicksBarSize,
  setGeneralAtrTicksBarSize,
  generalAtrTicks,
  setGeneralAtrTicks,
  queryGeneralAtrInRth,
  averageCloseChangeInTicks,
  setAverageCloseChangeInTicks,
  queryEndOfDayAtrTicks
}: Props) => {
  const infoGenAtrPopover = (
    <Popover id="infoGenAtrPopover">
      An average of ticks inside Average True Range (ATR) from start to end of the contract, using the selected bar-size, using regular
      trading hours (RTH) for intraday bar-sizes.
    </Popover>
  );

  const eodTicksPopover = (
    <Popover id="eodTicksPopover">
      An average of end-of-day true range in ticks; which makes the foundation for calculating the current day-range in percentage of
      average end-of-day range.
    </Popover>
  );

  const firstNoticeWarningPopover = (
    <Popover id="firstNoticeWarningPopover" title="Warning">
      Only query using contracts passed 1st Notice
    </Popover>
  );

  const statBarSizes = barSizes.filter((x) => x.key >= BarSize._5_MIN && x.key < BarSize._1_DAY);

  return (
    <CollapsePanel title="Statistics">
      <div className="section">
        <p>Average ATR for Bar Size</p>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="input_gen_atr_ticks_barsize" className="col-xs-12 col-sm-4 control-label">
          <span>Bar Size</span>
          <OverlayTrigger placement="top" overlay={infoGenAtrPopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg text-info" />
            </span>
          </OverlayTrigger>
          {convertToDate(periodEnd) > new Date() && (
            <OverlayTrigger placement="top" overlay={firstNoticeWarningPopover}>
              <span className="gap-left-10">
                <i className="fa fa-warning fa-lg warning-text" />
              </span>
            </OverlayTrigger>
          )}
        </label>
        <div className="col-sm-8">
          <select
            id="input_gen_atr_ticks_barsize"
            className="form-control"
            value={generalAtrTicksBarSize}
            onChange={(event) => setGeneralAtrTicksBarSize(Number(event.target.value))}
          >
            <option value="">Velg..</option>
            {statBarSizes.map((x) => (
              <option key={x.key} value={x.key}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="input_gen_atr_ticks" className="col-xs-12 col-sm-4 control-label">
          Avg. Ticks
        </label>
        <div className="col-xs-8 col-sm-5">
          <input
            id="input_gen_atr_ticks"
            type="number"
            className="form-control"
            value={generalAtrTicks}
            onChange={(event) => setGeneralAtrTicks(Number(event.target.value))}
          />
        </div>
        <div className="col-xs-4 col-sm-3">
          <button className="btn btn-warning btn-sm btn-block" type="button" onClick={queryGeneralAtrInRth}>
            Query
          </button>
        </div>
      </div>

      <div className="section">
        <hr />
        <p>Average ATR for 24H Day</p>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="input_eod_ticks" className="col-xs-12 col-sm-4 control-label">
          <span>Avg. Ticks</span>
          <OverlayTrigger placement="top" overlay={eodTicksPopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg text-info" />
            </span>
          </OverlayTrigger>
          {convertToDate(periodEnd) > new Date() && (
            <OverlayTrigger placement="top" overlay={firstNoticeWarningPopover}>
              <span className="gap-left-10">
                <i className="fa fa-warning fa-lg warning-text" />
              </span>
            </OverlayTrigger>
          )}
        </label>
        <div className="col-xs-8 col-sm-5">
          <input
            id="input_eod_ticks"
            type="number"
            className="form-control"
            value={averageCloseChangeInTicks}
            onChange={(event) => setAverageCloseChangeInTicks(Number(event.target.value))}
          />
        </div>
        <div className="col-xs-4 col-sm-3">
          <button className="btn btn-warning btn-sm btn-block" type="button" onClick={queryEndOfDayAtrTicks}>
            Query
          </button>
        </div>
      </div>
    </CollapsePanel>
  );
};

export default StatisticsPanel;
