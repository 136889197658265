import { Position } from '../../../../types';

import * as actionTypes from './action-types';

export interface BacktestPositionAction {
  type: string;
  backtestId: number;
  positions?: Position[];
  error?: string;
  loading?: boolean;
}

export const setBacktestPositions = (backtestId: number, positions: Position[]): BacktestPositionAction => {
  return {
    type: actionTypes.SET_BACKTEST_POS_LIST,
    backtestId,
    positions
  };
};

export const setBacktestLoading = (backtestId: number, loading: boolean): BacktestPositionAction => {
  return {
    type: actionTypes.SET_BACKTEST_POS_LOADING,
    backtestId,
    loading
  };
};

export const setBacktestError = (backtestId: number, error: string): BacktestPositionAction => {
  return {
    type: actionTypes.SET_BACKTEST_POS_ERROR,
    backtestId,
    error
  };
};

export const clearBacktestError = (backtestId: number): BacktestPositionAction => {
  return {
    type: actionTypes.SET_BACKTEST_POS_CLEAR_ERROR,
    backtestId
  };
};
