import { CompareType } from '../../../types';

export interface CompareTypeItem {
  key: CompareType;
  name: string;
}

export const compareTypes: CompareTypeItem[] = [
  { key: CompareType.Past, name: 'Past' },
  { key: CompareType.SimpleMovingAverage, name: 'SMA' },
  { key: CompareType.ExponentialMovingAverage, name: 'EMA' }
];

export const trendFollowingCompareTypes: CompareTypeItem[] = [
  { key: CompareType.Past, name: 'Close Price' },
  { key: CompareType.SimpleMovingAverage, name: 'SMA' },
  { key: CompareType.ExponentialMovingAverage, name: 'EMA' }
];
