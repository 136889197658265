import * as React from 'react';
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';

import { GlobalState } from '../../../core/root';
import { IndicatorResult, Period } from '../../../types';
import IndicatorResultList from '../../indicator-result/components/IndicatorResultList';
import { loadIndicatorResultsAsync } from '../../indicator-result/store/service';
import CollapsePanel from '../../shared/components/CollapsePanel';
import Spinner from '../../shared/components/Spinner';
import { periodModulePath } from '../period-routes';
import { setPeriodDay, setSelectedSubscriptionId } from '../store/period-range/action-creators';
import { getLatestSubscriptionPeriodAsync, getNextPeriodAsync, getPeriodAsync, getPreviousPeriodAsync } from '../store/period/service';

import PeriodResultPanel from './PeriodResultPanel';
import PeriodTable from './PeriodTable';

export interface RouteParamProps {
  periodId: string;
}

export type Props = RouteComponentProps<RouteParamProps>;

const PeriodScreen: React.FunctionComponent<Props> = ({ match, history }: Props) => {
  const periodId = Number(match.params.periodId);

  const state: GlobalState = useSelector((gs: GlobalState) => gs);
  const periodState = state.periodMapState.get(periodId);
  const period: Period | undefined = periodState?.period;
  const error = periodState?.error;
  const loading = periodState?.loading;
  const shouldFetch = !period && !error;

  const indicatorResultState = state.indicatorResultMapState.get(periodId);
  const results: IndicatorResult[] | undefined = indicatorResultState?.results;
  const indicatorResultsError = indicatorResultState?.error;
  const indicatorResultsLoading = indicatorResultState?.loading;
  const indicatorResultsShouldFetch = !results && !indicatorResultsError;

  const dispatch = useDispatch();

  useEffect(() => {
    if (shouldFetch) {
      dispatch(getPeriodAsync(periodId));
    }
  }, [shouldFetch]);

  useEffect(() => {
    if (indicatorResultsShouldFetch) {
      dispatch(loadIndicatorResultsAsync(periodId));
    }
  }, [indicatorResultsShouldFetch]);

  if (loading || indicatorResultsLoading) {
    return <Spinner />;
  }

  const goto = (id: number) => {
    history.push(`${periodModulePath}/${id}`);
  };

  const getNextPeriodHandler = () => {
    dispatch(getNextPeriodAsync(periodId, (id: number) => goto(id)));
  };

  const getPreviousPeriodHandler = () => {
    dispatch(getPreviousPeriodAsync(periodId, (id: number) => goto(id)));
  };

  const getLatestPeriodHandler = () => {
    if (period) {
      dispatch(getLatestSubscriptionPeriodAsync(period.subscriptionId, (id: number) => goto(id)));
    }
  };

  const goToListHandler = () => {
    if (period) {
      dispatch(setPeriodDay(period.created));
      dispatch(setSelectedSubscriptionId(period.subscriptionId));
      history.push(periodModulePath);
    }
  };

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to={periodModulePath}>Periods</Link>
        </li>
        <li className="breadcrumb-item active">{periodId}</li>
      </ol>

      <div className="page-header">
        <h3>
          {/* Period <small className="no-wrap">{ period && toMonthTime(period.created)}</small> */}
          Period <small className="no-wrap">{period && period.security.name}</small>
        </h3>
      </div>

      <CollapsePanel title="Period">
        {period && <PeriodTable periods={[period]} single={true} />}
        <div className="section">
          <hr />
          <p>Time {'&'} Risk Results</p>
        </div>
        {period ? <PeriodResultPanel period={period} /> : <span className="small">No results..</span>}

        <div className="section">
          <hr />
          <p>Indicator Results</p>
        </div>
        {results && results.length > 0 ? <IndicatorResultList results={results} /> : <span className="small">No results..</span>}
      </CollapsePanel>

      <div className="row">
        <div className="col-offset-sm-3 col-sm-6 col-sm-offset-3">
          <div className="btn-group btn-group-justified" role="group">
            <div className="btn-group btn-group-sm" role="group">
              <button type="button" className="btn btn-default" onClick={getPreviousPeriodHandler}>
                <span className="glyphicon glyphicon glyphicon-triangle-left" aria-hidden="true"></span>
              </button>
            </div>
            <div className="btn-group btn-group-sm" role="group">
              <button type="button" className="btn btn-default" onClick={getNextPeriodHandler}>
                <span className="glyphicon glyphicon glyphicon-triangle-right" aria-hidden="true"></span>
              </button>
            </div>
            <div className="btn-group btn-group-sm" role="group">
              <button type="button" className="btn btn-default" onClick={getLatestPeriodHandler}>
                <span className="glyphicon glyphicon glyphicon-step-forward" aria-hidden="true"></span>
              </button>
            </div>
            <div className="btn-group btn-group-sm" role="group">
              <button type="button" className="btn btn-default" onClick={goToListHandler}>
                <span className="glyphicon glyphicon glyphicon glyphicon-list" aria-hidden="true"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeriodScreen;
