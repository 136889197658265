/*** ASYNC WITH THUNK ***/

import { showNetworkError } from '../../../shared/utils/growl-utils';

import { setBacktestError, setBacktestPositions } from './action-creators';
import { BacktestPositionHttpRequest } from './http-request';

export const loadBacktestPositionsAsync = (backtestId: number) => {
  return (dispatch) => {
    BacktestPositionHttpRequest.getPositions(backtestId)
      .then((response) => {
        dispatch(setBacktestPositions(backtestId, response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load backtest-positions');
        dispatch(setBacktestError(backtestId, msg));
      });
  };
};
