import * as React from 'react';

import { Switch, Route } from 'react-router-dom';

import BacktestSummaryScreen from './components/BacktestSummaryScreen';

export const backtestSummaryModulePath = '/backtest-summaries';

const render = () => {
  return (
    <Switch>
      <Route exact path={'/backtest-summaries'} component={BacktestSummaryScreen}></Route>
    </Switch>
  );
};

export default {
  routeProps: {
    path: backtestSummaryModulePath,
    render
  },
  name: 'Backtest-Summary'
};
