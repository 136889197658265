import * as React from 'react';
import { useState, useEffect, FunctionComponent } from 'react';

import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { Account, Strategy, StrategyGroup, Subscription } from '../../../../types';
import { loadAccountListAsync } from '../../../account/store/service';
import CollapsePanel from '../../../shared/components/CollapsePanel';
import Spinner from '../../../shared/components/Spinner';
import { loadStrategyGroupsAsync } from '../../../strategy-group/store/action-creators';
import { sortStrategies } from '../../../strategy-group/utils';
import { ModuleState } from '../../state';

interface Props {
  subscription: Subscription;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdate(obj: any): void;
  strategies: Strategy[];
}

const AdvanvedStrategyPanel: FunctionComponent<Props> = ({ subscription, onUpdate, strategies }) => {
  const state: ModuleState = useSelector((gs: ModuleState) => gs);
  const showArchivedStrategies: boolean = state.strategyState.ui.showArchived;
  const subStrategy = strategies.find((x) => x.id === subscription.strategyId);

  const strategyGroupsLoaded: boolean = state.strategyGroupState.loaded;
  const strategyGroups: StrategyGroup[] = state.strategyGroupState.strategyGroups;
  const initialGroup = subStrategy?.groupId || 0;
  const [selectedGroupId, setSelectedGroupId] = useState(initialGroup);

  const accountsLoaded: boolean = state.accountState.accountListLoaded;
  const accounts: Account[] = state.accountState.accountList.filter((x) => x.isSuperUser);
  const initialAccount = subStrategy?.accountId || state.accountState.ui.selectedAccountId || state.authState.accountId;
  const [selectedAccountId, setSelectedAccountId] = useState(initialAccount);

  const loaded = strategyGroupsLoaded && accountsLoaded;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!strategyGroupsLoaded) {
      dispatch(loadStrategyGroupsAsync());
    }
    if (!accountsLoaded) {
      dispatch(loadAccountListAsync());
    }
  }, []);

  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const strategyGroupOptions = strategyGroups
    .filter((x) => x.name)
    .filter((x) => {
      if (subStrategy && subStrategy.groupId === x.id) {
        return x;
      } else {
        return x.accountId === selectedAccountId;
      }
    })
    .map((x) => (
      <option key={x.id} value={x.id}>
        {x.name}
      </option>
    ));

  const strategyOptions = strategies
    .filter((x) => x.name)
    .filter((x) => {
      if (subStrategy && subStrategy.id === x.id) {
        return x;
      } else {
        return x.groupId === selectedGroupId;
      }
    })
    .filter((x) => {
      if (showArchivedStrategies === true) {
        return x;
      } else {
        return x.archived === false;
      }
    })
    .sort(sortStrategies)
    .map((x) => (
      <option key={x.id} value={x.id}>
        {x.name}
      </option>
    ));

  const selectStrategyHandler = (val: string) => {
    if (!val) {
      onUpdate({ strategyId: undefined });
    } else {
      const strategy = strategies.find((x) => x.id === parseInt(val));
      onUpdate({ strategyId: strategy?.id });
    }
  };

  const priorityInfo = (
    <Popover id="priorityInfo">
      A priority order is used when there is more than one subscription for the same subscription. Subscriptions with high priority
      strategies are forecasted first.
    </Popover>
  );

  return loaded ? (
    <CollapsePanel title="Strategy" onToggle={toggleCollapse}>
      <div className="form-group form-group-sm">
        <label htmlFor="input_sub_account" className="col-sm-4 control-label">
          Strategy Owner
        </label>
        <div className="col-sm-8">
          <select
            id="input_sub_account"
            className="form-control"
            value={selectedAccountId}
            onChange={(event) => setSelectedAccountId(Number(event.target.value))}
          >
            <option value="">Velg..</option>
            {accounts.map((x) => (
              <option key={x.id} value={x.id}>
                {x.firstName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="form-group form-group-sm">
        <label htmlFor="input_sub_account" className="col-sm-4 control-label">
          Strategy Group
        </label>
        <div className="col-sm-8">
          <select
            id="input_sub_account"
            className="form-control"
            value={selectedGroupId}
            onChange={(event) => setSelectedGroupId(Number(event.target.value))}
          >
            <option value="">Velg..</option>
            {strategyGroupOptions}
          </select>
        </div>
      </div>
      <div className="form-group form-group-sm">
        <label htmlFor="input_asset_futcode" className="col-sm-4 control-label">
          Strategy
        </label>
        <div className="col-sm-8">
          <select
            id="input_asset_futcode"
            className="form-control"
            value={subscription.strategyId || ''}
            onChange={(event) => selectStrategyHandler(event.target.value)}
          >
            <option value="">Velg..</option>
            {strategyOptions}
          </select>
        </div>
      </div>
      <div className="form-group form-group-sm">
        <label className="col-sm-4 control-label">
          <span>Priority</span>
          <OverlayTrigger placement="top" overlay={priorityInfo}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg text-info" />
            </span>
          </OverlayTrigger>
        </label>
        <div className="col-sm-8">
          <input
            id="strategy_pri"
            type="number"
            step="1"
            className="form-control"
            value={subscription.priority}
            onChange={(event) =>
              onUpdate({
                priority: Number(event.target.value)
              })
            }
          />
        </div>
      </div>
    </CollapsePanel>
  ) : (
    <Spinner />
  );
};

export default AdvanvedStrategyPanel;
