import { StrategyGroup } from '../../../types';
import axiosApi from '../../shared/axios-api';

export class StrategyGroupHttpRequest {
  public static get() {
    return axiosApi.get<StrategyGroup[]>(`/strategygroup`);
  }

  public static update(strategyGroup: StrategyGroup) {
    return axiosApi.put(`/strategygroup/${strategyGroup.id}`, strategyGroup);
  }

  public static insert(strategyGroup: StrategyGroup) {
    return axiosApi.post('/strategygroup', strategyGroup);
  }

  public static remove(id: number) {
    return axiosApi.delete(`/strategygroup/${id}`);
  }
}
