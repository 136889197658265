import { Position } from '../../../../types';

import { BacktestPositionAction } from './action-creators';
import * as actionTypes from './action-types';
import { initialBacktestPositionMapState, initialBacktestPositionState } from './state';

export const backtestPositionReducer = (state = initialBacktestPositionMapState, action: BacktestPositionAction) => {
  const key = action.backtestId;
  const current = state.get(key) || { ...initialBacktestPositionState };

  if (action.type === actionTypes.SET_BACKTEST_POS_LOADING) {
    const loading = action.loading === true;
    return state.set(key, {
      ...current,
      loading
    });
  } else if (action.type === actionTypes.SET_BACKTEST_POS_ERROR) {
    return state.set(key, {
      ...current,
      loading: false,
      error: action.error
    });
  } else if (action.type === actionTypes.SET_BACKTEST_POS_CLEAR_ERROR) {
    return state.set(key, {
      ...current,
      error: undefined
    });
  } else if (action.type === actionTypes.SET_BACKTEST_POS_LIST) {
    const positions = action.positions as Position[];
    return state.set(key, {
      loading: false,
      loaded: true,
      error: undefined,
      positions
    });
  }

  return state;
};
