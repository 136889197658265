import * as React from 'react';
import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { Backtest, Strategy, BarSize } from '../../../../types';
import BacktestList from '../../../backtest/components/BacktestList';
import { ModuleState } from '../../../backtest/state';
import * as backtestActionCreators from '../../../backtest/store/backtest/action-creators';
import { getBacktests, getLoaded } from '../../../backtest/store/backtest/selectors';
import * as partialQuoteActionCreators from '../../../contract-partial/store/partial-quote/action-creators';
import CategoryFilterPanel from '../../../security/components/CategoryFilterPanel';
import SecurityPickerPanel from '../../../security/components/SecurityPickerPanel';
import Spinner from '../../../shared/components/Spinner';

interface Props {
  strategy: Strategy;
}

const BacktestTab = ({ strategy }: Props) => {
  const state = useSelector((gs: ModuleState) => gs);
  const securitiesLoaded: boolean = state.securityState.loaded;
  const selectedSecurity = state.securityState.ui.securityPicked;

  const partialQuotesLoaded = state.partialQuoteState.loaded;

  let backtests: Backtest[] = [];
  if (selectedSecurity) {
    backtests = getBacktests(state, selectedSecurity.id, strategy.id) || [];
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (!partialQuotesLoaded) {
      dispatch(partialQuoteActionCreators.loadPartialQuotesAsync());
    }
  }, []);

  let backtestsLoaded = true;
  useEffect(() => {
    if (selectedSecurity && strategy) {
      backtestsLoaded = getLoaded(state, selectedSecurity.id, strategy.id);
      if (!backtestsLoaded) {
        dispatch(backtestActionCreators.loadBacktestsAsync(selectedSecurity.id, strategy.id));
      }
    }
  }, [strategy, selectedSecurity]);

  const loading = !securitiesLoaded || !partialQuotesLoaded || !backtestsLoaded;

  const getBacktestWarningMessage = () => {
    const barSize = strategy.periodBarSize;
    if (barSize === BarSize.NA) {
      return "Strategy time period bar-size is NOT selected. Please select period bar-size from the strategy's Time-tab.";
    }
  };

  let backtestList: JSX.Element;
  if (!selectedSecurity) {
    backtestList = <p className="small">Security not selected..</p>;
  } else {
    const warningMessage = getBacktestWarningMessage();

    backtestList = (
      <>
        {warningMessage && (
          <div className="alert alert-warning small" role="alert">
            <b>WARNING</b> {warningMessage}
          </div>
        )}
        <BacktestList securityId={selectedSecurity.id} strategyId={strategy.id} backtests={backtests} />
      </>
    );
  }

  return (
    <div>
      {loading && <Spinner />}

      <SecurityPickerPanel upper={true}>
        <CategoryFilterPanel />
      </SecurityPickerPanel>

      {backtestList}
    </div>
  );
};

export default BacktestTab;
