import { BacktestPositionMapState, BacktestPositionState } from './state';

export interface PartialState {
  backtestPositionMapState: BacktestPositionMapState;
}

export const getBacktestPositionState = (state: PartialState, backtestId: number): BacktestPositionState | undefined => {
  return state.backtestPositionMapState.get(backtestId);
};

export const getError = (state: PartialState, backtestId: number) => {
  return getBacktestPositionState(state, backtestId)?.error;
};

export const getLoading = (state: PartialState, backtestId: number) => {
  return getBacktestPositionState(state, backtestId)?.loading;
};

export const getLoaded = (state: PartialState, backtestId: number) => {
  return getBacktestPositionState(state, backtestId)?.loaded;
};

export const getBacktestPositions = (state: PartialState, backtestId: number) => {
  return getBacktestPositionState(state, backtestId)?.positions;
};

export const shouldFetchPositions = (state: PartialState, backtestId: number): boolean => {
  const backtestPosState = getBacktestPositionState(state, backtestId);
  return backtestPosState === undefined || !!backtestPosState.error;
};
