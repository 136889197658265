import { Map } from 'immutable';

import { ContractPartial } from '../../../../types';

export interface ContractPartialState {
  loaded: boolean;
  error?: string;
  partials: ContractPartial[];
}

export const initalContractPartialState: ContractPartialState = {
  loaded: false,
  error: undefined,
  partials: []
};

export type ContractPartialMapState = Map<number, ContractPartialState>;

export const initalContractPartialMapState: ContractPartialMapState = Map();
