import * as React from 'react';

import { Route, Redirect, Switch } from 'react-router-dom';

import authRoutes, { authModulePath } from '../modules/auth/routes';

import Home from './components/Home';
import modules from './module-routes';

export const getRoutes = (authorized: boolean) => {
  // set up route guards
  if (!authorized) {
    return (
      <Switch>
        <Route {...authRoutes.routeProps} key={authRoutes.name} />
        <Redirect to={`${authModulePath}/login`} />
      </Switch>
    );
  }

  return (
    <Switch>
      {modules.map((module) => (
        <Route {...module.routeProps} key={module.name} />
      ))}
      <Route exact path={'/'} component={Home}></Route>
      <Redirect to={''} />
    </Switch>
  );
};
