import { BarSize, DayOfWeek } from '../../../types';

import { getBarSizeInMinutes } from './bar-utils';
import { convertToDate } from './date-utils';
import { addLeadingZero } from './number-utils';

export const setDecimalSpaces = (num: number, spaces: number) => {
  if (!num || !Number(num)) {
    return num;
  }
  return num.toFixed(spaces).replace(/\./g, ',');
};

export const getFormatedTimeFromPeriods = (barSize: BarSize, numPeriods: number) => {
  const barSizeMinutes = getBarSizeInMinutes(barSize);
  if (!numPeriods || !barSizeMinutes) {
    return 'N/A';
  }
  const totalMinutes = barSizeMinutes * numPeriods;
  const hours = Math.floor(totalMinutes / 60);
  //const hourString = "" + ((hours >= 10) ? hours : "0" + hours);
  const min = totalMinutes % 60;
  //const minString = "" + ((min >= 10) ? min : "0" + min);
  return addLeadingZero(hours) + ':' + addLeadingZero(min);
};

const getDurationInternal = (dt1: Date, dt2: Date) => {
  const dif = dt1.getTime() - dt2.getTime();
  const totalSeconds = dif / 1000;
  let minutes = Math.round(Math.abs(totalSeconds) / 60);
  let hours = 0;

  if (minutes > 59) {
    hours = Math.floor(minutes / 60);
    const totalMinutesInHours = hours * 60;
    minutes = Math.round(minutes - totalMinutesInHours);
    // fix
    if (minutes === 60) {
      minutes -= 60;
      hours += 1;
    }
  }

  let formattedDays = '';
  let days = 0;
  if (hours > 23) {
    days = Math.floor(hours / 24);
    const totalHoursInDays = days * 24;
    hours = Math.round(hours - totalHoursInDays);
    if (hours === 24) {
      hours -= 24;
      days += 1;
    }
    formattedDays = `${days}d `;
  }

  const formattedHours = days > 0 || hours > 0 ? `${hours}h ` : '';
  const formattedMinutes = `${minutes}m`;
  return `${formattedDays}${formattedHours}${formattedMinutes}`;
};

export const getDuration = (first: Date | string | undefined, second: Date | string | undefined) => {
  const dt1 = convertToDate(first);
  const dt2 = convertToDate(second);
  if (!dt1 || !dt2) {
    return '';
  }
  return getDurationInternal(dt1, dt2);
};

export const getTimeSpanByPeriods = (first: Date, barSize: BarSize, periods: number) => {
  const dt1 = convertToDate(first);
  const numberOfMinutes = getBarSizeInMinutes(barSize) as number;
  const totalMinutes = numberOfMinutes * periods;
  const dt2 = new Date(dt1.getTime() + totalMinutes * 60000);
  return getDurationInternal(dt1, dt2);
};

export const convertTicksToSeconds = (ticks: number) => {
  const ticksPerSecond = 10000000;
  return setDecimalSpaces(ticks / ticksPerSecond, 1);
};

export interface DayOfWeekInfoItem {
  key: number;
  name: string;
}

export const dayOfWeekList: DayOfWeekInfoItem[] = [
  { key: DayOfWeek.Sunday, name: 'Sunday' },
  { key: DayOfWeek.Monday, name: 'Monday' },
  { key: DayOfWeek.Tuesday, name: 'Tuesday' },
  { key: DayOfWeek.Wednesday, name: 'Wednesday' },
  { key: DayOfWeek.Thursday, name: 'Thursday' },
  { key: DayOfWeek.Friday, name: 'Friday' },
  { key: DayOfWeek.Saturday, name: 'Saturday' }
];

export const hoursOfDayList: DayOfWeekInfoItem[] = [
  { key: 0, name: '00:00 - 01:00' },
  { key: 1, name: '01:00 - 02:00' },
  { key: 2, name: '02:00 - 03:00' },
  { key: 3, name: '03:00 - 04:00' },
  { key: 4, name: '04:00 - 05:00' },
  { key: 5, name: '05:00 - 06:00' },
  { key: 6, name: '06:00 - 07:00' },
  { key: 7, name: '07:00 - 08:00' },
  { key: 8, name: '08:00 - 09:00' },
  { key: 9, name: '09:00 - 10:00' },
  { key: 10, name: '10:00 - 11:00' },
  { key: 11, name: '11:00 - 12:00' },
  { key: 12, name: '12:00 - 13:00' },
  { key: 13, name: '13:00 - 14:00' },
  { key: 14, name: '14:00 - 15:00' },
  { key: 15, name: '15:00 - 16:00' },
  { key: 16, name: '16:00 - 17:00' },
  { key: 17, name: '17:00 - 18:00' },
  { key: 18, name: '18:00 - 19:00' },
  { key: 19, name: '19:00 - 20:00' },
  { key: 20, name: '20:00 - 21:00' },
  { key: 21, name: '21:00 - 22:00' },
  { key: 22, name: '22:00 - 23:00' },
  { key: 23, name: '23:00 - 00:00' }
];
