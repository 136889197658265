import { Map } from 'immutable';

import { IndicatorResult } from '../../../types';

export interface IndicatorResultState {
  loading: boolean;
  error?: string;
  results: IndicatorResult[];
}

export const initalIndicatorResultState: IndicatorResultState = {
  loading: false,
  error: undefined,
  results: []
};

export type IndicatorResultMapState = Map<number, IndicatorResultState>;

export interface PartialState {
  indicatorResultMapState: IndicatorResultMapState;
}

export const initalIndicatorResultMapState: IndicatorResultMapState = Map();
