import * as React from 'react';
import { FunctionComponent, useState } from 'react';

import { NavDropdown, NavItem } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';

import AccountDialog from '../../../../modules/account/components/AccountDialog';
import { authModulePath } from '../../../../modules/auth/routes';
import { GlobalState } from '../../../root';

const AccountNavDropDown: FunctionComponent = () => {
  const firstName = useSelector((state: GlobalState) => state.authState.firstName);
  const [showAccountDialog, setShowAccountDialog] = useState(false);

  const toggleShowAccountDialog = () => {
    setShowAccountDialog(!showAccountDialog);
  };

  const navDropdownAccountTitle = <i className="fa fa-user-circle-o fa-lg" aria-hidden="true"></i>;
  return (
    <>
      <NavDropdown title={navDropdownAccountTitle} id="basic-nav-dropdown5">
        <NavItem onClick={toggleShowAccountDialog} className="ripple">
          {firstName}
        </NavItem>
        <hr className={'nav-devider'} />
        <LinkContainer to={`${authModulePath}/logout`} className="ripple">
          <NavItem>Logout</NavItem>
        </LinkContainer>
      </NavDropdown>
      {showAccountDialog && <AccountDialog show={showAccountDialog} toggle={toggleShowAccountDialog} />}
    </>
  );
};

export default AccountNavDropDown;
