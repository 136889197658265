import React, { FunctionComponent, useState, useEffect } from 'react';

import DatePicker from '@christianleypoldt/react-bootstrap-date-picker';
import classNames from 'classnames';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { Security, DeliveryMonthType, ContractEntity } from '../../../../types';
import { generateContractAsync, GenerateContractRequest } from '../../../contract/store/service';
import CollapsePanel from '../../../shared/components/CollapsePanel';
import { trimTimePartFromDate } from '../../../shared/utils/date-picker-utils';
import { firstNoticeTypes } from '../../constants/first-notice-types';
import * as actionCreators from '../../store/action-creators';

import { DeliveryMonthDialog } from './DeliveryMonthDialog';

const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY';

export interface DeliveryTabProps {
  security: Security;
}

const DeliveryTab: FunctionComponent<DeliveryTabProps> = ({ security }: DeliveryTabProps) => {
  const [securityState, setSecurityState] = useState(security);
  const [testDate, setTestDate] = useState<string | undefined>();
  const [showResultDialog, setShowResultDialog] = useState(false);
  const [generatedContract, setGeneratedContract] = useState<ContractEntity | undefined>();

  const updateTestDate = (value) => {
    const dt = trimTimePartFromDate(value);
    setTestDate(dt);
  };

  useEffect(() => {
    updateTestDate(new Date());
  }, []);

  const mapFromState = (sec: Security): Security => {
    return {
      ...security,
      deliveryJan: sec.deliveryJan,
      deliveryFeb: sec.deliveryFeb,
      deliveryMar: sec.deliveryMar,
      deliveryApr: sec.deliveryApr,
      deliveryMay: sec.deliveryMay,
      deliveryJun: sec.deliveryJun,
      deliveryJul: sec.deliveryJul,
      deliveryAug: sec.deliveryAug,
      deliverySep: sec.deliverySep,
      deliveryOct: sec.deliveryOct,
      deliveryNov: sec.deliveryNov,
      deliveryDec: sec.deliveryDec,
      deliveryNotes: sec.deliveryNotes,
      firstNoticeType: sec.firstNoticeType,
      rollOverDays: sec.rollOverDays
    };
  };

  const dispatch = useDispatch();

  const updateSecurityHandler = () => {
    const item = mapFromState(securityState);
    dispatch(actionCreators.updateSecurityAsync(item));
  };

  const generateContractHandler = () => {
    const item = mapFromState(securityState);
    const dtString = trimTimePartFromDate(testDate) as string;
    const callback = (contract: ContractEntity) => {
      setGeneratedContract(contract);
      setShowResultDialog(true);
    };
    const request: GenerateContractRequest = { security: item, setupDate: dtString, isPersistant: false, callback };
    dispatch(generateContractAsync(request));
  };

  const updateSecurityState = (obj: object) => {
    const field = Object.keys(obj)[0];
    const sec = {
      ...securityState,
      [field]: obj[field]
    };
    setSecurityState(sec);
  };

  const getNextDeliveryMonthValue = function (monthType: DeliveryMonthType | undefined) {
    if (!monthType) {
      return DeliveryMonthType.Retail;
    }
    switch (monthType) {
      case DeliveryMonthType.Retail:
        return DeliveryMonthType.Commercial;
      default:
        return DeliveryMonthType.Inactive;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toggleDeliveryMonth = (obj: any) => {
    const fieldName = Object.keys(obj)[0];
    const fieldValue = obj[fieldName];
    const monthType = getNextDeliveryMonthValue(fieldValue);
    updateSecurityState({ [fieldName]: monthType });
  };

  const {
    deliveryJan,
    deliveryFeb,
    deliveryMar,
    deliveryApr,
    deliveryMay,
    deliveryJun,
    deliveryJul,
    deliveryAug,
    deliverySep,
    deliveryOct,
    deliveryNov,
    deliveryDec,
    deliveryNotes,
    firstNoticeType,
    rollOverDays
  } = securityState;

  const popoverRollOverDays = (
    <Popover id="popoverRollOverDays">
      Number of days before the notice date in which the contract is set to roll over to the next front contract.
    </Popover>
  );

  return (
    <div>
      <CollapsePanel title="Delivery Months">
        <div className="btn-toolbar gap-bottom-20" role="toolbar" aria-label="...">
          <div className="gap-bottom-10">
            <span className="label label-default gap-left-5">Inactive</span>
            <span className="label label-success gap-left-5">Retail</span>
            <span className="label label-warning gap-left-5">Commercial</span>
          </div>
          <div className="btn-group" role="group" aria-label="...">
            <button
              type="button"
              className={classNames('btn', 'ripple', 'gap-top-5', {
                'btn-default': !deliveryJan,
                'btn-success': deliveryJan === DeliveryMonthType.Retail,
                'btn-warning': deliveryJan === DeliveryMonthType.Commercial
              })}
              onClick={() => toggleDeliveryMonth({ deliveryJan })}
            >
              January
            </button>

            <button
              type="button"
              className={classNames('btn', 'ripple', 'gap-top-5', {
                'btn-default': !deliveryFeb,
                'btn-success': deliveryFeb === DeliveryMonthType.Retail,
                'btn-warning': deliveryFeb === DeliveryMonthType.Commercial
              })}
              onClick={() => toggleDeliveryMonth({ deliveryFeb })}
            >
              February
            </button>

            <button
              type="button"
              className={classNames('btn', 'ripple', 'gap-top-5', {
                'btn-default': !deliveryMar,
                'btn-success': deliveryMar === DeliveryMonthType.Retail,
                'btn-warning': deliveryMar === DeliveryMonthType.Commercial
              })}
              onClick={() => toggleDeliveryMonth({ deliveryMar })}
            >
              March
            </button>

            <button
              type="button"
              className={classNames('btn', 'ripple', 'gap-top-5', {
                'btn-default': !deliveryApr,
                'btn-success': deliveryApr === DeliveryMonthType.Retail,
                'btn-warning': deliveryApr === DeliveryMonthType.Commercial
              })}
              onClick={() => toggleDeliveryMonth({ deliveryApr })}
            >
              April
            </button>

            <button
              type="button"
              className={classNames('btn', 'ripple', 'gap-top-5', {
                'btn-default': !deliveryMay,
                'btn-success': deliveryMay === DeliveryMonthType.Retail,
                'btn-warning': deliveryMay === DeliveryMonthType.Commercial
              })}
              onClick={() => toggleDeliveryMonth({ deliveryMay })}
            >
              May
            </button>

            <button
              type="button"
              className={classNames('btn', 'ripple', 'gap-top-5', {
                'btn-default': !deliveryJun,
                'btn-success': deliveryJun === DeliveryMonthType.Retail,
                'btn-warning': deliveryJun === DeliveryMonthType.Commercial
              })}
              onClick={() => toggleDeliveryMonth({ deliveryJun })}
            >
              June
            </button>

            <button
              type="button"
              className={classNames('btn', 'ripple', 'gap-top-5', {
                'btn-default': !deliveryJul,
                'btn-success': deliveryJul === DeliveryMonthType.Retail,
                'btn-warning': deliveryJul === DeliveryMonthType.Commercial
              })}
              onClick={() => toggleDeliveryMonth({ deliveryJul })}
            >
              July
            </button>

            <button
              type="button"
              className={classNames('btn', 'ripple', 'gap-top-5', {
                'btn-default': !deliveryAug,
                'btn-success': deliveryAug === DeliveryMonthType.Retail,
                'btn-warning': deliveryAug === DeliveryMonthType.Commercial
              })}
              onClick={() => toggleDeliveryMonth({ deliveryAug })}
            >
              August
            </button>

            <button
              type="button"
              className={classNames('btn', 'ripple', 'gap-top-5', {
                'btn-default': !deliverySep,
                'btn-success': deliverySep === DeliveryMonthType.Retail,
                'btn-warning': deliverySep === DeliveryMonthType.Commercial
              })}
              onClick={() => toggleDeliveryMonth({ deliverySep })}
            >
              September
            </button>

            <button
              type="button"
              className={classNames('btn', 'ripple', 'gap-top-5', {
                'btn-default': !deliveryOct,
                'btn-success': deliveryOct === DeliveryMonthType.Retail,
                'btn-warning': deliveryOct === DeliveryMonthType.Commercial
              })}
              onClick={() => toggleDeliveryMonth({ deliveryOct })}
            >
              October
            </button>

            <button
              type="button"
              className={classNames('btn', 'ripple', 'gap-top-5', {
                'btn-default': !deliveryNov,
                'btn-success': deliveryNov === DeliveryMonthType.Retail,
                'btn-warning': deliveryNov === DeliveryMonthType.Commercial
              })}
              onClick={() => toggleDeliveryMonth({ deliveryNov })}
            >
              November
            </button>

            <button
              type="button"
              className={classNames('btn', 'ripple', 'gap-top-5', {
                'btn-default': !deliveryDec,
                'btn-success': deliveryDec === DeliveryMonthType.Retail,
                'btn-warning': deliveryDec === DeliveryMonthType.Commercial
              })}
              onClick={() => toggleDeliveryMonth({ deliveryDec })}
            >
              December
            </button>
          </div>
        </div>
        <div className="section">
          <hr />
          <p>Notes</p>
        </div>
        <textarea
          id="input_delivery_notes"
          className="form-control"
          rows={5}
          value={deliveryNotes || ''}
          onChange={(event) => updateSecurityState({ deliveryNotes: event.target.value })}
        ></textarea>
      </CollapsePanel>

      <div className="row form-horizontal">
        <div className="col-md-6">
          <CollapsePanel title="Futures Contract Settings">
            <div className="form-group form-group-sm">
              <label htmlFor="first_notice_type" className="col-sm-6 control-label">
                First Notice Type
              </label>
              <div className="col-sm-6">
                <select
                  id="first_notice_type"
                  className="form-control"
                  value={firstNoticeType}
                  onChange={(event) => updateSecurityState({ firstNoticeType: Number(event.target.value) })}
                >
                  <option value="">Velg..</option>
                  {firstNoticeTypes.map((x) => (
                    <option key={x.key} value={x.key}>
                      {x.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="roll-over-days" className="col-xs-12 col-sm-6 control-label">
                <span>Roll Over Days</span>
                <OverlayTrigger placement="top" overlay={popoverRollOverDays}>
                  <span className="gap-left-10">
                    <i className="fa fa-question-circle fa-lg text-info" />
                  </span>
                </OverlayTrigger>
              </label>
              <div className="col-sm-6">
                <input
                  id="roll-over-days"
                  type="number"
                  className="form-control"
                  value={rollOverDays}
                  onChange={(event) => updateSecurityState({ rollOverDays: Number(event.target.value) })}
                />
              </div>
            </div>

            <div className="section">
              <hr />
              <p>Test Config</p>
            </div>

            <div className="form-group form-group-sm">
              <div className="col-xs-8">
                <div className="datepicker-fix">
                  <DatePicker
                    id="test_date"
                    required
                    weekStartsOn={1}
                    value={testDate}
                    placeholder="Test Date"
                    dateFormat={DEFAULT_DATE_FORMAT}
                    onChange={updateTestDate}
                  />
                </div>
              </div>
              <div className="col-xs-4">
                <button type="button" className="btn btn-warning btn-sm btn-block" onClick={() => generateContractHandler()}>
                  <i className="glyphicon glyphicon-flash"></i>
                </button>
              </div>
            </div>
          </CollapsePanel>
        </div>
        <div className="col-md-6"></div>
      </div>
      <hr />
      <div className="row">
        <div className="col-xs-4"></div>
        <div className="col-xs-8 text-right">
          <button type="button" className="btn btn-primary ripple gap-bottom-5" onClick={updateSecurityHandler}>
            Save
          </button>
        </div>
      </div>

      {generatedContract && (
        <DeliveryMonthDialog contract={generatedContract} show={showResultDialog} close={() => setShowResultDialog(false)} />
      )}
    </div>
  );
};

export default DeliveryTab;
