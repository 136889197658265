import { ContractPartial } from '../../../../types';
import axiosApi from '../../../shared/axios-api';

export class ContractPartialHttpRequest {
  public static get(securityId: number) {
    return axiosApi.get<ContractPartial[]>(`/contractpartial/${securityId}`);
  }

  public static generate(securityId: number) {
    return axiosApi.post(`/contractpartial/generate/${securityId}`);
  }

  public static update(partial: ContractPartial) {
    return axiosApi.put(`/contractpartial/${partial.id}`, partial);
  }

  public static insert(partial: ContractPartial) {
    return axiosApi.post('/contractpartial', partial);
  }

  public static remove(id: number) {
    return axiosApi.delete(`/contractpartial/${id}`);
  }
}
