import { StrategyGroup } from '../../../types';
import { showNetworkError } from '../../shared/utils/growl-utils';

import * as actionTypes from './action-types';
import { StrategyGroupHttpRequest } from './strategy-group-http-request';

export const addStrategyGroup = (strategyGroup: StrategyGroup) => {
  return {
    type: actionTypes.ADD_STRATEGY_GROUP,
    payload: strategyGroup
  };
};

export const updateStrategyGroup = (strategyGroup: StrategyGroup) => {
  return {
    type: actionTypes.UPDATE_STRATEGY_GROUP,
    payload: strategyGroup
  };
};

export const removeStrategyGroup = (id: number) => {
  return {
    type: actionTypes.REMOVE_STRATEGY_GROUP,
    value: id
  };
};

export const setStrategyGroups = (strategyGroups: StrategyGroup[]) => {
  return {
    type: actionTypes.SET_STRATEGY_GROUPS,
    payload: strategyGroups
  };
};

export const setStrategyGroupError = (error: string) => {
  return {
    type: actionTypes.SET_STRATEGY_GROUP_ERROR,
    value: error
  };
};

export const clearStrategyGroupError = () => {
  return {
    type: actionTypes.CLEAR_STRATEGY_GROUP_ERROR
  };
};

export const setSelectedStrategyGroup = (group?: StrategyGroup) => {
  return {
    type: actionTypes.UI_SET_SELECTED_STRATEGY_GROUP,
    payload: group
  };
};

/*** ASYNC WITH THUNK ***/

export const loadStrategyGroupsAsync = () => {
  return (dispatch) => {
    StrategyGroupHttpRequest.get()
      .then((response) => {
        // console.log(response);
        dispatch(setStrategyGroups(response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setStrategyGroupError(msg));
      });
  };
};

export const addStrategyGroupAsync = (strategyGroup: StrategyGroup) => {
  return (dispatch) => {
    StrategyGroupHttpRequest.insert(strategyGroup)
      .then((response) => {
        dispatch(addStrategyGroup(response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setStrategyGroupError(msg));
      });
  };
};

export const updateStrategyGroupAsync = (strategyGroup: StrategyGroup) => {
  return (dispatch) => {
    StrategyGroupHttpRequest.update(strategyGroup)
      .then(() => {
        dispatch(updateStrategyGroup(strategyGroup));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setStrategyGroupError(msg));
      });
  };
};

export const removeStrategyGroupAsync = (strategyId: number) => {
  return (dispatch) => {
    StrategyGroupHttpRequest.remove(strategyId)
      .then(() => {
        dispatch(removeStrategyGroup(strategyId));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setStrategyGroupError(msg));
      });
  };
};
