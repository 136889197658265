import { PositionTypeOption } from '../../utils/position-type-option';

import * as actionTypes from './action-types';

export const setActiveContractsPanelCollapsed = (collapse: boolean) => {
  return {
    type: actionTypes.UI_COLLAPSE_PANEL_ACTIVE_CONTRACTS,
    value: collapse
  };
};

export const setActiveStrategiesPanelCollapsed = (collapse: boolean) => {
  return {
    type: actionTypes.UI_COLLAPSE_PANEL_ACTIVE_STRATEGIES,
    value: collapse
  };
};

export const setPeriodsPanelCollapsed = (collapse: boolean) => {
  return {
    type: actionTypes.UI_COLLAPSE_PANEL_PERIODS,
    value: collapse
  };
};

export const setBrowseIntradayCollapsed = (collapse: boolean) => {
  return {
    type: actionTypes.UI_COLLAPSE_BROWSE_INTRADAY_PANEL,
    value: collapse
  };
};

export const setBacktestSummariesPanelCollapsed = (collapse: boolean) => {
  return {
    type: actionTypes.UI_COLLAPSE_PANEL_BACKTEST_SUMMARIES,
    value: collapse
  };
};

export const setCreateIndicatorPanelCollapsed = (collapse: boolean) => {
  return {
    type: actionTypes.UI_COLLAPSE_PANEL_CREATE_INDICATOR,
    value: collapse
  };
};

export const setOpenPositionsPanelCollapsed = (collapse: boolean) => {
  return {
    type: actionTypes.UI_COLLAPSE_PANEL_OPEN_POSITIONS,
    value: collapse
  };
};

export const setClosedPositionsPanelCollapsed = (collapse: boolean) => {
  return {
    type: actionTypes.UI_COLLAPSE_PANEL_CLOSED_POSITIONS,
    value: collapse
  };
};

export const setClosedPositionsPanelFilterCollapsed = (collapse: boolean) => {
  return {
    type: actionTypes.UI_COLLAPSE_PANEL_CLOSED_POSITIONS_FILTER,
    value: collapse
  };
};

export const setContractPartialTab = (tabIndex: number) => {
  return {
    type: actionTypes.UI_CONTRACT_PARTIAL_TAB,
    value: tabIndex
  };
};

export const toggleShowRaceView = (show: boolean) => {
  return {
    type: actionTypes.UI_SET_SHOW_RACE_VIEW,
    value: show
  };
};

export const setPositionTypeOptions = (positionTypeOptions: PositionTypeOption[]) => {
  return {
    type: actionTypes.UI_SET_POS_TYPE_OPTIONS,
    payload: positionTypeOptions
  };
};
