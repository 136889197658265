import * as React from 'react';

import classNames from 'classnames';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Switch from 'react-switch';

import { CompareType, Indicator } from '../../../../types';
import { trendFollowingCompareTypes } from '../../utils/compare-utils';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const TrendFollowingForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  const maPeriodsPopover = <Popover id="maPeriodsPopover">Number of periods used for simple or exponential average</Popover>;
  const historyPeriodsPopover = <Popover id="historyPeriodsPopover">Number of periods with continuous trend in one direction</Popover>;
  const upperThresholdPopover = (
    <Popover id="upperThresholdPopover">The upper threshold of the change in number of ticks (always positive)</Popover>
  );
  const lowerThresholdPopover = (
    <Popover id="lowerThresholdPopover">The lower threshold of the change in number of ticks (always positive)</Popover>
  );

  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label htmlFor="compare_type" className="col-xs-12 col-sm-6 control-label">
          Compare Type
        </label>
        <div className="col-sm-6">
          <select
            id="compare_type"
            className="form-control"
            value={indicator.compareType}
            onChange={(event) => update({ compareType: Number(event.target.value) })}
          >
            {trendFollowingCompareTypes.map((x) => (
              <option key={x.key} value={x.key}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="tf_ma_periods" className="col-sm-6 control-label">
          <span>MA Periods</span>
          <OverlayTrigger placement="top" overlay={maPeriodsPopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg info-text" />
            </span>
          </OverlayTrigger>
        </label>
        <div className="col-sm-6">
          <input
            id="tf_ma_periods"
            type="number"
            step="1"
            value={indicator.periods}
            onChange={(event) => update({ periods: Number(event.target.value) })}
            className={classNames('form-control', {
              'inactive-control': indicator.compareType === CompareType.Past
            })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="tf_periods" className="col-sm-6 control-label">
          <span>Trend Following Periods</span>
          <OverlayTrigger placement="top" overlay={historyPeriodsPopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg info-text" />
            </span>
          </OverlayTrigger>
        </label>
        <div className="col-sm-6">
          <input
            id="tf_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.secondaryPeriods}
            onChange={(event) => update({ secondaryPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="section">
        <hr />
        <p>Options</p>
      </div>

      <div className="form-group form-group-sm">
        <label className="col-sm-6 control-label">Use Ticks Threshold</label>
        <div className="col-sm-6">
          <label className="react-switch react-switch--sm">
            <Switch
              onChange={() => update({ useTopWickThresholds: !indicator.useTopWickThresholds })}
              checked={indicator.useTopWickThresholds}
            />
          </label>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="tf_upper_threshold" className="col-sm-6 control-label">
          <span>Upper Threshold</span>
          <OverlayTrigger placement="top" overlay={upperThresholdPopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg info-text" />
            </span>
          </OverlayTrigger>
        </label>
        <div className="col-sm-6">
          <input
            id="tf_upper_threshold"
            type="number"
            step="1"
            min="0"
            value={indicator.upperThreshold}
            onChange={(event) => update({ upperThreshold: Number(event.target.value) })}
            className={classNames('form-control', {
              'inactive-control': !indicator.useTopWickThresholds
            })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="tf_lower_threshold" className="col-sm-6 control-label">
          <span>Lower Threshold</span>
          <OverlayTrigger placement="top" overlay={lowerThresholdPopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg info-text" />
            </span>
          </OverlayTrigger>
        </label>
        <div className="col-sm-6">
          <input
            id="tf_lower_threshold"
            type="number"
            step="1"
            min="0"
            value={indicator.lowerThreshold}
            onChange={(event) => update({ lowerThreshold: Number(event.target.value) })}
            className={classNames('form-control', {
              'inactive-control': !indicator.useTopWickThresholds
            })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label className="col-sm-6 control-label">Use Counter Trend</label>
        <div className="col-sm-6">
          <label className="react-switch react-switch--sm">
            <Switch onChange={() => update({ useCrossOver: !indicator.useCrossOver })} checked={indicator.useCrossOver} />
          </label>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TrendFollowingForm;
