import { Security } from '../../../types';
import axiosApi from '../../shared/axios-api';

export class SecurityHttpRequest {
  public static get() {
    return axiosApi.get<Security[]>('/security');
  }

  public static update(security: Security) {
    return axiosApi.put(`/security/${security.id}`, security);
  }

  public static insert(security: Security) {
    return axiosApi.post('/security', security);
  }

  public static remove(id: number) {
    return axiosApi.delete(`/security/${id}`);
  }
}
