/*** ASYNC WITH THUNK ***/

import { ContractEntity as Contract, ContractEntity, Security } from '../../../types';
import { showNetworkError } from '../../shared/utils/growl-utils';

import { addContract, removeContract, setContractError, setContracts, setContractsLoaded, updateContract } from './action-creators';
import { ContractHttpRequest } from './contract-http-request';

export const loadContractsAsync = () => {
  return (dispatch) => {
    ContractHttpRequest.getContracts()
      .then((response) => {
        dispatch(setContracts(response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load contracts');
        dispatch(setContractError(msg));
      });
  };
};

export const addContractAsync = (contract: Contract) => {
  return (dispatch) => {
    ContractHttpRequest.insert(contract)
      .then((response) => {
        const lazy = {
          ...response.data,
          security: { ...contract.security }
        } as Contract;
        dispatch(addContract(lazy));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setContractError(msg));
      });
  };
};

export interface GenerateContractRequest {
  security: Security;
  setupDate: string;
  isPersistant: boolean;
  callback?: (contract: ContractEntity) => void;
}

export const generateContractAsync = (request: GenerateContractRequest) => (dispatch) => {
  dispatch(setContractsLoaded(false));
  ContractHttpRequest.generate(request)
    .then((response) => {
      if (request.isPersistant) {
        const lazy = {
          ...response.data,
          security: { ...request.security }
        } as Contract;
        dispatch(addContract(lazy));
      } else {
        if (request.callback) {
          request.callback(response.data);
        }
      }
    })
    .catch((error) => {
      const msg = showNetworkError(error);
      dispatch(setContractError(msg));
    });
};

export const updateContractAsync = (contract: Contract) => {
  return (dispatch) => {
    ContractHttpRequest.update(contract)
      .then(() => {
        dispatch(updateContract(contract));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setContractError(msg));
      });
  };
};

export const removeContractAsync = (contractId: number) => {
  return (dispatch) => {
    ContractHttpRequest.remove(contractId)
      .then(() => {
        dispatch(removeContract(contractId));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setContractError(msg));
      });
  };
};
