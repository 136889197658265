import { Map } from 'immutable';

import { GlobalState } from '../../../../core/root';
import { Position } from '../../../../types';
import { PositionTypeOption } from '../../../shared/utils/position-type-option';

export interface PnlMonthList {
  positions: Position[];
  loading: boolean;
  loaded: boolean;
  error?: string;
}

export const initalPnlMonthList: PnlMonthList = {
  positions: [],
  loading: false,
  loaded: false,
  error: undefined
};

export interface PnlMonthState {
  pnlListMap: Map<string, PnlMonthList>;
  ui: {
    selectedMonth?: number;
    selectedYear?: number;
    showPositionsInPnl: boolean;
    positionTypeOptions: PositionTypeOption[];
  };
}

export const initalPnlMonthState: PnlMonthState = {
  pnlListMap: Map(),
  ui: {
    selectedYear: undefined,
    selectedMonth: undefined,
    showPositionsInPnl: false,
    positionTypeOptions: []
  }
};

export const getPnlList = (state: GlobalState, key: string): PnlMonthList | undefined => {
  return state.pnlMonthState.pnlListMap.get(key);
};

export const getPnlListError = (state: GlobalState, key: string) => {
  return getPnlList(state, key)?.error;
};

export const getPnlListLoading = (state: GlobalState, key: string) => {
  return getPnlList(state, key)?.loading;
};

export const getPnlListLoaded = (state: GlobalState, key: string) => {
  return getPnlList(state, key)?.loaded;
};

export const getPnlListItems = (state: GlobalState, key: string) => {
  return getPnlList(state, key)?.positions;
};

export const shouldFetchPnlList = (state: GlobalState, key: string): boolean => {
  const pnlList = getPnlList(state, key);
  return pnlList === undefined || !!pnlList.error;
};
