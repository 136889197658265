import * as React from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { GlobalState } from '../../../../core/root';
import { OrderType, KeepAlive, OpenOrderViewModel, OrderAction, OrderDirection } from '../../../../types';
import { formatUsd } from '../../../shared/utils/currency-utils';
import { toTime } from '../../../shared/utils/date-format-utils';

const getKeepAliveLabel = (keepAlive: KeepAlive) => {
  switch (keepAlive) {
    case KeepAlive.GoodUntilCanceled:
      return 'Until-Cancel';
    case KeepAlive.GoodUntilExpires:
      return 'Until-Expires';
    case KeepAlive.ImmediateOrCancel:
      return 'Immediate';
    default:
      return 'N/A';
  }
};

export interface Props {
  openOrders: OpenOrderViewModel[];
}

const OpenOrderTable = ({ openOrders }: Props) => {
  const state: GlobalState = useSelector((x: GlobalState) => x);
  const securities = state.securityState.securities;
  const strategies = state.strategyState.strategies;

  const header = (
    <tr>
      <th className="middle small">Security</th>
      <td className="middle small">Dir.</td>
      <td className="middle small text-center">Type</td>
      <td className="middle small text-center">Action</td>
      <td className="middle small text-center">Size</td>
      <td className="middle small text-center">Succ.</td>
      {/* <td className="middle small text-center">Broker</td> */}

      <td className="middle small">Contract</td>
      <td className="middle small">Created</td>
      <td className="middle small text-center">Keep Alive</td>
      <td className="middle small">Expires</td>

      <td className="middle small">Strategy</td>
      <td className="middle small text-right">Price</td>
      <td className="middle small text-right">Limit</td>
      <td className="middle small text-right">Stop</td>

      <td className="middle small wrap" style={{ minWidth: '150px' }}>
        Status
      </td>
    </tr>
  );

  const sort = (a: OpenOrderViewModel, b: OpenOrderViewModel) => {
    const securityA = securities.find((x) => x.id === a.contract.securityId);
    const securityB = securities.find((x) => x.id === b.contract.securityId);
    if (!securityA || !securityB) {
      return 0;
    }
    if (securityA.name > securityB.name) return 1;
    if (securityA.name < securityB.name) return -1;
    return 0;
  };

  const rows = openOrders.sort(sort).map((x: OpenOrderViewModel, i: number) => {
    const security = securities.find((y) => y.id === x.contract.securityId);
    const strategy = strategies.find((y) => y.id === x.position.strategyId);

    const size =
      x.orderSize === x.orderStateSize ? (
        <span>{x.orderSize}</span>
      ) : (
        <span>
          {x.orderSize} <span className="text-danger">({x.orderStateSize})</span>
        </span>
      );

    const netLimit = x.position.forecastLimit - x.position.sumSlippageAmount;
    const limitAmount = x.orderType === OrderType.Limit ? formatUsd(Number(netLimit), 0) : '';
    const netStopLoss = x.position.forecastStopLoss - x.position.sumSlippageAmount;
    const stopAmount = x.orderType === OrderType.Stop ? formatUsd(Number(netStopLoss), 0) : '';

    return (
      <tr key={i}>
        <th className="middle small">{security?.name}</th>
        <td className="middle small">{OrderDirection[x.orderDirection]}</td>
        <td className="middle small text-center">{OrderType[x.orderType]}</td>
        <td className="middle small text-center">{OrderAction[x.orderAction]}</td>
        <td className="middle small text-center">{size}</td>
        <td className={classNames('middle', 'small', 'text-center')}>
          <i
            className={classNames('fa', {
              'fa-check-square-o': !x.hasError,
              'text-success': !x.hasError,
              'fa-square-o': x.hasError,
              'text-danger': x.hasError
            })}
            title={x.errorMessage}
          ></i>
        </td>

        <td className="middle small vborder">{x.contract.localSymbol}</td>
        <td className="middle small">{toTime(x.orderCreated)}</td>
        <td className="middle small text-center">{getKeepAliveLabel(x.orderKeepAlive)}</td>
        <td className="middle small">{toTime(x.orderExpires)}</td>

        <td className="middle small vborder">{strategy?.name}</td>
        <td className="middle small text-right">{formatUsd(Number(x.orderPrice), 3)}</td>
        <td className="middle small text-right">
          <span
            className={classNames({
              'success-text': netLimit > 0,
              'danger-text2': netLimit < 0
            })}
          >
            {limitAmount}
          </span>
        </td>
        <td className="middle small text-right">
          <span
            className={classNames({
              'success-text': netStopLoss > 0,
              'danger-text2': netStopLoss < 0
            })}
          >
            {stopAmount}
          </span>
        </td>
        <td className="middle small vborder">{x.errorMessage}</td>
      </tr>
    );
  });

  const table = (
    <table className="table table-striped table-hover table-condensed">
      <thead>{header}</thead>
      <tbody>{rows}</tbody>
    </table>
  );

  return openOrders.length > 0 ? (
    <div className="scrolling outer">
      <div className="inner">{table}</div>
    </div>
  ) : (
    <span className="small">No open orders..</span>
  );
};

export default OpenOrderTable;
