import React, { FunctionComponent, SyntheticEvent, useState } from 'react';

import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { Backtest, BacktestStatus } from '../../../types';
import { runBacktestAsync } from '../store/backtest-manager/service';
import * as actionCreators from '../store/backtest/action-creators';

export interface BacktestButtonProps {
  securityId: number;
  strategyId: number;
  backtests: Backtest[];
  className?: string;
}

const BacktestAllButton: FunctionComponent<BacktestButtonProps> = ({
  securityId,
  strategyId,
  className,
  backtests
}: BacktestButtonProps) => {
  const [running, setRunning] = useState(false);

  const btnClasses = ['btn', 'ripple'];
  if (className) {
    btnClasses.push(className);
  }

  const dispatch = useDispatch();

  const runNext = () => {
    const backtest = backtests.find((x) => x.status === BacktestStatus.NA || x.status === BacktestStatus.Failed);
    if (!backtest) {
      setRunning(false);
      return;
    }
    runBacktest(backtest);
  };

  const runBacktest = (backtest: Backtest) => {
    backtest.status = BacktestStatus.Running;
    dispatch(actionCreators.updateBacktest(securityId, strategyId, backtest));
    dispatch(runBacktestAsync(backtest, securityId, runNext));
  };

  const runBacktestHandler = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setRunning(true);
    runNext();
  };

  return (
    <button
      className={classNames(btnClasses, {
        'bt-btn__loading': running,
        'btn-success': true
      })}
      onClick={(event: SyntheticEvent) => runBacktestHandler(event)}
      disabled={running}
    >
      <span>Run All</span>
    </button>
  );
};

export default BacktestAllButton;
