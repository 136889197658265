import React, { FunctionComponent } from 'react';
import { useState } from 'react';

import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { Security } from '../../types';
import { GlobalState } from '../root';

export interface Props {
  show: boolean;
  toggle(): void;
}

const CalculatePnlDialog: FunctionComponent<Props> = ({ show, toggle }: Props) => {
  const state: GlobalState = useSelector((s: GlobalState) => s);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const securities: Security[] = state.securityState.securities;

  const [security, setSecurity] = useState<Security | undefined>();
  const [entryPrice, setEntryPrice] = useState(0);
  const [positionSize, setPositionSize] = useState(0);
  const [highPrice, setHighPrice] = useState(0);
  const [highAmount, setHighAmount] = useState(0);
  const [lowPrice, setLowPrice] = useState(0);
  const [lowAmount, setLowAmount] = useState(0);

  const listOptions = securities
    .filter((x) => !x.archived)
    .sort((a: Security, b: Security) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    })
    .map((x) => (
      <option key={x.id} value={x.id}>
        {x.name}
      </option>
    ));

  const selectListOptionHandler = (val: string) => {
    if (!val) {
      setSecurity(undefined);
    } else {
      const sec = securities.find((x) => x.id === parseInt(val));
      setSecurity(sec);
    }
  };

  const calculateTickChange = (change: number) => {
    if (!security) {
      return 0;
    }
    // bugfix: grain- and meat-quotes are shown multiplied by 100
    change = change / security.quotePriceMultiplier;

    return Math.round(change / security.tickIncrement);
  };

  const calculateProfitAndLoss = (tickChange: number) => {
    if (!security || tickChange === 0) {
      return 0;
    }
    const tickValue = security.tickIncrement * security.multiplier;
    const profitLossPerContact = tickValue * tickChange;
    return profitLossPerContact * positionSize;
  };

  const calculateTicksFromPnl = (pnl: number) => {
    if (!security || positionSize == 0 || pnl === 0) {
      return 0;
    }
    const tickValue = security.tickIncrement * security.multiplier;
    const ticks = pnl / tickValue;
    return Math.round(ticks / positionSize);
  };

  const addTicks = (ticks: number) => {
    if (!security || entryPrice == 0 || ticks == 0) {
      return 0;
    }
    let change = security.tickIncrement * ticks;
    // bugfix: grain- and meat-quotes are multiplied by 100
    change *= security.quotePriceMultiplier;
    return entryPrice + change;
  };

  const calcHighAmountHandler = () => {
    const change = highPrice - entryPrice;
    const tickChange = calculateTickChange(change);
    const pnl = calculateProfitAndLoss(tickChange);
    setHighAmount(pnl);
  };

  const calcHighPriceHandler = () => {
    const ticks = calculateTicksFromPnl(highAmount);
    const price = addTicks(ticks);
    setHighPrice(price);
  };

  const calcLowAmountHandler = () => {
    const change = lowPrice - entryPrice;
    const tickChange = calculateTickChange(change);
    const pnl = calculateProfitAndLoss(tickChange);
    setLowAmount(pnl);
  };

  const calcLowPriceHandler = () => {
    const ticks = calculateTicksFromPnl(lowAmount);
    const price = addTicks(ticks);
    setLowPrice(price);
  };

  const popoverNegWarning = (
    <Popover id="less-then-zero" title="Warning">
      Value expected to be less than zero
    </Popover>
  );

  return (
    <Modal show={show} onHide={toggle} aria-labelledby="contained-modal-title">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title">Calculate PnL</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form name="userForm" className="form-horizontal">
          <div className="form-group form-group-sm">
            <label htmlFor="input_asset_futcode" className="col-sm-4 control-label">
              Security
            </label>
            <div className="col-sm-8">
              <select
                id="input_asset_futcode"
                className="form-control"
                value={security?.id}
                onChange={(event) => selectListOptionHandler(event.target.value)}
              >
                <option value="">Velg..</option>
                {listOptions}
              </select>
            </div>
          </div>
          <div className="form-group form-group-sm">
            <label htmlFor="calc_base_price" className="col-sm-4 control-label small">
              Entry Price
            </label>
            <div className="col-sm-8">
              <input
                id="calc_base_price"
                type="number"
                step="any"
                className="form-control"
                value={entryPrice}
                onChange={(e) => setEntryPrice(Number(e.target.value))}
              />
            </div>
          </div>
          <div className="form-group form-group-sm">
            <label htmlFor="pos_size" className="col-sm-4 control-label small">
              Position Size
            </label>
            <div className="col-sm-8">
              <input
                id="pos_size"
                type="number"
                step="any"
                className="form-control"
                value={positionSize}
                onChange={(e) => setPositionSize(Number(e.target.value))}
              />
            </div>
          </div>
          <div className="section">
            <hr />
            <p>Profit</p>
          </div>
          <div className="form-group form-group-sm">
            <label htmlFor="calc_high_amount" className="col-sm-4 control-label">
              Limit Amount
            </label>
            <div className="col-sm-8">
              <div className="input-group input-group-sm">
                <input
                  id="calc_high_amount"
                  type="number"
                  step="any"
                  className="form-control"
                  value={highAmount}
                  onChange={(e) => setHighAmount(Number(e.target.value))}
                />
                <span className="input-group-btn">
                  <button className="btn btn-default" type="button" onClick={calcHighPriceHandler}>
                    Calc
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div className="form-group form-group-sm">
            <label htmlFor="calc_high_price" className="col-sm-4 control-label">
              Limit Price
            </label>
            <div className="col-sm-8">
              <div className="input-group input-group-sm">
                <input
                  id="calc_high_price"
                  type="number"
                  step="any"
                  className="form-control"
                  value={highPrice}
                  onChange={(e) => setHighPrice(Number(e.target.value))}
                />
                <span className="input-group-btn">
                  <button className="btn btn-default" type="button" onClick={calcHighAmountHandler}>
                    Calc
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div className="section">
            <hr />
            <p>Stop Loss</p>
          </div>
          <div className="form-group form-group-sm">
            <label htmlFor="calc_low_amount" className="col-xs-12 col-sm-4 control-label">
              <span>Stop Amount</span>
              {lowAmount > 0 && (
                <OverlayTrigger placement="top" overlay={popoverNegWarning}>
                  <span className="gap-left-10">
                    <i className="fa fa-warning fa-lg warning-text" />
                  </span>
                </OverlayTrigger>
              )}
            </label>
            <div className="col-sm-8">
              <div className="input-group input-group-sm">
                <input
                  id="calc_low_amount"
                  type="number"
                  step="any"
                  className="form-control"
                  value={lowAmount}
                  onChange={(e) => setLowAmount(Number(e.target.value))}
                />
                <span className="input-group-btn">
                  <button className="btn btn-default" type="button" onClick={calcLowPriceHandler}>
                    Calc
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div className="form-group form-group-sm">
            <label htmlFor="calc_low_price" className="col-sm-4 control-label">
              Stop Price
            </label>
            <div className="col-sm-8">
              <div className="input-group input-group-sm">
                <input
                  id="calc_low_price"
                  type="number"
                  step="any"
                  className="form-control"
                  value={lowPrice}
                  onChange={(e) => setLowPrice(Number(e.target.value))}
                />
                <span className="input-group-btn">
                  <button className="btn btn-default" type="button" onClick={calcLowAmountHandler}>
                    Calc
                  </button>
                </span>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={toggle}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CalculatePnlDialog;
