import { Map } from 'immutable';

import { GlobalState } from '../../../../core/root';
import { Position } from '../../../../types';

export interface HistoryList {
  positions: Position[];
  loading: boolean;
  loaded: boolean;
  error?: string;
}

export const initalHistoryList: HistoryList = {
  positions: [],
  loading: false,
  loaded: false,
  error: undefined
};

export interface HistoryState {
  historyListMap: Map<string, HistoryList>;
  ui: {
    historyDay?: string;
  };
}

export const initalHistoryState: HistoryState = {
  historyListMap: Map(),
  ui: {
    historyDay: undefined
  }
};

export const getHistoryList = (state: GlobalState, key: string): HistoryList | undefined => {
  return state.historyState.historyListMap.get(key);
};

export const getHistoryListError = (state: GlobalState, key: string) => {
  return getHistoryList(state, key)?.error;
};

export const getHistoryListLoading = (state: GlobalState, key: string) => {
  return getHistoryList(state, key)?.loading;
};

export const getHistoryListLoaded = (state: GlobalState, key: string) => {
  return getHistoryList(state, key)?.loaded;
};

export const getHistoryListItems = (state: GlobalState, key: string) => {
  return getHistoryList(state, key)?.positions;
};

export const shouldFetchHistoryList = (state: GlobalState, key: string): boolean => {
  const pnlList = getHistoryList(state, key);
  return pnlList === undefined || !!pnlList.error;
};
