import * as React from 'react';
import { FunctionComponent } from 'react';

import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { GlobalState } from '../../../../core/root';
import { ProductType } from '../../../../types';
import * as actionCreators from '../../store/action-creators';
import { getSortedProductCategories } from '../../utils/product-category-utils';

const CategoryFilter: FunctionComponent = () => {
  const selectedProductTypes = useSelector((state: GlobalState) => state.securityState.ui.selectedProductTypes);
  const dispatch = useDispatch();

  const productTypeClickHandler = (productType: ProductType) => {
    const selected = [...selectedProductTypes];
    const index = selected.indexOf(productType);
    if (index === -1) {
      selected.push(productType);
    } else {
      selected.splice(index, 1);
    }
    dispatch(actionCreators.setSelectedProductTypes(selected));
  };

  const isActive = (productType: ProductType) => {
    return selectedProductTypes.indexOf(productType) !== -1;
  };

  const options: JSX.Element[] = getSortedProductCategories().map((x) => (
    <button
      key={x.type}
      type="button"
      className={classNames('btn', 'btn-sm', 'btn-default', 'gap-bottom-5', { active: isActive(x.type) })}
      onClick={() => productTypeClickHandler(x.type)}
    >
      {x.name}
    </button>
  ));

  return <div className="btn-toolbar">{options}</div>;
};

export default CategoryFilter;
