import { Position } from '../../../../types';
import { showNetworkError } from '../../../shared/utils/growl-utils';

import { setPositionOrders, setPositionOrdersLoaded, setPositionOrdersError, addPositionOrder } from './action-creators';
import { PositionOrderHttpRequest } from './http-request';

export const fetchPositionOrdersAsync = (positionId: number) => {
  return (dispatch) => {
    dispatch(setPositionOrders(positionId, []));
    dispatch(setPositionOrdersLoaded(positionId, false));
    PositionOrderHttpRequest.getPositionOrders(positionId)
      .then((response) => {
        dispatch(setPositionOrders(positionId, response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load position orders');
        dispatch(setPositionOrdersError(positionId, msg));
      });
  };
};

export const placeLimitOrderAsync = (position: Position) => {
  return (dispatch) => {
    dispatch(setPositionOrdersLoaded(position.id, false));
    PositionOrderHttpRequest.placeLimitOrder(position)
      .then((response) => {
        dispatch(addPositionOrder(position.id, response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to place limit order');
        dispatch(setPositionOrdersError(position.id, msg));
      });
  };
};

export const placeStopOrderAsync = (position: Position) => {
  return (dispatch) => {
    dispatch(setPositionOrdersLoaded(position.id, false));
    PositionOrderHttpRequest.placeStopOrder(position)
      .then((response) => {
        dispatch(addPositionOrder(position.id, response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to place stop order');
        dispatch(setPositionOrdersError(position.id, msg));
      });
  };
};

export const placeMarketOrderAsync = (position: Position) => {
  return (dispatch) => {
    dispatch(setPositionOrdersLoaded(position.id, false));
    PositionOrderHttpRequest.placeMarketOrder(position)
      .then((response) => {
        dispatch(addPositionOrder(position.id, response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to place market order');
        dispatch(setPositionOrdersError(position.id, msg));
      });
  };
};
