import { showNetworkError } from '../../../shared/utils/growl-utils';

import { setPositionTrades, setPositionTradesLoaded, setPositionTradesError } from './action-creators';
import { PositionTradeHttpRequest } from './http-request';

export const fetchPositionTradesAsync = (positionId: number) => {
  return (dispatch) => {
    dispatch(setPositionTrades(positionId, []));
    dispatch(setPositionTradesLoaded(positionId, false));
    PositionTradeHttpRequest.getPositionTrades(positionId)
      .then((response) => {
        dispatch(setPositionTrades(positionId, response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load position trades');
        dispatch(setPositionTradesError(positionId, msg));
      });
  };
};
