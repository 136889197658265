import * as React from 'react';
import { useEffect, useState } from 'react';

import { VictoryBar, VictoryLine } from 'victory';

import { BarSize, GraphQuote } from '../../../../types';

import BaseGraph from './BaseGraph';
import { ChartLine, barColors, mapToVolumeAvg, mapToVolumeData, VolumeBar, lineColors } from './graph-utils';

export interface VolumeGraphProps {
  showVolumeGraph: boolean;
  showVolumeAvg: boolean;
  barSize: BarSize;
  graphData: GraphQuote[];
  expanded?: boolean;
}

const VolumeGraph = ({ showVolumeGraph, showVolumeAvg, barSize, graphData, expanded }: VolumeGraphProps) => {
  const [volumeData, setVolumeData] = useState<VolumeBar[]>([]);
  const [volumeAvgData, setVolumeAvgData] = useState<ChartLine[]>([]);

  useEffect(() => {
    if (graphData && showVolumeGraph) {
      setVolumeData(mapToVolumeData(graphData));
    }
  }, [graphData, showVolumeGraph]);

  useEffect(() => {
    if (graphData && showVolumeGraph && showVolumeAvg) {
      setVolumeAvgData(mapToVolumeAvg(graphData));
    }
  }, [graphData, showVolumeAvg]);

  if (!graphData || !showVolumeGraph) {
    return null;
  }

  const strokeWidth = expanded ? 0.5 : 1;

  return (
    <BaseGraph barSize={barSize} title="Volume" height={125} expanded={expanded}>
      <VictoryBar
        style={{
          data: {
            fill: ({ datum }) => (datum.direction > 0 ? barColors.positive : datum.direction < 0 ? barColors.negative : '#ccc'),
            stroke: 'black',
            strokeWidth
          }
        }}
        data={volumeData}
      />

      {showVolumeAvg && (
        <VictoryLine
          style={{
            data: { stroke: lineColors.volumeAvg, strokeWidth }
          }}
          data={volumeAvgData}
        />
      )}
    </BaseGraph>
  );
};

export default VolumeGraph;
