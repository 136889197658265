import { IndicatorType, IndicatorCategory } from '../../../../types';
import { IndicatorOption } from '../../models/indicator-option';

export const indicatorOptions: IndicatorOption[] = [
  {
    name: 'Average Directional Index',
    type: IndicatorType.Adx,
    category: IndicatorCategory.Volatility,
    tooltip: 'Average Directional Index (ADX) is plotted as a single line with values ranging from a low of zero to a high of 100.'
  },
  {
    name: 'Compare ATR',
    type: IndicatorType.AverageTrueRange,
    category: IndicatorCategory.Momentum,
    tooltip: 'Compares Average True Range (ATR) moving averages.'
  },
  { name: 'Bollinger Band', type: IndicatorType.BollingerBand, category: IndicatorCategory.Volatility },
  { name: 'Shift', type: IndicatorType.Shift, category: IndicatorCategory.Volatility },
  {
    name: 'Tick Volatility',
    type: IndicatorType.Tick,
    category: IndicatorCategory.Volatility,
    tooltip: 'Not range bound. Number of ticks in latest Average True Range (ATR).'
  },
  {
    name: 'Squeeze',
    type: IndicatorType.Squeeze,
    category: IndicatorCategory.Volatility,
    tooltip: 'Market is considered to have low volatility if Bollinger Bands are within Keltner Channels.'
  },
  {
    name: 'Compare',
    type: IndicatorType.Compare,
    category: IndicatorCategory.Trend,
    tooltip: 'Compare prices with past prices or averages.'
  },
  {
    name: 'Momentum',
    type: IndicatorType.AvgUpDown,
    category: IndicatorCategory.Momentum,
    tooltip:
      'Aka. average-up versus average-down. Simular to Relative Strength Index which is based on Close-Price. Range bound from 0 to 100; 100 being max momentum with zero periods in opposite direction.'
  },
  {
    name: 'Flat Position PnL',
    type: IndicatorType.AmountUpDown,
    category: IndicatorCategory.Momentum,
    tooltip:
      'The amount gained or lossed with a position of a single contract. The amount must be within the thresholds to be valid. Note: Use negative values with long or positive values with short for counter-trend.'
  },
  {
    name: 'Range',
    type: IndicatorType.Range,
    category: IndicatorCategory.Momentum,
    tooltip: 'Range bound from 0 to 100; 100 being the absolute top within the range for the given periods of time.'
  },
  {
    name: 'Breakout',
    type: IndicatorType.BreakOut,
    category: IndicatorCategory.Trend,
    tooltip: 'A breakout happens when current price is above previous high og below previous low.'
  },
  {
    name: 'Pullback',
    type: IndicatorType.Pullback,
    category: IndicatorCategory.Trend,
    tooltip:
      'A pullback happens when 1st EMA crossover the 2nd EMA and the crossover point is in the direction of the trend, compared to the previous crossover.'
  },
  { name: 'MACD', type: IndicatorType.MACD, category: IndicatorCategory.Trend },
  { name: 'Awesome Oscillator', type: IndicatorType.AwesomeOscillator, category: IndicatorCategory.Momentum },
  {
    name: 'Opening Hour',
    type: IndicatorType.OpeningHour,
    category: IndicatorCategory.Time,
    tooltip: 'Trades based on 5 min bar-size withing opening hour.'
  },
  { name: 'Donchian Channels', type: IndicatorType.DonchianChannels, category: IndicatorCategory.Trend },
  { name: 'Keltner Channels', type: IndicatorType.KeltnerChannels, category: IndicatorCategory.Volatility },
  {
    name: 'Trend Score',
    type: IndicatorType.TrendScore,
    category: IndicatorCategory.Trend,
    tooltip:
      'Calculates score by comparing high/low with previous high/low. Note: A bull trending score is positive and a bear trending score is negative.'
  },
  {
    name: 'Trend Following',
    type: IndicatorType.TrendFollowing,
    category: IndicatorCategory.Trend,
    tooltip: 'Entries and exits are based on continuous moving-average-change in one direction.'
  },
  {
    name: 'Trend Following ATR',
    type: IndicatorType.TrendFollowingAtr,
    category: IndicatorCategory.Momentum,
    tooltip: 'Entries and exits are based on continuous ATR value increasing or decreasing.'
  },
  {
    name: 'Seasonal',
    type: IndicatorType.Season,
    category: IndicatorCategory.Trend,
    tooltip: 'Entries and exits are based on seasonal trends. Configure seasonal trends in security settings.'
  },
  {
    name: 'Trading Hours',
    type: IndicatorType.TradingHours,
    category: IndicatorCategory.Time,
    tooltip: 'Entries and exits are enabled at spesific hours of the day.'
  },
  {
    name: 'Trading Days',
    type: IndicatorType.TradingWeekdays,
    category: IndicatorCategory.Time,
    tooltip: 'Entries and exits are enabled at spesific days of week.'
  },
  {
    name: 'Leech',
    type: IndicatorType.Leech,
    category: IndicatorCategory.Trend,
    tooltip: 'Trend following based on previous position PnL'
  },
  {
    name: 'Continuous MA',
    type: IndicatorType.ContinuousMa,
    category: IndicatorCategory.Trend,
    tooltip: 'Continuous periods were the MA is above or below threshold-MA'
  },
  {
    name: 'The Fox',
    type: IndicatorType.TheFox,
    category: IndicatorCategory.Trend,
    tooltip: ''
  },
  {
    name: 'Thunnel',
    type: IndicatorType.Thunnel,
    category: IndicatorCategory.Trend,
    tooltip: 'The indicator triggers if the price-action is breaking out of a thunnel.'
  },
  {
    name: 'High/Low Sequence',
    type: IndicatorType.HighLowSeq,
    category: IndicatorCategory.Trend,
    tooltip:
      'The indicator triggers on buy if the low-price if conistently highter and triggers on sell if the high-price is consistently lower.'
  }
];

export const getIndicatorOptionName = (type: IndicatorType) => {
  const item = indicatorOptions.find((x) => x.type === type);
  return item ? item.name : '';
};

export const getIndicatorOptionTooltip = (type: IndicatorType) => {
  const item = indicatorOptions.find((x) => x.type === type);
  return item ? item.tooltip : '';
};
