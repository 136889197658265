import { AnyAction } from 'redux';

import * as actionTypes from './action-types';
import { initalPnlStrategyMapState, initalPnlStrategyState } from './state';

export const pnlStrategyReducer = (state = initalPnlStrategyMapState, action: AnyAction) => {
  if (action.type === actionTypes.SET_PNL_STRATEGY_LIST_LOADING) {
    const key = action.payload.key;
    const current = state.get(key) || { ...initalPnlStrategyState };
    return state.set(key, {
      ...current,
      loading: action.payload.loading
    });
  } else if (action.type === actionTypes.SET_PNL_STRATEGY_LIST) {
    const key = action.payload.key;
    return state.set(key, {
      loading: false,
      loaded: true,
      error: undefined,
      summaries: action.payload.summaries
    });
  } else if (action.type === actionTypes.SET_PNL_STRATEGY_LIST_ERROR) {
    const key = action.payload.key;
    const current = state.get(key) || { ...initalPnlStrategyState };
    return state.set(key, {
      ...current,
      loading: false,
      error: action.payload.error
    });
  }

  return state;
};
