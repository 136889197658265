import * as React from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { GlobalState } from '../../../core/root';
import { Order, OrderDirection, OrderType, OrderAction, KeepAlive } from '../../../types';
import { formatUsd } from '../../shared/utils/currency-utils';
import { toTime } from '../../shared/utils/date-format-utils';
import { countDecimals } from '../../shared/utils/number-utils';

export interface OrderTableProps {
  securityId: number;
  orders: Order[];
}

const OrderTable = ({ securityId, orders }: OrderTableProps) => {
  const state: GlobalState = useSelector((gs: GlobalState) => gs);
  const securities = state.securityState.securities;
  const security = securities.find((x) => x.id === securityId);
  const tickIncrement = security?.tickIncrement || 0;
  const decimalPlaces = countDecimals(tickIncrement);

  const header = (
    <tr>
      <th className="middle small">Dir.</th>
      <th className="middle small text-center">Type</th>
      <th className="middle small text-center">Cmpn.</th>
      <th className="middle small text-center">Action</th>
      <th className="middle small text-right">Size</th>
      {/* <th className="middle small text-right">Margin</th> */}
      <th className="middle small text-right">Price</th>
      <th className="middle small text-right">PnL</th>

      <th className="middle small">Created</th>
      <th className="middle small">Keep Alive</th>
      <th className="middle small">Expires</th>
      <th className="middle small text-center">Canceled</th>
      <th className="middle small text-center">Error</th>

      <th className="middle small text-center" style={{ minWidth: '150px' }}>
        Info
      </th>
    </tr>
  );

  const rows = orders.map((x) => {
    let keepAliveValue = '';
    switch (x.keepAlive) {
      case KeepAlive.GoodUntilCanceled:
        keepAliveValue = 'Good Until Canceled';
        break;
      case KeepAlive.GoodUntilExpires:
        keepAliveValue = 'Good Until Expires';
        break;
      case KeepAlive.ImmediateOrCancel:
        keepAliveValue = 'Immediate Or Cancel';
        break;
    }

    let price = 0;
    let pnl = 0;
    switch (x.type) {
      case OrderType.Stop:
        price = x.stopLossPrice;
        pnl = x.position.movingStopLoss - x.position.sumSlippageAmount;
        break;
      case OrderType.Limit:
        price = x.limitPrice;
        pnl = x.position.forecastLimit - x.position.sumSlippageAmount;
        break;
      default:
        price = x.targetPrice;
    }

    const pnlLabel =
      pnl !== 0 ? (
        <span
          className={classNames({
            'success-text': pnl > 0,
            'danger-text2': pnl < 0
          })}
        >
          {formatUsd(pnl, 0)}
        </span>
      ) : (
        '-'
      );

    return (
      <tr key={x.id}>
        <td className="middle small">{OrderDirection[x.direction]}</td>
        <td className="middle small text-center">{OrderType[x.type]}</td>
        <td className="middle small text-center">
          <i className={classNames('fa', { 'fa-square-o': !x.isCompanionOrder, 'fa-check-square-o': x.isCompanionOrder })}></i>
        </td>
        <td className="middle small text-center">{OrderAction[x.action]}</td>
        <td className="middle small text-right">{x.size}</td>
        {/* <td className="middle small text-right">{formatUsd(Number(x.maintenanceMargin), 0)}</td> */}
        <td className="middle small text-right">{formatUsd(Number(price), decimalPlaces)}</td>
        <td className="middle small text-right">{pnlLabel}</td>

        <td className="middle small vborder">{toTime(x.created)}</td>
        <td className="middle small">{keepAliveValue}</td>
        <td className="middle small">{toTime(x.expires) || '-'}</td>
        <td className="middle small text-center">
          <i className={classNames('fa', { 'fa-square-o': !x.canceled, 'fa-check-square-o': x.canceled })}></i>
        </td>
        <td className="middle small text-center">
          <i className={classNames('fa', { 'fa-square-o': !x.hasError, 'fa-check-square-o': x.hasError })}></i>
        </td>

        <td className="middle small vborder">
          {x.cancelReason && <span className="gap-right-5">{x.cancelReason}</span>}
          {x.errorMessage && <span className="gap-right-5">{x.errorMessage}</span>}
        </td>
      </tr>
    );
  });

  return (
    <div className="table-responsive gap-top-10">
      <table className="table table-striped table-hover table-condensed">
        <thead>{header}</thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

export default OrderTable;
