import * as React from 'react';
import { FunctionComponent } from 'react';

import { VictoryChart, VictoryAxis, VictoryTheme } from 'victory';

import { BarSize } from '../../../../types';

import { formatAxisDate } from './graph-utils';

export interface BaseGraphProps {
  barSize: BarSize;
  height: number;
  min?: number;
  max?: number;
  title?: string;
  expanded?: boolean;
}

const BaseGraph: FunctionComponent<BaseGraphProps> = ({ height, barSize, min, max, title, expanded, children }) => {
  const padding = { top: 10, right: 20, bottom: 40, left: 50 };

  const yAxisRangeExists = min !== undefined && max !== undefined;
  const yAxisRange = yAxisRangeExists ? { y: [min, max] as [number, number] } : undefined;
  const fontSize = expanded ? 5 : 8;
  const fixedHeight = expanded ? height * 0.8 : height;

  return (
    <div>
      {title && (
        <div className="text-center">
          <span className="graph__title">{title}</span>
        </div>
      )}
      <VictoryChart
        padding={padding}
        height={fixedHeight}
        theme={VictoryTheme.material}
        domain={yAxisRange}
        domainPadding={{ x: [5, 0] }}
        scale={{ x: 'time' }}
      >
        <VictoryAxis
          style={{
            grid: { stroke: '#718096', strokeDasharray: '2 10' },
            tickLabels: { fontSize }
          }}
          tickFormat={(t) => formatAxisDate(barSize, t)}
        />
        <VictoryAxis
          style={{
            grid: { stroke: '#718096', strokeDasharray: '2 10' },
            tickLabels: { fontSize }
          }}
          dependentAxis
        />
        {children}
      </VictoryChart>
    </div>
  );
};

export default BaseGraph;
