import * as React from 'react';

import { Switch, Route } from 'react-router-dom';

import GraphScreen from './components/GraphScreen';

export const graphModulePath = '/graphs';

const render = () => {
  return (
    <Switch>
      <Route exact path={graphModulePath} component={GraphScreen}></Route>
    </Switch>
  );
};

export default {
  routeProps: {
    path: graphModulePath,
    render
  },
  name: 'Graph'
};
