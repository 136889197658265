import * as React from 'react';

import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Subscription, PeriodType } from '../../../types';
import { formatNok, formatUsd } from '../../shared/utils/currency-utils';
import { getExpireInDays } from '../../shared/utils/date-utils';
import { getDeliveryMonthName, getDeliveryMonthTypeByCode } from '../../shared/utils/delivery-month';
import { subscriptionModulePath } from '../routes';
import { sortSubscriptions } from '../utils/sort-subscriptions';

export interface SubscriptionListProps extends RouteComponentProps {
  subscriptions: Subscription[];
}

const SubscriptionList: React.FunctionComponent<SubscriptionListProps> = ({ subscriptions, history }: SubscriptionListProps) => {
  const rowClickHandler = (sub: Subscription) => {
    const route = `${subscriptionModulePath}/${sub.id}`;
    history.push(route);
  };

  const header = (
    <tr>
      <th className="middle small">Subscription</th>
      <td className="middle small">Strategy</td>
      <td className="middle small text-center">Pri</td>
      <td className="middle small text-center">Activation</td>

      <td className="middle small">Security</td>
      <td className="middle small">Contract</td>
      <td className="middle small no-wrap">Delivery</td>
      <td className="middle small no-wrap">Type</td>
      <td className="middle small no-wrap text-center">Expires</td>

      <td className="middle small text-center">Size</td>
      <td className="middle small text-right">Comm.</td>
      <td className="middle small text-right">Margin</td>
    </tr>
  );

  const rows = subscriptions.sort(sortSubscriptions).map((sub) => {
    const { contract, security, strategy } = sub;

    const activeClasses = ['fa clickable'];
    if (sub.active) {
      activeClasses.push('fa-check-square-o');
    } else {
      activeClasses.push('fa-square-o');
    }

    //const expireClass = sub.active && getExpireInDays(new Date(), contract.firstNoticeDate) === 0 ? 'danger-text2' : undefined;

    let activation = 'Inactive';
    if (sub.active) {
      if (sub.suspendEntries) {
        activation = 'Suspend Entries';
      } else if (sub.simulated) {
        activation = 'Simulate';
      } else {
        activation = PeriodType[PeriodType.Live];
      }
    } else {
      activation = 'Inactive';
    }

    const securityTooltip = <Tooltip id="securityTooltip">{security?.name}</Tooltip>;

    const expireInDays = getExpireInDays(new Date(), contract?.rolloverDate);

    const pri = sub.simulated || sub.priority === 0 ? '-' : sub.priority;

    return (
      <tr key={sub.id} className="clickable" onClick={() => rowClickHandler(sub)}>
        <th className="middle small">
          <OverlayTrigger placement="top" overlay={securityTooltip}>
            <span>{sub.alias || security?.name || 'N/A'}</span>
          </OverlayTrigger>
        </th>

        <td className="middle small">{strategy?.name}</td>
        <td className="middle small text-center">{pri}</td>
        <td
          className={classNames('middle', 'small', 'text-center', {
            'text-success': activation === PeriodType[PeriodType.Live] && sub.checkConsistency,
            'danger-text2': activation === PeriodType[PeriodType.Live] && !sub.checkConsistency,
            grey: activation === 'Inactive'
          })}
        >
          {activation}
        </td>

        <td className="middle small vborder">{security?.name}</td>
        <td className="middle small">{contract?.localSymbol}</td>
        <td className="middle small">{getDeliveryMonthName(contract?.monthCode)}</td>
        <td className="middle small">{getDeliveryMonthTypeByCode(contract?.monthCode, security)}</td>
        {/* <td className="middle small"><span className={expireClass}>{toShortMonth(contract?.rolloverDate)}</span></td> */}
        <td className={classNames('middle small text-center', { 'danger-text2': expireInDays <= 0 })}>
          <span title="Expires in days">{expireInDays}</span>
        </td>

        <td className="middle small text-center vborder">{sub.orderSize}</td>
        <td className="middle small text-right">{formatUsd(sub.commission, 1)}</td>
        <td className="middle small text-right">{formatNok(sub.initialMargin, 0)}</td>
      </tr>
    );
  });

  return (
    <React.Fragment>
      {rows.length > 0 ? (
        <div className="scrolling outer">
          <div className="inner">
            <table className="table table-hover table-striped table-condensed">
              <thead>{header}</thead>
              <tbody>{rows}</tbody>
            </table>
          </div>
        </div>
      ) : (
        <p className="small">No subscriptions..</p>
      )}
    </React.Fragment>
  );
};

export default withRouter(SubscriptionList);
