import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';

import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { GlobalState } from '../../../../core/root';
import { Position } from '../../../../types';
import { loadSecuritiesAsync } from '../../../security/store/action-creators';
import Spinner from '../../../shared/components/Spinner';
import { toggleShowRaceView } from '../../../shared/store/layout/action-creators';
import { loadAccountInfoAsync } from '../../../status/store/account-info/service';
import { loadStrategiesAsync } from '../../../strategy/store/services';
import { getOpenOrdersAsync } from '../../store/open-order/service';
import { fetchOpenPositionsAsync } from '../../store/open-position/service';
import OpenPositionTable from '../OpenPositionTable';
import RaceList from '../RaceList';

import OpenOrderPanel from './OpenOrderPanel';

const OpenPositionListPanel: FunctionComponent = () => {
  const state: GlobalState = useSelector((x: GlobalState) => x);
  const securitiesLoaded: boolean = state.securityState.loaded;
  const strategiesLoaded: boolean = state.strategyState.loaded;
  const openPositionsLoaded: boolean = state.openPositionState.loaded;
  const openPositions: Position[] = state.openPositionState.positions;
  const showRaceView = state.layoutState.ui.showRaceView;
  const accountInfoLoaded: boolean = state.accountInfoState.loaded;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!securitiesLoaded) {
      dispatch(loadSecuritiesAsync());
    }
    if (!strategiesLoaded) {
      dispatch(loadStrategiesAsync());
    }
    // dispatch(fetchOpenPositionsAsync());
  }, []);

  const toggleRaceView = () => {
    dispatch(toggleShowRaceView(!showRaceView));
  };

  const refreshOpenOrdersHandler = () => {
    dispatch(loadAccountInfoAsync());
    dispatch(getOpenOrdersAsync());
  };

  useEffect(() => {
    if (accountInfoLoaded) {
      dispatch(fetchOpenPositionsAsync());
    }
  }, [accountInfoLoaded]);

  const loading = !securitiesLoaded || !securitiesLoaded || !openPositionsLoaded;

  if (loading) {
    return <Spinner />;
  }

  const sort = (a: Position, b: Position) => {
    if (!a.security || !b.security) return 0;
    return a.security.name > b.security.name ? 1 : -1;
  };

  const sortedPositions = openPositions.sort(sort);

  return (
    <div>
      <div className="section">
        {/* <hr /> */}
        <p>Open Positions</p>
      </div>

      {showRaceView ? <RaceList positions={sortedPositions} /> : <OpenPositionTable positions={sortedPositions} />}

      <div className="gap-top-30">
        <OpenOrderPanel />
      </div>

      <div className="row gap-top-20">
        <div className="col-xs-12">
          <div className="btn-toolbar" style={{ justifyContent: 'center', display: 'flex' }}>
            <button
              className={classNames('btn', 'btn-default', { active: !showRaceView })}
              type="button"
              onClick={toggleRaceView}
              title="Grid View"
            >
              <span>
                <i className="fa fa-table" />
              </span>
            </button>
            <button className="btn btn-default ripple" type="button" onClick={refreshOpenOrdersHandler}>
              <i className="fa fa-refresh" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenPositionListPanel;
