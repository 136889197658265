import * as React from 'react';

import { OverlayTrigger, Popover } from 'react-bootstrap';
import Switch from 'react-switch';

import { Indicator } from '../../../../types';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const ShiftForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  const numShiftBarsTooltipPopover = (
    <Popover id="numShiftBarsTooltipPopover">
      <span>
        Number of overlapping bars, with the same direction, within the shift. Default size: <strong>1</strong>
      </span>
    </Popover>
  );

  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label htmlFor="num_shift_bars" className="col-sm-6 control-label">
          <span>Shift Bars</span>
          <OverlayTrigger placement="top" overlay={numShiftBarsTooltipPopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg text-info" />
            </span>
          </OverlayTrigger>
        </label>
        <div className="col-sm-6">
          <input
            id="num_shift_bars"
            type="number"
            step="1"
            className="form-control"
            value={indicator.numShiftBars}
            onChange={(event) => update({ numShiftBars: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="shift_atr_periods" className="col-sm-6 control-label">
          ATR Periods (d:14)
        </label>
        <div className="col-sm-6">
          <input
            id="shift_atr_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.atrPeriods}
            onChange={(event) => update({ atrPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="shift_bars_pct" className="col-sm-6 control-label">
          <span>Bar Pct of ATR (d: 200)</span>
        </label>
        <div className="col-sm-6">
          <input
            id="shift_bars_pct"
            type="number"
            step="1"
            className="form-control"
            value={indicator.shiftBarsPct}
            onChange={(event) => update({ shiftBarsPct: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="shift_bars_body_pct" className="col-sm-6 control-label">
          <span>Bar Body Pct (d: 80)</span>
        </label>
        <div className="col-sm-6">
          <input
            id="shift_bars_body_pct"
            type="number"
            step="1"
            className="form-control"
            value={indicator.shiftBarsBodyPct}
            onChange={(event) => update({ shiftBarsBodyPct: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="section">
        <hr />
        <p>Intraday Loss</p>
      </div>

      <div className="form-group form-group-sm">
        <label className="col-sm-6 control-label">Increase Bar Pct On Loss</label>
        <div className="col-sm-6">
          <label className="react-switch react-switch--sm">
            <Switch
              onChange={() => update({ useIncreaseShiftOnIntradayLoss: !indicator.useIncreaseShiftOnIntradayLoss })}
              checked={indicator.useIncreaseShiftOnIntradayLoss}
            />
          </label>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="shift_bar_increased_pct" className="col-sm-6 control-label">
          <span>Increased Bar Pct</span>
        </label>
        <div className="col-sm-6">
          <input
            id="shift_bar_increased_pct"
            type="number"
            step="1"
            className="form-control"
            value={indicator.increasedShiftPct}
            onChange={(event) => update({ increasedShiftPct: Number(event.target.value) })}
            disabled={!indicator.useIncreaseShiftOnIntradayLoss}
          />
        </div>
      </div>

      <div className="section">
        <hr />
        <p>Wick Thresholds</p>
      </div>

      <div className="form-group form-group-sm">
        <label className="col-sm-6 control-label">Use Top-Wick Thresholds</label>
        <div className="col-sm-6">
          <label className="react-switch react-switch--sm">
            <Switch
              onChange={() => update({ useTopWickThresholds: !indicator.useTopWickThresholds })}
              checked={indicator.useTopWickThresholds}
            />
          </label>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="shift_top_wick_max_pct" className="col-sm-6 control-label">
          <span>Top-Wick Max Pct</span>
        </label>
        <div className="col-sm-6">
          <input
            id="shift_top_wick_max_pct"
            type="number"
            step="1"
            className="form-control"
            value={indicator.topWickMaxPct}
            onChange={(event) => update({ topWickMaxPct: Number(event.target.value) })}
            disabled={!indicator.useTopWickThresholds}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="shift_top_wick_min_pct" className="col-sm-6 control-label">
          <span>Top-Wick Min Pct</span>
        </label>
        <div className="col-sm-6">
          <input
            id="shift_top_wick_min_pct"
            type="number"
            step="1"
            className="form-control"
            value={indicator.topWickMinPct}
            onChange={(event) => update({ topWickMinPct: Number(event.target.value) })}
            disabled={!indicator.useTopWickThresholds}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label className="col-sm-6 control-label">Use Bottom-Wick Thresholds</label>
        <div className="col-sm-6">
          <label className="react-switch react-switch--sm">
            <Switch
              onChange={() => update({ useBottomWickThresholds: !indicator.useBottomWickThresholds })}
              checked={indicator.useBottomWickThresholds}
            />
          </label>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="shift_bottom_wick_max_pct" className="col-sm-6 control-label">
          <span>Bottom-Wick Max Pct</span>
        </label>
        <div className="col-sm-6">
          <input
            id="shift_bottom_wick_max_pct"
            type="number"
            step="1"
            className="form-control"
            value={indicator.bottomWickMaxPct}
            onChange={(event) => update({ bottomWickMaxPct: Number(event.target.value) })}
            disabled={!indicator.useBottomWickThresholds}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="shift_bottom_wick_min_pct" className="col-sm-6 control-label">
          <span>Bottom-Wick Min Pct</span>
        </label>
        <div className="col-sm-6">
          <input
            id="shift_bottom_wick_min_pct"
            type="number"
            step="1"
            className="form-control"
            value={indicator.bottomWickMinPct}
            onChange={(event) => update({ bottomWickMinPct: Number(event.target.value) })}
            disabled={!indicator.useBottomWickThresholds}
          />
        </div>
      </div>

      <div className="section">
        <hr />
        <p>Bounce Back Protection</p>
      </div>

      <div className="form-group form-group-sm">
        <label className="col-sm-6 control-label">Use Bounce Back Protection</label>
        <div className="col-sm-6">
          <label className="react-switch react-switch--sm">
            <Switch
              onChange={() => update({ useBounceProtection: !indicator.useBounceProtection })}
              checked={indicator.useBounceProtection}
            />
          </label>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="shift_bounce_prds" className="col-sm-6 control-label">
          <span>History Periods</span>
        </label>
        <div className="col-sm-6">
          <input
            id="shift_bounce_prds"
            type="number"
            step="1"
            className="form-control"
            value={indicator.bounceProtectionPeriods}
            onChange={(event) => update({ bounceProtectionPeriods: Number(event.target.value) })}
            disabled={!indicator.useBounceProtection}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ShiftForm;
