import React, { FunctionComponent, useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { GlobalState } from '../../../core/root';
import { ContractEntity, Security } from '../../../types';
import CategoryFilterPanel from '../../security/components/CategoryFilterPanel';
import SecurityPickerPanel from '../../security/components/SecurityPickerPanel';
import * as securityActionCreators from '../../security/store/action-creators';
import Spinner from '../../shared/components/Spinner';
import { loadContractsAsync } from '../store/service';

import ContractList from './ContractList';

const ContractListScreen: FunctionComponent = () => {
  const state = useSelector((gs: GlobalState) => gs);
  const contractsLoaded: boolean = state.contractState.loaded;
  const contracts: ContractEntity[] = state.contractState.contracts;
  const securitiesLoaded: boolean = state.securityState.loaded;
  const selectedSecurity: Security | undefined = state.securityState.ui.securityPicked;

  const [selectedContracts, setSelectedContracts] = useState<ContractEntity[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!securitiesLoaded) {
      dispatch(securityActionCreators.loadSecuritiesAsync());
    }
    if (!contractsLoaded) {
      dispatch(loadContractsAsync());
    }
  }, []);

  useEffect(() => {
    if (!selectedSecurity) {
      setSelectedContracts([]);
    } else {
      const selectedContracts = contracts.filter((x) => x.securityId === selectedSecurity.id);
      setSelectedContracts(selectedContracts);
    }
  }, [selectedSecurity, contracts]);

  const loading = !contractsLoaded || !securitiesLoaded;

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item active">Contracts</li>
      </ol>

      <div className="page-header">
        <h3>
          Contracts <small className="no-wrap"></small>
        </h3>
      </div>

      {loading && <Spinner />}

      <SecurityPickerPanel>
        <CategoryFilterPanel />
      </SecurityPickerPanel>

      <div className="section">
        <hr />
        <p>Contracts</p>
      </div>

      {selectedSecurity ? (
        <ContractList security={selectedSecurity} contracts={selectedContracts} />
      ) : (
        <span className="small">No security selected..</span>
      )}
    </div>
  );
};

export default ContractListScreen;
