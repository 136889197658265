import { ThunkDispatch } from 'redux-thunk';

import { GlobalState } from '../../../../core/root';
import { AccountAction } from '../../../account/store/action-creators';

import { setAccountInfo, setAccountInfoError, setAccountInfoLoaded } from './action-creators';
import { AccountInfoHttpRequest } from './http-request';

/*** ASYNC WITH THUNK ***/

export const loadAccountInfoAsync = () => {
  return (dispatch: ThunkDispatch<GlobalState, void, AccountAction>) => {
    dispatch(setAccountInfoLoaded(false));
    AccountInfoHttpRequest.get()
      .then((response) => {
        dispatch(setAccountInfo(response.data));
      })
      .catch((error) => {
        dispatch(setAccountInfoError(error));
      });
  };
};
