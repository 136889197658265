import { Message } from '../../../../types';
import axiosApi from '../../../shared/axios-api';

export class InboxHttpRequest {
  public static get() {
    return axiosApi.get<Message[]>('/message');
  }

  public static toggleArchived(id: number, archived: boolean) {
    const url = `/message?id=${id}&archived=${archived}`;
    return axiosApi.patch(url);
  }
}
