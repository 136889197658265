import { AccountInfo } from '../../../../types';

import * as actionTypes from './action-types';

export interface AccountInfoAction {
  type: string;
  accountInfo?: AccountInfo;
  loaded?: boolean;
  error?: string;
}

export const setAccountInfo = (accountInfo: AccountInfo): AccountInfoAction => {
  return {
    type: actionTypes.SET_ACCOUNT_INFO,
    accountInfo: accountInfo
  };
};

export const setAccountInfoLoaded = (loaded: boolean): AccountInfoAction => {
  return {
    type: actionTypes.SET_ACCOUNT_INFO_LOADED,
    loaded
  };
};

export const setAccountInfoError = (msg: string): AccountInfoAction => {
  return {
    type: actionTypes.SET_ACCOUNT_INFO_ERROR,
    error: msg
  };
};

export const clearAccountInfoError = (): AccountInfoAction => {
  return {
    type: actionTypes.CLEAR_ACCOUNT_INFO_ERROR
  };
};
