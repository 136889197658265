import { Security, ProductType } from '../../../types';
import { showNetworkError } from '../../shared/utils/growl-utils';

import * as actionTypes from './action-types';
import { SecurityHttpRequest } from './security-http-request';

export const addSecurity = (security: Security) => {
  return {
    type: actionTypes.ADD_SECURITY,
    payload: security
  };
};

export const updateSecurity = (security: Security) => {
  return {
    type: actionTypes.UPDATE_SECURITY,
    payload: security
  };
};

export const removeSecurity = (id: number) => {
  return {
    type: actionTypes.REMOVE_SECURITY,
    value: id
  };
};

export const setSecurities = (securities: Security[]) => {
  return {
    type: actionTypes.SET_SECURITIES,
    payload: securities
  };
};

export const setSecurityError = (error: string) => {
  return {
    type: actionTypes.SET_SECURITY_ERROR,
    value: error
  };
};

export const clearSecurityError = () => {
  return {
    type: actionTypes.CLEAR_SECURITY_ERROR
  };
};

export const setSelectedProductTypes = (productTypes: ProductType[]) => {
  return {
    type: actionTypes.UI_SET_PRODUCT_TYPES,
    payload: productTypes
  };
};

export const setShowArchived = (archived: boolean) => {
  return {
    type: actionTypes.UI_SHOW_ARCHIVED,
    value: archived
  };
};

export const setOptionPanelCollapsed = (collapse: boolean) => {
  return {
    type: actionTypes.UI_COLLAPSE_OPTION_PANEL,
    value: collapse
  };
};

export const setPickerPanelCollapsed = (collapse: boolean) => {
  return {
    type: actionTypes.UI_COLLAPSE_PICKER_PANEL,
    value: collapse
  };
};

export const setSecurityPicked = (security: Security | undefined) => {
  return {
    type: actionTypes.UI_SECURITY_PICKED,
    payload: security
  };
};

export const selectTab = (tab: string) => {
  return {
    type: actionTypes.UI_SELECT_TAB,
    value: tab
  };
};

/*** ASYNC WITH THUNK ***/

export const loadSecuritiesAsync = () => {
  return (dispatch) => {
    SecurityHttpRequest.get()
      .then((response) => {
        // console.log(response);
        const securities = response.data;
        dispatch(setSecurities(securities));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setSecurityError(msg));
      });
  };
};

export const addSecurityAsync = (security: Security) => {
  return (dispatch) => {
    SecurityHttpRequest.insert(security)
      .then((response) => {
        dispatch(addSecurity(response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setSecurityError(msg));
      });
  };
};

export const updateSecurityAsync = (security: Security) => {
  return (dispatch) => {
    SecurityHttpRequest.update(security)
      .then(() => {
        dispatch(updateSecurity(security));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setSecurityError(msg));
      });
  };
};

export const removeSecurityAsync = (securityId: number) => {
  return (dispatch) => {
    SecurityHttpRequest.remove(securityId)
      .then(() => {
        dispatch(removeSecurity(securityId));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setSecurityError(msg));
      });
  };
};
