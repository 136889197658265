import { AnyAction } from 'redux';

import * as actionTypes from './action-types';
import { initialGraphDataMapState, initialGraphDataState } from './state';

export const graphDataReducer = (state = initialGraphDataMapState, action: AnyAction) => {
  if (action.type === actionTypes.ADD_GRAPH_DATA) {
    const key = action.payload.key;
    return state.set(key, {
      loaded: true,
      error: undefined,
      data: action.payload.data
    });
  } else if (action.type === actionTypes.REMOVE_GRAPH_DATA) {
    return state.remove(action.value);
  } else if (action.type === actionTypes.SET_GRAPH_DATA_ERROR) {
    const key = action.payload.key;
    const current = state.get(key) || { ...initialGraphDataState };
    return state.set(key, {
      ...current,
      loaded: true,
      error: action.payload.error
    });
  } else if (action.type === actionTypes.SET_GRAPH_DATA_LOADED) {
    const key = action.payload.key;
    const current = state.get(key) || { ...initialGraphDataState };
    return state.set(key, {
      ...current,
      loaded: action.payload.loaded,
      error: undefined
    });
  }

  return state;
};
