import * as React from 'react';

import { Indicator } from '../../../../types';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const BollingerBandForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label htmlFor="bb_periods" className="col-sm-6 control-label">
          Periods (d:20)
        </label>
        <div className="col-sm-6">
          <input
            id="bb_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.periods}
            onChange={(event) => update({ periods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="bb_upper" className="col-sm-6 control-label">
          Upper Threshold
        </label>
        <div className="col-sm-6">
          <input
            id="bb_upper"
            type="number"
            step="1"
            className="form-control"
            value={indicator.upperThreshold}
            onChange={(event) => update({ upperThreshold: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="bb_lower" className="col-sm-6 control-label">
          Lower Threshold
        </label>
        <div className="col-sm-6">
          <input
            id="bb_lower"
            type="number"
            step="1"
            className="form-control"
            value={indicator.lowerThreshold}
            onChange={(event) => update({ lowerThreshold: Number(event.target.value) })}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default BollingerBandForm;
