import React, { FunctionComponent } from 'react';
import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import OpenPositionListPanel from '../../../modules/position/components/OpenPositionListPanel';
import { loadSecuritiesAsync } from '../../../modules/security/store/action-creators';
import QuickNavBar from '../../../modules/shared/components/QuickNavBar';
import Spinner from '../../../modules/shared/components/Spinner';
import { getExpireInDays } from '../../../modules/shared/utils/date-utils';
import { loadStrategiesAsync } from '../../../modules/strategy/store/services';
import { loadSubscriptionsAsync } from '../../../modules/subscription/store/action-creators';
import { Subscription } from '../../../types';
import { GlobalState } from '../../root';

const Home: FunctionComponent = () => {
  const state: GlobalState = useSelector((x: GlobalState) => x);

  const subscriptionsLoaded: boolean = state.subscriptionState.loaded;
  const subscriptions: Subscription[] = state.subscriptionState.subscriptions;
  const securitiesLoaded: boolean = state.securityState.loaded;
  const strategiesLoaded: boolean = state.strategyState.loaded;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!securitiesLoaded) {
      dispatch(loadSecuritiesAsync());
    }
    if (!strategiesLoaded) {
      dispatch(loadStrategiesAsync());
    }
    if (!subscriptionsLoaded) {
      dispatch(loadSubscriptionsAsync());
    }
  }, []);

  const loading = !securitiesLoaded || !strategiesLoaded || !subscriptionsLoaded;

  if (loading) {
    return <Spinner />;
  }

  const expires = subscriptions
    .filter((x) => x.active)
    .filter((x) => getExpireInDays(new Date(), x.contract.firstNoticeDate) <= 3)
    .sort((a, b) => (a > b ? 1 : -1))
    .map((x) => ({ key: x.id, name: x.security.name }));

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active">Home</li>
      </ol>

      {expires.length > 0 && (
        <div className="alert alert-warning" role="alert">
          <strong>Attention:</strong> The following securities have contracts which are about to expire:
          <ul>
            {expires.map((x) => (
              <li key={x.key}>{x.name}</li>
            ))}
          </ul>
        </div>
      )}

      {/* <PeriodListPanel /> */}

      <QuickNavBar />

      <OpenPositionListPanel />
    </div>
  );
};

export default Home;
