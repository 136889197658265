/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { GlobalState } from '../../../core/root';
import { HistoryPeriodType, PeriodType, PositionRequest, ScheduleStatus } from '../../../types';
import IntradayNav from '../../shared/components/IntradayNav';
import Spinner from '../../shared/components/Spinner';
import * as constants from '../../shared/constants/broker-accounts';
import { toggleShowRaceView } from '../../shared/store/layout/action-creators';
import { getSystemTime } from '../../shared/utils/broker-time';
import { trimTimePartFromDate } from '../../shared/utils/date-picker-utils';
import { convertToDate } from '../../shared/utils/date-utils';
import { PositionTypeOption } from '../../shared/utils/position-type-option';
import * as actionCreators from '../store/history/action-creators';
import { getHistoryListItems, getHistoryListLoading, shouldFetchHistoryList } from '../store/history/history-state';
import { getFilteredPositionsByType } from '../utils/position-filter';

import ClosedPositionTable from './ClosedPositionTable';
import PositionTypeFilter from './PositionTypeFilter';
import RaceList from './RaceList';

const PositionListPanel = () => {
  const [isCurrentDay, setIsCurrentDay] = useState(false);
  const [systemTime, setSystemTime] = useState<Date>();

  const state: GlobalState = useSelector((gs: GlobalState) => gs);
  const status: ScheduleStatus = state.scheduleStatusState.status;
  const historyDay: string | undefined = state.historyState.ui.historyDay;
  const loading = historyDay ? getHistoryListLoading(state, historyDay) : false;
  const positions = historyDay ? getHistoryListItems(state, historyDay) ?? [] : [];
  const posTypeOptions: PositionTypeOption[] = state.layoutState.ui.positionTypeOptions;
  const shouldFetch = historyDay ? shouldFetchHistoryList(state, historyDay) : false;
  const showRaceView = state.layoutState.ui.showRaceView;

  const dispatch = useDispatch();

  const fetchHistory = (dt: Date) => {
    const fromDt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
    const endDt = new Date(fromDt.getTime());
    endDt.setDate(fromDt.getDate() + 1);
    const request: PositionRequest = {
      fromDate: fromDt,
      toDate: endDt,
      contractId: 0,
      historyPeriodType: HistoryPeriodType.Day
    };
    if (historyDay) {
      dispatch(actionCreators.fetchHistoryAsync(historyDay, request));
    }
  };

  useEffect(() => {
    const tm = getSystemTime(status.webApiTimeZone);
    setSystemTime(tm);
  }, [status.webApiTimestamp]);

  useEffect(() => {
    if (systemTime && !historyDay) {
      setHistoryDay(systemTime);
    }
  }, [systemTime]);

  useEffect(() => {
    if (historyDay) {
      if (isCurrentDay || shouldFetch) {
        const dt = convertToDate(historyDay);
        fetchHistory(dt);
      }
    }
  }, [historyDay]);

  const setHistoryDay = (dt: Date) => {
    const isCurrentDay =
      dt.getDay() === systemTime?.getDay() && dt.getMonth() === systemTime?.getMonth() && dt.getFullYear() === systemTime?.getFullYear();
    setIsCurrentDay(isCurrentDay);
    const day = trimTimePartFromDate(dt) as string;
    dispatch(actionCreators.setHistoryDay(day));
  };

  const toggleRaceView = () => {
    dispatch(toggleShowRaceView(!showRaceView));
  };

  const filteredPositions = getFilteredPositionsByType(positions, posTypeOptions);

  const emptyList = filteredPositions && filteredPositions.length === 0;

  return (
    <div>
      <IntradayNav title={'Browse'} intraday={historyDay ? convertToDate(historyDay) : systemTime} setIntraday={setHistoryDay} />

      <div className="text-center gap-top-30">
        <PositionTypeFilter />
      </div>

      <div className="section gap-bottom-15">
        <hr />
        <p>Positions</p>
      </div>

      {loading && <Spinner />}

      {emptyList && <p className="small">No positions..</p>}

      {showRaceView && !emptyList && <RaceList positions={filteredPositions} />}

      {!showRaceView && !emptyList && <ClosedPositionTable positions={filteredPositions} showRows={true} showSummary={true} />}

      <div className="row gap-top-20">
        <div className="col-xs-12">
          <div className="btn-toolbar" style={{ justifyContent: 'center', display: 'flex' }}>
            <button
              className={classNames('btn', 'btn-default', { active: !showRaceView })}
              type="button"
              onClick={toggleRaceView}
              title="Grid View"
            >
              <span>
                <i className="fa fa-table" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PositionListPanel;
