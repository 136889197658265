import * as React from 'react';
import { FunctionComponent } from 'react';

import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { GlobalState } from '../../../../core/root';
import { OpenOrderViewModel } from '../../../../types';
import CollapsePanel from '../../../shared/components/CollapsePanel';
import { setOpenOrdersPanelCollapsed } from '../../store/open-order/action-creators';
import { getOpenOrdersAsync } from '../../store/open-order/service';
import { OpenOrderState } from '../../store/open-order/state';

import OpenOrderTable from './OpenOrderTable';

const OpenOrderPanel: FunctionComponent = () => {
  const state: GlobalState = useSelector((gs: GlobalState) => gs);
  const openOrderState: OpenOrderState = state.openOrderState;
  const openOrders: OpenOrderViewModel[] = openOrderState.openOrders;
  const error: string | undefined = openOrderState.error;
  const loaded: boolean = openOrderState.loaded;
  const openOrdersPanelCollapsed = openOrderState.ui.openOrdersCollapsed;
  const accountLoaded = state.accountState.loaded;

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (accountLoaded) {
      if (!loaded) {
        dispatch(getOpenOrdersAsync());
      }
    }
  }, [accountLoaded]);

  const existingOrdersCount = openOrders.filter((x) => x.orderStateExists);
  const hasMissingBrokerOrders = openOrders.filter((x) => !x.orderStateExists).length > 0;
  const collapsePanel = hasMissingBrokerOrders ? false : openOrdersPanelCollapsed;

  const titleBadge = (
    <span
      style={{ paddingTop: '.3em' }}
      className={classNames('label', {
        'label-danger': hasMissingBrokerOrders,
        'label-info': !hasMissingBrokerOrders && existingOrdersCount.length > 0,
        'label-dark': !hasMissingBrokerOrders && existingOrdersCount.length === 0
      })}
    >
      {existingOrdersCount.length}
    </span>
  );

  const title = <span>Open Orders &nbsp;{titleBadge}</span>;

  return (
    <CollapsePanel
      title={title}
      upper={false}
      collapsed={collapsePanel}
      onToggle={() => dispatch(setOpenOrdersPanelCollapsed(!openOrdersPanelCollapsed))}
    >
      {error && (
        <div className="alert alert-danger small" role="alert">
          <b>ERROR</b> {error}
        </div>
      )}
      <OpenOrderTable openOrders={openOrders} />
    </CollapsePanel>
  );
};

export default OpenOrderPanel;
