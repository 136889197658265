import * as React from 'react';
import { useState, useEffect } from 'react';

import classNames from 'classnames';
import { createUseStyles, useTheme } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import chicagoSkyline from '../../../../assets/img/chicago_skyline.jpg';
import { Theme } from '../../../../core/jss/theme';
import Spinner from '../../../shared/components/Spinner';
import { ModuleState } from '../../state';
import { authAsync } from '../../store/service';

const useStyles = createUseStyles((theme: Theme) => ({
  bgWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '0',
    height: `100vh`,
    minHeight: '600px',
    width: `100%`,
    backgroundImage: `url(${chicagoSkyline})`,
    backgroundSize: `cover`,
    backgroundPosition: `center`,
    backgroundRepeat: `no-repeat`
  },
  loginTitle: {
    color: theme.palette.brandSuccess,
    fontSize: '2rem'
  },
  loginSubTitle: {
    fontSize: theme.font.size.lg
  },
  loginContainer: {
    flexGrow: 1
  },
  loginPanel: {
    composes: ['panel'], // arv: med klasser fra vendors (bootsrap, awesome etc.)
    backgroundColor: 'rgba(19, 19, 19, 0.5)'
  }
}));

const Login: React.FunctionComponent = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [disableSumbit, setDisableSubmit] = useState(false);

  const state: ModuleState = useSelector((s: ModuleState) => s);
  const loading = state.authState.loading;
  //const error = state.authState.error;
  const attempts = state.authState.attempts;
  const isAuthenticated = !!state.authState.token;
  const authRedirectPath = state.authState.authRedirectPath;

  const dispatch = useDispatch();

  const onSubmitHandler = () => {
    if (disableSumbit) {
      return;
    }
    dispatch(authAsync(username, password));
  };

  const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmitHandler();
    }
  };

  useEffect(() => {
    if (attempts > 0) {
      setDisableSubmit(true);
      setTimeout(() => {
        setDisableSubmit(false);
      }, 1000 * attempts * attempts);
    }
  }, [attempts]);

  let authRedirect: JSX.Element | undefined;
  if (isAuthenticated) {
    authRedirect = <Redirect to={authRedirectPath} />;
  }

  const theme: Theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.bgWrapper}>
      {authRedirect}
      <div className={classes.loginContainer}>
        <div className="container">
          <div className="row">
            <div className="col-sm-offset-2 col-sm-8 col-md-offset-3 col-md-6">
              <form name="loginForm" className="form-horizontal" onSubmit={onSubmitHandler}>
                <div className={classes.loginPanel}>
                  <div className="panel-heading text-center">
                    <span className={classes.loginTitle}>
                      Manubia <span className={classes.loginSubTitle}>Login</span>
                    </span>
                  </div>
                  <div className="panel-body">
                    <div className="form-group form-group-sm">
                      <label htmlFor="login_usr" className="col-sm-4 control-label">
                        Username
                      </label>
                      <div className="col-sm-8">
                        <input
                          id="login_usr"
                          type="text"
                          className="form-control"
                          value={username}
                          onChange={(event) => setUsername(event.target.value)}
                        />
                      </div>
                    </div>

                    <div className="form-group form-group-sm">
                      <label htmlFor="login_pwd" className="col-sm-4 control-label">
                        Password
                      </label>
                      <div className="col-sm-8">
                        <input
                          id="login_pwd"
                          type="password"
                          className="form-control"
                          value={password}
                          onChange={(event) => setPassword(event.target.value)}
                          onKeyDown={(event) => keyDownHandler(event)}
                        />
                      </div>
                    </div>

                    <hr />
                    <div className="row">
                      <div className="col-xs-12 text-right">
                        <button
                          type="button"
                          disabled={disableSumbit}
                          onClick={onSubmitHandler}
                          className={classNames('btn', 'ripple', 'gap-bottom-5', {
                            'btn-success': !disableSumbit,
                            'btn-danger': disableSumbit
                          })}
                        >
                          Enter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {loading && <Spinner />}
    </div>
  );
};

export default Login;
