import { Subscription } from '../../../types';
import axiosApi from '../../shared/axios-api';

export class SubscriptionHttpRequest {
  public static getSubscriptions() {
    return axiosApi.get<Subscription[]>(`/subscription`);
  }

  public static update(subscription: Subscription) {
    return axiosApi.put(`/subscription/${subscription.id}`, subscription);
  }

  public static insert(subscription: Subscription) {
    return axiosApi.post('/subscription', subscription);
  }

  public static remove(id: number) {
    return axiosApi.delete(`/subscription/${id}`);
  }
}
