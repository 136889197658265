import * as React from 'react';

import { Switch, Route } from 'react-router-dom';

import PnlMonthScreen from './components/PnlMonthScreen';
import PnlStrategyScreen from './components/PnlStrategyScreen';

export const pnlModulePath = '/pnl';

const render = () => {
  return (
    <Switch>
      <Route exact path={pnlModulePath + '/strategy'} component={PnlStrategyScreen}></Route>
      <Route exact path={pnlModulePath + '/month'} component={PnlMonthScreen}></Route>
    </Switch>
  );
};

export default {
  routeProps: {
    path: pnlModulePath,
    render
  },
  name: 'PnL'
};
