import { AnyAction } from 'redux';

import { AccountInfo } from '../../../../types';

import { AccountInfoAction } from './action-creators';
import * as actionTypes from './action-types';
import { initalAccountInfoState } from './state';

export const accountInfoReducer = (state = initalAccountInfoState, action: AccountInfoAction | AnyAction) => {
  if (action.type === actionTypes.SET_ACCOUNT_INFO) {
    const accountInfo = action.accountInfo as AccountInfo;
    return {
      ...state,
      loaded: true,
      accountInfo
    };
  } else if (action.type === actionTypes.SET_ACCOUNT_INFO_LOADED) {
    return {
      ...state,
      loaded: action.loaded === true
    };
  } else if (action.type === actionTypes.SET_ACCOUNT_INFO_ERROR) {
    return {
      ...state,
      loaded: true,
      error: action.error
    };
  } else if (action.type === actionTypes.CLEAR_ACCOUNT_INFO_ERROR) {
    return {
      ...state,
      error: undefined
    };
  }
  return state;
};
