import { Map } from 'immutable';

import { Indicator } from '../../../types';

export interface IndicatorState {
  loading: boolean;
  loaded: boolean;
  error?: string;
  indicators: Indicator[];
}

export const initalIndicatorState: IndicatorState = {
  loading: false,
  loaded: false,
  error: undefined,
  indicators: []
};

export type IndicatorMapState = Map<number, IndicatorState>;

export interface PartialState {
  indicatorMapState: IndicatorMapState;
}

export const initalIndicatorMapState: IndicatorMapState = Map();
