import { AnyAction } from 'redux';

import * as actionTypes from './action-types';
import { initalHistoryList, initalHistoryState } from './history-state';

export const historyReducer = (state = initalHistoryState, action: AnyAction) => {
  const getOrCreateHistoryList = (key: string) => {
    let pnlList = state.historyListMap.get(key);
    if (!pnlList) {
      pnlList = {
        ...initalHistoryList
      };
    }
    return pnlList;
  };

  if (action.type === actionTypes.SET_HISTORY_LOADING) {
    const key = action.payload.key;
    const historyList = getOrCreateHistoryList(key);
    const updatedListMap = state.historyListMap.set(key, {
      ...historyList,
      loading: action.payload.loading
    });
    return {
      ...state,
      historyListMap: updatedListMap
    };
  } else if (action.type === actionTypes.SET_HISTORY_POSITIONS) {
    const key = action.payload.key;
    const historyList = getOrCreateHistoryList(key);
    const updatedListMap = state.historyListMap.set(key, {
      ...historyList,
      loading: false,
      loaded: true,
      error: undefined,
      positions: action.payload.positions
    });
    return {
      ...state,
      historyListMap: updatedListMap
    };
  } else if (action.type === actionTypes.SET_HISTORY_ERROR) {
    const key = action.payload.key;
    const historyList = getOrCreateHistoryList(key);
    const updatedListMap = state.historyListMap.set(key, {
      ...historyList,
      loading: false,
      error: action.payload.error
    });
    return {
      ...state,
      historyListMap: updatedListMap
    };
  } else if (action.type === actionTypes.UI_SET_HISTORY_DAY) {
    const ui = {
      ...state.ui,
      historyDay: action.value
    };
    return {
      ...state,
      ui
    };
  }

  return state;
};
