import { AnyAction } from 'redux';

import { StrategyGroup } from '../../../types';

import * as actionTypes from './action-types';
import { initalStrategyGroupState } from './strategy-group-state';

export const strategyGroupReducer = (state = initalStrategyGroupState, action: AnyAction) => {
  const getStrategyGroupIndex = (id: number): number => {
    const item = state.strategyGroups.find((x) => x.id === id);
    return item ? state.strategyGroups.indexOf(item) : -1;
  };

  if (action.type === actionTypes.ADD_STRATEGY_GROUP) {
    const strategyGroups = [...state.strategyGroups];
    strategyGroups.push(action.payload);
    return {
      ...state,
      strategyGroups
    };
  } else if (action.type === actionTypes.UPDATE_STRATEGY_GROUP) {
    const strategyGroup = action.payload as StrategyGroup;
    const index = getStrategyGroupIndex(strategyGroup.id);
    const strategyGroups = [...state.strategyGroups];
    strategyGroups[index] = strategyGroup;
    return {
      ...state,
      strategyGroups
    };
  } else if (action.type === actionTypes.REMOVE_STRATEGY_GROUP) {
    const id = action.value as number;
    const index = getStrategyGroupIndex(id);
    const strategyGroups = [...state.strategyGroups];
    strategyGroups.splice(index, 1);
    return {
      ...state,
      strategyGroups
    };
  } else if (action.type === actionTypes.SET_STRATEGY_GROUPS) {
    return {
      ...state,
      loaded: true,
      error: undefined,
      strategyGroups: action.payload
    };
  } else if (action.type === actionTypes.SET_STRATEGY_GROUP_ERROR) {
    return {
      ...state,
      loaded: true,
      error: action.value
    };
  } else if (action.type === actionTypes.CLEAR_STRATEGY_GROUP_ERROR) {
    return {
      ...state,
      error: undefined
    };
  } else if (action.type === actionTypes.UI_SET_SELECTED_STRATEGY_GROUP) {
    const ui = { ...state.ui };
    ui.selectedStrategyGroup = action.payload;
    return {
      ...state,
      ui
    };
  }

  return state;
};
