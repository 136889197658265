import { Period } from '../../../../types';

import * as actionTypes from './action-types';

export const setPeriodRangeLoading = (loading: boolean) => {
  return {
    type: actionTypes.SET_PERIOD_RANGE_LOADING,
    value: loading
  };
};

export const setPeriodRange = (periods: Period[]) => {
  return {
    type: actionTypes.SET_PERIOD_RANGE,
    payload: periods
  };
};

export const setPeriodRangeError = (error: string) => {
  return {
    type: actionTypes.SET_PERIOD_RANGE_ERROR,
    value: error
  };
};

export const setPeriodDay = (day?: Date) => {
  return {
    type: actionTypes.UI_SET_PERIOD_DAY,
    value: day
  };
};

export const setSelectedSubscriptionId = (subscriptionId: number) => {
  return {
    type: actionTypes.UI_SET_SELECTED_SUBSCRIPTION,
    value: subscriptionId
  };
};
