import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';

import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { GlobalState } from '../../../core/root';
import { Subscription } from '../../../types';
import { loadSubscriptionsAsync } from '../../subscription/store/action-creators';

import CollapsePanel from './CollapsePanel';

interface Props {
  selectedSubscriptionId: number;
  onToggleSubscription(sub: Subscription): void;
}

const SubscriptionListToolbar: FunctionComponent<Props> = ({ selectedSubscriptionId, onToggleSubscription }: Props) => {
  const state = useSelector((gs: GlobalState) => gs);
  const subscriptions: Subscription[] = state.subscriptionState.subscriptions;
  const subscriptionsLoaded: boolean = state.subscriptionState.loaded;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!subscriptionsLoaded) {
      dispatch(loadSubscriptionsAsync());
    }
  }, []);

  const sortBySecurities = (a: Subscription, b: Subscription) => {
    if (a.security.name > b.security.name) return 1;
    if (a.security.name < b.security.name) return -1;
    return 0;
  };

  const sortByStrategies = (a: Subscription, b: Subscription) => {
    if (a.strategy.name > b.strategy.name) return 1;
    if (a.strategy.name < b.strategy.name) return -1;
    return 0;
  };

  const sort = (a: Subscription, b: Subscription) => {
    const order = sortBySecurities(a, b);
    if (order !== 0) {
      return order;
    }
    return sortByStrategies(a, b);
  };

  const subscriptionOptions = subscriptions
    .filter((x) => x.active)
    .sort(sort)
    .map((x) => {
      const subscriptionName = x.alias || `${x.security.name} - ${x.strategy.name}`;
      return (
        <button
          key={x.id}
          type="button"
          className={classNames('btn', 'btn-sm', 'btn-default', 'gap-bottom-5', { active: selectedSubscriptionId === x.id })}
          onClick={() => onToggleSubscription(x)}
        >
          <span>{subscriptionName}</span>
        </button>
      );
    });

  return subscriptionsLoaded ? (
    <CollapsePanel title="Subscriptions">
      {subscriptionOptions.length > 0 ? (
        <div className="btn-toolbar">{subscriptionOptions}</div>
      ) : (
        <span className="small">No subscriptions..</span>
      )}
    </CollapsePanel>
  ) : null;
};

export default SubscriptionListToolbar;
