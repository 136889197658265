import * as React from 'react';

import { Indicator } from '../../../../types';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const BreakOutForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label htmlFor="breakout_periods" className="col-sm-6 control-label">
          Periods
        </label>
        <div className="col-sm-6">
          <input
            id="breakout_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.periods}
            onChange={(event) => update({ periods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="buffer_periods" className="col-sm-6 control-label">
          Buffer Periods
        </label>
        <div className="col-sm-6">
          <input
            id="buffer_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.secondaryPeriods}
            onChange={(event) => update({ secondaryPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="section">
        <hr />
        <p>Breakout Amount</p>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="breakout_upper" className="col-sm-6 control-label">
          Max Amount
        </label>
        <div className="col-sm-6">
          <input
            id="breakout_upper"
            type="number"
            step="1"
            className="form-control"
            value={indicator.upperThreshold}
            onChange={(event) => update({ upperThreshold: Number(event.target.value) })}
          />
        </div>
      </div>
      <div className="form-group form-group-sm">
        <label htmlFor="breakout_lower" className="col-sm-6 control-label">
          Min Amount
        </label>
        <div className="col-sm-6">
          <input
            id="breakout_lower"
            type="number"
            step="1"
            className="form-control"
            value={indicator.lowerThreshold}
            onChange={(event) => update({ lowerThreshold: Number(event.target.value) })}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default BreakOutForm;
