import { AnyAction } from 'redux';

import { Period } from '../../../../types';

import * as actionTypes from './action-types';
import { initalPeriodRangeState } from './state';

export const periodRangeReducer = (state = initalPeriodRangeState, action: AnyAction) => {
  if (action.type === actionTypes.SET_PERIOD_RANGE) {
    const periods: Period[] = action.payload ? action.payload : [];
    return {
      ...state,
      periods,
      loading: false,
      loaded: true,
      error: undefined
    };
  } else if (action.type === actionTypes.SET_PERIOD_RANGE_LOADING) {
    return {
      ...state,
      loading: action.value,
      loaded: action.value === false
    };
  } else if (action.type === actionTypes.SET_PERIOD_RANGE_ERROR) {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: action.value
    };
  } else if (action.type === actionTypes.UI_SET_PERIOD_DAY) {
    const ui = {
      ...state.ui,
      periodDay: action.value
    };
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_SET_SELECTED_SUBSCRIPTION) {
    const ui = {
      ...state.ui,
      selectedSubscriptionId: action.value
    };
    return {
      ...state,
      ui
    };
  }

  return state;
};
