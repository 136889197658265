import * as constants from '../../shared/constants/local-storage';
import { showNetworkError } from '../../shared/utils/growl-utils';
import { AuthToken } from '../models/auth-token';

import { authFail, authStart, authSuccess, logout } from './action-creators';
import { AuthHttpRequest } from './auth-http-request';

export const updateFromLocalStorage = () => {
  return (dispatch) => {
    const token = localStorage.getItem(constants.LOCAL_STORAGE_TOKEN);
    if (!token) {
      dispatch(logout());
    } else {
      const expiresTime = localStorage.getItem(constants.LOCAL_STORAGE_EXPIRES_TIME);
      const expires = new Date(Number(expiresTime));
      const now = new Date();
      const isValid = expires > now;
      if (isValid) {
        const authToken = {
          accessToken: token,
          accountId: Number(localStorage.getItem(constants.LOCAL_STORAGE_ACCOUNT_ID)),
          userName: localStorage.getItem(constants.LOCAL_STORAGE_USERNAME),
          firstName: localStorage.getItem(constants.LOCAL_STORAGE_FIRSTNAME)
        } as AuthToken;
        dispatch(authSuccess(authToken));
        const expiresInSeconds = (expires.getTime() - now.getTime()) / 1000;
        dispatch(checkAuthTimeout(expiresInSeconds));
      } else {
        dispatch(logout());
      }
    }
  };
};

export const authAsync = (username: string, password: string) => {
  return (dispatch) => {
    dispatch(authStart());
    AuthHttpRequest.auth(username, password)
      .then((response) => {
        const authToken = response.data;

        // add to local storage
        const expires = new Date();
        expires.setSeconds(expires.getSeconds() + authToken.expiresIn);
        localStorage.setItem(constants.LOCAL_STORAGE_TOKEN, authToken.accessToken);
        localStorage.setItem(constants.LOCAL_STORAGE_EXPIRES_TIME, expires.getTime().toString());
        localStorage.setItem(constants.LOCAL_STORAGE_ACCOUNT_ID, authToken.accountId.toString());
        localStorage.setItem(constants.LOCAL_STORAGE_USERNAME, authToken.userName);
        localStorage.setItem(constants.LOCAL_STORAGE_FIRSTNAME, authToken.firstName);

        dispatch(authSuccess(authToken));
        dispatch(checkAuthTimeout(response.data.expiresIn));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(authFail(msg));
      });
  };
};

const checkAuthTimeout = (expirationTime) => {
  const expirationTimeInMilliseconds = expirationTime * 1000;
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTimeInMilliseconds);
  };
};
