import { Order } from '../../../../types';

import * as actionTypes from './action-types';

export const setPositionOrders = (positionId: number, orders: Order[]) => {
  return {
    type: actionTypes.SET_POSITION_ORDERS,
    positionId,
    orders
  };
};

export const addPositionOrder = (positionId: number, order: Order) => {
  return {
    type: actionTypes.ADD_POSITION_ORDER,
    positionId,
    order
  };
};

export const setPositionOrdersLoaded = (positionId: number, toggle: boolean) => {
  return {
    type: actionTypes.SET_POSITION_ORDERS_LOADED,
    positionId,
    loaded: toggle
  };
};

export const setPositionOrdersError = (positionId: number, error: string) => {
  return {
    type: actionTypes.SET_POSITION_ORDERS_ERROR,
    positionId,
    error
  };
};
