import { PeriodRequest } from '../../../../types';
import { showNetworkError } from '../../../shared/utils/growl-utils';

import { setPeriodRangeError, setPeriodRangeLoading, setPeriodRange } from './action-creators';
import { PeriodHttpRequest } from './http-request';

export const getPeriodRange = (request: PeriodRequest) => {
  return (dispatch) => {
    dispatch(setPeriodRangeLoading(true));
    PeriodHttpRequest.getPeriodsByTimeRange(request)
      .then((response) => {
        dispatch(setPeriodRange(response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load periods');
        dispatch(setPeriodRangeError(msg));
      });
  };
};
