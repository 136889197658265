import { AnyAction } from 'redux';

import { ContractEntity } from '../../../types';

import { ContractAction } from './action-creators';
import * as actionTypes from './action-types';
import { initalContractState } from './contract-state';

export const contractReducer = (state = initalContractState, action: ContractAction | AnyAction) => {
  const getContractIndex = (id: number): number => {
    const item = state.contracts.find((x) => x.id === id);
    return item ? state.contracts.indexOf(item) : -1;
  };

  if (action.type === actionTypes.ADD_CONTRACT) {
    const contracts = [...state.contracts];
    contracts.push(action.contract as ContractEntity);
    return {
      ...state,
      contracts,
      loaded: true,
      error: undefined
    };
  } else if (action.type === actionTypes.UPDATE_CONTRACT) {
    const contract = action.contract as ContractEntity;
    const index = getContractIndex(contract.id);
    const contracts = [...state.contracts];
    contracts[index] = contract;
    return {
      ...state,
      contracts,
      loaded: true,
      error: undefined
    };
  } else if (action.type === actionTypes.REMOVE_CONTRACT) {
    const id = action.id as number;
    const index = getContractIndex(id);
    const contracts = [...state.contracts];
    contracts.splice(index, 1);
    return {
      ...state,
      contracts,
      loaded: true,
      error: undefined
    };
  } else if (action.type === actionTypes.SET_CONTRACTS) {
    return {
      ...state,
      contracts: action.contracts,
      loaded: true,
      error: undefined
    };
  } else if (action.type === actionTypes.SET_CONTRACTS_LOADED) {
    return {
      ...state,
      loaded: action.loaded
    };
  } else if (action.type === actionTypes.SET_CONTRACT_ERROR) {
    return {
      ...state,
      loaded: true,
      error: action.error
    };
  }

  return state;
};
