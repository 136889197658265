import { AnyAction } from 'redux';

import { Indicator } from '../../../types';

import * as actionTypes from './action-types';
import { initalIndicatorMapState, initalIndicatorState } from './state';

export const indicatorReducer = (state = initalIndicatorMapState, action: AnyAction) => {
  const key = action.strategyId;
  const current = state.get(key) || { ...initalIndicatorState };

  if (action.type === actionTypes.SET_INDICATORS_LOADING) {
    const loading = action.loading === true;
    return state.set(key, {
      ...current,
      loading
    });
  } else if (action.type === actionTypes.SET_INDICATORS_ERROR) {
    return state.set(key, {
      ...current,
      loading: false,
      error: action.error
    });
  } else if (action.type === actionTypes.CLEAR_INDICATORS_ERROR) {
    return state.set(key, {
      ...current,
      error: undefined
    });
  } else if (action.type === actionTypes.SET_INDICATORS) {
    const indicators = action.indicators as Indicator[];
    return state.set(key, {
      loading: false,
      loaded: true,
      error: undefined,
      indicators
    });
  } else if (action.type === actionTypes.ADD_INDICATOR) {
    const indicators = [...current.indicators];
    const indicator = action.indicator as Indicator;
    indicators.push(indicator);
    return state.set(key, {
      ...current,
      indicators
    });
  } else if (action.type === actionTypes.UPDATE_INDICATOR) {
    const indicators = [...current.indicators];
    const indicator = action.indicator as Indicator;
    const item = indicators.find((x) => x.id === indicator.id);
    const index = item ? indicators.indexOf(item) : -1;
    indicators[index] = { ...indicator };
    return state.set(key, {
      ...current,
      error: undefined,
      indicators
    });
  } else if (action.type === actionTypes.REMOVE_INDICATOR) {
    const indicators = [...current.indicators];
    const indicator = action.indicator as Indicator;
    const item = indicators.find((x) => x.id === indicator.id);
    const index = item ? indicators.indexOf(item) : -1;
    indicators.splice(index, 1);
    return state.set(key, {
      ...current,
      error: undefined,
      indicators
    });
  }

  return state;
};
