import * as React from 'react';

import { OverlayTrigger, Popover } from 'react-bootstrap';
import Switch from 'react-switch';

import { Indicator } from '../../../../types';
import { basisTypes } from '../../utils/basis-utils';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const TrendScoreForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  const scoreSourcePopover = (
    <Popover id="scoreSourcePopover">
      Adds a point for every period which have a higher source value than previous previous source value, and removes a point for every
      period which have a lower source value than the previous source value.
    </Popover>
  );
  const thrustPointsPopover = (
    <Popover id="thrustPointsPopover">
      Adds a point for every period where close-price is higher than previous high-price, and removes a point for every period where
      close-price is lower than previous low-price.
    </Popover>
  );
  const runPointsPopover = (
    <Popover id="runPointsPopover">
      Adds a point for every period where high-price is greater than all previous high-prices within the indicators period-range, and
      removes a point for every period where low-price is lower than all previous low-prices within the indicators period-range.
    </Popover>
  );

  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label htmlFor="compare_basis_type" className="control-label col-sm-6">
          <span>Score Source</span>
          <OverlayTrigger placement="top" overlay={scoreSourcePopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg info-text" />
            </span>
          </OverlayTrigger>
        </label>
        <div className="col-sm-6">
          <select
            id="compare_basis_type"
            className="form-control"
            value={indicator.basisType}
            onChange={(event) => update({ basisType: Number(event.target.value) })}
          >
            {basisTypes.map((x) => (
              <option key={x.key} value={x.key}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="ts_periods" className="col-sm-6 control-label">
          Periods
        </label>
        <div className="col-sm-6">
          <input
            id="ts_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.periods}
            onChange={(event) => update({ periods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="ts_upper_threshold_periods" className="col-sm-6 control-label">
          Upper Score Threshold
        </label>
        <div className="col-sm-6">
          <input
            id="ts_upper_threshold_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.upperThreshold}
            onChange={(event) => update({ upperThreshold: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="ts_lower_threshold_periods" className="col-sm-6 control-label">
          Lower Score Threshold
        </label>
        <div className="col-sm-6">
          <input
            id="ts_lower_threshold_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.lowerThreshold}
            onChange={(event) => update({ lowerThreshold: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="section">
        <hr />
        <p>Options</p>
      </div>

      <div className="form-group form-group-sm">
        <label className="col-sm-6 control-label">Use Exponential Score</label>
        <div className="col-sm-6">
          <label className="react-switch react-switch--sm">
            <Switch onChange={() => update({ useCrossOver: !indicator.useCrossOver })} checked={indicator.useCrossOver} />
          </label>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label className="control-label col-sm-6">
          <span>Add Thrust Points</span>
          <OverlayTrigger placement="top" overlay={thrustPointsPopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg info-text" />
            </span>
          </OverlayTrigger>
        </label>
        <div className="col-sm-6">
          <label className="react-switch react-switch--sm">
            <Switch
              onChange={() => update({ useTopWickThresholds: !indicator.useTopWickThresholds })}
              checked={indicator.useTopWickThresholds}
            />
          </label>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label className="control-label col-sm-6">
          <span>Add Run Points</span>
          <OverlayTrigger placement="top" overlay={runPointsPopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg info-text" />
            </span>
          </OverlayTrigger>
        </label>
        <div className="col-sm-6">
          <label className="react-switch react-switch--sm">
            <Switch
              onChange={() => update({ useBottomWickThresholds: !indicator.useBottomWickThresholds })}
              checked={indicator.useBottomWickThresholds}
            />
          </label>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TrendScoreForm;
