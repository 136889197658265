import * as React from 'react';
import { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { GlobalState } from '../../../../core/root';
import { Position, Order, Trade, OrderType, OrderDirection, Period } from '../../../../types';
import PeriodTable from '../../../period/components/PeriodTable';
import { getPeriodAsync } from '../../../period/store/period/service';
import CollapsePanel from '../../../shared/components/CollapsePanel';
import { ConfirmDialog } from '../../../shared/components/ConfirmDialog';
import Spinner from '../../../shared/components/Spinner';
import { positionModulePath } from '../../pos-routes';
import {
  fetchPositionOrdersAsync,
  placeLimitOrderAsync,
  placeMarketOrderAsync,
  placeStopOrderAsync
} from '../../store/position-order/service';
import { PositionOrderState } from '../../store/position-order/state';
import { fetchPositionSelectedAsync } from '../../store/position-selected/service';
import { PositionSelectedState } from '../../store/position-selected/state';
import { fetchPositionTradesAsync } from '../../store/position-trades/service';
import { PositionTradeState } from '../../store/position-trades/state';
import ClosedPositionTable from '../ClosedPositionTable';
import OrderTable from '../OrderTable';
import TradeTable from '../TradeTable';

import { UpdatePositionDialog } from './UpdatePositionDialog';

export interface RouteParamProps {
  positionId: string;
}

export type Props = RouteComponentProps<RouteParamProps>;

const PositionScreen: React.FunctionComponent<Props> = ({ match }: Props) => {
  const positionId = Number(match.params.positionId);

  const [showUpdatePositionDialog, setShowUpdatePositionDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const state: GlobalState = useSelector((gs: GlobalState) => gs);

  const positionSelectedState: PositionSelectedState | undefined = state.positionSelectedMapState.get(positionId);
  const position: Position | undefined = positionSelectedState?.position;
  const positionLoaded = positionSelectedState?.loaded;

  let entryPeriod: Period | undefined;
  if (position && position.entryPeriodId !== 0) {
    const periodState = state.periodMapState.get(position.entryPeriodId);
    entryPeriod = periodState?.period;
  }

  const positionOrderState: PositionOrderState | undefined = state.positionOrderMapState.get(positionId);
  const orders: Order[] = positionOrderState?.orders || [];
  const ordersLoaded = positionOrderState?.loaded;

  const positionTradeState: PositionTradeState | undefined = state.positionTradeMapState.get(positionId);
  const trades: Trade[] = positionTradeState?.trades || [];
  const tradesLoaded = positionTradeState?.loaded;

  const dispatch = useDispatch();

  const refreshPositionHandler = () => {
    dispatch(fetchPositionSelectedAsync(positionId));
    dispatch(fetchPositionOrdersAsync(positionId));
    dispatch(fetchPositionTradesAsync(positionId));
  };

  useEffect(() => {
    if (!positionLoaded) {
      dispatch(fetchPositionSelectedAsync(positionId));
    }
  }, []);

  useEffect(() => {
    if (!ordersLoaded) {
      dispatch(fetchPositionOrdersAsync(positionId));
    }
  }, []);

  useEffect(() => {
    if (!tradesLoaded) {
      dispatch(fetchPositionTradesAsync(positionId));
    }
  }, []);

  useEffect(() => {
    if (position && position.entryPeriodId) {
      dispatch(getPeriodAsync(position.entryPeriodId));
    }
  }, [position]);

  const hideUpdatePositionDialog = () => {
    setShowUpdatePositionDialog(false);
  };

  const orderPanels = orders.map((x) => {
    const name = OrderType[x.type];
    const dir = x.direction === OrderDirection.Enter ? 'Entry' : 'Exit'; //OrderDirection[x.direction];
    const title = `${dir} Order - ${name} `;
    const orderTrades = trades.filter((y) => y.orderId === x.id);

    const hasTrades = orderTrades && orderTrades.length > 0;
    let bsStyle = 'default';
    if (hasTrades && (x.type === OrderType.Market || x.type === OrderType.Limit)) {
      bsStyle = 'success';
    } else if (hasTrades && x.type === OrderType.Stop) {
      bsStyle = 'danger';
    }

    return (
      <CollapsePanel bsStyle={bsStyle} key={x.id} title={title} collapsed={!hasTrades} upper={true}>
        <OrderTable securityId={position?.securityId || 0} orders={[x]} />
        <div className="section">
          <hr />
          <p>Trades</p>
        </div>
        {hasTrades ? <TradeTable trades={orderTrades} /> : <p className="small">No trades..</p>}
      </CollapsePanel>
    );
  });

  const createLimitOrderHandler = () => {
    if (position) {
      dispatch(placeLimitOrderAsync(position));
    }
  };

  const createStopOrderHandler = () => {
    if (position) {
      dispatch(placeStopOrderAsync(position));
    }
  };

  const createMarketOrderHandler = () => {
    setShowConfirmDialog(false);
    if (position) {
      dispatch(placeMarketOrderAsync(position));
    }
  };

  const loading = !ordersLoaded;
  const disableCreateMarketOrder = !position || position.size === 0;
  const disableLimitOrder = disableCreateMarketOrder || orders.filter((x) => x.type === OrderType.Limit).length > 0;
  const disableStopOrder = disableCreateMarketOrder || orders.filter((x) => x.type === OrderType.Stop).length > 0;

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to={positionModulePath}>Positions</Link>
        </li>
        <li className="breadcrumb-item active">{position?.id}</li>
      </ol>

      <div className="page-header">
        <h3>
          Position <small className="no-wrap">{}</small>
        </h3>
      </div>

      {entryPeriod && (
        <CollapsePanel title="Entry Period" upper={true} collapsed={true} className="gap-top-20">
          <PeriodTable periods={[entryPeriod]} single={true} />
        </CollapsePanel>
      )}

      {position && (
        <CollapsePanel title="Position" upper={true} collapsed={false} className="gap-top-20">
          <ClosedPositionTable positions={[position]} showRows={true} showSummary={false} />
          <div className="text-right gap-top-10">
            <button className="btn btn-sm btn-default ripple" onClick={() => setShowUpdatePositionDialog(true)}>
              <span>Edit Position..</span>
            </button>
          </div>
        </CollapsePanel>
      )}

      <div className="section">
        <hr />
        <p>Orders</p>
      </div>
      {orderPanels}

      {loading && <Spinner />}

      <hr />
      <div className="row gap-top-20">
        <div className="col-xs-4">
          <button type="button" className="btn btn-default btn-sm ripple gap-bottom-5 gap-right-5" onClick={refreshPositionHandler}>
            <span>
              <i className="fa fa-refresh gap-right-5" /> {`Refresh`}
            </span>
          </button>
        </div>
        <div className="col-xs-8 text-right">
          <button
            type="button"
            className="btn btn-default btn-sm ripple gap-bottom-5"
            onClick={createLimitOrderHandler}
            disabled={disableLimitOrder}
          >
            Create Limit Order
          </button>
          <button
            type="button"
            className="btn btn-default btn-sm ripple gap-bottom-5 gap-left-5"
            onClick={createStopOrderHandler}
            disabled={disableStopOrder}
          >
            Create Stop Order
          </button>
          <button
            type="button"
            className="btn btn-warning btn-sm ripple gap-bottom-5 gap-left-5"
            onClick={() => setShowConfirmDialog(true)}
            disabled={disableCreateMarketOrder}
          >
            Create Market Order
          </button>
        </div>
      </div>

      {position && showUpdatePositionDialog && (
        <UpdatePositionDialog position={position} show={showUpdatePositionDialog} hide={hideUpdatePositionDialog} />
      )}

      <ConfirmDialog
        show={showConfirmDialog}
        cancel={() => setShowConfirmDialog(false)}
        confirm={createMarketOrderHandler}
        title="Place Market Order"
        text="Placing a market order will close this position. Are you sure?"
      />
    </div>
  );
};

export default withRouter(PositionScreen);
