import * as React from 'react';
import { useEffect } from 'react';

import { Tabs, Tab } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Backtest, Strategy, BarSize } from '../../../types';
import * as partialQuoteActionCreators from '../../contract-partial/store/partial-quote/action-creators';
import IndicatorPanel from '../../indicator/components/IndicatorPanel';
import CategoryFilterPanel from '../../security/components/CategoryFilterPanel';
import SecurityPickerPanel from '../../security/components/SecurityPickerPanel';
import CollapsePanel from '../../shared/components/CollapsePanel';
import Spinner from '../../shared/components/Spinner';
import StrategyPickerPanel from '../../strategy/components/StrategyPickerPanel';
import GeneralTab from '../../strategy/components/StrategyScreen/GeneralTab';
import RiskTab from '../../strategy/components/StrategyScreen/RiskTab';
import TimePeriodTab from '../../strategy/components/StrategyScreen/TimePeriodTab';
import * as strategyActionCreators from '../../strategy/store/action-creators';
import { updateStrategyAsync } from '../../strategy/store/services';
import * as strategyGroupActionCreators from '../../strategy-group/store/action-creators';
import * as subscriptionActionCreators from '../../subscription/store/action-creators';
import { ModuleState } from '../state';
import * as backtestActionCreators from '../store/backtest/action-creators';
import { getBacktests, getLoaded } from '../store/backtest/selectors';

import BacktestList from './BacktestList';

const BacktestListScreen = () => {
  const state = useSelector((gs: ModuleState) => gs);
  const securitiesLoaded: boolean = state.securityState.loaded;
  const selectedSecurity = state.securityState.ui.securityPicked;
  const subscriptionsLoaded = state.subscriptionState.loaded;
  const subscriptions = state.subscriptionState.subscriptions;
  const strategyGroupsLoaded = state.strategyGroupState.loaded;
  const strategyGroups = state.strategyGroupState.strategyGroups;
  const selectedStrategy: Strategy | undefined = state.strategyState.ui.strategyPicked;
  const partialQuotesLoaded = state.partialQuoteState.loaded;

  let backtests: Backtest[] = [];
  let backtestsLoaded = true;
  useEffect(() => {
    if (selectedSecurity && selectedStrategy) {
      backtests = getBacktests(state, selectedSecurity.id, selectedStrategy.id);
      backtestsLoaded = getLoaded(state, selectedSecurity.id, selectedStrategy.id);
    }
  }, [selectedSecurity, selectedStrategy]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!subscriptionsLoaded) {
      dispatch(subscriptionActionCreators.loadSubscriptionsAsync());
    }
    if (!strategyGroupsLoaded) {
      dispatch(strategyGroupActionCreators.loadStrategyGroupsAsync());
    }
    if (!partialQuotesLoaded) {
      dispatch(partialQuoteActionCreators.loadPartialQuotesAsync());
    }
  }, []);

  useEffect(() => {
    if (selectedSecurity && selectedStrategy && !backtestsLoaded) {
      dispatch(backtestActionCreators.loadBacktestsAsync(selectedSecurity.id, selectedStrategy.id));
    }
  }, [selectedSecurity, selectedStrategy]);

  const updateStrategy = (strategy: Strategy) => {
    dispatch(updateStrategyAsync(strategy));
    dispatch(strategyActionCreators.setStrategyPicked(strategy));
  };

  const loading = !backtestsLoaded || !securitiesLoaded || !strategyGroupsLoaded || !partialQuotesLoaded || !subscriptionsLoaded;

  const getBacktestWarningMessage = () => {
    if (!selectedStrategy) {
      return undefined;
    }
    const barSize = selectedStrategy.periodBarSize;
    if (barSize === BarSize.NA) {
      return "Strategy time period bar-size is NOT selected. Please select period bar-size from the strategy's Time-tab.";
    }
  };

  let backtestList: JSX.Element;
  if (!selectedSecurity) {
    backtestList = <p className="small">Security not selected..</p>;
  } else if (!selectedStrategy) {
    backtestList = <p className="small">Strategy not selected..</p>;
  } else {
    const warningMessage = getBacktestWarningMessage();

    backtestList = (
      <>
        {warningMessage && (
          <div className="alert alert-warning small" role="alert">
            <b>WARNING</b> {warningMessage}
          </div>
        )}
        <BacktestList securityId={selectedSecurity.id} strategyId={selectedStrategy.id} backtests={backtests} />
      </>
    );
  }

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item active">Backtests</li>
      </ol>

      <div className="page-header">
        <h3>
          Backtests <small className="no-wrap"></small>
        </h3>
      </div>

      {loading && <Spinner />}

      <StrategyPickerPanel upper={true} />

      {selectedStrategy && (
        <CollapsePanel bsStyle="success" title={selectedStrategy.name} collapsed={true} upper={true}>
          <Tabs id="security_tabs" defaultActiveKey="general" transition="false">
            <Tab key="general" eventKey="general" title="General">
              <GeneralTab
                strategyId={selectedStrategy.id}
                updateStrategy={updateStrategy}
                strategyGroups={strategyGroups}
                subscriptions={subscriptions}
                deleteStrategy={() => {
                  return;
                }}
                duplicateStrategy={() => {
                  return;
                }}
              />
            </Tab>
            <Tab key="time" eventKey="time" title="Time">
              <TimePeriodTab strategyId={selectedStrategy.id} updateStrategy={updateStrategy} />
            </Tab>
            <Tab key="risk" eventKey="risk" title="Risk">
              <RiskTab strategyId={selectedStrategy.id} updateStrategy={updateStrategy} />
            </Tab>
            <Tab key="indicators" eventKey="indicators" title="Indicators">
              <IndicatorPanel strategy={selectedStrategy} />
            </Tab>
          </Tabs>
        </CollapsePanel>
      )}

      <SecurityPickerPanel upper={true}>
        <CategoryFilterPanel />
      </SecurityPickerPanel>

      <div className="section">
        <hr />
        <p>Backtests</p>
      </div>

      {backtestList}
    </div>
  );
};

export default BacktestListScreen;
