import { showNetworkError } from '../../../shared/utils/growl-utils';

import { setPeriod, setPeriodError } from './action-creators';
import { PeriodHttpRequest } from './http-request';

export const getPeriodAsync = (periodId: number) => {
  return (dispatch) => {
    PeriodHttpRequest.getPeriod(periodId)
      .then((response) => {
        if (response.data) {
          dispatch(setPeriod(response.data));
        }
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load period');
        dispatch(setPeriodError(periodId, msg));
      });
  };
};

export const getNextPeriodAsync = (periodId: number, callback) => {
  return (dispatch) => {
    PeriodHttpRequest.getNextPeriod(periodId)
      .then((response) => {
        if (response.data) {
          dispatch(setPeriod(response.data));
          if (callback) {
            callback(response.data.id);
          }
        }
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load next period');
        dispatch(setPeriodError(periodId, msg));
      });
  };
};

export const getPreviousPeriodAsync = (periodId: number, callback) => {
  return (dispatch) => {
    PeriodHttpRequest.getPreviousPeriod(periodId)
      .then((response) => {
        if (response.data) {
          dispatch(setPeriod(response.data));
          if (callback) {
            callback(response.data.id);
          }
        }
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load previous period');
        dispatch(setPeriodError(periodId, msg));
      });
  };
};

export const getLatestSubscriptionPeriodAsync = (subscriptionId: number, callback) => {
  return (dispatch) => {
    PeriodHttpRequest.getLatestSubscriptionPeriod(subscriptionId)
      .then((response) => {
        if (response.data) {
          dispatch(setPeriod(response.data));
          if (callback) {
            callback(response.data.id);
          }
        }
      })
      .catch((error) => {
        showNetworkError(error, 'Failed to load latest period');
      });
  };
};
