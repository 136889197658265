import { AnyAction } from 'redux';

import { Message } from '../../../../types';

import * as actionTypes from './action-types';
import { initalInboxState } from './inbox-state';

export const inboxReducer = (state = initalInboxState, action: AnyAction) => {
  const getMessageIndex = (id: number): number => {
    const item = state.messages.find((x) => x.id === id);
    return item ? state.messages.indexOf(item) : -1;
  };

  if (action.type === actionTypes.UPDATE_MESSAGE) {
    const message = action.payload as Message;
    const index = getMessageIndex(message.id);
    const messages = [...state.messages];
    messages[index] = message;
    return {
      ...state,
      messages
    };
  } else if (action.type === actionTypes.SET_MESSAGES) {
    return {
      ...state,
      loaded: true,
      messages: action.payload
    };
  } else if (action.type === actionTypes.SET_INBOX_ERROR) {
    return {
      ...state,
      loaded: true,
      error: true,
      errorMessage: action.value
    };
  } else if (action.type === actionTypes.CLEAR_INBOX_ERROR) {
    return {
      ...state,
      error: false,
      errorMessage: undefined
    };
  }

  return state;
};
