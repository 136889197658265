import { Period } from '../../../../types';
import axiosApi from '../../../shared/axios-api';

export class PeriodHttpRequest {
  public static getPeriod(id: number) {
    return axiosApi.get<Period>(`/periods/${id}`);
  }

  public static getNextPeriod(periodId: number) {
    return axiosApi.get<Period>(`/periods/next/${periodId}`);
  }

  public static getPreviousPeriod(periodId: number) {
    return axiosApi.get<Period>(`/periods/previous/${periodId}`);
  }

  public static getLatestSubscriptionPeriod(subscriptionId: number) {
    return axiosApi.get<Period>(`/periods/latest/${subscriptionId}`);
  }
}
