import * as React from 'react';

import classNames from 'classnames';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Switch from 'react-switch';

import { ValidationMode, Indicator } from '../../../../types';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const AtrForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  const maTooltipPopover = (
    <Popover id="maTooltipPopover">
      <span>The number of ATR periods used in each moving average</span>
    </Popover>
  );

  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label htmlFor="atr_periods" className="col-sm-6 control-label">
          <span>ATR Periods (d:14)</span>
          <OverlayTrigger placement="top" overlay={maTooltipPopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg text-info" />
            </span>
          </OverlayTrigger>
        </label>
        <div className="col-sm-6">
          <input
            id="atr_periods"
            type="number"
            step="1"
            min="2"
            className="form-control"
            value={indicator.atrPeriods}
            onChange={(event) => update({ atrPeriods: Number(event.target.value) })}
          />
        </div>
      </div>
      <div className="form-group form-group-sm">
        <label htmlFor="first_ma_periods" className="col-sm-6 control-label">
          <span>1st Periods</span>
        </label>
        <div className="col-sm-6">
          <input
            id="first_ma_periods"
            type="number"
            step="1"
            min="1"
            className="form-control"
            value={indicator.periods}
            onChange={(event) => update({ periods: Number(event.target.value) })}
          />
        </div>
      </div>
      <div className="form-group form-group-sm">
        <label htmlFor="indicator_direction_btn" className="col-sm-6 control-label">
          Mode
        </label>
        <div className="col-sm-6">
          <div className="btn-group btn-group-justified" role="group">
            <div className="btn-group" role="group">
              <button
                key="btn-1"
                type="button"
                className={classNames('btn', 'btn-sm btn-default', 'ripple', { active: indicator.atrMode === ValidationMode.Above })}
                onClick={() => update({ atrMode: ValidationMode.Above })}
              >
                Above 2nd
              </button>
            </div>
            <div className="btn-group" role="group">
              <button
                key="btn-2"
                type="button"
                className={classNames('btn', 'btn-sm btn-default', 'ripple', { active: indicator.atrMode === ValidationMode.Below })}
                onClick={() => update({ atrMode: ValidationMode.Below })}
              >
                Below 2nd
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group form-group-sm">
        <label htmlFor="first_ma_periods" className="col-sm-6 control-label">
          <span>2nd Periods</span>
        </label>
        <div className="col-sm-6">
          <input
            id="first_ma_periods"
            type="number"
            step="1"
            min="1"
            className="form-control"
            value={indicator.secondaryPeriods}
            onChange={(event) => update({ secondaryPeriods: Number(event.target.value) })}
          />
        </div>
      </div>
      <div className="form-group form-group-sm">
        <label className="col-sm-6 control-label">Use Cross Over</label>
        <div className="col-sm-6">
          <label className="react-switch react-switch--sm">
            <Switch onChange={() => update({ useCrossOver: !indicator.useCrossOver })} checked={indicator.useCrossOver} />
          </label>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AtrForm;
