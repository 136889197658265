import { BacktestSummary } from '../../../../types';

export interface SubscriptionSummaryState {
  loading: boolean;
  loaded: boolean;
  error?: string;
  summaries: BacktestSummary[];
}

export const initialSubscriptionSummaryState: SubscriptionSummaryState = {
  loading: false,
  loaded: false,
  error: undefined,
  summaries: []
};
