import * as React from 'react';
import { useEffect, useState } from 'react';

import { VictoryCandlestick, VictoryLine, VictoryScatter } from 'victory';

import { BarSize, GraphQuote } from '../../../../types';
import { GraphConfigState } from '../../store/graph-config/state';

import BaseGraph from './BaseGraph';
import {
  ChartLine,
  barColors,
  CandleStick,
  ScatterPoint,
  mapToCandleStickData,
  mapToTransactionData,
  mapToSmaLine,
  mapToSma2ndLine,
  mapToSma3rdLine,
  mapToKcHigh,
  mapToKcLow,
  mapToBbHigh,
  mapToBbLow,
  mapToPeakData,
  mapToFractalData,
  mapToDcHigh,
  mapToDcLow,
  mapToDcMid,
  mapToDcEntryData,
  mapToResistanceSupportData,
  connectedBarColors
} from './graph-utils';

export interface CandleStickGraphProps {
  graphConfigState: GraphConfigState;
  barSize: BarSize;
  graphData: GraphQuote[];
  expanded?: boolean;
}

const CandleStickGraph = ({ graphConfigState, barSize, graphData, expanded }: CandleStickGraphProps) => {
  const [candleStickData, setCandleStickData] = useState<CandleStick[]>([]);
  const [transactionData, setTransactionData] = useState<ScatterPoint[]>([]);
  const [resistanceSupportData, setResistanceSupportData] = useState<ScatterPoint[]>([]);
  const [smaData, setSmaData] = useState<ChartLine[]>([]);
  const [sma2ndData, setSma2ndData] = useState<ChartLine[]>([]);
  const [sma3rdData, setSma3rdData] = useState<ChartLine[]>([]);
  const [bbHighData, setBbHighData] = useState<ChartLine[]>([]);
  const [bbLowData, setBbLowData] = useState<ChartLine[]>([]);
  const [kcHighData, setKcHighData] = useState<ChartLine[]>([]);
  const [kcLowData, setKcLowData] = useState<ChartLine[]>([]);
  const [peakData, setPeakData] = useState<ScatterPoint[]>([]);
  const [fractalData, setFractalData] = useState<ScatterPoint[]>([]);
  const [dcHighData, setDcHighData] = useState<ChartLine[]>([]);
  const [dcLowData, setDcLowData] = useState<ChartLine[]>([]);
  const [dcMidData, setDcMidData] = useState<ChartLine[]>([]);
  const [dcEntryData, setDcEntryData] = useState<ScatterPoint[]>([]);

  useEffect(() => {
    if (graphData) {
      setCandleStickData(mapToCandleStickData(graphData, graphConfigState.showHeikinAshi, graphConfigState.showConnectedBars));
    }
  }, [graphData, graphConfigState.showHeikinAshi, graphConfigState.showConnectedBars]);

  useEffect(() => {
    if (graphData) {
      setTransactionData(mapToTransactionData(graphData));
    }
  }, [graphData, graphConfigState.showTransactions]);

  useEffect(() => {
    if (graphData) {
      setResistanceSupportData(mapToResistanceSupportData(graphData));
    }
  }, [graphData, graphConfigState.showResistanceSupport]);

  useEffect(() => {
    if (graphData) {
      setPeakData(mapToPeakData(graphData));
    }
  }, [graphData, graphConfigState.showPeaks]);

  useEffect(() => {
    if (graphData) {
      setFractalData(mapToFractalData(graphData));
    }
  }, [graphData, graphConfigState.showWilliamsFractal]);

  useEffect(() => {
    if (graphData) {
      setSmaData(mapToSmaLine(graphData));
    }
  }, [graphData, graphConfigState.showSma]);

  useEffect(() => {
    if (graphData) {
      setSma2ndData(mapToSma2ndLine(graphData));
    }
  }, [graphData, graphConfigState.showSma2nd]);

  useEffect(() => {
    if (graphData) {
      setSma3rdData(mapToSma3rdLine(graphData));
    }
  }, [graphData, graphConfigState.showSma3rd]);

  useEffect(() => {
    if (graphData) {
      setKcHighData(mapToKcHigh(graphData));
      setKcLowData(mapToKcLow(graphData));
    }
  }, [graphData, graphConfigState.showKeltnerChannels]);

  useEffect(() => {
    if (graphData) {
      setBbHighData(mapToBbHigh(graphData));
      setBbLowData(mapToBbLow(graphData));
    }
  }, [graphData, graphConfigState.showBollingerBands]);

  useEffect(() => {
    if (graphData) {
      setDcHighData(mapToDcHigh(graphData));
      setDcLowData(mapToDcLow(graphData));
      setDcMidData(mapToDcMid(graphData));
    }
  }, [graphData, graphConfigState.showDonchianChannels]);

  useEffect(() => {
    if (graphData) {
      setDcEntryData(mapToDcEntryData(graphData));
    }
  }, [graphData, graphConfigState.showDonchianChannels, graphConfigState.showDcEntry]);

  const strokeWidth = expanded ? 0.5 : 1;

  const transactions = graphConfigState.showTransactions ? (
    <VictoryScatter
      size={3}
      data={transactionData}
      style={{
        data: {
          fill: ({ datum }) => datum.fill,
          stroke: 'black',
          strokeWidth
        }
      }}
    />
  ) : undefined;

  const resistanceSupport = graphConfigState.showResistanceSupport ? (
    <VictoryScatter
      size={1}
      data={resistanceSupportData}
      style={{
        data: {
          fill: ({ datum }) => datum.fill,
          stroke: 'black',
          strokeWidth
        }
      }}
    />
  ) : undefined;

  const peaks = graphConfigState.showPeaks ? (
    <VictoryScatter
      size={1}
      data={peakData}
      style={{
        data: {
          fill: ({ datum }) => datum.fill,
          stroke: 'black',
          strokeWidth
        }
      }}
    />
  ) : undefined;

  const fractals = graphConfigState.showWilliamsFractal ? (
    <VictoryScatter
      size={1}
      data={fractalData}
      style={{
        data: {
          fill: ({ datum }) => datum.fill,
          stroke: 'black',
          strokeWidth
        }
      }}
    />
  ) : undefined;

  const dcEntries = graphConfigState.showDcEntry ? (
    <VictoryScatter
      size={1}
      data={dcEntryData}
      style={{
        data: {
          fill: ({ datum }) => datum.fill,
          stroke: 'black',
          strokeWidth
        }
      }}
    />
  ) : undefined;

  const smaChartLine = graphConfigState.showSma ? (
    <VictoryLine
      style={{
        data: { stroke: '#0066cc', strokeWidth }
      }}
      data={smaData}
    />
  ) : undefined;

  const sma2ndChartLine = graphConfigState.showSma2nd ? (
    <VictoryLine
      style={{
        data: { stroke: '#ffcc00', strokeWidth }
      }}
      data={sma2ndData}
    />
  ) : undefined;

  const sma3rdChartLine = graphConfigState.showSma3rd ? (
    <VictoryLine
      style={{
        data: { stroke: '#ff0033', strokeWidth }
      }}
      data={sma3rdData}
    />
  ) : undefined;

  const kcUpper = graphConfigState.showKeltnerChannels ? (
    <VictoryLine
      style={{
        data: { stroke: '#ffccff', strokeWidth }
      }}
      data={kcHighData}
    />
  ) : undefined;

  const kcLower = graphConfigState.showKeltnerChannels ? (
    <VictoryLine
      style={{
        data: { stroke: '#ffccff', strokeWidth }
      }}
      data={kcLowData}
    />
  ) : undefined;

  const bbUpper = graphConfigState.showBollingerBands ? (
    <VictoryLine
      style={{
        data: { stroke: '#6633cc', strokeWidth }
      }}
      data={bbHighData}
    />
  ) : undefined;

  const bbLower = graphConfigState.showBollingerBands ? (
    <VictoryLine
      style={{
        data: { stroke: '#6633cc', strokeWidth }
      }}
      data={bbLowData}
    />
  ) : undefined;

  const dcUpper = graphConfigState.showDonchianChannels ? (
    <VictoryLine
      style={{
        data: { stroke: '#ace1af', strokeWidth }
      }}
      data={dcHighData}
    />
  ) : undefined;

  const dcLower = graphConfigState.showDonchianChannels ? (
    <VictoryLine
      style={{
        data: { stroke: '#de3163', strokeWidth }
      }}
      data={dcLowData}
    />
  ) : undefined;

  const dcMid = graphConfigState.showDonchianChannels ? (
    <VictoryLine
      style={{
        data: { stroke: '#4997d0', strokeWidth }
      }}
      data={dcMidData}
    />
  ) : undefined;

  return (
    <BaseGraph barSize={barSize} height={250} expanded={expanded}>
      <VictoryCandlestick
        candleColors={graphConfigState.showConnectedBars ? connectedBarColors : barColors}
        style={{
          data: {
            stroke: '#c3b091',
            strokeWidth: 1 // "#e6e8fa"
          }
        }}
        data={candleStickData}
      />
      {peaks}
      {fractals}
      {kcUpper}
      {kcLower}
      {bbUpper}
      {bbLower}
      {dcUpper}
      {dcLower}
      {dcMid}
      {sma3rdChartLine}
      {sma2ndChartLine}
      {smaChartLine}
      {dcEntries}
      {resistanceSupport}
      {transactions}
    </BaseGraph>
  );
};

export default CandleStickGraph;
