import { AccountInfo, ScheduleStatus } from '../../../types';

export const getTwsStatusClassName = (loading: boolean, accountInfo: AccountInfo): string => {
  if (loading) {
    return '';
  }

  if (!!accountInfo.accountCode) {
    return 'online';
  } else {
    return 'offline';
  }
};

export const getScheduleStatusClassName = (loading: boolean, status: ScheduleStatus): string => {
  if (loading) {
    return '';
  }
  if (status.isScheduleStarted) {
    return 'online';
  }

  if (status.isAppRunning) {
    return 'pending';
  } else {
    return 'offline';
  }
};
