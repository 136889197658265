import { Map } from 'immutable';

import { Period } from '../../../../types';

export interface PeriodState {
  error?: string;
  loading: boolean;
  period: Period | undefined;
}

export const initalPeriodState: PeriodState = {
  error: undefined,
  loading: false,
  period: undefined
};

export type PeriodMapState = Map<number, PeriodState>;

export interface PartialState {
  periodMapState: PeriodMapState;
}

export const initalPeriodMapState: PeriodMapState = Map();
