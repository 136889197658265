import * as React from 'react';

import { connect } from 'react-redux';

import { GlobalState } from '../../../../core/root';
import { ContractEntity as Contract, Security } from '../../../../types';
import ContractList from '../../../contract/components/ContractList';
import { addContractAsync, loadContractsAsync, removeContractAsync, updateContractAsync } from '../../../contract/store/service';
import Spinner from '../../../shared/components/Spinner';

export interface ContractTabProps {
  security: Security;
  contracts: Contract[];
  loaded: boolean;
  loadContracts(): void;
  addContract(contract: Contract): void;
  updateContract(contract: Contract): void;
  removeContract(id: number): void;
}

class ContractTab extends React.Component<ContractTabProps, null> {
  componentDidMount() {
    if (!this.props.loaded) {
      this.props.loadContracts();
    }
  }

  render() {
    return (
      <div>
        {!this.props.loaded && <Spinner />}
        <ContractList security={this.props.security} contracts={this.props.contracts} />
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState, props: ContractTabProps) => {
  const contracts = props.security ? state.contractState.contracts.filter((x) => x.securityId === props.security.id) : [];
  return {
    loaded: state.contractState.loaded,
    contracts
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadContracts: () => dispatch(loadContractsAsync()),
    addContract: (contract: Contract) => dispatch(addContractAsync(contract)),
    updateContract: (contract: Contract) => dispatch(updateContractAsync(contract)),
    removeContract: (id: number) => dispatch(removeContractAsync(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractTab);
