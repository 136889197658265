import * as React from 'react';
import { useEffect, useState } from 'react';

import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { Indicator, IndicatorType } from '../../../../types';
import CollapsePanel from '../../../shared/components/CollapsePanel';
import { updateIndicatorAsync } from '../../store/service';
import { getIndicatorOptionName, getIndicatorOptionTooltip } from '../IndicatorOptionsPanel/indicator-options';

import AverageDirectionalIndexForm from './AdxForm';
import AmountUpDownForm from './AmountUpDownForm';
import AverageTrueRangeForm from './AtrForm';
import AvgUpDownForm from './AvgUpDownForm';
import AwesomeOscillatorForm from './AwesomeOscillatorForm';
import BollingerBandForm from './BollingerBandForm';
import BreakOutForm from './BreakOutForm';
import CompareForm from './CompareForm';
import ContinuousMaForm from './ContinuousMaForm';
import DonchianChannelsForm from './DonchianChannelsForm';
import GeneralForm from './GeneralForm';
import HighLowSeqForm from './HighLowSeqForm';
import KeltnerChannelForm from './KeltnerChannelForm';
import LeechForm from './LeechForm';
import MacdForm from './MacdForm';
import OpeningHourForm from './OpeningHourForm';
import PullbackForm from './PullbackForm';
import RangeForm from './RangeForm';
import SeasonalForm from './SeasonalForm';
import ShiftForm from './ShiftForm';
import SqueezeForm from './SqueezeForm';
import TheFoxForm from './TheFoxForm';
import ThunnelForm from './ThunnelForm';
import TickForm from './TickForm';
import TradingHoursForm from './TradingHoursForm';
import TradingWeekdaysForm from './TradingWeekdaysForm';
import TrendFollowingAtrForm from './TrendFollowingAtrForm';
import TrendFollowingForm from './TrendFollowingForm';
import TrendScoreForm from './TrendScoreForm';

export interface Props {
  indicator: Indicator;
  close(): void;
}

const IndicatorDialog: React.FunctionComponent<Props> = ({ indicator, close }) => {
  const [indicatorState, setIndicatorState] = useState(indicator);

  const dispatch = useDispatch();

  useEffect(() => {
    setIndicatorState(indicator);
  }, [indicator]);

  const updateIndicatorState = (obj: object) => {
    const field = Object.keys(obj)[0];
    const item = {
      ...indicatorState,
      [field]: obj[field]
    };
    setIndicatorState(item);
  };

  const saveClickHandler = () => {
    dispatch(updateIndicatorAsync(indicatorState));
    close();
  };

  let specificForm: JSX.Element | undefined;
  switch (indicator.type) {
    case IndicatorType.Adx:
      specificForm = <AverageDirectionalIndexForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.AvgUpDown:
      specificForm = <AvgUpDownForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.AmountUpDown:
      specificForm = <AmountUpDownForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.AverageTrueRange:
      specificForm = <AverageTrueRangeForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.BollingerBand:
      specificForm = <BollingerBandForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.BreakOut:
      specificForm = <BreakOutForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.Compare:
      specificForm = <CompareForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.Range:
      specificForm = <RangeForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.Shift:
      specificForm = <ShiftForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.Tick:
      specificForm = <TickForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.Squeeze:
      specificForm = <SqueezeForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.OpeningHour:
      specificForm = <OpeningHourForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.DonchianChannels:
      specificForm = <DonchianChannelsForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.Pullback:
      specificForm = <PullbackForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.MACD:
      specificForm = <MacdForm />;
      break;
    case IndicatorType.AwesomeOscillator:
      specificForm = <AwesomeOscillatorForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.KeltnerChannels:
      specificForm = <KeltnerChannelForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.TrendScore:
      specificForm = <TrendScoreForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.TrendFollowing:
      specificForm = <TrendFollowingForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.TrendFollowingAtr:
      specificForm = <TrendFollowingAtrForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.Season:
      specificForm = <SeasonalForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.TradingHours:
      specificForm = <TradingHoursForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.TradingWeekdays:
      specificForm = <TradingWeekdaysForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.Leech:
      specificForm = <LeechForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.ContinuousMa:
      specificForm = <ContinuousMaForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.TheFox:
      specificForm = <TheFoxForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.Thunnel:
      specificForm = <ThunnelForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
    case IndicatorType.HighLowSeq:
      specificForm = <HighLowSeqForm indicator={indicatorState} update={updateIndicatorState} />;
      break;
  }

  return indicator ? (
    <Modal show={!!indicator} onHide={close} aria-labelledby="contained-modal-title">
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title">
          <span>Indicator</span>
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <form name="indicatorForm" className="form-horizontal">
          <CollapsePanel title="Indicator Settings" upper={true}>
            <GeneralForm indicator={indicatorState} update={updateIndicatorState} />
          </CollapsePanel>

          <CollapsePanel upper={true} title={getIndicatorOptionName(indicator.type)} tooltip={getIndicatorOptionTooltip(indicator.type)}>
            {specificForm}
          </CollapsePanel>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="primary" onClick={saveClickHandler}>
          Save
        </Button>
        <Button onClick={close}>Close</Button>
      </Modal.Footer>
    </Modal>
  ) : null;
};

export default IndicatorDialog;
