import * as React from 'react';

import { Indicator } from '../../../../types';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const SeasonalForm: React.FunctionComponent<Props> = () => {
  return (
    <p>
      <small>No custom settings..</small>
    </p>
  );
};

export default SeasonalForm;
