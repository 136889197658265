import * as React from 'react';
import { FunctionComponent } from 'react';

import classNames from 'classnames';

import { OrderDirection, Period } from '../../../types';

interface Props {
  period: Period;
}

const PeriodResultPanel: FunctionComponent<Props> = ({ period }: Props) => {
  const header = (
    <tr>
      <td className="middle small">Name</td>
      <td className="middle small">Direction</td>
      <td className="middle small text-center">Valid</td>
      <td className="middle small" style={{ minWidth: '150px' }}>
        Message
      </td>
    </tr>
  );

  const timeRow = (
    <tr>
      <td className="middle small">Time</td>
      <td className="middle small vborder">{OrderDirection[period.forecastDirection]}</td>
      <td className="middle small text-center">
        <i
          className={classNames('fa', {
            'fa-check-square-o': period.timeValid,
            'text-success': period.timeValid,
            'fa-square-o': !period.timeValid
          })}
        ></i>
      </td>
      <td className="middle small vborder" style={{ minWidth: '150px' }}>
        {period.timeMessage}
      </td>
    </tr>
  );

  const riskRow = (
    <tr>
      <td className="middle small">Risk</td>
      <td className="middle small vborder">{OrderDirection[period.forecastDirection]}</td>
      <td className="middle small text-center">
        <i
          className={classNames('fa', {
            'fa-check-square-o': period.riskValid,
            'text-success': period.riskValid,
            'fa-square-o': !period.riskValid
          })}
        ></i>
      </td>
      <td className="middle small vborder" style={{ minWidth: '150px' }}>
        {period.riskMessage}
      </td>
    </tr>
  );

  return (
    <div className="table-responsive">
      <table className="table table-hover table-striped table-condensed">
        <thead>{header}</thead>
        <tbody>
          {timeRow}
          {riskRow}
        </tbody>
      </table>
    </div>
  );
};

export default PeriodResultPanel;
