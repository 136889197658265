import * as React from 'react';
import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { GlobalState } from '../../../../core/root';
import { Position, BarSize, ContractPartial, Period } from '../../../../types';
import { loadContractsAsync } from '../../../contract/store/service';
import Spinner from '../../../shared/components/Spinner';

import GraphDialogComponent from './GraphDialogComponent';

export interface GraphDialogProps {
  show: boolean;
  toggle(): void;
  period?: Period;
  position?: Position;
  partial?: ContractPartial;
  barSize?: BarSize;
}

const GraphDialog: React.FunctionComponent<GraphDialogProps> = ({ show, toggle, period, position, partial, barSize }) => {
  const contractsLoaded: boolean = useSelector((state: GlobalState) => state.contractState.loaded);
  const contracts = useSelector((state: GlobalState) => state.contractState.contracts);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!contractsLoaded) {
      dispatch(loadContractsAsync());
    }
  }, []);

  if (!contractsLoaded) {
    return <Spinner />;
  }

  let contractId: number | undefined;
  let contractPartialId = 0;
  let strategyId = 0;
  let lastBarTime: Date;
  let graphBarSize: BarSize;

  if (period) {
    contractId = period.contractId;
    lastBarTime = period.created;
    graphBarSize = period.periodBarSize;
  } else if (position) {
    contractId = position.contractId;
    lastBarTime = position.exited || position.updated || position.created;
    graphBarSize = position.strategy.periodBarSize;
    if (position.backtest) {
      contractPartialId = position.backtest.contractPartialId as number;
      strategyId = position.strategyId as number;
    }
  } else if (partial) {
    contractPartialId = partial.id;
    contractId = partial.contractId;
    lastBarTime = partial.periodEnd;
    graphBarSize = barSize as BarSize;
  } else {
    return null;
  }

  const contract = contracts.find((x) => x.id === contractId);

  return (
    <GraphDialogComponent
      show={show}
      toggle={toggle}
      contract={contract}
      contractPartialId={contractPartialId}
      strategyId={strategyId}
      barSize={graphBarSize}
      lastBarTime={lastBarTime}
    />
  );
};

export default GraphDialog;
