import { showNetworkError } from '../../../shared/utils/growl-utils';

import { setOpenOrders, setOpenOrdersError, setOpenOrdersLoading } from './action-creators';
import { OpenOrderHttpRequest } from './http-request';

export const getOpenOrdersAsync = () => {
  return (dispatch) => {
    dispatch(setOpenOrdersLoading());
    const promise = OpenOrderHttpRequest.getOpenOrders();
    if (!promise) {
      return;
    }
    promise
      .then((response) => {
        dispatch(setOpenOrders(response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load open orders');
        dispatch(setOpenOrdersError(msg));
      });
  };
};

export const updateOpenOrdersAsync = () => {
  return (dispatch) => {
    dispatch(setOpenOrdersLoading());
    const promise = OpenOrderHttpRequest.updateOpenOrders();
    if (!promise) {
      return;
    }
    promise
      .then((response) => {
        dispatch(setOpenOrders(response.data));
      })
      .catch((error) => {
        showNetworkError(error, 'Failed to update open orders');
      });
  };
};
