import { PositionTypeOption, positionTypeOptions } from '../../utils/position-type-option';

export interface LayoutState {
  ui: {
    //activeContractsCollapsed: boolean;
    activeStrategiesCollapsed: boolean;
    periodsCollapsed: boolean;
    openPositionsCollapsed: boolean;
    closedPositionsCollapsed: boolean;
    closedPositionsFilterCollapsed: boolean;
    contractPartialTabSelected?: number;
    backtestSummariesPanelCollapsed: boolean;
    createIndicatorPanelCollapsed: boolean;
    browseIntradayCollapsed: boolean;
    showRaceView: boolean;
    positionTypeOptions: PositionTypeOption[];
  };
}

export const initalLayoutState: LayoutState = {
  ui: {
    //activeContractsCollapsed: true,
    activeStrategiesCollapsed: true,
    periodsCollapsed: false,
    openPositionsCollapsed: false,
    closedPositionsCollapsed: false,
    closedPositionsFilterCollapsed: true,
    backtestSummariesPanelCollapsed: false,
    createIndicatorPanelCollapsed: false,
    browseIntradayCollapsed: false,
    showRaceView: true,
    positionTypeOptions
  }
};
