import { AnyAction } from 'redux';

import { Position } from '../../../../types';

import * as actionTypes from './action-types';
import { initalPositionSelectedMapState, initalPositionSelectedState } from './state';

export const positionSelectedReducer = (state = initalPositionSelectedMapState, action: AnyAction) => {
  const key = action.positionId;
  const current = state.get(key) || { ...initalPositionSelectedState };

  if (action.type === actionTypes.SET_POSITION_SELECTED_LOADED) {
    const loaded = action.loaded === true;
    return state.set(key, {
      ...current,
      loaded
    });
  } else if (action.type === actionTypes.SET_POSITION_SELECTED_ERROR) {
    return state.set(key, {
      ...current,
      loaded: true,
      error: action.error
    });
  } else if (action.type === actionTypes.SET_POSITION_SELECTED) {
    const position = action.position as Position | undefined;
    return state.set(key, {
      loaded: true,
      error: undefined,
      position
    });
  }

  return state;
};
