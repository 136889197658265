import * as React from 'react';
import { useEffect } from 'react';

import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useSelector, useDispatch } from 'react-redux';

import { GlobalState } from '../../../../core/root';
import { BarSize, ContractPartial, Strategy } from '../../../../types';
import { formatPartialMonth } from '../../../contract-partial/utils';
import { loadContractsAsync } from '../../../contract/store/service';
import Spinner from '../../../shared/components/Spinner';
import { getBarSizeName } from '../../../shared/utils/bar-utils';
import { loadStrategiesAsync } from '../../../strategy/store/services';
import Graph from '../Graph';

const useStyles = createUseStyles({
  graphPanel: {
    border: '1px solid #464545',
    borderRadius: '0.5rem'
  },
  header: {
    padding: '0.5rem 0.8rem'
  },
  headerBtn: {
    color: '#959595',
    padding: '5px',
    '&:hover': {
      cursor: 'pointer',
      color: '#ccc'
    }
  },
  headerText: {
    display: 'inline-block',
    marginTop: '8px',
    fontSize: '11px',
    color: '#959595',
    textTransform: 'uppercase',
    letterSpacing: '1px'
  }
});

export interface GraphPanelProps {
  guid: string;
  expand(guid: string): void;
  expanded: boolean;
  close(guid: string): void;
  partial: ContractPartial;
  barSize: BarSize;
  lastDate: Date;
  strategyId?: number;
}

const GraphPanel: React.FunctionComponent<GraphPanelProps> = ({
  guid,
  expand,
  expanded,
  close,
  partial,
  barSize,
  lastDate,
  strategyId
}) => {
  const contractsLoaded: boolean = useSelector((state: GlobalState) => state.contractState.loaded);
  const contracts = useSelector((state: GlobalState) => state.contractState.contracts);
  const strategiesLoaded: boolean = useSelector((state: GlobalState) => state.strategyState.loaded);
  const strategies = useSelector((state: GlobalState) => state.strategyState.strategies);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!contractsLoaded) {
      dispatch(loadContractsAsync());
    }
    if (!strategiesLoaded) {
      dispatch(loadStrategiesAsync());
    }
  }, []);

  if (!contractsLoaded) {
    return <Spinner />;
  }

  const contract = contracts.find((x) => x.id === partial.contractId);
  const strategy: Strategy = strategyId && strategyId > 0 ? strategies.find((x) => x.id === strategyId) : undefined;
  const selectedStrategy = strategy ? (
    <div className="col-xs-12">
      <span className={classes.headerText}>Strategy: {strategy.name}</span>
    </div>
  ) : undefined;

  return (
    <div className={classes.graphPanel}>
      <div className="row">
        <div className={classes.header}>
          <div className="col-xs-8">
            <span className="label label-default">{contract.localSymbol}</span>
            <span className="label label-default gap-left-5">{formatPartialMonth(partial.partialMonth)}</span>
            <span className="label label-success gap-left-5">{getBarSizeName(barSize)}</span>
          </div>
          <div className="col-xs-4 text-right">
            <span className={classes.headerBtn} onClick={() => expand(guid)}>
              <i className={classNames('fa', 'fa-lg', { 'fa-expand': !expanded, 'fa-compress': expanded })} />
            </span>
            <span className={classes.headerBtn} onClick={() => close(guid)}>
              <i className="fa fa-lg fa-close" />
            </span>
          </div>
          {selectedStrategy}
        </div>
      </div>
      <Graph
        guid={guid}
        contract={contract}
        contractPartialId={partial.id}
        barSize={barSize}
        lastBarTime={lastDate}
        strategyId={strategyId}
        partialPeriodStart={partial.periodStart}
        partialPeriodEnd={partial.periodEnd}
        expanded={expanded}
      />
    </div>
  );
};

export default GraphPanel;
