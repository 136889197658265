import { Message } from '../../../../types';

import * as actionTypes from './action-types';
import { InboxHttpRequest } from './inbox-http-request';

export const updateMessage = (message: Message) => {
  return {
    type: actionTypes.UPDATE_MESSAGE,
    payload: message
  };
};

export const setMessages = (messages: Message[]) => {
  return {
    type: actionTypes.SET_MESSAGES,
    payload: messages
  };
};

export const setInboxError = (msg: string) => {
  return {
    type: actionTypes.SET_INBOX_ERROR,
    value: msg
  };
};

export const clearInboxError = () => {
  return {
    type: actionTypes.CLEAR_INBOX_ERROR
  };
};

/*** ASYNC WITH THUNK ***/

export const loadInboxAsync = () => {
  return (dispatch) => {
    InboxHttpRequest.get()
      .then((response) => {
        // console.log(response);
        dispatch(setMessages(response.data));
      })
      .catch((error) => {
        dispatch(setInboxError(error));
      });
  };
};

export const updateMessageAsync = (message: Message) => {
  return (dispatch) => {
    InboxHttpRequest.toggleArchived(message.id, message.archived)
      .then(() => {
        dispatch(updateMessage(message));
      })
      .catch((error) => {
        dispatch(setInboxError(error));
      });
  };
};
