import { ContractEntity as Contract } from '../../../types';

import * as actionTypes from './action-types';

export interface ContractAction {
  type: string;
  contract?: Contract;
  id?: number;
  contracts?: Contract[];
  error?: string;
  loaded?: boolean;
}

export const addContract = (contract: Contract): ContractAction => {
  return {
    type: actionTypes.ADD_CONTRACT,
    contract
  };
};

export const updateContract = (contract: Contract): ContractAction => {
  return {
    type: actionTypes.UPDATE_CONTRACT,
    contract
  };
};

export const removeContract = (id: number): ContractAction => {
  return {
    type: actionTypes.REMOVE_CONTRACT,
    id
  };
};

export const setContracts = (contracts: Contract[]): ContractAction => {
  return {
    type: actionTypes.SET_CONTRACTS,
    contracts
  };
};

export const setContractError = (error: string): ContractAction => {
  return {
    type: actionTypes.SET_CONTRACT_ERROR,
    error
  };
};

export const setContractsLoaded = (loaded: boolean): ContractAction => {
  return {
    type: actionTypes.SET_CONTRACTS_LOADED,
    loaded
  };
};
