import { BacktestSummary } from '../../../../types';

import { BacktestSummaryAction } from './action-creators';
import * as actionTypes from './action-types';
import { initialBacktestSummaryMapState, initialBacktestSummaryState } from './state';

export const backtestSummaryReducer = (state = initialBacktestSummaryMapState, action: BacktestSummaryAction) => {
  const key = action.securityId;
  const current = state.get(key) || { ...initialBacktestSummaryState };

  if (action.type === actionTypes.SET_BACKTEST_SUM_LOADING) {
    const loading = action.loading === true;
    return state.set(key, {
      ...current,
      loading,
      loaded: !loading
    });
  } else if (action.type === actionTypes.SET_BACKTEST_SUM_ERROR) {
    return state.set(key, {
      ...current,
      loading: false,
      error: action.error
    });
  } else if (action.type === actionTypes.SET_BACKTEST_SUM_CLEAR_ERROR) {
    return state.set(key, {
      ...current,
      error: undefined
    });
  } else if (action.type === actionTypes.SET_BACKTEST_SUM_LIST) {
    const summaries = action.summaries as BacktestSummary[];
    return state.set(key, {
      loading: false,
      loaded: true,
      error: undefined,
      summaries
    });
  }

  return state;
};
