import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { GlobalState } from '../../../core/root';
import { Period, PeriodRequest, ScheduleStatus, Subscription } from '../../../types';
import IntradayNav from '../../shared/components/IntradayNav';
import QuickNavBar from '../../shared/components/QuickNavBar';
import Spinner from '../../shared/components/Spinner';
import SubscriptionListToolbar from '../../shared/components/SubscriptionListToolbar';
import { getSystemTime } from '../../shared/utils/broker-time';
import { convertToDate } from '../../shared/utils/date-utils';
import { loadSubscriptionsAsync } from '../../subscription/store/action-creators';
import * as actionCreators from '../store/period-range/action-creators';
import { getPeriodRange } from '../store/period-range/service';

import PeriodTable from './PeriodTable';

const PeriodDayScreen = () => {
  const state: GlobalState = useSelector((gs: GlobalState) => gs);
  const subscriptionsLoaded: boolean = state.subscriptionState.loaded;
  const intradayPeriods: Period[] = state.periodRangeState.periods;
  const periodRangeStateLoading: boolean = state.periodRangeState.loading;
  const periodDay: Date | undefined = state.periodRangeState.ui.periodDay;
  const selectedSubscriptionId: number = state.periodRangeState.ui.selectedSubscriptionId;
  const loading = !subscriptionsLoaded || periodRangeStateLoading;
  const status: ScheduleStatus = state.scheduleStatusState.status;

  const [systemTime, setSystemTime] = useState<Date>();

  const dispatch = useDispatch();

  const getIntradayPeriods = (dt: Date) => {
    const fromDt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
    const endDt = new Date(fromDt.getTime());
    endDt.setDate(fromDt.getDate() + 1);
    const request: PeriodRequest = {
      fromDate: fromDt,
      toDate: endDt
    };
    dispatch(getPeriodRange(request));
  };

  const updatePeriodDay = (day: Date) => {
    dispatch(actionCreators.setPeriodDay(day));
  };

  useEffect(() => {
    if (!subscriptionsLoaded) {
      dispatch(loadSubscriptionsAsync());
    }
  }, []);

  useEffect(() => {
    const tm = getSystemTime(status.webApiTimeZone);
    setSystemTime(tm);
  }, [status.webApiTimestamp]);

  useEffect(() => {
    if (systemTime && !periodDay) {
      updatePeriodDay(systemTime);
    }
  }, [systemTime]);

  useEffect(() => {
    const dt = convertToDate(periodDay);
    if (dt) {
      getIntradayPeriods(dt);
    } else {
      dispatch(actionCreators.setPeriodRange([]));
      const systemTime = getSystemTime(status.webApiTimeZone);
      updatePeriodDay(systemTime);
    }
  }, [periodDay]);

  const toggleSelectedSubscription = (sub: Subscription) => {
    const val = sub.id === selectedSubscriptionId ? 0 : sub.id;
    dispatch(actionCreators.setSelectedSubscriptionId(val));
  };

  const filteredIntradayPeriods = intradayPeriods.filter((x) => {
    if (selectedSubscriptionId == 0) {
      return true;
    }
    return selectedSubscriptionId === x.subscriptionId;
  });

  let infoMessage: JSX.Element | undefined;
  if (periodRangeStateLoading) {
    infoMessage = undefined;
  } else if (filteredIntradayPeriods.length == 0) {
    infoMessage = <span className="small">No periods..</span>;
  }

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item active">Periods</li>
      </ol>

      <QuickNavBar />

      <IntradayNav intraday={periodDay} setIntraday={updatePeriodDay} />

      {loading && <Spinner />}

      {subscriptionsLoaded && (
        <SubscriptionListToolbar selectedSubscriptionId={selectedSubscriptionId} onToggleSubscription={toggleSelectedSubscription} />
      )}

      <div className="section">
        <hr />
        <p>Periods</p>
      </div>

      {infoMessage}

      {filteredIntradayPeriods.length > 0 && <PeriodTable periods={filteredIntradayPeriods} single={false} />}
    </div>
  );
};

export default PeriodDayScreen;
