import * as React from 'react';

import classNames from 'classnames';
import { Modal, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { GlobalState } from '../../../../core/root';
import { Message, MessageType } from '../../../../types';
// import '../../../types/enums';
import * as inboxActionCreators from '../../store/inbox/action-creators';

export interface InboxDialogProps {
  show: boolean;
  toggle(): void;
}

const InboxDialog: React.SFC<InboxDialogProps> = (props) => {
  const messages = useSelector((state: GlobalState) => state.inboxState.messages);
  const dispatch = useDispatch();

  const archiveMessage = (message: Message) => {
    message.archived = true;
    dispatch(inboxActionCreators.updateMessageAsync(message));
  };

  const getAlertClass = (type: MessageType) => {
    switch (type) {
      case MessageType.Profit:
        return 'alert-success';
      case MessageType.Loss:
        return 'alert-warning';
      case MessageType.ConnectionLost:
        return 'alert-danger';
      case MessageType.PositionOffset:
        return 'alert-danger';
      case MessageType.PositionOffsetFixed:
        return 'alert-warning';
      case MessageType.OpenOrderOffset:
        return 'alert-danger';
      case MessageType.OpenOrderOffsetFixed:
        return 'alert-warning';
      default:
        return 'alert-info';
    }
  };

  const messageItems = messages
    .filter((x: Message) => !x.archived)
    .map((x: Message) => (
      <div key={x.id} className={classNames('alert', 'alert-dismissible', getAlertClass(x.type))} role="alert">
        <button
          type="button"
          onClick={() => {
            archiveMessage(x);
          }}
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <strong>{x.localTime}</strong> {x.content}
      </div>
    ));

  return (
    <Modal show={props.show} onHide={props.toggle} aria-labelledby="contained-modal-title">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title">Inbox</Modal.Title>
      </Modal.Header>
      <Modal.Body>{messageItems.length > 0 ? messageItems : <p>No messages..</p>}</Modal.Body>
      <Modal.Footer>
        <Button onClick={props.toggle}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InboxDialog;
