import * as React from 'react';

import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';

import { GlobalState } from '../../../core/root';
import { Position, Security, Strategy } from '../../../types';
import { PositionTypeOption } from '../../shared/utils/position-type-option';
import { getFilteredPositions } from '../utils/position-filter';

import ClosedPositionTable from './ClosedPositionTable';
import PositionFilter from './PositionFilter';

export interface Props extends RouteComponentProps {
  positions: Position[];
  showRows: boolean;
  showSummary: boolean;
  loading?: boolean;
  desc?: boolean;
}

const FilteredPositionListPanel: React.FunctionComponent<Props> = ({ positions, showRows, showSummary, loading, desc }: Props) => {
  const selectedSecurity: Security | undefined = useSelector((state: GlobalState) => state.securityState.ui.securityPicked);
  const selectedStrategy: Strategy | undefined = useSelector((state: GlobalState) => state.strategyState.ui.strategyPicked);
  const posTypeOptions: PositionTypeOption[] = useSelector((state: GlobalState) => state.layoutState.ui.positionTypeOptions);

  const sortBy = (a: Position, b: Position) => {
    if (desc) {
      if (!a.exited || !b.exited) return 1;
      if (a.exited > b.exited) return -1;
      if (a.exited < b.exited) return 1;
    } else {
      if (!a.exited || !b.exited) return -1;
      if (a.exited > b.exited) return 1;
      if (a.exited < b.exited) return -1;
    }
    return 0;
  };

  const filteredPositions: Position[] = getFilteredPositions(positions, selectedSecurity, selectedStrategy, posTypeOptions).sort(sortBy);

  return (
    <div>
      <PositionFilter />
      {!loading && positions && positions.length === 0 && <p className="small">No positions..</p>}
      {!loading && positions && positions.length > 0 && filteredPositions.length === 0 && <p className="small">No FILTERED positions..</p>}
      {filteredPositions.length > 0 && <ClosedPositionTable positions={filteredPositions} showRows={showRows} showSummary={showSummary} />}
    </div>
  );
};

export default withRouter(FilteredPositionListPanel);
