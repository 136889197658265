import { OpenOrderViewModel } from '../../../../types';
import axiosApi from '../../../shared/axios-api';

export class OpenOrderHttpRequest {
  public static getOpenOrders() {
    return axiosApi.get<OpenOrderViewModel[]>('/broker-order');
  }
  public static updateOpenOrders() {
    return axiosApi.post<OpenOrderViewModel[]>('/broker-order');
  }
}
