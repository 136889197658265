export interface ExchangeType {
  key: string;
  name: string;
  desc: string;
}

export const exchanges: ExchangeType[] = [
  {
    key: 'CME',
    name: 'CME Globex',
    desc: `Futures (Agriculture, Currency, Energy, Equity Index, Fixed Income)
  Futures Options (Currency, Equity Index)
  Indices`
  },
  {
    key: 'CBOT',
    name: 'Chicago Board Of Trade',
    desc: `Futures (Agriculture, Equity Index, Fixed Income)
  Futures Options (Agriculture, Equity Index, Fixed Income)
  Indices`
  },
  {
    key: 'NYMEX',
    name: 'New York Mercantile Exchange',
    desc: `Futures (Energy, Metals, Soft Commodities)
  Futures Options (Energy, Metals)
  Indices`
  },
  { key: 'COMEX', name: 'The Commodity Exchange', desc: `Futures and commodities` }
];
