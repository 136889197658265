import * as React from 'react';
import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { BacktestSummary as Summary } from '../../../../types';
import CategoryFilterPanel from '../../../security/components/CategoryFilterPanel';
import SecurityPickerPanel from '../../../security/components/SecurityPickerPanel';
import CollapsePanel from '../../../shared/components/CollapsePanel';
import Spinner from '../../../shared/components/Spinner';
import { setBacktestSummariesPanelCollapsed } from '../../../shared/store/layout/action-creators';
import { loadStrategiesAsync } from '../../../strategy/store/services';
import * as subscriptionActionCreators from '../../../subscription/store/action-creators';
import { ModuleState } from '../../state';
import { getBacktestSummaries, getBacktestSummariesLoaded, shouldFetchSummaries } from '../../store/backtest-summary/selectors';
import { loadBacktestSummariesAsync } from '../../store/backtest-summary/service';
import { loadSubscriptionSummariesAsync } from '../../store/subscription-summary/service';

import BacktestSummaryTable from './BacktestSummaryTable';

const BacktestSummaryScreen = () => {
  const state: ModuleState = useSelector((gs: ModuleState) => gs);

  const backtestSummariesPanelCollapsed = state.layoutState.ui.backtestSummariesPanelCollapsed;
  const toggleBacktestSummariesPanelCollapsed = () => dispatch(setBacktestSummariesPanelCollapsed(!backtestSummariesPanelCollapsed));

  const securitiesLoaded: boolean = state.securityState.loaded;
  const selectedSecurity = state.securityState.ui.securityPicked;

  const strategiesLoaded = state.strategyState.loaded;
  const subscriptionsLoaded = state.subscriptionState.loaded;

  let summaries: Summary[] = [];
  let shouldFetch = false;
  let summariesLoaded = true;
  if (selectedSecurity) {
    summaries = getBacktestSummaries(state, selectedSecurity.id) || [];
    shouldFetch = shouldFetchSummaries(state, selectedSecurity.id);
    summariesLoaded = getBacktestSummariesLoaded(state, selectedSecurity.id);
  }

  const subscriptionSummaries: Summary[] = state.subscriptionSummaryState.summaries;
  const subscriptionSummariesLoaded = state.subscriptionSummaryState.loaded;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!strategiesLoaded) {
      dispatch(loadStrategiesAsync());
    }
    if (!subscriptionsLoaded) {
      dispatch(subscriptionActionCreators.loadSubscriptionsAsync());
    }
    if (!subscriptionSummariesLoaded) {
      dispatch(loadSubscriptionSummariesAsync());
    }
  }, []);

  useEffect(() => {
    if (selectedSecurity && shouldFetch) {
      dispatch(loadBacktestSummariesAsync(selectedSecurity.id));
    }
  }, [selectedSecurity]);

  const reloadSubscriptionSummaries = () => {
    dispatch(loadSubscriptionSummariesAsync());
  };

  const reloadBacktestSummaries = () => {
    if (selectedSecurity) {
      dispatch(loadBacktestSummariesAsync(selectedSecurity.id));
    }
  };

  const loading = !securitiesLoaded || !strategiesLoaded || !summariesLoaded || !subscriptionSummariesLoaded;

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item active">Backtest Summaries</li>
      </ol>

      <div className="page-header">
        <h3>
          Backtest Summaries <small className="no-wrap"></small>
        </h3>
      </div>

      {loading && <Spinner />}

      <CollapsePanel bsStyle="success" title="Subscribed Strategies" collapsed={false}>
        <BacktestSummaryTable summaries={subscriptionSummaries} onReload={reloadSubscriptionSummaries} showSecurityNames={true} />
      </CollapsePanel>

      <SecurityPickerPanel upper={true}>
        <CategoryFilterPanel />
      </SecurityPickerPanel>

      <CollapsePanel
        title="Backtest Summaries"
        collapsed={backtestSummariesPanelCollapsed}
        onToggle={toggleBacktestSummariesPanelCollapsed}
      >
        {selectedSecurity ? (
          <BacktestSummaryTable summaries={summaries} onReload={reloadBacktestSummaries} />
        ) : (
          <span className="small">No security selected..</span>
        )}
      </CollapsePanel>
    </div>
  );
};

export default withRouter(BacktestSummaryScreen);
