export const UI_COLLAPSE_PANEL_ACTIVE_CONTRACTS = 'UI_COLLAPSE_PANEL_ACTIVE_CONTRACTS';
export const UI_COLLAPSE_PANEL_ACTIVE_STRATEGIES = 'UI_COLLAPSE_PANEL_ACTIVE_STRATEGIES';
export const UI_COLLAPSE_PANEL_PERIODS = 'UI_COLLAPSE_PANEL_PERIODS';
export const UI_COLLAPSE_BROWSE_INTRADAY_PANEL = 'UI_COLLAPSE_BROWSE_INTRADAY_PANEL';
export const UI_COLLAPSE_PANEL_OPEN_POSITIONS = 'UI_COLLAPSE_PANEL_OPEN_POSITIONS';
export const UI_COLLAPSE_PANEL_CLOSED_POSITIONS = 'UI_COLLAPSE_PANEL_CLOSED_POSITIONS';
export const UI_COLLAPSE_PANEL_CLOSED_POSITIONS_FILTER = 'UI_COLLAPSE_PANEL_CLOSED_POSITIONS_FILTER';
export const UI_CONTRACT_PARTIAL_TAB = 'UI_CONTRACT_PARTIAL_TAB';
export const UI_COLLAPSE_PANEL_BACKTEST_SUMMARIES = 'UI_COLLAPSE_PANEL_BACKTEST_SUMMARIES';
export const UI_COLLAPSE_PANEL_CREATE_INDICATOR = 'UI_COLLAPSE_PANEL_CREATE_INDICATOR';
export const UI_SET_SHOW_RACE_VIEW = 'UI_SET_SHOW_RACE_VIEW';
export const UI_SET_POS_TYPE_OPTIONS = 'UI_SET_POS_TYPE_OPTIONS';
