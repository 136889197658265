import { Position } from '../../../../types';
import { showNetworkError } from '../../../shared/utils/growl-utils';

import { setPositionSelected, setPositionSelectedLoaded, setPositionSelectedError } from './action-creators';
import { PositionSelectedHttpRequest } from './http-request';

export const fetchPositionSelectedAsync = (positionId: number) => {
  return (dispatch) => {
    dispatch(setPositionSelected(positionId, undefined));
    dispatch(setPositionSelectedLoaded(positionId, false));
    PositionSelectedHttpRequest.getPosition(positionId)
      .then((response) => {
        dispatch(setPositionSelected(positionId, response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load selected position');
        dispatch(setPositionSelectedError(positionId, msg));
      });
  };
};

export const updatePositionSelectedAsync = (positionId: number, position: Position) => {
  return (dispatch) => {
    dispatch(setPositionSelectedLoaded(positionId, false));
    PositionSelectedHttpRequest.updatePosition(position)
      .then((response) => {
        dispatch(setPositionSelected(positionId, response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to update selected position');
        dispatch(setPositionSelectedError(positionId, msg));
      });
  };
};
