import { GraphQuote, GraphRequest } from '../../../../types';
import { showNetworkError } from '../../../shared/utils/growl-utils';

import * as actionTypes from './action-types';
import { GraphDataHttpRequest } from './http-request';

export const addGraphData = (guid: string, data: GraphQuote[]) => {
  return {
    type: actionTypes.ADD_GRAPH_DATA,
    payload: {
      key: guid,
      data
    }
  };
};

export const removeGraphData = (guid: string) => {
  return {
    type: actionTypes.REMOVE_GRAPH_DATA,
    value: guid
  };
};

export const setGraphDataError = (guid: string, msg: string) => {
  return {
    type: actionTypes.SET_GRAPH_DATA_ERROR,
    payload: {
      key: guid,
      msg
    }
  };
};

export const setGraphDataLoaded = (guid: string, loaded: boolean) => {
  return {
    type: actionTypes.SET_GRAPH_DATA_LOADED,
    payload: {
      guid,
      loaded
    }
  };
};

/*** ASYNC WITH THUNK ***/

export const loadGraphDataAsync = (guid: string, graphRequest: GraphRequest) => {
  return (dispatch) => {
    const promise = GraphDataHttpRequest.get(graphRequest);
    if (!promise) {
      return;
    }
    promise
      .then((response) => {
        dispatch(addGraphData(guid, response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load graph-data');
        dispatch(setGraphDataError(error, msg));
      });
  };
};
