import { AnyAction } from 'redux';

import * as actionTypes from './action-types';
import { GraphTile, initialGraphTileState } from './state';

export const graphTileReducer = (state = initialGraphTileState, action: AnyAction) => {
  if (action.type === actionTypes.ADD_GRAPH_TILE) {
    const tile = action.payload as GraphTile;
    const copy = {
      ...tile,
      partial: { ...tile.partial }
    };
    const tiles = [...state.tiles];
    tiles.push(copy);
    return {
      tiles
    };
  } else if (action.type === actionTypes.UPDATE_GRAPH_TILE) {
    const tile = action.payload as GraphTile;
    const item = state.tiles.find((x) => x.guid === tile.guid);
    if (!item) {
      return state;
    }
    const index = state.tiles.indexOf(item);
    const copy = {
      ...tile,
      partial: {
        ...tile.partial,
        contract: { ...tile.partial.contract }
      }
    };
    const tiles = [...state.tiles];
    tiles[index] = copy;
    return {
      tiles
    };
  } else if (action.type === actionTypes.REMOVE_GRAPH_TILE) {
    const tile = action.payload as GraphTile;
    const item = state.tiles.find((x) => x.guid === tile.guid);
    if (!item) {
      return state;
    }
    const index = state.tiles.indexOf(item);
    const tiles = [...state.tiles];
    tiles.splice(index, 1);
    return {
      tiles
    };
  }

  return state;
};
