import * as React from 'react';

import classNames from 'classnames';
import Switch from 'react-switch';

import { ValidationMode, Indicator } from '../../../../types';
import { basisTypes } from '../../utils/basis-utils';
import { compareTypes } from '../../utils/compare-utils';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const CompareForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label htmlFor="compare_type" className="col-xs-12 col-sm-6 control-label">
          Compare Type
        </label>
        <div className="col-sm-6">
          <select
            id="compare_type"
            className="form-control"
            value={indicator.compareType}
            onChange={(event) => update({ compareType: Number(event.target.value) })}
          >
            {compareTypes.map((x) => (
              <option key={x.key} value={x.key}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="compare_basis_type" className="col-xs-12 col-sm-6 control-label">
          What To Compare
        </label>
        <div className="col-sm-6">
          <select
            id="compare_basis_type"
            className="form-control"
            value={indicator.basisType}
            onChange={(event) => update({ basisType: Number(event.target.value) })}
          >
            {basisTypes.map((x) => (
              <option key={x.key} value={x.key}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="compare_1_periods" className="col-sm-6 control-label">
          1st Periods
        </label>
        <div className="col-sm-6">
          <input
            id="compare_1_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.periods}
            onChange={(event) => update({ periods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="compare_mode" className="col-sm-6 control-label">
          Mode
        </label>
        <div className="col-sm-6">
          <div className="btn-group btn-group-justified" role="group">
            <div className="btn-group" role="group">
              <button
                key="btn-1"
                type="button"
                className={classNames('btn', 'btn-sm btn-default', 'ripple', { active: indicator.atrMode === ValidationMode.Above })}
                onClick={() => update({ atrMode: ValidationMode.Above })}
              >
                Above 2nd
              </button>
            </div>
            <div className="btn-group" role="group">
              <button
                key="btn-2"
                type="button"
                className={classNames('btn', 'btn-sm btn-default', 'ripple', { active: indicator.atrMode === ValidationMode.Below })}
                onClick={() => update({ atrMode: ValidationMode.Below })}
              >
                Below 2nd
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="compare_2_periods" className="col-sm-6 control-label">
          2nd Periods
        </label>
        <div className="col-sm-6">
          <input
            id="compare_2_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.secondaryPeriods}
            onChange={(event) => update({ secondaryPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="section">
        <hr />
        <p>Options</p>
      </div>

      <div className="form-group form-group-sm">
        <label className="col-sm-6 control-label">Use ATR Buffer</label>
        <div className="col-sm-6">
          <label className="react-switch react-switch--sm">
            <Switch
              onChange={() => update({ useTopWickThresholds: !indicator.useTopWickThresholds })}
              checked={indicator.useTopWickThresholds}
            />
          </label>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="compare_atr_buffer" className="col-sm-6 control-label">
          ATR Buffer Multiplier
        </label>
        <div className="col-sm-6">
          <input
            id="compare_atr_buffer"
            type="number"
            step="any"
            className={classNames('form-control', { 'inactive-control': !indicator.useTopWickThresholds })}
            value={indicator.upperThreshold}
            onChange={(event) => update({ upperThreshold: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label className="col-sm-6 control-label">Use Cross Over</label>
        <div className="col-sm-6">
          <label className="react-switch react-switch--sm">
            <Switch onChange={() => update({ useCrossOver: !indicator.useCrossOver })} checked={indicator.useCrossOver} />
          </label>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="compare_co_periods" className="col-sm-6 control-label">
          Cross Over Periods
        </label>
        <div className="col-sm-6">
          <input
            id="compare_co_periods"
            type="number"
            step="1"
            min="1"
            className={classNames('form-control', { 'inactive-control': !indicator.useCrossOver })}
            value={indicator.basisPeriods}
            onChange={(event) => update({ basisPeriods: Number(event.target.value) })}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CompareForm;
