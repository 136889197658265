import * as React from 'react';

import { Switch, Route } from 'react-router-dom';

import PeriodDayScreen from './components/PeriodDayScreen';
import PeriodScreen from './components/PeriodScreen';

export const periodModulePath = '/periods';

const render = () => {
  return (
    <Switch>
      <Route exact path={periodModulePath} component={PeriodDayScreen}></Route>
      <Route exact path={periodModulePath + '/:periodId'} component={PeriodScreen}></Route>
    </Switch>
  );
};

export default {
  routeProps: {
    path: periodModulePath,
    render
  },
  name: 'Period'
};
