import { AnyAction } from 'redux';

import * as actionTypes from './action-types';
import { initalPnlMonthList, initalPnlMonthState } from './state';

export const pnlMonthReducer = (state = initalPnlMonthState, action: AnyAction) => {
  const getOrCreatePnlList = (key: string) => {
    let pnlList = state.pnlListMap.get(key);
    if (!pnlList) {
      pnlList = {
        ...initalPnlMonthList
      };
    }
    return pnlList;
  };

  if (action.type === actionTypes.CLEAR_PNL_LIST) {
    const key = action.payload.key;
    if (state.pnlListMap.has(key)) {
      const updatedListMap = state.pnlListMap.remove(key);
      return {
        ...state,
        pnlListMap: updatedListMap
      };
    }
  } else if (action.type === actionTypes.SET_PNL_LIST_LOADING) {
    const key = action.payload.key;
    const pnlList = getOrCreatePnlList(key);
    const updatedListMap = state.pnlListMap.set(key, {
      ...pnlList,
      loading: action.payload.loading
    });
    return {
      ...state,
      pnlListMap: updatedListMap
    };
  } else if (action.type === actionTypes.SET_PNL_LIST) {
    const key = action.payload.key;
    const pnlList = getOrCreatePnlList(key);
    const updatedListMap = state.pnlListMap.set(key, {
      ...pnlList,
      loading: false,
      loaded: true,
      error: undefined,
      positions: action.payload.positions
    });
    return {
      ...state,
      pnlListMap: updatedListMap
    };
  } else if (action.type === actionTypes.SET_PNL_LIST_ERROR) {
    const key = action.payload.key;
    const pnlList = getOrCreatePnlList(key);
    const updatedListMap = state.pnlListMap.set(key, {
      ...pnlList,
      loading: false,
      error: action.payload.error
    });
    return {
      ...state,
      pnlListMap: updatedListMap
    };
  } else if (action.type === actionTypes.UI_SET_PNL_SELECTED_MONTH) {
    const ui = {
      ...state.ui,
      selectedMonth: action.value
    };
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_SET_PNL_SELECTED_YEAR) {
    const ui = {
      ...state.ui,
      selectedYear: action.value
    };
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_SET_PNL_SHOW_POSITIONS) {
    const ui = {
      ...state.ui,
      showPositionsInPnl: action.value
    };
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_SET_PNL_POS_TYPE_OPTIONS) {
    const ui = {
      ...state.ui,
      positionTypeOptions: action.payload
    };
    return {
      ...state,
      ui
    };
  }

  return state;
};
