import { Account } from '../../../types';

export interface AccountState {
  loaded: boolean;
  error: boolean;
  errorMessage?: string;
  account: Account;
  accountList: Account[];
  accountListLoaded: boolean;
  accountListError?: string;
  ui: {
    selectedAccountId?: number;
  };
}

export const initalAccountState: AccountState = {
  loaded: false,
  error: false,
  errorMessage: undefined,
  account: {
    id: 0,
    userName: '',
    userPassword: '',
    isAdminUser: false,
    isSuperUser: false,
    lastVisited: new Date(),
    firstName: '',
    lastName: '',
    emailAddress: '',
    liveTradingInvestment: 0,
    paperTradingInvestment: 0,
    disabled: false,
    useEmailNotification: false,
    disableControlCenterRequests: false, // deprecated
    disableRefreshBrokerConnection: false // deprecated
  },
  accountList: [],
  accountListLoaded: false,
  accountListError: undefined,
  ui: {
    selectedAccountId: undefined
  }
};
