export const ADD_STRATEGY = 'ADD_STRATEGY';
export const UPDATE_STRATEGY = 'UPDATE_STRATEGY';
export const REMOVE_STRATEGY = 'REMOVE_STRATEGY';
export const SET_STRATEGIES = 'SET_STRATEGIES';
export const ADD_ARCHIVED_STRATEGIES = 'ADD_ARCHIVED_STRATEGIES';
export const SET_STRATEGY_ERROR = 'SET_STRATEGY_ERROR';
export const SET_STRATEGY_LOADED = 'SET_STRATEGY_LOADED';
export const UI_SHOW_ARCHIVED = 'UI_STRATEGY_SHOW_ARCHIVED';
export const UI_COLLAPSE_OPTION_PANEL = 'UI_STRATEGY_COLLAPSE_OPTION_PANEL';
export const UI_COLLAPSE_PICKER_PANEL = 'UI_STRATEGY_COLLAPSE_PICKER_PANEL';
export const UI_COLLAPSE_DETAILS_PANEL = 'UI_COLLAPSE_DETAILS_PANEL';
export const UI_STRATEGY_PICKED = 'UI_STRATEGY_PICKED';
export const UI_SELECT_TAB = 'UI_STRATEGY_SELECT_TAB';
