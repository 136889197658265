import * as React from 'react';
import { FunctionComponent, useEffect, useState } from 'react';

import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import Switch from 'react-switch';

import { GlobalState } from '../../../../core/root';
import { Subscription, Strategy, ContractEntity as Contract, Security, ContractOrderInfo } from '../../../../types';
import { loadContractsAsync } from '../../../contract/store/service';
import { loadSecuritiesAsync } from '../../../security/store/action-creators';
import CollapsePanel from '../../../shared/components/CollapsePanel';
import Spinner from '../../../shared/components/Spinner';
import { getExpireInDays } from '../../../shared/utils/date-utils';
import { getDeliveryMonthTypeByCode } from '../../../shared/utils/delivery-month';
import { loadStrategiesAsync } from '../../../strategy/store/services';
import { subscriptionModulePath } from '../../routes';
import { loadSubscriptionsAsync, removeSubscriptionAsync, updateSubscriptionAsync } from '../../store/action-creators';

import AdvanvedStrategyPanel from './AdvancedStrategyPanel';
import ContractOrderInfoButton from './ContractOrderInfoButton';
import UnderlyingSecurityPanel from './UnderlyingSecurityPanel';

export interface RouteParamProps {
  id: string;
}

export type Props = RouteComponentProps<RouteParamProps>;

export interface SubscriptionState {
  subscription: Subscription;
}

const SubscriptionScreen: FunctionComponent<Props> = ({ history, match }: Props) => {
  const state: GlobalState = useSelector((gs: GlobalState) => gs);
  const subscriptions: Subscription[] = state.subscriptionState.subscriptions;
  const subscriptionsLoaded: boolean = state.subscriptionState.loaded;

  const subscriptionId = Number(match.params.id);
  const selectedSubscription: Subscription | undefined = subscriptions.find((x) => x.id === subscriptionId);

  const securities: Security[] = state.securityState.securities;
  const securitiesLoaded = state.securityState.loaded;

  const contracts: Contract[] = state.contractState.contracts;
  const contractsLoaded = state.contractState.loaded;

  const strategies: Strategy[] = state.strategyState.strategies;
  const strategiesLoaded: boolean = state.strategyState.loaded;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!securitiesLoaded) {
      dispatch(loadSecuritiesAsync());
    }
    if (!strategiesLoaded) {
      dispatch(loadStrategiesAsync());
    }
    if (!contractsLoaded) {
      dispatch(loadContractsAsync());
    }
    if (!subscriptionsLoaded) {
      dispatch(loadSubscriptionsAsync());
    }
  }, []);

  const deepCopy = (sub: Subscription | undefined) => {
    if (!sub) {
      return sub;
    }
    const subscription = { ...sub };
    if (sub.contract) {
      subscription.contract = { ...sub.contract };
    }
    if (sub.security) {
      subscription.security = { ...sub.security };
    }
    if (sub.strategy) {
      subscription.strategy = { ...sub.strategy };
    }
    return subscription;
  };

  const [subscription, setSubscription] = useState(deepCopy(selectedSubscription));

  useEffect(() => {
    setSubscription(deepCopy(selectedSubscription));
  }, [selectedSubscription]);

  const mapSubscriptionFromState = (): Subscription | undefined => {
    if (!subscription) {
      return;
    }

    const sub = subscription;

    let selectedSecurity: Security | undefined;
    if (sub.securityId) {
      const item = securities.find((x) => x.id === sub.securityId);
      if (item) {
        selectedSecurity = { ...item };
      }
    }

    let selectedContract: Contract | undefined;
    if (sub.contractId) {
      const item = contracts.find((x) => x.id === sub.contractId);
      if (item) {
        selectedContract = { ...item };
      }
    }

    // clear selected contract if the underlying security is not the selected security
    if (selectedContract && selectedContract.securityId !== sub.securityId) {
      sub.contractId = 0;
      selectedContract = undefined;
    }

    let selectedStrategy: Strategy | undefined;
    if (sub.strategyId) {
      const item = strategies.find((x) => x.id === sub.strategyId);
      if (item) {
        selectedStrategy = { ...item };
      }
    }
    return {
      ...sub,
      security: selectedSecurity,
      contract: {
        ...selectedContract,
        security: { ...selectedSecurity }
      },
      strategy: selectedStrategy
    } as Subscription;
  };

  const updateSubscription = (sub: Subscription) => {
    dispatch(updateSubscriptionAsync(sub));
  };

  const deleteSubscription = (subId: number) => {
    dispatch(removeSubscriptionAsync(subId));
  };

  const navigateToList = () => {
    const url = `${subscriptionModulePath}`;
    history.push(url);
  };

  const deleteSubscriptionHandler = () => {
    if (!selectedSubscription) {
      return;
    }
    deleteSubscription(selectedSubscription.id);
    navigateToList();
  };

  const updateSubscriptionHandler = () => {
    const cs = mapSubscriptionFromState();
    if (!cs) {
      return;
    }
    updateSubscription(cs);
    navigateToList();
  };

  const updateSubscriptionState = (obj: object) => {
    if (!subscription) {
      return;
    }
    const field = Object.keys(obj)[0];
    const updatedItem = {
      ...subscription,
      [field]: obj[field]
    };
    setSubscription(updatedItem);
  };

  const updateSecurityHandler = (security: Security) => {
    if (!subscription) {
      return;
    }
    setSubscription({
      ...subscription,
      securityId: security?.id || 0,
      security: security
    });
  };

  const loading = !subscriptionsLoaded || !strategiesLoaded || !contractsLoaded || !securitiesLoaded;

  if (loading || !subscription) {
    return <Spinner />;
  }

  const {
    contractId,
    security,
    securityId,
    active,
    simulated,
    suspendEntries,
    checkConsistency,
    orderSize,
    commission,
    initialMargin,
    maintenanceMargin,
    alias
  } = subscription;

  const now = new Date();

  const getContractOptionTitle = (contract: Contract) => {
    const security = securities.find((x) => x.id === contract.securityId) as Security;
    const deliveryMonthType = getDeliveryMonthTypeByCode(contract.monthCode, security);
    const expires = getExpireInDays(new Date(), contract.rolloverDate);
    return `${contract.localSymbol} - ${deliveryMonthType} \t\t(expires: ${expires})`;
  };

  const contractOptions = contracts
    .filter((x) => x.securityId == securityId)
    .filter((x) => {
      if (x.id === contractId) {
        return true;
      }
      return x.rolloverDate && new Date(x.rolloverDate) > now;
    })
    .sort((a: Contract, b: Contract) => {
      if (!a.rolloverDate || !b.rolloverDate) return 0;
      if (a.rolloverDate > b.rolloverDate) return -1;
      if (a.rolloverDate < b.rolloverDate) return 1;
      return 0;
    })
    .map((x) => (
      <option key={x.id} value={x.id}>
        {getContractOptionTitle(x)}
      </option>
    ));

  const updateOrderInfoHandler = (info: ContractOrderInfo) => {
    if (!info) {
      return;
    }
    const cs = mapSubscriptionFromState();
    if (!cs) {
      return;
    }
    const { commission, initialMargin, maintenanceMargin } = info;
    cs.commission = commission;
    cs.initialMargin = initialMargin;
    cs.maintenanceMargin = maintenanceMargin;
    updateSubscription(cs);
  };

  const activeSubscriptionInfo = <Popover id="activeSubscriptionInfo">Runs in schedule</Popover>;

  const subscriptionAlias = (
    <Popover id="subscriptionAlias">Use subscription alias if there are more the one subscription of the same security.</Popover>
  );

  const subscriptionTitle = subscription?.alias || security?.name || 'Subscription';

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/subscriptions">Subscriptions</Link>
        </li>
        <li className="breadcrumb-item active">{subscriptionTitle}</li>
      </ol>

      <div className="page-header">
        <h3>
          {subscriptionTitle} <small className="no-wrap"></small>
        </h3>
      </div>

      <div className="row form-horizontal">
        <div className="col-md-6">
          <UnderlyingSecurityPanel securities={securities} selected={subscription.security} setSelected={updateSecurityHandler}>
            <div className="form-group form-group-sm">
              <label htmlFor="input_cs_alias" className="col-sm-4 control-label">
                <span>Alias</span>
                <OverlayTrigger placement="top" overlay={subscriptionAlias}>
                  <span className="gap-left-10">
                    <i className="fa fa-question-circle fa-lg text-info" />
                  </span>
                </OverlayTrigger>
              </label>
              <div className="col-sm-8">
                <input
                  id="input_cs_alias"
                  type="text"
                  className="form-control"
                  value={alias || ''}
                  onChange={(event) => updateSubscriptionState({ alias: event.target.value })}
                />
              </div>
            </div>
          </UnderlyingSecurityPanel>

          <CollapsePanel title="Contract">
            <div className="form-group form-group-sm">
              <label htmlFor="input_contract" className="col-sm-4 control-label">
                Contract
              </label>
              <div className="col-sm-8">
                <select
                  id="input_contract"
                  className="form-control"
                  value={contractId || ''}
                  onChange={(event) =>
                    updateSubscriptionState({
                      contractId: Number(event.target.value)
                    })
                  }
                >
                  <option value="">Velg..</option>
                  {contractOptions}
                </select>
              </div>
            </div>
          </CollapsePanel>
        </div>

        <div className="col-md-6">
          <AdvanvedStrategyPanel subscription={subscription} onUpdate={updateSubscriptionState} strategies={strategies} />
        </div>
      </div>

      <hr />

      <div className="row form-horizontal">
        <div className="col-md-6">
          <CollapsePanel title="Order Info">
            <div className="form-group form-group-sm">
              <label htmlFor="contract_order_size" className="col-xs-12 col-sm-4 control-label">
                Order Size
              </label>
              <div className="col-xs-8 col-sm-5">
                <input
                  id="contract_order_size"
                  type="number"
                  step="1"
                  min="0"
                  className="form-control"
                  value={orderSize}
                  onChange={(event) =>
                    updateSubscriptionState({
                      orderSize: Number(event.target.value)
                    })
                  }
                />
              </div>
              <div className="col-xs-4 col-sm-3">
                <ContractOrderInfoButton
                  contractId={subscription?.contractId || 0}
                  orderSize={subscription?.orderSize || 0}
                  contracts={contracts}
                  updateOrderInfo={updateOrderInfoHandler}
                />
              </div>
            </div>

            <hr />

            <div className="form-group form-group-sm">
              <label htmlFor="contract_init_margin" className="col-xs-12 col-sm-4 control-label">
                Commission
              </label>
              <div className="col-sm-8">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '20px',
                        textAlign: 'center'
                      }}
                    >
                      $
                    </span>
                  </div>
                  <input
                    type="number"
                    step="any"
                    value={commission}
                    onChange={(event) =>
                      updateSubscriptionState({
                        commission: Number(event.target.value)
                      })
                    }
                    className="form-control"
                    id="contract_init_margin"
                    placeholder="Amount"
                  />
                  {/* <div className="input-group-addon">.00</div> */}
                </div>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="contract_init_margin" className="col-xs-12 col-sm-4 control-label">
                Init. Margin
              </label>
              <div className="col-sm-8">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '20px',
                        textAlign: 'center'
                      }}
                    >
                      kr.
                    </span>
                  </div>
                  <input
                    type="number"
                    step="any"
                    value={initialMargin}
                    onChange={(event) =>
                      updateSubscriptionState({
                        initialMargin: Number(event.target.value)
                      })
                    }
                    className="form-control"
                    id="contract_init_margin"
                    placeholder="Amount"
                  />
                  {/* <div className="input-group-addon">.00</div> */}
                </div>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="contract_maint_margin" className="col-xs-12 col-sm-4 control-label">
                Maint. Margin
              </label>
              <div className="col-sm-8">
                <div className="input-group input-group-sm">
                  <div className="input-group-addon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: '20px',
                        textAlign: 'center'
                      }}
                    >
                      kr.
                    </span>
                  </div>
                  <input
                    type="number"
                    step="any"
                    value={maintenanceMargin}
                    onChange={(event) =>
                      updateSubscriptionState({
                        maintenanceMargin: Number(event.target.value)
                      })
                    }
                    className="form-control"
                    id="contract_maint_margin"
                    placeholder="Amount"
                  />
                  {/* <div className="input-group-addon">.00</div> */}
                </div>
              </div>
            </div>
          </CollapsePanel>
        </div>

        <div className="col-md-6">
          <CollapsePanel title="Activation">
            <div className="form-group form-group-sm">
              <label className="col-sm-6 control-label">
                <span>Active</span>
                <OverlayTrigger placement="top" overlay={activeSubscriptionInfo}>
                  <span className="gap-left-10">
                    <i className="fa fa-question-circle fa-lg text-info" />
                  </span>
                </OverlayTrigger>
              </label>
              <div className="col-sm-6">
                <label className="react-switch react-switch--sm">
                  <Switch onChange={() => updateSubscriptionState({ active: !active })} checked={active} />
                </label>
              </div>
            </div>
            <hr style={{ margin: '0px 2px 15px 2px' }} />
            <div className="form-group form-group-sm">
              <label className="col-sm-6 control-label">Simulated Trading</label>
              <div className="col-sm-6">
                <label className="react-switch react-switch--sm">
                  <Switch onChange={() => updateSubscriptionState({ simulated: !simulated })} checked={simulated} />
                </label>
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label className="col-sm-6 control-label">Suspend New Entries</label>
              <div className="col-sm-6">
                <label className="react-switch react-switch--sm">
                  <Switch
                    onChange={() =>
                      updateSubscriptionState({
                        suspendEntries: !suspendEntries
                      })
                    }
                    checked={suspendEntries}
                  />
                </label>
              </div>
            </div>
            <div className="form-group form-group-sm">
              <label className="col-sm-6 control-label">Check Consistency</label>
              <div className="col-sm-6">
                <label className="react-switch react-switch--sm">
                  <Switch
                    onChange={() =>
                      updateSubscriptionState({
                        checkConsistency: !checkConsistency
                      })
                    }
                    checked={checkConsistency}
                  />
                </label>
              </div>
            </div>
          </CollapsePanel>
        </div>
      </div>

      <hr />
      <div className="row">
        <div className="col-xs-6">
          <button type="button" className="btn btn-danger ripple" onClick={deleteSubscriptionHandler}>
            Delete
          </button>
        </div>
        <div className="col-xs-6 text-right">
          <button type="button" className="btn btn-primary ripple gap-bottom-5 gap-right-5" onClick={updateSubscriptionHandler}>
            Save
          </button>
          <button type="button" className="btn btn-default ripple gap-bottom-5" onClick={navigateToList}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionScreen;
