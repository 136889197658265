import * as React from 'react';
import { useEffect } from 'react';

import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { GlobalState } from '../../../../core/root';
import { Strategy } from '../../../../types';
import Spinner from '../../../shared/components/Spinner';
import { loadStrategyGroupsAsync } from '../../../strategy-group/store/action-creators';
import { sortStrategies } from '../../../strategy-group/utils';
import { strategyModulePath } from '../../routes';
import * as actionCreators from '../../store/action-creators';
import { loadStrategiesAsync } from '../../store/services';

const useStyles = createUseStyles({
  alertDark: {
    backgroundColor: '#303030',
    padding: '10px',
    border: '1px solid #303030',
    borderRadius: '5px'
  }
});

export interface Props extends RouteComponentProps {
  strategyId: number;
  strategyGroupId: number;
}

const StrategyGroupNav = ({ history, strategyId, strategyGroupId }: Props) => {
  const state: GlobalState = useSelector((gs) => gs);
  const strategyGroups = state.strategyGroupState.strategyGroups;
  const strategyGroupsLoaded = state.strategyGroupState.loaded;
  const strategies = state.strategyState.strategies;
  const strategiesLoaded = state.strategyState.loaded;
  const showArchived = state.strategyState.ui.showArchived;
  const selectedGroup = strategyGroups.find((x) => x.id == strategyGroupId);

  const dispatch = useDispatch();

  const styles = useStyles();

  useEffect(() => {
    if (!strategyGroupsLoaded) {
      dispatch(loadStrategyGroupsAsync());
    }
    if (!strategiesLoaded) {
      dispatch(loadStrategiesAsync());
    }
  }, []);

  if (!strategyGroupId || !strategyId) {
    return null;
  }

  const goToStrategyHandler = (strategy: Strategy) => {
    // console.log(`row clicked: ${strategy.name}`);
    dispatch(actionCreators.setStrategyPicked(strategy));
    history.push(`${strategyModulePath}/${strategy.id}`);
  };

  const isActive = (strategy: Strategy) => {
    return strategy && strategy.id === strategyId;
  };

  const options = strategies
    .filter((x) => {
      if (selectedGroup) {
        return selectedGroup.id === x.groupId;
      } else {
        return x.groupId == undefined;
      }
    })
    .filter((x) => {
      if (showArchived === true) {
        return x;
      } else {
        return x.archived === false;
      }
    })
    .sort(sortStrategies)
    .map((strategy: Strategy) => (
      <button
        key={strategy.id}
        type="button"
        className={classNames('btn', 'btn-sm', 'btn-default', 'gap-bottom-5', { active: isActive(strategy) })}
        onClick={() => goToStrategyHandler(strategy)}
      >
        {strategy.name}
      </button>
    ));

  const loading = !strategiesLoaded || !strategyGroupsLoaded;

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={classNames('alert', styles.alertDark)} role="alert">
      <div className="section">
        <p>{selectedGroup?.name} strategies</p>
      </div>
      <div className="btn-toolbar">{options}</div>
    </div>
  );
};

export default withRouter(StrategyGroupNav);
