import * as React from 'react';
import { SyntheticEvent, useState } from 'react';

import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { Indicator, OrderAction, IndicatorCategory, IndicatorMode } from '../../../types';
import { getBarSizeName } from '../../shared/utils/bar-utils';
import { sortByEnabledThenByActionThenByBarSizeThenByNames } from '../../shared/utils/indicator-utils';
import { addIndicatorAsync, removeIndicatorAsync } from '../store/service';

import IndicatorDialog from './IndicatorDialog';
import { getIndicatorOptionName } from './IndicatorOptionsPanel/indicator-options';

export interface Props {
  indicators: Indicator[];
}

const IndicatorList: React.FunctionComponent<Props> = ({ indicators }: Props) => {
  const [selectedIndicator, setSelectedIndicator] = useState<Indicator | undefined>();

  const dispatch = useDispatch();

  const duplicateIndicatorHandler = (indicator: Indicator) => {
    const copy = {
      ...indicator,
      id: 0
    };
    dispatch(addIndicatorAsync(copy));
  };

  const deleteIndicatorHandler = (indicator: Indicator) => {
    dispatch(removeIndicatorAsync(indicator));
  };

  const preventDefault = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const header = (
    <tr>
      <th className="middle small">Indicator</th>
      <th className="middle small">Category</th>
      <th className="middle small no-wrap">Action</th>
      <th className="middle small">Mode</th>
      <th className="middle small no-wrap">Bar Size</th>
      <th className="middle small text-center">Enabled</th>
      <th className="middle small text-right"></th>
    </tr>
  );

  const rows: JSX.Element[] = (indicators || [])
    .sort(sortByEnabledThenByActionThenByBarSizeThenByNames)
    .map((indicator: Indicator, index: number) => {
      const action = indicator.action === OrderAction.Buy ? 'Long' : 'Short';

      return (
        <tr key={index} className="clickable" onClick={() => setSelectedIndicator(indicator)}>
          <td className="middle small">{indicator.alias || getIndicatorOptionName(indicator.type)}</td>
          <td className="middle small">{IndicatorCategory[indicator.category]}</td>
          <td className="middle small">
            <span>{action}</span>
          </td>
          <td
            className={classNames('middle', 'small', {
              'warning-text': indicator.mode === IndicatorMode.Absolute
            })}
          >
            {IndicatorMode[indicator.mode]}
          </td>
          <td className="middle small">{getBarSizeName(indicator.barSize)}</td>
          <td className={classNames('middle', 'small', 'text-center', { 'danger-text': !indicator.enabled })}>
            <i className={classNames('fa', 'clickable', { 'fa-check-square-o': indicator.enabled, 'fa-square-o': !indicator.enabled })} />
          </td>
          <td className="middle small text-right" onClick={(event: SyntheticEvent) => preventDefault(event)}>
            <button className="btn btn-xs btn-success ripple gap-right-5" onClick={() => duplicateIndicatorHandler(indicator)}>
              <span>Duplicate</span>
            </button>
            <button className="btn btn-xs btn-danger ripple" onClick={() => deleteIndicatorHandler(indicator)}>
              <span>Remove</span>
            </button>
          </td>
        </tr>
      );
    });

  return (
    <React.Fragment>
      {rows.length > 0 ? (
        <div className="table-responsive">
          <table className="table table-striped table-hover table-condensed">
            <thead>{header}</thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      ) : (
        <span className="small">No indicators..</span>
      )}
      {selectedIndicator ? <IndicatorDialog indicator={selectedIndicator} close={() => setSelectedIndicator(undefined)} /> : null}
    </React.Fragment>
  );
};

export default IndicatorList;
