import { convertToDate } from './date-utils';
import { addLeadingZero } from './number-utils';

export const toISOString = (date: Date | undefined): string | undefined => {
  const dt = convertToDate(date);
  if (!dt) {
    return undefined;
  }
  const offsetDt = new Date(dt.getTime() - dt.getTimezoneOffset() * 60 * 1000);
  return offsetDt.toISOString();
};

export const toMonthTime = (date?: Date): string => {
  const dt = convertToDate(date);
  if (dt) {
    return `
      ${addLeadingZero(dt.getDate())}.${addLeadingZero(dt.getMonth() + 1)} 
      ${addLeadingZero(dt.getHours())}:${addLeadingZero(dt.getMinutes())}`;
  }
  return '';
};

export const toTime = (date?: Date): string => {
  const dt = convertToDate(date);
  if (dt) {
    return `${addLeadingZero(dt.getHours())}:${addLeadingZero(dt.getMinutes())}`;
  }
  return '';
};

export const toTimeSeconds = (date?: Date): string => {
  const dt = convertToDate(date);
  if (dt) {
    return `${addLeadingZero(dt.getHours())}:${addLeadingZero(dt.getMinutes())}:${addLeadingZero(dt.getSeconds())}`;
  }
  return '';
};

export const toDateMonth = (date?: Date): string => {
  const dt = convertToDate(date);
  if (dt) {
    return `${addLeadingZero(dt.getDate())}.${addLeadingZero(dt.getMonth() + 1)}`;
  }
  return '';
};

export const toDateMonthYear = (date?: Date): string => {
  const dt = convertToDate(date);
  if (dt) {
    return `${addLeadingZero(dt.getDate())}.${addLeadingZero(dt.getMonth() + 1)}.${dt.getFullYear()}`;
  }
  return '';
};

export const toShortMonth = (date?: Date): string => {
  const dt = convertToDate(date);
  if (dt) {
    return `${dt.getDate()}. ${getShortMonth(dt)}`;
  }
  return '';
};

const getShortMonth = (dt: Date): string => {
  switch (dt.getMonth()) {
    case 0:
      return 'Jan';
    case 1:
      return 'Feb';
    case 2:
      return 'Mar';
    case 3:
      return 'Apr';
    case 4:
      return 'May';
    case 5:
      return 'Jun';
    case 6:
      return 'Jul';
    case 7:
      return 'Aug';
    case 8:
      return 'Sep';
    case 9:
      return 'Oct';
    case 10:
      return 'Nov';
    case 11:
      return 'Des';
    default:
      throw new Error('Not a valid month number');
  }
};
