import * as React from 'react';

import classNames from 'classnames';
import Switch from 'react-switch';

import { Indicator, OrderDirection, OrderAction, BarSize, IndicatorMode, IndicatorType } from '../../../../types';
import { barSizes } from '../../../shared/utils/bar-utils';
import { getIndicatorOptionName } from '../IndicatorOptionsPanel/indicator-options';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const GeneralForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  React.useEffect(() => {
    if (disableEntry) {
      update({ direction: OrderDirection.Exit });
    }
  }, []);

  const disableEntry = false;
  const disableExit = indicator.type === IndicatorType.OpeningHour;

  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label htmlFor="gen_alias" className="col-sm-6 control-label">
          Alias
        </label>
        <div className="col-sm-6">
          <input
            id="gen_alias"
            type="text"
            className="form-control"
            value={indicator.alias || getIndicatorOptionName(indicator.type)}
            onChange={(event) => update({ alias: event.target.value })}
          />
        </div>
      </div>
      <div className="form-group form-group-sm">
        <label className="col-sm-6 control-label">Direction</label>
        <div className="col-sm-6">
          <div className="btn-group btn-group-justified" role="group">
            <div className="btn-group" role="group">
              <button
                key="btn-1"
                type="button"
                className={classNames('btn', 'btn-sm btn-default', 'ripple', { active: indicator.direction === OrderDirection.Enter })}
                onClick={() => update({ direction: OrderDirection.Enter })}
                disabled={disableEntry}
              >
                Entry
              </button>
            </div>
            <div className="btn-group" role="group">
              <button
                key="btn-2"
                type="button"
                className={classNames('btn', 'btn-sm btn-default', 'ripple', { active: indicator.direction === OrderDirection.Exit })}
                onClick={() => update({ direction: OrderDirection.Exit })}
                disabled={disableExit}
              >
                Exit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label className="col-sm-6 control-label">Action</label>
        <div className="col-sm-6">
          <div className="btn-group btn-group-justified" role="group">
            <div className="btn-group" role="group">
              <button
                key="btn-1"
                type="button"
                className={classNames('btn', 'btn-sm btn-default', 'ripple', { active: indicator.action === OrderAction.Buy })}
                onClick={() => update({ action: OrderAction.Buy })}
              >
                Long
              </button>
            </div>
            <div className="btn-group" role="group">
              <button
                key="btn-2"
                type="button"
                className={classNames('btn', 'btn-sm btn-default', 'ripple', { active: indicator.action === OrderAction.Sell })}
                onClick={() => update({ action: OrderAction.Sell })}
              >
                Short
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label className="col-sm-6 control-label">Mode</label>
        <div className="col-sm-6">
          <div className="btn-group btn-group-justified" role="group">
            <div className="btn-group" role="group">
              <button
                key="btn-1"
                type="button"
                className={classNames('btn', 'btn-sm btn-default', 'ripple', { active: indicator.mode === IndicatorMode.Conditional })}
                onClick={() => update({ mode: IndicatorMode.Conditional })}
              >
                Conditional
              </button>
            </div>
            <div className="btn-group" role="group">
              <button
                key="btn-2"
                type="button"
                className={classNames('btn', 'btn-sm btn-default', 'ripple', { active: indicator.mode === IndicatorMode.Absolute })}
                onClick={() => update({ mode: IndicatorMode.Absolute })}
              >
                Absolute
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="indicator_barsize" className="col-sm-6 control-label">
          Bar Size
        </label>
        <div className="col-sm-6">
          <select
            id="indicator_barsize"
            className="form-control"
            value={indicator.barSize}
            onChange={(event) => update({ barSize: Number(event.target.value) })}
          >
            <option value="">Velg..</option>
            {barSizes
              .filter((x) => x.key >= BarSize._5_MIN)
              .map((x) => (
                <option key={x.key} value={x.key}>
                  {x.name}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="delayed_periods" className="col-sm-6 control-label">
          Delayed Periods
        </label>
        <div className="col-sm-6">
          <input
            id="delayed_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.delayedPeriods}
            onChange={(event) => update({ delayedPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label className="col-sm-6 control-label">Enabled</label>
        <div className="col-sm-6">
          <label className="react-switch react-switch--sm">
            <Switch offColor="#e43725" onChange={() => update({ enabled: !indicator.enabled })} checked={indicator.enabled} />
          </label>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GeneralForm;
