import { GlobalState } from '../../../../core/root';
import { showNetworkError } from '../../../shared/utils/growl-utils';
import { syncUnrealizedPnl } from '../../../shared/utils/position-utils';

import { clearOpenPositionsLoaded, setOpenPositions, setOpenPositionsError } from './action-creators';
import { OpenPositionHttpRequest } from './http-request';

export const fetchOpenPositionsAsync = () => {
  return (dispatch, getState) => {
    const state: GlobalState = getState();
    dispatch(clearOpenPositionsLoaded);
    OpenPositionHttpRequest.getOpenPositions()
      .then((response) => {
        const positions = response.data;
        const portfolios = state.accountInfoState.accountInfo?.portfolios;
        syncUnrealizedPnl(positions, portfolios);
        dispatch(setOpenPositions(positions));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load intraday-positions');
        dispatch(setOpenPositionsError(msg));
      });
  };
};
