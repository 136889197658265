import { AnyAction } from 'redux';

import * as actionTypes from './action-types';
import { initialGraphConfigMapState } from './state';

export const graphConfigReducer = (state = initialGraphConfigMapState, action: AnyAction) => {
  if (action.type === actionTypes.SET_GRAPH_CONFIG) {
    const key = action.payload.key;
    return state.set(key, action.payload.config);
  }

  return state;
};
