export interface MinutesApart {
  name: string;
  value: number;
}

export const minutesApartOptions: MinutesApart[] = [
  { name: '5 min', value: 5 },
  { name: '10 min', value: 10 },
  { name: '15 min', value: 15 },
  { name: '20 min', value: 20 },
  { name: '30 min', value: 30 },
  { name: '1 hr', value: 60 },
  { name: '2 hrs', value: 120 },
  { name: '3 hrs', value: 180 },
  { name: '4 hrs', value: 240 },
  { name: '8 hrs', value: 480 },
  { name: '1 day', value: 1440 }
];
