import { ProductType, Security } from '../../../types';

export interface SecurityState {
  ui: {
    optionPanelCollapsed: boolean;
    pickerPanelCollapsed: boolean;
    securityPicked: Security | undefined;
    selectedProductTypes: ProductType[];
    showArchived: boolean;
    selectedTab: string;
  };
  loaded: boolean;
  error: boolean;
  errorMessage?: string;
  securities: Security[];
}

export const initalSecurityState: SecurityState = {
  ui: {
    optionPanelCollapsed: true,
    pickerPanelCollapsed: true,
    securityPicked: undefined,
    selectedProductTypes: [],
    showArchived: false,
    selectedTab: 'general'
  },
  loaded: false,
  error: false,
  errorMessage: undefined,
  securities: []
};
