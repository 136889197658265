import React, { useState, useEffect } from 'react';

import DatePicker from '@christianleypoldt/react-bootstrap-date-picker';
import classNames from 'classnames';
import { Modal, Button } from 'react-bootstrap';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { GlobalState } from '../../../core/root';
import { ContractEntity as Contract, Security } from '../../../types';
import Spinner from '../../shared/components/Spinner';
import { formatUsd } from '../../shared/utils/currency-utils';
import { toShortMonth } from '../../shared/utils/date-format-utils';
import { trimTimePartFromDate } from '../../shared/utils/date-picker-utils';
import { sortByDate, getExpireInDays } from '../../shared/utils/date-utils';
import { getDeliveryMonthType } from '../../shared/utils/delivery-month';
import { contractModulePath } from '../routes';
import { generateContractAsync, GenerateContractRequest } from '../store/service';

const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY';

export interface Props extends RouteComponentProps {
  security: Security;
  contracts: Contract[];
}

const ContractList: React.FunctionComponent<Props> = ({ security, contracts, history }: Props) => {
  const state = useSelector((gs: GlobalState) => gs);
  const contractsLoaded: boolean = state.contractState.loaded;
  const loading = !contractsLoaded;

  const [showCreateContractDialog, setShowCreateContractDialog] = useState(false);
  const [contractCreated, setContractCreated] = useState<string | undefined>();

  const updateContractCreated = (value) => {
    if (!value) {
      setContractCreated(undefined);
    } else {
      const dt = trimTimePartFromDate(value);
      setContractCreated(dt);
    }
  };

  useEffect(() => {
    updateContractCreated(new Date());
  }, []);

  const rowClickHandler = (contract: Contract) => {
    const route = `${contractModulePath}/${contract.id}`;
    history.push(route);
  };

  const dispatch = useDispatch();
  const createContractClickhandler = () => {
    if (!security) {
      return;
    }
    const dtString = trimTimePartFromDate(contractCreated) as string;
    const request: GenerateContractRequest = { security, setupDate: dtString, isPersistant: true };
    dispatch(generateContractAsync(request));
    setShowCreateContractDialog(false);
  };

  const header = (
    <tr>
      <th className="middle small">Contract</th>
      <td className="middle small">Underlying</td>
      <td className="middle small">Type</td>
      <td className="middle small text-right">Comm.</td>
      <td className="middle small text-right">Delivery</td>
      <td className="middle small text-center">Type</td>
      <td className="middle small text-center">Months</td>

      <td className="middle small">First Trade</td>
      <td className="middle small">Rollover</td>
      <td className="middle small">1st Notice</td>
      <td className="middle small text-right">Expires</td>

      <td className="middle small text-right">Ref. ID</td>
      {/* <td className="middle small" style={{width: '50px'}}></td> */}
    </tr>
  );

  const sortByFirstNoticeDate = (a: Contract, b: Contract) => {
    return sortByDate(a.firstNoticeDate, b.firstNoticeDate, true);
  };

  const rows = contracts.sort(sortByFirstNoticeDate).map((contract) => {
    const firstTradeExpireClass = getExpireInDays(new Date(), contract.firstTradeDate) === 0 ? 'warning-text' : undefined;
    const rolloverExpireClass = getExpireInDays(new Date(), contract.rolloverDate) === 0 ? 'warning-text' : undefined;
    const firstNoticeExpireClass = getExpireInDays(new Date(), contract.firstNoticeDate) === 0 ? 'danger-text' : undefined;

    return (
      <tr key={contract.id} className="clickable" onClick={() => rowClickHandler(contract)}>
        <th className="middle small">{contract.localSymbol}</th>
        <td className="middle small">{security.name}</td>
        <td className="middle small">
          <span className={classNames('label', { 'label-info': contract.type === 'FUT' })}>{contract.type}</span>
        </td>
        <td className="middle small text-right">{formatUsd(contract.standardCommission, 2)}</td>
        <td className="middle small text-right vborder">{contract.contractMonth}</td>
        <td className="middle small text-center">{getDeliveryMonthType(contract.contractMonth, security)}</td>
        <td className="middle small text-center">{contract.numberOfTradeMonths}</td>

        <td className="middle small vborder">
          <span className={firstTradeExpireClass}>{toShortMonth(contract.firstTradeDate)}</span>
        </td>
        <td className="middle small">
          <span className={rolloverExpireClass}>{toShortMonth(contract.rolloverDate)}</span>
        </td>
        <td className="middle small">
          <span className={firstNoticeExpireClass}>{toShortMonth(contract.firstNoticeDate)}</span>
        </td>
        <td className="middle small text-right">{getExpireInDays(new Date(), contract.firstNoticeDate)}</td>

        <td className="middle small vborder text-right">{contract.refId}</td>
      </tr>
    );
  });

  const popoverContractCreated = (
    <Popover id="popoverContractCreated">A date between the first trade date and the roll-over date for the requested contact</Popover>
  );

  return (
    <div>
      {rows.length > 0 && (
        <div className="scrolling outer">
          <div className="inner">
            <table className="table table-hover table-striped table-condensed">
              <thead>{header}</thead>
              <tbody>{rows}</tbody>
            </table>
          </div>
        </div>
      )}
      <div className="btn-toolbar pull-right gap-top-20" role="toolbar">
        <button className="btn btn-sm btn-success" type="button" onClick={() => setShowCreateContractDialog(true)} disabled={!security}>
          <span>Create..</span>
        </button>
      </div>

      <Modal
        show={showCreateContractDialog}
        bsSize="small"
        onHide={() => setShowCreateContractDialog(false)}
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title">
            <span>Create Contract</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-horizontal">
            <div className="form-group form-group-sm">
              <label className="col-xs-12 small" htmlFor="contract_created">
                <span>Contract Month</span>
                <OverlayTrigger placement="top" overlay={popoverContractCreated}>
                  <span className="gap-left-10">
                    <i className="fa fa-question-circle fa-lg text-info" />
                  </span>
                </OverlayTrigger>
              </label>
              <div className="datepicker-fix col-xs-12">
                <DatePicker
                  id="contract_created"
                  required
                  weekStartsOn={1}
                  value={contractCreated}
                  placeholder="Test Date"
                  dateFormat={DEFAULT_DATE_FORMAT}
                  onChange={updateContractCreated}
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button bsStyle="warning" onClick={createContractClickhandler}>
            Create
          </Button>
          <Button onClick={() => setShowCreateContractDialog(false)}>Cancel</Button>
        </Modal.Footer>

        {loading && <Spinner />}
      </Modal>
    </div>
  );
};

export default withRouter(ContractList);
