import * as React from 'react';
import { useState, FunctionComponent } from 'react';

import { Security } from '../../../../types';
import CollapsePanel from '../../../shared/components/CollapsePanel';

interface Props {
  securities: Security[];
  selected: Security | undefined;
  setSelected(security: Security | undefined): void;
  children?: React.ReactNode;
}

const UnderlyingSecurityPanel: FunctionComponent<Props> = ({ securities, selected, setSelected, children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const listOptions = securities
    .filter((x) => !x.archived)
    .sort((a: Security, b: Security) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    })
    .map((x) => (
      <option key={x.id} value={x.id}>
        {x.name}
      </option>
    ));

  const selectListOptionHandler = (val: string) => {
    if (!val) {
      setSelected(undefined);
    } else {
      const security = securities.find((x) => x.id === parseInt(val));
      setSelected(security);
    }
  };

  return (
    <CollapsePanel title={'Security'} upper={false} collapsed={collapsed} onToggle={toggleCollapse}>
      <div className="form-horizontal">
        <div className="row">
          <div className="col-xs-12">
            <div className="form-group form-group-sm">
              <label htmlFor="input_asset_futcode" className="col-sm-4 control-label">
                Security
              </label>
              <div className="col-sm-8">
                <select
                  id="input_asset_futcode"
                  className="form-control"
                  value={selected?.id}
                  onChange={(event) => selectListOptionHandler(event.target.value)}
                >
                  <option value="">Velg..</option>
                  {listOptions}
                </select>
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </CollapsePanel>
  );
};

export default UnderlyingSecurityPanel;
