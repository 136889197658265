import { Map } from 'immutable';

import { Position } from '../../../../types';

export interface PositionSelectedState {
  loaded: boolean;
  error?: string;
  position: Position | undefined;
}

export const initalPositionSelectedState: PositionSelectedState = {
  loaded: false,
  error: undefined,
  position: undefined
};

export type PositionSelectedMapState = Map<number, PositionSelectedState>;

export const initalPositionSelectedMapState: PositionSelectedMapState = Map();
