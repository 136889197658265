import { GraphQuote, GraphRequest } from '../../../../types';
import axiosApi from '../../../shared/axios-api';

export class GraphDataHttpRequest {
  public static get(graphRequest: GraphRequest) {
    const isOfflineGraph = graphRequest.contractPartialId > 0;
    if (isOfflineGraph) {
      return axiosApi.post<GraphQuote[]>(`/graph/offline`, graphRequest);
    } else {
      return axiosApi.post<GraphQuote[]>(`/graph/online`, graphRequest);
    }
  }
}
