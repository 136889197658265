import * as React from 'react';
import { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';

import { GlobalState } from '../../../core/root';
import { HistoryPeriodType, PositionRequest, ScheduleStatus } from '../../../types';
import CollapsePanel from '../../shared/components/CollapsePanel';
import Spinner from '../../shared/components/Spinner';
import { getSystemTime } from '../../shared/utils/broker-time';
import * as actionCreators from '../store/pnl-month/action-creators';
import { getPnlListItems, getPnlListLoading, shouldFetchPnlList } from '../store/pnl-month/state';

import FilteredPositionListPanel from './FilteredPositionListPanel';

const yearList = [2020, 2021, 2022, 2023];

interface MonthItem {
  key: number;
  month: string;
}

const monthList: MonthItem[] = [
  { key: 0, month: 'January' },
  { key: 1, month: 'February' },
  { key: 2, month: 'March' },
  { key: 3, month: 'April' },
  { key: 4, month: 'May' },
  { key: 5, month: 'June' },
  { key: 6, month: 'July' },
  { key: 7, month: 'August' },
  { key: 8, month: 'September' },
  { key: 9, month: 'October' },
  { key: 10, month: 'November' },
  { key: 11, month: 'December' }
];

const PnlMonthScreen = () => {
  const [brokerTime, setBrokerTime] = useState<Date>();

  const state: GlobalState = useSelector((gs: GlobalState) => gs);
  const showPositionsInPnl = state.pnlMonthState.ui.showPositionsInPnl;
  const selectedMonth: number | undefined = state.pnlMonthState.ui.selectedMonth;
  const selectedYear: number | undefined = state.pnlMonthState.ui.selectedYear;
  const key = `${selectedYear}-${selectedMonth}`;
  const loading = getPnlListLoading(state, key);
  const positions = getPnlListItems(state, key);
  const shouldFetch = shouldFetchPnlList(state, key);
  const status: ScheduleStatus = state.scheduleStatusState.status;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!brokerTime) {
      const tm = getSystemTime(status.webApiTimeZone);
      setBrokerTime(tm);
      if (selectedYear === undefined) {
        dispatch(actionCreators.setSelectedYear(tm.getFullYear()));
      }
      if (selectedMonth === undefined) {
        dispatch(actionCreators.setSelectedMonth(tm.getMonth()));
      }
    }
  }, [status.webApiTimestamp]);

  const refreshHandler = () => {
    if (selectedYear === undefined || selectedMonth === undefined) {
      return;
    }
    const fromDt = new Date(selectedYear, selectedMonth, 1);
    const endDt = new Date(selectedYear, selectedMonth + 1, 1);
    const request: PositionRequest = {
      fromDate: fromDt,
      toDate: endDt,
      contractId: 0,
      historyPeriodType: HistoryPeriodType.Month
    };
    dispatch(actionCreators.fetchPnlListAsync(key, request));
  };

  useEffect(() => {
    if (selectedYear !== undefined && selectedMonth !== undefined) {
      // if (isCurrentMonth || shouldFetch) { // isCurrentMonth deprecated; use refresh-btn instead
      if (shouldFetch) {
        refreshHandler();
      }
    }
  }, [selectedMonth, selectedYear]);

  const yearOptions = yearList.map((x) => (
    <button
      key={x}
      type="button"
      className={classNames('btn', 'btn-sm', 'btn-default', 'gap-bottom-5', { active: x === selectedYear })}
      onClick={() => dispatch(actionCreators.setSelectedYear(x))}
    >
      {x}
    </button>
  ));

  const monthOptions = monthList.map((x) => (
    <button
      key={x.key}
      type="button"
      className={classNames('btn', 'btn-sm', 'btn-default', 'gap-bottom-5', { active: x.key === selectedMonth })}
      onClick={() => dispatch(actionCreators.setSelectedMonth(x.key))}
    >
      {x.month}
    </button>
  ));

  const refreshButton = (
    <div className="row">
      <div className="col-xs-12 text-right gap-top-20">
        <button
          type="button"
          className="btn btn-sm btn-success ripple"
          disabled={selectedMonth === undefined || selectedYear === undefined}
          onClick={refreshHandler}
        >
          Refresh
        </button>
      </div>
    </div>
  );

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item active">Monthy PnL</li>
      </ol>

      <div className="page-header">
        <h3>
          Profit &amp; Loss <small className="no-wrap"></small>
        </h3>
      </div>

      <CollapsePanel title="Select Month">
        <div className="row">
          <div className="col-xs-12">
            <div className="btn-toolbar">{yearOptions}</div>
            <hr className="gap-top-5 gap-bottom-10" />
            <div className="btn-toolbar">{monthOptions}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 gap-top-10">
            <table>
              <tbody>
                <tr>
                  <td className="middle">
                    <span className="small" style={{ display: 'inline-block', marginBottom: '8px', marginRight: '8px ' }}>
                      Show Positions
                    </span>
                  </td>
                  <td className="middle">
                    <label className="react-switch react-switch--sm">
                      <Switch
                        onChange={() => dispatch(actionCreators.setShowPositionsInPnl(!showPositionsInPnl))}
                        checked={showPositionsInPnl}
                      />
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </CollapsePanel>

      {loading && <Spinner />}

      {positions && positions.length > 0 ? (
        <div>
          <FilteredPositionListPanel positions={positions} showRows={showPositionsInPnl} showSummary={true} />
          {refreshButton}
        </div>
      ) : (
        <p className="small">No positions..</p>
      )}
    </div>
  );
};

export default PnlMonthScreen;
