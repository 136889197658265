import { ScheduleStatus } from '../../../../types';

import * as actionTypes from './action-types';

export interface ScheduleStatusAction {
  type: string;
  status?: ScheduleStatus;
  error?: string;
  loading?: boolean;
}

export const setStatusLoading = (loading: boolean): ScheduleStatusAction => {
  return {
    type: actionTypes.SET_SCHEDULE_STATUS_LOADING,
    loading
  };
};

export const setStatusError = (error: string) => {
  return {
    type: actionTypes.SET_SCHEDULE_STATUS_ERROR,
    error
  };
};

export const setStatus = (status: ScheduleStatus): ScheduleStatusAction => {
  return {
    type: actionTypes.SET_SCHEDULE_STATUS,
    status
  };
};

export const clearStatus = (): ScheduleStatusAction => {
  return {
    type: actionTypes.CLEAR_SCHEDULE_STATUS
  };
};

export const toggleRunSchedule = (status: ScheduleStatus): ScheduleStatusAction => {
  return {
    type: actionTypes.TOGGLE_RUN_SCHEDULE,
    status
  };
};
