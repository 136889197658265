import React from 'react';
import { SyntheticEvent } from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { GlobalState } from '../../../core/root';
import { Backtest, BacktestStatus, Strategy } from '../../../types';
import { formatUsd } from '../../shared/utils/currency-utils';
import { toMonthTime, toDateMonth } from '../../shared/utils/date-format-utils';
import { setDecimalSpaces, setThousenSeparator } from '../../shared/utils/number-utils';
import { convertTicksToSeconds, getDuration } from '../../shared/utils/time-utils';
import { backtestModulePath } from '../backtest-routes';
import { sortByPartialMonth } from '../backtest-utils';
import * as actionCreators from '../store/backtest/action-creators';

import BacktestAllButton from './BacktestAllButton';
import BacktestButton from './BacktestButton';

export interface BacktestTableProps extends RouteComponentProps {
  securityId: number;
  strategyId: number;
  backtests: Backtest[];
}

const BacktestTable: React.FunctionComponent<BacktestTableProps> = ({ securityId, strategyId, backtests, history }: BacktestTableProps) => {
  const dispatch = useDispatch();

  const state = useSelector((gs) => gs) as GlobalState;
  const strategy: Strategy | undefined = state.strategyState.strategies.find((x) => x.id === strategyId);
  const strategySuccessWinPct = !!strategy && strategy.successWinPct !== 0 ? strategy.successWinPct : 60;
  const strategyDangerWinPct = !!strategy && strategy.dangerWinPct !== 0 ? strategy.dangerWinPct : 50;

  const clearAllStatusHandler = () => {
    backtests.forEach((x) => {
      x.status = BacktestStatus.NA;
      dispatch(actionCreators.updateBacktest(securityId, strategyId, x));
    });
  };

  const completeAllHandler = () => {
    backtests.forEach((x) => {
      if (x.status !== BacktestStatus.Running) {
        x.status = BacktestStatus.Completed;
        dispatch(actionCreators.updateBacktest(securityId, strategyId, x));
      }
    });
  };

  const preventDefault = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const toggleStatusHandler = (backtest: Backtest) => {
    let newBacktestStatus = BacktestStatus.NA;
    switch (backtest.status) {
      case BacktestStatus.Completed:
      case BacktestStatus.Failed:
        newBacktestStatus = BacktestStatus.NA;
        break;
      case BacktestStatus.NA:
        newBacktestStatus = BacktestStatus.Running;
        break;
      case BacktestStatus.Running:
        newBacktestStatus = BacktestStatus.Completed;
        break;
    }
    backtest.status = newBacktestStatus;
    backtest.statusMessage = '';
    dispatch(actionCreators.updateBacktest(securityId, strategyId, backtest));
  };

  const selectBacktestHandler = (backtest: Backtest) => {
    const route = `${backtestModulePath}/${strategyId}/${backtest.id}`;
    history.push(route);
  };

  const getTotalPositions = () => {
    if (backtests.length == 0) {
      return 0;
    }
    const sum = backtests
      .map((x) => x.sumPositions)
      .reduce((total: number, positions: number) => {
        return total + positions;
      });
    return setThousenSeparator(sum);
  };

  const getTotalDuration = (backtest) => {
    if (!backtest) {
      return;
    }
    const dt = new Date();
    const prev = new Date();
    prev.setMinutes(prev.getMinutes() - backtest.totalMinutes);
    return getDuration(prev, dt);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getSumTotalDuration = () => {
    if (backtests.length == 0) {
      return 0;
    }
    const sumMinutes = backtests
      .map((x) => x.totalMinutes)
      .reduce((total: number, minutes: number) => {
        return total + minutes;
      });

    const dt = new Date();
    const prev = new Date();
    prev.setMinutes(prev.getMinutes() - sumMinutes);
    return getDuration(prev, dt);
  };

  const getSumNetPnl = () => {
    if (backtests.length == 0) {
      return 0;
    }
    const sum = backtests
      .map((x) => x.sumNetPnl + x.sumUnrealizedPnl)
      .reduce((total: number, netPnl: number) => {
        return total + netPnl;
      });
    return formatUsd(sum, 0);
  };

  const getSumRealizedPnl = () => {
    if (backtests.length == 0) {
      return 0;
    }
    const sum = backtests
      .map((x) => x.sumNetPnl)
      .reduce((total: number, unrlz: number) => {
        return total + unrlz;
      });
    return formatUsd(sum, 0);
  };

  const getSumUnrealizedPnl = () => {
    if (backtests.length == 0) {
      return 0;
    }
    const sum = backtests
      .map((x) => x.sumUnrealizedPnl)
      .reduce((total: number, unrlz: number) => {
        return total + unrlz;
      });
    return formatUsd(sum, 0);
  };

  const getSumCommission = () => {
    if (backtests.length == 0) {
      return 0;
    }
    const sum = backtests
      .map((x) => x.sumCommission)
      .reduce((total: number, comm: number) => {
        return total + comm;
      });
    return formatUsd(sum, 0);
  };

  const getSumSlippage = () => {
    if (backtests.length == 0) {
      return 0;
    }
    const sum = backtests
      .map((x) => x.sumSlippageAmount)
      .reduce((total: number, comm: number) => {
        return total + comm;
      });
    return formatUsd(sum, 0);
  };

  const getSumTotalGain = () => {
    if (backtests.length == 0) {
      return 0;
    }
    return backtests
      .map((x) => x.sumTotalGain)
      .reduce((total: number, gain: number) => {
        return total + gain;
      });
  };

  const getSumTotalLoss = () => {
    if (backtests.length == 0) {
      return 0;
    }
    return backtests
      .map((x) => x.sumTotalLoss)
      .reduce((total: number, loss: number) => {
        return total + loss;
      });
  };

  const getSumTotalPnlPct = (): number | undefined => {
    const gain = getSumTotalGain();
    const loss = getSumTotalLoss();
    const revenue = gain + loss * -1;
    if (revenue === 0) {
      return undefined;
    }

    return (gain * 100) / revenue;
  };

  const header = (
    <tr>
      {backtests.length > 1 && (
        <th className="middle small" style={{ width: '30px' }}>
          #
        </th>
      )}
      <th className="middle small">Partial</th>
      <th className="middle small">From</th>
      <th className="middle small">To</th>

      <th className="middle small text-right">Trades</th>
      <th className="middle small text-right">Slipp.</th>
      <th className="middle small text-right">Comm.</th>
      {/* <th className="middle small text-center">Ovrlp.</th> */}
      <th className="middle small text-right">Dur.</th>
      <th className="middle small text-right">Rlzd. PnL</th>
      {/* <th className="middle small text-center">Rlz. Pct</th> */}
      {/* <th className="middle small text-center">Pos. Pct</th> */}
      {/* <th className="middle small text-center">PnL Avg</th> */}
      <th className="middle small text-right">Unrl. PnL</th>
      <th className="middle small text-right">Sum PnL</th>
      <th className="middle small text-center">PnL Pct</th>
      <th className="middle small">Updated</th>
      <th className="middle small">Exec.</th>
      <th className="middle small text-center">
        <i className="fa fa-undo clickable" onClick={clearAllStatusHandler}></i>
      </th>
      <th className="middle small text-center">
        <BacktestAllButton securityId={securityId} strategyId={strategyId} backtests={backtests} className="btn-xs btn-backtest" />
      </th>
    </tr>
  );

  const rows: JSX.Element[] = backtests.sort(sortByPartialMonth).map((backtest: Backtest, index: number) => {
    // const totalPnlPct = setDecimalSpaces(backtest.totalPnlPct, 0);
    // const totalPnlPctElement =
    //   totalPnlPct !== undefined && totalPnlPct !== null ? (
    //     <span
    //       className={classNames({
    //         'success-text': totalPnlPct >= 60,
    //         'danger-text2': totalPnlPct < 50
    //       })}
    //     >
    //       {`${totalPnlPct}%`}
    //     </span>
    //   ) : (
    //     <span>{'-'}</span>
    //   );

    const totalWinPct = setDecimalSpaces(backtest.profitCountPct, 0);
    const totalWinPctElement =
      totalWinPct !== undefined && totalWinPct !== null ? (
        <span
          className={classNames({
            'success-text': (totalWinPct as number) >= strategySuccessWinPct,
            'danger-text2': (totalWinPct as number) < strategyDangerWinPct
          })}
        >
          {`${totalWinPct}%`}
        </span>
      ) : (
        <span>{'-'}</span>
      );

    let statusLabel: JSX.Element;
    switch (backtest.status) {
      case BacktestStatus.Running:
        statusLabel = <span className="fa fa-spinner fa-spin fa-xs"></span>;
        break;
      case BacktestStatus.Completed:
        statusLabel = <span className="glyphicon glyphicon-ok success-text"></span>;
        break;
      case BacktestStatus.NA:
        statusLabel = <span className="glyphicon glyphicon-ok success-text" style={{ opacity: '0.3' }}></span>;
        break;

      case BacktestStatus.Failed:
        statusLabel = <span className="glyphicon glyphicon-exclamation-sign warning-text"></span>;
        break;
    }

    return (
      <tr key={backtest.id} className="clickable" onClick={() => selectBacktestHandler(backtest)}>
        {backtests.length > 1 && (
          <td className="middle small">
            <i>{index + 1}</i>
          </td>
        )}
        <td className="middle small">{backtest.partial.partialMonth}</td>
        <td className="middle small">{toDateMonth(backtest.partial.periodStart)}</td>
        <td className="middle small">{toDateMonth(backtest.partial.periodEnd)}</td>

        <td className="middle small text-right vborder">{backtest.sumPositions}</td>
        <td className="middle small text-right">
          <span>
            {formatUsd(backtest.sumSlippageAmount, 0)} ({backtest.sumSlippageTicks})
          </span>
        </td>
        <td className="middle small text-right">{formatUsd(backtest.sumCommission, 0)}</td>
        {/* <td className={classNames('middle', 'small', 'text-center')}>
          <i
            className={classNames('fa', 'clickable', {
              'fa-check-square-o': backtest.useOverlapping,
              'fa-square-o': !backtest.useOverlapping
            })}
          />
        </td> */}
        <td className="middle small text-right">{getTotalDuration(backtest)}</td>

        <td className={classNames('middle', 'small', 'text-right', 'vborder', { 'danger-text2': backtest.sumNetPnl < 0 })}>
          {formatUsd(backtest.sumNetPnl, 0)}
        </td>

        {/* <td className="middle small text-center">
            <span className={classNames({
              'success-text': (backtest.realizedProfitPct && backtest.realizedProfitPct >= 60),
              'danger-text2': (backtest.realizedProfitPct && backtest.realizedProfitPct <= 50)
            })}>{realizedProfitPct}
            </span>
          </td> */}
        {/* <td className="middle small text-center vborder">
            <span className={classNames({
              'success-text': (backtest.profitCountPct && backtest.profitCountPct >= 60),
              'danger-text2': (backtest.profitCountPct && backtest.profitCountPct <= 50)
            })}>{profitCountPct}
            </span>
          </td> */}
        {/* <td className={classNames('middle', 'small', 'text-right',
            { 'danger-text2': (backtest.avgPnl < 0) })}>{avgPnl}</td> */}

        <td className={classNames('middle', 'small', 'text-right', { 'danger-text2': backtest.sumUnrealizedPnl < 0 })}>
          {formatUsd(backtest.sumUnrealizedPnl, 0)}
        </td>
        <td className={classNames('middle', 'small', 'text-right', { 'danger-text2': backtest.sumTotalPnl < 0 })}>
          {formatUsd(backtest.sumTotalPnl, 0)}
        </td>

        {/* <td className="middle small text-center">{totalPnlPctElement}</td> */}
        <td className="middle small text-center">{totalWinPctElement}</td>

        <td className="middle small vborder">{toMonthTime(backtest.lastExecuted)}</td>
        <td className="middle small">{backtest.executionTicks && <span>{convertTicksToSeconds(backtest.executionTicks)}s</span>}</td>
        <td className="middle small text-center" onClick={(event: SyntheticEvent) => preventDefault(event)}>
          <button className="btn btn-link btn-xs ripple" onClick={() => toggleStatusHandler(backtest)} title={backtest.statusMessage}>
            {statusLabel}
          </button>
        </td>
        <td className="middle small text-center" onClick={(event: SyntheticEvent) => preventDefault(event)}>
          <BacktestButton securityId={securityId} strategyId={strategyId} backtest={backtest} className="btn-xs btn-backtest" />
        </td>
      </tr>
    );
  });

  let footer: JSX.Element | undefined;
  if (backtests.length > 1) {
    const rawVal = getSumTotalPnlPct();
    const sumTotalPnlPct = setDecimalSpaces(rawVal, 0);
    const sumTotalPnlPctElement =
      sumTotalPnlPct !== undefined ? (
        <span
          className={classNames({
            'success-text': (sumTotalPnlPct as number) >= 60,
            'danger-text2': (sumTotalPnlPct as number) < 50
          })}
        >
          {`${sumTotalPnlPct}%`}
        </span>
      ) : (
        <span>{'-'}</span>
      );

    footer = (
      <tr>
        <th colSpan={2} className="middle small">
          Sum:
        </th>
        <th className="middle small"></th>
        <th className="middle small"></th>

        <th className="middle small text-right no-wrap">{getTotalPositions()}</th>
        <th className="middle small text-right">{getSumSlippage()}</th>
        <th className="middle small text-right">{getSumCommission()}</th>
        <th className="middle small text-right">{getSumTotalDuration()}</th>
        <th className="middle small text-right">{getSumRealizedPnl()}</th>
        <th className="middle small text-right">{getSumUnrealizedPnl()}</th>
        {/* <th className="middle small text-right">{getTotalPnlAvg()}</th> */}
        <th className="middle small text-right">{getSumNetPnl()}</th>
        {/* <th className="middle small text-center">{totalPosPctVal}</th> */}
        <th className="middle small text-center">{sumTotalPnlPctElement}</th>
        <th className="middle small"></th>
        <th className="middle small"></th>
        <th className="middle small text-center">
          <span className="glyphicon glyphicon-ok grey clickable" onClick={completeAllHandler} title="Complete All"></span>
        </th>
        <th className="middle small text-center"></th>
      </tr>
    );
  }

  return (
    <React.Fragment>
      <div className="table-responsive">
        <table className="table table-striped table-hover table-condensed">
          <thead>{header}</thead>
          <tbody>{rows}</tbody>
          <tfoot>{footer}</tfoot>
        </table>
      </div>
    </React.Fragment>
  );
};

export default withRouter(BacktestTable);
