import * as React from 'react';

import Switch from 'react-switch';

import { Indicator } from '../../../../types';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const TrendFollowingAtrForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label htmlFor="tf_ma_periods" className="col-sm-6 control-label">
          ATR Periods
        </label>
        <div className="col-sm-6">
          <input
            id="tf_ma_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.periods}
            onChange={(event) => update({ periods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="tf_periods" className="col-sm-6 control-label">
          Trend Following Periods
        </label>
        <div className="col-sm-6">
          <input
            id="tf_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.secondaryPeriods}
            onChange={(event) => update({ secondaryPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="section">
        <hr />
        <p>Options</p>
      </div>

      <div className="form-group form-group-sm">
        <label className="col-sm-6 control-label">Use Counter Trend</label>
        <div className="col-sm-6">
          <label className="react-switch react-switch--sm">
            <Switch onChange={() => update({ useCrossOver: !indicator.useCrossOver })} checked={indicator.useCrossOver} />
          </label>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TrendFollowingAtrForm;
