import { Indicator } from '../../../types';
import { showNetworkError } from '../../shared/utils/growl-utils';

import { addIndicator, removeIndicator, setIndicators, setIndicatorsError, updateIndicator } from './action-creators';
import { IndicatorHttpRequest } from './http-request';

/*** ASYNC WITH THUNK ***/

export const loadIndicatorsAsync = (strategyId: number) => {
  return (dispatch) => {
    IndicatorHttpRequest.get(strategyId)
      .then((response) => {
        // console.log(response);
        dispatch(setIndicators(strategyId, response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load indicators');
        dispatch(setIndicatorsError(strategyId, msg));
      });
  };
};

export const addIndicatorAsync = (indicator: Indicator) => {
  return (dispatch) => {
    IndicatorHttpRequest.insert(indicator)
      .then((response) => {
        dispatch(addIndicator(response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setIndicatorsError(indicator.strategyId, msg));
      });
  };
};

export const updateIndicatorAsync = (indicator: Indicator) => {
  return (dispatch) => {
    IndicatorHttpRequest.update(indicator)
      .then(() => {
        dispatch(updateIndicator(indicator));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setIndicatorsError(indicator.strategyId, msg));
      });
  };
};

export const removeIndicatorAsync = (indicator: Indicator) => {
  return (dispatch) => {
    IndicatorHttpRequest.remove(indicator.id)
      .then(() => {
        dispatch(removeIndicator(indicator));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setIndicatorsError(indicator.strategyId, msg));
      });
  };
};
