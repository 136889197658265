import * as React from 'react';

import { Switch, Route } from 'react-router-dom';

import ContractListScreen from './components/ContractListScreen';
import ContractScreen from './components/ContractScreen';

export const contractModulePath = '/contracts';

const render = () => {
  return (
    <Switch>
      {/* <Route exact path={contractModulePath + '/security/:securityId?'} component={ContractListScreen}></Route> */}
      <Route exact path={contractModulePath} component={ContractListScreen}></Route>
      <Route exact path={contractModulePath + '/:id'} component={ContractScreen}></Route>
    </Switch>
  );
};

export default {
  routeProps: {
    path: contractModulePath,
    render
  },
  name: 'Contract'
};
