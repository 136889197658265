import { showNetworkError } from '../../../shared/utils/growl-utils';

import { setSubscriptionSummaries, setSubscriptionSummariesError, setSubscriptionSummariesLoading } from './action-creators';
import { BacktestSummaryHttpRequest } from './http-request';

export const loadSubscriptionSummariesAsync = () => {
  return (dispatch) => {
    // reset loading
    dispatch(setSubscriptionSummariesLoading(true));

    BacktestSummaryHttpRequest.getSubscriptionSummaries()
      .then((response) => {
        dispatch(setSubscriptionSummaries(response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load backtest-summaries');
        dispatch(setSubscriptionSummariesError(msg));
      });
  };
};
