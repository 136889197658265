import * as React from 'react';
import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import * as accountActionCreators from '../../account/store/action-creators';
import * as actionCreators from '../store/action-creators';

const Logout: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionCreators.logout());
    dispatch(accountActionCreators.resetAccount());
  }, []);

  // do something to clear state
  // const rootReducer = (state, action) => {
  //   if (action.type === 'USER_LOGOUT') {
  //     state = undefined
  //   }

  return <Redirect to="/login" />;
};

export default Logout;
