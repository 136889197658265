import * as React from 'react';
import { useState } from 'react';

import queryString from 'query-string';
import { Tabs, Tab } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { GlobalState } from '../../../../core/root';
import { Strategy, StrategyGroup, Subscription, Indicator } from '../../../../types';
import { getIndicatorState } from '../../../indicator/store/selectors';
import { addIndicatorAsync, loadIndicatorsAsync } from '../../../indicator/store/service';
import Spinner from '../../../shared/components/Spinner';
import { showGrowlError } from '../../../shared/utils/growl-utils';
import * as strategyGroupActionCreators from '../../../strategy-group/store/action-creators';
import * as subscriptionActionCreators from '../../../subscription/store/action-creators';
import { strategyModulePath } from '../../routes';
import * as actionCreators from '../../store/action-creators';
import { duplicateStrategyAsync, loadStrategiesAsync, removeStrategyAsync, updateStrategyAsync } from '../../store/services';

import BacktestTab from './BacktestTab';
import GeneralTab from './GeneralTab';
import IndicatorTab from './IndicatorTab';
import RiskTab from './RiskTab';
import StrategyGroupNav from './StrategyGroupNav';
import * as tabs from './tabs';
import TimePeriodTab from './TimePeriodTab';

export interface RouteParamProps {
  id: string;
}

export type Props = RouteComponentProps<RouteParamProps>;

const StrategyScreen: React.FunctionComponent<Props> = ({ location, match, history }: Props) => {
  const params = queryString.parse(location.search);
  const tab = params.tab as string;

  const [duplicationInProgress, setDuplicationInProgress] = useState(false);

  const state: GlobalState = useSelector((gs: GlobalState) => gs);
  const selectedTab = tab ? tab : state.strategyState.ui.selectedTab || tabs.GENERAL;

  const strategy = state.strategyState.strategies.find((x) => x.id === Number(match.params.id));
  const loaded = state.strategyState.loaded;

  const strategyGroups: StrategyGroup[] = state.strategyGroupState.strategyGroups;
  const strategyGroupsLoaded: boolean = state.strategyGroupState.loaded;

  const subscriptions: Subscription[] = state.subscriptionState.subscriptions;
  const subscriptionsLoaded: boolean = state.subscriptionState.loaded;

  const indicatorState = strategy ? getIndicatorState(state, strategy.id) : null;
  // const shouldFetchIndicators: boolean = !indicatorState || !!indicatorState.error;
  const indicators = indicatorState ? indicatorState.indicators : [];
  const indicatorsLoaded: boolean = indicatorState ? indicatorState.loaded : false;

  const authAccountId = state.authState.accountId;

  const dispatch = useDispatch();
  const loadStrategies = () => dispatch(loadStrategiesAsync());
  const removeStrategy = (id: number) => dispatch(removeStrategyAsync(id));
  const updateStrategy = (strategy: Strategy) => dispatch(updateStrategyAsync(strategy));
  const duplicateStrategy = (strategy: Strategy, callback: (dupId: number) => void) => dispatch(duplicateStrategyAsync(strategy, callback));
  const selectTab = (tab: string) => dispatch(actionCreators.selectTab(tab));
  const loadStrategyGroups = () => dispatch(strategyGroupActionCreators.loadStrategyGroupsAsync());
  const loadSubscriptions = () => dispatch(subscriptionActionCreators.loadSubscriptionsAsync());
  const loadIndicators = (strategyId: number) => dispatch(loadIndicatorsAsync(strategyId));
  const addIndicator = (indicator: Indicator) => dispatch(addIndicatorAsync(indicator));

  React.useEffect(() => {
    if (!loaded) {
      loadStrategies();
    }
    if (!strategyGroupsLoaded) {
      loadStrategyGroups();
    }
    if (!subscriptionsLoaded) {
      loadSubscriptions();
    }
  }, []);

  React.useEffect(() => {
    if (strategy) {
      if (!indicatorsLoaded) {
        loadIndicators(strategy.id);
      }
    }
  }, [strategy]);

  const selectTabHandler = (key: string) => {
    selectTab(key);
    const search = key ? `?tab=${key}` : '';
    const pathname = match.url;
    history.push({ pathname, search: search });
  };

  const navigateToList = () => {
    const url = `${strategyModulePath}`;
    history.push(url);
  };

  const duplicateStrategyCallback = (dupId) => {
    if (!dupId || !strategy) {
      showGrowlError('Duplicate strategy has invalid id.');
      setDuplicationInProgress(false);
      return;
    }

    /* copy indicators */
    const copiedIndicators = indicators
      .filter((x) => x.strategyId === strategy.id)
      .map((x) => {
        return {
          ...x,
          id: 0,
          strategyId: dupId
        };
      });
    for (let i = 0; i < copiedIndicators.length; i++) {
      addIndicator(copiedIndicators[i]);
    }

    /* copy indicator-sets (deprecated) */
    // const strategyId = this.props.strategy.id;
    // const indicatorSets = this.props.indicatorSets.filter(x => x.strategyId === strategyId);
    // const copiedIndicatorSets = indicatorSets.map(x => {
    //   return {
    //     ...x,
    //     id: 0,
    //     strategyId: dupId
    //   }
    // });
    // for (let i = 0; i < copiedIndicatorSets.length; i++) {
    //   this.props.addIndicatorSet(copiedIndicatorSets[i]);
    // }

    setDuplicationInProgress(false);

    const url = `${strategyModulePath}/${dupId}`;
    history.push(url);
  };

  const duplicateStrategyHandler = () => {
    if (!strategy) {
      return;
    }

    setDuplicationInProgress(true);

    // remove strategy-group if is not owned by user
    const strategyGroup = strategyGroups.find((x) => x.id === strategy.groupId);
    const strategyGroupOwner = strategyGroup && strategyGroup.accountId === authAccountId;
    const groupId = strategyGroupOwner ? strategy.groupId : undefined;

    const duplicate = {
      ...strategy,
      id: 0,
      groupId
    };

    // postfix number to strategy-name
    const wordsInName = duplicate.name.split(' ');
    if (wordsInName.length > 0) {
      const lastValue = wordsInName[wordsInName.length - 1];
      let num = Number(lastValue);
      if (!isNaN(num)) {
        num++;
        wordsInName[wordsInName.length - 1] = '' + num;
        duplicate.name = wordsInName.join(' ');
      } else {
        duplicate.name += ' 2';
      }
    }

    duplicateStrategy(duplicate, duplicateStrategyCallback);
  };

  const deleteStrategyHandler = () => {
    if (strategy) {
      removeStrategy(strategy.id);
      navigateToList();
    }
  };

  const loading = duplicationInProgress || !loaded || !strategyGroupsLoaded || !subscriptionsLoaded || !indicatorsLoaded;

  const title = strategy ? strategy.name : 'undefined';

  const userGroups = strategyGroups ? strategyGroups.filter((x) => (strategy ? x.accountId === strategy.accountId : true)) : [];

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/strategies">Strategies</Link>
        </li>
        <li className="breadcrumb-item active">{title}</li>
      </ol>

      {loading && <Spinner />}

      <StrategyGroupNav strategyId={strategy?.id || 0} strategyGroupId={strategy?.groupId || 0} />

      <div className="page-header">
        <h3>
          {title} <small className="no-wrap"></small>
        </h3>
      </div>

      <Tabs id="strategy_tabs" animation={false} activeKey={selectedTab} onSelect={selectTabHandler}>
        <Tab eventKey={tabs.GENERAL} title="General">
          {selectedTab === tabs.GENERAL && strategy && (
            <GeneralTab
              strategyId={strategy.id}
              updateStrategy={updateStrategy}
              strategyGroups={userGroups}
              subscriptions={subscriptions}
              duplicateStrategy={duplicateStrategyHandler}
              deleteStrategy={deleteStrategyHandler}
            />
          )}
        </Tab>
        <Tab eventKey={tabs.RISK} title="PnL">
          {selectedTab === tabs.RISK && strategy && <RiskTab strategyId={strategy.id} updateStrategy={updateStrategy} />}
        </Tab>
        <Tab eventKey={tabs.TIME} title="Time">
          {selectedTab === tabs.TIME && strategy && <TimePeriodTab strategyId={strategy.id} updateStrategy={updateStrategy} />}
        </Tab>
        <Tab eventKey={tabs.INDICATORS} title="Indicators">
          {selectedTab === tabs.INDICATORS && strategy && <IndicatorTab strategy={strategy} />}
        </Tab>
        <Tab eventKey={tabs.BACKTESTS} title="Backtests">
          {selectedTab === tabs.BACKTESTS && strategy && <BacktestTab strategy={strategy} />}
        </Tab>
      </Tabs>
    </div>
  );
};

export default withRouter(StrategyScreen);
