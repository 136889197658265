import * as React from 'react';

import { Indicator } from '../../../../types';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const OpeningHourForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label htmlFor="oh_entry_hr" className="col-sm-6 control-label">
          Entry Hour
        </label>
        <div className="col-sm-6">
          <input
            id="oh_entry_hr"
            type="number"
            step="1"
            className="form-control"
            value={indicator.entryHour}
            onChange={(event) => update({ entryHour: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="oh_entry_min" className="col-sm-6 control-label">
          Entry Minute
        </label>
        <div className="col-sm-6">
          <input
            id="oh_entry_min"
            type="number"
            step="1"
            className="form-control"
            value={indicator.entryMinute}
            onChange={(event) => update({ entryMinute: Number(event.target.value) })}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default OpeningHourForm;
