import { AnyAction } from 'redux';

import { StrategyAction } from './action-creators';
import * as actionTypes from './action-types';
import { initalStrategyState } from './state';

export const strategyReducer = (state = initalStrategyState, action: AnyAction | StrategyAction) => {
  const getStrategyIndex = (id: number): number => {
    const item = state.strategies.find((x) => x.id === id);
    return item ? state.strategies.indexOf(item) : -1;
  };

  if (action.type === actionTypes.ADD_STRATEGY) {
    const strategies = [...state.strategies];
    strategies.push(action.strategy);
    return {
      ...state,
      strategies
    };
  } else if (action.type === actionTypes.UPDATE_STRATEGY) {
    const strategy = action.strategy;
    const index = getStrategyIndex(strategy.id);
    const strategies = [...state.strategies];
    strategies[index] = strategy;
    return {
      ...state,
      strategies
    };
  } else if (action.type === actionTypes.REMOVE_STRATEGY) {
    const id = action.id as number;
    const index = getStrategyIndex(id);
    const strategies = [...state.strategies];
    strategies.splice(index, 1);
    return {
      ...state,
      strategies
    };
  } else if (action.type === actionTypes.SET_STRATEGIES) {
    return {
      ...state,
      loaded: true,
      error: undefined,
      strategies: action.strategies
    };
  } else if (action.type === actionTypes.ADD_ARCHIVED_STRATEGIES) {
    const currStrategies = [...state.strategies];
    const strategies = currStrategies.concat(action.strategies);
    return {
      ...state,
      archivedLoaded: true,
      strategies
    };
  } else if (action.type === actionTypes.SET_STRATEGY_ERROR) {
    return {
      ...state,
      loaded: true,
      error: action.error
    };
  } else if (action.type === actionTypes.SET_STRATEGY_LOADED) {
    return {
      ...state,
      loaded: action.loaded
    };
  } else if (action.type === actionTypes.UI_SHOW_ARCHIVED) {
    const ui = { ...state.ui };
    ui.showArchived = action.showArchived;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_COLLAPSE_OPTION_PANEL) {
    const ui = { ...state.ui };
    ui.optionPanelCollapsed = action.collapsed;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_COLLAPSE_PICKER_PANEL) {
    const ui = { ...state.ui };
    ui.pickerPanelCollapsed = action.collapsed;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_COLLAPSE_DETAILS_PANEL) {
    const ui = { ...state.ui };
    ui.detailsCollapsed = action.collapsed;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_STRATEGY_PICKED) {
    const ui = { ...state.ui };
    ui.strategyPicked = action.strategy ? { ...action.strategy } : undefined;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_SELECT_TAB) {
    const ui = { ...state.ui };
    ui.selectedTab = action.selectedTab;
    return {
      ...state,
      ui
    };
  }

  return state;
};
