import * as React from 'react';
import { FunctionComponent } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { GlobalState } from '../../../../core/root';
import { ProductType } from '../../../../types';
import CollapsePanel from '../../../shared/components/CollapsePanel';
import * as actionCreators from '../../store/action-creators';

import CategoryFilter from './CategoryFilter';

export interface SecurityFilterPanelProps {
  title?: string;
  useCollapsePanel?: boolean;
}

const CategoryFilterPanel: FunctionComponent<SecurityFilterPanelProps> = ({ title, useCollapsePanel }) => {
  const state: GlobalState = useSelector((gs: GlobalState) => gs);
  const collapsed = state.securityState.ui.optionPanelCollapsed;
  const selectedProductTypes = state.securityState.ui.selectedProductTypes;
  const dispatch = useDispatch();

  const toggleCollapse = () => {
    dispatch(actionCreators.setOptionPanelCollapsed(!collapsed));
  };

  const getSelectedTitles = () => {
    return selectedProductTypes.map((x) => ProductType[x].replace('_', ' '));
  };

  if (useCollapsePanel) {
    <CollapsePanel title={title || 'Categories'} subTitles={getSelectedTitles()} collapsed={collapsed} onToggle={toggleCollapse}>
      <div className="form-horizontal">
        <div className="row">
          <div className="col-xs-12">
            <CategoryFilter />
          </div>
        </div>
      </div>
    </CollapsePanel>;
  }

  return (
    <div className="gap-top-5 gap-bottom-15">
      <CategoryFilter />
      <div className="section">
        <hr />
        <p>Securities</p>
      </div>
    </div>
  );
};

export default CategoryFilterPanel;
