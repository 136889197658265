import * as React from 'react';
import { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { GlobalState } from '../../../core/root';
import { ContractEntity, PeriodType, Security, Strategy, Subscription, Summary } from '../../../types';
import SecurityPickerPanel from '../../security/components/SecurityPickerPanel';
import { setSecurityPicked } from '../../security/store/action-creators';
import CollapsePanel from '../../shared/components/CollapsePanel';
import Spinner from '../../shared/components/Spinner';
import SubscriptionListToolbar from '../../shared/components/SubscriptionListToolbar';
import { LIVE_ACCOUNT, PAPER_TRADING_ACCOUNT } from '../../shared/constants/broker-accounts';
import { groupBy } from '../../shared/utils/array-utils';
import { formatUsd } from '../../shared/utils/currency-utils';
import { setDecimalSpaces, setThousenSeparator } from '../../shared/utils/number-utils';
import { mergeSummaries } from '../../shared/utils/summary-utils';
import StrategyPickerPanel from '../../strategy/components/StrategyPickerPanel';
import { setStrategyPicked } from '../../strategy/store/action-creators';
import * as actionCreators from '../store/pnl-strategy/action-creators';
import { getLoading, getSummaries, shouldFetchSummaries } from '../store/pnl-strategy/state';

const PnlStrategyScreen = () => {
  const selectedSecurity: Security = useSelector((state: GlobalState) => state.securityState.ui.securityPicked);
  const selectedStrategy: Strategy = useSelector((state: GlobalState) => state.strategyState.ui.strategyPicked);
  const shouldFetch: boolean = useSelector((state: GlobalState) =>
    selectedStrategy ? shouldFetchSummaries(state, selectedStrategy.id) : false
  );
  const loading: boolean = useSelector((state: GlobalState) => (selectedStrategy ? getLoading(state, selectedStrategy.id) : false));
  const summaries: Summary[] = useSelector((state: GlobalState) => (selectedStrategy ? getSummaries(state, selectedStrategy.id) : []));

  const [selectedContract, setSelectedContract] = useState<ContractEntity | undefined>();

  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(0);

  const dispatch = useDispatch();

  const toggleSelectSubscriptionHandler = (sub: Subscription) => {
    setSelectedContract(undefined);
    if (sub.id === selectedSubscriptionId) {
      setSelectedSubscriptionId(0);
      dispatch(setSecurityPicked(undefined));
      dispatch(setStrategyPicked(undefined));
    } else {
      setSelectedSubscriptionId(sub.id);
      dispatch(setSecurityPicked(sub.security));
      dispatch(setStrategyPicked(sub.strategy));
    }
  };

  useEffect(() => {
    setSelectedContract(undefined);
    if (shouldFetch) {
      dispatch(actionCreators.fetchPnlByStrategyAsync(selectedStrategy.id));
    }
  }, [selectedStrategy]);

  const refreshHandler = () => {
    dispatch(actionCreators.fetchPnlByStrategyAsync(selectedStrategy.id));
  };

  const contracts: ContractEntity[] = [];
  if (summaries) {
    summaries
      .filter((x) => {
        if (selectedSecurity) {
          return x.securityId === selectedSecurity.id;
        }
        return x;
      })
      .forEach((x) => {
        const c = contracts.find((y) => y.id === x.contract.id);
        if (!c) {
          contracts.push(x.contract);
        }
      });
  }

  const toggleContractSelectedHandler = (contract: ContractEntity) => {
    if (selectedContract && selectedContract.id === contract.id) {
      setSelectedContract(undefined);
    } else {
      setSelectedContract(contract);
    }
  };

  const getContractOptionButtons = (clist: ContractEntity[]) => {
    return clist
      .sort((a: ContractEntity, b: ContractEntity) => {
        if (!a.firstNoticeDate || !b.firstNoticeDate) return 0;
        if (a.firstNoticeDate > b.firstNoticeDate) return 1;
        if (a.firstNoticeDate < b.firstNoticeDate) return -1;
        return 0;
      })
      .map((x) => (
        <button
          key={x.localSymbol}
          type="button"
          className={classNames('btn', 'btn-sm', 'btn-default', 'gap-bottom-5', { active: x.id === selectedContract?.id })}
          onClick={() => toggleContractSelectedHandler(x)}
        >
          {x.localSymbol}
        </button>
      ));
  };

  const groupedContracts = groupBy(contracts, 'securityId');
  const groupedContractElements = groupedContracts.map((x) => (
    <div key={x.key} className="btn-toolbar">
      {getContractOptionButtons(x.list)}
    </div>
  ));

  const contractOptionPanel = (
    <CollapsePanel title="Contracts" upper={true} collapsed={true} smallTitle="Optional" subTitle={selectedContract?.localSymbol}>
      {groupedContracts.length > 0 ? groupedContractElements : <span className="small">No contracts..</span>}
    </CollapsePanel>
  );

  const filterSummaries = (type: PeriodType, paperTrading?: boolean, liveTrading?: boolean) => {
    return summaries
      .filter((x) => {
        if (selectedSecurity) {
          return x.securityId === selectedSecurity.id;
        }
        return x;
      })
      .filter((x) => {
        if (selectedContract) {
          return x.contractId === selectedContract.id;
        }
        return x;
      })
      .filter((x) => x.type === type)
      .filter((x) => {
        if (paperTrading) {
          return PAPER_TRADING_ACCOUNT.find((a) => a === x.account) !== undefined;
        }
        return x;
      })
      .filter((x) => {
        if (liveTrading) {
          return x.account === LIVE_ACCOUNT;
        }
        return x;
      });
  };

  const getTotalSummaries = () => {
    if (!summaries) {
      return [];
    }

    const totalSummaries: Summary[] = [];

    const btSummaries = filterSummaries(PeriodType.Backtest);
    if (btSummaries.length > 0) {
      const merged = mergeSummaries(btSummaries);
      totalSummaries.push(merged);
    }
    const simSummaries = filterSummaries(PeriodType.Simulation);
    if (simSummaries.length > 0) {
      const merged = mergeSummaries(simSummaries);
      totalSummaries.push(merged);
    }
    const paperSummaries = filterSummaries(PeriodType.Live, true);
    if (paperSummaries.length > 0) {
      const merged = mergeSummaries(paperSummaries);
      totalSummaries.push(merged);
    }
    const liveSummaries = filterSummaries(PeriodType.Live, false, true);
    if (liveSummaries.length > 0) {
      const merged = mergeSummaries(liveSummaries);
      totalSummaries.push(merged);
    }

    return totalSummaries;
  };

  const currentSummaries = getTotalSummaries();

  const getTypeName = (summary: Summary) => {
    switch (summary.type) {
      case PeriodType.Backtest:
      case PeriodType.Simulation:
        return PeriodType[summary.type];
      case PeriodType.Live:
        return summary.account === LIVE_ACCOUNT ? 'Live' : 'Paper';
      default:
        return 'N/A';
    }
  };

  const sortByType = (a: Summary, b: Summary) => {
    const aName = getTypeName(a);
    const bName = getTypeName(b);
    if (aName > bName) return 1;
    if (aName < bName) return -1;
    return 0;
  };

  const header = (
    <tr>
      <th colSpan={3} className="middle small text-center"></th>
      <th colSpan={5} className="middle small text-center">
        Count
      </th>
      <th colSpan={4} className="middle small text-center">
        PnL
      </th>
      <th className="middle small text-center"></th>
    </tr>
  );

  const header2 = (
    <tr>
      <th className="middle small text-center">Type</th>
      <th className="middle small text-right">Spread</th>
      <th className="middle small text-right">Comm.</th>

      <th className="middle small text-right vborder">Gain</th>
      <th className="middle small text-right">Loss</th>
      <th className="middle small text-right">Even</th>
      <th className="middle small text-right">Total</th>
      <th className="middle small text-right">Pct</th>

      <th className="middle small text-right vborder">Gain</th>
      <th className="middle small text-right">Loss</th>
      <th className="middle small text-right">Total</th>
      <th className="middle small text-right">Pct</th>

      <th className="middle small text-right vborder">Rlz. PnL</th>
    </tr>
  );

  const rows = currentSummaries.sort(sortByType).map((x) => {
    const totalCount = x.gainNum + x.lossNum;
    const countPct = totalCount > 0 ? (x.gainNum / totalCount) * 100 : undefined;
    const profitCountPct = countPct ? <span>{setDecimalSpaces(countPct, 0)}%</span> : '-';

    const totalPnl = x.gain + x.loss * -1;
    const amountPct = totalPnl > 0 ? (x.gain / totalPnl) * 100 : undefined;
    const pnlPct = amountPct ? <span>{setDecimalSpaces(amountPct, 0)}%</span> : '-';

    const typeName = getTypeName(x);

    return (
      <tr key={`${x.contractId}-${x.type}`}>
        <td className={classNames('middle', 'small', 'text-center', { 'paper-text': typeName === 'Paper' })}>
          <span className={classNames({ 'success-text2': typeName === 'Live' })}>{typeName}</span>
        </td>
        <td className="middle small text-right">{formatUsd(x.spreadCost, 0)}</td>
        <td className="middle small text-right">{formatUsd(x.commission, 1)}</td>

        <td className="middle small text-right vborder">{setThousenSeparator(x.gainNum)}</td>
        <td className="middle small text-right">{setThousenSeparator(x.lossNum)}</td>
        <td className="middle small text-right">{setThousenSeparator(x.evenNum)}</td>
        <td className="middle small text-right">{setThousenSeparator(x.count)}</td>
        <td className="middle small text-right">
          <span
            className={classNames({
              'success-text': countPct && countPct >= 60,
              'danger-text2': countPct && countPct <= 50
            })}
          >
            {profitCountPct}
          </span>
        </td>

        <td className="middle small text-right vborder">{formatUsd(x.gain, 0)}</td>
        <td className="middle small text-right">{formatUsd(x.loss, 0)}</td>
        <td className="middle small text-right">
          <span
            className={classNames({
              'success-text': x.netProfit > 0,
              'danger-text2': x.netProfit < 0
            })}
          >
            {formatUsd(x.netProfit, 0)}
          </span>
        </td>
        <td className="middle small text-right">
          <span
            className={classNames({
              'success-text': amountPct && amountPct >= 60,
              'danger-text2': amountPct && amountPct <= 50
            })}
          >
            {pnlPct}
          </span>
        </td>

        <td className="middle small text-right vborder">
          <span
            className={classNames({
              'success-text': x.realizedProfitAndLoss > 0,
              'danger-text2': x.realizedProfitAndLoss < 0
            })}
          >
            {formatUsd(x.realizedProfitAndLoss, 0)}
          </span>
        </td>
      </tr>
    );
  });

  const table = (
    <div className="table-responsive">
      <table className="table table-striped table-hover table-condensed">
        <thead>{header}</thead>
        <thead>{header2}</thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );

  const body =
    currentSummaries && currentSummaries.length > 0 ? <div>{table}</div> : <p className="small">No summaries for selected security..</p>;

  const refreshButton = (
    <div className="row">
      <div className="col-xs-12 text-right">
        <button type="button" className="btn btn-sm btn-success ripple gap-top-20" onClick={refreshHandler}>
          Refresh
        </button>
      </div>
    </div>
  );

  return (
    <div>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item active">PnL by Strategy</li>
      </ol>

      <div className="page-header">
        <h3>
          Profit &amp; Loss <small className="no-wrap"></small>
        </h3>
      </div>

      {loading && <Spinner />}

      <SubscriptionListToolbar selectedSubscriptionId={selectedSubscriptionId} onToggleSubscription={toggleSelectSubscriptionHandler} />

      <StrategyPickerPanel upper={true} />

      <SecurityPickerPanel upper={true} smallTitle="Optional" />

      {contractOptionPanel}

      <div className="section">
        <hr />
        <p>Summaries</p>
      </div>

      {selectedStrategy ? (
        <div>
          {body}
          {refreshButton}
        </div>
      ) : (
        <p className="small">No strategy selected..</p>
      )}
    </div>
  );
};

export default PnlStrategyScreen;
