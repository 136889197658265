import { OpenOrderViewModel } from '../../../../types';

export interface OpenOrderState {
  loaded: boolean;
  error?: string;
  openOrders: OpenOrderViewModel[];
  ui: {
    openOrdersCollapsed: boolean;
  };
}

export const initalOpenOrderState: OpenOrderState = {
  loaded: false,
  error: undefined,
  openOrders: [],
  ui: {
    openOrdersCollapsed: true
  }
};
