import React, { useState, useEffect } from 'react';

import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Switch from 'react-switch';

import { GlobalState } from '../../../../core/root';
import { BarSize, RiskType, Strategy, StrategyGroup, Subscription } from '../../../../types';
import AdministrationPanel from '../../../shared/components/AdministrationPanel';
import CollapsePanel from '../../../shared/components/CollapsePanel';
import { barSizes } from '../../../shared/utils/bar-utils';
import { riskTypes } from '../../../shared/utils/risk-utils';
import { setDetailsCollapsed } from '../../store/action-creators';

export interface GeneralTabProps {
  strategyId: number;
  updateStrategy(strategy: Strategy): void;
  strategyGroups: StrategyGroup[];
  subscriptions: Subscription[];
  duplicateStrategy: () => void;
  deleteStrategy: () => void;
}

const GeneralTab: React.FunctionComponent<GeneralTabProps> = ({
  strategyId,
  updateStrategy,
  strategyGroups,
  subscriptions,
  duplicateStrategy,
  deleteStrategy
}: GeneralTabProps) => {
  // bugfix: use-selctor top listen to redux-state changes elsewhere;
  // listening to props changes didnt work
  const strategy = useSelector((state: GlobalState) => state.strategyState.strategies.find((x) => x.id === strategyId));

  const [strategyForm, setStrategyForm] = useState<Strategy>(strategy);

  const detailsCollapsed = useSelector((state: GlobalState) => state.strategyState.ui.detailsCollapsed);

  const dispatch = useDispatch();

  useEffect(() => {
    if (strategyForm != strategy) {
      setStrategyForm(strategy);
    }
  }, [strategy]);

  const mapFromState = (): Strategy => {
    let selectedStrategyGroup = strategyForm.group;
    if (strategyForm.groupId) {
      const item = strategyGroups.find((x) => x.id === strategyForm.groupId);
      if (item) {
        selectedStrategyGroup = { ...item };
      }
    }
    return {
      ...strategy,
      ...strategyForm,
      group: selectedStrategyGroup
    };
  };

  const updateStrategyHandler = () => {
    const item = mapFromState();
    updateStrategy(item);
  };

  const updateStrategyState = (obj: object) => {
    const field = Object.keys(obj)[0];
    const str = {
      ...strategyForm,
      [field]: obj[field]
    } as Strategy;
    if (!!strategyForm.group) {
      str.group = {
        id: strategyForm.group.id,
        name: strategyForm.group.name,
        archived: strategyForm.group.archived
      };
    }
    setStrategyForm(str);
  };

  const { id, name, groupId, periodBarSize, profitType, lossType, avgTrueRangePeriods, archived, dangerWinPct, successWinPct } =
    strategyForm;

  const strategyGroupOptions = strategyGroups.map((x) => (
    <option key={x.id} value={x.id}>
      {x.name}
    </option>
  ));

  const filteredSubscriptions = subscriptions
    .filter((x) => x.strategyId === id)
    .sort((a: Subscription, b: Subscription) => {
      if (a.security.name > b.security.name) return 1;
      else if (a.security.name < b.security.name) return -1;
      else return a.contract.localSymbol > b.contract.localSymbol ? 1 : -1;
    });

  const subscriptionTable = (
    <div className="table-responsive">
      <table className="table table-bordered table-condensed">
        <thead>
          <tr>
            <th className="middle small text-center">Security</th>
            <th className="middle small text-center">Contract</th>
          </tr>
        </thead>
        <tbody>
          {filteredSubscriptions.map((x: Subscription) => (
            <tr key={x.id}>
              <td className="middle small text-center">{x.security.name}</td>
              <td className="middle small text-center">{x.contract.localSymbol}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const subscriptionView = subscriptions.length > 0 ? subscriptionTable : <p className="small">None..</p>;

  const timePeriodBarSizePopover = <Popover id="timePeriodBarSizePopover">The duration between each schedule execution</Popover>;
  const successWinPctPopover = (
    <Popover id="successWinPctPopover">
      Uses <b>success</b> color for Backtest PnL percentage if the percetage is above the this threshold
    </Popover>
  );
  const dangerWinPctPopover = (
    <Popover id="dangerWinPctPopover">
      Uses <b>danger</b> color for Backtest PnL percentage if the percetage is above the this threshold
    </Popover>
  );

  const quoteBarSizes = barSizes.filter((x) => x.key >= BarSize._5_MIN && x.key <= BarSize._1_DAY);

  const showAvgTrueRangePeriods = profitType === RiskType.AvgTrueRange || lossType === RiskType.AvgTrueRange;

  const riskPeriodsPopover = (
    <Popover id="avgTickPeriodsPopover">
      The number of periods used to calulate ATR or AVG or Bar Peaks. The period duration is equal to schedule period duration by default.
    </Popover>
  );

  return (
    <div>
      <div className="row form-horizontal">
        <div className="col-md-6">
          <CollapsePanel
            title="General Settings"
            collapsed={detailsCollapsed}
            onToggle={() => dispatch(setDetailsCollapsed(!detailsCollapsed))}
          >
            <div className="form-group form-group-sm">
              <label htmlFor="sec_name" className="col-sm-4 control-label">
                Name
              </label>
              <div className="col-sm-8">
                <input
                  id="sec_name"
                  type="text"
                  className="form-control"
                  value={name || ''}
                  onChange={(event) => updateStrategyState({ name: event.target.value })}
                />
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="sec_exchange" className="col-sm-4 control-label">
                Group
              </label>
              <div className="col-sm-8">
                <select
                  id="sec_exchange"
                  className="form-control"
                  value={groupId || ''}
                  onChange={(event) => updateStrategyState({ groupId: Number(event.target.value) })}
                >
                  <option value="">Velg..</option>
                  {strategyGroupOptions}
                </select>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="str_period_barsize" className="col-sm-4 control-label">
                <span>Period Length</span>
                <OverlayTrigger placement="top" overlay={timePeriodBarSizePopover}>
                  <span className="gap-left-10">
                    <i className="fa fa-question-circle fa-lg info-text" />
                  </span>
                </OverlayTrigger>
              </label>
              <div className="col-sm-8">
                <select
                  id="str_period_barsize"
                  className="form-control"
                  value={periodBarSize}
                  onChange={(event) => updateStrategyState({ periodBarSize: Number(event.target.value) })}
                >
                  <option key={BarSize.NA} value={BarSize.NA}>
                    Velg..
                  </option>
                  {quoteBarSizes.map((x) => (
                    <option key={x.key} value={x.key}>
                      {x.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="compare_basis_type" className="col-xs-12 col-sm-4 control-label">
                Profit Type
              </label>
              <div className="col-sm-8">
                <select
                  id="compare_basis_type"
                  className="form-control"
                  value={strategyForm.profitType}
                  onChange={(event) => updateStrategyState({ profitType: Number(event.target.value) })}
                >
                  {riskTypes.map((x) => (
                    <option key={x.key} value={x.key}>
                      {x.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="compare_basis_type" className="col-xs-12 col-sm-4 control-label">
                Loss Type
              </label>
              <div className="col-sm-8">
                <select
                  id="compare_basis_type"
                  className="form-control"
                  value={strategyForm.lossType}
                  onChange={(event) => updateStrategyState({ lossType: Number(event.target.value) })}
                >
                  {riskTypes.map((x) => (
                    <option key={x.key} value={x.key}>
                      {x.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {showAvgTrueRangePeriods && (
              <div className="form-group form-group-sm">
                <label htmlFor="atr_loss_periods" className="col-sm-4 control-label">
                  <span>ATR Periods</span>
                  <OverlayTrigger placement="top" overlay={riskPeriodsPopover}>
                    <span className="gap-left-10">
                      <i className="fa fa-question-circle fa-lg info-text" />
                    </span>
                  </OverlayTrigger>
                </label>
                <div className="col-sm-8">
                  <input
                    id="atr_loss_periods"
                    step="1"
                    type="number"
                    className="form-control"
                    value={avgTrueRangePeriods}
                    onChange={(event) => updateStrategyState({ avgTrueRangePeriods: Number(event.target.value) })}
                  />
                </div>
              </div>
            )}

            <div className="form-group form-group-sm">
              <label htmlFor="success_win_pct" className="col-sm-4 control-label">
                <span>Success Win Pct</span>
                <OverlayTrigger placement="top" overlay={successWinPctPopover}>
                  <span className="gap-left-10">
                    <i className="fa fa-question-circle fa-lg info-text" />
                  </span>
                </OverlayTrigger>
              </label>
              <div className="col-sm-8">
                <input
                  id="success_win_pct"
                  type="number"
                  className="form-control"
                  value={successWinPct}
                  onChange={(event) => updateStrategyState({ successWinPct: Number(event.target.value) })}
                />
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label htmlFor="danger_win_pct" className="col-sm-4 control-label">
                <span>Danger Win Pct</span>
                <OverlayTrigger placement="top" overlay={dangerWinPctPopover}>
                  <span className="gap-left-10">
                    <i className="fa fa-question-circle fa-lg info-text" />
                  </span>
                </OverlayTrigger>
              </label>
              <div className="col-sm-8">
                <input
                  id="danger_win_pct"
                  type="number"
                  className="form-control"
                  value={dangerWinPct}
                  onChange={(event) => updateStrategyState({ dangerWinPct: Number(event.target.value) })}
                />
              </div>
            </div>

            <div className="form-group form-group-sm">
              <label className="col-sm-4 control-label">Active</label>
              <div className="col-sm-8">
                <label className="react-switch react-switch--sm">
                  <Switch onChange={() => updateStrategyState({ archived: !archived })} checked={!archived} />
                </label>
              </div>
            </div>
          </CollapsePanel>
        </div>

        <div className="col-md-6">
          <CollapsePanel title="Subscriptions" subTitle={`${filteredSubscriptions.length}`} collapsed={false}>
            <div className="row">
              <div className="col-xs-12">{subscriptionView}</div>
            </div>
          </CollapsePanel>
          <AdministrationPanel
            duplicateTitle="Duplicate strategy with indicators"
            onDuplicate={duplicateStrategy}
            deleteTitle="Delete strategy with indicators"
            confirmDeleteText="Are you sure you want to delete the strategy?"
            onDelete={deleteStrategy}
          />
        </div>
      </div>

      <hr />
      <div className="row">
        <div className="col-xs-12 text-right">
          <button type="button" className="btn btn-primary ripple gap-bottom-5" onClick={updateStrategyHandler}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default GeneralTab;
