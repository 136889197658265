import { ContractPartialQuote } from '../../../../types';

export interface PartialQuoteState {
  loaded: boolean;
  error?: string;
  partialQuotes: ContractPartialQuote[];
}

export const initalPartialQuoteState: PartialQuoteState = {
  loaded: false,
  error: undefined,
  partialQuotes: []
};
