import * as React from 'react';

import { Switch, Route } from 'react-router-dom';

import BacktestListScreen from './components/BacktestListScreen';
import BacktestScreen from './components/BacktestScreen';

export const backtestModulePath = '/backtests';

const render = () => {
  return (
    <Switch>
      <Route exact path={backtestModulePath + '/:strategyId/:backtestId'} component={BacktestScreen}></Route>
      <Route exact path={backtestModulePath} component={BacktestListScreen}></Route>
    </Switch>
  );
};

export default {
  routeProps: {
    path: backtestModulePath,
    render
  },
  name: 'Backtest'
};
