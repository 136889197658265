import { AnyAction } from 'redux';

import { Subscription } from '../../../types';

import { SubscriptionAction } from './action-creators';
import * as actionTypes from './action-types';
import { initalSubscriptionState } from './state';

export const subscriptionReducer = (state = initalSubscriptionState, action: SubscriptionAction | AnyAction) => {
  const getSubscriptionIndex = (id: number): number => {
    const item = state.subscriptions.find((x) => x.id === id);
    return item ? state.subscriptions.indexOf(item) : -1;
  };

  if (action.type === actionTypes.FETCH_SUBSCRIPTIONS) {
    return {
      ...state,
      error: undefined,
      loading: true,
      loaded: false
    };
  } else if (action.type === actionTypes.SET_SUBSCRIPTIONS) {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: undefined,
      subscriptions: action.subscriptions
    };
  } else if (action.type === actionTypes.SET_SUBSCRIPTION_ERROR) {
    return {
      ...state,
      loaded: true,
      loading: false,
      error: action.value
    };
  } else if (action.type === actionTypes.CLEAR_SUBSCRIPTION_ERROR) {
    return {
      ...state,
      error: undefined
    };
  } else if (action.type === actionTypes.ADD_SUBSCRIPTION) {
    const subscriptions = [...state.subscriptions];
    subscriptions.push(action.subscription as Subscription);
    return {
      ...state,
      subscriptions
    };
  } else if (action.type === actionTypes.UPDATE_SUBSCRIPTION) {
    const subscription = action.subscription as Subscription;
    const index = getSubscriptionIndex(subscription.id);
    const subscriptions = [...state.subscriptions];
    subscriptions[index] = subscription;
    return {
      ...state,
      subscriptions
    };
  } else if (action.type === actionTypes.REMOVE_SUBSCRIPTION) {
    const id = Number(action.value);
    const index = getSubscriptionIndex(id);
    const subscriptions = [...state.subscriptions];
    subscriptions.splice(index, 1);
    return {
      ...state,
      subscriptions
    };
  }

  return state;
};
