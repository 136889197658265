import * as React from 'react';
import { useEffect, useState } from 'react';

import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { BarSize, ContractEntity } from '../../../../types';
import CollapsePanel from '../../../shared/components/CollapsePanel';
import { getBarSizeName } from '../../../shared/utils/bar-utils';
import { generateGuid } from '../../../shared/utils/guid-utils';
import * as graphDataActionCreators from '../../store/graph-data/action-creators';
import Graph from '../Graph';
import GraphConfigTab from '../GraphConfigPanel/GraphConfigTab';

export interface GraphDialogComponentProps {
  show: boolean;
  toggle(): void;
  contract: ContractEntity;
  barSize: BarSize;
  lastBarTime: Date;
  contractPartialId?: number;
  strategyId?: number;
}

const GraphDialogComponent: React.FunctionComponent<GraphDialogComponentProps> = (props) => {
  const [uniqueId, setUniqueId] = useState('');

  useEffect(() => {
    if (props.show) {
      setUniqueId(generateGuid());
    }
  }, [props.show]);

  const dispatch = useDispatch();

  const hideHandler = () => {
    props.toggle();
    // clean
    dispatch(graphDataActionCreators.removeGraphData(uniqueId));
  };

  return (
    <Modal show={props.show} onHide={props.toggle} bsSize="large" aria-labelledby="graph-modal-title">
      <Modal.Header closeButton>
        <Modal.Title id="graph-modal-title">
          <span className="label label-default">{props.contract.localSymbol}</span>
          <span className="label label-success gap-left-5">{getBarSizeName(props.barSize)}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CollapsePanel title="Config" upper={true} collapsed={true} className="mb-10">
          <GraphConfigTab barSize={props.barSize} />
        </CollapsePanel>

        {uniqueId && <Graph guid={uniqueId} {...props} />}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideHandler}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GraphDialogComponent;
