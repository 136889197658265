import { AnyAction } from 'redux';

import { ContractPartialQuote } from '../../../../types';

import * as actionTypes from './action-types';
import { initalPartialQuoteState } from './partial-quote-state';

export const partialQuoteReducer = (state = initalPartialQuoteState, action: AnyAction) => {
  const getPartialQuoteIndex = (partialQuote: ContractPartialQuote): number => {
    const item = state.partialQuotes.find(
      (x) => x.contractPartialId === partialQuote.contractPartialId && x.barSize === partialQuote.barSize
    );
    return item ? state.partialQuotes.indexOf(item) : -1;
  };

  if (action.type === actionTypes.ADD_PARTIAL_QUOTE_ITEM) {
    const partialQuotes = [...state.partialQuotes];
    partialQuotes.push(action.payload);
    return {
      ...state,
      error: undefined,
      partialQuotes
    };
  } else if (action.type === actionTypes.UPDATE_PARTIAL_QUOTE_ITEM) {
    const partialQuote = action.payload as ContractPartialQuote;
    const index = getPartialQuoteIndex(partialQuote);
    const partialQuotes = [...state.partialQuotes];
    partialQuotes[index] = partialQuote;
    return {
      ...state,
      error: undefined,
      partialQuotes
    };
  } else if (action.type === actionTypes.REMOVE_PARTIAL_QUOTE_ITEM) {
    const partialQuote = action.payload as ContractPartialQuote;
    const index = getPartialQuoteIndex(partialQuote);
    const partialQuotes = [...state.partialQuotes];
    partialQuotes.splice(index, 1);
    return {
      ...state,
      error: undefined,
      partialQuotes
    };
  } else if (action.type === actionTypes.SET_PARTIAL_QUOTES) {
    return {
      ...state,
      loaded: true,
      error: undefined,
      partialQuotes: action.payload
    };
  } else if (action.type === actionTypes.SET_PARTIAL_QUOTE_ERROR) {
    return {
      ...state,
      error: action.value
    };
  } else if (action.type === actionTypes.CLEAR_PARTIAL_QUOTE_ERROR) {
    return {
      ...state,
      loaded: true,
      error: undefined
    };
  } else if (action.type === actionTypes.TOGGLE_PARTIAL_QUOTE_LOADED) {
    return {
      ...state,
      loaded: action.value,
      error: undefined
    };
  }

  return state;
};
