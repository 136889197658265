import { BacktestSummaryMapState, BacktestSummaryState } from './state';

export interface PartialState {
  backtestSummaryMapState: BacktestSummaryMapState;
}

export const getBacktestSummaryState = (state: PartialState, securityId: number): BacktestSummaryState | undefined => {
  return state.backtestSummaryMapState.get(securityId);
};

export const getError = (state: PartialState, securityId: number) => {
  return getBacktestSummaryState(state, securityId)?.error;
};

export const getLoading = (state: PartialState, securityId: number) => {
  return getBacktestSummaryState(state, securityId)?.loading;
};

export const getBacktestSummariesLoaded = (state: PartialState, securityId: number) => {
  return getBacktestSummaryState(state, securityId)?.loaded || false;
};

export const getBacktestSummaries = (state: PartialState, securityId: number) => {
  return getBacktestSummaryState(state, securityId)?.summaries;
};

export const shouldFetchSummaries = (state: PartialState, secrurityId: number): boolean => {
  const backtestSummaryState = getBacktestSummaryState(state, secrurityId);
  return backtestSummaryState === undefined || !!backtestSummaryState.error;
};
