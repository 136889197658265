import { AnyAction } from 'redux';

import { ContractPartial } from '../../../../types';

import * as actionTypes from './action-types';
import { initalGraphState } from './state';

export const graphReducer = (state = initalGraphState, action: AnyAction) => {
  if (action.type === actionTypes.SET_PARTIAL_PANEL_COLLAPSED) {
    return {
      ...state,
      partialPanelCollapsed: action.value
    };
  } else if (action.type === actionTypes.SET_SELECTED_PARTIAL) {
    const selectedPartial: ContractPartial | undefined = action.value ? { ...action.value } : undefined;
    return {
      ...state,
      selectedPartial
    };
  }

  return state;
};
