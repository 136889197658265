import { Trade } from '../../../../types';

import * as actionTypes from './action-types';

export const setPositionTrades = (positionId: number, trades: Trade[]) => {
  return {
    type: actionTypes.SET_POSITION_TRADES,
    positionId,
    trades
  };
};

export const addPositionTrade = (positionId: number, trade: Trade) => {
  return {
    type: actionTypes.ADD_POSITION_TRADE,
    positionId,
    trade
  };
};

export const setPositionTradesLoaded = (positionId: number, toggle: boolean) => {
  return {
    type: actionTypes.SET_POSITION_TRADES_LOADED,
    positionId,
    loaded: toggle
  };
};

export const setPositionTradesError = (positionId: number, error: string) => {
  return {
    type: actionTypes.SET_POSITION_TRADES_ERROR,
    positionId,
    error
  };
};
