import { IndicatorResult } from '../../../types';

import * as actionTypes from './action-types';

export const setIndicatorResults = (periodId: number, indicatorResults: IndicatorResult[]) => {
  return {
    type: actionTypes.SET_INDICATOR_RESULTS,
    periodId,
    indicatorResults
  };
};

export const setIndicatorResultsLoading = (periodId: number, loading: boolean) => {
  return {
    type: actionTypes.SET_INDICATOR_RESULTS_LOADING,
    periodId,
    loading
  };
};

export const setIndicatorsError = (periodId: number, error: string) => {
  return {
    type: actionTypes.SET_INDICATOR_RESULTS_ERROR,
    periodId,
    error
  };
};
