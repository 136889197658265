import * as React from 'react';

import { Indicator } from '../../../../types';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const KeltnerChannelForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label htmlFor="kc_ema_periods" className="col-sm-6 control-label">
          EMA Periods (d:20)
        </label>
        <div className="col-sm-6">
          <input
            id="kc_ema_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.periods}
            onChange={(event) => update({ periods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="kc_atr_periods" className="col-sm-6 control-label">
          ATR Periods (d:10)
        </label>
        <div className="col-sm-6">
          <input
            id="kc_atr_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.atrPeriods}
            onChange={(event) => update({ atrPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="kc_upper" className="col-sm-6 control-label">
          Upper Threshold
        </label>
        <div className="col-sm-6">
          <input
            id="kc_upper"
            type="number"
            step="1"
            className="form-control"
            value={indicator.upperThreshold}
            onChange={(event) => update({ upperThreshold: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="kc_lower" className="col-sm-6 control-label">
          Lower Threshold
        </label>
        <div className="col-sm-6">
          <input
            id="kc_lower"
            type="number"
            step="1"
            className="form-control"
            value={indicator.lowerThreshold}
            onChange={(event) => update({ lowerThreshold: Number(event.target.value) })}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default KeltnerChannelForm;
