import { createStore, applyMiddleware, compose } from 'redux';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { accountReducer } from '../modules/account/store/account-reducer';
import { AccountState } from '../modules/account/store/account-state';
import { authReducer } from '../modules/auth/store/auth-reducer';
import { AuthState } from '../modules/auth/store/auth-state';
import { backtestPositionReducer } from '../modules/backtest/store/backtest-position/reducer';
import { BacktestPositionMapState } from '../modules/backtest/store/backtest-position/state';
import { backtestSummaryReducer } from '../modules/backtest/store/backtest-summary/reducer';
import { BacktestSummaryMapState } from '../modules/backtest/store/backtest-summary/state';
import { backtestReducer } from '../modules/backtest/store/backtest/reducer';
import { BacktestMapState } from '../modules/backtest/store/backtest/state';
import { subscriptionSummaryReducer } from '../modules/backtest/store/subscription-summary/reducer';
import { SubscriptionSummaryState } from '../modules/backtest/store/subscription-summary/state';
import { contractPartialReducer } from '../modules/contract-partial/store/partial-map/reducer';
import { ContractPartialMapState } from '../modules/contract-partial/store/partial-map/state';
import { partialQuoteReducer } from '../modules/contract-partial/store/partial-quote/partial-quote-reducer';
import { PartialQuoteState } from '../modules/contract-partial/store/partial-quote/partial-quote-state';
import { contractReducer } from '../modules/contract/store/contract-reducer';
import { ContractState } from '../modules/contract/store/contract-state';
import { graphConfigReducer } from '../modules/graph/store/graph-config/reducer';
import { GraphConfigMapState } from '../modules/graph/store/graph-config/state';
import { graphDataReducer } from '../modules/graph/store/graph-data/reducer';
import { GraphDataMapState } from '../modules/graph/store/graph-data/state';
import { graphTileReducer } from '../modules/graph/store/graph-tile/reducer';
import { GraphTileState } from '../modules/graph/store/graph-tile/state';
import { graphReducer } from '../modules/graph/store/graph/reducer';
import { GraphState } from '../modules/graph/store/graph/state';
import { indicatorResultReducer } from '../modules/indicator-result/store/reducer';
import { IndicatorResultMapState } from '../modules/indicator-result/store/state';
import { indicatorReducer } from '../modules/indicator/store/reducer';
import { IndicatorMapState } from '../modules/indicator/store/state';
import { periodListReducer } from '../modules/period/store/period-list/reducer';
import { PeriodListState } from '../modules/period/store/period-list/state';
import { periodRangeReducer } from '../modules/period/store/period-range/reducer';
import { PeriodRangeState } from '../modules/period/store/period-range/state';
import { periodReducer } from '../modules/period/store/period/reducer';
import { PeriodMapState } from '../modules/period/store/period/state';
import { historyReducer } from '../modules/position/store/history/history-reducer';
import { HistoryState } from '../modules/position/store/history/history-state';
import { openOrderReducer } from '../modules/position/store/open-order/reducer';
import { OpenOrderState } from '../modules/position/store/open-order/state';
import { openPositionReducer } from '../modules/position/store/open-position/reducer';
import { OpenPositionState } from '../modules/position/store/open-position/state';
import { pnlMonthReducer } from '../modules/position/store/pnl-month/reducer';
import { PnlMonthState } from '../modules/position/store/pnl-month/state';
import { pnlStrategyReducer } from '../modules/position/store/pnl-strategy/reducer';
import { PnlStrategyMapState } from '../modules/position/store/pnl-strategy/state';
import { positionOrderReducer } from '../modules/position/store/position-order/reducer';
import { PositionOrderMapState } from '../modules/position/store/position-order/state';
import { positionSelectedReducer } from '../modules/position/store/position-selected/reducer';
import { PositionSelectedMapState } from '../modules/position/store/position-selected/state';
import { positionTradeReducer } from '../modules/position/store/position-trades/reducer';
import { PositionTradeMapState } from '../modules/position/store/position-trades/state';
import { securityReducer } from '../modules/security/store/security-reducer';
import { SecurityState } from '../modules/security/store/security-state';
import { layoutReducer } from '../modules/shared/store/layout/layout-reducer';
import { LayoutState } from '../modules/shared/store/layout/layout-state';
import { accountInfoReducer } from '../modules/status/store/account-info/reducer';
import { AccountInfoState } from '../modules/status/store/account-info/state';
import { inboxReducer } from '../modules/status/store/inbox/inbox-reducer';
import { InboxState } from '../modules/status/store/inbox/inbox-state';
import { scheduleStatusReducer } from '../modules/status/store/schedule-status/reducer';
import { ScheduleStatusState } from '../modules/status/store/schedule-status/state';
import { strategyGroupReducer } from '../modules/strategy-group/store/strategy-group-reducer';
import { StrategyGroupState } from '../modules/strategy-group/store/strategy-group-state';
import { strategyReducer } from '../modules/strategy/store/reducer';
import { StrategyState } from '../modules/strategy/store/state';
import { subscriptionReducer } from '../modules/subscription/store/reducer';
import { SubscriptionState } from '../modules/subscription/store/state';

export interface GlobalState {
  contractState: ContractState;
  subscriptionState: SubscriptionState;
  securityState: SecurityState;
  strategyState: StrategyState;
  strategyGroupState: StrategyGroupState;
  accountState: AccountState;
  inboxState: InboxState;
  accountInfoState: AccountInfoState;
  backtestMapState: BacktestMapState;
  backtestPositionMapState: BacktestPositionMapState;
  backtestSummaryMapState: BacktestSummaryMapState;
  subscriptionSummaryState: SubscriptionSummaryState;
  contractPartialMapState: ContractPartialMapState;
  partialQuoteState: PartialQuoteState;
  openPositionState: OpenPositionState;
  pnlMonthState: PnlMonthState;
  pnlStrategyMapState: PnlStrategyMapState;
  historyState: HistoryState;
  layoutState: LayoutState;
  authState: AuthState;
  graphDataMapState: GraphDataMapState;
  graphConfigMapState: GraphConfigMapState;
  graphTileState: GraphTileState;
  graphState: GraphState;
  indicatorMapState: IndicatorMapState;
  periodListState: PeriodListState;
  periodRangeState: PeriodRangeState;
  periodMapState: PeriodMapState;
  indicatorResultMapState: IndicatorResultMapState;
  scheduleStatusState: ScheduleStatusState;
  openOrderState: OpenOrderState;
  positionSelectedMapState: PositionSelectedMapState;
  positionOrderMapState: PositionOrderMapState;
  positionTradeMapState: PositionTradeMapState;
}

export const rootReducer = combineReducers<GlobalState>({
  contractState: contractReducer,
  securityState: securityReducer,
  strategyState: strategyReducer,
  strategyGroupState: strategyGroupReducer,
  accountState: accountReducer,
  inboxState: inboxReducer,
  accountInfoState: accountInfoReducer,
  backtestMapState: backtestReducer,
  backtestPositionMapState: backtestPositionReducer,
  backtestSummaryMapState: backtestSummaryReducer,
  subscriptionSummaryState: subscriptionSummaryReducer,
  contractPartialMapState: contractPartialReducer,
  partialQuoteState: partialQuoteReducer,
  openPositionState: openPositionReducer,
  pnlMonthState: pnlMonthReducer,
  pnlStrategyMapState: pnlStrategyReducer,
  historyState: historyReducer,
  layoutState: layoutReducer,
  subscriptionState: subscriptionReducer,
  authState: authReducer,
  graphDataMapState: graphDataReducer,
  graphConfigMapState: graphConfigReducer,
  graphTileState: graphTileReducer,
  graphState: graphReducer,
  indicatorMapState: indicatorReducer,
  periodListState: periodListReducer,
  periodRangeState: periodRangeReducer,
  periodMapState: periodReducer,
  indicatorResultMapState: indicatorResultReducer,
  scheduleStatusState: scheduleStatusReducer,
  openOrderState: openOrderReducer,
  positionSelectedMapState: positionSelectedReducer,
  positionOrderMapState: positionOrderReducer,
  positionTradeMapState: positionTradeReducer
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

// store.subscribe(() => {
//   console.log('[Subscription]', store.getState());
// });
// store.dispatch({ type: "REMOVE_EXCHANGE", value: 10122 });

export default store;
