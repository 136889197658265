import { AccountInfo } from '../../../../types';

export interface AccountInfoState {
  loaded: boolean;
  error: boolean;
  accountInfo: AccountInfo;
}

export const initalAccountInfoState: AccountInfoState = {
  loaded: false,
  error: false,
  accountInfo: {} as AccountInfo
};
