import * as React from 'react';
import { useState } from 'react';

import { Modal, Button } from 'react-bootstrap';

export interface Props {
  show: boolean;
  title?: string;
  propName?: string;
  cancel(): void;
  create(name: string): void;
}

export const CreateDialog = ({ show, title, propName, cancel, create }: Props) => {
  const [value, setValue] = useState('');

  return (
    <Modal show={show} bsSize="small" onHide={cancel} aria-labelledby="contained-modal-title">
      {title && (
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title">{title}</Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body>
        <form name="createForm" className="form-horizontal" onSubmit={() => create(value)}>
          <div className="form-group form-group-sm">
            <label htmlFor="create_prop" className="col-sm-4 control-label">
              {propName || 'Name'}
            </label>
            <div className="col-sm-8">
              <input
                id="create_prop"
                type="text"
                className="form-control"
                value={value}
                onChange={(event) => setValue(event.target.value)}
              />
            </div>
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          bsStyle="primary"
          onClick={() => {
            create(value);
          }}
        >
          Create
        </Button>
        <Button onClick={cancel}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
};
