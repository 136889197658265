import { Map } from 'immutable';

import { Backtest } from '../../../../types';

export interface BacktestState {
  loading: boolean;
  loaded: boolean;
  error?: string;
  backtests: Backtest[];
}

export const initalBacktestState: BacktestState = {
  loading: false,
  loaded: false,
  error: undefined,
  backtests: []
};

export type BacktestMapState = Map<string, BacktestState>;

export const initalBacktestMapState: BacktestMapState = Map();
