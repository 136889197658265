import React from 'react';

import Switch from 'react-switch';

import { DayOfWeek, Indicator } from '../../../../types';
import { DayOfWeekInfoItem, dayOfWeekList } from '../../../shared/utils/time-utils';

const INITIAL_WEEK_DAY_SELECTION = '0-0-0-0-0-0-0';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const TradingWeekdaysForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  const specificTradeDays = indicator.textValue || INITIAL_WEEK_DAY_SELECTION;
  const tradingDays = specificTradeDays.split('-');

  const selectDayOfWeek = (dayOfWeek: DayOfWeek, checked: boolean) => {
    tradingDays[dayOfWeek] = checked ? '1' : '0';
    const result = `${tradingDays[0]}-${tradingDays[1]}-${tradingDays[2]}-${tradingDays[3]}-${tradingDays[4]}-${tradingDays[5]}-${tradingDays[6]}`;
    update({ textValue: result });
  };

  const days = dayOfWeekList.map((item: DayOfWeekInfoItem) => (
    <tr key={item.key}>
      <td className="small middle">
        <span>{item.name}</span>
      </td>
      <td width="100px" className="small middle text-center">
        <label className="react-switch react-switch--sm">
          <Switch onChange={(checked: boolean) => selectDayOfWeek(item.key, checked)} checked={tradingDays[item.key] === '1'} />
        </label>
      </td>
    </tr>
  ));

  return (
    <div className="form-group form-group-sm">
      <div className="col-sm-offset-2 col-sm-8 col-sm-offset-2">
        <table className="table table-hover table-bordered">
          <tbody>{days}</tbody>
        </table>
      </div>
    </div>
  );
};

export default TradingWeekdaysForm;
