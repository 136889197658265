import { AnyAction } from 'redux';

import { Security } from '../../../types';

import * as actionTypes from './action-types';
import { initalSecurityState } from './security-state';

export const securityReducer = (state = initalSecurityState, action: AnyAction) => {
  const getSecurityIndex = (id: number): number => {
    const item = state.securities.find((x) => x.id === id);
    return item ? state.securities.indexOf(item) : -1;
  };

  if (action.type === actionTypes.ADD_SECURITY) {
    const securities = [...state.securities];
    securities.push(action.payload);
    return {
      ...state,
      securities
    };
  } else if (action.type === actionTypes.UPDATE_SECURITY) {
    const security = action.payload as Security;
    const index = getSecurityIndex(security.id);
    const securities = [...state.securities];
    securities[index] = security;
    return {
      ...state,
      securities
    };
  } else if (action.type === actionTypes.REMOVE_SECURITY) {
    const id = action.value as number;
    const index = getSecurityIndex(id);
    const securities = [...state.securities];
    securities.splice(index, 1);
    return {
      ...state,
      securities
    };
  } else if (action.type === actionTypes.SET_SECURITIES) {
    return {
      ...state,
      loaded: true,
      securities: action.payload
    };
  } else if (action.type === actionTypes.SET_SECURITY_ERROR) {
    return {
      ...state,
      loaded: true,
      error: true,
      errorMessage: action.value
    };
  } else if (action.type === actionTypes.CLEAR_SECURITY_ERROR) {
    return {
      ...state,
      error: false,
      errorMessage: undefined
    };
  } else if (action.type === actionTypes.UI_SHOW_ARCHIVED) {
    const ui = { ...state.ui };
    ui.showArchived = action.value;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_SET_PRODUCT_TYPES) {
    const ui = { ...state.ui };
    ui.selectedProductTypes = action.payload;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_COLLAPSE_OPTION_PANEL) {
    const ui = { ...state.ui };
    ui.optionPanelCollapsed = action.value;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_COLLAPSE_PICKER_PANEL) {
    const ui = { ...state.ui };
    ui.pickerPanelCollapsed = action.value;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_SECURITY_PICKED) {
    const ui = { ...state.ui };
    ui.securityPicked = action.payload ? { ...action.payload } : undefined;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_SELECT_TAB) {
    const ui = { ...state.ui };
    ui.selectedTab = action.value;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_SET_SECURITY_OPTIONS) {
    const ui = {
      ...state.ui,
      securityOptions: action.payload
    };
    return {
      ...state,
      ui
    };
  }

  return state;
};
