import * as React from 'react';
import { useEffect, useState } from 'react';

import { VictoryLine } from 'victory';

import { BarSize, GraphQuote } from '../../../../types';
import { GraphConfigState } from '../../store/graph-config/state';

import BaseGraph from './BaseGraph';
import { ChartLine, mapToRsi, mapToRsiMa, mapToWpr } from './graph-utils';

export interface OscillatorGraphProps {
  graphConfigState: GraphConfigState;
  barSize: BarSize;
  graphData: GraphQuote[];
  expanded?: boolean;
}

const OscillatorGraph = ({ graphConfigState, barSize, graphData, expanded }: OscillatorGraphProps) => {
  const [rsiData, setRsiData] = useState<ChartLine[]>([]);
  const [rsiMaData, setRsiMaData] = useState<ChartLine[]>([]);
  const [wprData, setWprData] = useState<ChartLine[]>([]);

  useEffect(() => {
    if (graphData && graphConfigState.showRsi) {
      setRsiData(mapToRsi(graphData));
    }
  }, [graphData, graphConfigState.showRsi]);

  useEffect(() => {
    if (graphData && graphConfigState.showRsiMa) {
      setRsiMaData(mapToRsiMa(graphData));
    }
  }, [graphData, graphConfigState.showRsiMa]);

  useEffect(() => {
    if (graphData && graphConfigState.showWpr) {
      setWprData(mapToWpr(graphData));
    }
  }, [graphData, graphConfigState.showWpr]);

  const showOcillators = graphConfigState.showRsi || graphConfigState.showRsiMa || graphConfigState.showWpr;
  if (!showOcillators) {
    return null;
  }

  const strokeWidth = expanded ? 0.5 : 1;

  const rsiChartLine = graphConfigState.showRsi ? (
    <VictoryLine
      style={{
        data: { stroke: '#ff0033', strokeWidth }
      }}
      data={rsiData}
    />
  ) : undefined;

  const rsiMaChartLine = graphConfigState.showRsiMa ? (
    <VictoryLine
      style={{
        data: { stroke: '#ffccff', strokeWidth }
      }}
      data={rsiMaData}
    />
  ) : undefined;

  const wprChartLine = graphConfigState.showWpr ? (
    <VictoryLine
      style={{
        data: { stroke: '#0066cc', strokeWidth }
      }}
      data={wprData}
    />
  ) : undefined;

  let title: string | undefined = undefined;
  if ((graphConfigState.showRsi || graphConfigState.showRsiMa) && graphConfigState.showWpr) {
    title = 'RSI & % Range';
  } else if (graphConfigState.showRsi || graphConfigState.showRsiMa) {
    title = 'RSI';
  } else if (graphConfigState.showWpr) {
    title = '% Range';
  }

  return (
    <BaseGraph barSize={barSize} title={title} height={150} min={0} max={100} expanded={expanded}>
      {rsiMaChartLine}
      {rsiChartLine}
      {wprChartLine}
    </BaseGraph>
  );
};

export default OscillatorGraph;
