const LOCAL_STORAGE_TOKEN = 'no.manubia.token';

const originalSetItem = localStorage.setItem;

class TokenEvent extends Event {
  token: string;
  constructor(token: string) {
    super('TokenEvent');
    this.token = token;
  }
}

Storage.prototype.setItem = function (key, value) {
  if (key === LOCAL_STORAGE_TOKEN) {
    document.dispatchEvent(new TokenEvent(value));
  }
  originalSetItem.apply(this, [key, value]);
};

export const onTokenEvent = (callback: (token: string) => void) => {
  const eventListener = (event: Event) => {
    const tokenEvent = event as TokenEvent;
    return callback(tokenEvent.token);
  };
  document.addEventListener('TokenEvent', eventListener, false);
};
