import * as React from 'react';

import { BarSize, ContractPartialQuote } from '../../../../types';
import CollapsePanel from '../../../shared/components/CollapsePanel';
import { barSizes, getBarSizeName } from '../../../shared/utils/bar-utils';
import { setThousenSeparator } from '../../../shared/utils/number-utils';
import { sortContractPartialQuotes } from '../../utils';

export interface QuotePanelProps {
  partialQuotes: ContractPartialQuote[];
  selectedBarSize: BarSize | undefined;
  setSelectedBarSize(barSize: BarSize): void;
  collectPartialQuotes(barSize: BarSize | undefined): void;
  showGraphHandler(barSize: BarSize): void;
  updatePartialQuote(quote: ContractPartialQuote): void;
  removePartialQuote(quote: ContractPartialQuote): void;
}

const QuotePanel = ({
  partialQuotes,
  selectedBarSize,
  setSelectedBarSize,
  collectPartialQuotes,
  showGraphHandler,
  updatePartialQuote,
  removePartialQuote
}: QuotePanelProps) => {
  const quoteBarSizes = barSizes.filter((x) => x.key >= BarSize._5_MIN && x.key <= BarSize._1_DAY);

  const header = (
    <tr>
      <th className="middle small text-center">Bar Size</th>
      <th className="middle small text-center">Quotes</th>
      <th className="middle small text-center">Update</th>
      <th className="middle small text-center">Graph</th>
      <th className="middle small text-center">Remove</th>
    </tr>
  );

  const rows = partialQuotes.sort(sortContractPartialQuotes).map((x) => (
    <tr key={`${x.contractPartialId}${x.barSize}`}>
      <td className="middle small">
        <span className="label label-success gap-left-5">{getBarSizeName(x.barSize)}</span>
      </td>
      <td className="middle small text-right">{setThousenSeparator(x.numQuotes.toString())}</td>
      <td className="middle small text-center" style={{ width: '70px' }}>
        <button className="btn btn-default btn-sm" onClick={() => updatePartialQuote(x)}>
          <i className="fa fa-refresh text-success"></i>
        </button>
      </td>
      <td className="middle small text-center" style={{ width: '70px' }}>
        <button className="btn btn-success btn-sm" onClick={() => showGraphHandler(x.barSize)}>
          <i className="fa fa-line-chart"></i>
        </button>
      </td>
      <td className="middle small text-center" style={{ width: '70px' }}>
        <button className="btn btn-default btn-sm" onClick={() => removePartialQuote(x)}>
          <i className="fa fa-trash danger-text2"></i>
        </button>
      </td>
    </tr>
  ));

  const quotesTable = (
    <div className="table-responsive">
      <table className="table table-bordered table-condensed">
        <thead>{header}</thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );

  return (
    <CollapsePanel title="Quotes">
      <div className="form-group form-group-sm">
        <label htmlFor="input_gen_atr_ticks" className="col-xs-12 col-sm-4 control-label">
          Bar Size
        </label>
        <div className="col-xs-8 col-sm-5">
          <select
            id="input_gen_atr_ticks_barsize"
            className="form-control"
            value={selectedBarSize}
            onChange={(event) => setSelectedBarSize(Number(event.target.value))}
          >
            <option value="">Velg..</option>
            {quoteBarSizes.map((x) => (
              <option key={x.key} value={x.key}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-xs-4 col-sm-3">
          <button className="btn btn-warning btn-sm btn-block" type="button" onClick={() => collectPartialQuotes(selectedBarSize)}>
            Collect
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">{quotesTable}</div>
      </div>
    </CollapsePanel>
  );
};

export default QuotePanel;
