import { AnyAction } from 'redux';

import * as actionTypes from './action-types';
import { initalLayoutState } from './layout-state';

export const layoutReducer = (state = initalLayoutState, action: AnyAction) => {
  // if (action.type === actionTypes.UI_COLLAPSE_PANEL_ACTIVE_CONTRACTS) {
  //   const ui = { ...state.ui };
  //   ui.activeContractsCollapsed = action.value;
  //   return {
  //     ...state,
  //     ui
  //   };
  // }
  if (action.type === actionTypes.UI_COLLAPSE_PANEL_ACTIVE_STRATEGIES) {
    const ui = { ...state.ui };
    ui.activeStrategiesCollapsed = action.value;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_COLLAPSE_PANEL_PERIODS) {
    const ui = { ...state.ui };
    ui.periodsCollapsed = action.value;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_COLLAPSE_BROWSE_INTRADAY_PANEL) {
    const ui = { ...state.ui };
    ui.browseIntradayCollapsed = action.value;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_COLLAPSE_PANEL_BACKTEST_SUMMARIES) {
    const ui = { ...state.ui };
    ui.backtestSummariesPanelCollapsed = action.value;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_COLLAPSE_PANEL_CREATE_INDICATOR) {
    const ui = { ...state.ui };
    ui.createIndicatorPanelCollapsed = action.value;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_COLLAPSE_PANEL_OPEN_POSITIONS) {
    const ui = { ...state.ui };
    ui.openPositionsCollapsed = action.value;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_SET_SHOW_RACE_VIEW) {
    const ui = { ...state.ui };
    ui.showRaceView = action.value;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_COLLAPSE_PANEL_CLOSED_POSITIONS) {
    const ui = { ...state.ui };
    ui.closedPositionsCollapsed = action.value;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_COLLAPSE_PANEL_CLOSED_POSITIONS_FILTER) {
    const ui = { ...state.ui };
    ui.closedPositionsFilterCollapsed = action.value;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_CONTRACT_PARTIAL_TAB) {
    const ui = { ...state.ui };
    ui.contractPartialTabSelected = action.value;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_SET_POS_TYPE_OPTIONS) {
    const ui = {
      ...state.ui,
      positionTypeOptions: action.payload
    };
    return {
      ...state,
      ui
    };
  }

  return state;
};
