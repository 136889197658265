import { RiskType } from '../../../types';

export const getAmountTypeLabel = (riskType: RiskType) => {
  switch (riskType) {
    case RiskType.Currency:
      return '$';
    case RiskType.AvgTrueRange:
      return 'MLT';
    default:
      return 'TKS';
  }
};
