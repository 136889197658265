import { ContractPartial } from '../../../../types';

import * as actionTypes from './action-types';

export const setPartialPanelCollapsed = (collapse: boolean) => {
  return {
    type: actionTypes.SET_PARTIAL_PANEL_COLLAPSED,
    value: collapse
  };
};

export const setSelectedPartial = (partial: ContractPartial | undefined) => {
  return {
    type: actionTypes.SET_SELECTED_PARTIAL,
    value: partial
  };
};
