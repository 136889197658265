import { showNetworkError } from '../../../shared/utils/growl-utils';

import { setBacktestSummaries, setBacktestSummariesError, setBacktestSummariesLoading } from './action-creators';
import { BacktestSummaryHttpRequest } from './http-request';

export const loadBacktestSummariesAsync = (securityId: number) => {
  return (dispatch) => {
    // reset loading
    dispatch(setBacktestSummariesLoading(securityId, true));

    BacktestSummaryHttpRequest.getSummaries(securityId)
      .then((response) => {
        dispatch(setBacktestSummaries(securityId, response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load backtest-summaries');
        dispatch(setBacktestSummariesError(securityId, msg));
      });
  };
};
