import React, { FunctionComponent } from 'react';

import classNames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { periodModulePath } from '../../period/period-routes';
import { positionModulePath } from '../../position/pos-routes';

const QuickNavBar: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const homeModulePath = '/';

  const goToHomeHandler = () => {
    history.push(homeModulePath);
  };

  const goToPeriodsHandler = () => {
    history.push(periodModulePath);
  };

  const goToPositionsHandler = () => {
    history.push(positionModulePath);
  };

  const isActive = (path) => {
    const currPath = history.location.pathname;
    return path === currPath;
  };

  return (
    <div className="row gap-bottom-30">
      <div className="col-offset-sm-4 col-sm-4 col-sm-offset-4">
        <div className="btn-group btn-group-justified" role="group">
          <div className="btn-group btn-group-sm" role="group">
            <button
              type="button"
              className={classNames('btn', 'btn-default', 'ripple', { active: isActive(homeModulePath) })}
              onClick={goToHomeHandler}
            >
              Home
            </button>
          </div>
          <div className="btn-group btn-group-sm" role="group">
            <button
              type="button"
              className={classNames('btn', 'btn-default', 'ripple', { active: isActive(periodModulePath) })}
              onClick={goToPeriodsHandler}
            >
              Periods
            </button>
          </div>
          <div className="btn-group btn-group-sm" role="group">
            <button
              type="button"
              className={classNames('btn', 'btn-default', 'ripple', { active: isActive(positionModulePath) })}
              onClick={goToPositionsHandler}
            >
              Positions
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(QuickNavBar);
