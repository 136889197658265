import { Account } from '../../../types';
import axiosApi from '../../shared/axios-api';

export class AccountHttpRequest {
  public static get() {
    return axiosApi.get<Account>('/account/identity');
  }

  public static getAccountList() {
    return axiosApi.get<Account[]>('/account/list');
  }

  public static update(account: Account) {
    return axiosApi.put(`/account/${account.id}`, account);
  }
}
