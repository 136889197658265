import * as React from 'react';

import { Switch, Route } from 'react-router-dom';

import ContractListStrategyScreen from './components/SubscriptionListScreen';
import SubscriptionScreen from './components/SubscriptionScreen';

export const subscriptionModulePath = '/subscriptions';

const render = () => {
  return (
    <Switch>
      {/* <Route exact path={contractModulePath + '/security/:securityId?'} component={ContractListScreen}></Route> */}
      <Route exact path={subscriptionModulePath} component={ContractListStrategyScreen}></Route>
      <Route exact path={subscriptionModulePath + '/:id'} component={SubscriptionScreen}></Route>
    </Switch>
  );
};

export default {
  routeProps: {
    path: subscriptionModulePath,
    render
  },
  name: 'Subscription'
};
