import * as React from 'react';

import { Switch, Route } from 'react-router-dom';

import SecurityListScreen from './components/SecurityListScreen';
import SecurityScreen from './components/SecurityScreen';

export const securityModulePath = '/securities';

const render = () => {
  return (
    <Switch>
      <Route exact path={securityModulePath} component={SecurityListScreen}></Route>
      <Route exact path={securityModulePath + '/:id'} component={SecurityScreen}></Route>
    </Switch>
  );
};

export default {
  routeProps: {
    path: securityModulePath,
    render
  },
  name: 'Security'
};
