import React, { FunctionComponent, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Strategy } from '../../../../types';
import IndicatorPanel from '../../../indicator/components/IndicatorPanel';
import CollapsePanel from '../../../shared/components/CollapsePanel';
import { updateStrategyAsync } from '../../store/services';

interface Props {
  strategy: Strategy;
}

const IndicatorTab: FunctionComponent<Props> = ({ strategy }: Props) => {
  const [notes, setNotes] = useState(strategy.notes || '');

  const dispatch = useDispatch();

  const updateNotesHandler = (notes: string) => {
    const item = {
      ...strategy,
      notes
    } as Strategy;
    dispatch(updateStrategyAsync(item));
  };

  return (
    <div>
      <IndicatorPanel strategy={strategy} />
      <CollapsePanel title="Notes" collapsed={!notes}>
        <div className="form-group form-group-sm">
          <textarea id="sec_notes" className="form-control" rows={4} value={notes} onChange={(event) => setNotes(event.target.value)} />
        </div>
        <div className="text-right">
          <button className="btn btn-sm btn-primary" type="button" onClick={() => updateNotesHandler(notes)}>
            Save
          </button>
        </div>
      </CollapsePanel>
    </div>
  );
};

export default IndicatorTab;
