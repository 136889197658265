import { AnyAction } from 'redux';

import { OpenOrderAction } from './action-creators';
import * as actionTypes from './action-types';
import { initalOpenOrderState } from './state';

export const openOrderReducer = (state = initalOpenOrderState, action: AnyAction | OpenOrderAction) => {
  if (action.type === actionTypes.SET_OPEN_ORDERS_LOADING) {
    return {
      ...state,
      loaded: false,
      error: undefined
    };
  } else if (action.type === actionTypes.SET_OPEN_ORDERS) {
    return {
      ...state,
      loaded: true,
      error: undefined,
      openOrders: action.openOrders
    };
  } else if (action.type === actionTypes.SET_OPEN_ORDERS_ERROR) {
    return {
      ...state,
      loaded: true,
      error: action.error,
      openOrders: []
    };
  } else if (action.type === actionTypes.UI_COLLAPSE_PANEL_OPEN_ORDERS) {
    const ui = { ...state.ui };
    ui.openOrdersCollapsed = action.openOrdersCollapsed;
    return {
      ...state,
      ui
    };
  }

  return state;
};
