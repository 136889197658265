import { Backtest, BacktestStatus } from '../../../../types';
import { showNetworkError } from '../../../shared/utils/growl-utils';
import { setBacktestPositions } from '../backtest-position/action-creators';
import { updateBacktest } from '../backtest/action-creators';

import { BacktestManagerHttpRequest } from './http-request';

export const runBacktestAsync = (backtest: Backtest, securityId: number, callback: Function | undefined = undefined) => {
  return (dispatch) => {
    const promise = BacktestManagerHttpRequest.run(backtest);
    if (!promise) {
      return;
    }
    promise
      .then((response) => {
        const backtestResult = response.data.backtest;
        dispatch(updateBacktest(securityId, backtest.strategyId, backtestResult));
        dispatch(setBacktestPositions(backtestResult.id, response.data.positions));
      })
      .catch((error) => {
        showNetworkError(error);
        backtest.status = BacktestStatus.Failed;
        backtest.statusMessage = error.toString();
        dispatch(updateBacktest(securityId, backtest.strategyId, backtest));
      })
      .finally(() => {
        if (callback) {
          callback();
        }
      });
  };
};
