import { Indicator, IndicatorResult } from '../../../types';
import { getIndicatorOptionName } from '../../indicator/components/IndicatorOptionsPanel/indicator-options';

type CombinedType = Indicator | IndicatorResult;

const sortByEnabled = (a: Indicator, b: Indicator) => {
  if (a.enabled && !b.enabled) return -1;
  if (!a.enabled && b.enabled) return 1;
  return 0;
};

const sortByBarSize = (a: CombinedType, b: CombinedType) => {
  if (a.barSize > b.barSize) return -1;
  if (a.barSize < b.barSize) return 1;
  return 0;
};

const sortByAction = (a: CombinedType, b: CombinedType) => {
  if (a.action > b.action) return 1;
  if (a.action < b.action) return -1;
  return 0;
};

const sortByNames = (a: CombinedType, b: CombinedType) => {
  const aName = getIndicatorOptionName(a.type);
  const bName = getIndicatorOptionName(b.type);
  if (aName > bName) return 1;
  if (aName < bName) return -1;
  return 0;
};

const sortByDirection = (a: CombinedType, b: CombinedType) => {
  if (a.direction > b.direction) return 1;
  if (a.direction < b.direction) return -1;
  return 0;
};

export const sortByDirectionThenByNamesThenByActionThenByBarSize = (a: CombinedType, b: CombinedType) => {
  let sort = sortByDirection(a, b);
  if (sort !== 0) {
    return sort;
  }
  sort = sortByNames(a, b);
  if (sort !== 0) {
    return sort;
  }
  sort = sortByAction(a, b);
  if (sort !== 0) {
    return sort;
  }
  return sortByBarSize(a, b);
};

export const sortByDirectionThenByActionThenByBarSizeThenByNames = (a: CombinedType, b: CombinedType) => {
  let sort = sortByDirection(a, b);
  if (sort !== 0) {
    return sort;
  }
  sort = sortByAction(a, b);
  if (sort !== 0) {
    return sort;
  }
  sort = sortByBarSize(a, b);
  if (sort !== 0) {
    return sort;
  }
  return sortByNames(a, b);
};

export const sortByEnabledThenByActionThenByBarSizeThenByNames = (a: Indicator, b: Indicator) => {
  let sort = sortByEnabled(a, b);
  if (sort !== 0) {
    return sort;
  }
  sort = sortByAction(a, b);
  if (sort !== 0) {
    return sort;
  }
  sort = sortByBarSize(a, b);
  if (sort !== 0) {
    return sort;
  }
  return sortByNames(a, b);
};
