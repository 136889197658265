import { AnyAction } from 'redux';

import { Period } from '../../../../types';

import * as actionTypes from './action-types';
import { initalPeriodListState } from './state';

export const periodListReducer = (state = initalPeriodListState, action: AnyAction) => {
  if (action.type === actionTypes.SET_PERIOD_LIST) {
    const periods: Period[] = action.payload ? action.payload : [];
    return {
      ...state,
      periods,
      loading: false,
      loaded: true,
      error: undefined
    };
  } else if (action.type === actionTypes.SET_PERIOD_LIST_LOADING) {
    return {
      ...state,
      loading: action.value,
      loaded: action.value === false
    };
  } else if (action.type === actionTypes.SET_PERIOD_LIST_ERROR) {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: action.value
    };
  } else if (action.type === actionTypes.CLEAR_PERIOD_LIST_ERROR) {
    return {
      ...state,
      error: undefined
    };
  } else if (action.type === actionTypes.UI_SET_MINUTES_ADJUSTED) {
    const ui = { ...state.ui };
    ui.minutesAdjusted = action.value;
    return {
      ...state,
      ui
    };
  } else if (action.type === actionTypes.UI_SET_MINUTES_APART) {
    const ui = { ...state.ui };
    ui.minutesApart = action.payload ? { ...action.payload } : undefined;
    return {
      ...state,
      ui
    };
  }

  return state;
};
