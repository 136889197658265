import { AnyAction } from 'redux';

import { Period } from '../../../../types';

import * as actionTypes from './action-types';
import { initalPeriodMapState, initalPeriodState } from './state';

export const periodReducer = (state = initalPeriodMapState, action: AnyAction) => {
  const key = action.periodId;
  const current = state.get(key) || { ...initalPeriodState };

  if (action.type === actionTypes.SET_PERIOD_LOADING) {
    const loading = action.loading === true;
    return state.set(key, {
      ...current,
      loading,
      error: undefined
    });
  } else if (action.type === actionTypes.SET_PERIOD_ERROR) {
    return state.set(key, {
      ...current,
      loading: false,
      error: action.error
    });
  } else if (action.type === actionTypes.SET_PERIOD) {
    const payload: Period = action.payload;
    const period = {
      ...payload,
      contract: { ...payload.contract },
      security: { ...payload.security },
      strategy: { ...payload.strategy },
      position: payload.position ? { ...payload.position } : undefined
    } as Period;
    return state.set(period.id, {
      period,
      loading: false,
      error: undefined
    });
  }

  return state;
};
