import { BacktestSummary } from '../../../../types';

import * as actionTypes from './action-types';

export interface SubscriptionSummaryAction {
  type: string;
  summaries?: BacktestSummary[];
  error?: string;
  loading?: boolean;
}

export const setSubscriptionSummaries = (summaries: BacktestSummary[]): SubscriptionSummaryAction => {
  return {
    type: actionTypes.SET_SUBSCRIPTION_SUM_LIST,
    summaries
  };
};

export const setSubscriptionSummariesError = (error: string): SubscriptionSummaryAction => {
  return {
    type: actionTypes.SET_SUBSCRIPTION_SUM_ERROR,
    error
  };
};

export const setSubscriptionSummariesLoading = (loading: boolean): SubscriptionSummaryAction => {
  return {
    type: actionTypes.SET_SUBSCRIPTION_SUM_LOADING,
    loading
  };
};
