import { Map } from 'immutable';

import { GlobalState } from '../../../../core/root';
import { BarSize, GraphSettings } from '../../../../types';

export interface GraphConfigState extends GraphSettings {
  showVolumeGraph: boolean;
}

export const initialGraphConfigState: GraphConfigState = {
  showTransactions: true,
  showSma: false,
  smaPeriods: 10,
  showSma2nd: false,
  sma2ndPeriods: 20,
  showSma3rd: false,
  sma3rdPeriods: 30,
  showVolumeGraph: false,
  showVolumeAvg: false,
  volumeAvgPeriods: 20,
  showBollingerBands: false,
  bbPeriods: 20,
  bbStandardDeviation: 2,
  showKeltnerChannels: false,
  kcPeriods: 15,
  kcAtrMultiplier: 1.5,
  showRsi: false,
  rsiPeriods: 14,
  showRsiMa: false,
  rsiMaPeriods: 20,
  showWpr: false,
  wprPeriods: 14,
  showTrueRange: false,
  showAtr: false,
  atrPeriods: 14,
  showAtrMa: false,
  atrMaPeriods: 20,
  showAdx: false,
  adxBasisPeriods: 14,
  adxPeriods: 14,
  showAroon: false,
  aroonPeriods: 25,
  showHeikinAshi: false,
  showPeaks: false,
  peakPeriods: 10,
  showDonchianChannels: false,
  dcPeriods: 20,
  showDcEntry: false,
  dcResistanceSupportPeriods: 5,
  dcLimitMultiplier: 1,
  dcUseClosePrice: false,
  showResistanceSupport: false,
  resistanceSupportRange: 0.3,
  showConnectedBars: false,
  showWilliamsFractal: false,
  williamsFractal: 2
};

export type GraphConfigMapState = Map<BarSize, GraphConfigState>;

export const initialGraphConfigMapState: GraphConfigMapState = Map();

export const getGraphConfigState = (barSize: BarSize, state: GlobalState): GraphConfigState | undefined => {
  const config = state.graphConfigMapState.get(barSize);
  return config || initialGraphConfigState;
};
