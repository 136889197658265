import * as React from 'react';
import { SyntheticEvent, useState } from 'react';

import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';

import { Position, PeriodType, Forecast } from '../../../types';
import GraphDialog from '../../graph/components/GraphDialog';
import * as constants from '../../shared/constants/broker-accounts';
import { formatUsd } from '../../shared/utils/currency-utils';
import { toMonthTime } from '../../shared/utils/date-format-utils';
import { getDuration } from '../../shared/utils/time-utils';
import { positionModulePath } from '../pos-routes';

export interface Props extends RouteComponentProps {
  positions: Position[];
  showRows: boolean;
  showSummary: boolean;
}

const ClosedPositionTable: React.FunctionComponent<Props> = ({ positions, showRows, showSummary, history }: Props) => {
  const [showGraphDialog, setShowGraphDialog] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState<Position>();

  const toggleShowGraphDialog = () => {
    setShowGraphDialog(!showGraphDialog);
  };

  const showGraphHandler = (e: SyntheticEvent, position: Position) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setSelectedPosition(position);
    toggleShowGraphDialog();
  };

  const rowClickHandler = (position: Position) => {
    const route = `${positionModulePath}/${position.id}`;
    history.push(route);
  };

  const getSumGrossPnl = () => {
    if (positions.length == 0) {
      return 0;
    }
    const sum = positions
      .map((x) => x.sumGrossPnl)
      .reduce((total: number, pnl: number) => {
        return total + pnl;
      });
    return formatUsd(sum, 0);
  };

  const getSumNetPnl = () => {
    if (positions.length == 0) {
      return 0;
    }
    const sum = positions
      .map((x) => x.sumNetPnl)
      .reduce((total: number, pnl: number) => {
        return total + pnl;
      });
    return sum;
  };

  const getSumCommission = () => {
    if (positions.length == 0) {
      return 0;
    }
    const sum = positions
      .map((x) => x.sumCommission)
      .reduce((total: number, pnl: number) => {
        return total + pnl;
      });
    return formatUsd(sum, 1);
  };

  const getSumSpreadCost = () => {
    if (positions.length == 0) {
      return 0;
    }
    const sum = positions
      .map((x) => x.sumSlippageAmount)
      .reduce((total: number, pnl: number) => {
        return total + pnl;
      });
    return formatUsd(sum, 0);
  };

  const header = (
    <tr>
      <th className="middle small">Security</th>
      {showRows && positions.length > 1 && (
        <td className="middle small" style={{ width: '30px' }}>
          #
        </td>
      )}
      <td className="middle small">Contract</td>
      <td className="middle small">Strategy</td>
      <td className="middle small text-center">Type</td>
      <td className="middle small text-center">Forecast</td>
      <td className="middle small text-center">Size</td>
      <td className="middle small">Created</td>
      <td className="middle small">Exited</td>
      <td className="middle small text-right">Dur.</td>

      <td className="middle small text-right no-wrap">Gross PnL</td>
      <td className="middle small text-right">Slipp.</td>
      <td className="middle small text-right">Comm.</td>
      <td className="middle small text-right no-wrap">Net PnL</td>
      <td className="middle small text-center">MSL</td>
      <td className="middle small text-center"></td>
      <td className="middle small" style={{ minWidth: '150px' }}>
        Exit Reason
      </td>
    </tr>
  );

  const rows = positions.map((position: Position, index: number) => {
    let account: JSX.Element | undefined;
    if (position.type === PeriodType.Live && position.account === constants.LIVE_ACCOUNT) {
      account = <span className="success-text2">Live</span>;
    }
    if (position.type === PeriodType.Live && constants.PAPER_TRADING_ACCOUNT.find((a) => a === position.account) !== undefined) {
      account = <span>Paper</span>;
    } else {
      account = <span>{PeriodType[position.type]}</span>;
    }

    const isPaper = position.type === PeriodType.Live && constants.PAPER_TRADING_ACCOUNT.find((a) => a === position.account) !== undefined;

    const stopHand = position.stopLossReached ? (
      <i
        className={classNames('fa', 'fa-hand-paper-o', 'gap-right-5', {
          'danger-text2': position.sumNetPnl < 0,
          'success-text': position.sumNetPnl > 0
        })}
        aria-hidden="true"
        title="Stop Loss"
      ></i>
    ) : undefined;

    const throphy = position.limitReached ? (
      <i
        className={classNames('fa', 'fa-trophy', {
          gold: !position.stopLossReached,
          grey: position.stopLossReached
        })}
        aria-hidden="true"
        title="Limit"
      ></i>
    ) : undefined;

    let exitStatus: JSX.Element | undefined;
    if (stopHand || throphy) {
      exitStatus = (
        <span className="gap-right-5">
          {stopHand}
          {throphy}
        </span>
      );
    }

    const stopLossMoves = Number(position.stopLossMoves) > 0 ? <span className="small info-text">{position.stopLossMoves}</span> : '';

    return (
      <tr key={position.id} className="clickable" onClick={() => rowClickHandler(position)}>
        <th className="middle small">{position.security?.name}</th>
        {showRows && positions.length > 1 && (
          <td className="middle small">
            <i>{index + 1}</i>
          </td>
        )}
        <td className="middle small">{position.contract?.localSymbol}</td>
        <td className="middle small">{position.strategy?.name}</td>
        <td className={classNames('middle', 'small', 'text-center', { 'paper-text': isPaper })}>{account}</td>

        <td className="middle small text-center">{Forecast[position.forecast]}</td>
        <td className="middle small text-center">{position.orderSize}</td>
        <td className="middle small">{toMonthTime(position.created)}</td>
        <td className="middle small">{toMonthTime(position.exited)}</td>
        <td className="middle small text-right">{getDuration(position.created, position.exited)}</td>
        <td className="middle small text-right vborder">
          <span>{formatUsd(position.sumGrossPnl, 0)}</span>
        </td>
        <td className="middle small text-right">
          <span>
            {formatUsd(position.sumSlippageAmount, 0)} ({position.sumSlippageTicks})
          </span>
        </td>
        <td className="middle small text-right">
          <span>{formatUsd(position.sumCommission, 1)}</span>
        </td>
        <td className="middle small text-right">
          <span
            className={classNames({
              'success-text': position.sumNetPnl > 0,
              'danger-text2': position.sumNetPnl < 0
            })}
          >
            {formatUsd(position.sumNetPnl, 0)}
          </span>
        </td>
        <td className="middle small text-center">{stopLossMoves}</td>
        <td className="middle small text-center">
          <button className="btn btn-warning btn-xs" onClick={(event: SyntheticEvent) => showGraphHandler(event, position)}>
            <span className="fa fa-line-chart fa-xs"></span>
          </button>
        </td>
        <td className="middle small vborder">
          {exitStatus}
          {position.exitReason}
        </td>
      </tr>
    );
  });

  const sumNetPnl = getSumNetPnl();
  const footer = (
    <tr>
      <th className="middle small">Sum:</th>
      {showRows && positions.length > 1 && <td className="middle small"></td>}
      <td className="middle small"></td>
      <td className="middle small"></td>
      <td className="middle small"></td>
      <td className="middle small text-center"></td>
      <td className="middle small text-center"></td>
      <td className="middle small"></td>
      <td className="middle small"></td>
      <td className="middle small text-right"></td>

      <td className="middle small text-right">{getSumGrossPnl()}</td>
      <td className="middle small text-right">{getSumSpreadCost()}</td>
      <td className="middle small text-right">{getSumCommission()}</td>
      <td className="middle small text-right">
        <span
          className={classNames({
            'success-text': sumNetPnl > 0,
            'danger-text2': sumNetPnl < 0
          })}
        >
          {formatUsd(sumNetPnl, 0)}
        </span>
      </td>
      <td className="middle small"></td>
      <td className="middle small"></td>
      <td className="middle small" style={{ minWidth: '150px' }}></td>
    </tr>
  );

  const table = (
    <table className="table table-striped table-hover table-condensed">
      <thead>{header}</thead>
      <tbody>
        {showRows && rows}
        {showSummary && footer}
      </tbody>
    </table>
  );

  return (
    <div>
      <div>
        <div className="scrolling outer">
          <div className="inner">{table}</div>
        </div>
      </div>

      {showGraphDialog && selectedPosition && (
        <GraphDialog position={selectedPosition} show={showGraphDialog} toggle={toggleShowGraphDialog} />
      )}
    </div>
  );
};

export default withRouter(ClosedPositionTable);
