import { BarSize, GraphQuote } from '../../../../types';
import { toDateMonth, toTime } from '../../../shared/utils/date-format-utils';
import { convertToDate } from '../../../shared/utils/date-utils';

export const padding = { top: 10, right: 20, bottom: 40, left: 50 };

export const formatAxisDate = (barSize: BarSize, t: Date) => {
  return barSize < BarSize._8_HRS ? toTime(t) : toDateMonth(t);
};

export const barColors = {
  positive: '#008966', // '#00bc8c',    // "#5f5c5b",
  negative: '#e32636' // 'salmon'      // '#E74C3C'   //"#c43a31"
};

export const connectedBarColors = {
  positive: '#5d8aa8',
  negative: '#efdecd'
};

export const lineColors = {
  volumeAvg: '#0066cc'
};

export interface CandleStick {
  x: Date;
  open: number;
  close: number;
  high: number;
  low: number;
}

export interface ScatterPoint {
  x: Date;
  y: number;
  size: number;
  fill: string;
}

export interface ChartLine {
  x: Date;
  y: number;
}

export interface VolumeBar {
  x: Date;
  y: number;
  direction: number;
}

export const mapToCandleStickData = (graphData: GraphQuote[], showHeikinAshi: boolean, showConnectedBars: boolean) => {
  if (showHeikinAshi) {
    return graphData.map((y) => {
      return {
        x: convertToDate(y.closeTime),
        open: y.haOpenPrice,
        close: y.haClosePrice,
        high: y.haHighPrice,
        low: y.haLowPrice
      } as CandleStick;
    });
  } else if (showConnectedBars) {
    return graphData.map((y) => {
      return {
        x: convertToDate(y.closeTime),
        open: y.conOpenPrice,
        close: y.conClosePrice,
        high: y.highPrice,
        low: y.lowPrice
      } as CandleStick;
    });
  } else {
    return graphData.map((y) => {
      return {
        //x: convertToDate(y.openTime),
        x: convertToDate(y.closeTime),
        open: y.openPrice,
        close: y.closePrice,
        high: y.highPrice,
        low: y.lowPrice
      } as CandleStick;
    });
  }
};

export const mapToVolumeData = (graphData: GraphQuote[]) => {
  return graphData.map((g) => {
    return {
      x: convertToDate(g.closeTime),
      y: g.volume,
      direction: g.closePrice > g.openPrice ? 1 : g.closePrice < g.openPrice ? -1 : 0
    } as VolumeBar;
  });
};

export const mapToTransactionData = (graphData: GraphQuote[]) => {
  const fillColors = {
    long: '#00cc00', // '#90ee90'
    short: '#800080', // 'gold',
    exit: 'red'
  };

  const longEntries = graphData
    .filter((z) => !!z.longEntryPrice)
    .map((z) => {
      return {
        x: convertToDate(z.openTime),
        y: z.longEntryPrice,
        size: 3,
        fill: fillColors.long
      } as ScatterPoint;
    });

  const shortEntries = graphData
    .filter((z) => !!z.shortEntryPrice)
    .map((z) => {
      return {
        x: convertToDate(z.openTime),
        y: z.shortEntryPrice,
        size: 3,
        fill: fillColors.short
      } as ScatterPoint;
    });

  const exits = graphData
    .filter((z) => !!z.exitPrice)
    .map((z) => {
      return {
        x: convertToDate(z.closeTime),
        y: z.exitPrice,
        size: 4,
        fill: fillColors.exit
      } as ScatterPoint;
    });

  return exits.concat(longEntries).concat(shortEntries);
};

export const mapToResistanceSupportData = (graphData: GraphQuote[]) => {
  const fillColors = {
    resistance: '#bcd4e6',
    support: '#e97451'
  };

  const resistance = graphData
    .filter((z) => !!z.hasResistance)
    .map((z) => {
      return {
        x: convertToDate(z.closeTime),
        y: z.highPrice,
        size: 2,
        fill: fillColors.resistance
      } as ScatterPoint;
    });

  const support = graphData
    .filter((z) => !!z.hasSupport)
    .map((z) => {
      return {
        x: convertToDate(z.closeTime),
        y: z.lowPrice,
        size: 2,
        fill: fillColors.support
      } as ScatterPoint;
    });

  return resistance.concat(support);
};

export const mapToSmaLine = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.sma)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.sma
      } as ChartLine;
    });
};

export const mapToSma2ndLine = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.sma2nd)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.sma2nd
      } as ChartLine;
    });
};

export const mapToSma3rdLine = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.sma3rd)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.sma3rd
      } as ChartLine;
    });
};

export const mapToBbHigh = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.bbHigh)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.bbHigh
      } as ChartLine;
    });
};

export const mapToBbLow = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.bbLow)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.bbLow
      } as ChartLine;
    });
};

export const mapToKcHigh = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.kcHigh)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.kcHigh
      } as ChartLine;
    });
};

export const mapToKcLow = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.kcLow)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.kcLow
      } as ChartLine;
    });
};

export const mapToDcHigh = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.dcHigh)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.dcHigh
      } as ChartLine;
    });
};

export const mapToDcLow = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.dcLow)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.dcLow
      } as ChartLine;
    });
};

export const mapToDcMid = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.dcMid)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.dcMid
      } as ChartLine;
    });
};

export const mapToPeakData = (graphData: GraphQuote[]) => {
  const fillColors = {
    high: '#ffc04d',
    highNoise: '#b37400',
    low: '#ffed4d',
    lowNoise: '#b3a000'
  };

  const highPeaks = graphData
    .filter((z) => !!z.peakHigh)
    .map((z) => {
      return {
        x: convertToDate(z.closeTime),
        y: z.peakHigh,
        size: 3,
        fill: z.peakHighNoise ? fillColors.highNoise : fillColors.high
      } as ScatterPoint;
    });

  const lowPeaks = graphData
    .filter((z) => !!z.peakLow)
    .map((z) => {
      return {
        x: convertToDate(z.closeTime),
        y: z.peakLow,
        size: 3,
        fill: z.peakLowNoise ? fillColors.lowNoise : fillColors.low
      } as ScatterPoint;
    });

  return highPeaks.concat(lowPeaks);
};

export const mapToFractalData = (graphData: GraphQuote[]) => {
  const fillColors = {
    bull: '#ace1af',
    bear: '#de3163'
  };

  const fractalBullish = graphData
    .filter((z) => !!z.williamsFractalBull)
    .map((z) => {
      return {
        x: convertToDate(z.closeTime),
        y: z.williamsFractalBull,
        size: 5,
        fill: fillColors.bull
      } as ScatterPoint;
    });

  const fractalBearish = graphData
    .filter((z) => !!z.williamsFractalBear)
    .map((z) => {
      return {
        x: convertToDate(z.closeTime),
        y: z.williamsFractalBear,
        size: 5,
        fill: fillColors.bear
      } as ScatterPoint;
    });

  return fractalBullish.concat(fractalBearish);
};

export const mapToDcEntryData = (graphData: GraphQuote[]) => {
  const fillColors = {
    long: '#fff',
    short: '#fff',
    limit: '#ffbf00',
    stop: '#a67b5b'
  };

  const longEntries = graphData
    .filter((z) => !!z.dcLongEntry)
    .map((z) => {
      return {
        x: convertToDate(z.closeTime),
        y: z.closePrice,
        size: 3,
        fill: fillColors.long
      } as ScatterPoint;
    });

  const shortEntries = graphData
    .filter((z) => !!z.dcShortEntry)
    .map((z) => {
      return {
        x: convertToDate(z.closeTime),
        y: z.closePrice,
        size: 3,
        fill: fillColors.short
      } as ScatterPoint;
    });

  const limitExits = graphData
    .filter((z) => !!z.dcLimit)
    .map((z) => {
      return {
        x: convertToDate(z.closeTime),
        y: z.dcLimit,
        size: 3,
        fill: fillColors.limit
      } as ScatterPoint;
    });

  const stopExits = graphData
    .filter((z) => !!z.dcStop)
    .map((z) => {
      return {
        x: convertToDate(z.closeTime),
        y: z.dcStop,
        size: 3,
        fill: fillColors.stop
      } as ScatterPoint;
    });

  return longEntries.concat(shortEntries).concat(limitExits).concat(stopExits);
};

export const mapToVolumeAvg = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.volumeAvg)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.volumeAvg
      } as ChartLine;
    });
};

export const mapToRsi = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.rsi)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.rsi
      } as ChartLine;
    });
};

export const mapToRsiMa = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.rsiMa)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.rsiMa
      } as ChartLine;
    });
};

export const mapToWpr = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.wpr)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.wpr
      } as ChartLine;
    });
};

export const mapToTrueRange = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.trueRange)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.trueRange
      } as ChartLine;
    });
};

export const mapToAtr = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.atr)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.atr
      } as ChartLine;
    });
};

export const mapToAtrMa = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.atrMa)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.atrMa
      } as ChartLine;
    });
};

export const mapToAroonUp = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.aroonUp)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.aroonUp
      } as ChartLine;
    });
};

export const mapToAroonDown = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.aroonDown)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.aroonDown
      } as ChartLine;
    });
};

export const mapToAdx = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.adx)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.adx
      } as ChartLine;
    });
};

export const mapToPlusDx = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.plusDx)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.plusDx
      } as ChartLine;
    });
};

export const mapToMinusDx = (graphData: GraphQuote[]) => {
  return graphData
    .filter((x) => !!x.minusDx)
    .map((y) => {
      return {
        x: convertToDate(y.closeTime),
        y: y.minusDx
      } as ChartLine;
    });
};
