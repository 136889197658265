/*** ASYNC WITH THUNK ***/

import { ContractPartial } from '../../../../types';
import { showNetworkError } from '../../../shared/utils/growl-utils';

import {
  addContractPartial,
  addContractPartials,
  removeContractPartial,
  setContractPartialError,
  setContractPartials,
  updateContractPartial
} from './action-creators';
import { ContractPartialHttpRequest } from './http-request';

export const loadContractPartialsAsync = (securityId: number) => {
  return (dispatch) => {
    ContractPartialHttpRequest.get(securityId)
      .then((response) => {
        // console.log(response);
        dispatch(setContractPartials(securityId, response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load contract-partials');
        dispatch(setContractPartialError(securityId, msg));
      });
  };
};

export const addContractPartialAsync = (securityId: number, partial: ContractPartial) => {
  return (dispatch) => {
    ContractPartialHttpRequest.insert(partial)
      .then((response) => {
        dispatch(addContractPartial(securityId, response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setContractPartialError(securityId, msg));
      });
  };
};

export const updateContractPartialAsync = (securityId: number, partial: ContractPartial) => {
  return (dispatch) => {
    ContractPartialHttpRequest.update(partial)
      .then(() => {
        dispatch(updateContractPartial(securityId, partial));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setContractPartialError(securityId, msg));
      });
  };
};

export const removeContractPartialAsync = (securityId: number, partial: ContractPartial) => {
  return (dispatch) => {
    ContractPartialHttpRequest.remove(partial.id)
      .then(() => {
        dispatch(removeContractPartial(securityId, partial));
      })
      .catch((error) => {
        const msg = showNetworkError(error);
        dispatch(setContractPartialError(securityId, msg));
      });
  };
};

export const generateContractPartialsAsync = (securityId: number) => {
  return (dispatch) => {
    ContractPartialHttpRequest.generate(securityId)
      .then((response) => {
        dispatch(addContractPartials(securityId, response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to generate contract-partials');
        dispatch(setContractPartialError(securityId, msg));
      });
  };
};
