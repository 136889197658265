import * as React from 'react';
import { useState } from 'react';

import DatePicker from '@christianleypoldt/react-bootstrap-date-picker';
import { Modal, Button } from 'react-bootstrap';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import TimePicker from 'react-time-picker';

import { Position } from '../../../../types';
import { toISOString, toTime } from '../../../shared/utils/date-format-utils';
import { trimTimePartFromDate } from '../../../shared/utils/date-picker-utils';
import { convertToDate } from '../../../shared/utils/date-utils';
import { updatePositionSelectedAsync } from '../../store/position-selected/service';

export interface UpdatePositionDialogProps {
  show: boolean;
  hide(): void;
  position: Position;
}

export const UpdatePositionDialog = ({ show, hide, position }: UpdatePositionDialogProps) => {
  const dispatch = useDispatch();

  const [positionState, setPositionState] = useState(position);
  const intialTime = position.exited ? toTime(position.exited) : undefined;
  const [exitedTime, setExitedTime] = useState<string | undefined>(intialTime);

  const copyPosition = (): Position => {
    return {
      ...positionState,
      contract: { ...positionState.contract },
      backtest: { ...positionState.backtest },
      security: { ...positionState.security },
      strategy: { ...positionState.strategy }
    } as Position;
  };

  const updatePositionState = (obj: object) => {
    const field = Object.keys(obj)[0];
    const pos = {
      ...copyPosition(),
      [field]: obj[field]
    };
    setPositionState(pos);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateExitedDateHandler = (value, formattedValue) => {
    const dt = trimTimePartFromDate(value);
    updatePositionState({ exited: dt });
  };

  const updateExitedTimeHandler = (ts: string) => {
    setExitedTime(ts);
  };

  const setExitedNow = () => {
    const now = new Date();
    const dt = trimTimePartFromDate(now);
    updatePositionState({ exited: dt });
    setExitedTime(toTime(now));
  };

  const updateHandler = () => {
    const pos = copyPosition();
    if (pos.exited) {
      const dt = convertToDate(pos.exited);
      const tmString = exitedTime || toTime(new Date());
      const tm = tmString.split(':');

      // TODO: quick-fix of offset
      const n = dt.getTimezoneOffset() / 60;
      dt.setHours(Number(tm[0]) - Number(n));

      dt.setMinutes(Number(tm[1]));
      dt.setSeconds(0);
      dt.setMilliseconds(0);
      pos.exited = dt;
    }
    dispatch(updatePositionSelectedAsync(position.id, pos));
    hide();
  };

  const exitedDateTooltip = <Tooltip id="exitedDateTooltip">{positionState.exited}</Tooltip>;

  const intitalDt = positionState.exited
    ? typeof positionState.exited === 'string'
      ? positionState.exited
      : toISOString(positionState.exited)
    : undefined;

  const dtFormField = (
    <div className="form-group form-group-sm">
      <label htmlFor="exited_date" className="col-xs-12 col-sm-5 control-label">
        <OverlayTrigger placement="top" overlay={exitedDateTooltip}>
          <span>Exited Date</span>
        </OverlayTrigger>
      </label>
      <div className="col-xs-8 col-sm-5">
        <div className="datepicker-fix">
          <DatePicker
            id="exited_date"
            required
            weekStartsOn={1}
            value={intitalDt}
            placeholder="Exited Date"
            dateFormat="DD.MM.YYYY"
            onChange={updateExitedDateHandler}
          />
        </div>
      </div>
      <div className="col-xs-4 col-sm-2">
        <button type="button" className="btn btn-default btn-sm btn-block" onClick={setExitedNow}>
          <i className="glyphicon glyphicon-time"></i>
        </button>
      </div>
    </div>
  );

  const tmFormField = (
    <div className="form-group form-group-sm">
      <label htmlFor="enter_before" className="col-sm-5 control-label">
        Exited Time
      </label>
      <div className="col-sm-7">
        <TimePicker id="enter_before" onChange={updateExitedTimeHandler} value={exitedTime} className="custom_timepicker" locale="nb-NO" />
      </div>
    </div>
  );

  const sizeFormField = (
    <div className="form-group form-group-sm">
      <label htmlFor="pos_size" className="col-xs-12 col-sm-5 control-label">
        Position Size
      </label>
      <div className="col-sm-7">
        <input
          id="pos_size"
          type="number"
          step="1"
          className="form-control"
          value={positionState.size}
          onChange={(event) => updatePositionState({ size: Number(event.target.value) })}
        />
      </div>
    </div>
  );

  const exitReasonField = (
    <div className="form-group form-group-sm">
      <label htmlFor="pos_exit_reason" className="col-xs-12 col-sm-5 control-label">
        Exit Reason
      </label>
      <div className="col-sm-7">
        <input
          id="pos_exit_reason"
          type="text"
          className="form-control"
          value={positionState.exitReason}
          onChange={(event) => updatePositionState({ exitReason: event.target.value })}
        />
      </div>
    </div>
  );

  const grossPnlFormField = (
    <div className="form-group form-group-sm">
      <label htmlFor="gross_pnl" className="col-xs-12 col-sm-5 control-label">
        Gross PnL
      </label>
      <div className="col-sm-7">
        <input
          id="gross_pnl"
          type="number"
          step="any"
          className="form-control"
          value={positionState.sumGrossPnl}
          onChange={(event) => updatePositionState({ sumGrossPnl: Number(event.target.value) })}
        />
      </div>
    </div>
  );

  const commFormField = (
    <div className="form-group form-group-sm">
      <label htmlFor="pos_comm" className="col-xs-12 col-sm-5 control-label">
        Commission
      </label>
      <div className="col-sm-7">
        <input
          id="pos_comm"
          type="number"
          step="any"
          className="form-control"
          value={positionState.sumCommission}
          onChange={(event) => updatePositionState({ sumCommission: Number(event.target.value) })}
        />
      </div>
    </div>
  );

  const info = position.manuallyUpdated ? (
    <div className="form-group form-group-sm">
      <div className="col-sm-offset-5 col-sm-7">
        <div className="alert alert-warning small" role="alert">
          Manually updated!
        </div>
      </div>
    </div>
  ) : undefined;

  return (
    <Modal show={show} onHide={hide} aria-labelledby="contained-modal-title">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title">Edit Position</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="row form-horizontal">
          <div className="col-xs-12">
            {sizeFormField}
            {exitReasonField}
            {dtFormField}
            {tmFormField}
            {grossPnlFormField}
            {commFormField}
            {info}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button bsStyle="primary" onClick={updateHandler}>
          Update
        </Button>
        <Button onClick={hide}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
};
