import * as React from 'react';

import { Switch, Route } from 'react-router-dom';

import Login from './components/Login';
import Logout from './components/Logout';

export const authModulePath = '/auth';

const render = () => {
  return (
    <Switch>
      <Route exact path={authModulePath + '/login'} component={Login}></Route>
      <Route exact path={authModulePath + '/logout'} component={Logout}></Route>
    </Switch>
  );
};

export default {
  routeProps: {
    path: authModulePath,
    render
  },
  name: 'Auth'
};
