import { Map } from 'immutable';

import { GlobalState } from '../../../../core/root';
import { GraphQuote } from '../../../../types';

export interface GraphDataState {
  loaded: boolean;
  error?: string;
  data: GraphQuote[];
}

export const initialGraphDataState: GraphDataState = {
  loaded: false,
  error: undefined,
  data: []
};

export type GraphDataMapState = Map<string, GraphDataState>;

export const initialGraphDataMapState: GraphDataMapState = Map();

export const getGraphDataState = (state: GlobalState, guid: string): GraphDataState | undefined => {
  return state.graphDataMapState.get(guid);
};

export const getError = (state: GlobalState, guid: string) => {
  return getGraphDataState(state, guid)?.error;
};

export const getLoaded = (state: GlobalState, guid: string) => {
  return getGraphDataState(state, guid)?.loaded;
};

export const getData = (state: GlobalState, guid: string) => {
  return getGraphDataState(state, guid)?.data;
};
