import { Position, PositionRequest } from '../../../../types';
import { showNetworkError } from '../../../shared/utils/growl-utils';
import { PositionTypeOption } from '../../../shared/utils/position-type-option';

import * as actionTypes from './action-types';
import { PnlMonthHttpRequest } from './http-request';

export const clearPnlList = (key: number) => {
  return {
    type: actionTypes.CLEAR_PNL_LIST,
    value: key
  };
};

export const setPnlList = (key: string, positions: Position[]) => {
  return {
    type: actionTypes.SET_PNL_LIST,
    payload: {
      key,
      positions
    }
  };
};

export const setPnlListLoading = (key: string, loading: boolean) => {
  return {
    type: actionTypes.SET_PNL_LIST_LOADING,
    payload: {
      key,
      loading
    }
  };
};

export const setPnlListError = (key: string, error: string) => {
  return {
    type: actionTypes.SET_PNL_LIST_ERROR,
    payload: {
      key,
      error
    }
  };
};

export const setSelectedYear = (year: number) => {
  return {
    type: actionTypes.UI_SET_PNL_SELECTED_YEAR,
    value: year
  };
};

export const setSelectedMonth = (month: number) => {
  return {
    type: actionTypes.UI_SET_PNL_SELECTED_MONTH,
    value: month
  };
};

export const setShowPositionsInPnl = (showPositionsInPnl: boolean) => {
  return {
    type: actionTypes.UI_SET_PNL_SHOW_POSITIONS,
    value: showPositionsInPnl
  };
};

export const setPositionTypeOptions = (positionTypeOptions: PositionTypeOption[]) => {
  return {
    type: actionTypes.UI_SET_PNL_POS_TYPE_OPTIONS,
    payload: positionTypeOptions
  };
};

/*** ASYNC WITH THUNK ***/

export const fetchPnlListAsync = (key: string, posRequest: PositionRequest) => {
  return (dispatch) => {
    dispatch(setPnlListLoading(key, true));
    PnlMonthHttpRequest.getHistory(posRequest)
      .then((response) => {
        dispatch(setPnlList(key, response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load PnL list');
        dispatch(setPnlListError(key, msg));
      });
  };
};
