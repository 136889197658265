import * as React from 'react';
import { useEffect, useState } from 'react';

import { VictoryLine } from 'victory';

import { BarSize, GraphQuote } from '../../../../types';

import BaseGraph from './BaseGraph';
import { ChartLine, mapToAdx, mapToMinusDx, mapToPlusDx } from './graph-utils';

export interface AverageDirectionalIndexGraphProps {
  showAdx: boolean;
  barSize: BarSize;
  graphData: GraphQuote[];
  expanded?: boolean;
}

const AverageDirectionalIndexGraph = ({ showAdx, barSize, graphData, expanded }: AverageDirectionalIndexGraphProps) => {
  const [adxData, setAdxData] = useState<ChartLine[]>([]);
  const [plusDxData, setPlusDxData] = useState<ChartLine[]>([]);
  const [minusDxData, setMinusDxData] = useState<ChartLine[]>([]);

  useEffect(() => {
    if (graphData && showAdx) {
      setAdxData(mapToAdx(graphData));
      setPlusDxData(mapToPlusDx(graphData));
      setMinusDxData(mapToMinusDx(graphData));
    }
  }, [graphData, showAdx]);

  if (!showAdx) {
    return null;
  }

  const strokeWidth = expanded ? 0.5 : 1;

  return (
    <BaseGraph barSize={barSize} title="Average Directinal Index" height={125} expanded={expanded}>
      <VictoryLine
        style={{
          data: { stroke: '#ff0033', strokeWidth }
        }}
        data={minusDxData}
      />
      <VictoryLine
        style={{
          data: { stroke: '#00bc8c', strokeWidth }
        }}
        data={plusDxData}
      />
      <VictoryLine
        style={{
          data: { stroke: '#0066cc', strokeWidth }
        }}
        data={adxData}
      />
    </BaseGraph>
  );
};

export default AverageDirectionalIndexGraph;
