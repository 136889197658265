import { AnyAction } from 'redux';

import { Account } from '../../../types';

import { initalAccountState } from './account-state';
import { AccountAction } from './action-creators';
import * as actionTypes from './action-types';

export const accountReducer = (state = initalAccountState, action: AccountAction | AnyAction) => {
  if (action.type === actionTypes.UPDATE_ACCOUNT) {
    const account = action.account as Account;
    return {
      ...state,
      account
    };
  } else if (action.type === actionTypes.SET_ACCOUNT) {
    const account = action.account as Account;
    return {
      ...state,
      loaded: true,
      account
    };
  } else if (action.type === actionTypes.SET_ACCOUNT_ERROR) {
    return {
      ...state,
      loaded: true,
      error: true,
      errorMessage: action.value
    };
  } else if (action.type === actionTypes.RESET_ACCOUNT) {
    return initalAccountState;
  } else if (action.type === actionTypes.CLEAR_ACCOUNT_ERROR) {
    return {
      ...state,
      error: false,
      errorMessage: undefined
    };
  } else if (action.type === actionTypes.SET_ACCOUNT_LIST) {
    return {
      ...state,
      accountListLoaded: true,
      accountListError: undefined,
      accountList: action.accounts
    };
  } else if (action.type === actionTypes.SET_ACCOUNT_LIST_ERROR) {
    return {
      ...state,
      accountListLoaded: true,
      accountListError: action.value,
      accountList: []
    };
  } else if (action.type === actionTypes.UI_SET_SELECTED_ACCOUNTID) {
    const ui = { ...state.ui };
    ui.selectedAccountId = Number(action.value);
    return {
      ...state,
      ui
    };
  }

  return state;
};
