import { Map } from 'immutable';

import { Position } from '../../../../types';

export interface BacktestPositionState {
  loading: boolean;
  loaded: boolean;
  error?: string;
  positions: Position[];
}

export const initialBacktestPositionState: BacktestPositionState = {
  loading: false,
  loaded: false,
  error: undefined,
  positions: []
};

export type BacktestPositionMapState = Map<number, BacktestPositionState>;

export const initialBacktestPositionMapState: BacktestPositionMapState = Map();
