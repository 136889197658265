import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import DatePicker from '@christianleypoldt/react-bootstrap-date-picker';
import { useSelector, useDispatch } from 'react-redux';

import { GlobalState } from '../../../core/root';
import * as actionCreators from '../store/layout/action-creators';
import { trimTimePartFromDate } from '../utils/date-picker-utils';
import { convertToDate } from '../utils/date-utils';

import CollapsePanel from './CollapsePanel';

export interface IntradayNavProps {
  title?: string;
  dateFormat?: string;
  intraday?: Date;
  minDate?: Date;
  maxDate?: Date;
  setIntraday(day?: Date): void;
}

const IntradayNav = ({ dateFormat, intraday, minDate, maxDate, setIntraday }: IntradayNavProps) => {
  // title = title || "Options";
  dateFormat = dateFormat || 'DD.MM.YYYY';

  const [dateString, setDateString] = useState<string | undefined>(undefined);
  const [minDt, setMinDt] = useState<Date | undefined>(undefined);
  const [minDateString, setMinDateString] = useState<string | undefined>(undefined);
  const [maxDt, setMaxDt] = useState<Date | undefined>(undefined);
  const [maxDateString, setMaxDateString] = useState<string | undefined>(undefined);

  const browseIntradayCollapsed: boolean = useSelector((state: GlobalState) => state.layoutState.ui.browseIntradayCollapsed);

  const dispatch = useDispatch();

  const toggleCollapse = () => {
    dispatch(actionCreators.setBrowseIntradayCollapsed(!browseIntradayCollapsed));
  };

  const updateCurrentDay = (dt: Date | undefined) => {
    if (!dt) {
      setDateString(undefined);
      setIntraday(undefined);
    } else {
      const d = trimTimePartFromDate(dt) as string;
      const day = new Date(d);
      setIntraday(day);
    }
  };

  const goToNextHandler = () => {
    const dt = convertToDate(dateString);
    const next = dt.setDate(dt.getDate() + 1);
    updateCurrentDay(new Date(next));
  };

  const goToPreviousHandler = () => {
    const dt = convertToDate(dateString);
    const prev = dt.setDate(dt.getDate() - 1);
    updateCurrentDay(new Date(prev));
  };

  useEffect(() => {
    const d = trimTimePartFromDate(intraday);
    setDateString(d);
  }, [intraday]);

  useEffect(() => {
    const d = trimTimePartFromDate(minDate);
    setMinDateString(d);
    setMinDt(convertToDate(d));
  }, [minDate]);

  useEffect(() => {
    const dt = convertToDate(maxDate);
    setMaxDt(dt);
    const d = trimTimePartFromDate(dt);
    setMaxDateString(d);
  }, [maxDate]);

  let title = 'Browse';
  if (dateString) {
    title += ' ' + new Date(dateString).toLocaleDateString('nb-NO');
  }

  return (
    <CollapsePanel title={title} collapsed={browseIntradayCollapsed} onToggle={toggleCollapse}>
      <div className="row form-horizontal">
        <div className="col-sm-6">
          <div className="form-group form-group-sm">
            <label htmlFor="selected_dt" className="col-sm-4 control-label">
              <span>Select Date</span>
            </label>
            <div className="col-sm-8">
              <div className="datepicker-fix">
                <DatePicker
                  id="selected_dt"
                  required
                  weekStartsOn={1}
                  value={dateString}
                  minDate={minDateString}
                  maxDate={maxDateString}
                  placeholder="Date"
                  dateFormat={dateFormat}
                  onChange={updateCurrentDay}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="btn-group btn-group-justified" role="group">
            <div className="btn-group btn-group-sm" role="group">
              <button
                type="button"
                className="btn btn-default"
                onClick={goToPreviousHandler}
                disabled={!intraday || (minDt && minDt >= intraday)}
              >
                <span className="glyphicon glyphicon glyphicon-triangle-left" aria-hidden="true"></span>
              </button>
            </div>
            <div className="btn-group btn-group-sm" role="group">
              <button
                type="button"
                className="btn btn-default"
                onClick={goToNextHandler}
                disabled={!intraday || (maxDt && maxDt <= intraday)}
              >
                <span className="glyphicon glyphicon glyphicon-triangle-right" aria-hidden="true"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </CollapsePanel>
  );
};

export default IntradayNav;
