export const ADD_SECURITY = 'ADD_SECURITY';
export const UPDATE_SECURITY = 'UPDATE_SECURITY';
export const REMOVE_SECURITY = 'REMOVE_SECURITY';
export const SET_SECURITIES = 'SET_SECURITIES';
export const SET_SECURITY_ERROR = 'SET_SECURITY_ERROR';
export const CLEAR_SECURITY_ERROR = 'CLEAR_SECURITY_ERROR';
export const UI_SET_PRODUCT_TYPES = 'UI_SET_PRODUCT_TYPES';
export const UI_SHOW_ARCHIVED = 'UI_SECURITY_SHOW_ARCHIVED';
export const UI_COLLAPSE_OPTION_PANEL = 'UI_SECURITY_COLLAPSE_OPTION_PANEL';
export const UI_COLLAPSE_PICKER_PANEL = 'UI_SECURITY_COLLAPSE_PICKER_PANEL';
export const UI_SECURITY_PICKED = 'UI_SECURITY_PICKED';
export const UI_SELECT_TAB = 'UI_SECURITY_SELECT_TAB';
export const UI_SET_SECURITY_OPTIONS = 'UI_SET_SECURITY_OPTIONS';
