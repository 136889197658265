import { Strategy } from '../../../types';
import axiosApi from '../../shared/axios-api';

export class StrategyHttpRequest {
  public static get() {
    return axiosApi.get<Strategy[]>(`/strategy`);
  }

  public static getArchived() {
    return axiosApi.get<Strategy[]>(`/strategy/archived`);
  }

  public static update(strategy: Strategy) {
    return axiosApi.put(`/strategy/${strategy.id}`, strategy);
  }

  public static insert(strategy: Strategy) {
    return axiosApi.post<Strategy>('/strategy', strategy);
  }

  public static remove(id: number) {
    return axiosApi.delete(`/strategy/${id}`);
  }
}
