import { ValidationType } from '../../../types';

export interface BasisTypeItem {
  key: ValidationType;
  name: string;
}

export const basisTypes: BasisTypeItem[] = [
  { key: ValidationType.ClosePrice, name: 'Close Price' },
  { key: ValidationType.HighPrice, name: 'High Price' },
  { key: ValidationType.LowPrice, name: 'Low Price' },
  { key: ValidationType.MidPrice, name: 'Mid Price' },
  { key: ValidationType.Volume, name: 'Volume' }
];
