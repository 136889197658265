import { ContractPartialQuote, BarSize, ContractPartial } from '../../../../types';
import axiosApi from '../../../shared/axios-api';

export class PartialQuoteHttpRequest {
  public static get() {
    return axiosApi.get<ContractPartialQuote[]>('/contractpartialquote');
  }

  public static remove(partialQuote: ContractPartialQuote) {
    return axiosApi.delete('/contractpartialquote', {
      params: { contractPartialId: partialQuote.contractPartialId, barSize: partialQuote.barSize }
    });
  }

  public static collect(partial: ContractPartial, barSize: BarSize) {
    return axiosApi.post('/contractpartialquote', partial, { params: { barSize } });
  }

  public static updatePartialQuote(partialQuote: ContractPartialQuote) {
    const id = partialQuote.contractPartialId;
    return axiosApi.put(`/contractpartialquote/${id}`, partialQuote);
  }
}
