import { Map } from 'immutable';

import { Order } from '../../../../types';

export interface PositionOrderState {
  loaded: boolean;
  error?: string;
  orders: Order[];
}

export const initalPositionOrderState: PositionOrderState = {
  loaded: false,
  error: undefined,
  orders: []
};

export type PositionOrderMapState = Map<number, PositionOrderState>;

export const initalPositionOrderMapState: PositionOrderMapState = Map();
