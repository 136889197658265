import * as React from 'react';

import classNames from 'classnames';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Switch from 'react-switch';

import { Indicator } from '../../../../types';
import { basisTypes } from '../../utils/basis-utils';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const DonchianChannelsForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  const wiggleRoomTooltipPopover = (
    <Popover id="maTooltipPopover">
      <span>Messures an average of periods which have crossed over resistance or support instead of only latest period.</span>
    </Popover>
  );
  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label htmlFor="donchian_basis_type" className="col-xs-12 col-sm-6 control-label">
          Type
        </label>
        <div className="col-sm-6">
          <select
            id="donchian_basis_type"
            className="form-control"
            value={indicator.basisType}
            onChange={(event) => update({ basisType: Number(event.target.value) })}
          >
            {basisTypes.map((x) => (
              <option key={x.key} value={x.key}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="donchian_periods" className="col-xs-12 col-sm-6 control-label">
          Periods
        </label>
        <div className="col-sm-6">
          <input
            id="donchian_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.periods}
            onChange={(event) => update({ periods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="donchian_lifetime" className="col-xs-12 col-sm-6 control-label">
          Min S/R Lifetime
        </label>
        <div className="col-sm-6">
          <input
            id="donchian_lifetime"
            type="number"
            step="1"
            className="form-control"
            value={indicator.secondaryPeriods}
            onChange={(event) => update({ secondaryPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="compare_co_periods" className="col-sm-6 control-label">
          <span> Wiggle Room Periods</span>
          <OverlayTrigger placement="top" overlay={wiggleRoomTooltipPopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg text-info" />
            </span>
          </OverlayTrigger>
        </label>
        <div className="col-sm-6">
          <input
            id="compare_co_periods"
            type="number"
            step="1"
            min="0"
            className="form-control"
            value={indicator.basisPeriods}
            onChange={(event) => update({ basisPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label className="col-sm-6 control-label">Use ATR</label>
        <div className="col-sm-6">
          <label className="react-switch react-switch--sm">
            <Switch
              onChange={() => update({ useBounceProtection: !indicator.useBounceProtection })}
              checked={indicator.useBounceProtection}
            />
          </label>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="dc_atr_periods" className="col-sm-6 control-label">
          ATR Periods
        </label>
        <div className="col-sm-6">
          <input
            id="dc_atr_periods"
            type="number"
            step="1"
            min="1"
            className={classNames('form-control', { 'inactive-control': !indicator.useBounceProtection })}
            value={indicator.atrPeriods}
            onChange={(event) => update({ atrPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="dc_atr_threshold" className="col-sm-6 control-label">
          ATR Threshold
        </label>
        <div className="col-sm-6">
          <input
            id="dc_atr_threshold"
            type="number"
            step="any"
            min="0"
            className={classNames('form-control', { 'inactive-control': !indicator.useBounceProtection })}
            value={indicator.upperThreshold}
            onChange={(event) => update({ upperThreshold: Number(event.target.value) })}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DonchianChannelsForm;
