import { createUseStyles } from 'react-jss';

import { Theme } from './theme';

const useStyles = createUseStyles((theme: Theme) => ({
  '@global': {
    body: {},
    'hr.nav-devider': {
      margin: theme.spacing.xs2
    },
    '.graph__title': {
      display: 'inline-block',
      marginBottom: '0',
      fontSize: '12px',
      color: theme.palette.brandPrimary,
      textTransform: 'uppercase',
      letterSpacing: '1px'
    },
    '.info-text': {
      color: theme.palette.brandInfo
    },
    '.info-text2': {
      color: '#1b95e0'
    },
    '.danger-text': {
      color: theme.palette.brandDanger
    },
    '.danger-text2': {
      color: '#e43725'
    },
    '.warning-text': {
      color: '#ffea00'
    },
    '.btn-warning-y': {
      color: '#ffea00'
    },
    '.warning-text2': {
      color: theme.palette.brandWarning
    },
    '.warning-text3': {
      color: '#fcf8e3'
    },
    '.grey': {
      color: '#959595'
    },
    '.gold': {
      color: '#ffe34d'
    },
    '.grey .gold': {
      opacity: '0.3'
    },
    '.warning-text.grey': {
      color: '#959595'
    },
    '.success-text': {
      color: '#5cb85c'
    },
    '.success-text.grey': {
      color: '#959595'
    },
    '.success-text2': {
      color: theme.palette.brandSuccess
    },
    'input[type="radio"]': {
      '&:after': {
        content: '""',
        width: '15px',
        height: '15px',
        borderRadius: '15px',
        top: '-2px',
        left: '-1px',
        position: 'relative',
        backgroundColor: '#e9e9e9',
        display: 'inline-block',
        visibility: 'visible',
        border: '2px solid white',
        color: 'green'
      },
      '&:checked:after': {
        content: '""',
        width: '15px',
        height: '15px',
        borderRadius: '15px',
        top: '-2px',
        left: '-1px',
        position: 'relative',
        backgroundColor: theme.palette.brandSuccess,
        display: 'inline-block',
        visibility: 'visible',
        border: '2px solid #1b896d'
      }
    },
    '.inactive-control': {
      backgroundColor: theme.palette.grayLight
    },
    '.label-pending': {
      backgroundColor: theme.palette['sass-color']
    },
    '.label-dark': {
      backgroundColor: '#303030'
    },
    '.nav-tabs': {
      marginBottom: theme.spacing.md
    },
    i: {
      '&.online': {
        color: '#32cd32'
      },
      '&.pending': {
        color: '#ffea00'
      },
      '&.offline': {
        color: '#dc143c'
      },
      '&.unknown-status': {
        color: '#959595'
      }
    },
    div: {
      '&.online': {
        backgroundColor: theme.palette.brandSuccess
      },
      '&.pending': {
        backgroundColor: '#ffea00'
      },
      '&.offline': {
        backgroundColor: '#dc143c'
      },
      '&.unknown-status': {
        backgroundColor: '#959595'
      }
    }
  }
}));

export default useStyles;
