export const getSystemTime = (timeZone: number): Date => {
  const now = new Date();
  const utcNow = new Date(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds()
  );
  const systemTime = new Date(utcNow.getTime());
  systemTime.setHours(systemTime.getHours() + timeZone);
  return systemTime;
};

export interface TimeZoneItem {
  key: number;
  name: string;
  value: number;
}

export const timeZones: TimeZoneItem[] = [
  { key: 2, name: 'UTC -11:00: Samoa Standard Time', value: -11 },
  { key: 3, name: 'UTC -10:00: Hawaii', value: -10 },
  { key: 4, name: 'UTC -09:00: Alaska', value: -9 },
  { key: 5, name: 'UTC -08:00: Pacific Standard Time (PST)', value: -8 },
  { key: 6, name: 'UTC -07:00: Arizona', value: -7 },
  { key: 7, name: 'UTC -06:00: Central Standard Time (CST)', value: -6 },
  { key: 8, name: 'UTC -05:00: Eastern Standard Time (EST)', value: -5 },
  { key: 9, name: 'UTC -04:00: Santiago', value: -4 },
  { key: 10, name: 'UTC -03:00: Greenland', value: -3 },
  { key: 11, name: 'UTC -02:00: Mid-Atlantic Time', value: -2 },
  { key: 12, name: 'UTC -01:00: Cabo Verde Is.', value: -1 },
  { key: 13, name: 'UTC +00:00: Greenwich Mean Time (GMT)', value: 0 },
  { key: 14, name: 'UTC +01:00: Central European Time (CET)', value: 1 },
  { key: 15, name: 'UTC +02:00: East European Time', value: 2 },
  { key: 16, name: 'UTC +03:00: Moscow', value: 3 },
  { key: 17, name: 'UTC +04:00: Abu Dhabi', value: 4 },
  { key: 18, name: 'UTC +05:00: Islamabad', value: 5 },
  { key: 19, name: 'UTC +06:00: Central Asia Time', value: 6 },
  { key: 20, name: 'UTC +07:00: Bangkok', value: 7 },
  { key: 21, name: 'UTC +08:00: Singapore', value: 8 },
  { key: 22, name: 'UTC +09:00: Tokyo', value: 9 },
  { key: 23, name: 'UTC +10:00: Sydney', value: 10 },
  { key: 24, name: 'UTC +11:00: Central Pacific Time', value: 11 },
  { key: 25, name: 'UTC +12:00: New Zealand', value: 12 },
  { key: 26, name: 'UTC +13:00: Tonga Is.', value: 13 }
];
