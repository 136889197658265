import React from 'react';

import Switch from 'react-switch';

import { Indicator } from '../../../../types';
import { hoursOfDayList } from '../../../shared/utils/time-utils';

const INITIAL_TRADING_HOURS_SELECTION = '0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const TradingHoursForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  const specificTradeHours = indicator.textValue || INITIAL_TRADING_HOURS_SELECTION;
  const tradingHours = specificTradeHours.split('-');

  const selectHourOfDay = (hour: number, checked: boolean) => {
    tradingHours[hour] = checked ? '1' : '0';
    const result = `${tradingHours[0]}-${tradingHours[1]}-${tradingHours[2]}-${tradingHours[3]}-${tradingHours[4]}-${tradingHours[5]}-${tradingHours[6]}-${tradingHours[7]}-${tradingHours[8]}-${tradingHours[9]}-${tradingHours[10]}-${tradingHours[11]}-${tradingHours[12]}-${tradingHours[13]}-${tradingHours[14]}-${tradingHours[15]}-${tradingHours[16]}-${tradingHours[17]}-${tradingHours[18]}-${tradingHours[19]}-${tradingHours[20]}-${tradingHours[21]}-${tradingHours[22]}-${tradingHours[23]}`;
    update({ textValue: result });
  };

  const hourOptions = hoursOfDayList.map((item: { key: number; name: string }) => (
    <div key={item.key} className="form-group form-group-sm col-sm-6">
      <div className="col-xs-8 control-label">
        <span>{item.name}</span>
      </div>
      <div className="col-xs-4 small middle">
        <label className="react-switch react-switch--sm">
          <Switch onChange={(checked: boolean) => selectHourOfDay(item.key, checked)} checked={tradingHours[item.key] === '1'} />
        </label>
      </div>
    </div>
  ));

  return <div className="row">{hourOptions}</div>;
};

export default TradingHoursForm;
