import { AxiosInstance } from 'axios';

export enum Protocol {
  Http = 0,
  Https = 1
}

export const getCurrentProtocol = (): Protocol => {
  const prtcl = window.location.href.split(':')[0].toLowerCase();
  const protocol = prtcl.charAt(0).toUpperCase() + prtcl.slice(1);
  return Protocol[protocol];
};

export const updateHeaderWithAuthToken = (instance: AxiosInstance, token: string | null) => {
  const authToken = !!token ? `Bearer ${token}` : undefined;
  instance.defaults.headers.common['Authorization'] = authToken;
};
