import { ContractEntity as Contract, ContractEntity } from '../../../types';
import axiosApi from '../../shared/axios-api';

export class ContractHttpRequest {
  public static getContracts() {
    return axiosApi.get<Contract[]>(`/contract`);
  }

  public static getContractsBySecurity(securityId: number) {
    return axiosApi.get<Contract[]>(`/contract?securityId=${securityId}`);
  }

  public static update(contract: Contract) {
    return axiosApi.put(`/contract/${contract.id}`, contract);
  }

  public static insert(contract: Contract) {
    return axiosApi.post('/contract', contract);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static generate(request: any) {
    return axiosApi.post<ContractEntity>('/generatecontract/fut', request);
  }

  public static remove(id: number) {
    return axiosApi.delete(`/contract/${id}`);
  }
}
