import * as React from 'react';

import classNames from 'classnames';

import { Trade } from '../../../types';
import { formatUsd } from '../../shared/utils/currency-utils';
import { toTime } from '../../shared/utils/date-format-utils';

export interface TradeTableProps {
  trades: Trade[];
}

const TradeTable = ({ trades }: TradeTableProps) => {
  const header = (
    <tr>
      <th className="middle small">Filled</th>
      <th className="middle small text-right">Size</th>
      <th className="middle small text-right">Price</th>
      <th className="middle small text-right">Slipp.</th>
      <th className="middle small text-right">Comm.</th>
      <th className="middle small text-right">PnL</th>
      <th className="middle small text-right no-wrap">Rlz. PnL</th>
    </tr>
  );

  const rows = trades.map((x) => {
    return (
      <tr key={x.id}>
        <td className="middle small">{toTime(x.filled)}</td>
        <td className="middle small text-right">{x.size}</td>
        <td className="middle small text-right">{formatUsd(x.price, 6)}</td>
        <td className="middle small text-right">
          <span>
            {formatUsd(x.slippageAmount, 0)} ({x.slippageTicks})
          </span>
        </td>
        <td className="middle small text-right">{formatUsd(x.commission, 1)}</td>
        <td className="middle small text-right vborder">
          <span
            className={classNames({
              'success-text': x.realizedPnl > 0,
              'danger-text2': x.realizedPnl < 0
            })}
          >
            {formatUsd(x.realizedPnl, 0)}
          </span>
        </td>
        <td className="middle small text-right">
          <span
            className={classNames({
              'success-text': x.realizedPnl > 0,
              'danger-text2': x.realizedPnl < 0
            })}
          >
            {formatUsd(x.realizedPnl, 0)}
          </span>
        </td>
      </tr>
    );
  });

  return (
    <div className="table-responsive gap-top-10">
      <table className="table table-striped table-hover table-condensed">
        <thead>{header}</thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

export default TradeTable;
