import * as React from 'react';
import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { GlobalState } from '../../../../core/root';
import { StrategyGroup } from '../../../../types';
import CollapsePanel from '../../../shared/components/CollapsePanel';
import * as strategyGroupActionCreators from '../../../strategy-group/store/action-creators';
import * as strategyActionCreators from '../../store/action-creators';

import StrategyGroupFilter from './StrategyGroupFilter';

export interface StrategyOptionFilterProps {
  title?: string;
  accountId?: number;
  useCollapsePanel?: boolean;
}

const StrategyGroupFilterPanel: React.FunctionComponent<StrategyOptionFilterProps> = ({ title, accountId, useCollapsePanel }) => {
  const state: GlobalState = useSelector((gs: GlobalState) => gs);
  const collapsed = state.strategyState.ui.optionPanelCollapsed;
  const groupsLoaded = state.strategyGroupState.loaded;
  const groups = state.strategyGroupState.strategyGroups;
  const selectedStrategyGroup: StrategyGroup | undefined = state.strategyGroupState.ui.selectedStrategyGroup;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!groupsLoaded) {
      dispatch(strategyGroupActionCreators.loadStrategyGroupsAsync());
    }
  }, []);

  const toggleCollapse = () => {
    dispatch(strategyActionCreators.setOptionPanelCollapsed(!collapsed));
  };

  if (!groupsLoaded) {
    return null;
  }

  if (useCollapsePanel) {
    return (
      <CollapsePanel title={title || 'Groups'} subTitle={selectedStrategyGroup?.name} collapsed={collapsed} onToggle={toggleCollapse}>
        <div className="form-horizontal">
          <StrategyGroupFilter accountId={accountId} />
        </div>
      </CollapsePanel>
    );
  }

  const hasStrategyGroups =
    groups
      .filter((x) => {
        return accountId ? x.accountId === accountId : true;
      })
      .filter((x) => !x.archived).length > 0;

  return hasStrategyGroups ? (
    <div className="gap-bottom-15">
      <StrategyGroupFilter accountId={accountId} />
      <div className="section">
        <hr />
        <p>Strategies</p>
      </div>
    </div>
  ) : null;
};

export default StrategyGroupFilterPanel;
