import { Period } from '../../../../types';

export interface PeriodRangeState {
  error?: string;
  loading: boolean;
  loaded: boolean;
  periods: Period[];
  ui: {
    periodDay?: Date;
    selectedSubscriptionId: number;
  };
}

export const initalPeriodRangeState: PeriodRangeState = {
  error: undefined,
  loading: false,
  loaded: false,
  periods: [],
  ui: {
    selectedSubscriptionId: 0
  }
};
