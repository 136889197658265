import { AnyAction } from 'redux';

import { Position } from '../../../../types';

import * as actionTypes from './action-types';
import { initalOpenPositionState } from './state';

export const openPositionReducer = (state = initalOpenPositionState, action: AnyAction) => {
  const getOpenPositionIndex = (id: number): number => {
    const item = state.positions.find((x) => x.id === id);
    return item ? state.positions.indexOf(item) : -1;
  };

  if (action.type === actionTypes.SET_OPEN_POSITIONS) {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: undefined,
      positions: action.positions
    };
  }
  if (action.type === actionTypes.UPDATE_OPEN_POSITION_UNREALIZED_PNL) {
    const position = action.payload as Position;
    const index = getOpenPositionIndex(position.id);
    const positions = [...state.positions];
    const pos = positions[index];
    if (!!pos) {
      pos.sumUnrealizedPnl = position.sumUnrealizedPnl;
    }
    return {
      ...state,
      error: undefined,
      positions
    };
  } else if (action.type === actionTypes.SET_OPEN_POSITIONS_ERROR) {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: action.error
    };
  } else if (action.type === actionTypes.CLEAR_OPEN_POSITION_ERROR) {
    return {
      ...state,
      error: undefined
    };
  } else if (action.type === actionTypes.CLEAR_OPEN_POSITIONS_LOADED) {
    return {
      ...state,
      loaded: false
    };
  }

  return state;
};
