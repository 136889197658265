import { Indicator } from '../../../types';
import axiosApi from '../../shared/axios-api';

export class IndicatorHttpRequest {
  public static get(strategyId: number) {
    return axiosApi.get<Indicator[]>(`/indicators`, { params: { strategyId } });
  }

  public static insert(indicator: Indicator) {
    return axiosApi.post('/indicator', indicator);
  }

  public static update(indicator: Indicator) {
    return axiosApi.put(`/indicator/${indicator.id}`, indicator);
  }

  public static remove(id: number) {
    return axiosApi.delete(`/indicator/${id}`);
  }
}
