import { Backtest } from '../../../../types';

import * as actionTypes from './action-types';
import { BacktestAction } from './actions';
import { initalBacktestMapState, initalBacktestState } from './state';

export const backtestReducer = (state = initalBacktestMapState, action: BacktestAction) => {
  const key = `${action.securityId}-${action.strategyId}`;
  const current = state.get(key) || { ...initalBacktestState };

  if (action.type === actionTypes.SET_BACKTEST_LIST_LOADING) {
    const loading = action.loading === true;
    return state.set(key, {
      ...current,
      loading
    });
  } else if (action.type === actionTypes.SET_BACKTEST_LIST_ERROR) {
    return state.set(key, {
      ...current,
      loading: false,
      error: action.error
    });
  } else if (action.type === actionTypes.SET_BACKTEST_LIST_CLEAR_ERROR) {
    return state.set(key, {
      ...current,
      error: undefined
    });
  } else if (action.type === actionTypes.SET_BACKTEST_LIST) {
    const backtests = action.backtests as Backtest[];
    return state.set(key, {
      loading: false,
      loaded: true,
      error: undefined,
      backtests
    });
  } else if (action.type === actionTypes.ADD_BACKTEST) {
    const backtests = [...current.backtests];
    const backtest = action.backtest as Backtest;
    backtests.push(backtest);
    return state.set(key, {
      ...current,
      backtests
    });
  } else if (action.type === actionTypes.ADD_BACKTESTS) {
    const addedBacktests = action.backtests as Backtest[];
    const backtests = [...current.backtests].concat(addedBacktests);
    return state.set(key, {
      ...current,
      error: undefined,
      backtests
    });
  } else if (action.type === actionTypes.UPDATE_BACKTEST) {
    const backtests = [...current.backtests];
    const backtest = action.backtest as Backtest;
    const item = backtests.find((x) => x.id === backtest.id);
    const index = item ? backtests.indexOf(item) : -1;
    backtests[index] = backtest;
    return state.set(key, {
      ...current,
      error: undefined,
      backtests
    });
  } else if (action.type === actionTypes.REMOVE_BACKTEST) {
    const backtests = [...current.backtests];
    const backtest = action.backtest as Backtest;
    const item = backtests.find((x) => x.id === backtest.id);
    const index = item ? backtests.indexOf(item) : -1;
    backtests.splice(index, 1);
    return state.set(key, {
      ...current,
      error: undefined,
      backtests
    });
  }

  return state;
};
