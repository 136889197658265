import { Backtest } from '../../types';

export const sortByPartialMonth = (a: Backtest, b: Backtest) => {
  if (a.partial.partialMonth > b.partial.partialMonth) {
    return 1;
  } else if (a.partial.partialMonth < b.partial.partialMonth) {
    return -1;
  }
  return 0;
};
