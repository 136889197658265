import * as React from 'react';
import { useEffect } from 'react';

import classNames from 'classnames';
import { Tabs, Tab } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { GlobalState } from '../../../core/root';
import { Strategy, Account, StrategyGroup } from '../../../types';
import * as accountActionCreators from '../../account/store/action-creators';
import { loadAccountListAsync } from '../../account/store/service';
import CollapsePanel from '../../shared/components/CollapsePanel';
import Spinner from '../../shared/components/Spinner';
import { sortStrategies } from '../../strategy-group/utils';
import * as actionCreators from '../store/action-creators';
import { loadStrategiesAsync } from '../store/services';

import StrategyGroupFilterPanel from './StrategyGroupFilterPanel';

export interface StrategyPickerPanelProps {
  title?: string;
  smallTitle?: string;
  upper?: boolean;
}

const StrategyPickerPanel = ({ title, smallTitle, upper }: StrategyPickerPanelProps) => {
  const authAccountId = useSelector((state: GlobalState) => state.authState.accountId);

  const accountsLoaded: boolean = useSelector((state: GlobalState) => state.accountState.accountListLoaded);
  const accounts: Account[] = useSelector((state: GlobalState) => state.accountState.accountList);
  const selectedAccountId = useSelector((state: GlobalState) => state.accountState.ui.selectedAccountId);

  const collapsed = useSelector((state: GlobalState) => state.strategyState.ui.pickerPanelCollapsed);
  const picked = useSelector<Strategy>((state: GlobalState) => state.strategyState.ui.strategyPicked);
  const showArchived = useSelector((state: GlobalState) => state.strategyState.ui.showArchived);
  const strategies = useSelector((state: GlobalState) => state.strategyState.strategies);
  const strategiesLoaded = useSelector((state: GlobalState) => state.strategyState.loaded);

  const selectedGroup: StrategyGroup | undefined = useSelector((state: GlobalState) => state.strategyGroupState.ui.selectedStrategyGroup);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!accountsLoaded) {
      dispatch(loadAccountListAsync());
    }
    if (!strategiesLoaded) {
      dispatch(loadStrategiesAsync());
    }
  }, []);

  const toggleCollapse = () => {
    dispatch(actionCreators.setPickerPanelCollapsed(!collapsed));
  };

  const isActive = (strategy: Strategy) => {
    return picked && strategy.id === picked.id;
  };

  const pick = (strategy: Strategy) => {
    if (isActive(strategy)) {
      dispatch(actionCreators.setStrategyPicked(undefined));
    } else {
      dispatch(actionCreators.setStrategyPicked(strategy));
    }
  };

  //const defaultId = accounts && accounts.length > 0 ? accounts[0].id : 0;
  const accountId = selectedAccountId || authAccountId;

  const options = strategies
    .filter((x) => x.accountId === accountId)
    .filter((x) => {
      if (selectedGroup) {
        return selectedGroup.id === x.groupId;
      } else {
        return x.groupId == undefined;
      }
    })
    .filter((x) => {
      if (showArchived === true) {
        return x;
      } else {
        return x.archived === false;
      }
    })
    .sort(sortStrategies)
    .map((strategy: Strategy) => (
      <button
        key={strategy.id}
        type="button"
        className={classNames('btn', 'btn-sm', 'btn-default', 'gap-bottom-5', { active: isActive(strategy) })}
        onClick={() => pick(strategy)}
      >
        {strategy.name}
      </button>
    ));

  const tabs = accounts
    .filter((x) => (x.isAdminUser || x.isSuperUser) && !x.disabled)
    .sort((a, b) => {
      if (a.firstName > b.firstName) return 1;
      if (a.firstName < b.firstName) return -1;
      return 0;
    })
    .map((x) => <Tab key={x.id} eventKey={x.id} title={x.firstName}></Tab>);

  const selectTabHandler = (key: number) => {
    dispatch(accountActionCreators.setSelectedAccountId(key));
  };

  // defaults
  const upperVal = upper !== undefined ? upper : true;

  const loading = !accountsLoaded || !strategiesLoaded;

  if (loading) {
    return <Spinner />;
  }

  return (
    <CollapsePanel
      title={title || 'Strategies'}
      smallTitle={smallTitle}
      subTitle={picked?.name}
      upper={upperVal}
      collapsed={collapsed}
      onToggle={toggleCollapse}
    >
      <div className="form-horizontal">
        <Tabs id="account_strategy_group_tabs" defaultActiveKey={accountId} onSelect={selectTabHandler} animation={false}>
          {tabs}
        </Tabs>

        <div className="row">
          <div className="col-xs-12">
            <StrategyGroupFilterPanel title="Groups" accountId={accountId} />
            <div className="btn-toolbar">{options}</div>
          </div>
        </div>
      </div>
    </CollapsePanel>
  );
};

export default StrategyPickerPanel;
