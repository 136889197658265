import { BacktestSummary } from '../../../../types';

import { SubscriptionSummaryAction } from './action-creators';
import * as actionTypes from './action-types';
import { initialSubscriptionSummaryState } from './state';

export const subscriptionSummaryReducer = (state = initialSubscriptionSummaryState, action: SubscriptionSummaryAction) => {
  if (action.type === actionTypes.SET_SUBSCRIPTION_SUM_LOADING) {
    const loading = action.loading === true;
    return {
      ...state,
      loading,
      loaded: !loading,
      error: undefined
    };
  } else if (action.type === actionTypes.SET_SUBSCRIPTION_SUM_ERROR) {
    return {
      ...state,
      loading: false,
      error: action.error
    };
  } else if (action.type === actionTypes.SET_SUBSCRIPTION_SUM_LIST) {
    const summaries = action.summaries as BacktestSummary[];
    return {
      loading: false,
      loaded: true,
      error: undefined,
      summaries
    };
  }

  return state;
};
