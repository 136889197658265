import * as React from 'react';

import { Switch, Route } from 'react-router-dom';

import StrategyGroupScreen from './components/StrategyGroupScreen';

export const strategyGroupsModulePath = '/strategy-groups';

const render = () => {
  return (
    <Switch>
      <Route exact path={strategyGroupsModulePath} component={StrategyGroupScreen}></Route>
    </Switch>
  );
};

export default {
  routeProps: {
    path: strategyGroupsModulePath,
    render
  },
  name: 'StrategyGroups'
};
