import { ContractPartialQuote } from '../../types';

export const formatPartialMonth = (month: string) => {
  return month; // (month) ? `${month.substring(0, 4)}  ${month.substring(4, 6)}` : month;
};

export const sortContractPartialQuotes = (a: ContractPartialQuote, b: ContractPartialQuote) => {
  if (a.barSize > b.barSize) {
    return 1;
  } else if (a.barSize < b.barSize) {
    return -1;
  } else {
    return 0;
  }
};

export const sortContractPartialQuotesDecending = (a: ContractPartialQuote, b: ContractPartialQuote) => {
  if (a.barSize > b.barSize) {
    return -1;
  } else if (a.barSize < b.barSize) {
    return 1;
  } else {
    return 0;
  }
};
