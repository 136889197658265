import * as React from 'react';

import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { GlobalState } from '../../../../core/root';
import { StrategyGroup } from '../../../../types';
import * as strategyGroupActionCreators from '../../../strategy-group/store/action-creators';
import { sortStrategyGroups } from '../../../strategy-group/utils';

export interface Props {
  accountId?: number;
}

const StrategyGroupFilter: React.FunctionComponent<Props> = ({ accountId }) => {
  const state: GlobalState = useSelector((gs: GlobalState) => gs);
  const groups = state.strategyGroupState.strategyGroups;
  const selectedStrategyGroup = state.strategyGroupState.ui.selectedStrategyGroup;
  const dispatch = useDispatch();

  const strategyGroupClickHandler = (group: StrategyGroup) => {
    const selected = selectedStrategyGroup?.id === group.id ? undefined : group;
    dispatch(strategyGroupActionCreators.setSelectedStrategyGroup(selected));
  };

  const isActive = (group: StrategyGroup) => {
    return selectedStrategyGroup?.id === group.id;
  };

  const options = groups
    .filter((x) => {
      return accountId ? x.accountId === accountId : true;
    })
    .filter((x: StrategyGroup) => !x.archived)
    .sort(sortStrategyGroups)
    .map((x) => {
      return (
        <button
          key={x.id}
          type="button"
          className={classNames('btn', 'btn-sm', 'btn-default', 'gap-bottom-5', { active: isActive(x) })}
          onClick={() => strategyGroupClickHandler(x)}
        >
          {x.name}
        </button>
      );
    });

  return <div className="btn-toolbar">{options}</div>;
};

export default StrategyGroupFilter;
