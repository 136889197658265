import * as React from 'react';

import classNames from 'classnames';

import { ValidationMode, Indicator } from '../../../../types';
import { basisTypes } from '../../utils/basis-utils';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const AmountUpDownForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label className="col-sm-6 control-label">Mode</label>
        <div className="col-sm-6">
          <div className="btn-group btn-group-justified" role="group">
            <div className="btn-group" role="group">
              <button
                key="btn-1"
                type="button"
                className={classNames('btn', 'btn-sm btn-default', 'ripple', { active: indicator.atrMode === ValidationMode.Above })}
                onClick={() => update({ atrMode: ValidationMode.Above })}
              >
                Above
              </button>
            </div>
            <div className="btn-group" role="group">
              <button
                key="btn-2"
                type="button"
                className={classNames('btn', 'btn-sm btn-default', 'ripple', { active: indicator.atrMode === ValidationMode.Below })}
                onClick={() => update({ atrMode: ValidationMode.Below })}
              >
                Below
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="amountupdown_basis_type" className="col-xs-12 col-sm-6 control-label">
          Type
        </label>
        <div className="col-sm-6">
          <select
            id="amountupdown_basis_type"
            className="form-control"
            value={indicator.basisType}
            onChange={(event) => update({ basisType: Number(event.target.value) })}
          >
            {basisTypes.map((x) => (
              <option key={x.key} value={x.key}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="amountupdown_periods" className="col-sm-6 control-label">
          Periods
        </label>
        <div className="col-sm-6">
          <input
            id="amountupdown_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.periods}
            onChange={(event) => update({ periods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="amountupdown_lowert" className="col-sm-6 control-label">
          Threshold
        </label>
        <div className="col-sm-6">
          <input
            id="amountupdown_lowert"
            type="number"
            step="1"
            className="form-control"
            value={indicator.upperThreshold}
            onChange={(event) => update({ upperThreshold: Number(event.target.value) })}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AmountUpDownForm;
