import * as React from 'react';

import { Tabs, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';

import { GlobalState } from '../../../../core/root';
import * as layoutActionCreators from '../../../../modules/shared/store/layout/action-creators';
import { Security, ContractPartial, BarSize, ContractPartialQuote, Strategy } from '../../../../types';
import GraphDialog from '../../../graph/components/GraphDialog';
import * as graphActionCreators from '../../../graph/store/graph-data/action-creators';
import * as graphTileActionCreators from '../../../graph/store/graph-tile/action-creators';
import { GraphTile } from '../../../graph/store/graph-tile/state';
import * as securityActionCreators from '../../../security/store/action-creators';
import axiosApi from '../../../shared/axios-api';
import Spinner from '../../../shared/components/Spinner';
import { trimTimePartFromDate } from '../../../shared/utils/date-picker-utils';
import { convertToDate } from '../../../shared/utils/date-utils';
import { showNetworkError } from '../../../shared/utils/growl-utils';
import { contractPartialModulePath } from '../../routes';
import { getContractPartials, getContractPartialsError, getContractPartialsLoaded } from '../../store/partial-map/selectors';
import { loadContractPartialsAsync, removeContractPartialAsync, updateContractPartialAsync } from '../../store/partial-map/service';
import * as partialQuoteActionCreators from '../../store/partial-quote/action-creators';
import { formatPartialMonth } from '../../utils';

import GeneralPanel from './GeneralPanel';
import QuotePanel from './QuotePanel';
import StatisticsPanel from './StatisticsPanel';

export interface ContractPartialProps {
  loaded: boolean;
  error?: string;
  partial: ContractPartial;
  partials: ContractPartial[];
  periodStart?: string;
  periodEnd?: string;
  loadPartials(securityId: number): void;
  updatePartial(securityId: number, partial: ContractPartial): void;
  deletePartial(securityId: number, partial: ContractPartial): void;
  securitiesLoaded: boolean;
  securitiesError: boolean;
  security: Security;
  loadSecurities(): void;
  partialQuotes: ContractPartialQuote[];
  partialQuotesLoaded: boolean;
  partialQuotesError?: string;
  loadPartialQuotes(): void;
  collectPartialQuotes(partial: ContractPartial, barSize: BarSize): void;
  updatePartialQuote(partialQuote: ContractPartialQuote): void;
  removePartialQuote(partialQuote: ContractPartialQuote): void;
  selectedTab: number;
  setSelectedTab(tabId: number): void;
  removeGraphData(guid: string): void;
  addGraphTile(graphTile: GraphTile): void;
  selectedStrategy: Strategy | undefined;
}

export interface RouteParamProps {
  securityId: string;
  id: string;
}

export type CombinedProps = ContractPartialProps & RouteComponentProps<RouteParamProps>;

export interface ContractPartialScreenState {
  partialMonth: string;
  periodStart?: string;
  periodEnd?: string;
  generalAtrTicksBarSize: BarSize;
  generalAtrTicks: number;
  averageCloseChangeInTicks: number;
  selectedBarSize: BarSize | undefined;
  graphBarSize: BarSize | undefined;
  showGraphDialog: boolean;
}

class ContractPartialScreen extends React.Component<CombinedProps, ContractPartialScreenState> {
  constructor(props: CombinedProps) {
    super(props);
    this.state = {
      partialMonth: props.partial ? props.partial.partialMonth : '',
      periodStart: props.periodStart,
      periodEnd: props.periodEnd,
      generalAtrTicksBarSize: props.partial ? props.partial.generalAtrTicksBarSize : 0,
      generalAtrTicks: props.partial ? props.partial.generalAtrTicks : 0,
      averageCloseChangeInTicks: props.partial ? props.partial.averageCloseChangeInTicks : 0,
      selectedBarSize: undefined,
      graphBarSize: undefined,
      showGraphDialog: false
    };
  }

  componentDidMount() {
    if (!this.props.securitiesLoaded) {
      this.props.loadSecurities();
    }
    if (!this.props.partialQuotesLoaded) {
      this.props.loadPartialQuotes();
    }
  }

  componentDidUpdate(prevProps: CombinedProps) {
    if (this.props.securitiesLoaded) {
      if (!this.props.loaded) {
        this.props.loadPartials(this.props.security.id);
      }
    }

    if (!this.props.partial) {
      return;
    }
    if (this.props.partial.partialMonth !== prevProps.partial?.partialMonth) {
      this.setState({ partialMonth: this.props.partial.partialMonth });
    }
    if (this.props.periodStart !== prevProps.periodStart) {
      this.setState({ periodStart: this.props.periodStart });
    }
    if (this.props.periodEnd !== prevProps.periodEnd) {
      this.setState({ periodEnd: this.props.periodEnd });
    }
    if (this.props.partial.generalAtrTicksBarSize !== prevProps.partial?.generalAtrTicksBarSize) {
      this.setState({ generalAtrTicksBarSize: this.props.partial.generalAtrTicksBarSize });
    }
    if (this.props.partial.generalAtrTicks !== prevProps.partial?.generalAtrTicks) {
      this.setState({ generalAtrTicks: this.props.partial.generalAtrTicks });
    }
    if (this.props.partial.averageCloseChangeInTicks !== prevProps.partial?.averageCloseChangeInTicks) {
      this.setState({ averageCloseChangeInTicks: this.props.partial.averageCloseChangeInTicks });
    }
  }

  toggleShowGraphDialog = () => {
    this.setState({ showGraphDialog: !this.state.showGraphDialog });
  };

  showGraphHandler = (barSize: BarSize) => {
    this.setState({ graphBarSize: barSize });
    this.toggleShowGraphDialog();
  };

  updatePeriodStart = (value: Date) => {
    const periodStart = trimTimePartFromDate(value);
    this.setState({ periodStart });
  };

  updatePeriodEnd = (value: Date) => {
    const periodEnd = trimTimePartFromDate(value);
    this.setState({ periodEnd });
  };

  navigateToList = () => {
    // const url = `${securityModulePath}/${this.props.security.id}?tab=contract-partials`;
    const url = contractPartialModulePath;
    this.props.history.push(url);
  };

  deleteContractPartialHandler = () => {
    this.props.deletePartial(this.props.security.id, this.props.partial);
    this.navigateToList();
  };

  updateContractPartialHandler = () => {
    const bt = {
      ...this.props.partial,
      partialMonth: this.state.partialMonth,
      periodStart: convertToDate(this.state.periodStart),
      periodEnd: convertToDate(this.state.periodEnd),
      generalAtrTicksBarSize: this.state.generalAtrTicksBarSize,
      generalAtrTicks: this.state.generalAtrTicks,
      averageCloseChangeInTicks: this.state.averageCloseChangeInTicks
    };
    this.props.updatePartial(this.props.security.id, bt);
    this.navigateToList();
  };

  queryTicksInAtr = (partial: ContractPartial) => {
    if (!partial || partial.generalAtrTicksBarSize === BarSize.NA) {
      return;
    }
    axiosApi
      .post<number>('/partial-stats', partial)
      .then((response) => {
        if (partial.generalAtrTicksBarSize === BarSize._1_DAY) {
          this.setState({ averageCloseChangeInTicks: Number(response.data) });
        } else {
          this.setState({ generalAtrTicks: Number(response.data) });
        }
      })
      .catch((error) => {
        showNetworkError(error);
      });
  };

  queryGeneralAtrInRth = () => {
    const partial = { ...this.props.partial };
    partial.generalAtrTicksBarSize = this.state.generalAtrTicksBarSize || BarSize.NA;
    this.queryTicksInAtr(partial);
  };

  queryEndOfDayAtrTicks = () => {
    const partial = { ...this.props.partial };
    partial.generalAtrTicksBarSize = BarSize._1_DAY;
    this.queryTicksInAtr(partial);
  };

  collectPartialQuotes = (barSize: BarSize | undefined) => {
    if (!barSize) {
      // TODO: create dialog-popup
      // eslint-disable-next-line no-console
      console.log('barSize not selected..');
    } else {
      this.props.collectPartialQuotes(this.props.partial, barSize);
    }
  };

  selectTabHandler = (key: number) => {
    this.props.setSelectedTab(key);
  };

  setSelectedBarSize = (barSize: BarSize) => {
    this.setState({ selectedBarSize: barSize });
  };

  setGeneralAtrTicksBarSize = (barSize: BarSize) => {
    this.setState({ generalAtrTicksBarSize: barSize });
  };

  setGeneralAtrTicks = (num: number) => {
    this.setState({ generalAtrTicks: num });
  };

  setAverageCloseChangeInTicks = (num: number) => {
    this.setState({ averageCloseChangeInTicks: num });
  };

  render() {
    const { partial } = this.props;

    if (!partial) {
      return null;
    }

    const loading = !this.props.loaded || !this.props.securitiesLoaded || !this.props.partialQuotesLoaded;

    const breadCrumpTitle = `${partial.contract.localSymbol} ${formatPartialMonth(partial.partialMonth)}`;

    return (
      <div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={contractPartialModulePath}>Partials</Link>
          </li>
          <li className="breadcrumb-item active">{breadCrumpTitle}</li>
        </ol>

        <div className="page-header">
          <h3>
            Partial <small className="no-wrap">{breadCrumpTitle}</small>
          </h3>
        </div>

        {loading && <Spinner />}

        <Tabs id="indicator_tabs" defaultActiveKey={this.props.selectedTab} onSelect={this.selectTabHandler}>
          <Tab eventKey={1} title="Quotes">
            <div className="row form-horizontal">
              <div className="col-md-6">
                <GeneralPanel
                  security={this.props.security}
                  partials={this.props.partials}
                  partial={this.props.partial}
                  partialMonth={this.state.partialMonth}
                  periodStart={this.state.periodStart}
                  periodEnd={this.state.periodEnd}
                  updatePeriodStart={this.updatePeriodStart}
                  updatePeriodEnd={this.updatePeriodEnd}
                />
              </div>
              <div className="col-md-6">
                <QuotePanel
                  partialQuotes={this.props.partialQuotes}
                  selectedBarSize={this.state.selectedBarSize}
                  setSelectedBarSize={this.setSelectedBarSize}
                  collectPartialQuotes={this.collectPartialQuotes}
                  showGraphHandler={this.showGraphHandler}
                  updatePartialQuote={this.props.updatePartialQuote}
                  removePartialQuote={this.props.removePartialQuote}
                />
              </div>
              <div className="col-xs-12">
                {/* <div className="section">
                  <hr />
                  <p>Graph View</p>
                </div>

                <GraphTilePanel onAddGraphTile={this.addGraphTileHandler} partialId={this.props.partial.id} /> */}
              </div>
            </div>
          </Tab>

          <Tab eventKey={2} title="Statistics">
            <div className="row form-horizontal">
              <div className="col-md-6">
                <StatisticsPanel
                  periodEnd={this.state.periodEnd}
                  generalAtrTicks={this.state.generalAtrTicks}
                  setGeneralAtrTicks={this.setGeneralAtrTicks}
                  generalAtrTicksBarSize={this.state.generalAtrTicksBarSize}
                  setGeneralAtrTicksBarSize={this.setGeneralAtrTicksBarSize}
                  queryGeneralAtrInRth={this.queryGeneralAtrInRth}
                  averageCloseChangeInTicks={this.state.averageCloseChangeInTicks}
                  setAverageCloseChangeInTicks={this.setAverageCloseChangeInTicks}
                  queryEndOfDayAtrTicks={this.queryEndOfDayAtrTicks}
                />
              </div>
            </div>
          </Tab>
        </Tabs>

        <hr />
        <div className="row">
          <div className="col-xs-6">
            <button type="button" className="btn btn-danger ripple" onClick={this.deleteContractPartialHandler}>
              Delete
            </button>
          </div>
          <div className="col-xs-6 text-right">
            <button type="button" className="btn btn-primary ripple gap-right-5" onClick={this.updateContractPartialHandler}>
              Save
            </button>
            <button type="button" className="btn btn-default ripple" onClick={this.navigateToList}>
              Close
            </button>
          </div>
        </div>

        {this.state.showGraphDialog && (
          <GraphDialog
            partial={this.props.partial}
            barSize={this.state.graphBarSize}
            show={this.state.showGraphDialog}
            toggle={this.toggleShowGraphDialog}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState, props: CombinedProps) => {
  const securityId = Number(props.match.params.securityId);
  const security = state.securityState.securities.find((x) => x.id === securityId);
  const partials = getContractPartials(state, securityId);
  const loaded = getContractPartialsLoaded(state, securityId);
  const error = getContractPartialsError(state, securityId);
  const partialId = Number(props.match.params.id);
  const partial = partials.find((x) => x.id === partialId);
  const partialQuotes = state.partialQuoteState.partialQuotes.filter((x) => x.contractPartialId === Number(props.match.params.id));
  const periodStart = trimTimePartFromDate(partial?.periodStart);
  const periodEnd = trimTimePartFromDate(partial?.periodEnd);

  return {
    loaded,
    error,
    partial,
    partials,
    periodStart,
    periodEnd,
    securitiesLoaded: state.securityState.loaded,
    securitiesError: state.securityState.error,
    security,
    partialQuotes,
    partialQuotesLoaded: state.partialQuoteState.loaded,
    partialQuotesError: state.partialQuoteState.error,
    selectedTab: state.layoutState.ui.contractPartialTabSelected,
    selectedStrategy: state.strategyState.ui.strategyPicked
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadPartials: (securityId: number) => dispatch(loadContractPartialsAsync(securityId)),
    updatePartial: (securityId: number, partial: ContractPartial) => dispatch(updateContractPartialAsync(securityId, partial)),
    deletePartial: (securityId: number, partial: ContractPartial) => dispatch(removeContractPartialAsync(securityId, partial)),
    loadSecurities: () => dispatch(securityActionCreators.loadSecuritiesAsync()),
    loadPartialQuotes: () => dispatch(partialQuoteActionCreators.loadPartialQuotesAsync()),
    collectPartialQuotes: (contractPartial: ContractPartial, barSize: BarSize) =>
      dispatch(partialQuoteActionCreators.collectPartialQuotesAsync(contractPartial, barSize)),
    updatePartialQuote: (partialQuote: ContractPartialQuote) => dispatch(partialQuoteActionCreators.updatePartialQuoteAsync(partialQuote)),
    removePartialQuote: (partialQuote: ContractPartialQuote) => dispatch(partialQuoteActionCreators.removePartialQuotesAsync(partialQuote)),
    setSelectedTab: (tabId: number) => dispatch(layoutActionCreators.setContractPartialTab(tabId)),
    removeGraphData: (guid: string) => dispatch(graphActionCreators.removeGraphData(guid)),
    addGraphTile: (graphTile: GraphTile) => dispatch(graphTileActionCreators.addGraphTile(graphTile))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractPartialScreen);
