import * as React from 'react';
import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { GlobalState } from '../../../core/root';
import { OrderAction, OrderDirection, Strategy } from '../../../types';
import Spinner from '../../shared/components/Spinner';
import { getIndicators, shouldFetchIndicators } from '../store/selectors';
import { loadIndicatorsAsync } from '../store/service';

import IndicatorList from './IndicatorList';
import IndicatorOptionsPanel from './IndicatorOptionsPanel';

export interface IndicatorPanelProps {
  strategy: Strategy;
}

const IndicatorPanel: React.FunctionComponent<IndicatorPanelProps> = ({ strategy }: IndicatorPanelProps) => {
  const state: GlobalState = useSelector((x: GlobalState) => x);
  const indicatorsLoaded = !shouldFetchIndicators(state, strategy.id);
  const indicators = getIndicators(state, strategy.id);
  const longIndicators = indicators?.filter((x) => x.direction === OrderDirection.Enter && x.action === OrderAction.Buy) ?? [];
  const shortIndicators = indicators?.filter((x) => x.direction === OrderDirection.Enter && x.action === OrderAction.Sell) ?? [];
  const exitIndicators = indicators?.filter((x) => x.direction === OrderDirection.Exit) ?? [];

  const dispatch = useDispatch();

  useEffect(() => {
    if (!indicatorsLoaded) {
      dispatch(loadIndicatorsAsync(strategy.id));
    }
  }, [strategy]);

  if (!indicatorsLoaded) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="section">
        <p>Long Indicators</p>
      </div>
      {longIndicators.length > 0 ? (
        <IndicatorList indicators={longIndicators || []} />
      ) : (
        <span className="small">No long indicators..</span>
      )}
      <div className="section">
        <hr />
        <p>Short Indicators</p>
      </div>
      {shortIndicators.length > 0 ? (
        <IndicatorList indicators={shortIndicators || []} />
      ) : (
        <span className="small">No short indicators..</span>
      )}
      <div className="section">
        <hr />
        <p>Exit Indicators</p>
      </div>
      {exitIndicators.length > 0 ? (
        <IndicatorList indicators={exitIndicators || []} />
      ) : (
        <span className="small">No exit indicators..</span>
      )}

      <div className="gap-top-30">
        <IndicatorOptionsPanel strategy={strategy} />
      </div>
    </div>
  );
};

export default IndicatorPanel;
