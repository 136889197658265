import { DefaultTheme } from 'react-jss';

export interface Theme extends DefaultTheme {
  palette: {
    ['app-primary']: string;
    ['sass-color']: string;
    grayDark: string;
    gray: string;
    grayLight: string;
    grayLighter: string;
    brandPrimary: string;
    brandSuccess: string;
    brandSuccessDarker: string;
    brandSuccessLighter: string;
    brandInfo: string;
    brandWarning: string;
    brandDanger: string;
    brandDangerDarker: string;
  };
  spacing: {
    xs3: string;
    xs2: string;
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
    xl2: string;
    xl3: string;
    xl4: string;
    xl5: string;
    xl6: string;
  };
  screen: {
    desktop: string;
    tablet: string;
    mobile: string;
  };
  font: {
    family: {
      primary: string;
      secondary: string;
    };
    size: {
      xl: string;
      lg: string;
      md: string;
      sm: string;
      xs: string;
    };
  };
}

export const theme: Theme = {
  palette: {
    ['app-primary']: '#9bddff',
    ['sass-color']: '#CF649A',
    grayDark: '#303030',
    gray: '#464545',
    grayLight: '#999',
    grayLighter: '#EBEBEB',
    brandPrimary: '#375a7f',
    brandSuccess: '#00bc8c',
    brandSuccessDarker: '#007053',
    brandSuccessLighter: '#00efb2',
    brandInfo: '#3498DB',
    brandWarning: '#F39C12',
    brandDanger: '#E74C3C',
    brandDangerDarker: '#bf2718'
  },
  spacing: {
    xs3: '0.125rem', // 2px
    xs2: '0.25rem', // 4px
    xs: '0.5rem', // 8px
    sm: '1rem', // 16px
    md: '1.5rem', // 24px
    lg: '2rem', // 32px
    xl: '3rem', // 48px
    xl2: '4rem', // 64px
    xl3: '5rem', // 80px
    xl4: '6rem', // 96px
    xl5: '7rem', // 112px
    xl6: '8rem' // 128px
  },
  screen: {
    desktop: '',
    tablet: '992px',
    mobile: '768px'
  },
  font: {
    family: {
      primary: '',
      secondary: ''
    },
    size: {
      xl: '1.2rem',
      lg: '1rem',
      md: '0.8rem',
      sm: '0.75rem',
      xs: '0.5rem'
    }
  }
};
