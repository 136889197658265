import { Period } from '../../../../types';
import { MinutesApart, minutesApartOptions } from '../../utils/minutes-apart';

export interface PeriodListState {
  error?: string;
  loading: boolean;
  loaded: boolean;
  periods: Period[];
  ui: {
    minutesApart: MinutesApart;
    minutesAdjusted: number;
  };
}

export const initalPeriodListState: PeriodListState = {
  error: undefined,
  loading: false,
  loaded: false,
  periods: [],
  ui: {
    minutesApart: minutesApartOptions[5],
    minutesAdjusted: 0
  }
};
