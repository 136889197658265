import { Position, PositionRequest } from '../../../../types';
import { showNetworkError } from '../../../shared/utils/growl-utils';

import * as actionTypes from './action-types';
import { HistoryHttpRequest } from './history-http-request';

export const setHistoryPositions = (key: string, positions: Position[]) => {
  return {
    type: actionTypes.SET_HISTORY_POSITIONS,
    payload: {
      key,
      positions
    }
  };
};

export const setHistoryLoading = (key: string, loading: boolean) => {
  return {
    type: actionTypes.SET_HISTORY_LOADING,
    payload: {
      key,
      loading
    }
  };
};

export const setHistoryError = (key: string, error: string) => {
  return {
    type: actionTypes.SET_HISTORY_ERROR,
    payload: {
      key,
      error
    }
  };
};

export const setHistoryDay = (day: string) => {
  return {
    type: actionTypes.UI_SET_HISTORY_DAY,
    value: day
  };
};

/*** ASYNC WITH THUNK ***/

export const fetchHistoryAsync = (key: string, posRequest: PositionRequest) => {
  return (dispatch) => {
    dispatch(setHistoryLoading(key, true));
    HistoryHttpRequest.getHistory(posRequest)
      .then((response) => {
        dispatch(setHistoryPositions(key, response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load position history');
        dispatch(setHistoryError(key, msg));
      });
  };
};
