import querystring from 'querystring';

import axiosApi from '../../shared/axios-api';
import { AuthToken } from '../models/auth-token';

export class AuthHttpRequest {
  public static auth(username: string, password: string) {
    const requestBody = {
      username,
      password
    };
    return axiosApi.post<AuthToken>('/auth', requestBody);
  }

  public static authOld(username: string, password: string) {
    const requestBody = {
      username,
      password
    };
    const config = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    };
    return axiosApi.post<AuthToken>('/token', querystring.stringify(requestBody), config);
  }
}
