import { Position } from '../../../../types';

import * as actionTypes from './action-types';

export const setOpenPositions = (positions: Position[]) => {
  return {
    type: actionTypes.SET_OPEN_POSITIONS,
    positions
  };
};

export const updateOpenPositionUnrealizedPnl = (position: Position) => {
  return {
    type: actionTypes.UPDATE_OPEN_POSITION_UNREALIZED_PNL,
    position
  };
};

export const setOpenPositionsError = (error: string) => {
  return {
    type: actionTypes.SET_OPEN_POSITIONS_ERROR,
    error
  };
};

export const clearOpenPositionsError = () => {
  return {
    type: actionTypes.CLEAR_OPEN_POSITION_ERROR
  };
};

export const clearOpenPositionsLoaded = () => {
  return {
    type: actionTypes.CLEAR_OPEN_POSITIONS_LOADED
  };
};
