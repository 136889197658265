import * as actionTypes from './action-types';
import { GraphTile } from './state';

export const addGraphTile = (graphTile: GraphTile) => {
  return {
    type: actionTypes.ADD_GRAPH_TILE,
    payload: graphTile
  };
};

export const updateGraphTile = (graphTile: GraphTile) => {
  return {
    type: actionTypes.UPDATE_GRAPH_TILE,
    payload: graphTile
  };
};

export const removeGraphTile = (graphTile: GraphTile) => {
  return {
    type: actionTypes.REMOVE_GRAPH_TILE,
    payload: graphTile
  };
};
