import { Summary } from '../../../../types';
import { showNetworkError } from '../../../shared/utils/growl-utils';

import * as actionTypes from './action-types';
import { PnlStrategyHttpRequest } from './http-request';

export const setPnlList = (key: number, summaries: Summary[]) => {
  return {
    type: actionTypes.SET_PNL_STRATEGY_LIST,
    payload: {
      key,
      summaries
    }
  };
};

export const setPnlListLoading = (key: number, loading: boolean) => {
  return {
    type: actionTypes.SET_PNL_STRATEGY_LIST_LOADING,
    payload: {
      key,
      loading
    }
  };
};

export const setPnlListError = (key: number, error: string) => {
  return {
    type: actionTypes.SET_PNL_STRATEGY_LIST_ERROR,
    payload: {
      key,
      error
    }
  };
};

/*** ASYNC WITH THUNK ***/

export const fetchPnlByStrategyAsync = (strategyId: number) => {
  return (dispatch) => {
    dispatch(setPnlListLoading(strategyId, true));
    PnlStrategyHttpRequest.getPnlByStrategy(strategyId)
      .then((response) => {
        dispatch(setPnlList(strategyId, response.data));
      })
      .catch((error) => {
        const msg = showNetworkError(error, 'Failed to load PnL by strategy.');
        dispatch(setPnlListError(strategyId, msg));
      });
  };
};
