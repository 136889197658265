export const setDecimalSpaces = (num: number | undefined, spaces: number) => {
  if (num === undefined) {
    return num;
  }
  if (!num || !Number(num)) {
    return num;
  }
  return num.toFixed(spaces).replace(/\./g, ',');
};

export const setThousenSeparator = (amount: string | number | undefined, separator = ' '): string => {
  if (amount === undefined) {
    return '';
  }
  if (typeof amount === 'number') {
    amount = amount.toString();
  }
  const isNegativeNumber = amount.startsWith('-');
  if (isNegativeNumber) {
    amount = amount.substring(1);
  }

  let partial = '';
  const commaIndex = amount.indexOf(',');
  if (commaIndex > 0) {
    partial = amount.substring(commaIndex, amount.length);
    amount = amount.substring(0, commaIndex);
  }
  if (amount.length >= 7) {
    amount = amount.substring(0, amount.length - 6) + separator + amount.substring(amount.length - 6, amount.length);
  }
  if (amount.length >= 4) {
    amount = amount.substring(0, amount.length - 3) + separator + amount.substring(amount.length - 3, amount.length);
  }

  return (isNegativeNumber ? '-' : '') + amount + partial;
};

export const addLeadingZero = (num: number) => {
  return num < 10 ? '0' + num : num;
};

export const isNumeric = (n: number | undefined | null) => {
  if (n === undefined || n === null) {
    return false;
  }
  return !isNaN(parseFloat(n.toString())) && isFinite(n);
};

export const formatEmpty = (input: number | string | undefined | null, postFix = ''): string => {
  if (input === null || input === '' || input === undefined) {
    return '-';
  }
  return input.toString() + postFix;
};
export const countDecimals = (value: number) => {
  if (Math.floor(value) !== value) return value.toString().split('.')[1].length || 0;
  return 0;
};
