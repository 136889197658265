import { ContractEntity as Contract } from '../../../types';

export interface ContractState {
  loaded: boolean;
  error?: string;
  contracts: Contract[];
}

export const initalContractState: ContractState = {
  loaded: false,
  error: undefined,
  contracts: []
};
