import { Account } from '../../../types';

import * as actionTypes from './action-types';

export interface AccountAction {
  type: string;
  account?: Account;
  accounts?: Account[];
  value?: string;
}

export const updateAccount = (account: Account): AccountAction => {
  return {
    type: actionTypes.UPDATE_ACCOUNT,
    account
  };
};

export const setAccount = (account: Account): AccountAction => {
  return {
    type: actionTypes.SET_ACCOUNT,
    account
  };
};

export const resetAccount = () => {
  return {
    type: actionTypes.RESET_ACCOUNT
  };
};

export const setAccountError = (msg: string): AccountAction => {
  return {
    type: actionTypes.SET_ACCOUNT_ERROR,
    value: msg
  };
};

export const clearAccountError = (): AccountAction => {
  return {
    type: actionTypes.CLEAR_ACCOUNT_ERROR
  };
};

export const setAccountList = (accounts: Account[]): AccountAction => {
  return {
    type: actionTypes.SET_ACCOUNT_LIST,
    accounts
  };
};

export const setAccountListError = (msg: string): AccountAction => {
  return {
    type: actionTypes.SET_ACCOUNT_LIST_ERROR,
    value: msg
  };
};

export const setSelectedAccountId = (id?: number): AccountAction => {
  return {
    type: actionTypes.UI_SET_SELECTED_ACCOUNTID,
    value: `${id}`
  };
};
