import { BarSize } from '../../../types';

export interface BarSizeItem {
  key: BarSize;
  name: string;
}

export const barSizes: BarSizeItem[] = [
  { key: BarSize.NA, name: '' },
  { key: BarSize._1_SEC, name: '1 sec' },
  { key: BarSize._5_SEC, name: '5 sec' },
  { key: BarSize._10_SEC, name: '10 secs' },
  { key: BarSize._15_SEC, name: '15 secs' },
  { key: BarSize._30_SEC, name: '30 secs' },
  { key: BarSize._1_MIN, name: '1 min' },
  { key: BarSize._2_MIN, name: '2 min' },
  { key: BarSize._3_MIN, name: '3 min' },
  { key: BarSize._5_MIN, name: '5 min' },
  { key: BarSize._10_MIN, name: '10 min' },
  { key: BarSize._15_MIN, name: '15 min' },
  { key: BarSize._20_MIN, name: '20 min' },
  { key: BarSize._30_MIN, name: '30 min' },
  { key: BarSize._1_HR, name: '1 hour' },
  { key: BarSize._2_HRS, name: '2 hours' },
  { key: BarSize._3_HRS, name: '3 hours' },
  { key: BarSize._4_HRS, name: '4 hours' },
  { key: BarSize._8_HRS, name: '8 hours' },
  { key: BarSize._1_DAY, name: '1 day' },
  { key: BarSize._1_WEEK, name: '1 week' }
  // { key: BarSize._1_MONTH, name: "1 month" }
];

export const getBarSizeName = (barSize: BarSize) => {
  const item = barSizes.find((x) => x.key === barSize);
  return item ? item.name : '';
};

export const getBarSizeInMinutes = (barSize: BarSize) => {
  if (!Number(barSize)) {
    return null;
  }
  const selectedBarSize = Number(barSize);
  switch (selectedBarSize) {
    case BarSize._1_MIN:
      return 1;
    case BarSize._2_MIN:
      return 2;
    case BarSize._3_MIN:
      return 3;
    case BarSize._5_MIN:
      return 5;
    case BarSize._10_MIN:
      return 10;
    case BarSize._15_MIN:
      return 15;
    case BarSize._20_MIN:
      return 20;
    case BarSize._30_MIN:
      return 30;
    case BarSize._1_HR:
      return 60;
    case BarSize._2_HRS:
      return 60 * 2;
    case BarSize._3_HRS:
      return 60 * 3;
    case BarSize._4_HRS:
      return 60 * 4;
    case BarSize._8_HRS:
      return 60 * 8;
    case BarSize._1_DAY:
      return 60 * 24;
    case BarSize._1_WEEK:
      return 60 * 24 * 7;
    default:
      return null;
  }
};
