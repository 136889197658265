import * as React from 'react';

import { Modal, Button } from 'react-bootstrap';

export interface ConfirmProps {
  show: boolean;
  title?: string;
  text: string;
  cancel(): void;
  confirm(): void;
}

export const ConfirmDialog = ({ show, title, text, cancel, confirm }: ConfirmProps) => {
  return (
    <Modal show={show} bsSize="small" onHide={cancel} aria-labelledby="contained-modal-title">
      {title && (
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title">{title}</Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body>{text}</Modal.Body>

      <Modal.Footer>
        <Button bsStyle="primary" onClick={confirm}>
          Confirm
        </Button>
        <Button onClick={cancel}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
};
