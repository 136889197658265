import * as React from 'react';

import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Switch from 'react-switch';

import { GlobalState } from '../../../../core/root';
import { BarSize } from '../../../../types';
import * as graphActionCreators from '../../store/graph-config/action-creators';
import { getGraphConfigState, GraphConfigState } from '../../store/graph-config/state';

export interface GraphConfigTabProps {
  barSize: BarSize;
}

const GraphConfigTab = ({ barSize }: GraphConfigTabProps) => {
  const dispatch = useDispatch();
  const graphConfigState: GraphConfigState = useSelector((state: GlobalState) => getGraphConfigState(barSize, state));

  const updateGraphConfig = (obj: object) => {
    const field = Object.keys(obj)[0];
    const settings = {
      ...graphConfigState,
      [field]: obj[field]
    };
    dispatch(graphActionCreators.setGraphConfig(barSize, settings));
  };

  const kcSqueezeTypePopover = (
    <Popover id="kcSqueezeTypePopover">
      These Keltner Channels are customized for the Squeeze-indicator; meaning they use the Bollinger Bands periods and SMA, instead of an
      EMA.
    </Popover>
  );

  return (
    <div className="row form-horizontal">
      <div className="col-sm-6">
        <div className="section">
          <p>Simple Moving Averages</p>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show SMA</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch onChange={() => updateGraphConfig({ showSma: !graphConfigState.showSma })} checked={graphConfigState.showSma} />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_sma" className="col-sm-6 control-label">
            SMA Periods
          </label>
          <div className="col-sm-6">
            <input
              id="graph_sma"
              type="number"
              step="1"
              min="1"
              className="form-control"
              value={graphConfigState.smaPeriods}
              onChange={(event) => updateGraphConfig({ smaPeriods: Number(event.target.value) })}
            />
          </div>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show 2nd SMA</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch
                onChange={() => updateGraphConfig({ showSma2nd: !graphConfigState.showSma2nd })}
                checked={graphConfigState.showSma2nd}
              />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_sma_2nd" className="col-sm-6 control-label">
            2nd SMA Periods
          </label>
          <div className="col-sm-6">
            <input
              id="graph_sma_2nd"
              type="number"
              step="1"
              min="1"
              className="form-control"
              value={graphConfigState.sma2ndPeriods}
              onChange={(event) => updateGraphConfig({ sma2ndPeriods: Number(event.target.value) })}
            />
          </div>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show 3rd SMA</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch
                onChange={() => updateGraphConfig({ showSma3rd: !graphConfigState.showSma3rd })}
                checked={graphConfigState.showSma3rd}
              />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_sma_3rd" className="col-sm-6 control-label">
            3rd SMA Periods
          </label>
          <div className="col-sm-6">
            <input
              id="graph_sma_3rd"
              type="number"
              step="1"
              min="1"
              className="form-control"
              value={graphConfigState.sma3rdPeriods}
              onChange={(event) => updateGraphConfig({ sma3rdPeriods: Number(event.target.value) })}
            />
          </div>
        </div>

        <div className="section">
          <hr />
          <p>Bollinger Bands</p>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show Bollinger Bands</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch
                onChange={() => updateGraphConfig({ showBollingerBands: !graphConfigState.showBollingerBands })}
                checked={graphConfigState.showBollingerBands}
              />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_bbp" className="col-sm-6 control-label">
            BB Periods
          </label>
          <div className="col-sm-6">
            <input
              id="graph_bbp"
              type="number"
              step="1"
              min="1"
              className="form-control"
              value={graphConfigState.bbPeriods}
              onChange={(event) => updateGraphConfig({ bbPeriods: Number(event.target.value) })}
            />
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_bbstd" className="col-sm-6 control-label">
            BB SD Multiplier
          </label>
          <div className="col-sm-6">
            <input
              id="graph_bbstd"
              type="number"
              step="any"
              min="0"
              className="form-control"
              value={graphConfigState.bbStandardDeviation}
              onChange={(event) => updateGraphConfig({ bbStandardDeviation: Number(event.target.value) })}
            />
          </div>
        </div>

        <div className="section">
          <hr />
          <p>
            <span>Keltner Channels</span>
            <OverlayTrigger placement="top" overlay={kcSqueezeTypePopover}>
              <span className="gap-left-10">
                <i className="fa fa-question-circle fa-lg text-info" />
              </span>
            </OverlayTrigger>
          </p>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show Keltner Channels</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch
                onChange={() => updateGraphConfig({ showKeltnerChannels: !graphConfigState.showKeltnerChannels })}
                checked={graphConfigState.showKeltnerChannels}
              />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_kcp" className="col-sm-6 control-label">
            ATR Periods
          </label>
          <div className="col-sm-6">
            <input
              id="graph_kcp"
              type="number"
              step="1"
              min="1"
              className="form-control"
              value={graphConfigState.kcPeriods}
              onChange={(event) => updateGraphConfig({ kcPeriods: Number(event.target.value) })}
            />
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_kcatr" className="col-sm-6 control-label">
            ATR Multiplier
          </label>
          <div className="col-sm-6">
            <input
              id="graph_kcatr"
              type="number"
              step="any"
              min="0"
              className="form-control"
              value={graphConfigState.kcAtrMultiplier}
              onChange={(event) => updateGraphConfig({ kcAtrMultiplier: Number(event.target.value) })}
            />
          </div>
        </div>

        <div className="section">
          <hr />
          <p>
            <span>Donchian Channels</span>
          </p>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show Donchian Channels</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch
                onChange={() => updateGraphConfig({ showDonchianChannels: !graphConfigState.showDonchianChannels })}
                checked={graphConfigState.showDonchianChannels}
              />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_dc" className="col-sm-6 control-label">
            DC Periods
          </label>
          <div className="col-sm-6">
            <input
              id="graph_dc"
              type="number"
              step="1"
              min="1"
              className="form-control"
              value={graphConfigState.dcPeriods}
              onChange={(event) => updateGraphConfig({ dcPeriods: Number(event.target.value) })}
            />
          </div>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show Entries</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch
                onChange={() => updateGraphConfig({ showDcEntry: !graphConfigState.showDcEntry })}
                checked={graphConfigState.showDcEntry}
              />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_dcnc" className="col-sm-6 control-label">
            S/R Min Periods
          </label>
          <div className="col-sm-6">
            <input
              id="graph_dcnc"
              type="number"
              step="1"
              min="0"
              className="form-control"
              value={graphConfigState.dcResistanceSupportPeriods}
              onChange={(event) => updateGraphConfig({ dcResistanceSupportPeriods: Number(event.target.value) })}
            />
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_dclm" className="col-sm-6 control-label">
            Limit Multiplier
          </label>
          <div className="col-sm-6">
            <input
              id="graph_dclm"
              type="number"
              step="any"
              min="0"
              className="form-control"
              value={graphConfigState.dcLimitMultiplier}
              onChange={(event) => updateGraphConfig({ dcLimitMultiplier: Number(event.target.value) })}
            />
          </div>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Use Close Price</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch
                onChange={() => updateGraphConfig({ dcUserClosePrice: !graphConfigState.dcUseClosePrice })}
                checked={graphConfigState.dcUseClosePrice}
              />
            </label>
          </div>
        </div>

        <div className="section">
          <hr />
          <p>Volume</p>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show Volume Graph</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch
                onChange={() => updateGraphConfig({ showVolumeGraph: !graphConfigState.showVolumeGraph })}
                checked={graphConfigState.showVolumeGraph}
              />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show Volume Avg</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch
                onChange={() => updateGraphConfig({ showVolumeAvg: !graphConfigState.showVolumeAvg })}
                checked={graphConfigState.showVolumeAvg}
              />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_vol_avg" className="col-sm-6 control-label">
            Vol. Avg Periods
          </label>
          <div className="col-sm-6">
            <input
              id="graph_vol_avg"
              type="number"
              step="1"
              min="1"
              className="form-control"
              value={graphConfigState.volumeAvgPeriods}
              onChange={(event) => updateGraphConfig({ volumeAvgPeriods: Number(event.target.value) })}
            />
          </div>
        </div>

        <div className="section">
          <hr />
          <p>Support {'&'} Resistance</p>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show Support/Resistance</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch
                onChange={() => updateGraphConfig({ showResistanceSupport: !graphConfigState.showResistanceSupport })}
                checked={graphConfigState.showResistanceSupport}
              />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_rs_range" className="col-sm-6 control-label">
            S/R Range
          </label>
          <div className="col-sm-6">
            <input
              id="graph_rs_range"
              type="number"
              step="any"
              min="0"
              className="form-control"
              value={graphConfigState.resistanceSupportRange}
              onChange={(event) => updateGraphConfig({ resistanceSupportRange: Number(event.target.value) })}
            />
          </div>
        </div>
      </div>

      <div className="col-sm-6">
        <div className="section">
          <hr className="hidden-sm hidden-md hidden-lg" />
          <p>Oscillators</p>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show RSI</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch onChange={() => updateGraphConfig({ showRsi: !graphConfigState.showRsi })} checked={graphConfigState.showRsi} />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_rsi" className="col-sm-6 control-label">
            RSI Periods
          </label>
          <div className="col-sm-6">
            <input
              id="graph_rsi"
              type="number"
              step="1"
              min="1"
              className="form-control"
              value={graphConfigState.rsiPeriods}
              onChange={(event) => updateGraphConfig({ rsiPeriods: Number(event.target.value) })}
            />
          </div>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show RSI-MA</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch onChange={() => updateGraphConfig({ showRsiMa: !graphConfigState.showRsiMa })} checked={graphConfigState.showRsiMa} />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_rsi_ma" className="col-sm-6 control-label">
            RSI-MA Periods
          </label>
          <div className="col-sm-6">
            <input
              id="graph_rsi_ma"
              type="number"
              step="1"
              min="1"
              className="form-control"
              value={graphConfigState.rsiMaPeriods}
              onChange={(event) => updateGraphConfig({ rsiMaPeriods: Number(event.target.value) })}
            />
          </div>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show % Range</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch onChange={() => updateGraphConfig({ showWpr: !graphConfigState.showWpr })} checked={graphConfigState.showWpr} />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_wpr" className="col-sm-6 control-label">
            % Range Periods
          </label>
          <div className="col-sm-6">
            <input
              id="graph_wpr"
              type="number"
              step="1"
              min="1"
              className="form-control"
              value={graphConfigState.wprPeriods}
              onChange={(event) => updateGraphConfig({ wprPeriods: Number(event.target.value) })}
            />
          </div>
        </div>

        <div className="section">
          <hr />
          <p>Average True Range</p>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show True Range</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch
                onChange={() => updateGraphConfig({ showTrueRange: !graphConfigState.showTrueRange })}
                checked={graphConfigState.showTrueRange}
              />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show ATR</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch onChange={() => updateGraphConfig({ showAtr: !graphConfigState.showAtr })} checked={graphConfigState.showAtr} />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_atr" className="col-sm-6 control-label">
            ATR Periods
          </label>
          <div className="col-sm-6">
            <input
              id="graph_atr"
              type="number"
              step="1"
              min="1"
              className="form-control"
              value={graphConfigState.atrPeriods}
              onChange={(event) => updateGraphConfig({ atrPeriods: Number(event.target.value) })}
            />
          </div>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show ATR-MA</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch onChange={() => updateGraphConfig({ showAtrMa: !graphConfigState.showAtrMa })} checked={graphConfigState.showAtrMa} />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_atr_ma" className="col-sm-6 control-label">
            ATR-MA Periods
          </label>
          <div className="col-sm-6">
            <input
              id="graph_atr_ma"
              type="number"
              step="1"
              min="1"
              className="form-control"
              value={graphConfigState.atrMaPeriods}
              onChange={(event) => updateGraphConfig({ atrMaPeriods: Number(event.target.value) })}
            />
          </div>
        </div>

        <div className="section">
          <hr />
          <p>Average Directinal Index</p>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show ADX</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch onChange={() => updateGraphConfig({ showAdx: !graphConfigState.showAdx })} checked={graphConfigState.showAdx} />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_adx_basis_periods" className="col-sm-6 control-label">
            ADX Basis Periods
          </label>
          <div className="col-sm-6">
            <input
              id="graph_adx_basis_periods"
              type="number"
              step="1"
              min="1"
              className="form-control"
              value={graphConfigState.adxBasisPeriods}
              onChange={(event) => updateGraphConfig({ adxBasisPeriods: Number(event.target.value) })}
            />
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_adx_periods" className="col-sm-6 control-label">
            ADX Periods
          </label>
          <div className="col-sm-6">
            <input
              id="graph_adx_periods"
              type="number"
              step="1"
              min="1"
              className="form-control"
              value={graphConfigState.adxPeriods}
              onChange={(event) => updateGraphConfig({ adxPeriods: Number(event.target.value) })}
            />
          </div>
        </div>

        <div className="section">
          <hr />
          <p>Aroon</p>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show Aroon</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch onChange={() => updateGraphConfig({ showAroon: !graphConfigState.showAroon })} checked={graphConfigState.showAroon} />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_aroon_periods" className="col-sm-6 control-label">
            Aroon Periods
          </label>
          <div className="col-sm-6">
            <input
              id="graph_aroon_periods"
              type="number"
              step="1"
              min="1"
              className="form-control"
              value={graphConfigState.aroonPeriods}
              onChange={(event) => updateGraphConfig({ aroonPeriods: Number(event.target.value) })}
            />
          </div>
        </div>

        <div className="section">
          <hr />
          <p>Peaks</p>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show Peaks</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch onChange={() => updateGraphConfig({ showPeaks: !graphConfigState.showPeaks })} checked={graphConfigState.showPeaks} />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_peak_periods" className="col-sm-6 control-label">
            Peak Periods
          </label>
          <div className="col-sm-6">
            <input
              id="graph_peak_periods"
              type="number"
              step="1"
              min="1"
              className="form-control"
              value={graphConfigState.peakPeriods}
              onChange={(event) => updateGraphConfig({ peakPeriods: Number(event.target.value) })}
            />
          </div>
        </div>

        <div className="section">
          <hr />
          <p>Williams Fractals</p>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show Fractals</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch
                onChange={() => updateGraphConfig({ showWilliamsFractal: !graphConfigState.showWilliamsFractal })}
                checked={graphConfigState.showWilliamsFractal}
              />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm gap-top-20">
          <label htmlFor="graph_w_fractal" className="col-sm-6 control-label">
            Fractal
          </label>
          <div className="col-sm-6">
            <input
              id="graph_w_fractal"
              type="number"
              step="1"
              min="1"
              className="form-control"
              value={graphConfigState.williamsFractal}
              onChange={(event) => updateGraphConfig({ williamsFractal: Number(event.target.value) })}
            />
          </div>
        </div>

        <div className="section">
          <hr />
          <p>Candlestick</p>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show Heikin Ashi Bars</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch
                onChange={() => updateGraphConfig({ showHeikinAshi: !graphConfigState.showHeikinAshi })}
                checked={graphConfigState.showHeikinAshi}
              />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show Connected Bars</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch
                onChange={() => updateGraphConfig({ showConnectedBars: !graphConfigState.showConnectedBars })}
                checked={graphConfigState.showConnectedBars}
              />
            </label>
          </div>
        </div>

        <div className="form-group form-group-sm">
          <label className="col-sm-6 control-label">Show Transactions</label>
          <div className="col-sm-6">
            <label className="react-switch react-switch--sm">
              <Switch
                onChange={() => updateGraphConfig({ showTransactions: !graphConfigState.showTransactions })}
                checked={graphConfigState.showTransactions}
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphConfigTab;
