/* eslint-disable no-console */
import * as React from 'react';
import { SyntheticEvent, useState } from 'react';

import classNames from 'classnames';

import { Position, Forecast } from '../../../../types';
import GraphDialog from '../../../graph/components/GraphDialog';
import { formatUsd } from '../../../shared/utils/currency-utils';
import { toMonthTime } from '../../../shared/utils/date-format-utils';
import { getSumCommission, getSumGrossPnl, getSumNetPnl, getSumSlippage, getSumUnrealizedPnl } from '../../../shared/utils/position-utils';
import { getDuration } from '../../../shared/utils/time-utils';

export interface Props {
  positions: Position[];
  summaryOnly?: boolean;
}

const getTableRows = (positions: Position[], showGraphHandler: Function) => {
  return positions.map((position: Position, index: number) => {
    const stopHand = position.stopLossReached ? (
      <i
        className={classNames('fa', 'fa-hand-paper-o', 'gap-right-5', {
          'danger-text2': position.sumNetPnl < 0,
          'success-text': position.sumNetPnl > 0
        })}
        aria-hidden="true"
        title="Stop Loss"
      ></i>
    ) : undefined;

    const throphy = position.limitReached ? (
      <i
        className={classNames('fa', 'fa-trophy', {
          gold: !position.stopLossReached,
          grey: position.stopLossReached
        })}
        aria-hidden="true"
        title="Limit"
      ></i>
    ) : undefined;

    let exitStatus: JSX.Element | undefined;
    if (stopHand || throphy) {
      exitStatus = (
        <span className="gap-right-5">
          {stopHand}
          {throphy}
        </span>
      );
    }

    const stopLossMoves = Number(position.stopLossMoves) > 0 ? <span className="small info-text">{position.stopLossMoves}</span> : '';

    return (
      <tr key={position.id} className={classNames({ overlapping: position.isOverlapping })}>
        <td className="middle small">
          <i>{index + 1}</i>
        </td>
        <td className="middle small">{Forecast[position.forecast]}</td>
        <td className="middle small text-center">{position.orderSize}</td>
        <td className="middle small text-right">
          <span>
            {formatUsd(position.sumSlippageAmount, 0)} ({position.sumSlippageTicks})
          </span>
        </td>
        <td className="middle small text-right">{formatUsd(position.forecastStopLoss - position.sumSlippageAmount, 0)}</td>
        <td className="middle small text-right">{formatUsd(position.forecastLimit - position.sumSlippageAmount, 0)}</td>

        <td className="middle small vborder">{toMonthTime(position.created)}</td>
        <td className="middle small">{toMonthTime(position.exited)}</td>
        <td className="middle small text-right">{getDuration(position.created, position.exited)}</td>
        <td
          className={classNames('middle', 'small', 'text-right', {
            'success-text': position.sumUnrealizedPnl > 0,
            'danger-text2': position.sumUnrealizedPnl < 0
          })}
        >
          {formatUsd(position.sumUnrealizedPnl, 0)}
        </td>
        {/* <td className="middle small text-center">
            <i className={classNames('fa', { 'fa-square-o': !position.isOverlapping, 'fa-check-square-o': position.isOverlapping })}></i>
          </td> */}
        <td className="middle small text-right vborder">
          <span>{formatUsd(position.sumGrossPnl, 0)}</span>
        </td>
        <td className="middle small text-right">
          <span>{formatUsd(position.sumCommission, 1)}</span>
        </td>
        <td className="middle small text-right">
          <span
            className={classNames({
              'success-text': position.sumNetPnl > 0,
              'danger-text2': position.sumNetPnl < 0
            })}
          >
            {formatUsd(position.sumNetPnl, 0)}
          </span>
        </td>

        <td className="middle small text-center">{stopLossMoves}</td>
        <td className="middle small text-center">
          <button className="btn btn-success btn-xs" onClick={(event: SyntheticEvent) => showGraphHandler(event, position)}>
            <span className="fa fa-line-chart fa-xs"></span>
          </button>
        </td>
        <td className="middle small vborder">
          {exitStatus}
          {position.exitReason}
        </td>
      </tr>
    );
  });
};

const BacktestPositionTable: React.FunctionComponent<Props> = ({ positions, summaryOnly }: Props) => {
  const [showGraphDialog, setShowGraphDialog] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState<Position>();

  const toggleShowGraphDialog = () => {
    setShowGraphDialog(!showGraphDialog);
  };

  const showGraphHandler = (e: SyntheticEvent, position: Position) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setSelectedPosition(position);
    toggleShowGraphDialog();
  };

  const header = (
    <tr>
      <th className="middle small" style={{ width: '30px' }}>
        #
      </th>
      <th className="middle small">Forecast</th>
      <th className="middle small text-center">Size</th>
      <th className="middle small text-right">Slipp.</th>
      <th className="middle small text-right">Stop</th>
      <th className="middle small text-right">Limit</th>

      <th className="middle small">Created</th>
      <th className="middle small">Exited</th>
      <th className="middle small text-right">Dur.</th>
      <th className="middle small text-right">Unrlzd.</th>

      <th className="middle small text-right no-wrap">Gross PnL</th>
      <th className="middle small text-right">Comm.</th>
      <th className="middle small text-right no-wrap">Net PnL</th>
      <th className="middle small">MSL</th>
      <th className="middle small"></th>
      <th className="middle small" style={{ minWidth: '150px' }}>
        Exit Reason
      </th>
    </tr>
  );

  let rows: JSX.Element[] | undefined;
  if (!summaryOnly) {
    rows = getTableRows(positions, showGraphHandler);
  }

  const sumUnrealizedPnl = getSumUnrealizedPnl(positions);
  const sumGrossPnl = getSumGrossPnl(positions) + sumUnrealizedPnl;
  const sumNetPnl = getSumNetPnl(positions) + sumUnrealizedPnl;

  const footer = (
    <tr>
      <th colSpan={2} className="middle small">
        Sum:
      </th>
      <th className="middle small text-center"></th>
      <th className="middle small text-right">{getSumSlippage(positions)}</th>
      <th className="middle small text-right"></th>
      <th className="middle small text-right"></th>

      <th className="middle small"></th>
      <th className="middle small"></th>
      <th className="middle small text-right"></th>
      <th
        className={classNames('middle', 'small', 'text-right', {
          'success-text': sumUnrealizedPnl > 0,
          'danger-text2': sumUnrealizedPnl < 0
        })}
      >
        {formatUsd(sumUnrealizedPnl, 0)}
      </th>

      <th className="middle small text-right">{formatUsd(sumGrossPnl, 0)}</th>
      <th className="middle small text-right">{getSumCommission(positions)}</th>
      <th className="middle small text-right">
        <span
          className={classNames({
            'success-text': sumNetPnl > 0,
            'danger-text2': sumNetPnl < 0
          })}
        >
          {formatUsd(sumNetPnl, 0)}
        </span>
      </th>
      <th className="middle small text-center"></th>
      <th className="middle small text-center"></th>
      <th className="middle small"></th>
    </tr>
  );

  return (
    <div>
      {positions.length > 0 && (
        <div className="table-responsive gap-top-10">
          <table className="table table-striped table-hover table-condensed">
            <thead>{header}</thead>
            {rows && <tbody>{rows}</tbody>}
            <tfoot>{footer}</tfoot>
          </table>
        </div>
      )}

      {showGraphDialog && selectedPosition && (
        <GraphDialog position={selectedPosition} show={showGraphDialog} toggle={toggleShowGraphDialog} />
      )}
    </div>
  );
};

export default BacktestPositionTable;
