import { GlobalState } from '../../../../core/root';

import { ContractPartialState, initalContractPartialState } from './state';

export const getContractPartialState = (state: GlobalState, securityId: number): ContractPartialState => {
  return state.contractPartialMapState.get(securityId) ?? initalContractPartialState;
};

export const getContractPartialsError = (state: GlobalState, securityId: number) => {
  return getContractPartialState(state, securityId).error;
};

export const getContractPartialsLoaded = (state: GlobalState, securityId: number) => {
  return getContractPartialState(state, securityId).loaded;
};

export const getContractPartials = (state: GlobalState, securityId: number) => {
  return getContractPartialState(state, securityId).partials;
};
