import * as React from 'react';
import { useState, useEffect } from 'react';

import { Modal, Button } from 'react-bootstrap';

export interface Props {
  show: boolean;
  cancel(): void;
  name: string;
  save(name: string): void;
}

export const EditGroupDialog = ({ show, cancel, name, save }: Props) => {
  const [value, setValue] = useState(name);

  useEffect(() => {
    setValue(name);
  }, [name]);

  return (
    <Modal show={show} bsSize="small" onHide={cancel} aria-labelledby="contained-modal-title">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title">
          <span>Edit Group Name</span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form name="editGroupForm" className="form-horizontal" onSubmit={() => save(value)}>
          <div className="form-group form-group-sm">
            <label htmlFor="edit_prop" className="col-sm-4 control-label">
              <span>Group</span>
            </label>
            <div className="col-sm-8">
              <input id="edit_prop" type="text" className="form-control" value={value} onChange={(event) => setValue(event.target.value)} />
            </div>
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          bsStyle="primary"
          onClick={() => {
            save(value);
          }}
        >
          Save
        </Button>
        <Button onClick={cancel}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
};
