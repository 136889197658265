import { Message } from '../../../../types';

export interface InboxState {
  loaded: boolean;
  error: boolean;
  errorMessage?: string;
  messages: Message[];
}

export const initalInboxState: InboxState = {
  loaded: false,
  error: false,
  errorMessage: undefined,
  messages: []
};
