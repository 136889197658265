import * as React from 'react';

import classNames from 'classnames';

export interface Props {
  formatCurrency: (amount: number) => string;
  totalCash: number;
  availableCash: number;
  initialMargin: number;
  unrealizedPnl: number;
  realizedPnl: number;
}

export const AccountStatsTable = ({ formatCurrency, totalCash, availableCash, initialMargin, unrealizedPnl, realizedPnl }: Props) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-striped table-condensed">
        <tbody>
          <tr>
            <td className="middle small text-left">Total Cash</td>
            <td className="middle small text-right">{formatCurrency(totalCash)}</td>
          </tr>
          <tr>
            <td className="middle small text-left">Available Cash</td>
            <td className="middle small text-right">{formatCurrency(availableCash)}</td>
          </tr>
          <tr>
            <td className="middle small text-left">Initial Margin</td>
            <td className="middle small text-right">{formatCurrency(initialMargin)}</td>
          </tr>
          <tr>
            <td className="middle small text-left">Unrealized PnL</td>
            <td className="middle small text-right">
              <span
                className={classNames({
                  'success-text': unrealizedPnl > 0,
                  'danger-text2': unrealizedPnl < 0
                })}
              >
                {formatCurrency(unrealizedPnl)}
              </span>
            </td>
          </tr>
          <tr>
            <td className="middle small text-left">Realized PnL</td>
            <td className="middle small text-right">
              <span
                className={classNames({
                  'success-text': realizedPnl > 0,
                  'danger-text2': realizedPnl < 0
                })}
              >
                {formatCurrency(realizedPnl)}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
