import * as React from 'react';

import classNames from 'classnames';

import { Indicator, SqueezeMode } from '../../../../types';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const SqueezeForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label className="col-sm-6 control-label">Mode</label>
        <div className="col-sm-6">
          <div className="btn-group btn-group-justified" role="group">
            <div className="btn-group" role="group">
              <button
                key="btn-1"
                type="button"
                className={classNames('btn', 'btn-sm btn-default', 'ripple', { active: indicator.squeezeMode === SqueezeMode.Inside })}
                onClick={() => update({ squeezeMode: SqueezeMode.Inside })}
              >
                Inside
              </button>
            </div>
            <div className="btn-group" role="group">
              <button
                key="btn-2"
                type="button"
                className={classNames('btn', 'btn-sm btn-default', 'ripple', { active: indicator.squeezeMode === SqueezeMode.Outside })}
                onClick={() => update({ squeezeMode: SqueezeMode.Outside })}
              >
                Outside
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="bb_periods" className="col-sm-6 control-label">
          BB Periods (d:20)
        </label>
        <div className="col-sm-6">
          <input
            id="bb_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.bbPeriods}
            onChange={(event) => update({ bbPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="bb_value" className="col-sm-6 control-label">
          BB Std Dev. (d:2.0)
        </label>
        <div className="col-sm-6">
          <input
            id="bb_value"
            type="number"
            step="any"
            className="form-control"
            value={indicator.bbValue}
            onChange={(event) => update({ bbValue: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="kc_periods" className="col-sm-6 control-label">
          KC Periods (d:15)
        </label>
        <div className="col-sm-6">
          <input
            id="kc_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.kcPeriods}
            onChange={(event) => update({ kcPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label htmlFor="kc_value" className="col-sm-6 control-label">
          KC ATR (d:1.5)
        </label>
        <div className="col-sm-6">
          <input
            id="kc_value"
            type="number"
            step="any"
            className="form-control"
            value={indicator.kcValue}
            onChange={(event) => update({ kcValue: Number(event.target.value) })}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SqueezeForm;
