import * as constants from '../../shared/constants/local-storage';
import { AuthToken } from '../models/auth-token';

import * as actionTypes from './action-types';

export interface AuthAction {
  type: string;
  value?: string;
  authToken?: AuthToken;
}

export const logout = (): AuthAction => {
  localStorage.removeItem(constants.LOCAL_STORAGE_TOKEN);
  localStorage.removeItem(constants.LOCAL_STORAGE_EXPIRES_TIME);
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const authStart = (): AuthAction => {
  return {
    type: actionTypes.AUTH_START
  };
};

// to be updated in axios interceptor if not autenticated and redirected to /login
export const setAuthRedirectPath = (path: string): AuthAction => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    value: path
  };
};

export const authSuccess = (authToken: AuthToken): AuthAction => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    authToken
  };
};

export const authFail = (error: string): AuthAction => {
  return {
    type: actionTypes.AUTH_FAIL,
    value: error
  };
};
