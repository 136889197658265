import * as React from 'react';

import { OverlayTrigger, Popover } from 'react-bootstrap';
import Switch from 'react-switch';

import { Indicator } from '../../../../types';

export interface Props {
  indicator: Indicator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(obj: any): void;
}

const HighLowSeqForm: React.FunctionComponent<Props> = ({ indicator, update }) => {
  const periodsPopover = <Popover id="periodsPopover">Number of periods for conistant falling high (sell) or raising low (buy)</Popover>;
  const deviationPopover = (
    <Popover id="deviationPopover">
      Deviation threshold within the number of periods. If the deviation has value 2; 2 or the high/low prices out-or-order will be ignored.
    </Popover>
  );
  const sequenceDeviationPopover = (
    <Popover id="sequenceDeviationPopover">
      The indicator is blocked if the deviations are in sequence, meaning more than one period is out-of-order in a row.
    </Popover>
  );

  return (
    <React.Fragment>
      <div className="form-group form-group-sm">
        <label className="control-label col-sm-6">
          <span>Periods</span>
          <OverlayTrigger placement="top" overlay={periodsPopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg info-text" />
            </span>
          </OverlayTrigger>
        </label>
        <div className="col-sm-6">
          <input
            id="hl_periods"
            type="number"
            step="1"
            className="form-control"
            value={indicator.periods}
            onChange={(event) => update({ periods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label className="control-label col-sm-6">
          <span>Deviation</span>
          <OverlayTrigger placement="top" overlay={deviationPopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg info-text" />
            </span>
          </OverlayTrigger>
        </label>
        <div className="col-sm-6">
          <input
            id="hl_deviation"
            type="number"
            step="1"
            className="form-control"
            value={indicator.basisPeriods}
            onChange={(event) => update({ basisPeriods: Number(event.target.value) })}
          />
        </div>
      </div>

      <div className="form-group form-group-sm">
        <label className="control-label col-sm-6">
          <span>Use Sequence Deviation</span>
          <OverlayTrigger placement="top" overlay={sequenceDeviationPopover}>
            <span className="gap-left-10">
              <i className="fa fa-question-circle fa-lg info-text" />
            </span>
          </OverlayTrigger>
        </label>
        <div className="col-sm-6">
          <label className="react-switch react-switch--sm">
            <Switch onChange={() => update({ useCrossOver: !indicator.useCrossOver })} checked={indicator.useCrossOver} />
          </label>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HighLowSeqForm;
