import { StrategyGroup, Strategy } from '../../types';

export const sortByArchived = (a: StrategyGroup, b: StrategyGroup) => {
  if (a.archived && !b.archived) {
    return 1;
  } else if (!a.archived && b.archived) {
    return -1;
  } else {
    return 0;
  }
};

export const sortByName = (a: StrategyGroup, b: StrategyGroup) => {
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  } else {
    return 0;
  }
};

export const sortStrategyGroups = (a: StrategyGroup, b: StrategyGroup) => {
  const sort = sortByArchived(a, b);
  if (sort !== 0) {
    return sort;
  }
  return sortByName(a, b);
};

export const sortStrategies = (a: Strategy, b: Strategy) => {
  return a.name > b.name ? 1 : -1;
};

export const sortStrategiesByGroups = (a: Strategy, b: Strategy) => {
  if (!a.group && !b.group) {
    return a.name > b.name ? 1 : -1;
  }
  if (!a.group) {
    return -1;
  }
  if (!b.group) {
    return 1;
  }
  if (a.group.name === b.group.name) {
    return a.name > b.name ? 1 : -1;
  }
  return a.group.name > b.group.name ? 1 : -1;
};
