import { growl } from '@christianleypoldt/react-growl';
import { AxiosError } from 'axios';

export const showNetworkError = (error: AxiosError, title?: string): string => {
  let growlTitle: string | undefined;
  let growlMessage: string;

  if (error.isAxiosError && error.response) {
    growlTitle = title || error.response.statusText;
    growlMessage = error.response.data.message || error.response.data.error_description || (title ? error.response.statusText : '');
  } else {
    growlTitle = title;
    growlMessage = error.toString();
  }

  growl({ title: growlTitle, message: growlMessage, type: 'error' });
  return growlMessage;
};

export const showGrowlError = (message: string, title?: string): void => {
  growl({ title, message, type: 'error' });
};

export const showGrowlWarning = (message: string, title?: string): void => {
  growl({ title, message, type: 'warning' });
};
